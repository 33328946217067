import * as React from "react"
import { LabelLarge, ParagraphSmall } from "baseui/typography"
import { ZenCard } from "../../components/common"
import { useStyletron } from "baseui"
import { ZenDatePicker } from "../../components/zenComponents/zenTime"
import moment from "moment-timezone"
import { useForm } from "react-hook-form"
import { ErrorNotification } from "../../components/errorBox"
import { useMutation, useQuery } from "react-fetching-library"
import { fetching } from "../../fetching"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenConfirmModal } from "../../components/zenComponents/zenConfirmModal"
import { useZenToast } from "../../components/zenComponents/useZenToast"
import { LockDateUpdateInput } from "../../fetching/inputType"
import { RolePermission, ZenDateFormat } from "../../types/types"
import { KIND } from "baseui/button"
import { AuthContainer } from "../../controllers/auth"

export const LockDateManagement = () => {
	const [isEdit, setIsEdit] = React.useState(false)

	const [css] = useStyletron()
	const card = css({
		width: "fit-content",
	})
	const buttons = css({
		marginTop: "10px",
		display: "flex",
		justifyContent: isEdit ? "space-between" : "flex-end",
	})

	const { hasPermission } = AuthContainer.useContainer()
	const { control, handleSubmit, errors, getValues, trigger, reset } = useForm()
	const { showToast } = useZenToast()

	const [showConfirmation, setShowConfirmation] = React.useState(false)

	const { mutate, loading, payload, error } = useMutation(fetching.mutation.updateLockDate)
	const { payload: lockDate, loading: lockDateLoading, error: lockDateError, query } = useQuery(fetching.query.lockDateGet())

	const handleClick = async () => {
		if (!isEdit) {
			setIsEdit(true)
			return
		}

		const valid = await trigger()
		if (!valid) {
			return
		}

		setShowConfirmation(true)
	}

	const onSubmit = async (data: LockDateUpdateInput) => {
		// Set to start of day
		data.lockDate = moment(data.lockDate).startOf("day").toDate()

		const resp = await mutate(data)
		if (resp.payload && !resp.error) {
			setIsEdit(false)
			showToast("Lock date updated successfully.", "positive")
			query()
		}

		setShowConfirmation(false)
	}

	// Set values when lock date is loaded
	React.useEffect(() => {
		if (lockDate && !lockDateError) {
			const values: LockDateUpdateInput = {
				lockDate: new Date(lockDate.date),
			}
			reset(values)
		}
	}, [lockDate, lockDateError, reset])

	return (
		<ZenCard className={card}>
			<LabelLarge>Lock Date Management</LabelLarge>
			<ParagraphSmall maxWidth={"400px"}>
				Setting the lock date will prevent creation or editing of all NDIS related sessions and travels that take place prior to the selected date. This
				date can't be set in the future.
			</ParagraphSmall>
			<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<ZenDatePicker
					nullDefaultValue={true}
					formRef={control}
					label={"Lock Date"}
					formName={"lockDate"}
					inputError={errors.lockDate}
					formRules={{
						validate: {
							required: (value: string) => {
								if (!value) {
									return "You must select a lock date"
								}
								if (moment(value).isAfter(moment())) {
									return "Lock date can not be in the future"
								}
								return null
							},
						},
					}}
					disabled={lockDateError || lockDateLoading || !isEdit}
				/>
			</form>
			{lockDateError && <ErrorNotification messageOrPayload={lockDate} />}
			{error && <ErrorNotification messageOrPayload={payload} />}
			{hasPermission(RolePermission.LockDateManage) && (
				<div className={buttons}>
					{isEdit && (
						<ZenButton
							type={"button"}
							disabled={loading}
							onClick={() => {
								setIsEdit(false)
								reset()
							}}
							altKind={"secondary"}
						>
							Cancel
						</ZenButton>
					)}
					<ZenButton type={"button"} onClick={handleClick} isLoading={loading || lockDateLoading} disabled={lockDateError}>
						{isEdit ? "Save" : "Edit"}
					</ZenButton>
				</div>
			)}
			<ZenConfirmModal
				title={`Are you sure you want to update lock date to ${moment(getValues("lockDate")).format(ZenDateFormat)}?`}
				loading={loading}
				kind={KIND.primary}
				action={"Confirm"}
				open={showConfirmation}
				setOpen={setShowConfirmation}
				confirm={() => handleSubmit(onSubmit)()}
			/>
		</ZenCard>
	)
}
