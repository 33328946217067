import * as React from "react"
import { useStyletron } from "baseui"
import { BlockOverrides } from "baseui/block"
import { ProgressBar } from "baseui/progress-bar"
import { NumberedStep, ProgressSteps } from "baseui/progress-steps"
import { LabelMedium } from "baseui/typography"
import { useHistory } from "react-router-dom"

import { ZenTheme } from "../../themeOverrides"
import { IntakeType } from "../../types/enums"
import { LabelLink } from "../../types/types"
import { intakeURLParser } from "../clients/Intake/adult/intakeAdultBaseForm"
import { ZenButton } from "./zenButtons"

interface ZenProgressBarProps {
	labels: React.ReactNode[]
	currentStep: number
	width?: string
	marginTop?: string
	marginLeft?: string
}
export const ZenProgressBar = (props: ZenProgressBarProps) => {
	const { labels, currentStep, width, marginTop, marginLeft } = props
	const [css] = useStyletron()

	const container = css({
		display: "flex",
		alignItems: "center",
		width: width || "100%",
		marginTop: marginTop || 0,
		marginLeft: marginLeft || 0,
		paddingRight: "10px",
		"@media only screen and (max-width: 900px)": {
			flexWrap: "wrap",
		},
	})
	return (
		<div className={container}>
			{labels.map((l: React.ReactNode, i: number) => (
				<ProgressCard key={i} prefix={`${i + 1}. `} label={l} index={i} currentStep={currentStep} />
			))}
		</div>
	)
}

const ProgressCard = (props: { prefix: string; label: React.ReactNode; index: number; currentStep: number }) => {
	const { prefix, label, index, currentStep } = props
	const [css, theme] = useStyletron()
	const container = css({
		width: "100%",
		display: "flex",
		flexDirection: "column",
	})
	const boldFont: BlockOverrides = {
		Block: {
			style: {
				whiteSpace: "nowrap",
				fontWeight: currentStep === index ? "bold" : 600,
				marginLeft: index === 0 ? "unset" : "10px",
				display: "flex",
				fontSize: "15px",
			},
		},
	}
	return (
		<div className={container}>
			<LabelMedium overrides={boldFont}>
				{prefix}
				{label}
			</LabelMedium>
			<ProgressBar
				overrides={{
					Bar: {
						style: {
							marginTop: "10px",
							marginBottom: 0,
							marginLeft: 0,
							marginRight: 0,
							height: "10px",
							borderTopLeftRadius: "4px",
							borderTopRightRadius: "4px",
							borderBottomLeftRadius: "4px",
							borderBottomRightRadius: "4px",
							backgroundColor: "#E6E6E6",
							border: "2px solid white",
							zIndex: 10 - index,
						},
					},
					BarProgress: {
						style: {
							backgroundColor: theme.colors.primary,
						},
					},
					BarContainer: {
						style: {
							marginLeft: 0,
							marginRight: "-10px",
						},
					},
					Root: {
						style: {
							marginTop: 0,
							marginBottom: 0,
							marginLeft: 0,
							marginRight: 0,
						},
					},
				}}
				value={index <= currentStep ? 100 : 0}
				successValue={100}
			/>
		</div>
	)
}

interface ZenVerticalProgressProps {
	list: LabelLink[]
	step: number
	clientID: string
	intakeType: IntakeType
	formID: string | null
	withNDIS?: boolean
	saveForm: () => void
	incompleteSteps: number[]
	isReadOnly: boolean
}
export const ZenVerticalProgress = (props: ZenVerticalProgressProps) => {
	const { list, step, formID, withNDIS, clientID, intakeType, saveForm, incompleteSteps, isReadOnly } = props
	const [css] = useStyletron()
	const history = useHistory()
	const container = css({
		width: "100%",
		height: "100%",
		backgroundColor: "white",
		display: "flex",
		flexDirection: "column",
	})
	const stepIcon = css({
		cursor: "pointer",
		backgroundColor: ZenTheme.colors.primaryGreen,
		width: "24px",
		height: "24px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "24px",
		position: "absolute",
	})

	return (
		<div className={container}>
			<ProgressSteps current={step}>
				{list.map((s, i) => {
					const url = intakeURLParser(`/portal/clients/${clientID}/intake/${intakeType.toLowerCase()}/${s.link}`, formID, withNDIS)
					return (
						<NumberedStep
							key={i}
							overrides={{
								Root: {
									style: {
										height: "70px",
									},
								},
								Icon: {
									component: () => (
										<div
											onClick={() => history.push(url)}
											className={stepIcon}
											style={{
												backgroundColor: !incompleteSteps.includes(i) ? ZenTheme.colors.primaryGreen : ZenTheme.colors.warning600,
											}}
										>
											<LabelMedium color="white">{i + 1}</LabelMedium>
										</div>
									),
								},
								Tail: {
									style: {
										height: "50px",
										top: "-27px",
										left: "11px",
									},
								},
								Title: {
									component: () => (
										<div onClick={() => history.push(url)} style={{ cursor: "pointer" }}>
											<LabelMedium color={!incompleteSteps.includes(i) ? ZenTheme.colors.black : ZenTheme.colors.warning600}>{s.label}</LabelMedium>
										</div>
									),
								},
							}}
						/>
					)
				})}
			</ProgressSteps>

			<ZenButton onClick={isReadOnly ? () => history.push(`/portal/clients/${clientID}#forms`) : saveForm} altKind={isReadOnly ? "secondary" : "primary"}>
				{isReadOnly ? "Exit" : "Save and Exit"}
			</ZenButton>
		</div>
	)
}
