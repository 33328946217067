import * as React from "react"
import { useStyletron } from "baseui"
import { Divider, SearchAndFilter, ZenCard } from "../../../components/common"
import { useDebounce } from "../../../helpers/utils"
import { Value } from "baseui/select"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { Referrer } from "../../../types/types"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { fetching } from "../../../fetching"
import { ErrorNotification } from "../../../components/errorBox"
import { ListTable } from "../../../components/listTable"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "baseui/button"
import { ZenTheme } from "../../../themeOverrides"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { useForm } from "react-hook-form"
import { LabelLarge, LabelMedium } from "baseui/typography"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { PlaceholderPanel } from "./placeholderPanel"

enum FilterOption {
	Active = "Active",
	Archive = "Archive",
}

export const ReferrerOptionManagement = () => {
	const [css] = useStyletron()
	const outer = css({
		display: "flex",
		flexDirection: "column",
		maxWidth: "calc(min(1280px, 100%))",
		width: "100%",
		height: "100%",
	})
	const [selectedParentReferrer, setSelectedParentReferrer] = React.useState<Referrer>()
	const displaySubReferrer = () => {
		if (!selectedParentReferrer) return <PlaceholderPanel height="50%" text="Please select a referrer to view sub referrers" />
		return <SubReferrers selectedParentReferrer={selectedParentReferrer} />
	}
	return (
		<div className={outer}>
			<Referrers selectedParentReferrer={selectedParentReferrer} setSelectedParentReferrer={setSelectedParentReferrer} />
			<Divider />
			{displaySubReferrer()}
		</div>
	)
}

interface ReferrerProps {
	selectedParentReferrer: Referrer | undefined
	setSelectedParentReferrer: React.Dispatch<React.SetStateAction<Referrer | undefined>>
}
const Referrers = (props: ReferrerProps) => {
	const { selectedParentReferrer, setSelectedParentReferrer } = props
	const [css] = useStyletron()
	const container = css({
		height: "50%",
		flex: "unset !important",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [referrers, setReferrers] = React.useState<Referrer[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, referrerName: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(10)

	const referrerMany = useQuery(
		fetching.query.getReferrerMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
		}),
	)
	React.useEffect(() => {
		if (referrerMany.error || !referrerMany.payload) return
		setTotal(referrerMany.payload.total)
		setReferrers(referrerMany.payload.referrers)
	}, [referrerMany.payload, referrerMany.error])

	const referrerArchive = useMutation(fetching.mutation.referrerArchive)
	const referrerUnarchive = useMutation(fetching.mutation.referrerUnarchive)

	const [targetedReferrerID, setTargetedReferrerID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedReferrer, setSelectedReferrer] = React.useState<Referrer>()

	return (
		<ZenCard className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={setFilter}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Referrer</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{referrerMany.error && <ErrorNotification messageOrPayload={referrerMany.payload} />}
			{referrerArchive.error && <ErrorNotification messageOrPayload={referrerArchive.payload} />}
			{referrerUnarchive.error && <ErrorNotification messageOrPayload={referrerUnarchive.payload} />}
			<ListTable
				isLoading={referrerMany.loading || referrerArchive.loading || referrerUnarchive.loading}
				rows={referrers}
				selectedID={selectedParentReferrer?.id}
				onRowClick={(row: Referrer) => {
					setSelectedParentReferrer(row)
				}}
				columns={[
					{
						id: "referrerName",
						header: "Referrer",
						resolver: (row: Referrer) => row.referrerName,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: Referrer) => {
							const isHighlighted = selectedParentReferrer?.id === row.id
							return (
								<div className={row.deletedAt ? actionButtonHeader : actionButton}>
									{!row.deletedAt && (
										<Button
											kind="minimal"
											onClick={(e) => {
												e.stopPropagation()
												setOpenUpdateModal(true)
												setSelectedReferrer(row)
											}}
										>
											<FontAwesomeIcon color={isHighlighted ? "white" : ZenTheme.colors.primaryGreen} size={"1x"} icon={["fal", "edit"]} />
										</Button>
									)}
									<Button
										kind="minimal"
										onClick={(e) => {
											e.stopPropagation()
											setTargetedReferrerID(row.id)
											if (!row.deletedAt) {
												setOpenArchiveModal(true)
												return
											}
											setOpenUnarchiveModal(true)
										}}
									>
										<FontAwesomeIcon
											color={isHighlighted ? "white" : row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
											size={"1x"}
											icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
										/>
									</Button>
									{targetedReferrerID === row.id && (
										<div onClick={(e) => e.stopPropagation()}>
											{openArchiveModal && (
												<ZenArchiveModal
													open={openArchiveModal}
													loading={referrerArchive.loading || referrerMany.loading}
													message={row.referrerName}
													onClose={() => setOpenArchiveModal(false)}
													confirmArchive={() => {
														referrerArchive.mutate(row.id).then((resp) => {
															if (resp.error || !resp.payload) return
															referrerMany.query()
															setOpenArchiveModal(false)
														})
													}}
												/>
											)}
											{openUnarchiveModal && (
												<ZenArchiveModal
													open={openUnarchiveModal}
													loading={referrerUnarchive.loading || referrerMany.loading}
													message={row.referrerName}
													onClose={() => setOpenUnarchiveModal(false)}
													restoreMode
													confirmArchive={() => {
														referrerUnarchive.mutate(row.id).then((resp) => {
															if (resp.error || !resp.payload) return
															referrerMany.query()
															setOpenUnarchiveModal(false)
														})
													}}
												/>
											)}
										</div>
									)}
								</div>
							)
						},
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedReferrer && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<ReferrerForm
						referrer={selectedReferrer}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedReferrer(undefined)
						}}
						queryReferrers={referrerMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<ReferrerForm
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryReferrers={referrerMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface SubReferrerProps {
	selectedParentReferrer: Referrer
}
const SubReferrers = (props: SubReferrerProps) => {
	const { selectedParentReferrer } = props
	const [css] = useStyletron()
	const container = css({
		height: "50%",
		flex: "unset !important",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [referrers, setReferrers] = React.useState<Referrer[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, referrerName: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(10)

	const referrerMany = useQuery(
		fetching.query.getReferrerMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
			parentID: selectedParentReferrer.id,
		}),
	)
	React.useEffect(() => {
		if (referrerMany.error || !referrerMany.payload) return
		setTotal(referrerMany.payload.total)
		setReferrers(referrerMany.payload.referrers)
	}, [referrerMany.payload, referrerMany.error])

	const referrerArchive = useMutation(fetching.mutation.referrerArchive)
	const referrerUnarchive = useMutation(fetching.mutation.referrerUnarchive)

	const [targetedReferrerID, setTargetedReferrerID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedReferrer, setSelectedReferrer] = React.useState<Referrer>()

	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelLarge>{selectedParentReferrer.referrerName} - Sub Referrers</LabelLarge>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Sub Referrer</ZenButton>}
			</div>
			<SearchAndFilter
				search={displayKey}
				setSearch={setDisplayKey}
				filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
				filter={filter}
				setFilter={setFilter}
			/>
			<Divider style={{ backgroundColor: "transparent" }} />
			{referrerMany.error && <ErrorNotification messageOrPayload={referrerMany.payload} />}
			{referrerArchive.error && <ErrorNotification messageOrPayload={referrerArchive.payload} />}
			{referrerUnarchive.error && <ErrorNotification messageOrPayload={referrerUnarchive.payload} />}
			<ListTable
				isLoading={referrerMany.loading || referrerArchive.loading || referrerUnarchive.loading}
				rows={referrers}
				onRowClick={(row: Referrer) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedReferrerID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedReferrer(row)
				}}
				columns={[
					{
						id: "referrerName",
						header: "Sub Referrer",
						resolver: (row: Referrer) => row.referrerName,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: Referrer) => (
							<div className={row.deletedAt ? actionButtonHeader : actionButton}>
								<Button
									kind="minimal"
									onClick={(e) => {
										e.stopPropagation()
										setTargetedReferrerID(row.id)
										if (!row.deletedAt) {
											setOpenArchiveModal(true)
											return
										}
										setOpenUnarchiveModal(true)
									}}
								>
									<FontAwesomeIcon
										color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
										size={"1x"}
										icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
									/>
								</Button>
								{targetedReferrerID === row.id && (
									<>
										{openArchiveModal && (
											<ZenArchiveModal
												open={openArchiveModal}
												loading={referrerArchive.loading || referrerMany.loading}
												message={row.referrerName}
												onClose={() => setOpenArchiveModal(false)}
												confirmArchive={() => {
													referrerArchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														referrerMany.query()
														setOpenArchiveModal(false)
													})
												}}
											/>
										)}
										{openUnarchiveModal && (
											<ZenArchiveModal
												open={openUnarchiveModal}
												loading={referrerUnarchive.loading || referrerMany.loading}
												message={row.referrerName}
												onClose={() => setOpenUnarchiveModal(false)}
												restoreMode
												confirmArchive={() => {
													referrerUnarchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														referrerMany.query()
														setOpenUnarchiveModal(false)
													})
												}}
											/>
										)}
									</>
								)}
							</div>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedReferrer && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<ReferrerForm
						referrer={selectedReferrer}
						parentID={selectedParentReferrer.id}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedReferrer(undefined)
						}}
						queryReferrers={referrerMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<ReferrerForm
						parentID={selectedParentReferrer.id}
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryReferrers={referrerMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface ReferrerFormProps {
	referrer?: Referrer
	parentID?: string
	onClose: () => void
	queryReferrers: () => Promise<
		QueryResponse<{
			referrers: Referrer[]
			total: number
		}>
	>
}
const ReferrerForm = (props: ReferrerFormProps) => {
	const { referrer, parentID, onClose, queryReferrers } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedReferrer, setDuplicatedReferrer] = React.useState<Referrer>()

	const referrerCreate = useMutation(fetching.mutation.referrerCreate)
	const referrerUpdate = useMutation(fetching.mutation.referrerUpdate)
	const referrerUnarchive = useMutation(fetching.mutation.referrerUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!referrer) return
		setValue("referrerName", referrer.referrerName)
	}, [referrer, setValue])

	const onSubmit = (formData: any) => {
		if (referrer?.referrerName === formData.referrerName) {
			onClose()
			return
		}

		// update existing referrer
		if (referrer) {
			referrerUpdate
				.mutate({
					id: referrer.id,
					parentID,
					referrerName: formData.referrerName,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated referrer
					if (typeof resp.payload === "object") {
						setDuplicatedReferrer(resp.payload)
						return
					}

					queryReferrers()
					onClose()
				})
			return
		}

		// create new referrer
		referrerCreate
			.mutate({
				parentID,
				referrerName: formData.referrerName,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated referrer
				if (typeof resp.payload === "object") {
					setDuplicatedReferrer(resp.payload)
					return
				}

				queryReferrers()
				onClose()
			})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated referrer after submit
		if (duplicatedReferrer && !!duplicatedReferrer.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The{parentID ? " sub " : " "}referrer "{duplicatedReferrer.referrerName}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedReferrer(undefined)}
						saveFn={() =>
							referrerUnarchive.mutate(duplicatedReferrer.id).then((resp) => {
								if (resp.error || !resp.payload) return
								queryReferrers()
								onClose()
							})
						}
						isLoading={referrerUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{referrerUnarchive.error && <ErrorNotification messageOrPayload={referrerUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>
					{!!referrer ? "Update" : "Create"}
					{parentID ? " Sub " : " "}Referrer
				</LabelMedium>
				<ZenInput label="Name" formRef={control} nameRef="referrerName" inputError={errors.referrerName} required />
				<CancelAndSaveButtons cancelFn={onClose} saveLabel={!!referrer ? "Save" : "Submit"} isLoading={referrerCreate.loading || referrerUpdate.loading} />
				{duplicatedReferrer && <ErrorNotification message={`The${parentID ? " sub " : " "}referrer "${duplicatedReferrer.referrerName}" is already exists`} />}
				{referrerCreate.error && <ErrorNotification messageOrPayload={referrerCreate.payload} />}
				{referrerUpdate.error && <ErrorNotification messageOrPayload={referrerUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}
