import * as React from "react"
import { useStyletron } from "baseui"
import { FormList } from "../../components/forms/formList"
import { FormTypeList } from "../../components/forms/formTypeList"
import { Route, Switch } from "react-router-dom"
import K10Form from "../../components/forms/k10"
import WellbeingForm from "../../components/forms/wellbeingPlan/wellbeingPlan"
import HazardRiskIdentificationForm from "../../components/forms/hazardRiskIdentification/hazardRiskIdentification"
import IncidentAccidentReport from "../../components/forms/incidentAccidentReport/incidentAccidentReport"
import { IntakeSelectPage } from "../../components/clients/Intake/intakeSelect"
import { RiskAssessmentVisitation } from "../../components/forms/riskAssessmentVisitation"
import { CarersStarForm } from "../../components/forms/carersStar"
import { YouthStarForm } from "../../components/forms/youthStar"
import { RiskAssessment } from "../../components/forms/riskAssessment"
import { DASS21Form } from "../../components/forms/dass21"
import { SafetyPlanForm } from "../../components/forms/safetyPlan"
import { NotFound } from "../../components/notFound"
import { Responsive, WidthProvider } from "react-grid-layout"
import { routes } from "routes"

const ResponsiveGridLayout = WidthProvider(Responsive)

export default function FormsPage() {
	return (
		<Switch>
			<Route path={routes.forms.k10} component={K10Form} />
			<Route path={routes.forms.wellbeingPlan} component={WellbeingForm} />
			<Route path={routes.forms.hazardRiskIdentification} component={HazardRiskIdentificationForm} />
			<Route path={routes.forms.incidentAndAccidentReport} component={IncidentAccidentReport} />
			<Route path={routes.forms.intake} component={IntakeSelectPage} />
			<Route path={routes.forms.outreachVisitationRiskAssessment} component={RiskAssessmentVisitation} />
			<Route path={routes.forms.riskAssessment} component={RiskAssessment} />
			<Route path={routes.forms.carersStar} component={CarersStarForm} />
			<Route path={routes.forms.youthStar} component={YouthStarForm} />
			<Route path={routes.forms.dass21} component={DASS21Form} />
			<Route path={routes.forms.safetyPlan} component={SafetyPlanForm} />
			<Route exact path={routes.forms.root} component={FormListPage} />
			<Route component={NotFound} />
		</Switch>
	)
}

const FormListPage = () => {
	const [css] = useStyletron()
	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexGrow: 1,
		flexDirection: "column",
		overflowY: "auto",
	})

	const itemCls = css({
		display: "flex",
		overflowY: "auto",
		overflowX: "hidden",
	})

	return (
		<div className={container}>
			<ResponsiveGridLayout measureBeforeMount={true} layouts={layouts} rowHeight={(window.innerHeight - 66) / 25}>
				<div className={itemCls} key={"action-required"}>
					<FormList requiresAction={1} title="Action Required" />
				</div>
				<div className={itemCls} key={"forms"}>
					<FormList requiresAction={2} />
				</div>
				<div className={itemCls} key={"forms-type"}>
					<FormTypeList />
				</div>
			</ResponsiveGridLayout>
		</div>
	)
}

const layouts = {
	lg: [
		{
			w: 8,
			h: 7,
			x: 0,
			y: 0,
			i: "action-required",
			static: true,
		},
		{
			w: 8,
			h: 13,
			x: 0,
			y: 7,
			i: "forms",
			static: true,
		},
		{
			w: 4,
			h: 20,
			x: 8,
			y: 0,
			i: "forms-type",
			static: true,
		},
	],
	md: [
		{
			w: 7,
			h: 6,
			x: 0,
			y: 0,
			i: "action-required",
			static: true,
		},
		{
			w: 7,
			h: 13,
			x: 0,
			y: 6,
			i: "forms",
			static: true,
		},
		{
			w: 3,
			h: 19,
			x: 7,
			y: 0,
			i: "forms-type",
			static: true,
		},
	],

	sm: [
		{
			w: 6,
			h: 9,
			x: 0,
			y: 6,
			i: "action-required",
			static: true,
		},
		{
			w: 6,
			h: 9,
			x: 0,
			y: 15,
			i: "forms",
			static: true,
		},
		{
			w: 6,
			h: 6,
			x: 0,
			y: 0,
			i: "forms-type",
			static: true,
		},
	],
	xs: [
		{
			w: 4,
			h: 5,
			x: 0,
			y: 5,
			i: "action-required",
			static: true,
		},
		{
			w: 4,
			h: 9,
			x: 0,
			y: 10,
			i: "forms",
			static: true,
		},
		{
			w: 4,
			h: 5,
			x: 0,
			y: 0,
			i: "forms-type",
			static: true,
		},
	],
	xxs: [
		{
			w: 2,
			h: 6,
			x: 0,
			y: 6,
			i: "action-required",
			static: true,
		},
		{
			w: 2,
			h: 13,
			x: 0,
			y: 12,
			i: "forms",
			static: true,
		},
		{
			w: 2,
			h: 6,
			x: 0,
			y: 0,
			i: "forms-type",
			static: true,
		},
	],
}
