import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { BlockProps } from "baseui/block"
import { ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal"
import { Textarea } from "baseui/textarea"
import { StatefulTooltip } from "baseui/tooltip"
import { LabelMedium, LabelSmall } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { ZenTheme } from "themeOverrides"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { friendlyTime } from "../../helpers/utils"
import { CallLog, Client, Note, UserDetail } from "../../types/types"
import { CancelAndSaveButtons } from "../cancelSaveButtons"
import { ErrorNotification } from "../errorBox"
import { RevisionListModal } from "../sessions/sessionNoteComponent"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenTextArea } from "../zenComponents/zenInput"

interface LabelFieldProps extends BlockProps {
	label: string
	width?: string
	warningMsg?: string
}
export const LabelField = (props: LabelFieldProps) => {
	const { label, children, width, warningMsg } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		marginTop: "20px",
		width: width || "100%",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
	})
	return (
		<div className={container}>
			<div className={group}>
				<LabelMedium color="rgba(0,0,0,0.3)">{label}</LabelMedium>
				{warningMsg && <LabelSmall marginLeft="3px" color={ZenTheme.colors.negative200}>{` - ${warningMsg}`}</LabelSmall>}
			</div>
			{children}
		</div>
	)
}

interface UserLabelProps {
	data: UserDetail | Client
}
export const UserLabel = (props: UserLabelProps) => {
	const { data } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		alignItems: "center",
		marginTop: "10px",
		marginBottom: "10px",
	})
	return (
		<div className={container}>
			<Avatar
				name={`${data.firstName} ${data.lastName}`}
				size="scale1000"
				overrides={{
					Root: {
						style: {
							minWidth: ZenTheme.sizing.scale1000,
						},
					},
				}}
				src={data.avatarURL || ""}
			/>
			<LabelSmall marginLeft="5px">{`${data.firstName} ${data.lastName}`}</LabelSmall>
		</div>
	)
}

interface CallLogNoteProps {
	callLogID: string
	reFetch: () => Promise<QueryResponse<CallLog>>
}

export const CallLogNotes = (props: CallLogNoteProps) => {
	const { callLogID } = props
	const [css] = useStyletron()
	const { loading, error, payload } = useQuery(fetching.query.callLogNotesGet(callLogID))
	const [currentNotes, setCurrentNotes] = React.useState<Note[]>([])
	const [openModal, setOpenModal] = React.useState<boolean>(false)

	const loadCurrentNotes = React.useCallback(() => {
		if (loading || error || !payload) return
		setCurrentNotes(payload)
	}, [payload, loading, error])
	React.useEffect(() => {
		loadCurrentNotes()
	}, [loadCurrentNotes])
	const container = css({
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
		height: "100%",
	})
	const functionBar = css({
		display: "flex",
		alignItems: "center",
		marginBottom: "5px",
	})
	const addNoteButton = css({
		cursor: "pointer",
	})
	const scrollableDiv = css({
		overflowY: "auto",
		overflowX: "hidden",
		paddingRight: "8px",
		height: "100%",
		maxHeight: "100%",
	})
	return (
		<div className={container}>
			<div className={functionBar}>
				<LabelMedium marginRight="8px" color="rgba(0,0,0,0.3)">
					Note
				</LabelMedium>
				<div className={addNoteButton} onClick={() => setOpenModal(true)}>
					<FontAwesomeIcon icon={["fal", "plus-circle"]} />
				</div>
				<CallLogAddNoteModal callLogID={callLogID} isOpen={openModal} setIsOpen={setOpenModal} onSuccess={() => props.reFetch()} />
			</div>
			<div className={scrollableDiv}>{currentNotes.length > 0 && currentNotes.map((c) => <CallLogNoteCard key={c.id} {...c} />)}</div>
		</div>
	)
}

interface CallLogAddNoteModalProps {
	callLogID: string
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	onSuccess: (n: Note) => void
}
export const CallLogAddNoteModal = (props: CallLogAddNoteModalProps) => {
	const { isOpen, setIsOpen, callLogID, onSuccess } = props
	const [css] = useStyletron()
	const [content, setContent] = React.useState<string>("")
	const { mutate, error, payload, loading } = useMutation<Note>(fetching.mutation.callLogAddNote)

	const clear = () => {
		setContent("")
	}
	const handleOnClose = () => {
		clear()
		setIsOpen(false)
	}
	const handleAddNote = async () => {
		if (content === "") return
		const resp = await mutate({
			callLogID,
			content,
		})
		if (resp.error) return
		if (!resp.payload) return
		onSuccess(resp.payload)
		handleOnClose()
	}
	const container = css({
		display: "flex",
		flexDirection: "column",
	})
	const buttonBar = css({
		marginTop: "8px",
		display: "flex",
		justifyContent: "space-between",
	})
	return (
		<ZenModal
			onClose={handleOnClose}
			isOpen={isOpen}
			role={ROLE.dialog}
			size={SIZE.auto}
			overrides={{
				Dialog: {
					style: {
						width: "80%",
						maxWidth: "1200px",
					},
				},
			}}
		>
			<ModalHeader>Add Note</ModalHeader>
			<ModalBody>
				<div className={container}>
					{error && <ErrorNotification messageOrPayload={payload} />}
					<Textarea value={content} onChange={(e) => setContent(e.currentTarget.value)} />
					<div className={buttonBar}>
						<ZenButton width="80px" onClick={handleOnClose} altKind={"secondary"}>
							Cancel
						</ZenButton>
						<ZenButton width="80px" onClick={handleAddNote} isLoading={loading}>
							Add
						</ZenButton>
					</div>
				</div>
			</ModalBody>
		</ZenModal>
	)
}

export const CallLogNoteCard = (props: Note) => {
	const { revisions, createdAt, id } = props
	const [css] = useStyletron()
	const { control, handleSubmit, setValue, errors } = useForm()
	const [showRevisionList, setShowRevisionList] = React.useState<boolean>(false)
	const [editMode, setEditMode] = React.useState<boolean>(false)
	const [revisionNotes, setRevisionNotes] = React.useState<Note[]>(revisions)
	const noteUpdate = useMutation(fetching.mutation.callLogNoteUpdate)
	const { timezone } = PortalContainer.useContainer()

	const container = css({
		display: "flex",
		flexDirection: "column",
		marginBottom: "3px",
		borderRadius: "3px",
		backgroundColor: "#f8f9fd",
		paddingLeft: "10px",
		paddingRight: "10px",
		paddingBottom: "10px",
	})
	const title = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
	})
	const iconStyle = css({
		marginLeft: "8px",
		cursor: "pointer",
	})

	const onSubmit = async (formData: any) => {
		// do not trigger fetching, if the content is the same
		if (revisionNotes[0].content === formData.content) {
			setEditMode(false)
			return
		}

		const resp = await noteUpdate.mutate({ noteID: id, content: formData.content })
		if (resp.error || !resp.payload) return
		const newList = [...revisionNotes]
		newList.unshift(resp.payload)
		setRevisionNotes(newList)
		setValue("content", formData.content)
		setEditMode(false)
	}

	if (revisionNotes && revisionNotes.length === 0) {
		return null
	}

	return (
		<div className={container}>
			<div className={title}>
				<UserLabel data={revisionNotes[0].poster} />
				{!editMode && (
					<div className={group}>
						<StatefulTooltip content={() => <div>History</div>} placement="top" returnFocus autoFocus>
							<div className={iconStyle} onClick={() => setShowRevisionList(true)}>
								<FontAwesomeIcon icon={["fal", "history"]} />
							</div>
						</StatefulTooltip>
						<div className={iconStyle} onClick={() => setEditMode(true)}>
							<FontAwesomeIcon icon={["fal", "edit"]} />
						</div>
					</div>
				)}
			</div>
			{!editMode ? (
				<>
					<LabelSmall
						overrides={{
							Block: {
								style: {
									overflowWrap: "break-word",
									whiteSpace: "pre-line",
								},
							},
						}}
						padding="5px"
					>
						{revisionNotes[0].content}
					</LabelSmall>
					<LabelSmall
						overrides={{
							Block: {
								style: {
									display: "flex",
									justifyContent: "flex-end",
								},
							},
						}}
					>
						{friendlyTime(timezone, createdAt)}
					</LabelSmall>
				</>
			) : (
				<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					<ZenTextArea
						formRef={control}
						nameRef="content"
						value={revisionNotes[0].content}
						formRules={{ required: "content is required" }}
						inputError={errors.content}
					/>
					{noteUpdate.error && <ErrorNotification messageOrPayload={noteUpdate.payload} />}
					<CancelAndSaveButtons
						cancelFn={() => {
							setValue("content", revisionNotes[0].content)
							setEditMode(false)
						}}
						isLoading={noteUpdate.loading}
					/>
				</form>
			)}
			<RevisionListModal isOpen={showRevisionList} setIsOpen={setShowRevisionList} revisions={revisionNotes} />
		</div>
	)
}
