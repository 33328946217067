import * as React from "react"
import { useStyletron } from "baseui"
import { LabelSmall } from "baseui/typography"
import { ZenTheme } from "../../themeOverrides"
import { capitalize } from "../../helpers/utils"
import { Divider } from "../common"

interface ErrorFieldTrackerProps {
	errorIDs: string[]
	position?: "end" | "start" | "center" | "nearest"
}
export const ErrorFieldTracker = (props: ErrorFieldTrackerProps) => {
	const { errorIDs, position } = props
	const [css] = useStyletron()
	const goToElement = (id: string) => {
		const scrollToElement = document.getElementById(id)

		if (!scrollToElement) return
		scrollToElement.scrollIntoView({ behavior: "smooth", block: position || "center", inline: "nearest" })
	}

	const errorFieldsContainer = css({
		display: "flex",
		flexDirection: "column",
		backgroundColor: ZenTheme.colors.negative100,
		width: "100%",
		borderRadius: "6px",
		padding: "6px 12px",
	})

	const errorFields = css({
		display: "flex",
		flexWrap: "wrap",
	})

	return (
		<>
			{errorIDs.length > 0 && (
				<>
					<Divider />
					<div className={errorFieldsContainer}>
						<LabelSmall color={ZenTheme.colors.negative300}>Please complete following fields: (Click on a field to hop to its location)</LabelSmall>
						<div className={errorFields}>
							{errorIDs.map((id, i) => {
								const words = id.split(/[-.]/g)[0].match(/[A-Z][a-z]+|[a-z]+/g) || []
								const label = capitalize(words.join(" "))

								return (
									<React.Fragment key={i}>
										{i > 0 && (
											<LabelSmall marginRight="4px" color={ZenTheme.colors.negative400}>
												,
											</LabelSmall>
										)}
										<LabelSmall
											onClick={() => goToElement(id)}
											overrides={{
												Block: {
													style: {
														cursor: "pointer",
														color: ZenTheme.colors.negative400,
													},
												},
											}}
										>
											{label}
										</LabelSmall>
									</React.Fragment>
								)
							})}
						</div>
					</div>
				</>
			)}
		</>
	)
}
