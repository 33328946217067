import * as React from "react"
import { useStyletron } from "baseui"
import { CheckboxOverrides } from "baseui/checkbox"
import { LabelLarge, LabelSmall } from "baseui/typography"
import { useFormContext } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { ZenTheme } from "../../../../themeOverrides"
import { IntakeType } from "../../../../types/enums"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { ZenCheckbox } from "../../../zenComponents/zenCheckboxList"
import { ZenTextArea } from "../../../zenComponents/zenInput"
import { intakeURLParser } from "../adult/intakeAdultBaseForm"
import { IntakeScrollDiv } from "../intakeComponent"
import { CommonIntakeFormData } from "./detail"
import { ErrorFieldTracker } from "../../../forms/errorFieldTracker"

export const ChallengesForClient = (props: CommonIntakeFormData) => {
	const { clientID, intakeType, formID, withNDIS, isReadOnly, validationTriggered } = props
	const [css] = useStyletron()
	const history = useHistory()

	const { watch, trigger, control, errors, getValues } = useFormContext()

	const hasLearningCognitiveDifficulties = watch("hasLearningCognitiveDifficulties")
	const hasMentalHealthConcerns = watch("hasMentalHealthConcerns")
	const hasPhysicalDifficulties = watch("hasPhysicalDifficulties")
	const hasAccessibilityRequirements = watch("hasAccessibilityRequirements")
	const hasRiskOfFalls = watch("hasRiskOfFalls")
	const hasAllergies = watch("hasAllergies")
	const hasMedicalHistory = watch("hasMedicalHistory")
	const hasPalliativeSupportCare = watch("hasPalliativeSupportCare")

	const validation = React.useCallback(async () => {
		const checkList: string[] = []
		// generate checkList
		if (hasLearningCognitiveDifficulties) checkList.push("learningCognitiveDifficulties")
		if (hasMentalHealthConcerns) checkList.push("mentalHealthConcerns")
		if (hasPhysicalDifficulties) checkList.push("physicalDifficulties")
		if (hasAccessibilityRequirements) checkList.push("accessibilityRequirements")
		if (hasRiskOfFalls) checkList.push("riskOfFalls")
		if (hasAllergies) checkList.push("allergies")
		if (hasMedicalHistory) checkList.push("medicalHistory")
		if (hasPalliativeSupportCare) checkList.push("palliativeSupportCare")

		const isValid = await trigger(checkList)
		return isValid
	}, [
		//
		hasMentalHealthConcerns,
		hasPalliativeSupportCare,
		hasLearningCognitiveDifficulties,
		hasMedicalHistory,
		hasAccessibilityRequirements,
		hasAllergies,
		hasPhysicalDifficulties,
		hasRiskOfFalls,
		trigger,
	])

	React.useEffect(() => {
		if (validationTriggered) validation()
	}, [validationTriggered, validation])

	const onSubmit = async () => {
		const isValid = await validation()
		if (!isValid) return
		nextPage()
	}

	const nextPage = () => {
		switch (intakeType) {
			case IntakeType.Adult:
				// for ndis version
				if (withNDIS) {
					history.push(intakeURLParser(`/portal/clients/${clientID}/intake/ndis/serviceDetail`, formID, true))
					break
				}
				history.push(intakeURLParser(`/portal/clients/${clientID}/intake/adult/clientContext`, formID))
				break
			case IntakeType.Youth:
				history.push(intakeURLParser(`/portal/clients/${clientID}/intake/youth/mentalHealthContext`, formID))
				break
		}
	}

	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "white",
		flexDirection: "row",
	})
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
		paddingTop: "25px",
	})
	const body = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})

	const checkBoxStyle: CheckboxOverrides = {
		Root: {
			style: {
				marginTop: "15px",
			},
		},
	}
	return (
		<div className={container}>
			<form autoComplete="off" className={formStyle}>
				<div className={body}>
					<LabelLarge>Challenges for Client</LabelLarge>
					<IntakeScrollDiv>
						<ZenCheckbox
							label="Learning / Cognitive Difficulties"
							formName="hasLearningCognitiveDifficulties"
							formRef={control}
							disabled={isReadOnly}
							overrides={checkBoxStyle}
						/>
						{hasLearningCognitiveDifficulties && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Please list any relevant information"
								nameRef="learningCognitiveDifficulties"
								formRef={control}
								formRules={{ required: "Learning / Cognitive Difficulties are required" }}
								inputError={errors.learningCognitiveDifficulties}
								placeholder="Enter Learning / Cognitive Difficulties"
								initialHeight={180}
								getValues={getValues}
							/>
						)}

						<ZenCheckbox label="Mental Health Concerns" formName="hasMentalHealthConcerns" formRef={control} disabled={isReadOnly} overrides={checkBoxStyle} />
						{hasMentalHealthConcerns && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Please list any relevant information"
								nameRef="mentalHealthConcerns"
								formRef={control}
								formRules={{ required: "mental health concerns are required" }}
								inputError={errors.mentalHealthConcerns}
								placeholder="Enter mental health concerns"
								initialHeight={180}
								getValues={getValues}
							/>
						)}

						<ZenCheckbox label="Physical Difficulties" disabled={isReadOnly} formRef={control} formName="hasPhysicalDifficulties" overrides={checkBoxStyle} />
						{hasPhysicalDifficulties && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Please list any relevant information"
								nameRef="physicalDifficulties"
								formRef={control}
								formRules={{ required: "physical difficulties are required" }}
								inputError={errors.physicalDifficulties}
								placeholder="Enter physical difficulties"
								initialHeight={180}
								getValues={getValues}
							/>
						)}

						<ZenCheckbox
							disabled={isReadOnly}
							label="Accessibility Requirements"
							formRef={control}
							formName={"hasAccessibilityRequirements"}
							overrides={checkBoxStyle}
						/>
						{hasAccessibilityRequirements && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Please list any relevant information"
								nameRef="accessibilityRequirements"
								formRef={control}
								formRules={{ required: "accessibility requirements are required" }}
								inputError={errors.accessibilityRequirements}
								placeholder="Eg. Wheelchair access, mobility issues, hearing and/or visual aid"
								initialHeight={180}
								getValues={getValues}
							/>
						)}

						<ZenCheckbox label="Risk of Falls" disabled={isReadOnly} formRef={control} formName="hasRiskOfFalls" overrides={checkBoxStyle} />
						{hasRiskOfFalls && (
							<>
								<LabelSmall color={ZenTheme.colors.primaryGrey}>Information provided to client regarding falls prevention and supports available</LabelSmall>
								<ZenTextArea
									disabled={isReadOnly}
									label=""
									nameRef="riskOfFalls"
									formRef={control}
									formRules={{ required: "most recent fall is required" }}
									inputError={errors.riskOfFalls}
									placeholder="Eg. 2 months"
									initialHeight={180}
									getValues={getValues}
								/>
							</>
						)}

						<ZenCheckbox label="Do you have any allergies?" formRef={control} disabled={isReadOnly} formName="hasAllergies" overrides={checkBoxStyle} />
						{hasAllergies && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Please provide allergen and reaction (incl. medications)"
								nameRef="allergies"
								formRef={control}
								formRules={{ required: "allergies detail is required" }}
								inputError={errors.allergies}
								placeholder="Enter allergen, reaction and medications"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
						<ZenCheckbox
							label="Do you have any other medical history we should be aware of?"
							formName="hasMedicalHistory"
							formRef={control}
							disabled={isReadOnly}
							overrides={checkBoxStyle}
						/>
						{hasMedicalHistory && (
							<ZenTextArea
								disabled={isReadOnly}
								label=""
								nameRef="medicalHistory"
								formRef={control}
								formRules={{ required: "medical history is required" }}
								inputError={errors.medicalHistory}
								placeholder="Eg. other diagnosed medical conditions including transmittable and infectious conditions"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
						<ZenCheckbox
							label="Do you or someone you're caring for require palliative support care?"
							formRef={control}
							disabled={isReadOnly}
							formName="hasPalliativeSupportCare"
							overrides={checkBoxStyle}
						/>
						{hasPalliativeSupportCare && (
							<ZenTextArea
								disabled={isReadOnly}
								label=""
								nameRef="palliativeSupportCare"
								formRef={control}
								formRules={{ required: "palliative support detail is required" }}
								inputError={errors.palliativeSupportCare}
								placeholder="If yes, refer on to Palliative Care WA(1800 573 299) or NT(08 8951 6762)"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
					</IntakeScrollDiv>
				</div>
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				{!isReadOnly && <CancelAndSaveButtons cancelLabel="Back" cancelFn={() => history.goBack()} saveLabel="Next" saveFn={onSubmit} />}
			</form>
		</div>
	)
}
