import * as React from "react"
import { useStyletron } from "baseui"
import { DisplayXSmall } from "baseui/typography"
import { useFormContext } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { StarChartLabel } from "../../../../types/enums"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { CarersStar } from "../../../forms/carersStar"
import { IntakeScrollDiv } from "../intakeComponent"
import { IntakeAdultProps, intakeURLParser } from "./intakeAdultBaseForm"
import { ErrorFieldTracker } from "../../../forms/errorFieldTracker"

interface IntakeAdultCarerStarProps extends IntakeAdultProps {
	starSpike: {
		label: StarChartLabel
		value: number
	}[]
	setStarSpike: React.Dispatch<
		React.SetStateAction<
			{
				label: StarChartLabel
				value: number
			}[]
		>
	>
	actionPlanPlaceholder: number[]
	setActionPlanPlaceholder: React.Dispatch<React.SetStateAction<number[]>>
	actionPlanNextIndex: number
	setActionPlanNextIndex: React.Dispatch<React.SetStateAction<number>>
}

export const IntakeAdultCarerStar = (props: IntakeAdultCarerStarProps) => {
	const {
		clientID,
		formID,
		isReadOnly,
		starSpike,
		validationTriggered,
		setStarSpike,
		actionPlanPlaceholder,
		setActionPlanPlaceholder,
		actionPlanNextIndex,
		setActionPlanNextIndex,
	} = props
	const history = useHistory()
	const [css] = useStyletron()

	const { trigger, errors } = useFormContext()

	const selectStarDegree = (label: StarChartLabel, value: number) => {
		if (isReadOnly) return
		setStarSpike(
			starSpike.map((st) => {
				// if not match, return original data
				if (st.label !== label) return st

				// otherwise, return new value
				return { label, value }
			}),
		)
	}

	const carerStarValidationTrigger = React.useCallback(() => {
		if (validationTriggered) trigger(["carerStarWorker", "carerStarClient", "dateOfCompletion"])
	}, [validationTriggered, trigger])
	React.useEffect(() => {
		carerStarValidationTrigger()
	}, [carerStarValidationTrigger])

	const onSubmit = async () => {
		const isValid = await trigger(["carerStarWorker", "carerStarClient", "dateOfCompletion"])
		if (!isValid) return
		history.push(intakeURLParser(`/portal/clients/${clientID}/intake/adult/supports`, formID))
	}
	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "white",
		flexDirection: "row",
	})
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
		paddingTop: "25px",
	})
	const body = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})
	return (
		<div className={container}>
			<form autoComplete="off" className={formStyle}>
				<div className={body}>
					<DisplayXSmall
						marginTop="8px"
						marginBottom="8px"
						color="#717A9D"
						overrides={{
							Block: {
								style: {
									fontWeight: "bold",
								},
							},
						}}
					>
						Carers Star
					</DisplayXSmall>
					<IntakeScrollDiv style={{ maxHeight: "100%" }}>
						<CarersStar
							starSpike={starSpike}
							selectStarDegree={selectStarDegree}
							actionPlanPlaceholder={actionPlanPlaceholder}
							setActionPlanPlaceholder={setActionPlanPlaceholder}
							actionPlanNextIndex={actionPlanNextIndex}
							setActionPlanNextIndex={setActionPlanNextIndex}
							starScale={0.8}
							isIntake
							isReadOnly={isReadOnly}
						/>
					</IntakeScrollDiv>
				</div>
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				{!isReadOnly && <CancelAndSaveButtons cancelLabel="Back" cancelFn={() => history.goBack()} saveLabel="Next" saveFn={onSubmit} />}
			</form>
		</div>
	)
}
