import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Select, SelectProps, Value } from "baseui/select"
import { LabelSmall } from "baseui/typography"
import React from "react"
import { Controller } from "react-hook-form"
import { ZenTheme } from "themeOverrides"

interface ZenSelectProps extends SelectProps {
	label?: string
	formName: string
	formRef: any
	formRules?: any
	inputError?: any
	getValues?: () => any
	actionOnChange?: (value: Value) => void
	rootHight?: string
	paddingBottom?: string
}

export const ZenSelect = (props: ZenSelectProps) => {
	const [css, theme] = useStyletron()
	const { label, formName, formRef, formRules, inputError, actionOnChange, value, getValues, disabled, rootHight, paddingBottom, multi, ...rest } = props

	const displayMessage = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "fit-content",
		marginBottom: "8px",
	})
	if (disabled && getValues && getValues()[`${formName}`])
		return (
			<div className={displayMessage}>
				{label ? <LabelSmall marginBottom="2px">{label}</LabelSmall> : ""}
				<LabelSmall
					marginLeft="16px"
					overrides={{
						Block: {
							style: {
								whiteSpace: "pre-line",
								color: ZenTheme.colors.primaryGrey,
							},
						},
					}}
				>
					{getValues()[`${formName}`].length > 0 ? getValues()[`${formName}`][0].label : ""}
				</LabelSmall>
			</div>
		)
	return (
		<FormControl
			label={<LabelSmall>{label}</LabelSmall>}
			error={inputError?.message || ""}
			overrides={{
				ControlContainer: {
					style: {
						marginBottom: 0,
					},
				},
			}}
		>
			<Controller
				name={formName}
				control={formRef}
				defaultValue={value || []}
				rules={formRules}
				render={({ value, name, onChange }) => (
					<Select
						{...rest}
						multi={multi}
						id={name}
						disabled={disabled}
						onChange={({ value }) => {
							if (actionOnChange) actionOnChange(value)
							onChange(value)
						}}
						value={value || []}
						overrides={{
							Root: {
								style: {
									height: rootHight,
								},
							},
							ControlContainer: {
								style: ({ $disabled }) => ({
									backgroundColor: "transparent",
									borderLeftWidth: 0,
									borderTopWidth: 0,
									borderBottomWidth: $disabled ? 0 : "2px",
									borderBottomColor: theme.colors.black,
									borderRightWidth: 0,
								}),
							},
							ValueContainer: {
								style: {
									paddingBottom: paddingBottom ? paddingBottom : rootHight ? 0 : undefined,
								},
							},
							Dropdown: {
								style: {
									maxHeight: "calc(min(200px, 60vh))",
								},
							},
						}}
					/>
				)
				}
			/>
		</FormControl>
	)
}