import * as React from "react"
import { useStyletron } from "baseui"
import { Value } from "baseui/select"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { Prompt, useHistory } from "react-router-dom"

import { AuthContainer } from "../../../controllers/auth"
import { fetching } from "../../../fetching"
import { alignDate, setTimezoneToTime } from "../../../helpers/utils"
import { AddedWorker, Client, ClientDetail, ContractArea, FundingSource, SubSupportType, SupportType, Timezone } from "../../../types/types"
import { ZenCard } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { useZenToast } from "../../zenComponents/useZenToast"
import { ZenProgressBar } from "../../zenComponents/zenProgressBar"
import { GroupSessionAppointmentForm } from "./groupSessionAppointmentForm"
import { GroupSessionAttendeeInfoForm } from "./groupSessionAttendeeInfoForm"
import { GroupSessionConfirmation } from "./groupSessionConfirmation"
import { GroupSessionGeneralForm } from "./groupSessionGeneralForm"
import { GroupSessionNotesForm } from "./groupSessionNotesForm"
import { GroupSessionWorkerDetailForm } from "./groupSessionWorkerDetailForm"
import { routes } from "routes"

const NewGroupAppointmentStep = ["General", "Appointment", "Worker Detail", "Attendee Info", "Group Notes"]

export interface GroupAppointmentFormData {
	worker?: Value
	organisation?: Value
	appointmentDate?: Date
	timezone?: Timezone[]
	startTime?: Date
	endTime?: Date
	sessionAddress?: Value
	sessionFundingSources?: SessionFundingSourceInput[]
	additionalWorkers?: AddedWorker[]
	attendees?: Client[]
	attendeeNumber?: number // total number of attendees
	ATslNumber?: number
	CaLDNumber?: number
	yearGroup?: string
	programDelivered?: Value
	clientType?: Value
	notes?: string
	// data cached locally
	fontSize?: number
}

export interface SessionFundingSourceInput {
	fundingSource: FundingSource[]
	contractArea?: ContractArea[]
	supportType?: SupportType[]
	subSupportType?: SubSupportType[]
}

export interface GroupAppointmentFormProps {
	data: GroupAppointmentFormData
	setData: React.Dispatch<React.SetStateAction<GroupAppointmentFormData>>
	changePage: (index: -1 | 1) => void
	setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>
	loading?: boolean
}

export const GroupSessionBaseForm = () => {
	const [css] = useStyletron()
	const history = useHistory()
	const searchArgs = React.useMemo(() => {
		return new URLSearchParams(history.location.search)
	}, [history.location.search])
	const { showToast } = useZenToast()
	const { currentUser } = AuthContainer.useContainer()
	const [step, setStep] = React.useState<number>(0)
	const [data, setData] = React.useState<GroupAppointmentFormData>({})
	const { mutate, error, payload, loading } = useMutation(fetching.mutation.sessionGroupCreate)
	const { payload: clientData, query } = useParameterizedQuery<ClientDetail>(fetching.query.getClient)
	const [isDirty, setIsDirty] = React.useState(false)

	React.useEffect(() => {
		const clientID = searchArgs.get("client_id")
		if (!clientID || clientID === "") return
		query(clientID)
	}, [query, searchArgs])

	React.useEffect(() => {
		if (!clientData) return
		setData((data: GroupAppointmentFormData) => {
			return {
				...data,
				organisation: [{ ...clientData, label: `${clientData.firstName} ${clientData.lastName}` }],
				sessionAddress: clientData.currentContact?.residentialAddress
					? [{ id: clientData.currentContact?.residentialAddress.placeID, label: clientData.currentContact?.residentialAddress.fullAddress }]
					: [],
			}
		})
	}, [clientData])

	React.useEffect(() => {
		if (!currentUser) return
		setData((d) => ({ ...d, worker: [{ ...currentUser, label: `${currentUser.firstName} ${currentUser.lastName}` }] }))
	}, [currentUser])
	// functions
	const onPageChange = async (index: -1 | 1) => {
		const nextPage = step + index
		if (nextPage < 0) {
			// return back to client session tab, if client id exists
			const returnClientID = searchArgs.get("client_id")
			if (returnClientID) {
				history.push({
					pathname: routes.withID(returnClientID, routes.clients.client.root),
					hash: "sessions",
				})
				return
			}

			// otherwise, redirect to session list page
			history.push(routes.sessions.root)
			return
		}
		if (nextPage > 5) {
			setStep(5)

			if (isNaN(Number(data.attendees?.length)) || isNaN(Number(data.ATslNumber)) || isNaN(Number(data.CaLDNumber))) {
				setStep(3)
				return
			}

			if (!data.worker || data.worker.length === 0 || !data.worker[0].id) return
			if (!data.organisation || data.organisation.length === 0 || !data.organisation[0].id) return
			if (!data.sessionAddress || data.sessionAddress.length === 0 || !data.sessionAddress[0].label) return
			if (!data.sessionFundingSources || data.sessionFundingSources.length === 0) return

			let timezoneID = ""
			let startTime = alignDate(data.appointmentDate, data.startTime)
			let endTime = alignDate(data.appointmentDate, data.endTime)
			if (data.timezone && data.timezone.length > 0) {
				startTime = setTimezoneToTime(startTime, data.timezone[0].id)
				endTime = setTimezoneToTime(endTime, data.timezone[0].id)
				timezoneID = data.timezone[0].id
			}

			const resp = await mutate({
				workerID: data.worker[0].id?.toString(),
				organisationID: data.organisation[0].id.toString(),
				timezoneID,
				startTime,
				endTime,
				sessionLocation: data.sessionAddress[0].label.toString(),
				sessionFundingSourcesInput: data.sessionFundingSources.map((sfs) => ({
					fundingSourceID: sfs.fundingSource[0].id,
					contractAreaID: sfs.contractArea && sfs.contractArea.length > 0 ? sfs.contractArea[0].id : undefined,
					supportTypeID: sfs.supportType && sfs.supportType.length > 0 ? sfs.supportType[0].id : undefined,
					subSupportTypeID: sfs.subSupportType && sfs.subSupportType.length > 0 ? sfs.subSupportType[0].id : undefined,
				})),
				additionalWorkers: data.additionalWorkers?.map((w) => ({ workerID: w.worker.id, claimHour: w.claimHour })),
				attendeeNumber: Number(data.attendeeNumber),
				ATslNumber: Number(data.ATslNumber),
				CaLDNumber: Number(data.CaLDNumber),
				yearGroup: data.yearGroup,
				programDeliveredID: data.programDelivered && data.programDelivered.length > 0 ? data.programDelivered[0].id?.toString() : undefined,
				notes: data.notes,
				attendeeIDs: data.attendees?.map((a) => a.id) || [],
				clientType: data.clientType && data.clientType.length > 0 ? data.clientType[0].id?.toString() : undefined,
			})

			if (resp.error || !resp.payload) {
				showToast("Failed to create group session.", "negative")
				return
			}
			if (resp.payload) {
				showToast("Group Session created successfully.", "positive")
				setIsDirty(false)

				// return back to client session tab, if client id exists
				const returnClientID = searchArgs.get("client_id")
				if (returnClientID) {
					history.push({
						pathname: routes.withID(returnClientID, routes.clients.client.root),
						hash: "sessions",
					})
					return
				}

				// otherwise redirect to session list
				history.push(routes.sessions.root)
			}

			return
		}
		setStep(nextPage)
	}

	if (error) return <ErrorNotification messageOrPayload={payload} />

	const displayForm = (): React.ReactNode => {
		switch (step) {
			case 0:
				return <GroupSessionGeneralForm data={data} setData={setData} changePage={onPageChange} setIsDirty={setIsDirty} />
			case 1:
				return <GroupSessionAppointmentForm data={data} setData={setData} changePage={onPageChange} />
			case 2:
				return <GroupSessionWorkerDetailForm data={data} setData={setData} changePage={onPageChange} />
			case 3:
				return <GroupSessionAttendeeInfoForm data={data} setData={setData} changePage={onPageChange} />
			case 4:
				return <GroupSessionNotesForm data={data} setData={setData} changePage={onPageChange} />
			case 5:
				return <GroupSessionConfirmation data={data} setData={setData} changePage={onPageChange} loading={loading} />
			default:
				return null
		}
	}

	// css
	const container = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
		padding: "15px",
		overflowY: "auto",
	})
	const form = css({
		display: "flex",
		maxHeight: "95%",
		height: "100%",
		padding: "15px",
		justifyContent: "center",
		overflowY: "auto",
	})

	return (
		<ZenCard className={container}>
			<ZenProgressBar labels={NewGroupAppointmentStep} currentStep={step} />
			<div className={form}>{displayForm()}</div>
			<Prompt
				when={isDirty}
				message={(location) => {
					if (location.pathname.startsWith("/portal/sessions/create_group_session")) return true
					return "You have unsaved changes, are you sure you want to leave?"
				}}
			/>
		</ZenCard>
	)
}
