import { Layout } from "react-grid-layout"
import { storeKey } from "../layout"

export const storageKey = (breakpoint: string) => `${storeKey}-supervisor-${breakpoint}-v3`

export const panels = ["worker-session", "assigned"]

const xxs: Layout[] = [
	{ w: 2, h: 10, x: 0, y: 0, i: "worker-session", minW: 2, minH: 4, moved: false, static: true },
	{ w: 2, h: 10, x: 0, y: 9, i: "assigned", minW: 2, minH: 4, moved: false, static: true },
]

const xs: Layout[] = [
	{ w: 4, h: 10, x: 0, y: 9, i: "assigned", minW: 4, minH: 4, moved: false, static: true },
	{ w: 4, h: 10, x: 0, y: 0, i: "worker-session", minW: 4, minH: 4, moved: false, static: true },
]

const sm: Layout[] = [
	{ w: 4, h: 10, x: 0, y: 0, i: "worker-session", minW: 6, minH: 4, moved: false, static: true },
	{ w: 4, h: 10, x: 0, y: 12, i: "assigned", minW: 6, minH: 4, moved: false, static: true },
]

const md: Layout[] = [
	{ w: 3, h: 20, x: 0, y: 0, i: "worker-session", minW: 3, minH: 3, moved: false, static: true },
	{ w: 3, h: 20, x: 3, y: 0, i: "assigned", moved: false, static: true },
]
const lg: Layout[] = [
	{ w: 4, h: 20, x: 0, y: 0, i: "worker-session", minW: 4, minH: 4, moved: false, static: true },
	{ w: 3, h: 20, x: 4, y: 0, i: "assigned", moved: false, static: true },
]

export const layouts = { xxs, xs, sm, md, lg }
