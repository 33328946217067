import * as React from "react"
import { useStyletron } from "baseui"
import { DatePicker } from "baseui/datepicker"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Value } from "baseui/select"
import { Textarea } from "baseui/textarea"
import { LabelLarge, ParagraphSmall } from "baseui/typography"
import { Controller, FieldError, useFormContext } from "react-hook-form"
import { ZenDatepickerOverrides, ZenInputBottomOverrides, ZenInputOverrides } from "../../../themeOverrides"
import { ZenButton } from "../../zenComponents/zenButtons"
import { ZenClientSelect, ZenUserSelect } from "../../zenComponents/zenSelectBox"
import { WellbeingFormData } from "./wellbeingPlan"

interface WellbeingNDISPlanProps {
	disabled?: boolean
	fieldCount?: number
}

export const WellbeingNDISPlan = (props: WellbeingNDISPlanProps) => {
	const { disabled, fieldCount } = props
	const { control, errors } = useFormContext<WellbeingFormData>()

	// Number of entries
	const [count, setCount] = React.useState(1)
	React.useEffect(() => {
		if (fieldCount) setCount(fieldCount)
	}, [fieldCount])

	// Styling
	const [css, theme] = useStyletron()
	const titleStyle = css({
		display: "flex",
		alignItems: "center",
	})
	const optionalTextStyle = css({
		fontSize: "18px",
		fontWeight: 600,
		color: theme.colors.contentTertiary,
		marginLeft: "10px",
	})
	const signStyle = css({
		display: "flex",
		marginTop: "20px",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
		},
	})
	const signatureStyle = css({
		flex: 1,
		marginLeft: "30px",
		marginRight: "30px",
		"@media only screen and (max-width: 700px)": {
			margin: 0,
		},
	})
	const dateStyle = css({
		flex: 1,
	})

	return (
		<div>
			<div className={titleStyle}>
				<LabelLarge $style={{ fontWeight: "bold" }}>Their NDIS Plan</LabelLarge>
				<div className={optionalTextStyle}>(only required for NDIS & WANDIS clients)</div>
			</div>
			<ParagraphSmall>Goals and hours allocated are to be taken directly from their plan.</ParagraphSmall>

			{Array.from(Array(count)).map((_, index) => (
				<NDISGoal key={`actionPlan-${index}`} index={index} disabled={disabled} />
			))}

			{!disabled && (
				<ZenButton type="button" onClick={() => setCount(count + 1)}>
					Add Another Goal
				</ZenButton>
			)}

			<div className={signStyle}>
				<ZenClientSelect
					label="Client"
					formRef={control}
					formName="client"
					disabled={disabled}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Client is required",
						},
					}}
					inputError={errors.client as FieldError | undefined}
				/>

				<div className={signatureStyle}>
					<FormControl label="Client Signature" disabled={disabled}>
						<Controller
							name={`ndisPlan.clientSignature`}
							control={control}
							as={<Input data-lpignore="true" autoComplete="off" overrides={ZenInputBottomOverrides} />}
						/>
					</FormControl>
				</div>

				<div className={dateStyle}>
					<FormControl label="Date">
						<Controller
							name={`ndisPlan.clientSignatureDate`}
							control={control}
							render={({ value, onChange }) => (
								<DatePicker
									formatString={"dd/MM/yyyy"}
									onChange={({ date }) => onChange(date)}
									value={value ? new Date(value) : new Date()}
									disabled={disabled}
									overrides={ZenDatepickerOverrides}
								/>
							)}
						/>
					</FormControl>
				</div>
			</div>
			<div className={signStyle}>
				<ZenUserSelect
					label="Worker"
					disabled={disabled}
					formName="worker"
					formRef={control}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
						},
					}}
					inputError={errors.worker as FieldError | undefined}
				/>

				<div className={signatureStyle}>
					<FormControl label="Worker Signature">
						<Controller
							name={`ndisPlan.workerSignature`}
							control={control}
							disabled={disabled}
							as={<Input data-lpignore="true" autoComplete="off" overrides={ZenInputBottomOverrides} />}
						/>
					</FormControl>
				</div>

				<div className={dateStyle}>
					<FormControl label="Date">
						<Controller
							name={`ndisPlan.workerSignatureDate`}
							control={control}
							render={({ value, onChange }) => (
								<DatePicker
									formatString={"dd/MM/yyyy"}
									onChange={({ date }) => onChange(date)}
									value={value ? new Date(value) : new Date()}
									disabled={disabled}
									overrides={ZenDatepickerOverrides}
								/>
							)}
						/>
					</FormControl>
				</div>
			</div>
		</div>
	)
}

interface NDISGoalProps {
	index: number
	disabled?: boolean
}

const NDISGoal = (props: NDISGoalProps) => {
	const { index, disabled } = props
	const { control, errors } = useFormContext()
	// Styling
	const [css, theme] = useStyletron()
	const containerStyle = css({
		padding: "12px",
		borderRadius: "3px",
		backgroundColor: theme.colors.backgroundSecondary,
		marginBottom: "12px",
		boxShadow: theme.lighting.shadow400,
	})
	const helpStyle = css({
		color: "#BEBEBE",
		marginLeft: "7px",
	})
	const rowStyle = css({
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "10px",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
			justifyContent: "initial",
		},
	})
	const textAreaInputStyle = css({
		flex: "1",
		marginLeft: "10px",
	})

	return (
		<div className={containerStyle}>
			<div className={rowStyle}>
				<div className={textAreaInputStyle}>
					<FormControl
						label={
							<span>
								Their goal<span className={helpStyle}>What they would like to happen</span>
							</span>
						}
					>
						<Controller
							name={`ndisPlan.goals[${index}].goal`}
							control={control}
							as={Textarea}
							placeholder="Enter goal"
							disabled={disabled}
							overrides={ZenInputOverrides}
						/>
					</FormControl>
				</div>
				<div className={textAreaInputStyle}>
					<FormControl label="Hours Allocated" error={errors[`ndisPlan.goals[${index}].hoursAllocated`]?.message}>
						<Controller
							name={`ndisPlan.goals[${index}].hoursAllocated`}
							control={control}
							defaultValue={0}
							disabled={disabled}
							rules={{
								validate: {
									GreaterThanOrEqualToZero: (value: string) => parseInt(value) >= 0 || "hours should greater than or equal to zero",
								},
							}}
							render={({ onChange, value }) => {
								return (
									<Input
										data-lpignore="true"
										autoComplete="off"
										overrides={ZenInputBottomOverrides}
										placeholder="Enter hours"
										type="number"
										value={value}
										onChange={(e) => {
											const value = parseInt(e.currentTarget.value)
											onChange(Math.max(0, value))
										}}
										min={0}
									/>
								)
							}}
						/>
					</FormControl>
				</div>
			</div>
			<div className={rowStyle}>
				<div className={textAreaInputStyle}>
					<FormControl
						label={
							<span>
								Their Actions<span className={helpStyle}>Try to be specific</span>
							</span>
						}
					>
						<Controller
							name={`ndisPlan.goals[${index}].myActions`}
							control={control}
							as={Textarea}
							placeholder="Enter actions"
							disabled={disabled}
							overrides={ZenInputOverrides}
						/>
					</FormControl>
				</div>
				<div className={textAreaInputStyle}>
					<FormControl
						label={
							<span>
								Progress<span className={helpStyle}>(describe in their own words)</span>
							</span>
						}
					>
						<Controller
							name={`ndisPlan.goals[${index}].progress`}
							control={control}
							as={Textarea}
							placeholder="Enter progress"
							disabled={disabled}
							overrides={ZenInputOverrides}
						/>
					</FormControl>
				</div>
			</div>
		</div>
	)
}
