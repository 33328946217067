import * as React from "react"
import { useStyletron } from "baseui"
import { ZenCard, Divider } from "../common"
import { LabelMedium } from "baseui/typography"
import { FormType, FormTypeName, FormTypeURL } from "../../types/enums"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { ClientDetail } from "../../types/types"

export const FormTypeList = (props: { client?: ClientDetail }) => {
	const [css] = useStyletron()
	const formsCardHeaderStyle = css({
		paddingBottom: "12px",
		display: "flex",
		flexDirection: "column",
	})
	const formsCardBodyStyle = css({
		flex: 1,
		minHeight: 0,
		overflowY: "auto",
	})

	return (
		<ZenCard>
			<div className={formsCardHeaderStyle}>
				<LabelMedium marginRight="12px">Forms</LabelMedium>
				<Divider />
			</div>
			<div className={formsCardBodyStyle}>
				{Object.values(FormType)
					.slice(1)
					.filter((f) => !f.startsWith("!")) // Don't list non-implemented form types
					.map((f, index) => (
						<FormListButton
							key={`formType-${f}`}
							index={index}
							type={f}
							clientName={props.client ? `${props.client.firstName} ${props.client.lastName}` : undefined}
							clientID={props.client?.id}
						/>
					))}
			</div>
		</ZenCard>
	)
}

const FormListButton = (props: { type: FormType; index: number; clientName?: string; clientID?: string }) => {
	const { type, index, clientName, clientID } = props
	const [css, theme] = useStyletron()
	const urlParams = (): string => {
		if (!clientName && !clientID) return ""
		let params = "?"
		if (clientName) params += `client=${clientName}`
		if (clientID) {
			if (clientName) params += "&&"
			params += `id=${clientID}`
		}
		return params
	}
	const containerStyle = css({
		width: "100%",
		backgroundColor: index % 2 !== 0 ? theme.colors.backgroundSecondary : "unset",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		paddingTop: "8px",
		paddingBottom: "8px",
		paddingLeft: "12px",
		paddingRight: "12px",
		":hover": {
			backgroundColor: "#eef0f9",
			paddingRight: "24px",
		},
		cursor: "pointer",
		transition: "padding-right 0.3s",
	})
	return (
		<Link className={containerStyle} to={`/portal/forms/${FormTypeURL(type)}${urlParams()}`}>
			<LabelMedium>{FormTypeName(type)}</LabelMedium>
			<FontAwesomeIcon icon={["fas", "arrow-right"]} size="1x" color={theme.colors.primary} />
		</Link>
	)
}
