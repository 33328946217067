import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge, LabelMedium, LabelXSmall } from "baseui/typography"
import { useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"
import { AuthContainer } from "../../../controllers/auth"
import { fetching } from "../../../fetching"
import { FormType, FormTypeName } from "../../../types/enums"
import { Form, RolePermission } from "../../../types/types"
import { ZenCard } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { Loading } from "../../loading"
import { useZenToast } from "../../zenComponents/useZenToast"
import { ZenButton } from "../../zenComponents/zenButtons"
import { ZenProgressBar } from "../../zenComponents/zenProgressBar"
import IncidentAccidentReportStep1 from "./incidentAccidentReportStep1"
import IncidentAccidentReportStep2 from "./incidentAccidentReportStep2"
import IncidentAccidentReportStep3 from "./incidentAccidentReportStep3"
import IncidentAccidentReportStep4 from "./incidentAccidentReportStep4"

import { routes } from "routes"
import { Responsive, WidthProvider } from "react-grid-layout"

const ResponsiveGridLayout = WidthProvider(Responsive)

export interface ReportPerson {
	name: string
	/** Team - Client - Public */
	relation: string
	age: string
	gender: string
}

export interface IncidentAccidentReportPartData {
	partA: {
		reporterID: string
		reporterName: string
		personsInvolved: ReportPerson[]
		location: string
		date: Date
		time: Date

		details: string
		response: string
		mediaCoverage: boolean
		mediaCoverageDetails: string
	}
	partB: {
		type: string[]
		comment: string
		feedbackProvidedToReporter: boolean
		relevant: string
		executiveName: string
	}
	partC: {
		/** Yes - No - N/A */
		riskAssessmentUndertaken: number
		/** Extreme - High - Medium - Low */
		riskRating: string
		reportToCEO: {
			responsibility: string
			targetCompletionDate: Date
			completionDate: Date
		}
		reportToFundingContactManager: {
			responsibility: string
			targetCompletionDate: Date
			completionDate: Date
		}
		/** Extreme - High - Medium - Low */
		newRiskRating: string
		/** Yes - No - N/A */
		feedbackProvidedToReporter: number
		executiveCorporateName: string
		executiveSignature: string
		executiveDate: Date
	}
}

const stepLabels = ["Who / Where / When", "Describe what happened", "Type of Accident or Incident", "Assessment"]

const IncidentAccidentReport = () => {
	const history = useHistory()
	const { showToast } = useZenToast()
	const searchArgs = new URLSearchParams(history.location.search)
	const defaultStep = searchArgs.get("step")

	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const isExecutive = hasPermission(RolePermission.Executive)
	const isExecutiveCorporate = hasPermission(RolePermission.ExecutiveCorporate)

	const { control, formState, handleSubmit, setValue, trigger, errors, getValues } = useForm<IncidentAccidentReportPartData>({
		defaultValues: {
			partA: {
				reporterID: "",
				reporterName: "",
				personsInvolved: [],
				location: "",
				date: undefined,
				time: undefined,

				details: "",
				response: "",
				mediaCoverage: false,
				mediaCoverageDetails: "",
			},
			partB: {
				type: [],
				comment: "",
				relevant: "",
				executiveName: "",
			},
			partC: {
				riskRating: "",
				reportToCEO: {
					responsibility: "",
				},
				reportToFundingContactManager: {
					responsibility: "",
				},
				newRiskRating: "",

				executiveCorporateName: "",
				executiveSignature: "",
				executiveDate: new Date(),
			},
		},
	})

	// Get existing form
	const formID = searchArgs.get("formID")
	const [requiresAction, setRequiresAction] = React.useState(false)
	const [viewOnly, setViewOnly] = React.useState(false) // members can see full form (ie: step 3) but can not longer edit this form
	const [isCompleted, setIsCompleted] = React.useState(false) // whether or not all 3 parts have been completed
	const [isLoaded, setIsLoaded] = React.useState(false)

	// part b types
	const [partBTypes, setPartBTypes] = React.useState<string[]>([])

	const { payload: data, loading, error } = useQuery<Form<IncidentAccidentReportPartData>>(fetching.query.getForm(formID || ""), !!formID)

	const loadData = React.useCallback(() => {
		if (!data) return

		// load existing form data
		const content = data.content as IncidentAccidentReportPartData
		setValue("partA", content.partA)
		setValue("partB", content.partB)
		setValue("partC", content.partC)

		if (content.partB) setPartBTypes(content.partB.type)

		setRequiresAction(data.requiresAction)

		setIsLoaded(true)

		// form is complete, set view only to true
		if (!data.requiresAction) {
			setViewOnly(true)
			// set parameters to show full detail
			setStep(3)
			setIsCompleted(true)
			return
		}

		// check which stage currently in
		// and set the last step
		const ls = content.partB !== undefined ? 3 : 2

		// Set form to view only if user does not have permissions
		if (!isExecutiveCorporate && !isExecutive) {
			setStep(ls)
			// if not executiveCorporate and an executive has already reviewed part of this form - set to view only
			setViewOnly(true)
			setIsCompleted(false)
			return
		}

		if (isExecutiveCorporate) {
			// Jump to section that needs completing
			setStep(ls)
			setLastStep(ls)
			setIsEditMode(true)
			return
		}

		if (isExecutive) {
			// jump to the step
			setStep(ls)

			// become view only when Part B is complete
			if (ls === 3) {
				setViewOnly(true)
				setIsCompleted(false)
				return
			}
			// setRequiresAction(true)
			setLastStep(ls)
			setIsEditMode(true)
		}
	}, [data, isExecutive, isExecutiveCorporate, setValue])
	React.useEffect(() => {
		loadData()
	}, [loadData])

	// Editing
	const [lastStep, setLastStep] = React.useState<number>(1)
	const [step, setStep] = React.useState<number>(defaultStep ? +defaultStep : 0)
	const canGoBackStep = step > 0 && (lastStep === 1 || !requiresAction)
	React.useEffect(() => {
		const s = searchArgs.get("step")
		if (s === null || +s === step) return
		const _step = s ? +s : 0
		setStep(_step)
	}, [searchArgs.get("step")]) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (viewOnly || !currentUser || loading) return
		if (!formID) {
			setValue("partA.reporterID", currentUser.id)
			setValue("partA.reporterName", `${currentUser.firstName} ${currentUser.lastName}`)
			return
		}
		// Set default
		if (step === 2) setValue("partB.executiveName", `${currentUser.firstName} ${currentUser.lastName}`)
		if (step === 3) setValue("partC.executiveCorporateName", `${currentUser.firstName} ${currentUser.lastName}`)
	}, [currentUser, setValue, step, loading, formID, viewOnly])

	const [isEditMode, setIsEditMode] = React.useState(!formID)

	const {
		mutate: createForm,
		loading: creatingForm,
		payload: createFormData,
		error: createError,
	} = useMutation<Form<IncidentAccidentReportPartData>>(fetching.mutation.formCreate)
	const {
		mutate: updateForm,
		loading: updatingForm,
		payload: updateFormData,
		error: updateError,
	} = useMutation<Form<IncidentAccidentReportPartData>>(fetching.mutation.formUpdate)

	const isLoading = creatingForm || updatingForm || loading
	const formDisabled = isLoading || !isEditMode

	const onSaveForm = handleSubmit(async (data) => {
		if (isLoading || (!formID && step < 1)) return

		const input = {
			content: data,
			requiresAction: true,
		}

		if (data.partB && !data.partC) {
			input.content.partB.type = partBTypes
		}

		if (formID) {
			if (!!data.partC && isExecutiveCorporate) input.requiresAction = false // form was reviewed by executiveCorporate
			const resp = await updateForm({
				id: formID,
				...input,
			})

			if (resp.status === 200) {
				control.updateFormState({ isDirty: false })
				showToast("Incident and Accident Report updated successfully.", "positive")
				history.push(routes.forms.root)
			}
		} else {
			// Create
			const resp = await createForm({
				input: {
					name: `OSHF 007 ${FormTypeName(FormType.IncidentAndAccidentReport)} Form | ${data.partA.location} | ${data.partA.time.toLocaleDateString(undefined, {
						day: "numeric",
						month: "numeric",
						year: "numeric",
					})}`,
					type: FormType.IncidentAndAccidentReport,
					...input,
				},
			})
			if (resp.status === 200) {
				control.updateFormState({ isDirty: false })
				showToast("Incident and Accident Report created successfully.", "positive")
				history.push(routes.forms.root)
			}
		}
	})
	const nextStep = async () => {
		if (step === lastStep) return

		// validate
		if (isEditMode) {
			let valid = false
			switch (step) {
				case 0:
					valid = await trigger(["partA.reporterName", "partA.location", "partA.date", "partA.time"])
					break
				case 1:
					valid = await trigger(["partA.details", "partA.response"])
					break
				case 2:
					valid = await trigger(["partB.comment"])
					break
				case 3:
					valid = await trigger(["partC.riskRating", "partC.reportToCEO.responsibility", "partC.reportToFundingContactManager.responsibility"])
					break
			}
			if (!valid) return
		}

		// next step
		searchArgs.set("step", (step + 1).toString())
		history.push({ search: searchArgs.toString() })
		if (requiresAction && step + 1 === lastStep && !isEditMode) {
			// automatically start edit mode on reach step 3
			setIsEditMode(true)
		}
		setStep(step + 1)
	}
	const prevStep = () => {
		if (step <= 0) return
		searchArgs.set("step", (step - 1).toString())
		history.push({ search: searchArgs.toString() })
		setStep(step - 1)
	}

	// Styling
	const [css, theme] = useStyletron()
	const containerStyle = css({
		display: "flex",
		height: "100%",
		width: "100%",
		overflowY: "auto",
	})
	const formStyle = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
		maxHeight: "100%",
		overflowY: "auto",
	})
	const progressBarStyle = css({
		paddingRight: "20px",
	})
	const sectionStyle = css({
		overflowY: "auto",
		marginTop: "20px",
		flex: 1,
	})
	const sectionInnerStyle = css({
		minWidth: "480px",
		marginLeft: "auto",
		marginRight: "auto",
	})
	const footerStyle = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "end",
		height: error || createError || updateError ? "98px" : "unset",
		marginTop: "10px",
	})
	const footerButtons = css({
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
	})
	const notificationCircle = css({
		minHeight: "10px",
		minWidth: "10px",
		background: theme.colors.negative,
		borderRadius: "10px",
		alignSelf: "center",
		marginLeft: "10px",
	})
	const titleStyle = css({
		fontWeight: "bold",
	})
	const infoCardStyle = css({
		height: "100%",
		visibility: step >= 2 ? "unset" : "hidden",
		overflowY: "auto",
	})
	const infoStyle = css({
		paddingRight: "20px",
	})
	const sectionTitleStyle = css({
		display: "flex",
		fontWeight: "bold",
	})

	if (loading || (formID && !isLoaded)) return <Loading />

	return (
		<div className={containerStyle}>
			{viewOnly ? (
				<div key="info-card">
					<ZenCard className={infoCardStyle}>
						<div className={infoStyle}>
							{step >= 2 && (
								<>
									<LabelLarge className={titleStyle}>Information From Part A</LabelLarge>
									<LabelXSmall>(Completed by a Team Member)</LabelXSmall>
									<IncidentAccidentReportStep1 control={control} errors={errors} disabled infoOnly />
									<IncidentAccidentReportStep2 control={control} errors={errors} disabled infoOnly />
									{step === 2 && <LabelLarge className={titleStyle}>Information From Part B - Waiting for relevant executive to complete</LabelLarge>}
									{step === 2 && <LabelLarge className={titleStyle}>Information From Part C - Waiting for executive corporate to complete</LabelLarge>}
								</>
							)}
							{step === 3 && (
								<>
									<LabelLarge className={titleStyle}>Information From Part B</LabelLarge>
									<LabelXSmall>(Completed by Relevant Executive - {getValues().partB.executiveName})</LabelXSmall>
									<IncidentAccidentReportStep3 control={control} errors={errors} types={partBTypes} changeTypes={setPartBTypes} disabled infoOnly />
									{(!viewOnly || !isCompleted) && (
										<LabelLarge className={titleStyle}>Information From Part C - Waiting for executive corporate to complete</LabelLarge>
									)}
								</>
							)}
							{viewOnly && isCompleted && (
								<>
									<LabelLarge className={titleStyle}>Information From Part C</LabelLarge>
									<LabelXSmall>(Completed by Executive Corporate - {getValues().partC.executiveCorporateName})</LabelXSmall>
									<IncidentAccidentReportStep4 control={control} errors={errors} disabled infoOnly />
								</>
							)}
						</div>
					</ZenCard>
				</div>
			) : (
				<ResponsiveGridLayout style={{ width: "100%" }} layouts={getLayouts(isCompleted, viewOnly, step)}>
					<div key="report-form">
						<ZenCard style={{ flex: "unset", height: "100%" }}>
							<form autoComplete="off" className={formStyle} onSubmit={onSaveForm}>
								<div className={progressBarStyle}>
									{lastStep === 1 ? (
										<ZenProgressBar labels={["Who / Where / When", "Describe What Happened"]} currentStep={step} />
									) : (
										<div className={sectionTitleStyle}>
											<LabelMedium>{stepLabels[2]}</LabelMedium>
											{requiresAction && <div className={notificationCircle} title="Action is required on this form" />}
										</div>
									)}
								</div>

								<div className={sectionStyle}>
									<div className={sectionInnerStyle}>
										{step === 0 && <IncidentAccidentReportStep1 control={control} errors={errors} disabled={formDisabled} />}
										{step === 1 && <IncidentAccidentReportStep2 control={control} errors={errors} disabled={formDisabled} />}
										{step === 2 && (
											<IncidentAccidentReportStep3 control={control} errors={errors} disabled={formDisabled} types={partBTypes} changeTypes={setPartBTypes} />
										)}
										{step === 3 && <IncidentAccidentReportStep4 control={control} errors={errors} disabled={formDisabled} />}
									</div>
								</div>

								{/* Footer */}
								<div className={footerStyle}>
									{error && <ErrorNotification messageOrPayload={data} />}
									{createError && <ErrorNotification messageOrPayload={createFormData} />}
									{updateError && <ErrorNotification messageOrPayload={updateFormData} />}
									<div className={footerButtons}>
										<ZenButton
											width="100px"
											type="button"
											onClick={() => {
												if (canGoBackStep) prevStep()
												else history.push(routes.forms.root)
											}}
											disabled={isLoading || loading}
											altKind="secondary"
										>
											{!canGoBackStep ? "Cancel" : "Back"}
										</ZenButton>
										<div>
											{isEditMode && (step >= lastStep || formID) && (
												<ZenButton width="100px" type="button" onClick={onSaveForm} isLoading={isLoading}>
													Submit
												</ZenButton>
											)}

											{step < lastStep && (
												<ZenButton width="100px" marginLeft={"10px"} disabled={isLoading} type="button" onClick={nextStep}>
													Continue
												</ZenButton>
											)}
										</div>
									</div>
								</div>
							</form>
						</ZenCard>
					</div>
					<div key="info-card">
						<ZenCard className={infoCardStyle}>
							<div className={infoStyle}>
								{step >= 2 && (
									<>
										<LabelLarge className={titleStyle}>Information From Part A</LabelLarge>
										<LabelXSmall>(Completed by a Team Member)</LabelXSmall>
										<IncidentAccidentReportStep1 control={control} errors={errors} disabled infoOnly />
										<IncidentAccidentReportStep2 control={control} errors={errors} disabled infoOnly />
										{step === 2 && <LabelLarge className={titleStyle}>Information From Part B - Waiting for relevant executive to complete</LabelLarge>}
										{step === 2 && <LabelLarge className={titleStyle}>Information From Part C - Waiting for executive corporate to complete</LabelLarge>}
									</>
								)}
								{step === 3 && (
									<>
										<LabelLarge className={titleStyle}>Information From Part B</LabelLarge>
										<LabelXSmall>(Completed by Relevant Executive - {getValues().partB.executiveName})</LabelXSmall>
										<IncidentAccidentReportStep3 control={control} errors={errors} types={partBTypes} changeTypes={setPartBTypes} disabled infoOnly />
										{(!viewOnly || !isCompleted) && (
											<LabelLarge className={titleStyle}>Information From Part C - Waiting for executive corporate to complete</LabelLarge>
										)}
									</>
								)}
								{viewOnly && isCompleted && (
									<>
										<LabelLarge className={titleStyle}>Information From Part C</LabelLarge>
										<LabelXSmall>(Completed by Executive Corporate - {getValues().partC.executiveCorporateName})</LabelXSmall>
										<IncidentAccidentReportStep4 control={control} errors={errors} disabled infoOnly />
									</>
								)}
							</div>
						</ZenCard>
					</div>
				</ResponsiveGridLayout>
			)}

			<Prompt
				when={formState.isDirty}
				message={(location) => {
					if (location.pathname === "/portal/forms/incident_and_accident_report") {
						return true
					}
					return "You have unsaved changes, are you sure you want to leave?"
				}}
			/>
		</div>
	)
}

export default IncidentAccidentReport

const getLayouts = (isCompleted: boolean, viewOnly: boolean, step: number): ReactGridLayout.Layouts => {
	// Infobar is shown
	if ((isCompleted && viewOnly) || step >= 2) {
		return {
			lg: [
				{
					w: 7,
					h: 6,
					x: 0,
					y: 0,
					i: "report-form",
					moved: false,
					static: true,
				},
				{
					w: 5,
					h: 6,
					x: 7,
					y: 0,
					i: "info-card",
					moved: false,
					static: true,
				},
			],
			md: [
				{
					w: 6,
					h: 5,
					x: 0,
					y: 0,
					i: "report-form",
					moved: false,
					static: true,
				},
				{
					w: 4,
					h: 5,
					x: 6,
					y: 0,
					i: "info-card",
					moved: false,
					static: true,
				},
			],
			sm: [
				{
					w: 6,
					h: 5,
					x: 0,
					y: 3,
					i: "report-form",
					moved: false,
					static: true,
				},
				{
					w: 6,
					h: 5,
					x: 0,
					y: 0,
					i: "info-card",
					moved: false,
					static: true,
				},
			],
		}
	}

	// Infobar is hidden
	return {
		lg: [
			{
				w: 7,
				h: 6,
				x: 0,
				y: 0,
				i: "report-form",
				moved: false,
				static: true,
			},
			{
				w: 0,
				h: 0,
				x: 7,
				y: 0,
				i: "info-card",
				moved: false,
				static: true,
			},
		],
		md: [
			{
				w: 6,
				h: 5,
				x: 0,
				y: 0,
				i: "report-form",
				moved: false,
				static: true,
			},
			{
				w: 0,
				h: 0,
				x: 6,
				y: 0,
				i: "info-card",
				moved: false,
				static: true,
			},
		],
		sm: [
			{
				w: 6,
				h: 5,
				x: 0,
				y: 0,
				i: "report-form",
				moved: false,
				static: true,
			},
			{
				w: 0,
				h: 0,
				x: 0,
				y: 0,
				i: "info-card",
				moved: false,
				static: true,
			},
		],
	}
}
