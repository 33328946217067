import * as React from "react"
import { NDISLineItem, NDISPriceGuide } from "../../../types/types"
import { ZenTheme } from "../../../themeOverrides"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { fetching } from "../../../fetching"
import { FilterBy } from "../../../types/enums"
import { SearchAndFilter } from "../../../components/common"
import { snakeToTitle } from "../../../helpers/utils"
import { ListTable, useListTable } from "../../../components/listTable"
import { getErrorMessage } from "../../../helpers/errors"
import { Button, KIND } from "baseui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { useZenToast } from "../../../components/zenComponents/useZenToast"
import { LineItemEdit } from "./lineItemEdit"
import { LineItemDetail } from "./lineItemDetail"

export const LineItemList = (props: { selectedRow?: NDISPriceGuide }) => {
	const { selectedRow } = props
	const { limit, offset, setTotal, total, setOffset, filter, setFilter, search, setSearch } = useListTable({})
	const { showToast } = useZenToast()

	const [archiveLineItem, setArchiveLineItem] = React.useState<NDISLineItem>()
	const [selectedLineItem, setSelectedLineItem] = React.useState<NDISLineItem>()

	const {
		query: lineItemsQuery,
		loading: lineItemsLoading,
		payload: lineItemsPayload,
		error: lineItemsError,
	} = useParameterizedQuery(fetching.query.getNDISLineItemsByPriceGuide)
	const {
		payload: toggleArchivePayload,
		loading: toggleArchiveLoading,
		error: toggleArchiveError,
		mutate: toggleArchive,
		reset: toggleArchiveReset,
	} = useMutation(fetching.mutation.ndisLineItemToggleArchive)

	// re-fetch data with current parameters so page, search etc. is no lost
	const reFetch = React.useCallback(() => {
		if (!selectedRow) return

		let f: string = FilterBy.Active
		if (filter[0] && filter[0].id) {
			f = filter[0].id.toString()
		}
		lineItemsQuery({ priceGuideID: selectedRow.id, limit: limit, offset: offset, search: search, filterBy: f })
	}, [selectedRow, search, filter, offset, limit, lineItemsQuery])

	const handleArchiveLineItem = async () => {
		if (!archiveLineItem || !selectedRow) return

		const result = await toggleArchive({ id: archiveLineItem.id })

		if (!result.payload || result.error) return

		showToast(`Line item ${result.payload.itemNumber} ${result.payload.deletedAt ? "archived" : "unarchived"} successfully.`, "positive")
		setArchiveLineItem(undefined)

		reFetch()
	}

	// Run query when selected row, pagination or search changes
	React.useEffect(() => {
		reFetch()
	}, [reFetch])

	// Update total on pagination when line items are fetched
	React.useEffect(() => {
		if (!lineItemsPayload || lineItemsError) return
		setTotal(lineItemsPayload.total)
	}, [lineItemsPayload, lineItemsError, setTotal])

	// Reset offset when search value changes
	React.useEffect(() => {
		setOffset(0)
	}, [search, setOffset])

	let lineItemRows: NDISLineItem[] = []
	if (lineItemsPayload && !lineItemsError) {
		lineItemRows = lineItemsPayload.lineItems
	}

	return (
		<>
			<SearchAndFilter search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} />
			<ListTable
				error={lineItemsError ? getErrorMessage(lineItemsPayload) : undefined}
				isLoading={lineItemsLoading}
				rows={lineItemRows}
				onRowClick={(row: NDISLineItem) => setSelectedLineItem(row)}
				columns={[
					{
						id: "ItemNumber",
						header: "Item Number",
						resolver: (row: NDISLineItem) => (
							<div>
								{row.itemNumber}
								{row.deletedAt && ` [ARCHIVED]`}
							</div>
						),
					},
					{
						id: "ItemName",
						header: "Item Name",
						resolver: (row: NDISLineItem) => row.itemName,
					},
					{
						id: "Type",
						header: "Type",
						resolver: (row: NDISLineItem) => snakeToTitle(row.itemType),
					},
					{
						id: "PriceNational",
						header: "National",
						resolver: (row: NDISLineItem) => row.priceNational && `$${parseFloat(row.priceNational).toFixed(2)}`,
					},
					{
						id: "PriceRemote",
						header: "Remote",
						resolver: (row: NDISLineItem) => row.priceRemote && `$${parseFloat(row.priceRemote).toFixed(2)}`,
					},
					{
						id: "PriceVeryRemote",
						header: "Very Remote",
						resolver: (row: NDISLineItem) => row.priceVeryRemote && `$${parseFloat(row.priceVeryRemote).toFixed(2)}`,
					},
					{
						id: "Actions",
						header: "Actions",
						resolver: (row: NDISLineItem) => (
							<Button
								kind={KIND.minimal}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									setArchiveLineItem(row)
								}}
							>
								<FontAwesomeIcon
									color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
									icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
								/>
							</Button>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{archiveLineItem && (
				<ZenArchiveModal
					message={`${archiveLineItem.itemNumber}`}
					open={!!archiveLineItem}
					confirmArchive={() => {
						handleArchiveLineItem()
					}}
					onClose={() => {
						setArchiveLineItem(undefined)
						toggleArchiveReset()
					}}
					restoreMode={!!archiveLineItem.deletedAt}
					loading={toggleArchiveLoading}
					error={toggleArchiveError}
					payload={toggleArchivePayload}
				/>
			)}

			{selectedLineItem && selectedRow && (
				<ZenModal isOpen={!!selectedLineItem} onClose={() => setSelectedLineItem(undefined)} size="auto" autoFocus={false}>
					<LineItemView
						lineItem={selectedLineItem}
						priceGuide={selectedRow}
						onSave={(item: NDISLineItem) => {
							reFetch()
							setSelectedLineItem(item)
						}}
					/>
				</ZenModal>
			)}
		</>
	)
}

const LineItemView = (props: { lineItem: NDISLineItem; priceGuide: NDISPriceGuide; onSave: (item: NDISLineItem) => void }) => {
	const [editMode, setEditMode] = React.useState(false)

	if (!editMode) {
		return <LineItemDetail {...props} setEditMode={setEditMode} />
	}

	return <LineItemEdit {...props} setEditMode={setEditMode} />
}
