import * as React from "react"
import { useStyletron } from "baseui"
import { Value } from "baseui/select"
import { Prompt, Route, Switch, useHistory } from "react-router-dom"

import { ZenCard } from "../../components/common"
import { WorkerEmergencyContact } from "../../components/workers/onboarding/emergencyContact"
import { WorkerConfirmation } from "../../components/workers/onboarding/workerConfirmation"
import { WorkerContact } from "../../components/workers/onboarding/workerContact"
import { WorkerGeneralInfo } from "../../components/workers/onboarding/workerGeneralInfo"
import { WorkerRole } from "../../components/workers/onboarding/workerRole"
import { ZenProgressBar } from "../../components/zenComponents/zenProgressBar"
import { BasicName } from "../../types/types"
import { routes } from "routes"

const processStep = ["General", "Contact", "Role", "Emergency Contact", "Confirmation"]
export interface UserInput {
	firstName?: string
	lastName?: string
	email?: string
	mobileNumber?: string
	dateOfBirth?: Date
	role?: Value
	status?: Value
	avatar?: File
	address?: Value
	position?: Value
	department?: Value
	offices?: UserOfficesInput[]
	vehicleRegistrationNumber?: string
	emergencyContact?: EmergencyContactInput[]
}
export interface EmergencyContactInput {
	firstName: string
	lastName: string
	relationship: string
	telephoneNumber?: string
	mobileNumber?: string
}

export interface UserOfficesInput {
	day: string
	office?: BasicName
}

export interface WorkerFormData {
	data: UserInput
	setData: React.Dispatch<React.SetStateAction<UserInput>>
}

export const WorkerCreate = () => {
	const [css, theme] = useStyletron()
	const history = useHistory()
	const [step, setStep] = React.useState<number>(0)
	const [data, setData] = React.useState<UserInput>({})
	const [firstLoad, setFirstLoad] = React.useState(true)
	const [startedForm, setStartedForm] = React.useState(false)
	const [submittedForm, setSubmittedForm] = React.useState(false)

	React.useEffect(() => {
		const pathElements = history.location.pathname.split("/")
		const lastValue = pathElements[pathElements.length - 1]
		if (firstLoad) {
			if (lastValue !== "create") {
				history.push(routes.workers.create.root)
			}
			setFirstLoad(false)
			return
		}
		switch (lastValue) {
			case "contact":
				setStep(1)
				if (!startedForm) setStartedForm(true)
				break
			case "role":
				setStep(2)
				break
			case "emergencyContact":
				setStep(3)
				break
			case "confirmation":
				setStep(4)
				break
			default:
				setStep(0)
				break
		}
	}, [firstLoad, history, history.location.pathname, startedForm])

	const container = css({
		height: "100%",
		width: "70%",
		display: "flex",
		flexDirection: "column",
		padding: "20px",
		backgroundColor: theme.colors.white,

		[`@media only screen and (max-width: 1500px)`]: {
			width: "100%",
		},
	})
	const form = css({
		width: "100%",
		height: "100%",
		minHeight: 0,
		maxHeight: "100%",
		marginTop: "15px",
		display: "flex",
		justifyContent: "center",
	})
	return (
		<ZenCard className={container}>
			<ZenProgressBar labels={processStep} currentStep={step} />
			<div className={form}>
				<Switch>
					<Route exact path={routes.workers.create.root} render={() => <WorkerGeneralInfo data={data} setData={setData} />} />
					<Route exact path={routes.workers.create.contact} render={() => <WorkerContact data={data} setData={setData} />} />
					<Route exact path={routes.workers.create.role} render={() => <WorkerRole data={data} setData={setData} />} />
					<Route exact path={routes.workers.create.emergencyContact} render={() => <WorkerEmergencyContact data={data} setData={setData} />} />
					<Route
						exact
						path={routes.workers.create.confirmation}
						render={() => <WorkerConfirmation data={data} setData={setData} onSubmitted={() => setSubmittedForm(true)} />}
					/>
				</Switch>
			</div>
			<Prompt
				when={startedForm && !submittedForm}
				message={(location) => {
					if (location.pathname.startsWith(routes.workers.create.root)) return true
					return "You have unsaved changes, are you sure you want to leave?"
				}}
			/>
		</ZenCard>
	)
}
