import * as React from "react"
import { useStyletron } from "baseui"
import { LabelMedium } from "baseui/typography"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { routes } from "routes"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { ZenTextArea } from "../../zenComponents/zenInput"
import { SessionFormData } from "./singleSessionBaseForm"

export const ClientNotesForm = (props: SessionFormData) => {
	const { data, setData, pageChange } = props
	const history = useHistory()
	const searchArgs = React.useMemo(() => {
		return new URLSearchParams(history.location.search)
	}, [history.location.search])
	const [css] = useStyletron()
	const { control, setValue, getValues, handleSubmit } = useForm()

	React.useEffect(() => {
		setValue("notes", data.notes)
	}, [data, setValue])

	const back = () => {
		saveData(getValues())
		if (pageChange) {
			pageChange(-1)
			return
		}
		history.goBack()
	}

	const submit = (noteData: any) => {
		saveData(noteData)
		if (pageChange) {
			pageChange(1)
			return
		}
		history.push({
			pathname: routes.sessions.create.single.confirmation,
			search: searchArgs.toString(),
		})
	}

	const saveData = (d: any) => {
		setData((data) => ({
			...data,
			notes: d.notes,
		}))
	}

	const container = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		maxWidth: "600px",
		height: "100%",
	})
	const noteLabel = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "4px",
	})
	const scrollingDiv = css({
		height: "100%",
		maxHeight: "100%",
		overflowY: "auto",
		paddingRight: "8px",
	})
	return (
		<form autoComplete="off" className={container} onSubmit={handleSubmit(submit)}>
			<div className={scrollingDiv}>
				<div className={noteLabel}>
					<LabelMedium>Pre-session Notes</LabelMedium>
				</div>
				<ZenTextArea formRef={control} nameRef="notes" value="" resizable />
			</div>
			<CancelAndSaveButtons cancelLabel="Back" cancelFn={back} saveLabel="Next" />
		</form>
	)
}
