import { Action, createClient, QueryResponse } from "react-fetching-library"

import { requestHostInterceptor } from "./requestInterceptors/requestHostInterceptor"

import { base_host as host } from "../host"

export const responseInterceptor = () => async (action: Action<any, any>, response: QueryResponse<any>) => {
	if (response.status === 401) {
		window.location.href = "/?sessionExpired=true"
		return response
	}

	return response
}

export const Client = createClient({
	//requestInterceptors: [requestHostInterceptor(`${loc.protocol}//${loc.hostname}:${loc.port}/api`)],
	//requestInterceptors: [requestHostInterceptor(`https://zen.helpingminds.org.au/api`)],
	//requestInterceptors: [requestHostInterceptor(`https://zen.australiaeast.cloudapp.azure.com/api`)],
	//requestInterceptors: [requestHostInterceptor(`http://localhost:8080/api`)],
	requestInterceptors: [requestHostInterceptor(`${host.uri}/api`)],
	responseInterceptors: [responseInterceptor],
})
