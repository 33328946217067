import * as React from "react"
import { useStyletron } from "baseui"
import { LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { AuthContainer } from "../../../../controllers/auth"
import { fetching } from "../../../../fetching"
import { Client, ClientParentGuardian, RolePermission } from "../../../../types/types"
import { Divider } from "../../../common"
import { FieldDisplay } from "../../../fieldDisplay"
import { TooltipButton } from "../../../tooltipButton"
import { ZenArchiveModal } from "../../../zenComponents/zenArchiveDialog"
import { ClientOverviewBasicProps } from "../clientOverview"
import { BasicInfo } from "./BasicInfo"
import { PostalAddress } from "./postalAddress"
import { SupplementaryInfo } from "./supplementary"

interface ClientDetailProps extends ClientOverviewBasicProps {
	showTitle?: boolean
}

export const ClientDetails = (props: ClientDetailProps) => {
	const { client, showTitle, refetch } = props
	const { hasPermission } = AuthContainer.useContainer()
	const history = useHistory()

	// archive/unarchive
	const [archiveModalOpen, setArchiveModalOpen] = React.useState(false)
	const archiveClient = useMutation<Client>(fetching.mutation.clientArchive)
	const unarchiveClient = useMutation<Client>(fetching.mutation.clientUnarchive)

	const onArchive = React.useCallback(() => {
		if (!(archiveClient.payload || unarchiveClient.payload)) return
		refetch()
		setArchiveModalOpen(false)
	}, [archiveClient.payload, unarchiveClient.payload, refetch])
	React.useEffect(() => {
		onArchive()
	}, [onArchive])

	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		padding: "2px",
		maxHeight: "100%",
		height: "100%",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const body = css({
		width: "100%",
		overflowX: "hidden",
		overflowY: "auto",
		paddingRight: "14px",
	})

	return (
		<div className={container}>
			{showTitle && (
				<>
					<div className={title}>
						<LabelMedium>Client Details</LabelMedium>
						<div>
							<TooltipButton onClick={() => history.push(`/portal/clients/${client.id}/update`)} tooltip={"Edit client details"} iconName={"pencil"} />
							{hasPermission(RolePermission.ClientUpdate) && (
								<TooltipButton
									onClick={() => {
										setArchiveModalOpen(true)
									}}
									tooltip={client.deletedAt ? "Unarchive Client" : "Archive Client"}
									iconName={client.deletedAt ? "trash-restore-alt" : "trash-alt"}
								/>
							)}
						</div>
					</div>
					<Divider />
				</>
			)}
			<div className={body}>
				<BasicInfo {...client} />

				<Divider />

				<PostalAddress {...client} />

				<Divider />

				<SupplementaryInfo {...client} />

				<ZenArchiveModal
					error={archiveClient.error || unarchiveClient.error}
					payload={archiveClient.payload || unarchiveClient.payload}
					open={archiveModalOpen}
					onClose={() => setArchiveModalOpen(false)}
					loading={archiveClient.loading || unarchiveClient.loading}
					message={`${client.firstName} ${client.lastName}`}
					restoreMode={!!client?.deletedAt}
					confirmArchive={() => {
						if (!client) return
						if (client.deletedAt) {
							unarchiveClient.mutate({ id: client.id })
						} else {
							archiveClient.mutate({ id: client.id })
						}
					}}
				/>
			</div>
		</div>
	)
}

interface ClientParentGuardianCardProps extends ClientParentGuardian {
	index: number
}
export const ClientParentGuardianCard = (props: ClientParentGuardianCardProps) => {
	const {
		index,
		firstName,
		lastName,
		relationship,
		telephoneNumber,
		mobileNumber,
		dateOfBirth,
		languageSpokenAtHome,
		countryOfBirth,
		requiresTranslator,
		culturallyAndLinguisticallyDiverse,
		aboriginalOrTorresStraitIslander,
	} = props
	const [css] = useStyletron()
	const group = css({
		marginTop: "8px",
		display: "flex",
		alignContent: "center",
	})
	return (
		<FieldDisplay label={`Parent/Guardian ${index}`} marginTop="8px">
			<div className={group}>
				<FieldDisplay label="Name">
					<LabelSmall>{`${firstName} ${lastName}`}</LabelSmall>
				</FieldDisplay>
				<FieldDisplay label="Relationship">
					<LabelSmall>{relationship}</LabelSmall>
				</FieldDisplay>
			</div>
			<div className={group}>
				<FieldDisplay label="Home phone">
					<LabelSmall>{telephoneNumber || "N/A"}</LabelSmall>
				</FieldDisplay>
				<FieldDisplay label="Mobile">
					<LabelSmall>{mobileNumber || "N/A"}</LabelSmall>
				</FieldDisplay>
			</div>
			<FieldDisplay label="Date of birth">
				<LabelSmall>{moment(dateOfBirth).format("DD/MM/YYYY")}</LabelSmall>
			</FieldDisplay>
			<div className={group}>
				<FieldDisplay label="Country of birth">
					<LabelSmall>{countryOfBirth.countryName}</LabelSmall>
				</FieldDisplay>
				<FieldDisplay label="Language spoken at home">
					<LabelSmall>{languageSpokenAtHome.label}</LabelSmall>
				</FieldDisplay>
			</div>
			<div className={group}>
				<FieldDisplay label="Identify as ATSI">
					<LabelSmall>{aboriginalOrTorresStraitIslander}</LabelSmall>
				</FieldDisplay>
				<FieldDisplay label="Identify as CALD">
					<LabelSmall>{culturallyAndLinguisticallyDiverse ? "Yes" : "No"}</LabelSmall>
				</FieldDisplay>
			</div>
			<FieldDisplay label="Required translator">
				<LabelSmall>{requiresTranslator ? "Yes" : "No"}</LabelSmall>
			</FieldDisplay>
		</FieldDisplay>
	)
}
