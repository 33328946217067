import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Notification } from "baseui/notification"
import { Radio } from "baseui/radio"
import { HeadingXSmall, LabelSmall, ParagraphSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"

import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ListTable } from "../../../components/listTable"
import { TooltipButton } from "../../../components/tooltipButton"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenConfirmModal } from "../../../components/zenComponents/zenConfirmModal"
import { ZenDatePicker } from "../../../components/zenComponents/zenTime"
import { fetching } from "../../../fetching"
import { friendlyDate } from "../../../helpers/utils"
import { NDISPlan, NDISPlanLine } from "../../../types/types"
import { GetLineItemRows, LineItemRow } from "./planDetails"

interface Props {
	plan: NDISPlan
	clientID: string
	ndisNumber: string
	currentPlanLines: LineItemRow[]
	activePlan?: NDISPlan
	onClose: () => void
}

interface NDISMigratePlanLine {
	/** fromPlanID */
	id: string
	toPlanID?: string
	isTravel?: boolean
	isTransport?: boolean
	itemNumber?: string
	itemName?: string
	planLine?: NDISPlanLine
}

export const MigrateSessions = (props: Props) => {
	const { clientID, currentPlanLines, plan, activePlan } = props

	const currentPlanSets = React.useMemo(() => {
		const planLineSets: LineItemRow[][] = []
		let index = -1
		currentPlanLines.forEach((cpl) => {
			if (!cpl.isTransport && !cpl.isTravel) {
				index += 1
				planLineSets.push([])
			}
			planLineSets[index].push(cpl)
		})

		return planLineSets
	}, [currentPlanLines])

	const [css, theme] = useStyletron()
	const card = css({
		minWidth: "800px",
		width: "fit-content",
	})
	const arrowStyle = css({
		position: "absolute",
		left: "-25px",
	})
	const nowrapText = css({
		whiteSpace: "nowrap",
	})
	const iconStyle = css({
		display: "inline-block",
		marginRight: "10px",
	})
	const migrateTableContainer = css({
		flexGrow: 1,
		flexBasis: 0,
	})
	const migratePlanLineContainer = css({
		padding: "15px",
		borderRadius: "4px",
		backgroundColor: theme.colors.backgroundSecondary,
		boxShadow: theme.lighting.shadow400,
	})
	const submitContainer = css({
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "15px",
	})
	const planTitleStyle = css({
		display: "flex",
		alignItems: "center",
	})
	const dateStyle = css({
		color: "grey",
		fontSize: "12px",
		marginLeft: "5px",
	})
	const lineItemHeader = css({
		display: "flex",
		flexWrap: "nowrap",
	})

	const compareTable = css({
		display: "flex",
		width: "100%",
	})

	const [showConfirmModal, setShowConfirmModal] = React.useState(false)

	const [selectedPlanLine, setSelectedPlanLine] = React.useState<NDISMigratePlanLine>()
	const [migratePlanLines, setMigratePlanLines] = React.useState<Record<string, NDISMigratePlanLine>>({})

	const { payload: plans, loading: plansLoading, error: errorPlans } = useQuery(fetching.query.getClientNDISPlans(clientID))
	const {
		payload: planLinesPayload,
		loading: planLinesLoading,
		error: planLinesError,
		query: planLinesQuery,
	} = useParameterizedQuery(fetching.query.getClientNDISPlanLines)
	const [lineItems, setLineItems] = React.useState<LineItemRow[]>([])
	React.useEffect(() => {
		if (!planLinesPayload || !activePlan) return
		setLineItems(GetLineItemRows(planLinesPayload, activePlan.priceType))
	}, [planLinesPayload, activePlan])

	const { payload: response, loading: submitting, error: submitErrors, mutate: migrateSessions, status } = useMutation(fetching.mutation.ndisMigrateSessions)

	const { control, handleSubmit } = useForm<{ firstToMigrateDate: Date }>()

	const [assignLine, setAssignLine] = React.useState<LineItemRow>()
	const onAssignLine = () => {
		setMigratePlanLines((prev) => {
			if (!selectedPlanLine || !lineItems || !assignLine) return prev

			return {
				...prev,
				[selectedPlanLine.id]: {
					id: selectedPlanLine.id,
					toPlanID: assignLine.id,
					isTravel: selectedPlanLine.isTravel,
					isTransport: selectedPlanLine.isTransport,
					itemNumber: assignLine.itemNumber,
					itemName: assignLine.itemName,
					planLine: assignLine.planLine,
				},
			}
		})
		setSelectedPlanLine(undefined)
		setAssignLine(undefined)
	}

	React.useEffect(() => {
		if (!activePlan) return
		planLinesQuery(activePlan.id)
	}, [activePlan, planLinesQuery])

	const migrateSessionsSaveHandler = handleSubmit((input) => {
		const planLines: { fromID: string; toID: string }[] = []
		Object.values(migratePlanLines).forEach((p) => {
			if (!p.id || !p.toPlanID) return
			planLines.push({
				fromID: p.id,
				toID: p.toPlanID,
			})
		})
		if (planLines.length === 0) return

		migrateSessions({
			planID: plan.id,
			firstToMigrateDate: input.firstToMigrateDate,
			planLines,
		})
	})

	if (plansLoading || !plans) {
		return null
	}
	return (
		<ZenCard className={card}>
			<HeadingXSmall marginTop={0} marginBottom={0}>
				Migrate Charges to Active NDIS Plan
			</HeadingXSmall>
			<ParagraphSmall color={theme.colors.contentSecondary} marginTop={0}>
				Migrate lines from this plan to the currently active one
			</ParagraphSmall>

			{submitErrors && <ErrorNotification messageOrPayload={response} />}
			{errorPlans && <ErrorNotification messageOrPayload={plans} />}
			{planLinesError && <ErrorNotification messageOrPayload={planLinesPayload} />}

			{status === 200 && response && (
				<Notification
					kind={response.migratedSessionsCount === 0 ? "warning" : "positive"}
					overrides={{
						Body: {
							style: {
								width: "100%",
								marginTop: 0,
								marginBottom: 0,
							},
						},
					}}
				>
					{response.migratedSessionsCount} Charge{response.migratedSessionsCount !== 1 ? "s" : ""} have been Migrated to the new Plan Line
				</Notification>
			)}

			<ZenDatePicker label="First Day to Migrate" formName="firstToMigrateDate" formRef={control} clearable={false} />

			<FormControl
				overrides={{
					ControlContainer: {
						style: {
							display: "flex",
							flexDirection: "column",
							justifyContent: "space",
							marginTop: "15px",
						},
					},
					Label: {
						style: {
							...theme.typography.LabelSmall,
						},
					},
				}}
			>
				<>
					<div className={compareTable}>
						<div className={migrateTableContainer}>
							<LabelSmall className={planTitleStyle}>
								<span>Current Plan Lines</span>
								<span className={dateStyle}>{formatPlanLabel(plan)}</span>
							</LabelSmall>
						</div>
						<div style={{ width: "40px" }} />

						<div className={migrateTableContainer}>
							<LabelSmall className={planTitleStyle}>
								<span>Active Plan Lines</span>
								{activePlan && <span className={dateStyle}>{formatPlanLabel(activePlan)}</span>}
							</LabelSmall>
						</div>
					</div>
					{currentPlanSets.length > 0 &&
						currentPlanSets.map((cps, i) => (
							<div key={i} className={compareTable}>
								<div className={migrateTableContainer}>
									<ListTable
										rows={cps}
										disabledFunc={(row: LineItemRow) => row.isTravel || row.isTransport}
										columns={[
											{
												id: "ItemNumber",
												header: i === 0 ? "Item Number" : "",
												resolver: (row: LineItemRow) => (
													<div className={lineItemHeader}>
														{row.isTravel || row.isTransport ? (
															<FontAwesomeIcon className={iconStyle} icon={["fal", row.isTransport ? "bus" : "car"]} />
														) : (
															<></>
														)}
														{row.itemNumber}
													</div>
												),
											},
											{
												id: "Name",
												header: i === 0 ? "Name" : "",
												resolver: (row: LineItemRow) => row.itemName,
											},
										]}
									/>
								</div>
								<div style={{ width: "40px" }} />
								<div className={migrateTableContainer}>
									<ListTable
										selectedID={selectedPlanLine ? selectedPlanLine.id : undefined}
										onRowClick={(row: NDISMigratePlanLine) => setSelectedPlanLine(row)}
										disabledFunc={(row: NDISMigratePlanLine) => !!row.isTravel || !!row.isTransport}
										allowOverflow
										rows={cps.map((pl) => {
											// Blank (unselected)
											const blankLine = {
												id: pl.id,
												isTravel: pl.isTravel,
												isTransport: pl.isTransport,
											}
											if (!migratePlanLines[pl.id]) return blankLine

											// Plan Line (selected)
											const migrateLine = migratePlanLines[pl.id]
											if (pl.isTravel) {
												// Travel line?
												if (!migrateLine.planLine || !migrateLine.planLine.travel) return blankLine
												return {
													...migrateLine,
													isTravel: true,
													isTransport: false,
													itemNumber: migrateLine.planLine?.travel?.lineItem.itemNumber,
													itemName: migrateLine.planLine?.travel?.lineItem.itemName,
												}
											} else if (pl.isTransport) {
												// Transport Line
												if (!migrateLine.planLine || !migrateLine.planLine.transport) return blankLine
												return {
													...migrateLine,
													isTravel: false,
													isTransport: true,
													itemNumber: migrateLine.planLine?.transport?.lineItem.itemNumber,
													itemName: migrateLine.planLine?.transport?.lineItem.itemName,
												}
											}
											// Support Line
											return migrateLine
										})}
										columns={[
											{
												id: "ItemNumber",
												header: i === 0 ? "Item Number" : "",
												resolver: (row: NDISMigratePlanLine) => {
													const arrow = !row.isTravel && !row.isTransport && (
														<div className={arrowStyle}>
															<FontAwesomeIcon icon={["fal", "arrow-right"]} color={theme.colors.black} />
														</div>
													)
													const icon =
														row.isTravel || row.isTransport ? <FontAwesomeIcon className={iconStyle} icon={["fal", row.isTransport ? "bus" : "car"]} /> : null

													if (row.itemNumber) {
														return (
															<span className={nowrapText}>
																{arrow}
																{icon}
																{row.itemNumber}
															</span>
														)
													} else if (row.isTravel || row.isTransport) {
														return icon
													}
													return (
														<span className={nowrapText}>
															{arrow}
															<FontAwesomeIcon className={iconStyle} icon={["fal", "upload"]} /> Select Plan Line
														</span>
													)
												},
											},
											{
												id: "Name",
												header: i === 0 ? "Name" : "",
												resolver: (row: NDISMigratePlanLine) => row.itemName,
											},
											{
												id: "Actions",
												header: "",
												minWidth: "58px",
												resolver: (row: NDISMigratePlanLine) => {
													if (!row.toPlanID || row.isTravel || row.isTransport) return null
													return (
														<TooltipButton
															onClick={(e) => {
																e.stopPropagation()
																setMigratePlanLines((prev) => {
																	// Remove record
																	const filteredList: Record<string, NDISMigratePlanLine> = {}
																	for (const key in prev) {
																		if (key !== row.id) filteredList[key] = prev[key]
																	}
																	return filteredList
																})
																if (selectedPlanLine) setSelectedPlanLine(undefined)
															}}
															tooltip="Remove Line"
															iconName="trash-alt"
														/>
													)
												},
											},
										]}
									/>
								</div>
							</div>
						))}
				</>
			</FormControl>

			{/* Assign Plan Line Migrate Item */}
			{selectedPlanLine && (
				<div className={migratePlanLineContainer}>
					{activePlan && planLinesError && <ErrorNotification closeable messageOrPayload={"Failed to load Plan Lines"} />}
					{activePlan && (
						<FormControl label={<LabelSmall>Select Plan Line</LabelSmall>}>
							<ListTable
								isLoading={planLinesLoading}
								rows={lineItems || []}
								onRowClick={(row: LineItemRow) => setAssignLine(row)}
								disabledFunc={(row: LineItemRow) => row.isTravel || row.isTransport}
								columns={[
									{
										id: "ItemSelected",
										header: "",
										resolver: (row: LineItemRow) => (row.isTravel || row.isTransport ? null : <Radio checked={row.id === assignLine?.id} />),
									},
									{
										id: "ItemNumber",
										header: "Item Number",
										resolver: (row: LineItemRow) => (
											<span className={nowrapText}>
												{row.isTravel || row.isTransport ? <FontAwesomeIcon className={iconStyle} icon={["fal", row.isTransport ? "bus" : "car"]} /> : <></>}
												{row.itemNumber}
											</span>
										),
									},
									{
										id: "Name",
										header: "Name",
										resolver: (row: LineItemRow) => row.itemName,
									},
									{
										id: "ServiceDates",
										header: "Service Date",
										resolver: (row: LineItemRow) => `${friendlyDate(row.serviceStartDate)} - ${friendlyDate(row.serviceEndDate)}`,
									},
									{
										id: "WeeksRemaining",
										header: "Weeks Remaining",
										resolver: (row: LineItemRow) => moment(row.serviceEndDate).diff(moment(), "weeks", true).toFixed(2),
									},
									{
										id: "Budget",
										header: "Budget",
										resolver: (row: LineItemRow) => `$${row.budget.toFixed(2)}`,
									},
									{
										id: "Remaining",
										header: "Remaining",
										resolver: (row: LineItemRow) => `$${row.remainingBudget.toFixed(2)}`,
									},
								]}
							/>
						</FormControl>
					)}

					<div className={submitContainer}>
						<ZenButton
							type={"button"}
							altKind={"secondary"}
							marginRight={"15px"}
							onClick={() => {
								setSelectedPlanLine(undefined)
								setAssignLine(undefined)
							}}
						>
							Cancel
						</ZenButton>
						<ZenButton onClick={onAssignLine}>Assign</ZenButton>
					</div>
				</div>
			)}

			{showConfirmModal && (
				<ZenConfirmModal
					open={showConfirmModal}
					setOpen={setShowConfirmModal}
					loading={submitting}
					title={"Are you sure you want to migrate?"}
					message={"Are you sure you want to migrate the charges from these lines to the currently active NDIS plan lines?"}
					action={"Migrate"}
					confirm={() => {
						migrateSessionsSaveHandler()
						setShowConfirmModal(false)
					}}
					kind="primary"
				/>
			)}

			<CancelAndSaveButtons cancelLabel="Cancel" cancelFn={props.onClose} saveLabel="Migrate" saveFn={() => setShowConfirmModal(true)} isLoading={submitting} />
		</ZenCard>
	)
}

const formatPlanLabel = (p: NDISPlan) => {
	return `(${friendlyDate(p.planStartDate)} - ${friendlyDate(p.planEndDate)})`
}
