import * as React from "react"
import { useStyletron } from "baseui"
import { Notification } from "baseui/notification"
import { LabelMedium } from "baseui/typography"
import moment from "moment-timezone"
import { useParameterizedQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import { fetching } from "../../../fetching"
import { ImageUpload } from "../../../pages/admin/imageUpload"
import { WorkerFormData } from "../../../pages/workers/workerCreate"
import { minimumAgeMonths } from "../../../types/enums"
import { CheckAvailability } from "../../../types/types"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { ZenInput } from "../../zenComponents/zenInput"
import { ZenDatePicker } from "../../zenComponents/zenTime"
import { routes } from "routes"
import { ErrorFieldTracker } from "../../forms/errorFieldTracker"

export const WorkerGeneralInfo = (props: WorkerFormData) => {
	const { data, setData } = props
	const history = useHistory()
	const { handleSubmit, watch, errors, control, setValue } = useForm()
	const [avatar, setAvatar] = React.useState<File | undefined>(data.avatar)

	// check email
	const { query, payload: emailAvailability } = useParameterizedQuery<CheckAvailability>(fetching.query.checkEmailAvailability)
	const email = watch("email")
	const handleCheckAvailability = React.useCallback(() => {
		if (!email) return
		query(email)
	}, [email, query])

	// pre-fill
	const prefillData = React.useCallback(() => {
		setValue("firstName", data.firstName)
		setValue("lastName", data.lastName)
		setValue("email", data.email)
		setValue("dateOfBirth", data.dateOfBirth || new Date(new Date().setFullYear(new Date().getFullYear() - 18)))
	}, [data, setValue])
	React.useEffect(() => {
		prefillData()
	}, [prefillData])

	React.useEffect(() => {
		handleCheckAvailability()
	}, [handleCheckAvailability])

	const onSubmit = (formData: any) => {
		storeData(formData)
		history.push(routes.workers.create.contact)
	}

	const storeData = (formData: any) => {
		setData({
			...data,
			avatar: avatar,
			firstName: formData.firstName,
			lastName: formData.lastName,
			email: formData.email,
			dateOfBirth: formData.dateOfBirth,
		})
	}

	const [css] = useStyletron()
	const container = css({
		width: "50%",
		height: "100%",
		"@media only screen and (max-width: 900px)": {
			width: "100%",
		},
	})
	const avatarContainer = css({
		width: "225px",
		display: "flex",
		alignItems: "center",
	})
	const avatarContainerInner = css({
		width: "100%",
	})
	const nameContainer = css({
		width: "100%",
		marginLeft: "15px",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
		},
	})

	return (
		<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
			<LabelMedium>New Worker</LabelMedium>
			<div className={group}>
				<div className={avatarContainer}>
					<div className={avatarContainerInner}>
						<ImageUpload
							label="Avatar"
							name="avatar"
							imageURL={""}
							imageRemoved={false}
							file={avatar}
							setFile={(file?: File) => setAvatar(file)}
							previewHeight="200px"
							maxFileSize={1e7}
						/>
					</div>
				</div>
				<div className={nameContainer}>
					<ZenInput placeholder="Enter worker first name" label="First name" nameRef="firstName" inputError={errors.firstName} formRef={control} required />
					<ZenInput placeholder="Enter worker last name" label="Last name" nameRef="lastName" inputError={errors.lastName} formRef={control} required />
				</div>
			</div>
			<ZenDatePicker
				clearable
				label="Date of birth"
				formName="dateOfBirth"
				placeholder="Enter Date of birth"
				formRef={control}
				inputError={errors.dateOfBirth}
				value={data.dateOfBirth || moment().subtract(minimumAgeMonths, "month").toDate()}
				minDate={new Date("1900-01-01")}
				maxDate={moment().subtract(minimumAgeMonths, "month").toDate()}
			/>
			<ZenInput
				label="Email"
				nameRef="email"
				placeholder="Enter email. eg. name@example.com"
				inputError={errors.email}
				formRef={control}
				formRules={{
					required: "email is required",
					pattern: {
						value: /.+@.+\..+/,
						message: "Invalid email address",
					},
				}}
			/>

			{emailAvailability && !emailAvailability.available && <Notification kind="negative">{email} already taken</Notification>}
			<ErrorFieldTracker errorIDs={Object.keys(errors)} />
			<CancelAndSaveButtons saveFnDisabled={emailAvailability && !emailAvailability.available} cancelFn={history.goBack} saveLabel="Next" />
		</form>
	)
}
