import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { Responsive, WidthProvider } from "react-grid-layout"
import { NoDataText, ZenCard } from "../../../components/common"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { fetching } from "../../../fetching"
import { NDISPlanStatus } from "../../../types/enums"
import { ClientDetail, NDISPlan, RolePermission, ZenDateFormat } from "../../../types/types"
import { AddPlan } from "./addPlan"
import { PlanDetails } from "./planDetails"
import { PlanNote } from "./planNote"
import { AuthContainer } from "../../../controllers/auth"
import { ErrorNotification } from "../../../components/errorBox"
import { ListTable } from "../../../components/listTable"
import { StatefulTooltip } from "baseui/tooltip"
import moment from "moment/moment"
import { minsStr } from "../../../helpers/utils"
import { RowActions } from "../../../components/rowActions"
import { useForm } from "react-hook-form"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { ZenSelect } from "../../../components/zenComponents/zenSelectBox"
import { Value } from "baseui/select"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"

const ResponsiveGridLayout = WidthProvider(Responsive)

const layouts = {
	lg: [
		{ w: 8, h: 10, x: 0, y: 0, i: "plan-list", static: true },
		{ w: 8, h: 10, x: 0, y: 10, i: "plan-details", static: true },
		{ w: 4, h: 20, x: 8, y: 0, i: "plan-notes", static: true },
	],
	md: [
		{ w: 7, h: 9, x: 0, y: 0, i: "plan-list", static: true },
		{ w: 7, h: 11, x: 0, y: 9, i: "plan-details", static: true },
		{ w: 3, h: 19, x: 7, y: 0, i: "plan-notes", static: true },
	],

	sm: [
		{ w: 6, h: 9, x: 0, y: 0, i: "plan-list", static: true },
		{ w: 6, h: 7, x: 0, y: 9, i: "plan-details", static: true },
		{ w: 6, h: 8, x: 0, y: 16, i: "plan-notes", static: true },
	],
	xs: [
		{ w: 4, h: 10, x: 0, y: 0, i: "plan-list", static: true },
		{ w: 4, h: 10, x: 0, y: 10, i: "plan-details", static: true },
		{ w: 4, h: 10, x: 0, y: 20, i: "plan-notes", static: true },
	],
	xxs: [
		{ w: 2, h: 12, x: 0, y: 0, i: "plan-list", static: true },
		{ w: 2, h: 12, x: 0, y: 12, i: "plan-details", static: true },
		{ w: 2, h: 12, x: 0, y: 24, i: "plan-notes", static: true },
	],
}

interface ClientNDISProps {
	client: ClientDetail
	loading: boolean
	refetchClient: () => Promise<QueryResponse<ClientDetail>>
}

export const NDIS = (props: ClientNDISProps) => {
	const { client, refetchClient } = props
	const { hasPermission } = AuthContainer.useContainer()
	const [css] = useStyletron()
	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexGrow: 1,
		flexDirection: "column",
		overflowY: "auto",
	})
	const itemCls = css({
		maxWidth: "100% !important",
		display: "flex",
		overflowY: "auto",
		overflowX: "hidden",
	})
	const noteCard = css({
		maxWidth: "100% !important",
		display: "flex",
		flexDirection: "column",
		overflowY: "auto",
		overflowX: "hidden",
	})
	const ndisInfoContainer = css({
		flex: "unset !important",
		marginBottom: "8px",
		maxWidth: "100% !important",
	})
	const group = css({
		display: "flex",
	})
	const ndisInfo = css({
		display: "flex",
		alignItems: "center",
		width: "100%",
	})
	const planListContainer = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
		flex: 1,
		width: "100%",
	})
	const ndisInfoForm = css({
		minWidth: "300px",
		maxWidth: "500px !important",
	})

	const [selectedPlan, setSelectedPlan] = React.useState<NDISPlan>()
	const [activePlan, setActivePlan] = React.useState<NDISPlan>()
	const [newPlanOpen, setNewPlanOpen] = React.useState(false)
	const [newLineOpen, setNewLineOpen] = React.useState(false)
	const [editPlan, setEditPlan] = React.useState<NDISPlan>()

	const { payload: plans, loading, error, query: refetch } = useQuery(fetching.query.getClientNDISPlans(props.client.id))

	React.useEffect(() => {
		if (!!plans && plans.length > 0) {
			setActivePlan(plans.find((p) => p.status !== NDISPlanStatus.Inactive))
			if (selectedPlan) {
				// Update selected plan on plans refetch
				const updatedPlan = plans.find((p) => p.id === selectedPlan.id)
				if (updatedPlan) setSelectedPlan(updatedPlan)
			} else {
				// Set default selected plan
				setSelectedPlan(plans[0])
			}
		} else {
			setActivePlan(undefined)
		}
	}, [plans, selectedPlan])

	const onNewPlanSuccess = (plan: NDISPlan) => {
		refetch()
		refetchClient()
		setNewPlanOpen(false)
		setSelectedPlan(plan)
		setNewLineOpen(true)
	}

	const onUpdatePlanSuccess = () => {
		refetch()
		refetchClient()
		setEditPlan(undefined)
	}

	// NDIS client edit
	const { control, getValues, errors, trigger, setValue } = useForm()
	const [editNDISDetail, setEditNDISDetail] = React.useState(false)
	const getNDISContractArea = useQuery(fetching.query.getNDISContractAreas())
	const clientNDISDetailUpdate = useMutation(fetching.mutation.clientNDISDetailUpdate)
	const onCloseNDISDetailModal = () => {
		setValue("ndisNumber", client.ndisNumber)
		setValue("contractArea", client.contractArea ? [client.contractArea] : [])
		setEditNDISDetail(false)
	}

	return (
		<div className={container}>
			<ResponsiveGridLayout measureBeforeMount={true} layouts={layouts} rowHeight={(window.innerHeight - 142) / 25}>
				<div className={planListContainer} key={"plan-list"}>
					<ZenCard className={ndisInfoContainer}>
						<div className={group}>
							<div className={ndisInfo}>
								<LabelMedium>NDIS Number: </LabelMedium>
								<LabelMedium marginLeft="8px">{client.ndisNumber || "N/A"}</LabelMedium>
							</div>
							<div className={ndisInfo}>
								<LabelMedium>Contract Area: </LabelMedium>
								<LabelMedium marginLeft="8px">{client.contractArea?.label || "N/A"}</LabelMedium>
							</div>

							{hasPermission(RolePermission.NDISPlanUpdate) && (
								<div>
									<ZenButton onClick={() => setEditNDISDetail(true)}>{!client.ndisNumber || !client.contractAreaID ? "Set" : "Edit"}</ZenButton>
								</div>
							)}
						</div>
					</ZenCard>
					<ZenCard className={itemCls}>
						<PlanList
							client={client}
							setNewPlanOpen={setNewPlanOpen}
							selectedPlan={selectedPlan}
							setSelectedPlan={setSelectedPlan}
							setEditPlan={setEditPlan}
							error={error}
							loading={loading}
							plans={plans}
						/>
					</ZenCard>
				</div>
				<ZenCard className={itemCls} key={"plan-details"}>
					{selectedPlan && (
						<PlanDetails
							plan={selectedPlan}
							ndisNumber={client?.ndisNumber || ""}
							activePlan={activePlan}
							onSuccess={onUpdatePlanSuccess}
							newLineOpen={newLineOpen}
							setNewLineOpen={setNewLineOpen}
						/>
					)}
					{!selectedPlan && <NoDataText label={"Select a plan to view details"} />}
				</ZenCard>
				<ZenCard className={noteCard} key={"plan-notes"}>
					{selectedPlan && <PlanNote plan={selectedPlan} onNoteUpdate={onUpdatePlanSuccess} />}
					{!selectedPlan && <NoDataText label={"Select a plan to view notes"} />}
				</ZenCard>
			</ResponsiveGridLayout>
			{newPlanOpen && (
				<ZenModal isOpen={newPlanOpen} onClose={() => setNewPlanOpen(false)} size="auto" autoFocus={false}>
					<AddPlan onSuccess={onNewPlanSuccess} onCancel={() => setNewPlanOpen(false)} clientID={client.id} currentPlans={plans || []} refetchPlans={refetch} />
				</ZenModal>
			)}
			{editPlan && (
				<ZenModal isOpen={!!editPlan} onClose={() => setEditPlan(undefined)} size="auto" autoFocus={false}>
					<AddPlan
						onSuccess={onUpdatePlanSuccess}
						onCancel={() => setEditPlan(undefined)}
						clientID={editPlan.clientID}
						plan={editPlan}
						currentPlans={plans || []}
						refetchPlans={refetch}
					/>
				</ZenModal>
			)}
			{editNDISDetail && (
				<ZenModal isOpen={editNDISDetail} size="default" autoFocus={false} onClose={onCloseNDISDetailModal}>
					<ZenCard className={ndisInfoForm}>
						<ZenInput label="NDIS number" formRef={control} nameRef="ndisNumber" inputError={errors.ndisNumber} required value={client.ndisNumber} />
						<ZenSelect
							label="Contract area"
							formRef={control}
							formName="contractArea"
							inputError={errors.contractArea}
							options={getNDISContractArea.payload ? getNDISContractArea.payload : []}
							value={client.contractArea ? [client.contractArea] : []}
							formRules={{
								validate: {
									required: (v: Value) => v.length > 0 || "Contract area is required",
								},
							}}
						/>
						<CancelAndSaveButtons
							cancelFn={onCloseNDISDetailModal}
							saveFn={async () => {
								// validate
								const isValid = await trigger(["ndisNumber", "contractArea"])
								if (!isValid) return

								const formData = getValues()

								const resp = await clientNDISDetailUpdate.mutate({
									clientID: client.id,
									ndisNumber: formData.ndisNumber,
									contractAreaID: formData.contractArea && formData.contractArea.length > 0 ? formData.contractArea[0].id : undefined,
								})

								if (resp.error || !resp.payload) return
								setEditNDISDetail(false)
								refetchClient()
							}}
							isLoading={clientNDISDetailUpdate.loading}
						/>
					</ZenCard>
				</ZenModal>
			)}
		</div>
	)
}

interface PlanListProps {
	client?: ClientDetail
	setNewPlanOpen: (open: boolean) => void
	selectedPlan?: NDISPlan
	setSelectedPlan: (plan: NDISPlan) => void
	setEditPlan: (plan: NDISPlan) => void
	error: boolean
	loading: boolean
	plans?: NDISPlan[]
}
const PlanList = (props: PlanListProps) => {
	const { setNewPlanOpen, selectedPlan, setSelectedPlan, setEditPlan, error, loading, plans, client } = props
	const { hasPermission } = AuthContainer.useContainer()

	const [css] = useStyletron()
	const plansHeading = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})

	if (!client) {
		return (
			<div>
				<div className={plansHeading}>
					<LabelLarge>Plans</LabelLarge>
				</div>
			</div>
		)
	}

	return (
		<div>
			<div className={plansHeading}>
				<LabelLarge>Plans</LabelLarge>
				{hasPermission(RolePermission.NDISPlanCreate) && (
					<StatefulTooltip
						content={() => {
							let disableReasons: string[] = []
							if (!client.ndisNumber) disableReasons.push("Client's NDIS number is not set")
							if (!client.contractAreaID) disableReasons.push("Client's NDIS contract area is not set")

							if (disableReasons.length > 0) {
								return (
									<>
										<LabelSmall color="white">Unable to create NDIS plan because:</LabelSmall>
										{disableReasons.map((dr, i) => (
											<LabelSmall key={i} color="white" marginLeft="4px">
												- {dr}
											</LabelSmall>
										))}
									</>
								)
							}
							return null
						}}
						popoverMargin={6}
						returnFocus
						autoFocus
						placement="leftTop"
					>
						<div>
							<ZenButton onClick={() => setNewPlanOpen(true)} disabled={!client.ndisNumber || !client.contractAreaID}>
								New Plan
							</ZenButton>
						</div>
					</StatefulTooltip>
				)}
			</div>
			{error && <ErrorNotification messageOrPayload={plans} />}
			<ListTable
				isLoading={loading}
				rows={plans ? plans.sort((a, b) => b.planStartDate.localeCompare(a.planStartDate)).sort((a, b) => a.status.localeCompare(b.status)) : []}
				selectedID={selectedPlan?.id}
				onRowClick={(row: NDISPlan) => {
					setSelectedPlan(row)
				}}
				columns={[
					{
						id: "PlanStart",
						header: "Start Date",
						resolver: (row: NDISPlan) => moment(row.planStartDate).format(ZenDateFormat),
					},
					{
						id: "PlanEnd",
						header: "End Date",
						resolver: (row: NDISPlan) => moment(row.planEndDate).format(ZenDateFormat),
					},
					{
						id: "Status",
						header: "Status",
						resolver: (row: NDISPlan) => row.status.charAt(0) + row.status.slice(1).toLowerCase(),
					},
					{
						id: "BuddySessionHours",
						header: "Buddy Session Hours",
						resolver: (row: NDISPlan) => minsStr(row.buddySessionMinutes),
					},
					{
						id: "PriceType",
						header: "Price Type",
						resolver: (row: NDISPlan) => row.priceType.charAt(0) + row.priceType.slice(1).toLowerCase(),
					},
					{
						id: "Actions",
						header: "",
						resolver: (row: NDISPlan) =>
							hasPermission(RolePermission.NDISPlanUpdate) && (
								<RowActions
									menuItems={[
										{
											icon: "pencil",
											label: !client.ndisNumber || !client.contractAreaID ? "Can't edit plan till both NDIS number and contract area are set" : "Edit Plan",
											onClick: () => {
												if (!client.ndisNumber || !client.contractAreaID) return
												setEditPlan(row)
											},
										},
									]}
								/>
							),
					},
				]}
			/>
		</div>
	)
}
