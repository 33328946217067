import * as React from "react"
import { LabelLarge } from "baseui/typography"
import moment from "moment-timezone"
import { useForm } from "react-hook-form"
import { useStyletron } from "styletron-react"

import { Spacer, ZenCard } from "../../components/common"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenDatePicker } from "../../components/zenComponents/zenTime"
import { ErrorNotification } from "../../components/errorBox"
import { useParameterizedQuery, useQuery } from "react-fetching-library"
import { fetching } from "../../fetching"
import { getErrorFromBlob, snakeToTitle } from "../../helpers/utils"
import { Option, Value } from "baseui/select"
import { ZenSelect, ZenUserSelect } from "../../components/zenComponents/zenSelectBox"
import { FundingSource, SubSupportType, SupportType } from "../../types/types"
import { ZenCheckbox } from "../../components/zenComponents/zenCheckboxList"
import { Options } from "http-proxy-middleware"

interface FormData {
	sessionType: Option[];
	attendanceStatus: Option[];
	startDate: string;
	endDate: string;
	user?: Option[];
	fundingSource?: Option[];
	contractArea?: Option[];
	onlySingleClientSessions: boolean;
	onlyMultiClientsSessions: boolean;
	summaryOnly: boolean;
	support?: Option[];
	subSupport?: Option[];
}


enum SessionType {
	individualGeneral = "INDIVIDUAL_(_GENERAL_)",
	individualNonNDIS = "INDIVIDUAL_(_NON_NDIS_)",
	groupClientSupport = "GROUP_(_CLIENT_SUPPORT_)",
	groupNonClientSupport = "GROUP_(_NON_CLIENT_SUPPORT_)",
	groupHealthPromotion = "GROUP_(_HEALTH_PROMOTION_)",
}

const attendanceStatusOptions: Options[] = [
	{ id: "ATTENDED", label: snakeToTitle("ATTENDED") } as Options,
	{ id: "DNA", label: snakeToTitle("DNA") } as Options,
	{ id: "SHORT_NOTICE_CANCELLATION", label: snakeToTitle("SHORT_NOTICE_CANCELLATION") } as Options,
	{ id: "ENDED_EARLY", label: snakeToTitle("ENDED_EARLY") } as Options,
	{ id: "CANCELLED", label: snakeToTitle("CANCELLED") } as Options,
]

const singleSessionType = [SessionType.individualGeneral, SessionType.individualNonNDIS]

export const ContractHoursReport = () => {
	const [css] = useStyletron()
	const card = css({
		height: "fit-content",
		width: "fit-content",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		flexDirection: "column",
	})
	const buttonRow = css({
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "15px",
	})
	const group = css({
		display: "flex",
		justifyContent: "space-between",
	})

	const [isSummaryOnly, setIsSummaryOnly] = React.useState<boolean>(false)
	const [sessionTypeOptions, setSessionTypeOptions] = React.useState<Option[]>([])

	const { control, handleSubmit, getValues, errors, setValue, watch } = useForm<FormData>()
	const { query, loading, error } = useParameterizedQuery(fetching.query.getContractHourReport)
	const fundingSourceAll = useQuery(fetching.query.getFundingSourceAll(false, true))
	const fundingSourceWithGroupClientSupport = useQuery(fetching.query.getFundingSourceWithGroupClientSupport())
	const contractAreaList = useParameterizedQuery(fetching.query.getContractAreasByFundingSourceID)
	const [contractAreaOptions, setContractAreaOptions] = React.useState<Option[]>([])
	const getContractAreaOptions = (fundingSourceID: string) => {
		contractAreaList.query(fundingSourceID).then((resp) => {
			if (resp.error || !resp.payload) return
			setContractAreaOptions(resp.payload)
		})
	}

	//Support Options dropdown
	const supportTypeList = useParameterizedQuery(fetching.query.getSupportTypesByFundingSourceID)
	const [supportTypeOptions, setSupportTypeOptions] = React.useState<Option[]>([]) //updated
	const getSupportTypeOptions = (fundingSourceID: string) => {
		supportTypeList.query(fundingSourceID).then((resp) => {
			if (resp.error || !resp.payload) return
			setSupportTypeOptions(resp.payload)
		})
	}

	//Support Options dropdown
	const subSupportTypeList = useParameterizedQuery(fetching.query.getSubSupportTypesBySupportTypeID)
	const [subSupportTypeOptions, setSubSupportTypeOptions] = React.useState<Option[]>([]) //updated

	// Function to fetch and set SubSupportType options
	const getSubSupportTypeOptions = (fundingSourceID: string, supportTypeID: string) => {
		subSupportTypeList.query({ fundingSourceID, supportTypeID }).then((resp) => {
			if (resp.error || !resp.payload) return
			setSubSupportTypeOptions(resp.payload)
		})
	}

	const [fundingSourceOptions, setFundingSourceOptions] = React.useState<FundingSource[]>([])

	const watchSessionType = watch("sessionType")

	const sessionTypesWithAll = React.useMemo(() => {
		const allSessionTypes = Object.values(SessionType).map(st => ({ id: st, label: snakeToTitle(st) }));
		return [{ id: 'ALL', label: 'All Session Types' }, ...allSessionTypes];
	}, []); // Empty dependency array ensures this only runs once

	React.useEffect(() => {
		setSessionTypeOptions(sessionTypesWithAll);

		if (!fundingSourceAll.loading && !fundingSourceAll.error && fundingSourceAll.payload) {
			setFundingSourceOptions(fundingSourceAll.payload);
		}
	}, [sessionTypesWithAll, fundingSourceAll.loading, fundingSourceAll.error, fundingSourceAll.payload]); // Adjusted dependencies

	const [errorMsg, setErrorMsg] = React.useState<string>()

	// Utility function to delay execution
	const delay = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

	const onSubmit = async (data: FormData) => {
		setErrorMsg(undefined);

		// Helper function to download a report for a given sessionType
		const downloadReport = async (sessionType: SessionType | 'ALL') => {
			// Adjust the sessionType for the payload
			const sessionTypeParam = sessionType === 'ALL' ? 'ALL' : sessionType;

			// Dynamically build the filename for each report
			let filenameBase = `contract_hours_${data.summaryOnly ? 'summary_' : ''}`;
			let sessionTypePart = sessionType === 'ALL' ? 'all' : sessionType.toLowerCase().replace("_(", "").replace("_)", "");
			let clientSessionPart = '';
			if (data.onlySingleClientSessions) clientSessionPart = "_single_client_only";
			if (data.onlyMultiClientsSessions) clientSessionPart = "_multi_client_only";
			const datePart = `${moment(data.startDate).format("YYYYMMDD")}_${moment(data.endDate).format("YYYYMMDD")}`;
			const filename = `${filenameBase}${sessionTypePart}${clientSessionPart}_${datePart}.xlsx`;

			// Prepare your API request payload with the specific sessionType
			const apiPayload = {
				sessionType: sessionTypeParam,
				onlyMultiClientsSessions: data.onlyMultiClientsSessions,
				onlySingleClientSessions: data.onlySingleClientSessions,
				summaryOnly: data.summaryOnly,
				startDate: new Date(data.startDate),
				endDate: new Date(data.endDate),
				workerIDs: data.user ? data.user.map<string>((u) => u.id?.toString() || "") : undefined,
				fundingSourceIDs: data.fundingSource ? data.fundingSource.map<string>((f) => f.id?.toString() || "") : undefined,
				contractAreaIDs: data.contractArea ? data.contractArea.map<string>((f) => f.id?.toString() || "") : undefined,
				attendanceStatus: data.attendanceStatus ? data.attendanceStatus.map<string>((f) => f.id?.toString() || "") : undefined,
				supportTypeIDs: data.support ? data.support.map<string>((s) => s.id?.toString() || "") : undefined,
				subSupportTypeIDs: data.subSupport ? data.subSupport.map<string>((s) => s.id?.toString() || "") : undefined,
			};


			// Log the payload before sending it
			console.log("Sending API payload:", apiPayload);

			// Make the request and handle the response
			const resp = await query(apiPayload);
			if (resp.error || !resp.payload) {
				console.error('Error downloading report:', await getErrorFromBlob(resp.payload));
				// Optionally, handle the error more gracefully in the UI
			} else {
				// Create link and initiate download when response is received
				const downloadLink = document.createElement("a");
				downloadLink.href = URL.createObjectURL(resp.payload);
				downloadLink.download = filename; // Use the dynamically built filename
				document.body.appendChild(downloadLink);
				downloadLink.click();
				downloadLink.remove();
			}
		};

		// Handle "ALL" or specific session types for downloading reports
		if (data.sessionType.some(st => st.id === 'ALL')) {
			// Assuming getAllSessionTypes() returns an array of all session types' IDs
			const allSessionTypes = Object.values(SessionType); // Use actual logic to retrieve all session types
			for (const sessionType of allSessionTypes) {
				await downloadReport(sessionType); // Sequentially download each report
			}
		} else {
			for (const option of data.sessionType) {
				if (typeof option.id === 'string') {
					await downloadReport(option.id as SessionType | 'ALL');
					await delay(2000); // Wait for 1 second before the next download
				}
			}
		}

	};



	return (
		<ZenCard className={card}>
			<form autoComplete="off" className={title} onSubmit={handleSubmit(onSubmit)}>
				<LabelLarge marginBottom="20px">Export Contract Hours Report</LabelLarge>
				<ZenCheckbox
					marginTop="15px"
					marginBottom="5px"
					label="Summary Only"
					formName="summaryOnly"
					formRef={control}
					width="100%"
					checked={isSummaryOnly}
					actionOnChange={(checked) => {
						setIsSummaryOnly(checked)
						setValue("sessionType", [])
						let sessionTypes = Object.values(SessionType).map((st) => ({ id: st, label: snakeToTitle(st) }));
						if (checked) sessionTypes.splice(sessionTypes.length - 1, 1)
						setSessionTypeOptions(sessionTypes)
					}}
				/>
				<ZenSelect
					options={sessionTypeOptions}
					label="Session Type"
					formName="sessionType"
					clearable={false}
					formRef={control}
					multi={true} // This enables multi-selection
					inputError={errors.sessionType}
					actionOnChange={(v) => {
						// clear funding source option if NDIA is set
						setValue("fundingSource", [])
						setValue("contractArea", [])
					
						if (!fundingSourceAll.payload || !fundingSourceWithGroupClientSupport.payload) return
					
						// if values is cleared or "ALL" is selected
						if (v.length === 0 || !v[0].id || v.some(option => option.id === 'ALL')) {
							setFundingSourceOptions(fundingSourceAll.payload)
							return
						}

						switch (v[0].id.toString()) {
							case SessionType.individualGeneral:
								// show all the funding sources
								setFundingSourceOptions(fundingSourceAll.payload)
								break
							case SessionType.individualNonNDIS:
							case SessionType.groupNonClientSupport:
								// filter out NDIA option
								setFundingSourceOptions(fundingSourceAll.payload.filter((f) => f.label !== "NDIA"))
								break
							case SessionType.groupClientSupport:
								// only show the funding sources that have client support support type
								setFundingSourceOptions(fundingSourceWithGroupClientSupport.payload)
								break
						}
					}}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || `Session Type is required`,
						},
					}}
				/>
				{
					watchSessionType && watchSessionType.length > 0 && !isSummaryOnly &&
					<ZenSelect
						multi={true}
						options={attendanceStatusOptions}
						label="Attendance Status (Optional)"
						formName="attendanceStatus"
						clearable={true}
						formRef={control}
						inputError={errors.attendanceStatus}
					/>
				}
				{watchSessionType &&
					watchSessionType.length > 0 &&
					watchSessionType[0].id &&
					!isSummaryOnly &&
					singleSessionType.includes(watchSessionType[0].id.toString() as SessionType) && (
						<>
							<ZenCheckbox
								marginTop="15px"
								marginBottom="5px"
								label="Only Include SINGLE client sessions"
								formName="onlySingleClientSessions"
								formRef={control}
								width="100%"
								actionOnChange={(checked) => {
									if (checked) {
										setValue("onlyMultiClientsSessions", false)
									}
								}}
							/>
							<ZenCheckbox
								marginTop="5px"
								marginBottom="5px"
								label="Only Include MULTIPLE clients sessions"
								formName="onlyMultiClientsSessions"
								formRef={control}
								width="100%"
								actionOnChange={(checked) => {
									if (checked) {
										setValue("onlySingleClientSessions", false)
									}
								}}
							/>
						</>
					)}

				<div className={group}>
					<div>
						<ZenDatePicker
							inputError={errors.startDate}
							nullDefaultValue={true}
							formName="startDate"
							label="Start Date"
							formRef={control}
							formRules={{
								required: "You must select a start date.",
							}}
						/>
					</div>
					<Spacer style={{ width: 15 }} />
					<div>
						<ZenDatePicker
							inputError={errors.endDate}
							nullDefaultValue={true}
							formName="endDate"
							label="End Date"
							formRef={control}
							formRules={{
								required: "You must select an end date.",
								validate: (value: string) => {
									if (moment(value).isBefore(moment(getValues("startDate")))) {
										return "End date must be after start date."
									}
									return null
								},
							}}
						/>
					</div>
				</div>
				{
					!isSummaryOnly && <ZenUserSelect label={"Worker (Optional)"} formName={"user"} formRef={control} />
				}
				<ZenSelect
					options={fundingSourceOptions}
					label="Funding Source (Optional)"
					formName={"fundingSource"}
					formRef={control}
					disabled={
						watchSessionType &&
						watchSessionType.length > 0 &&
						!!watchSessionType[0].id &&
						watchSessionType.every(option => option.id !== 'ALL') && // Ensure dropdown is not disabled when "ALL" is selected
						!singleSessionType.includes(watchSessionType[0].id.toString() as SessionType)
					}
					

					actionOnChange={(v) => {
						// Clear previous options
						setContractAreaOptions([]);
						setSupportTypeOptions([]); // Clear previous support type options
						setValue("contractArea", []);
						setValue("support", []); // Clear any selected support types
						setValue("subSupport", []); // Assuming you want to clear this as well

						if (v.length === 0 || !v[0].id) return;

						const fundingSourceID = v[0].id.toString();
						// Fetch new contract area options based on the selected funding source
						getContractAreaOptions(fundingSourceID);
						// Fetch new support type options based on the selected funding source
						getSupportTypeOptions(fundingSourceID);
					}}

				/>
				<ZenSelect
					options={contractAreaOptions}
					label="Contract Area (Optional)"
					formName={"contractArea"}
					formRef={control}
					disabled={contractAreaOptions.length === 0}
				/>
				<ZenSelect
					options={supportTypeOptions}
					label="Support Type (Optional)"
					formName={"support"}
					formRef={control}
					// Inside your Support Type dropdown component
					// Inside your Support Type dropdown component
					actionOnChange={(selectedOptions) => {
						// Clear existing sub-support type options as we're about to fetch new ones
						setSubSupportTypeOptions([]);

						// Use optional chaining to avoid errors if selectedOptions is empty
						const supportTypeID = selectedOptions[0]?.id?.toString();

						// Retrieve fundingSourceId from the form's state
						const fundingSourceArray = getValues("fundingSource") || [];
						const fundingSourceID = fundingSourceArray[0]?.id?.toString();

						// Fetch new sub-support types only if both IDs are defined
						if (fundingSourceID && supportTypeID) {
							getSubSupportTypeOptions(fundingSourceID, supportTypeID);
						} else {
							// If one or both IDs are undefined, ensure subSupport is cleared to avoid stale options
							setValue("subSupport", []);
							console.log("Missing fundingSourceID or supportTypeID");
						}
					}}

				/>
				<ZenSelect
					options={subSupportTypeOptions}
					label="Sub Support Type (Optional)"
					formName={"subSupport"}
					formRef={control}
					// For the Sub Support Type dropdown
					actionOnChange={(selectedOptions) => {
						// Transform and set subSupport based on selected options correctly
						const transformedOptions = selectedOptions.map(option => ({
							id: option.id, // Ensure this matches the structure of `Option`
							label: option.label,
						}));

						setValue("subSupport", transformedOptions);
					}}

				/>

				{error && <ErrorNotification message={errorMsg} />}
				<div className={buttonRow}>
					<ZenButton type="submit" isLoading={loading}>
						Export
					</ZenButton>
				</div>
			</form>
		</ZenCard>
	)
}
