import * as React from "react"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal"
import { Option, Value } from "baseui/select"
import { LabelMedium } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import { useMutation } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { fetching } from "../../fetching"
import { TravelExpenseInput } from "../../fetching/inputType"
import { currencyRegex } from "../../helpers/utils"
import { ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { ZenFileUploader } from "../fileUploader"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenInput } from "../zenComponents/zenInput"
import { ZenSelect } from "../zenComponents/zenSelectBox"

interface AddExpensesModalProps {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSubmit: (data: TravelExpenseInput) => void
}

interface AddExpenseForm {
	expenseType: { id: string; value: string }[]
	amount: string
	file?: File
}

export const AddExpensesModal = (props: AddExpensesModalProps) => {
	const [css] = useStyletron()
	const fileName = css({
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	})

	const { isOpen, setIsOpen } = props
	const { errors, control, watch, setValue, handleSubmit } = useForm()
	const uploadFile = useMutation(fetching.mutation.fileUpload)

	const onSubmit = async (formData: AddExpenseForm) => {
		let fileID: string | undefined = undefined
		if (formData.file) {
			const resp = await uploadFile.mutate({ file: formData.file })
			// dont submit if file upload has failed/not complete
			if (!resp.payload) return

			fileID = resp.payload.fileID
		}

		props.onSubmit({ expenseType: formData.expenseType[0].id, amount: formData.amount, fileName: formData.file?.name, blobID: fileID })
	}

	const file = watch("file")
	const handleFileSelected = (selectedFile: File) => {
		setValue("file", selectedFile)
	}

	const handleDelete = () => {
		if (uploadFile.loading) return
		setValue("file", undefined)
	}

	const expenseTypes: Option[] = [
		{
			id: "Parking",
			label: "Parking",
		},
	]

	return (
		<ZenModal
			onClose={() => setIsOpen(false)}
			isOpen={isOpen}
			overrides={{
				Close: {
					style: {
						display: "none",
					},
				},
			}}
			autoFocus={false}
		>
			<ModalHeader>Add Travel Expense</ModalHeader>
			<ModalBody>
				<ZenSelect
					label={"Expense Type"}
					formName={"expenseType"}
					formRef={control}
					inputError={errors.expenseType}
					placeholder={"Select expense type"}
					value={[expenseTypes[0]]}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Expense type is required",
						},
					}}
					options={expenseTypes}
					creatable
				/>
				<ZenInput
					startEnhancer={<span>$</span>}
					label={"Amount"}
					nameRef={"amount"}
					inputError={errors.amount}
					formRef={control}
					type="number"
					formRules={{
						pattern: {
							value: currencyRegex,
							message: "Please enter a valid amount",
						},
						required: "Amount is required",
					}}
				/>

				<ZenFileUploader onFileAccepted={handleFileSelected} disabled={!!file} />
				{file && (
					<ZenCard className={fileName}>
						<LabelMedium>{file.name}</LabelMedium>
						<ZenButton altKind="danger" width={"30px"} type="button" onClick={handleDelete}>
							x
						</ZenButton>
					</ZenCard>
				)}
			</ModalBody>
			<ModalFooter>
				{uploadFile.error && <ErrorNotification messageOrPayload={uploadFile.payload} />}
				<Block display="flex" justifyContent="space-between" alignItems="center" width="100%">
					<ZenButton onClick={() => setIsOpen(false)} altKind="danger" isLoading={uploadFile.loading}>
						Cancel
					</ZenButton>
					<ZenButton onClick={handleSubmit(onSubmit)} isLoading={uploadFile.loading}>
						Add
					</ZenButton>
				</Block>
			</ModalFooter>
		</ZenModal>
	)
}
