import * as React from "react"
import { useStyletron } from "baseui"
import { ZenCard } from "../../common"
import { LabelLarge } from "baseui/typography"

const InfoScaleDescriptions = [
	"Not Known",
	"They are experiencing difficulty with managing this area and are unsure what to do",
	"They have started to work on this area, but they still need frequent support",
	"They are managing this area some of the time, but they still need regular support",
	"They feel they are progressing well to manage this area and only need occasional support",
	"They believe they have the skills to manage this area their own",
]

export const WellbeingInfoCard = () => {
	const [css, theme] = useStyletron()
	const cardStyle = css({
		padding: "20px",
		height: "fit-content",
		fontSize: "14px",
	})
	const flexStyle = css({
		display: "flex",
	})
	const scaleInfoStyle = css({
		marginTop: "20px",
	})
	const numberBoxStyle = css({
		borderWidth: "2px",
		borderColor: theme.colors.primary,
		borderRadius: "3px",
		backgroundColor: "white",
		fontSize: "16px",
		fontWeight: "bold",
		width: "26px",
		height: "26px",
		minWidth: "26px",
		minHeight: "26px",
		display: "flex",
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center",
		marginRight: "16px",
		marginBottom: "12px",
	})
	const descStyle = css({
		marginBottom: "12px",
	})

	return (
		<ZenCard className={cardStyle}>
			<LabelLarge>What is the Wellbeing Plan?</LabelLarge>
			<div>
				<p>
					This Wellbeing Plan will help them to identify the important areas of their life where they may be experiencing challenges. By identifying the areas of
					their life where they need help, their support staff can guide them with planning goals and learning new skills and strategies to best support their
					personal wellbeing in these areas.
				</p>
				<p>
					To start this Wellbeing Plan, score each area of wellbeing using the 5-point scale below and add a reason for why they gave that score, identify their
					current strengths and things they would like to develop. It is okay to leave blank any areas which they feel are not relevant for them, they may find some
					areas are more important, or urgent, than others.
				</p>
				<p>
					Over the course of their support, it is important that this Wellbeing Plan is regularly reviewed with their support staff to make sure the client and the staff
					are both on the same path in terms of providing them with the support they really need. It will also reveal their progress in each area.
				</p>
			</div>
			<LabelLarge>5-point scale</LabelLarge>
			<div className={scaleInfoStyle}>
				{InfoScaleDescriptions.map((s, i) => (
					<div className={flexStyle} key={`scale-desc-${s}`}>
						<div className={numberBoxStyle}>{i}</div>
						<div className={descStyle}>{s}</div>
					</div>
				))}
			</div>
		</ZenCard>
	)
}
