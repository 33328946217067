import * as React from "react"
import { LabelLarge } from "baseui/typography"
import { useFormContext } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { useStyletron } from "styletron-react"

import { CancelAndSaveButtons } from "../../../../cancelSaveButtons"
import { ZenTextArea } from "../../../../zenComponents/zenInput"
import { IntakeAdultProps } from "../intakeAdultBaseForm"

interface IntakeNDISSupportProps extends IntakeAdultProps {
	onSubmit: () => void
}
export const IntakeNDISSupport = (props: IntakeNDISSupportProps) => {
	const { isReadOnly, onSubmit } = props
	const [css] = useStyletron()
	const { control, getValues } = useFormContext()
	const history = useHistory()

	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
	})
	const formStyle = css({
		width: "100%",
		height: "95%",
		display: "flex",
		flexDirection: "column",
	})
	const body = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
	})
	return (
		<div className={container}>
			<LabelLarge>NDIS supports</LabelLarge>
			<form autoComplete="off" className={formStyle}>
				<div className={body}>
					<ZenTextArea
						resizable
						disabled={isReadOnly}
						label="Summary of Support Needs"
						formRef={control}
						getValues={getValues}
						nameRef="supportNeedsSummary"
						placeholder="(i.e. community/social participation, capacity/skill building, managing daily living needs, vocational/training/employment goals, maintaining physical wellbeing; or specific tasks client requires help with):"
					/>
					<ZenTextArea
						resizable
						disabled={isReadOnly}
						label="Are there any problems with current housing situation"
						nameRef="currentHousingSituationProblem"
						formRef={control}
						getValues={getValues}
					/>

					<ZenTextArea
						resizable
						disabled={isReadOnly}
						label="Specific Support Requests"
						formRef={control}
						getValues={getValues}
						nameRef="specificSupportRequests"
						placeholder="i.e. worker preference, hours available in plan; client availability; specific worker skills required"
					/>
					<ZenTextArea disabled={isReadOnly} label="Additional Comments" formRef={control} nameRef="additionalComment" fontSize={13} resizable />
				</div>
				{!isReadOnly && <CancelAndSaveButtons cancelLabel="Back" cancelFn={history.goBack} saveLabel="Submit" saveFn={onSubmit} />}
			</form>
		</div>
	)
}
