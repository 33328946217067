import * as React from "react"
import {useEffect, useState} from "react"
import { useParams } from "react-router-dom"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Checkbox } from "baseui/checkbox"
import { FormControl } from "baseui/form-control"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal"
import { Notification } from "baseui/notification"
import { ProgressBar } from "baseui/progress-bar"
import { OnChangeParams, Value, Select } from "baseui/select"
import { KIND } from "baseui/toast"
import { HeadingMedium, HeadingXSmall, LabelMedium, LabelSmall } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { CancelAndSaveButtons } from "../../components/cancelSaveButtons"
import { Divider, SearchAndFilter, ZenCard } from "../../components/common"
import { ErrorNotification } from "../../components/errorBox"
import { ErrorMessage } from "components/errorMessage"
import { FieldDisplay } from "../../components/fieldDisplay"
import { ListTable, useListTable } from "../../components/listTable"
import { Loading } from "../../components/loading"
import { SessionTravelSpanTable } from "../../components/sessions/sessionTravelSpanTable"
import { useZenToast } from "../../components/zenComponents/useZenToast"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenConfirmModal } from "../../components/zenComponents/zenConfirmModal"
import { ZenDivider, ZenTextArea } from "../../components/zenComponents/zenInput"
import { ZenPagination } from "../../components/zenComponents/zenPagination"
import { ZenUserSelect } from "../../components/zenComponents/zenSelectBox"
import { ZenUserDropdownSelect } from "../../components/dropdownSelect"
import { ZenDatePicker } from "../../components/zenComponents/zenTime"
import { AuthContainer } from "../../controllers/auth"
import { ReportsContainer } from "../../controllers/reports"
import { fetching } from "../../fetching"
import { GeneratedTimesheetResult, query, TimesheetDayData } from "../../fetching/query"
import { getErrorMessage } from "../../helpers/errors"
import { getTimesheetStatus, friendlyDate, minsStr, snakeToTitle, setTimezoneToTime, getTimesheetLatestStatusUpdateDate,getTimesheetLatestReviewer, emailNotifTemplate} from "../../helpers/utils"
import { ZenTheme } from "../../themeOverrides"
import { ReviewStatus } from "../../types/enums"
import { RolePermission, SearchTextMinLength, Session, SortOrder, Timesheet, TimesheetResult, Travel, TZString, ZenDateFormat, UserWithRole } from "../../types/types"
import { SessionViewBasic } from "../sessions/sessionView"
import { TravelView } from "../travels/travelView"
import { TimesheetPDFPreview } from "./pdfExports/timesheetExport"
import { ReviewStamp } from "../../components/reviewStamp"
import { base_host as host } from "../../host"
import { TimesheetNoteArea } from "./timesheetsNotes"

interface TimesheetCreateInput {
	periodStart: Date
	periodEnd: Date
	workers: Value
}

interface TimesheetCreateProps {
	onSuccess: () => void
	onCancel: () => void
	worker?: Value
	timesheet?: Timesheet
}

export const TimesheetManage = (props: TimesheetCreateProps) => {
	const { onSuccess, onCancel, worker, timesheet } = props
	const { showToast } = useZenToast()
	const [css] = useStyletron()
	const card = css({
		width: "60%",
		minWidth: "680px",
		padding: "20px",
	})

	const [validationErrors, setValidationErrors] = React.useState<string[]>([])
	const timesheetCreate = useMutation<TimesheetResult>(fetching.mutation.timesheetCreate)
	const timesheetUpdate = useMutation<TimesheetResult>(fetching.mutation.timesheetUpdate)

	const { control, handleSubmit, getValues, errors, setValue } = useForm()
	const { hasPermission } = AuthContainer.useContainer()

	// excluded IDs
	const [excludedUserID, setExcludedUserID] = React.useState<string[]>([])
	const onSubmit = async (d: TimesheetCreateInput) => {
		const userIDs: string[] = []
		for (const selection of d.workers) {
			if (selection.id) {
				userIDs.push(`${selection.id}`)
			}
		}
		const variables = {
			periodStart: setTimezoneToTime(moment(d.periodStart).startOf("day").toDate(), TZString[0].id),
			periodEnd: setTimezoneToTime(moment(d.periodEnd).endOf("day").toDate(), TZString[0].id),
			userIDs,
			timesheetID: timesheet?.id,
		}

		// updating
		if (variables.timesheetID) {
			const resp = await timesheetUpdate.mutate(variables)
			if (resp.error) return
			if (resp.payload && resp.payload.validationErrors && resp.payload.validationErrors.length !== 0) return
			showToast("Timesheet updated successfully.", "positive")
			return
		}

		const resp = await timesheetCreate.mutate(variables)
		if (resp.error) return
		if (resp.payload && resp.payload.validationErrors && resp.payload.validationErrors.length !== 0) return
		showToast("Timesheet created successfully.", "positive")
	}

	// set initial state
	React.useEffect(() => {
		setValue("workers", worker || [])

		if (timesheet) {
			setValue("workers", [timesheet.user] || [])
			setValue("periodStart", timesheet.periodStart)
			setValue("periodEnd", timesheet.periodEnd)
		}
	}, [setValue, worker, timesheet])

	// effect: On create
	React.useEffect(() => {
		if (!timesheetCreate.payload) return
		if (timesheetCreate.payload.validationErrors && timesheetCreate.payload.validationErrors.length > 0) {
			setValidationErrors(timesheetCreate.payload.validationErrors)
			return
		}
		onSuccess()
	}, [timesheetCreate.payload, onSuccess])

	// effect: On update
	React.useEffect(() => {
		if (!timesheetUpdate.payload) return
		if (timesheetUpdate.payload.validationErrors && timesheetUpdate.payload.validationErrors.length > 0) {
			setValidationErrors(timesheetUpdate.payload.validationErrors)
			return
		}
		onSuccess()
	}, [timesheetUpdate.payload, onSuccess])

	return (
		<ZenCard className={card}>
			<HeadingXSmall marginTop={0}>{!!props.timesheet ? "Edit Timesheet" : "Create Timesheets"}</HeadingXSmall>
			<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<ZenDatePicker
					formRef={control}
					label={"From"}
					formName={"periodStart"}
					inputError={errors.periodStart}
					formRules={{
						validate: {
							required: (value: string) => {
								if (!value) {
									return "You must select a start date"
								}
								const periodEnd = getValues("periodEnd")
								if (moment(value).isAfter(periodEnd)) {
									return "Start date must be before end date"
								}
								if (moment(value).isAfter(moment())) {
									return "Start date can not be in the future"
								}
								return null
							},
						},
					}}
				/>
				<ZenDatePicker
					formRef={control}
					label={"To"}
					formName={"periodEnd"}
					inputError={errors.periodEnd}
					formRules={{
						validate: {
							required: (value: string) => {
								if (!value) {
									return "You must select an end date"
								}
								const periodStart = getValues("periodStart")
								if (moment(value).isBefore(periodStart)) {
									return "End date must be after start date"
								}
								if (moment(value).isAfter(moment().add(1, "day"))) {
									return "End date can not be in the future"
								}
								return null
							},
						},
					}}
				/>
				<ZenUserSelect
					label="Search"
					formName="workers"
					multi={true}
					formRef={control}
					inputError={errors.worker}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "You must select at least one user",
						},
					}}
					disabled={!hasPermission(RolePermission.ReportCreate) || !!timesheet}
					actionOnChange={(v) => {
						// check null value
						if (!v || v.length === 0 || !v[0].id) {
							setExcludedUserID([])
							return
						}
						setExcludedUserID([v[0].id.toString()])
						setValue("worker", v)
					}}
					excludedID={excludedUserID}
				/>
				{validationErrors.length > 0 && (
					<Notification kind={KIND.negative} overrides={{ Body: { style: { width: "100%" } } }}>
						<div>There was a problem {timesheet?.id ? "updating timesheets" : "creating timesheets"}</div>
						<br />
						{validationErrors.map((value, key) => (
							<li key={key}>{value}</li>
						))}
					</Notification>
				)}
				{timesheetCreate.error && <ErrorNotification messageOrPayload={timesheetCreate.payload} />}
				{timesheetUpdate.error && <ErrorNotification messageOrPayload={timesheetUpdate.payload} />}

				<FormControl error={timesheetCreate.error || timesheetUpdate.loading}>
					<CancelAndSaveButtons cancelFn={() => onCancel()} isLoading={timesheetCreate.loading || timesheetUpdate.loading} />
				</FormControl>
			</form>
		</ZenCard>
	)
}

const PayrollTimesheets = (props: { onClick: () => void }) => {
	const { timesheetsReady } = ReportsContainer.useContainer()
	const [css] = useStyletron()
	const container = css({
		width: "100%",
		marginLeft: "10px",
		cursor: "pointer",
	})

	let message = ""
	if (timesheetsReady === 0) return null
	if (timesheetsReady === 1) {
		message = `There is ${timesheetsReady} timesheet ready to complete.`
	} else {
		message = `There are ${timesheetsReady} timesheets ready to complete.`
	}
	return (
		<div className={container} onClick={props.onClick}>
			<Notification
				kind={KIND.positive}
				overrides={{
					Body: { style: { width: "100%" } },
					InnerContainer: { style: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" } },
				}}
			>
				<Block>{message}</Block>
				<ZenButton altKind="tertiary">View all</ZenButton>
			</Notification>
		</div>
	)
}

export const TimeSheets = () => {
	const { getTimesheetsReady } = ReportsContainer.useContainer()
	const filterOptions: Value = [
		{ id: "All", label: "All" },
		{ id: "Draft", label: "Draft" },
		{ id: "Declined", label: "Declined" },
		{ id: "Pending", label: "Pending" },
		{ id: "Checked", label: "Checked" },
		{ id: "Approved", label: "Approved" },
		{ id: "Complete", label: "Complete" },
	]
	const { hasPermission } = AuthContainer.useContainer()
	const [filter, setFilter] = React.useState<Value>([filterOptions[0]])
	const [search, setSearch] = React.useState<string>("")
	const [selectedReportID, setSelectedReportID] = React.useState("")
	const [selectedRows, setSelectedRows] = React.useState<Timesheet[]>([])
	const [showBulkPDFModel, setShowBulkPDFModel] = React.useState(false)
	const [selectAll, setSelectAll] = React.useState(false)

	const onSelectAll = () => {
		setSelectAll(!selectAll)
		if (selectAll) {
			setSelectedRows([])
			return
		}
		setSelectedRows(rows)
	}

	const [rows, setRows] = React.useState<Timesheet[]>([])
	const { sortColumn, sortAsc, limit, offset, setTotal, total, setOffset, handleSort } = useListTable({
		sortColumn: "CreatedAt",
		sortAsc: false
	})

	const {
		payload: timesheets,
		loading,
		error,
		query,
	} = useQuery<{
		timesheets: Timesheet[]
		total: number
	}>(
		fetching.query.getManyTimesheets({
			search: {
				filterBy: filter[0]?.id?.toString(),
				search: search.length >= SearchTextMinLength ? search : undefined,
				sortBy: sortColumn,
				sortDir: sortAsc ? SortOrder.Ascending : SortOrder.Descending,
			},
			limit,
			offset,
		}),
	)

	React.useEffect(() => {
		if (!timesheets) return
		setRows(timesheets.timesheets)
		setTotal(timesheets.total)
	}, [setTotal, timesheets])

	React.useEffect(() => {
		setOffset(0)
	}, [search, setOffset])

	React.useEffect(() => {
		getTimesheetsReady()
	}, [getTimesheetsReady])

	const handleCloseReview = () => {
		setSelectedReportID("")
		query()
	}

	const handleRowClick = (id: string) => {
		if (hasPermission(RolePermission.ReportUpdate) || hasPermission(RolePermission.PayrollComplete)) {
			setSelectedReportID(id)
		}
	}

	const [css] = useStyletron()
	const title = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "14px",
	})

	return (
		<ZenCard style={{ height: "100%" }}>
			<div className={title}>
				<SearchAndFilter search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} filterOptions={filterOptions} />
				<PayrollTimesheets onClick={() => setFilter([{ id: "PayrollReady", label: "Payroll Ready" }])} />
			</div>
			{selectedRows.length > 0 && (
				<ZenButton type={"button"} width={"150px"} onClick={() => setShowBulkPDFModel(true)}>
					Preview &amp; Export
				</ZenButton>
			)}
			<ListTable
				error={error ? getErrorMessage(timesheets) : undefined}
				isLoading={loading}
				rows={rows}
				onRowClick={(row: Timesheet) => handleRowClick(row.id)}
				sortColumn={sortColumn}
				sortAsc={sortAsc}
				handleSort={handleSort}
				columns={[
					{
						id: "bulk",
						header: <Checkbox checked={selectAll} onChange={onSelectAll} />,
						resolver: (row: Timesheet) => (
							<div
								onClick={(e) => {
									e.stopPropagation()
								}}
							>
								<Checkbox
									checked={selectAll || selectedRows.some((r) => r.id === row.id)}
									onChange={(e) => {
										setSelectedRows((prev) => {
											if (e.currentTarget.checked) {
												return prev.concat(row)
											}
											return prev.filter((r) => r.id !== row.id)
										})
									}}
								/>
							</div>
						),
					},
					{
						id: "CreatedAt",
						header: "Created",
						sortable: true,
						resolver: (row: Timesheet) => friendlyDate(row.createdAt, TZString[0]),
					},
					{
						id: "User",
						header: "User",
						resolver: (row: Timesheet) => `${row.user.firstName} ${row.user.lastName}`,
					},
					{
						id: "From",
						header: "From",
						resolver: (row: Timesheet) => friendlyDate(row.periodStart, TZString[0]),
					},
					{
						id: "To",
						header: "To",
						resolver: (row: Timesheet) => friendlyDate(row.periodEnd, TZString[0]),
					},
					{
						id: "Status",
						header: "Status",
						resolver: (row: Timesheet) => snakeToTitle(getTimesheetStatus(!!row.completedAt, !!row.readyAt, !!row.checkedAt, row.reviewStatus)),
					},
					{
						id: "ReviewedAt",
						header: "Status Updated",
						resolver: (row: Timesheet) => {
							const latestDate = getTimesheetLatestStatusUpdateDate(row);
							return latestDate ? friendlyDate(latestDate, TZString[0]) : "";
						} // Correctly closed function block
					},					
					{
						id: "ReviewedBy",
						header: "Reviewed By",
						resolver: (row: Timesheet) => getTimesheetLatestReviewer(row)
					}
					
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />

			<Divider style={{ backgroundColor: "white" }} />
			{selectedReportID && <TimesheetReview reQuery={query} id={selectedReportID} isOpen={!!selectedReportID} onClose={handleCloseReview} />}
			<BulkPreviewExportModel show={showBulkPDFModel} timesheets={showBulkPDFModel ? selectedRows : []} onClose={() => setShowBulkPDFModel(false)} />
		</ZenCard>
	)
}

interface TimesheetReviewProps {
	id: string
	isOpen: boolean
	onClose: () => void
	reQuery: () => void
}

interface Params {
	workerID: string;
}

interface Params {
	workerID: string;
}

export const TimesheetReview = (props: TimesheetReviewProps) => {
	const { id, isOpen, onClose, reQuery } = props
	const [workerValue, setWorkerValue] = useState<Value>([])
	const [ fieldErrors, setFieldErrors] = useState<Record<string, any>>({});
	const [ approvalErr, setApprovalErr] = useState<boolean>(false)
	const { workerID } = useParams<Params>();
	const { getTimesheetsReady } = ReportsContainer.useContainer()
	const { showToast } = useZenToast()
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		height: "70vh",
		width: "95vw",
		flexWrap: "wrap",
		justifyContent: "center",
		position: "relative",
		overflow: "auto",
	})
	const footerContainer = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const totalsContainer = css({
		display: "flex",
		marginBottom: "10px",
		justifyContent: "space-between"
	})

	const declinedReasonButton = css({
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
	})
	const footerSubmit = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "10px",
		width: "450px",
	})
	const completeSubmit = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "10px",
		width: "500px",
	})

	const { hasPermission, currentUser } = AuthContainer.useContainer()
	const { firstName, lastName } = currentUser ?? {}
	const { error, loading, payload: timesheet, query: queryTimesheet } = useQuery(query.getTimesheet(id), false)
	const { timesheet: payloadTimesheet } = timesheet ?? {}
	const { periodEnd, periodStart, user } = payloadTimesheet ?? {}
	const { timezoneID, email: timesheetUserEmail, firstName: timesheetFirstName, lastName: timesheetLastName, id: timesheetUserId } = user ?? {}
	const completeTimesheet = useMutation<Timesheet>(fetching.mutation.timesheetComplete)
	const uncompleteTimesheet = useMutation<Timesheet>(fetching.mutation.timesheetUncomplete)
	const reviewTimesheet = useMutation<Timesheet>(fetching.mutation.timesheetReview)
	const unapproveTimesheet = useMutation(fetching.mutation.timesheetUnapprove)
	const readyTimesheet = useMutation(fetching.mutation.timesheetReady)
	const deleteTimesheet = useMutation(fetching.mutation.timesheetDelete)
	const checkTimesheet = useMutation(fetching.mutation.timesheetCheck)
	const [showDeleteModal, setShowDeleteModal] = React.useState(false)

	const [openCheckConfirmation, setOpenCheckConfirmation] = React.useState(false)
	const [openDeclineReasonModal, setOpenDeclineReasonModal] = React.useState(false)
	const { setValue, control, errors, handleSubmit } = useForm()
	const onSubmitDeclinedReason = async (formData: { declinedReason: string }) => {
		setOpenDeclineReasonModal(false)
		await handleDecision(ReviewStatus.Declined, formData.declinedReason)
	}

	const handleSendEmail = async (data: Value, timesheetUserEmail?: string) => {
		try {
			const status = snakeToTitle(getTimesheetStatus(!!payloadTimesheet?.completedAt, !!payloadTimesheet?.readyAt, !!payloadTimesheet?.checkedAt, payloadTimesheet?.reviewStatus as ReviewStatus))
			const timesheetStatus = 
				(status === "Draft" && payloadTimesheet?.reviewStatus === "PENDING") ? "Pending" : 
				status === "Pending" ? "Checked" : 
				status === "Checked" ? "Approved" : 
				status === "Approved" ? "Complete" : 
				''
			const { firstName: workerFirstName, lastName: workerLastName, email: workerEmail } = data?.[0] ?? [];
			const workerDetails = {
				firstName: workerFirstName,
				lastName: workerLastName
			};
			const isTimesheetUserEmail = !!timesheetUserEmail;
			const testemail = "byatong@aquip.io"; // will remove this part
			const email = isTimesheetUserEmail ? timesheetUserEmail : workerEmail;
			// const emailDetails = {
			// 	formName: 'Timesheet',
			// 	status: timesheetStatus,
			// 	receiverDetails: isTimesheetUserEmail ? 
			// 		{firstName: timesheetFirstName, lastName: timesheetLastName} 
			// 			: workerDetails,
			// 	startDate: `${moment(periodStart).tz(timezoneID as string).format("MMMM Do YYYY")}`,
			// 	endDate: `${moment(periodEnd).tz(timezoneID as string).format("MMMM Do YYYY")}`,
			// 	...(!timesheetUserEmail && {url: `${host.uri}/portal/workers/${workerID}`}),
			// 	bodyMsg: isTimesheetUserEmail ? 
			// 		"The timesheet is approved and sent to payroll for processing." 
			// 			: `There is a timesheet record for ${timesheetFirstName} ${timesheetLastName}, waiting for your review/approval.`,
			// };
			// const emailTemplate = emailNotifTemplate(emailDetails);
			const emailBody = {
				to: email,
				cc: [
					"cc1@example.com",
					"cc2@example.com"
				],
				bcc: [
					"bcc1@example.com",
					"bcc2@example.com"
				],
				subject: isTimesheetUserEmail ? "Timesheet Approved for Payroll Processing" : "Timesheet Review/Approval Reminder",
				// body: emailTemplate,
				condition: !isTimesheetUserEmail,
				formName: 'Timesheet',
				status: timesheetStatus,
				firstName: isTimesheetUserEmail ? timesheetFirstName?.trim() : workerFirstName?.trim(),
				lastName: isTimesheetUserEmail ? timesheetLastName?.trim() : workerLastName?.trim(),
				fromDate: `${moment(periodStart).tz(timezoneID as string).format("DD MMMM YYYY")}`,
				toDate: `${moment(periodEnd).tz(timezoneID as string).format("DD MMMM YYYY")}`,
				...(!timesheetUserEmail && {url: `${host.uri}/portal/workers/${workerID ? workerID : timesheetUserId}`}),
				bodyMsg: isTimesheetUserEmail ? 
					"The timesheet request is approved and sent to payroll for processing." 
						: `There is a timesheet record for ${timesheetFirstName?.trim()} ${timesheetLastName?.trim()}, waiting for your review/approval.`,
			};
			
			const response = await fetch(host.email_uri, {
				method: "POST",
				headers:{
					"Content-Type": "application/json",
				},
				body: JSON.stringify(emailBody)
			});

			if(response && response.ok) {
				setWorkerValue([]);
			}

			return response;
		} catch (error) {
			throw new Error(`Error in Timesheet handleSendEmail: ${error}`)
		}
	};

	const handleDecision = async (outcome: ReviewStatus, declinedReason?: string) => {
		if(!!declinedReason) {
			await reviewTimesheet.mutate({ id, outcome, declinedReason })
			await getTimesheetsReady()
			return;
		} else {
			if(!workerValue?.length || !!workerValue.some(value => value === undefined)) {
				setFieldErrors(
					(prev:Record<string, any>) => ({
						...prev, 
						['approved_by']: {
							type: "required",
							message: `"Submit to payroll" is required!`
						}
					})
				);
				return;
			};
			
			const response = await handleSendEmail(workerValue);
			if(response && response.ok) {
				await reviewTimesheet.mutate({ id, outcome, declinedReason })
				await getTimesheetsReady()
			};
		};
	}

	const handleComplete = async () => {
		if(!workerValue?.length || !!workerValue.some(value => value === undefined)) {
			setFieldErrors(
				(prev:Record<string, any>) => ({
					...prev, 
					['completed_by']: {
						type: "required",
						message:`"To be completed by" is required.`,
						modalErrorMsg: "Please select relevant team member from the list of workers."
					}
				})
			);
			return;
		};

		const response = await handleSendEmail(workerValue, timesheetUserEmail);
		if(response && response.ok) {
			await completeTimesheet.mutate({ id })
			setOpenCompleteConfirmation(false)
			await getTimesheetsReady()
		};
	}

	const handleUncomplete = async () => {
		await uncompleteTimesheet.mutate({ id })
		setOpenUncompleteConfirmation(false)
		await getTimesheetsReady()
	}
	
	const handleCheck = async () => {
		if(!workerValue?.length || !!workerValue.some(value => value === undefined)) {
			setApprovalErr(true);
			setFieldErrors(
				(prev:Record<string, any>) => ({
					...prev, 
					['checked_by']: { 
						type: "required",
						message:`"To be approved by" is required.`, 
						modalErrorMsg: `Please select relevant team member from the list of workers.`
					}
				})
			);
			return;
		};

		const response = await handleSendEmail(workerValue);
		if(response && response.ok) {
			await checkTimesheet.mutate(id)
			setOpenCheckConfirmation(false)
			await getTimesheetsReady()
		};
	}

	const handleUnapprove = async () => {
		await unapproveTimesheet.mutate({ timesheetID: id })
		setOpenUnapproveModal(false)
		await getTimesheetsReady()
	}

	React.useEffect(() => {
		if (
			!(
				reviewTimesheet.payload ||
				completeTimesheet.payload ||
				readyTimesheet.payload ||
				deleteTimesheet.payload ||
				unapproveTimesheet.payload ||
				checkTimesheet.payload ||
				uncompleteTimesheet.payload
			)
		)
			return
		setShowDeleteModal(false)
		if (
			reviewTimesheet.error ||
			completeTimesheet.error ||
			readyTimesheet.error ||
			deleteTimesheet.error ||
			unapproveTimesheet.error ||
			checkTimesheet.error ||
			uncompleteTimesheet.error
		)
			return
		reQuery()
		onClose()
	}, [
		reviewTimesheet.payload,
		completeTimesheet.payload,
		readyTimesheet.payload,
		deleteTimesheet.payload,
		reviewTimesheet.error,
		completeTimesheet.error,
		readyTimesheet.error,
		deleteTimesheet.error,
		unapproveTimesheet.payload,
		unapproveTimesheet.error,
		checkTimesheet.payload,
		checkTimesheet.error,
		uncompleteTimesheet.payload,
		uncompleteTimesheet.error,
		reQuery,
		onClose,
	])

	React.useEffect(() => {
		if (isOpen && id) {
			queryTimesheet()
		}
	}, [id, isOpen, queryTimesheet]);

	const handleSelectOnChange = (params: Value) => {
		const [values] = params;
		setFieldErrors({});
		setWorkerValue([values]);
	};

	const handleReadyTimesheet = async () => {
		if(!workerValue?.length || !!workerValue.some(value => value === undefined)) {
			setFieldErrors(
				(prev:Record<string, any>) => ({...prev, ['reviewed_by']: {type: "required",message: `"To be reviewed by" is required!`}})
			);
			return;
		};
		const response = await handleSendEmail(workerValue);

		if (response && response.ok) {
			await readyTimesheet.mutate(id);
			return;
		};
	};

	const handleCancel = (setter: React.Dispatch<React.SetStateAction<boolean>>, errKey: string) => {
		setter(false);
		setFieldErrors(
			(prev:Record<string, any>) => ({...prev, [`${errKey}`]: ``})
		);
		return;
	}

	const isLoading =
		loading || completeTimesheet.loading || uncompleteTimesheet.loading || reviewTimesheet.loading || readyTimesheet.loading || deleteTimesheet.loading

	const canMarkReady = (): boolean => {
		if (!currentUser || !timesheet) {
			return false
		}
		if (
			(currentUser.id === timesheet.timesheet.user.id || hasPermission(RolePermission.ReportUpdate)) &&
			(!timesheet.timesheet.readyAt || timesheet.timesheet.reviewStatus === ReviewStatus.Declined)
		) {
			return true
		}
		return false
	}

	// complete modal
	const [openCompleteConfirmation, setOpenCompleteConfirmation] = React.useState(false)
	const [openUncompleteConfirmation, setOpenUncompleteConfirmation] = React.useState(false)

	// un-approve model
	const [openUnapproveModal, setOpenUnapproveModal] = React.useState(false)

	if (error) return <ErrorNotification messageOrPayload={timesheet} />
	if (!timesheet?.timesheet) return null

	return (
		<>
			<ZenModal isOpen={isOpen} onClose={onClose} size={"auto"}>
				<ModalHeader>Timesheet Review</ModalHeader>
				<ModalBody className={container}>
					<ReportReviewBody data={timesheet} loading={loading} refetchTimesheet={queryTimesheet} />
					{openCompleteConfirmation && (
						<ZenModal isOpen={openCompleteConfirmation} onClose={() => setOpenCompleteConfirmation(false)} size="auto">
							<ModalHeader>
								<LabelMedium>{!!fieldErrors['completed_by']?.message ? fieldErrors['completed_by']?.modalErrorMsg : "Are you sure you want to mark current timesheet as complete?"}</LabelMedium>
							</ModalHeader>
							<ModalFooter>
								{/* <CancelAndSaveButtons cancelFn={() => setOpenCompleteConfirmation(false)} saveLabel="Confirm" saveFn={handleComplete} /> */}
								<CancelAndSaveButtons cancelFn={() => handleCancel( setOpenCompleteConfirmation, 'completed_by')} saveLabel="Confirm" saveFn={handleComplete} saveButtonKind={fieldErrors['completed_by']?.message ? 'danger' : undefined} />
							</ModalFooter>
						</ZenModal>
					)}
					{openUnapproveModal && (
						<ZenModal isOpen={openUnapproveModal} onClose={() => setOpenUnapproveModal(false)} size="auto">
							<ModalHeader>
								<LabelMedium>Are you sure you want to un-approve the timesheet?</LabelMedium>
							</ModalHeader>
							<ModalFooter>
								<CancelAndSaveButtons cancelFn={() => setOpenUnapproveModal(false)} saveLabel="Confirm" saveFn={handleUnapprove} />
							</ModalFooter>
						</ZenModal>
					)}
					{openUncompleteConfirmation && (
						<ZenModal isOpen={openUncompleteConfirmation} onClose={() => setOpenUncompleteConfirmation(false)} size="auto">
							<ModalHeader>
								<LabelMedium>Are you sure you want to reverse the timesheet back to approved state?</LabelMedium>
							</ModalHeader>
							<ModalFooter>
								<CancelAndSaveButtons cancelFn={() => setOpenUncompleteConfirmation(false)} saveLabel="Confirm" saveFn={handleUncomplete} />
							</ModalFooter>
						</ZenModal>
					)}
				</ModalBody>

				<ModalFooter>
					{completeTimesheet.error && <ErrorNotification messageOrPayload={completeTimesheet.payload} />}
					{reviewTimesheet.error && <ErrorNotification messageOrPayload={reviewTimesheet.payload} />}
					{readyTimesheet.error && <ErrorNotification messageOrPayload={readyTimesheet.payload} />}
					{deleteTimesheet.error && <ErrorNotification messageOrPayload={deleteTimesheet.payload} />}
					{unapproveTimesheet.error && <ErrorNotification messageOrPayload={unapproveTimesheet.payload} />}
					<div className={totalsContainer}>
						<LabelMedium>Total Hours: {minsStr(timesheet?.timesheet.totalMinutes) || 0}</LabelMedium>
					</div>
					<div className={footerContainer}>
						{canMarkReady() && (
							<>
								<ZenButton disabled={isLoading} isLoading={isLoading} altKind={"danger"} onClick={() => setShowDeleteModal(true)}>
									Delete
								</ZenButton>
								<form className={footerSubmit} autoComplete="off" onSubmit={handleSubmit(handleReadyTimesheet)}>
									<ZenUserDropdownSelect 
										label={"Reviewed by: "} 
										formName={"reviewed_by"} 
										formRef={control}
										inputError={fieldErrors["reviewed_by"]}
										placeholder="To be reviewed by.."
										actionOnChange={handleSelectOnChange}
										width={"360px"} 
									/>
									<ZenButton type="submit" disabled={isLoading} isLoading={isLoading}>
										{/* <LabelSmall color="white">Submit</LabelSmall> */}
										Submit
									</ZenButton>
								</form>
							</>
						)}
						{(hasPermission(RolePermission.ReportUpdate) || hasPermission(RolePermission.PayrollComplete)) && timesheet?.timesheet.readyAt && (
							<>
								{timesheet?.timesheet.reviewStatus === ReviewStatus.Pending && hasPermission(RolePermission.ReportUpdate) && (
									<>
										<div>
											<ZenButton marginRight={"20px"} disabled={isLoading} isLoading={isLoading} altKind={"danger"} onClick={() => setShowDeleteModal(true)}>
												Delete
											</ZenButton>
											<ZenButton altKind={"warning"} disabled={isLoading} isLoading={isLoading} onClick={() => setOpenDeclineReasonModal(true)}>
												Decline
											</ZenButton>
										</div>
										{timesheet.timesheet.checkedAt ? (
											<div>
												<div className={footerSubmit}>
													<ZenUserDropdownSelect 
														label={`Reviewed by: ${firstName} ${lastName ?? ''}`} 
														formName={"approved_by"} 
														formRef={control}
														inputError={fieldErrors["approved_by"]}
														placeholder="Submit to payroll"
														actionOnChange={handleSelectOnChange}
														width={"360px"} 
													/>
													<ZenButton
														type="submit"
														disabled={isLoading}
														isLoading={isLoading}
														onClick={() => {
															handleDecision(ReviewStatus.Approved)
														}}
													>
														Approve
													</ZenButton>
												</div>
											</div>
										) : (
											<div>
												<div className={footerSubmit}>
													<ZenUserDropdownSelect 
														label={`Checked By: ${firstName} ${lastName ?? ''}`} 
														formName={"reviewed_by"} 
														formRef={control}
														error={!!fieldErrors["checked_by"]?.message}
														inputError={fieldErrors["checked_by"]}
														placeholder="To be approved by.."
														actionOnChange={handleSelectOnChange}
														width={"360px"} 
													/>
													<ZenButton disabled={isLoading} isLoading={isLoading} onClick={() => setOpenCheckConfirmation(true)}>
														Mark Checked
													</ZenButton>
												</div>
											</div>
										)}
									</>
								)}
								{timesheet?.timesheet.reviewStatus === ReviewStatus.Approved && !timesheet.timesheet.completedAt && (
									<>
										{hasPermission(RolePermission.ReportUpdate) && (
											<ZenButton disabled={isLoading} altKind={"danger"} marginRight={"auto"} isLoading={isLoading} onClick={() => setOpenUnapproveModal(true)}>
												Un-approve
											</ZenButton>
										)}
										{hasPermission(RolePermission.PayrollComplete) && (
											<div>
												<div className={completeSubmit}>
													<ZenUserDropdownSelect 
														label={`Completed By: ${firstName} ${lastName ?? ''}`} 
														formName={"reviewed_by"} 
														formRef={control}
														error={!!fieldErrors["completed_by"]?.message}
														inputError={fieldErrors["completed_by"]}
														placeholder="Submit to payroll"
														actionOnChange={handleSelectOnChange}
														width={"360px"} 
													/>
													<ZenButton disabled={isLoading} marginLeft={"auto"} isLoading={isLoading} onClick={() => setOpenCompleteConfirmation(true)}>
														Payroll Complete
													</ZenButton>
												</div>
											</div>
										)}
									</>
								)}
								{timesheet?.timesheet.completedAt && (
									<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
										{/* <LabelMedium>
											Marked complete by {timesheet?.timesheet.completedBy?.firstName} {timesheet?.timesheet.completedBy?.lastName} on{" "}
											{moment(timesheet?.timesheet.completedAt).format(ZenDateFormat)}
										</LabelMedium> */}
										{hasPermission(RolePermission.PayrollComplete) && (
											<ZenButton
												disabled={isLoading}
												altKind={"danger"}
												marginTop="4px"
												isLoading={isLoading}
												onClick={() => setOpenUncompleteConfirmation(true)}
											>
												Payroll Uncomplete
											</ZenButton>
										)}
									</div>
								)}
							</>
						)}
					</div>
				</ModalFooter>
			</ZenModal>
			<ZenModal
				isOpen={openDeclineReasonModal}
				onClose={() => {
					setValue("declinedReason", "")
					setOpenDeclineReasonModal(false)
				}}
			>
				<ModalHeader>Declined Reason</ModalHeader>
				<ModalBody>
					<form autoComplete="off" onSubmit={handleSubmit(onSubmitDeclinedReason)}>
						<ZenTextArea
							formRef={control}
							nameRef="declinedReason"
							formRules={{ required: "Declined reason is required" }}
							inputError={errors.declinedReason}
						/>
						<ZenDivider borderColor="transparent" />
						<div className={declinedReasonButton}>
							<ZenButton type="submit">
								<LabelSmall color="white">Submit</LabelSmall>
							</ZenButton>
						</div>
					</form>
				</ModalBody>
			</ZenModal>
			<ZenConfirmModal
				title={"Are you sure you want to delete this timesheet?"}
				action={"Delete"}
				open={showDeleteModal}
				setOpen={setShowDeleteModal}
				loading={isLoading}
				confirm={async () => {
					const resp = await deleteTimesheet.mutate(id)

					if (resp.error) return
					showToast("Timesheet deleted successfully.", "positive")
				}}
			/>
			{openCheckConfirmation && (
				<ZenConfirmModal
					title={approvalErr ? fieldErrors['checked_by']?.modalErrorMsg :"Are you sure to mark current timesheet as checked for approval?"}
					action={"Mark Checked"}
					open={openCheckConfirmation}
					setOpen={setOpenCheckConfirmation}
					loading={checkTimesheet.loading}
					confirm={handleCheck}
					kind={approvalErr ? "danger" : "primary"}
					setFieldErrors={setFieldErrors}
					setApprovalErr={setApprovalErr}
				/>
			)}
		</>
	)
}

interface ReportReviewBodyProps {
	data: { timesheet: Timesheet; generatedTimesheet: GeneratedTimesheetResult }
	loading: boolean
	refetchTimesheet: () => void
}

const ReportReviewBody = (props: ReportReviewBodyProps) => {
	const [selectedTravel, setSelectedTravel] = React.useState<Travel | null>(null)
	const [selectedSession, setSelectedSession] = React.useState<Session | null>(null)
	const [timesheetRows, setTimesheetRows] = React.useState<TimesheetDayData[]>([])
	const [fullModalOpen, setFullModalOpen] = React.useState<boolean>(false)
	const [previewExportModalOpen, setPreviewExportModalOpen] = React.useState<boolean>(false)
	const [manageModalOpen, setManageModalOpen] = React.useState<boolean>(false)

	const { showToast } = useZenToast()

	const { data, loading } = props
	const { currentUser, hasPermission } = AuthContainer.useContainer()

	const detailsRef = React.useRef<HTMLDivElement>(null)

	const timesheetRegen = useMutation<TimesheetResult>(fetching.mutation.timesheetUpdate)

	const canEdit = (): boolean => {
		if (!data || !data.timesheet) return false

		const timesheet = data.timesheet
		const ready = !!(timesheet && !!timesheet.readyAt)

		if (timesheet.reviewStatus === ReviewStatus.Approved) {
			return false
		}

		// ready but no update perms
		if (ready && !hasPermission(RolePermission.ReportUpdate)) {
			return false
		}

		// no perms or not own  editing own
		if (!(hasPermission(RolePermission.ReportUpdate) || (currentUser && timesheet.user.id === currentUser.id))) {
			return false
		}
		return true
	}

	React.useEffect(() => {
		if (!data) return
		if (!data.generatedTimesheet.daysData) {
			setTimesheetRows([])
		}
		setTimesheetRows(data.generatedTimesheet.daysData)
	}, [data])

	const [css] = useStyletron()
	const leftColumn = css({
		flex: 1,
		display: "flex",
		flexDirection: "column",
		maxHeight: "100%",
		marginRight: "20px",
		width: "60%",
	})
	const rightColumn = css({
		display: "flex",
		minWidth: "40%",
		height: "100%",
		"@media only screen and (max-width: 1440px)": {
			width: "100%",
			height: "100%",
		},
	})
	const infoContainer = css({
		display: "flex",
		marginBottom: "10px",
	})
	const top = css({
		width: "100%",
		display: "flex",
		flexDirection: "column",
	})
	const buttons = css({
		width: "100%",
		display: "flex",
		marginTop: "10px",
	})
	const tableContainer = css({
		boxShadow: "#0000001A 0px 0px 6px",
		borderRadius: "3px",
		padding: "11px 14px 14px 14px",
		overflow: "auto",
		margin: "3px",
		width: "100% !important",
		height: "100%",
	})
	const greyArea = css({
		backgroundColor: ZenTheme.colors.lightGrey,
		height: "100%",
		width: "100%",
		"@media only screen and (max-width: 1440px)": {
			display: "none",
			height: "0%",
			width: "0%",
		},
	})

	const isNote = () => {
		setSelectedSession(null)
		setSelectedTravel(null)
	}

	if (loading) return <Loading />

	return (
		<>
			<div className={leftColumn}>
				<div className={infoContainer}>
					<div className={top}>
						<FieldDisplay label={"Created"}>
							<LabelSmall>{friendlyDate(data.timesheet.createdAt, TZString[0])}</LabelSmall>
						</FieldDisplay>

						<FieldDisplay label={"Worker"}>
							<LabelSmall>{`${data.timesheet.user.firstName} ${data.timesheet.user.lastName} | ${snakeToTitle(data.timesheet.user.status)}`}</LabelSmall>
						</FieldDisplay>
						<FieldDisplay label={"Period"}>
							<LabelSmall>
								{friendlyDate(data.timesheet.periodStart, TZString[0])} - {friendlyDate(data.timesheet.periodEnd, TZString[0])}
							</LabelSmall>
						</FieldDisplay>
						<div className={buttons}>
							{canEdit() && (
								<ZenButton marginRight={"10px"} onClick={() => setManageModalOpen(true)}>
									Edit Time Period
								</ZenButton>
							)}
							<ZenButton onClick={() => setFullModalOpen(true)}>View full size</ZenButton>
							<ZenButton marginLeft={"10px"} onClick={() => setPreviewExportModalOpen(true)}>
								Preview & Export
							</ZenButton>
							<ZenButton
								marginLeft={"10px"}
								onClick={() => {
									timesheetRegen
										.mutate({
											userIDs: [data.timesheet.userID],
											timesheetID: data.timesheet.id,
											periodStart: setTimezoneToTime(moment(data.timesheet.periodStart).startOf("day").toDate(), TZString[0].id),
											periodEnd: setTimezoneToTime(moment(data.timesheet.periodEnd).endOf("day").toDate(), TZString[0].id),
										})
										.then((resp) => {
											if (resp.error || !resp.payload) return
											if (resp.payload && resp.payload.validationErrors && resp.payload.validationErrors.length !== 0) return
											props.refetchTimesheet()
											setSelectedSession(null)
											setSelectedTravel(null)
											showToast("Timesheet re-generate successfully.", "positive")
										})
								}}
							>
								Timesheet Re-gen
							</ZenButton>
							<ZenButton marginLeft={"10px"} onClick={() => isNote()}>
								Notes
							</ZenButton>
						</div>
					</div>
					{data.timesheet.checkedBy && data.timesheet.checkedAt && (
						<ReviewStamp noticeType={"CHECKED"} byUser={data.timesheet.checkedBy} time={moment(data.timesheet.checkedAt)} />
					)}
					{data.timesheet.reviewedBy && data.timesheet.reviewedAt && (
						<>
							{data.timesheet.reviewStatus === ReviewStatus.Pending && (
								<ReviewStamp noticeType={"UNAPPROVED"} byUser={data.timesheet.reviewedBy} time={moment(data.timesheet.reviewedAt)} />
							)}
							{data.timesheet.reviewStatus !== ReviewStatus.Pending && (
								<ReviewStamp
									noticeType={data.timesheet.reviewStatus === ReviewStatus.Approved ? "APPROVED" : "DECLINED"}
									byUser={data.timesheet.reviewedBy}
									time={moment(data.timesheet.reviewedAt)}
									reason={data.timesheet.declinedReason}
								/>
							)}
						</>
					)}
					{data.timesheet.checkedBy && data.timesheet.completedBy && data.timesheet.reviewedBy && (
						<ReviewStamp noticeType={"PAYROLL COMPLETED"} byUser={data.timesheet.completedBy} time={moment(data.timesheet.completedAt)} />
					)}
				</div>

				<div className={tableContainer}>
					<div>
						<SessionTravelSpanTable
							fullView={true}
							timesheet={data.timesheet}
							scrollTo={() => detailsRef.current?.scrollIntoView({ behavior: "smooth" })}
							rows={timesheetRows}
							setSelectedTravel={setSelectedTravel}
							setSelectedSession={setSelectedSession}
						/>
					</div>
				</div>
			</div>
			<div className={rightColumn} ref={detailsRef}>
				{/* notes here */}
				{!selectedTravel && !selectedSession && (<div className={greyArea}><TimesheetNoteArea timesheet={data.timesheet} loading={props.loading} refetchTimesheet={props.refetchTimesheet}/></div>)}
				{selectedTravel && <TravelView id={selectedTravel.id} refetchTimesheet={props.refetchTimesheet} />}
				{selectedSession && (
					<SessionViewBasic
						identifier={selectedSession.identifier.toString()}
						inTimesheet
						refetchTimesheet={props.refetchTimesheet}
						setSelectedSession={setSelectedSession}
					/>
				)}
				{/* {!selectedSession && !selectedTravel && <div className={greyArea} />} */}
			</div>

			{fullModalOpen && (
				<FullSessionTravelSpanTable
					setIsOpen={setFullModalOpen}
					rows={timesheetRows}
					setSelectedTravel={setSelectedTravel}
					setSelectedSession={setSelectedSession}
				/>
			)}

			{previewExportModalOpen && <PreviewExportModal timesheetData={data.timesheet} setIsOpen={setPreviewExportModalOpen} rows={timesheetRows} />}

			{manageModalOpen && (
				<Modal isOpen={manageModalOpen} closeable={false} unstable_ModalBackdropScroll={true}>
					<TimesheetManage
						timesheet={data.timesheet}
						onSuccess={() => {
							setManageModalOpen(false)
							props.refetchTimesheet()
						}}
						onCancel={() => setManageModalOpen(false)}
					/>
				</Modal>
			)}
		</>
	)
}

interface FullSessionTravelSpanTableProps {
	setIsOpen: (b: boolean) => void
	rows: TimesheetDayData[]
	setSelectedSession: (s: Session | null) => void
	setSelectedTravel: (s: Travel | null) => void
}
const FullSessionTravelSpanTable = (props: FullSessionTravelSpanTableProps) => {
	const [css] = useStyletron()
	const tableContainer = css({
		marginTop: "50px",
	})

	return (
		<ZenModal isOpen={true} onClose={() => props.setIsOpen(false)} size="full">
			<ModalBody>
				<ZenCard className={tableContainer}>
					<SessionTravelSpanTable fullView rows={props.rows} setSelectedTravel={props.setSelectedTravel} setSelectedSession={props.setSelectedSession} />
				</ZenCard>
			</ModalBody>
		</ZenModal>
	)
}

interface PreviewExportModalProps {
	setIsOpen: (b: boolean) => void
	rows: TimesheetDayData[]
	timesheetData: Timesheet
}

const PreviewExportModal = (props: PreviewExportModalProps) => {
	const { rows, timesheetData } = props

	const [css] = useStyletron()
	const tableContainer = css({
		marginTop: "50px",
		height: "90vh",
	})

	return (
		<ZenModal isOpen={true} onClose={() => props.setIsOpen(false)} size="full">
			<ModalBody className={tableContainer}>
				<TimesheetPDFPreview
					timesheets={[
						{
							timesheetData,
							rows,
						},
					]}
				/>
			</ModalBody>
		</ZenModal>
	)
}

interface BulkPreviewExportModelProps {
	show: boolean
	timesheets: Timesheet[]
	onClose: () => void
}

const BulkPreviewExportModel = (props: BulkPreviewExportModelProps) => {
	const { show, timesheets } = props

	const [timesheetDayRows, setTimesheetDayRows] = React.useState<{ rows: TimesheetDayData[]; timesheetData: Timesheet }[]>([])
	const { query: timesheetQuery, error: timesheetError, payload: timesheetPayload } = useParameterizedQuery(fetching.query.getTimesheet)

	React.useEffect(() => {
		setTimesheetDayRows([])
	}, [timesheets])

	React.useEffect(() => {
		if (timesheetDayRows.length >= timesheets.length) return
		timesheetQuery(timesheets[timesheetDayRows.length].id)
	}, [timesheetQuery, timesheetDayRows, timesheets])

	React.useEffect(() => {
		setTimesheetDayRows((prev) => {
			if (!timesheetPayload) return prev
			if (!timesheets[prev.length]) return prev
			return prev.concat({
				timesheetData: timesheetPayload.timesheet,
				rows: timesheetPayload.generatedTimesheet.daysData,
			})
		})
	}, [timesheetPayload, timesheets])

	const [css] = useStyletron()
	const tableContainer = css({
		marginTop: "50px",
		height: timesheetDayRows.length === timesheets.length ? "90vh" : undefined,
	})

	return (
		<ZenModal isOpen={show && timesheets.length > 0} onClose={() => props.onClose()} size={timesheetDayRows.length === timesheets.length ? "full" : "auto"}>
			<ModalBody className={tableContainer}>
				{/* Show Progress Bar while loading */}
				{timesheetDayRows.length < timesheets.length && (
					<>
						<HeadingMedium>Generating Timesheet PDF</HeadingMedium>
						{timesheetError && <ErrorNotification messageOrPayload={"An error occurred while loading Timesheet data, please try again"} />}
						<ProgressBar
							value={Math.round((timesheetDayRows.length / timesheets.length) * 100)}
							overrides={{
								BarProgress: {
									style: ({ $theme, $value }) => {
										return {
											...$theme.typography.font350,
											backgroundColor: timesheetError ? $theme.colors.negative : $theme.colors.positive,
											color: $theme.colors.mono200,
											position: "relative",
											":after": {
												position: "absolute",
												content: $value > 5 ? `"${$value}%"` : "",
												right: "10px",
											},
										}
									},
								},
								Bar: {
									style: ({ $theme }) => ({
										height: $theme.sizing.scale800,
									}),
								},
							}}
						/>
					</>
				)}

				{/* PDF Preview + Export */}
				{timesheetDayRows.length === timesheets.length && (
					<TimesheetPDFPreview
						fileName={timesheets.length > 1 ? "Timesheet_bulk.pdf" : undefined}
						timesheets={timesheets.map((t) => {
							const data = timesheetDayRows.find((r) => r.timesheetData.id === t.id)
							return {
								timesheetData: data?.timesheetData || t,
								rows: data?.rows || [],
							}
						})}
					/>
				)}
			</ModalBody>
		</ZenModal>
	)
}
