import * as React from "react"
import { ZenCard } from "../components/common"
import { AuthContainer } from "../controllers/auth"
import { useStyletron } from "baseui"

import EventCalendar from "../components/eventCalendar"

export default function Schedule() {
	const { currentUser } = AuthContainer.useContainer()

	const [css, theme] = useStyletron()

	const upperPart = css({
		flex: 1,
		backgroundColor: theme.colors.white,
		overflowY: "auto",
		height: "100%",
		maxHeight: "100%",
		maxWidth: "unset !important",
	})

	return (
		<ZenCard className={upperPart}>
			<EventCalendar workerID={currentUser?.id} defaultView={"week"} hideBorder />
		</ZenCard>
	)
}
