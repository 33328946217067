import * as React from "react"
import { faCalendar } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button, KIND } from "baseui/button"
import { Datepicker, DatepickerProps } from "baseui/datepicker"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import TimePicker from "rc-time-picker"
import { Controller, FieldError } from "react-hook-form"

import { ZenTheme } from "../../themeOverrides"

interface ZenDatePickerProps extends DatepickerProps {
	label: string
	formName: string
	formRef: any
	formRules?: any
	inputError?: FieldError
	getValues?: () => any
	actionOnChange?: (v: Date) => void
	nullDefaultValue?: boolean // if true use null instead of new Date() as default value
	paddingTop?: string
	marginTop?: string
	marginBottom?: string
}
export const ZenDatePicker = (props: ZenDatePickerProps) => {
	const datePicker = React.useRef<Datepicker>()
	const [css, theme] = useStyletron()
	const {
		label,
		formName,
		formRef,
		formRules,
		inputError,
		value,
		actionOnChange,
		nullDefaultValue,
		paddingTop,
		getValues,
		disabled,
		marginBottom,
		marginTop,
		...rest
	} = props
	const displayMessage = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "fit-content",
		marginBottom: "8px",
	})
	if (disabled && getValues)
		return (
			<div className={displayMessage}>
				{label ? <LabelSmall marginBottom="2px">{label}</LabelSmall> : ""}
				<LabelSmall
					marginLeft="16px"
					overrides={{
						Block: {
							style: {
								whiteSpace: "pre-line",
								color: ZenTheme.colors.primaryGrey,
							},
						},
					}}
				>
					{getValues()[`${formName}`] ? moment(getValues()[`${formName}`]).format("DD/MM/YYYY") : ""}
				</LabelSmall>
			</div>
		)

	const handleDatePickerIconClick = () => {
		if (datePicker.current !== undefined) {
			datePicker.current.open()
		}
	}

	return (
		<>
			<FormControl
				label={<LabelSmall>{label}</LabelSmall>}
				error={inputError?.message || ""}
				overrides={{
					ControlContainer: {
						style: {
							marginBottom,
							marginTop,
						},
					},
				}}
			>
				<Controller
					name={formName}
					control={formRef}
					rules={formRules}
					defaultValue={value || (nullDefaultValue ? null : new Date())}
					render={({ value, onChange }) => (
						<Datepicker
							{...rest}
							ref={datePicker as any}
							disabled={disabled}
							minDate={new Date("1920")}
							value={typeof value === "string" ? new Date(value) : value}
							formatString={"dd/MM/yyyy"}
							placeholder={"DD/MM/YYYY"}
							onChange={({ date }) => {
								if (actionOnChange && date instanceof Date) {
									actionOnChange(date)
								}
								onChange(date)
							}}
							overrides={{
								Input: {
									component: (props: any) => (
										<Input
											data-lpignore="true"
											autoComplete="off"
											{...props}
											endEnhancer={
												!props.disabled && (
													<Button
														kind={KIND.minimal}
														shape="round"
														onClick={(e) => {
															e.preventDefault()
															e.stopPropagation()
															handleDatePickerIconClick()
														}}
													>
														<FontAwesomeIcon color={ZenTheme.colors.primaryGreen} icon={faCalendar} size="lg" />
													</Button>
												)
											}
											overrides={{
												Root: {
													style: ({ $disabled }: any) => ({
														paddingRight: 0,
														backgroundColor: "transparent",
														borderLeftWidth: 0,
														borderRightWidth: 0,
														borderTopWidth: 0,
														borderBottomWidth: $disabled ? 0 : "2px",
														borderBottomColor: theme.colors.black,
														height: "48px",
													}),
												},
												Input: {
													style: {
														paddingTop: paddingTop || "10px",
													},
												},
												InputContainer: {
													style: {
														backgroundColor: "transparent",
													},
												},
												EndEnhancer: {
													style: {
														paddingRight: 0,
														backgroundColor: "transparent",
													},
												},
											}}
										/>
									),
								},
							}}
						/>
					)}
				/>
			</FormControl>
		</>
	)
}

interface ZenTimePickerProps {
	label: string
	formName: string
	formRef: any
	width?: string
	actionOnChange?: (value: Date) => void
	formRules?: any
	inputError?: FieldError
	customLabel?: JSX.Element
	value?: moment.MomentInput
	disabled?: boolean

	/** @deprecated an old baseweb time picker prop that is no longer used */
	creatable?: boolean
	nullable?: boolean

	//** Date used for consistency, will always use this date for the year/month/day */
	date?: Date

	minuteStep?: number
	showTodayButton?: boolean
}
export const ZenTimePicker = (props: ZenTimePickerProps) => {
	const { label, formRef, formName, width, actionOnChange, inputError, customLabel, disabled, nullable, date, minuteStep } = props

	const [css, theme] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		width: width || "unset",
	})
	const timePickerStyle = css({
		borderBottom: disabled ? "2px solid transparent" : `2px solid ${theme.colors.black}`,
		minHeight: "48px",
		width: "100%",
	})

	return (
		<div className={container}>
			<FormControl
				error={inputError?.message || ""}
				label={customLabel ? customLabel : <LabelSmall>{label}</LabelSmall>}
				overrides={{
					ControlContainer: {
						style: {
							marginBottom: 0,
							minHeight: "48px",
						},
					},
				}}
			>
				<Controller
					name={formName}
					control={formRef}
					rules={props.formRules}
					render={({ value, onChange }) => (
						<TimePicker
							id={formName}
							className={timePickerStyle}
							disabled={disabled}
							value={value ? moment(value) : undefined}
							placeholder="HH:MM AM"
							showSecond={false}
							use12Hours={true}
							minuteStep={minuteStep || 1}
							hideDisabledOptions
							allowEmpty={nullable === true}
							onChange={(time) => {
								if (!time) return

								time.set("second", 0)

								// Align to date
								if (date) {
									time.set({
										year: date.getFullYear(),
										month: date.getMonth(),
										date: date.getDate(),
									})
								}

								if (actionOnChange) actionOnChange(time.toDate())
								onChange(time.toDate())
							}}
						/>
					)}
				/>
			</FormControl>
		</div>
	)
}
