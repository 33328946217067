import * as React from "react"
import { IconName } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Input, InputProps } from "baseui/input"
import { Textarea } from "baseui/textarea"
import { LabelSmall } from "baseui/typography"
import { Controller } from "react-hook-form"
import { FieldError } from "react-hook-form/dist/types"

import { borderRadiusStyle, ZenTheme } from "../../themeOverrides"

interface ZenLineProps {
	width?: string
	height?: string
	marginTop?: string
	marginBottom?: string
	backgroundColor?: string
}

export const ZenLine = ({ width, height, marginBottom, marginTop, backgroundColor }: ZenLineProps) => {
	const [css, theme] = useStyletron()
	const line = css({
		backgroundColor: backgroundColor || theme.colors.black,
		height: height || "2px",
		width: width || "100%",
		borderRadius: "2px",
		marginTop: marginTop || 0,
		marginBottom: marginBottom || 0,
	})

	return <div className={line} />
}

interface ZenDividerProps {
	width?: string
	height?: string
	borderWidth?: string
	borderStyle?: string
	borderColor?: string
	marginTop?: string
	marginBottom?: string
}
export const ZenDivider = (props: ZenDividerProps) => {
	const { width, height, borderColor, borderStyle, borderWidth, marginTop, marginBottom } = props
	const [css] = useStyletron()
	const divider = css({
		width: width || "100%",
		height: height || 0,
		borderWidth: borderWidth || "1px",
		borderColor: borderColor || ZenTheme.colors.lightGrey,
		borderStyle: borderStyle || "solid",
		marginTop: marginTop || "5px",
		marginBottom: marginBottom || 0,
	})
	return <div className={divider} />
}

interface ZenInputProps extends InputProps {
	label?: string
	icon?: IconName
	width?: string
	marginTop?: string
	marginBottom?: string
	marginLeft?: string
	inputTextAlign?: "left" | "center" | "right"
	containerBottom?: string
	paddingBottom?: string
	inputError?: FieldError
	height?: string
	nameRef: string
	formRef: any
	formRules?: any
	initialHeight?: number
	fontSize?: number | string
	getValues?: () => any
	actionOnChange?: (e: React.FormEvent<HTMLInputElement>) => void
	resizable?: boolean
	startEnhancer?: React.ReactNode
	maxHeight?: string
	customLabel?: React.ReactNode
	hideBorder?: boolean
}

export const ZenInput = ({
	paddingBottom,
	height,
	clearable,
	inputError,
	marginBottom,
	marginTop,
	marginLeft,
	inputTextAlign,
	width,
	placeholder,
	label,
	type,
	containerBottom,
	nameRef,
	formRef,
	formRules,
	value,
	required,
	disabled,
	actionOnChange,
	getValues,
	startEnhancer,
	customLabel,
	...rest
}: ZenInputProps) => {
	const [css, theme] = useStyletron()
	const containerStyles = css({
		marginTop: marginTop || 0,
		marginBottom: marginBottom || 0,
		marginLeft: marginLeft || 0,
		width: width || "100%",
	})
	let rules = formRules || {}
	if (required) {
		rules = { ...rules, required: `${label} is required` }
	}

	const displayMessage = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "fit-content",
		marginBottom: "8px",
	})

	if (disabled && getValues)
		return (
			<div className={displayMessage}>
				{customLabel ? customLabel : <LabelSmall marginBottom="2px">{label}</LabelSmall>}
				<LabelSmall
					marginLeft="16px"
					overrides={{
						Block: {
							style: {
								whiteSpace: "pre-line",
								color: ZenTheme.colors.primaryGrey,
							},
						},
					}}
				>
					{getValues()[`${nameRef}`]}
				</LabelSmall>
			</div>
		)

	return (
		<div className={containerStyles}>
			<FormControl
				label={customLabel ? customLabel : <LabelSmall>{label}</LabelSmall>}
				error={inputError?.message || ""}
				overrides={{
					ControlContainer: {
						style: {
							marginBottom: containerBottom || "3px",
						},
					},
				}}
			>
				<Controller
					name={nameRef}
					control={formRef}
					rules={rules}
					defaultValue={value || ""}
					render={({ value, name, onChange }) => (
						<Input
							{...rest}
							id={name}
							name={name}
							data-lpignore="true"
							autoComplete="off"
							startEnhancer={startEnhancer}
							disabled={disabled}
							clearable={!!clearable}
							value={value || ""}
							onChange={(e) => {
								if (actionOnChange) actionOnChange(e)
								onChange(e)
							}}
							placeholder={placeholder || ""}
							overrides={{
								Root: {
									style: ({ $disabled }) => ({
										backgroundColor: "transparent",
										borderLeftWidth: 0,
										borderTopWidth: 0,
										borderBottomWidth: $disabled ? 0 : "2px",
										borderBottomColor: theme.colors.black,
										borderRightWidth: 0,
										height: height || "48px",
									}),
								},
								Input: {
									style: {
										backgroundColor: "transparent",
										paddingBottom: paddingBottom || "10px",
										height: height || "auto",
										textAlign: inputTextAlign,
									},
								},
								InputContainer: {
									style: {
										backgroundColor: "transparent",
									},
								},
								MaskToggleButton: {
									style: {
										":hover": {
											backgroundColor: "transparent",
										},
										":focus": {
											backgroundColor: "transparent",
										},
										":active": {
											backgroundColor: "transparent",
										},
									},
								},
								StartEnhancer: {
									style: {
										backgroundColor: "transparent",
									},
								},
								EndEnhancer: {
									style: {
										backgroundColor: "transparent",
									},
								},
							}}
							type={type || "text"}
						/>
					)}
				/>
			</FormControl>
		</div>
	)
}

export const ZenTextArea = ({
	paddingBottom,
	inputError,
	marginBottom,
	marginTop,
	marginLeft,
	width,
	placeholder,
	label,
	nameRef,
	formRef,
	formRules,
	fontSize,
	value,
	disabled,
	initialHeight,
	resizable,
	maxHeight,
	getValues,
	hideBorder,
}: ZenInputProps) => {
	const [css] = useStyletron()
	// resizable textarea function
	const [, triggerResize] = React.useState<string>("")
	const textInputField = document.getElementById(`${nameRef}-comment`)
	const [scrollHeight, setScrollHeight] = React.useState<number>(initialHeight || 34)
	const getFontSize = (): number => {
		if (!fontSize) return 14
		const size = fontSize as number
		if (size > 30) {
			return 30
		} else if (size < 14) {
			return 14
		}

		return size
	}
	const containerStyles = css({
		marginTop: marginTop || "15px",
		marginBottom: marginBottom || "10px",
		marginLeft: marginLeft || 0,
		paddingBottom: paddingBottom || 0,
		width: width || "100%",
		height: "fit-content",
		borderRadius: "3px",
		border: !hideBorder ? "2px solid black" : undefined,
	})
	const autoResizeTextArea: string = css({
		overflowY: !maxHeight ? "hidden" : "auto",
		width: "100%",
		height: scrollHeight + "px",
		outline: "none",
		border: "none",
		padding: "5px",
		fontSize: `${getFontSize()}px`,
		resize: "none",
		fontFamily: "'Open Sans', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif",
		backgroundColor: "white",
		color: "black",
		maxHeight: maxHeight,
	})
	const displayMessage = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "fit-content",
		marginBottom: "8px",
	})

	// update the height of textarea
	if (textInputField) {
		let height = scrollHeight
		if (initialHeight && scrollHeight < initialHeight) height = initialHeight
		textInputField.style.height = "auto"
		textInputField.style.height = height + "px"
	}

	const actionOnChange = React.useCallback(
		(v: string) => {
			triggerResize(v)
			if (textInputField) {
				textInputField.style.height = "auto"
				const textInputScrollHeight = textInputField.scrollHeight
				if (textInputScrollHeight !== scrollHeight) setScrollHeight(textInputScrollHeight)
			}
		},
		[scrollHeight, textInputField],
	)
	React.useEffect(() => {
		// trigger resize
		actionOnChange("")
	}, [actionOnChange])

	// HACK: re-render resizable textarea at the start to force the height match the content
	React.useEffect(() => {
		if (resizable) actionOnChange("change")
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	// display label and message only, when disabled
	if (disabled && getValues)
		return (
			<div className={displayMessage}>
				{label ? <LabelSmall marginBottom="2px">{label}</LabelSmall> : ""}
				<LabelSmall
					marginLeft="16px"
					overrides={{
						Block: {
							style: {
								whiteSpace: "pre-line",
								color: ZenTheme.colors.primaryGrey,
							},
						},
					}}
				>
					{getValues()[`${nameRef}`]}
				</LabelSmall>
			</div>
		)

	const textareaComponent = () => {
		if (resizable)
			return (
				<Controller
					name={nameRef}
					disabled={disabled}
					control={formRef}
					rules={formRules}
					defaultValue={value || ""}
					render={({ value, name, onChange }) => (
						<div className={containerStyles}>
							<textarea
								name={name}
								id={`${nameRef}-comment`}
								className={autoResizeTextArea}
								placeholder={placeholder || " "}
								value={value || ""}
								disabled={disabled}
								onChange={(e) => {
									onChange(e)
									actionOnChange(e.currentTarget.value)
								}}
							/>
						</div>
					)}
				/>
			)
		return (
			<Controller
				name={nameRef}
				disabled={disabled}
				control={formRef}
				rules={formRules}
				defaultValue={value || ""}
				render={({ value, name, onChange }) => (
					<Textarea
						id={name}
						name={name}
						placeholder={placeholder || " "}
						value={value || ""}
						disabled={disabled}
						onChange={(e) => {
							onChange(e)
							actionOnChange(e.currentTarget.value)
						}}
						overrides={{
							InputContainer: {
								style: ({ $theme, $disabled, $error }: any) => ({
									...borderRadiusStyle,
									borderTopWidth: "2px",
									borderTopColor: $error ? $theme.colors.negative : $theme.colors.black,
									borderBottomWidth: "2px",
									borderBottomColor: $error ? $theme.colors.negative : $theme.colors.black,
									borderLeftWidth: "2px",
									borderLeftColor: $error ? $theme.colors.negative : $theme.colors.black,
									borderRightWidth: "2px",
									borderRightColor: $error ? $theme.colors.negative : $theme.colors.black,
									borderTopStyle: $disabled ? "unset" : "solid",
									borderBottomStyle: $disabled ? "unset" : "solid",
									borderLeftStyle: $disabled ? "unset" : "solid",
									borderRightStyle: $disabled ? "unset" : "solid",
									backgroundColor: $disabled ? "unset" : "solid",
									height: initialHeight ? `${initialHeight}px` : "unset",
								}),
							},
							Input: {
								style: {
									cursor: "text",
									fontSize: `${getFontSize()}px`,
								},
							},
						}}
					/>
				)}
			/>
		)
	}
	return (
		<FormControl
			label={label ? <LabelSmall>{label}</LabelSmall> : ""}
			error={inputError?.message || ""}
			overrides={{
				ControlContainer: {
					style: {
						marginBottom: 0,
					},
				},
			}}
		>
			{textareaComponent()}
		</FormControl>
	)
}

interface ZenSearchInputProps extends InputProps {
	paddingBottom?: string
	height?: string
	width?: string
}
export const ZenSearchInput = (props: ZenSearchInputProps) => {
	const { paddingBottom, height, width, ...rest } = props
	const [css, theme] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		marginRight: "10px",
	})
	return (
		<div className={container}>
			<Input
				{...rest}
				data-lpignore="true"
				autoComplete="off"
				endEnhancer={() => (
					<FontAwesomeIcon
						style={{
							fontWeight: 900,
						}}
						color="#caccd6"
						icon={["fal", "search"]}
						size={"1x"}
					/>
				)}
				overrides={{
					Root: {
						style: {
							borderLeftWidth: 0,
							borderTopWidth: 0,
							borderBottomWidth: "2px",
							borderBottomColor: theme.colors.black,
							borderRightWidth: 0,
						},
					},
					Input: {
						style: {
							backgroundColor: "transparent",
							paddingBottom: paddingBottom || "0",
							height: height || "auto",
							width: width || "100%",
							paddingTop: 0,
							paddingRight: 0,
							paddingLeft: 0,
						},
					},
					InputContainer: {
						style: {
							backgroundColor: "transparent",
						},
					},
					MaskToggleButton: {
						style: {
							":hover": {
								backgroundColor: "transparent",
							},
							":focus": {
								backgroundColor: "transparent",
							},
							":active": {
								backgroundColor: "transparent",
							},
						},
					},
					EndEnhancer: {
						style: {
							backgroundColor: "transparent",
							color: ZenTheme.colors.primaryGreen,
						},
					},
				}}
			/>
		</div>
	)
}
