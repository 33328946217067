import * as React from "react"
import { useStyletron } from "baseui"
import { StyledSpinnerNext } from "baseui/spinner"
import { LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { QueryResponse, useMutation } from "react-fetching-library"
import { Link } from "react-router-dom"

import { AuthContainer } from "../../../controllers/auth"
import { snakeToTitle } from "../../../helpers/utils"
import { ZenTheme } from "../../../themeOverrides"
import { RolePermission, Session, SessionType, TZString } from "../../../types/types"
import { Divider, ZenCard } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { ZenButton } from "../../zenComponents/zenButtons"
import { ZenDivider } from "../../zenComponents/zenInput"
import { SelectedItemCard } from "../../zenComponents/zenSelectBox"
import { DisplayLabel } from "../newClientAppointment/singleSessionConfirmation"
import { SessionMeetingType } from "../newClientAppointment/singleSessionGeneralForm"
import { DisplayAttendeeList, DisplayWorkerList } from "../newGroupAppointment/groupSessionConfirmation"
import { DisplayOrgCard } from "../organisationDisplay"
import { EditGroupSession } from "./sessionEdit/sessionGroupEdit"
import { EditSingleSession } from "./sessionEdit/sessionSingleEdit"
import { PostSessionDetail } from "./sessionPostDetail"
import { PortalContainer } from "../../../controllers/portal"
import { fetching } from "fetching"
import { ZenArchiveModal } from "components/zenComponents/zenArchiveDialog"
import { useZenToast } from "components/zenComponents/useZenToast"
import { StatefulTooltip } from "baseui/tooltip"

export interface SessionViewProps extends Session {
	displayMode: boolean
	setDisplayMode: React.Dispatch<React.SetStateAction<boolean>>
	refetch: () => Promise<QueryResponse<Session>>
	triggerQuerySessionList: React.Dispatch<React.SetStateAction<boolean>>

	inTimesheet?: boolean // different view when editing a session while viewing a timesheet
	refetchTimesheet?: () => void
}

interface SessionInfoProps extends Session {
	readonly?: boolean
	refetch: () => Promise<QueryResponse<Session>>
	triggerQuerySessionList: React.Dispatch<React.SetStateAction<boolean>>

	// timesheets
	inTimesheet?: boolean
	refetchTimesheet?: () => void
	setSelectedSession?: (value: React.SetStateAction<Session | null>) => void

	onViewPage?: boolean
}

export const SessionInfo = (props: SessionInfoProps) => {
	const { identifier, type, refetch, sessionFundingSources, refetchTimesheet, deletedAt, onViewPage, setSelectedSession } = props
	const [css] = useStyletron()
	const { showToast } = useZenToast()
	const isNonBillable = React.useMemo(() => {
		if (!sessionFundingSources || sessionFundingSources.length === 0 || sessionFundingSources[0].fundingSource.label !== "Non-billable") return false
		return true
	}, [sessionFundingSources])

	const { fetchIncompleteSessionAlert } = PortalContainer.useContainer()
	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const { lockDate } = PortalContainer.useContainer()
	const [displayMode, setDisplayMode] = React.useState<boolean>(true)
	const [showPostSession, setShowPostSession] = React.useState<boolean>(false)

	// toggle archive
	const toggleArchive = useMutation<Session>(fetching.mutation.sessionToggleArchive)
	const [showArchiveModal, setShowArchiveModal] = React.useState(false)

	const cantEditReason = React.useMemo<string[]>(() => {
		// skip the process if users have completed report manage permissions
		if (hasPermission(RolePermission.CompletedReportManage)) return []

		let errorMessages: string[] = []

		// return false if current user is not loaded
		if (!currentUser) {
			return ["Current user is not loaded"]
		}

		if (
			lockDate > moment(props.endTime).toDate() &&
			sessionFundingSources &&
			sessionFundingSources.length === 1 &&
			sessionFundingSources[0].fundingSource.label === "NDIA"
		) {
			errorMessages.push(`It is before the lock date ${moment(lockDate).format("DD/MM/YYYY")}`)
		}

		if (props.isLocked) {
			if (!!props.timesheets && props.timesheets.length > 0 && props.timesheets[0].completedAt) {
				errorMessages.push("It is linked to a completed timesheet")
			} else {
				errorMessages.push("It is linked to an approved timesheet")
			}
		}

		if (hasPermission(RolePermission.SessionUpdate)) return errorMessages

		// if the worker is not the dedicated worker of the session
		if (props.workerID !== currentUser.id && props.buddySessionMentor?.id !== currentUser.id) {
			errorMessages.push("Only workers involved in this session can edit the session")
		}
		if (!!props.timesheets && props.timesheets.length > 0 && props.timesheets[0].readyAt && !props.timesheets[0].completedAt) {
			errorMessages.push("It is claimed and waiting for supervisor to approve")
		}

		return errorMessages
	}, [currentUser, hasPermission, lockDate, props, sessionFundingSources])

	const displayInfo = () => {
		switch (type) {
			case SessionType.Single:
				if (displayMode) return <DisplaySingleSession {...props} />
				if (props.inTimesheet) {
					if (showPostSession) {
						return (
							<PostSessionDetail
								inTimesheet
								{...props}
								setDisplayMode={setDisplayMode}
								refetchCurrentSession={refetch}
								triggerQuerySessionList={() => refetch()}
								refetchTimesheet={refetchTimesheet}
							/>
						)
					}
					return <EditSingleSession {...props} inTimesheet displayMode={displayMode} setDisplayMode={setDisplayMode} refetchTimesheet={refetchTimesheet} />
				}
				return <EditSingleSession {...props} displayMode={displayMode} setDisplayMode={setDisplayMode} />

			case SessionType.Group:
				if (displayMode) return <DisplayGroupSessionView {...props} />
				return <EditGroupSession {...props} displayMode={displayMode} setDisplayMode={setDisplayMode} />
			default:
				return <ErrorNotification messageOrPayload="Error - Invalid session" />
		}
	}

	const container = css({
		width: "100%",
		height: onViewPage ? "calc(100% - 225px)" : "100%",
		backgroundColor: "white",
		padding: "15px",
		flex: onViewPage ? "unset !important" : 1,
		display: "flex",
		flexDirection: "column",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const footer = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: "15px",
	})
	const body = css({
		minHeight: 0,
		height: "100%",
		display: "flex",
		flexDirection: "column",
		opacity: props.deletedAt ? ".5" : 1,
	})

	const group = css({
		display: "flex",
	})

	return (
		<>
			<ZenCard className={container}>
				<div className={title}>
					<LabelMedium>{`Session #${identifier}`}</LabelMedium>
					{displayMode && (
						<div className={group}>
							<StatefulTooltip
								content={() =>
									cantEditReason.length > 0 || props.deletedAt ? (
										<>
											<LabelSmall color="white">This session cannot be edited because</LabelSmall>
											{cantEditReason.map((r, i) => (
												<LabelSmall key={i} color="white" marginLeft="5px">
													{`-  ${r}`}
												</LabelSmall>
											))}
											{props.deletedAt && (
												<LabelSmall color="white" marginLeft="5px">
													{"-  It is archived"}
												</LabelSmall>
											)}
										</>
									) : null
								}
								triggerType="hover"
								placement="leftTop"
								popoverMargin={5}
							>
								<div>
									<ZenButton
										onClick={() => {
											setDisplayMode(false)
											setShowPostSession(false)
										}}
										disabled={cantEditReason.length > 0 || !!props.deletedAt}
									>
										{props.inTimesheet ? "Edit Session Details" : "Edit"}
									</ZenButton>
								</div>
							</StatefulTooltip>
							{props.inTimesheet && !isNonBillable && (
								<ZenButton
									onClick={() => {
										setDisplayMode(false)
										setShowPostSession(true)
									}}
									disabled={cantEditReason.length > 0 || !!props.deletedAt}
								>
									Edit Post Session Details
								</ZenButton>
							)}
						</div>
					)}
				</div>

				<span className={body}>{displayInfo()}</span>

				{cantEditReason.length === 0 && (isNonBillable || (displayMode && !props.attendanceStatus)) && (
					<div className={footer}>
						<ZenButton onClick={() => setShowArchiveModal(true)} altKind={deletedAt ? "primary" : "danger"} width="fit-content">
							{deletedAt ? "Unarchive" : "Archive"}
						</ZenButton>
					</div>
				)}
			</ZenCard>

			{/* Archive/unarchive modal */}
			<ZenArchiveModal
				width="600px"
				hideHeader={!props.deletedAt}
				message={
					props.deletedAt ? (
						"Session"
					) : (
						<span>
							<span>Warning, Archiving a session this way will remove the session from the system and will result in</span>
							<span style={{ fontWeight: "bold", textDecoration: "underline" }}>
								{props.clients && props.clients[0] && ` ${props.clients[0].firstName} ${props.clients[0].lastName} NOT `}
							</span>
							<span>being charged.</span>
						</span>
					)
				}
				areYouSureMessage={props.deletedAt ? undefined : "Only use this method when correcting a session created by accident."}
				open={showArchiveModal}
				confirmArchive={() => {
					toggleArchive.mutate({ id: props.id }).then((resp) => {
						if (resp.error || !resp.payload) return
						showToast(`Session ${resp.payload.deletedAt ? "archived" : "unarchived"} successfully`, "positive")
						refetch()
						fetchIncompleteSessionAlert()
						if (refetchTimesheet) refetchTimesheet()
						if (setSelectedSession) setSelectedSession(null)
					})
				}}
				onClose={() => {
					setShowArchiveModal(false)
				}}
				restoreMode={!!props.deletedAt}
				loading={toggleArchive.loading}
				error={toggleArchive.error}
				payload={toggleArchive.payload}
			/>
		</>
	)
}

const DisplaySingleSession = (props: Session) => {
	const {
		worker,
		clients,
		startTime,
		endTime,
		sessionLocation,
		meetingType,
		actualStartTime,
		actualEndTime,
		office,
		buddySessionMentor,
		sessionFundingSources,
		timezoneID,
		clientType,
	} = props
	const [css] = useStyletron()
	const [imageLoading, setImageLoading] = React.useState<boolean>(true)

	const nonBillable = sessionFundingSources.length > 0 && sessionFundingSources[0].fundingSource.label === "Non-billable"
	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const { timezone: globalTimezone } = PortalContainer.useContainer()

	const timezone = React.useMemo(() => {
		const target = TZString.find((tz) => tz.id === timezoneID)
		if (!target) return globalTimezone
		return target
	}, [timezoneID, globalTimezone])

	const displaySessionLocation = () => {
		switch (meetingType) {
			case SessionMeetingType.InPerson:
				return (
					<>
						<DisplayLabel label="Location" icon="map-marker-alt">
							{office ? (
								<>
									<LabelMedium>{`Office: ${office.name}`}</LabelMedium>
									<LabelMedium marginLeft="8px">{office.address.fullAddress}</LabelMedium>
								</>
							) : (
								<LabelMedium>{sessionLocation}</LabelMedium>
							)}
						</DisplayLabel>
						{imageLoading && <ImageLoadingPlaceholder width="100%" height="225px" />}
						<img
							width="100%"
							alt={`Map of ${office?.address.fullAddress || sessionLocation}`}
							src={`/api/maps/static_map?address=${office?.address.fullAddress || sessionLocation}`}
							onLoad={() => setImageLoading(false)}
						/>
					</>
				)
			case SessionMeetingType.Online:
			case SessionMeetingType.Telephone:
				return (
					<DisplayLabel
						label={meetingType === SessionMeetingType.Online ? "Link" : "Telephone"}
						icon={meetingType === SessionMeetingType.Online ? "map-marker-alt" : "phone-alt"}
					>
						<LabelMedium>{sessionLocation}</LabelMedium>
					</DisplayLabel>
				)
			default:
				return (
					<DisplayLabel label={"Other"}>
						<LabelMedium>{sessionLocation}</LabelMedium>
					</DisplayLabel>
				)
		}
	}
	const container = css({
		marginTop: "10px",
		maxHeight: "100%",
		paddingRight: "8px",
		overflowY: "auto",
		overflowX: "hidden",
	})
	const group = css({
		display: "flex",
	})

	const viewingSelf = currentUser && currentUser.id === worker.id
	const permission = currentUser && (viewingSelf || hasPermission(RolePermission.UserRead))

	return (
		<div className={container}>
			<div className={group}>
				<DisplayLabel label="Worker" icon="briefcase">
					<Link style={{ cursor: permission ? "pointer" : "default" }} to={permission ? `/portal/workers/${worker.id}` : "#"}>
						<SelectedItemCard firstName={worker.firstName} lastName={worker.lastName} avatarUrl={worker.avatarURL} />
					</Link>
				</DisplayLabel>
				<DisplayLabel label="Client" icon="user">
					{clients &&
						clients.length > 0 &&
						clients.map((c) => (
							<Link key={`singles-session-client-${c.id}`} to={`/portal/clients/${c.id}`} style={{ cursor: "pointer" }}>
								<SelectedItemCard key={c.id} firstName={c.firstName} lastName={c.lastName} avatarUrl={c.avatarURL} marginBottom="3px" />
							</Link>
						))}
				</DisplayLabel>
			</div>

			{buddySessionMentor && (
				<DisplayLabel label="Mentor" icon="briefcase">
					<Link style={{ cursor: permission ? "pointer" : "default" }} to={permission ? `/portal/workers/${buddySessionMentor.id}` : "#"}>
						<SelectedItemCard firstName={buddySessionMentor.firstName} lastName={buddySessionMentor.lastName} avatarUrl={buddySessionMentor.avatarURL} />
					</Link>
				</DisplayLabel>
			)}

			{clientType && (
				<DisplayLabel label="Client Type" icon="users">
					<LabelMedium>{snakeToTitle(clientType)}</LabelMedium>
				</DisplayLabel>
			)}

			<DisplayLabel label="Support" icon="users">
				<LabelMedium>{snakeToTitle(meetingType)}</LabelMedium>
			</DisplayLabel>
			<DisplayLabel label="Date" icon="calendar-alt">
				<LabelMedium>{moment(startTime).tz(timezone.id).format("DD MMMM YYYY")}</LabelMedium>
			</DisplayLabel>
			<DisplayLabel label="Timezone" icon="globe-asia">
				<LabelMedium>{timezone.label}</LabelMedium>
			</DisplayLabel>
			<div className={group}>
				<DisplayLabel label={`Booked Time`} icon="clock">
					<LabelMedium>{`${moment(startTime).tz(timezone.id).format("hh:mm a")} - ${moment(endTime).tz(timezone.id).format("hh:mm a")}`}</LabelMedium>
				</DisplayLabel>
				{actualStartTime && (
					<DisplayLabel label={`Actual Time`} icon="clock">
						<LabelMedium>{`${moment(actualStartTime).tz(timezone.id).format("hh:mm a")} - ${moment(actualEndTime)
							.tz(timezone.id)
							.format("hh:mm a")}`}</LabelMedium>
					</DisplayLabel>
				)}
			</div>

			{!nonBillable && (
				<>
					<Divider style={{ backgroundColor: "#A1ADDD" }} />

					<DisplayLabel label="Meeting Type">
						<LabelMedium>{snakeToTitle(meetingType)}</LabelMedium>
					</DisplayLabel>

					{displaySessionLocation()}
				</>
			)}

			<Divider style={{ backgroundColor: "#A1ADDD" }} />
			{sessionFundingSources?.map((sfs, i) => (
				<div key={i}>
					{i !== 0 && <Divider style={{ backgroundColor: "#A1ADDD" }} />}
					<DisplayLabel label="Funding Source">
						<LabelMedium>{sfs.fundingSource.label || "N/A"}</LabelMedium>
					</DisplayLabel>
					{!nonBillable && (
						<DisplayLabel label="Contract Area">
							<LabelMedium>{sfs.contractArea ? sfs.contractArea.label : "N/A"}</LabelMedium>
						</DisplayLabel>
					)}
					<DisplayLabel label={nonBillable ? "Activity Type" : "Support Type"}>
						<LabelMedium>{sfs.supportType ? sfs.supportType.label : "N/A"}</LabelMedium>
					</DisplayLabel>
					<DisplayLabel label={nonBillable ? "Sub Activity Type" : "Sub Support Type"}>
						<LabelMedium>{sfs.subSupportType ? sfs.subSupportType.label : "N/A"}</LabelMedium>
					</DisplayLabel>
					{
						sfs.subSubSupportType &&
						<DisplayLabel label={nonBillable ? "Sub Activity Type" : "Support Type"}>
							<LabelMedium>{sfs.subSubSupportType ? sfs.subSubSupportType.label : "N/A"}</LabelMedium>
						</DisplayLabel>
					}
				</div>
			))}
		</div>
	)
}

const DisplayGroupSessionView = (props: Session) => {
	const {
		clients,
		attendeeNumber,
		groupAttendees,
		worker,
		additionalWorkers,
		actualStartTime,
		actualEndTime,
		startTime,
		endTime,
		sessionLocation,
		sessionFundingSources,
		groupYearGroup,
		groupProgramDelivered,
		groupAtslNumber,
		groupCaldNumber,
		timezoneID,
		clientType,
	} = props

	const [imageLoading, setImageLoading] = React.useState<boolean>(true)
	const { timezone: globalTimezone } = PortalContainer.useContainer()

	const timezone = React.useMemo(() => {
		const target = TZString.find((tz) => tz.id === timezoneID)
		if (!target) return globalTimezone
		return target
	}, [timezoneID]) // eslint-disable-line react-hooks/exhaustive-deps

	const [css] = useStyletron()
	const container: string = css({
		marginTop: "10px",
		maxHeight: "100%",
		paddingRight: "8px",
		overflowY: "auto",
		overflowX: "hidden",
		display: "flex",
		height: "100%",
		flexDirection: "column",
	})
	const groupHorizontal = css({
		display: "flex",
	})
	const groupVertical = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
	})
	return (
		<div className={container}>
			<div className={groupHorizontal}>
				<div className={groupVertical}>
					<DisplayLabel label="Group Client" icon="users">
						{clients && clients.length > 0 && (
							<Link to={`/portal/clients/${clients[0].id}`}>
								<DisplayOrgCard name={clients[0].firstName} />
							</Link>
						)}
					</DisplayLabel>
					{clientType && (
						<DisplayLabel label="Client Type" icon="users">
							<LabelMedium>{snakeToTitle(clientType)}</LabelMedium>
						</DisplayLabel>
					)}
					<DisplayLabel label="Date of First Contact" icon="calendar-alt">
						{clients && clients.length > 0 && <LabelMedium>{moment(clients[0].dateOfFirstContact).tz(timezone.id).format("DD/MM/YYYY")}</LabelMedium>}
					</DisplayLabel>
					<DisplayLabel label={`Additional Attendees (${groupAttendees.length})`} icon="user">
						<DisplayAttendeeList attendees={groupAttendees} />
					</DisplayLabel>
				</div>
				<DisplayLabel label="Worker" icon="user">
					<DisplayWorkerList primaryWorker={worker} additionalWorkers={additionalWorkers} />
				</DisplayLabel>
			</div>
			<DisplayLabel label="Date" icon="calendar-alt">
				<LabelMedium>{moment(startTime).tz(timezone.id).format("DD MMMM YYYY")}</LabelMedium>
			</DisplayLabel>
			<DisplayLabel label="Timezone" icon="globe-asia">
				<LabelMedium>{timezone.label}</LabelMedium>
			</DisplayLabel>
			<div className={groupHorizontal}>
				<DisplayLabel label={`Booked Time`} icon="clock">
					<LabelMedium>{`${moment(startTime).tz(timezone.id).format("hh:mm a")} - ${moment(endTime).tz(timezone.id).format("hh:mm a")}`}</LabelMedium>
				</DisplayLabel>
				{actualStartTime && (
					<DisplayLabel label={`Actual Time`} icon="clock">
						<LabelMedium>{`${moment(actualStartTime).tz(timezone.id).format("hh:mm a")} - ${moment(actualEndTime)
							.tz(timezone.id)
							.format("hh:mm a")}`}</LabelMedium>
					</DisplayLabel>
				)}
			</div>
			<Divider />
			<DisplayLabel label="Location" icon="map-marker-alt">
				<LabelMedium>{sessionLocation}</LabelMedium>
			</DisplayLabel>
			{imageLoading && <ImageLoadingPlaceholder width="450px" height="225px" />}
			<img width="450px" alt={`Map of ${sessionLocation}`} src={`/api/maps/static_map?address=${sessionLocation}`} onLoad={() => setImageLoading(false)} />
			<ZenDivider />
			{sessionFundingSources?.map((sfs, i) => (
				<div key={i}>
					<DisplayLabel label="Funding source">
						<LabelMedium>{sfs.fundingSource.label || "N/A"}</LabelMedium>
					</DisplayLabel>
					<DisplayLabel label="Contract Area">
						<LabelMedium>{sfs.contractArea ? sfs.contractArea.label : "N/A"}</LabelMedium>
					</DisplayLabel>
					<DisplayLabel label="Support Type">
						<LabelMedium>{sfs.supportType ? sfs.supportType.label : "N/A"}</LabelMedium>
					</DisplayLabel>
					<DisplayLabel label="Sub Support Type">
						<LabelMedium>{sfs.subSupportType ? sfs.subSupportType.label : "N/A"}</LabelMedium>
					</DisplayLabel>
				</div>
			))}

			<ZenDivider />
			<LabelMedium marginTop="10px" color={ZenTheme.colors.primaryGrey}>
				Attendee Details
			</LabelMedium>
			<DisplayLabel label="Total Number of Attendees">
				<LabelMedium>{attendeeNumber}</LabelMedium>
			</DisplayLabel>
			<DisplayLabel label="Approx Number of ATSI">
				<LabelMedium>{groupAtslNumber}</LabelMedium>
			</DisplayLabel>
			<DisplayLabel label="Approx Number of CaLD">
				<LabelMedium>{groupCaldNumber}</LabelMedium>
			</DisplayLabel>
			<ZenDivider />
			<DisplayLabel label="Year Group">
				<LabelMedium>{groupYearGroup || "N/A"}</LabelMedium>
			</DisplayLabel>
			<DisplayLabel label="Program Delivered">
				<LabelMedium>{groupProgramDelivered?.label || "N/A"}</LabelMedium>
			</DisplayLabel>
		</div>
	)
}

interface ImagePlaceholderProps {
	width?: string
	height?: string
}
const ImageLoadingPlaceholder = (props: ImagePlaceholderProps) => {
	const { width, height } = props
	const [css] = useStyletron()
	const container = css({
		width: width || "100%",
		height: height || "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: ZenTheme.colors.lightGrey,
	})
	return (
		<div className={container}>
			<LabelSmall>Loading image...</LabelSmall>
			<StyledSpinnerNext />
		</div>
	)
}
