import * as React from "react"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { LabelSmall, LabelXSmall } from "baseui/typography"
import moment from "moment-timezone"
import { PortalContainer } from "../../../../controllers/portal"
import { ZenTheme } from "../../../../themeOverrides"
import { ClientDetail } from "../../../../types/types"
import { SubInfo } from "../../../zenComponents/labels"

export const BasicInfo = (props: ClientDetail) => {
	const { firstName, lastName, dateOfBirth, createdAt, currentContact, avatarURL, pronounOption } = props
	const { timezone } = PortalContainer.useContainer()
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
	})
	const personal = css({
		marginTop: "8px",
		display: "flex",
		alignItems: "center",
		height: "fit-content",
	})
	const info = css({
		flex: 1,
		height: "100%",
		marginLeft: "8px",
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column",
	})
	const infoInner = css({
		flex: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	})
	const pronoun = css({
		flex: 2,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: "10px",
	})

	const genderAndAge = (): string => {
		let str = ""
		if (!!props.gender && !!props.gender.label) str = props.gender.label
		if (dateOfBirth) {
			if (str.length > 0) str += ", "
			str += `${moment(dateOfBirth).format("DD/MM/YYYY")} (${moment().diff(dateOfBirth, "y", false)})`
		}
		return str
	}

	return (
		<div className={container}>
			<div className={personal}>
				<Avatar
					name={`${firstName} ${lastName}`}
					overrides={{
						Root: {
							style: {
								minWidth: ZenTheme.sizing.scale1000,
							},
						},
					}}
					src={avatarURL}
				/>
				<div className={info}>
					<div className={infoInner}>
						<LabelSmall marginBottom="8px">{`${firstName} ${lastName}`}</LabelSmall>
						<LabelSmall marginBottom="8px" color={ZenTheme.colors.primaryGrey}>{`Joined ${moment(createdAt).tz(timezone.id).format("DD/MM/YY")}`}</LabelSmall>
					</div>
					<div className={infoInner}>
						<LabelSmall>Client</LabelSmall>
						<LabelSmall color={ZenTheme.colors.primaryGrey}>{genderAndAge()}</LabelSmall>
					</div>
					<div className={pronoun}>
						<LabelSmall>Pronouns</LabelSmall>
						<LabelSmall color={ZenTheme.colors.primaryGrey}>{pronounOption}</LabelSmall>
					</div>
				</div>
			</div>
			<SubInfo icon="home">
				<LabelXSmall marginLeft="10px">{currentContact?.residentialAddress?.fullAddress || ""}</LabelXSmall>
			</SubInfo>
			<SubInfo icon="mobile">
				<LabelXSmall marginLeft="10px">{currentContact?.mobileNumber}</LabelXSmall>
			</SubInfo>
			<SubInfo icon="phone-alt">
				<LabelXSmall marginLeft="10px">{currentContact?.telephoneNumber}</LabelXSmall>
			</SubInfo>
			<SubInfo icon="envelope">
				<a href={`mailto:${currentContact?.email}`}>
					<LabelXSmall marginLeft="10px" color="blue">
						{currentContact?.email}
					</LabelXSmall>
				</a>
			</SubInfo>
		</div>
	)
}
