import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal"

import { ZenTheme } from "../../themeOverrides"
import { ErrorNotification } from "../errorBox"
import { Spread } from "../spread"
import { ZenButton } from "../zenComponents/zenButtons"

interface ZenArchiveModalProps {
	open: boolean
	onClose: () => void
	loading: boolean
	restoreMode?: boolean
	message?: React.ReactNode
	confirmArchive: () => void
	hideHeader?: boolean
	confirmButtonText?: string
	cancelButtonText?: string
	areYouSureMessage?: string
	error?: boolean
	payload?: any
	width?: string
}

export const ZenArchiveModal = (props: ZenArchiveModalProps) => {
	/**
	 * ZenArchiveModal
	 *
	 * @remarks
	 * A modal specifically for archiving and unarchiving confirmations
	 *
	 *
	 * @param open - True for dialog is open
	 * @param onClose - Called on close
	 * @param loading - Is their activity for the primary button spinner
	 * @param recoverMode - Optional default `false`; Confirmmation for unarchiving
	 * @param message - Optional; Object being modified displays as 'Confirm Archive of ${message}'
	 * @param areYouSureMessage - Optional; Object being modified displays as 'Confirm Archive of ${message}'
	 * @param confirmArchive - The action for confirmation
	 * @param hideHeader - Hides the ModalHeader
	 * @param confirmButtonText - Custom button text
	 * @param cancelButtonText - Custom button text (cancel)
	 *
	 */
	const {
		open,
		onClose,
		loading,
		message,
		areYouSureMessage,
		restoreMode = false,
		confirmArchive,
		error,
		payload,
		hideHeader,
		confirmButtonText,
		cancelButtonText,
		width,
	} = props

	const handleClick = confirmArchive

	return (
		<Modal
			onClose={onClose}
			isOpen={open}
			overrides={{
				Root: {
					style: {
						zIndex: 30,
					},
				},
				Dialog: {
					style: {
						width: width || "500px",
						height: error ? "320px" : "270px",
						padding: "20px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "center",
					},
				},
				Close: {
					style: {
						display: "none",
					},
				},
			}}
		>
			{restoreMode ? (
				<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
			) : (
				<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.red} size={"4x"} icon={["fal", "exclamation-circle"]} />
			)}
			{!hideHeader && (
				<ModalHeader
					style={{
						margin: "0",
						fontWeight: "bold",
						textAlign: "center",
					}}
				>
					Confirm {restoreMode ? "Restore" : "Archive"} {message ? " of " : ""}
				</ModalHeader>
			)}
			{message ? (
				<ModalHeader
					style={{
						margin: "0",
						textAlign: "center",
					}}
				>
					{message}
				</ModalHeader>
			) : null}
			<ModalBody
				style={{
					margin: "0",
					fontWeight: "bold",
					textAlign: "center",
				}}
			>
				{areYouSureMessage || "Are you sure?"}
			</ModalBody>
			<ModalFooter
				style={{
					margin: "0",
					padding: "0",
					width: "100%",
				}}
			>
				{error && <ErrorNotification messageOrPayload={payload} />}
				<Spread>
					<ZenButton
						altKind={"secondary"}
						onClick={() => {
							onClose()
						}}
					>
						{cancelButtonText || "Cancel"}
					</ZenButton>
					<ZenButton isLoading={loading} altKind={restoreMode ? "primary" : "danger"} onClick={handleClick}>
						{confirmButtonText || (restoreMode ? "Restore" : "Archive")}
					</ZenButton>
				</Spread>
			</ModalFooter>
		</Modal>
	)
}
