import * as React from "react"
import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Textarea } from "baseui/textarea"
import { LabelLarge } from "baseui/typography"
import { Control, Controller, FieldError } from "react-hook-form"
import { DeepMap } from "react-hook-form/dist/types/utils"

import { ZenFormControlOverrides, ZenFormControlWrapOverrides, ZenInputBottomOverrides, ZenInputResizableOverrides } from "../../../themeOverrides"
import { FormValuePreview, YesNoSelection } from "../common"
import { IncidentAccidentReportPartData } from "./incidentAccidentReport"

interface IncidentAccidentReportStep2Props {
	control: Control<IncidentAccidentReportPartData>
	errors: DeepMap<IncidentAccidentReportPartData, FieldError>
	disabled?: boolean
	infoOnly?: boolean
}

const required = { required: { value: true, message: "Required" } }

const IncidentAccidentReportStep2 = (props: IncidentAccidentReportStep2Props) => {
	const { control, errors, disabled, infoOnly } = props

	const [css] = useStyletron()
	const titleStyle = css({
		fontWeight: "bold",
		marginBottom: "20px",
	})
	const helpStyle = css({
		color: "#BEBEBE",
		fontWeight: "normal",
	})

	return (
		<div>
			{!infoOnly && <LabelLarge className={titleStyle}>Part A: To be completed by team member</LabelLarge>}
			<FormControl
				label={
					<div>
						<div>Description of the Incident or Accident</div>
						{!disabled && <div className={helpStyle}>Detail what occurred, sequences of events, injuries</div>}
					</div>
				}
				overrides={ZenFormControlOverrides}
				error={errors.partA?.details && errors.partA.details.message}
			>
				<Controller
					name="partA.details"
					control={control}
					as={infoOnly ? undefined : Textarea}
					render={FormValuePreview(infoOnly)}
					placeholder={!disabled ? "Enter description" : ""}
					disabled={disabled}
					overrides={ZenInputResizableOverrides}
					defaultValue=""
					error={errors.partA?.details !== undefined}
					rules={required}
				/>
			</FormControl>
			<FormControl
				label="Response to the Incident or Accident"
				overrides={ZenFormControlOverrides}
				error={errors.partA?.response && errors.partA.response.message}
			>
				<Controller
					name="partA.response"
					control={control}
					as={infoOnly ? undefined : Textarea}
					render={FormValuePreview(infoOnly)}
					placeholder={!disabled ? "Enter description" : ""}
					disabled={disabled}
					overrides={ZenInputResizableOverrides}
					defaultValue=""
					error={errors.partA?.response !== undefined}
					rules={required}
				/>
			</FormControl>

			<FormControl label="Has there been or likely to be media coverage of the Incident or Accident?" overrides={ZenFormControlWrapOverrides}>
				<YesNoSelection
					name="partA.mediaCoverage"
					control={control}
					disabled={disabled}
					showOnYes={
						<FormControl
							label="Explanation"
							overrides={ZenFormControlOverrides}
							error={errors.partA?.mediaCoverageDetails && errors.partA.mediaCoverageDetails.message}
						>
							<Controller
								name={`partA.mediaCoverageDetails`}
								control={control}
								as={<Input data-lpignore="true" autoComplete="off" disabled={disabled} overrides={ZenInputBottomOverrides} placeholder="Enter explanation" />}
								disabled={disabled}
								error={errors.partA?.mediaCoverageDetails !== undefined}
								rules={required}
							/>
						</FormControl>
					}
				/>
			</FormControl>
		</div>
	)
}

export default IncidentAccidentReportStep2
