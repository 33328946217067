import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button, ButtonProps } from "baseui/button"
import { FileUploader } from "baseui/file-uploader"
import { FormControl } from "baseui/form-control"
import { LabelSmall, Paragraph1 } from "baseui/typography"
import { useMutation } from "react-fetching-library"

import { ErrorNotification } from "../../components/errorBox"
import { TooltipButton } from "../../components/tooltipButton"
import { fetching } from "../../fetching"

export interface ImageUploadHandler {
	removeImage: boolean
	upload?: any
	setUploadError?: React.Dispatch<React.SetStateAction<string>>
}

interface ImageUploadProps {
	name: string
	imageURL: string
	disabled?: boolean
	setImageUploader?: (image: ImageUploadHandler | undefined) => void
	label?: string
	buttonLabel?: string
	caption?: string
	maxFileSize?: number

	// Used for profile editing (allows sync between profile preview and editing modal)
	imageRemoved?: boolean
	file?: File
	setFile?: (file?: File) => void

	previewHeight?: string
	previewWidth?: string
}

export const ImageUpload = (props: ImageUploadProps) => {
	// avatar upload
	const { setImageUploader } = props
	const [uploadError, setUploadError] = React.useState<string>("")
	const [removed, setRemoved] = React.useState<boolean>(props.imageRemoved === true)
	const [file, setFile] = React.useState<File | undefined>(props.file)
	const { mutate, loading, error, payload } = useMutation(fetching.mutation.fileUpload)

	const previewImage = !removed ? (file ? URL.createObjectURL(file) : props.imageURL) : ""

	const [css] = useStyletron()
	const imagePreviewContainer = css({
		borderRadius: "100%",
		display: "flex",
		justifyContent: "center",
	})

	const imagePreviewStyles = css({
		width: "100px",
		height: "100px",
		borderRadius: "100%",
		backgroundImage: previewImage ? `url(${previewImage})` : "",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	})

	const uploadImage = () => {
		if (!file) {
			return
		}
		mutate({ file: file })
	}

	React.useEffect(() => {
		// Remove blob attachment after uploading
		if (payload && setImageUploader) {
			setImageUploader({
				removeImage: false,
				upload: undefined,
				setUploadError: undefined,
			})
		}
	}, [payload, setImageUploader])

	return (
		<FormControl
			label={props.label ? <LabelSmall>{props.label}</LabelSmall> : undefined}
			disabled={props.disabled}
			caption={props.caption}
			error={uploadError}
			positive=""
		>
			<div>
				{!previewImage && (
					<>
						<FileUploader
							multiple={false}
							maxSize={props.maxFileSize || 5e6}
							disabled={props.disabled}
							onDrop={(acceptedFiles, rejectedFiles) => {
								if (acceptedFiles.length === 0) {
									if (rejectedFiles[0].size >= (props.maxFileSize || 5e6)) {
										setUploadError("File size too large")
									} else {
										setUploadError("Invalid file")
									}

									return
								}

								setUploadError("")
								setRemoved(false)
								setFile(acceptedFiles[0])
								if (props.setFile) props.setFile(acceptedFiles[0])
								if (props.setImageUploader)
									props.setImageUploader({
										removeImage: false,
										upload: uploadImage,
										setUploadError: setUploadError,
									})
							}}
							accept=".jpg, .jpeg, .png, .svg, .gif, .bmp"
							progressMessage={loading ? "Uploading..." : ""}
							overrides={{
								Root: {
									style: {
										width: "unset",
										borderLeft: "1px solid #D6DBF0",
										borderRight: "1px solid #D6DBF0",
										borderTop: "1px solid #D6DBF0",
										borderBottom: "1px solid #D6DBF0",
									},
								},

								ContentMessage: {
									style: { display: "none" },
								},
								ContentSeparator: {
									style: { display: "none" },
								},
								FileDragAndDrop: {
									style: {
										paddingLeft: 0,
										paddingRight: 0,
										paddingTop: 0,
										paddingBottom: 0,
										borderTopStyle: "unset",
										borderBottomStyle: "unset",
										borderLeftStyle: "unset",
										borderRightStyle: "unset",
										outline: "unset",
									},
								},
								ButtonComponent: { component: BrowseFileButton },
							}}
						/>
					</>
				)}
				{error && <ErrorNotification messageOrPayload={payload} />}

				{previewImage && (
					<div className={imagePreviewContainer}>
						<div style={{ maxWidth: "180px" }}>
							<div className={imagePreviewStyles} />
						</div>
						<div>
							<TooltipButton
								onClick={() => {
									setUploadError("")
									setFile(undefined)
									if (props.setFile) props.setFile(undefined)
									setRemoved(true)
									if (props.setImageUploader)
										props.setImageUploader({
											removeImage: true,
											upload: undefined,
											setUploadError: undefined,
										})
								}}
								tooltip={"Remove image"}
								iconName={"times"}
							/>
						</div>
					</div>
				)}
			</div>
		</FormControl>
	)
}

export const BrowseFileButton = ({ children, ...rest }: ButtonProps) => {
	const [css] = useStyletron()
	const iconStyle = css({})
	return (
		<Button
			type="button"
			{...rest}
			overrides={{
				BaseButton: {
					style: {
						maxWidth: "170px",
						display: "flex",
						flexDirection: "column",
						backgroundColor: "#F8F9FD",
						color: "#D6DBF0",
						":hover": {
							backgroundColor: "#F8F9FD",
						},
					},
				},
			}}
		>
			<div className={iconStyle}>
				<FontAwesomeIcon color={"#8189A6"} icon={["fal", "upload"]} size={"3x"} />
			</div>
			<Paragraph1
				overrides={{
					Block: {
						style: {
							fontSize: "15px",
						},
					},
				}}
				color={"#8189A6"}
			>
				{children ? children : "Drag and drop .png or .jpg files here or click to upload"}
			</Paragraph1>
		</Button>
	)
}
