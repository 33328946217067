import * as React from "react"
import { useStyletron } from "baseui"
import { Option, Value } from "baseui/select"
import { LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"

import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { capitalize, splitOnCaps } from "../../helpers/utils"
import { ImageUpload } from "../../pages/admin/imageUpload"
import { EmergencyContactInput } from "../../pages/workers/workerCreate"
import { daysOfWeek, minimumAgeMonths } from "../../types/enums"
import { BasicName, Role, UserDetail, UserOfficesInput } from "../../types/types"
import { CancelAndSaveButtons } from "../cancelSaveButtons"
import { ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenDivider, ZenInput } from "../zenComponents/zenInput"
import { ZenPlaceSelect, ZenSelect } from "../zenComponents/zenSelectBox"
import { ZenDatePicker } from "../zenComponents/zenTime"
import { EmergencyCard, EmergencyContactForm } from "./onboarding/emergencyContact"
import { ErrorFieldTracker } from "../forms/errorFieldTracker"

const fteOptions: Record<string, string>[] = [
	{ id: "1.0", label: "1.0" },
	{ id: "0.9", label: "0.9" },
	{ id: "0.8", label: "0.8" },
	{ id: "0.7", label: "0.7" },
	{ id: "0.6", label: "0.6" },
	{ id: "0.5", label: "0.5" },
	{ id: "0.4", label: "0.4" },
	{ id: "0.3", label: "0.3" },
	{ id: "0.2", label: "0.2" },
	{ id: "0.1", label: "0.1" },
	{ id: "0.0", label: "0.0" },
]

export const WorkerInfoEditForm = (props: UserDetail) => {
	const history = useHistory()
	const { showToast } = useZenToast()
	const { currentUser } = AuthContainer.useContainer()

	const [css, theme] = useStyletron()
	const [avatar, setAvatar] = React.useState<File>()
	// for displaying address
	const { mutate, payload, error, loading: updateLoading } = useMutation<UserDetail>(fetching.mutation.userUpdate)

	const fileUpload = useMutation<{ fileID: string }>(fetching.mutation.fileUpload)

	// for office options
	const [officeOptions, setOfficeOptions] = React.useState<Option[]>([])
	const officeData = useQuery<BasicName[]>(fetching.query.getOfficeAll())
	React.useEffect(() => {
		if (officeData.loading || !officeData.payload) return
		setOfficeOptions(officeData.payload.map<Option>((o) => ({ label: o.name, id: o.id })))
	}, [officeData.payload, officeData.loading])

	// load department and position options
	const [department, setDepartment] = React.useState<Value>([props.department])
	const [departmentOptions, setDepartmentOptions] = React.useState<Option[]>([])
	const departmentData = useQuery<BasicName[]>(fetching.query.getDepartmentAll())
	React.useEffect(() => {
		if (departmentData.loading || !departmentData.payload) return
		setDepartmentOptions(departmentData.payload.map<Option>((d) => ({ ...d, label: d.name })))
	}, [departmentData.payload, departmentData.loading, departmentData.error])

	const [positionOptions, setPositionOptions] = React.useState<Option[]>([])
	const positionData = useQuery<BasicName[]>(fetching.query.getPositionAll(department.length > 0 && department[0].id ? department[0].id.toString() : ""), false)
	React.useEffect(() => {
		if (department.length === 0) return
		positionData.query()
	}, [department]) // eslint-disable-line react-hooks/exhaustive-deps

	const statusOptions: Option[] = [
		{ id: "FULL_TIME", label: "Full Time" },
		{ id: "PART_TIME", label: "Part Time" },
		{ id: "CASUAL", label: "Casual" },
		{ id: "TERMINATED", label: "Terminated" },
	]

	React.useEffect(() => {
		// clear position field if department is empty
		if (department.length === 0) {
			setPositionOptions([])
			return
		}

		// otherwise fill in the position options
		if (positionData.loading || !positionData.payload) return
		setPositionOptions(positionData.payload.map<Option>((p) => ({ ...p, label: p.name })))
	}, [positionData.payload, department, positionData.loading])

	// for role options
	const [roleOptions, setRoleOptions] = React.useState<Option[]>([])
	const roleData = useQuery<Role[]>(fetching.query.getRoleAll())
	React.useEffect(() => {
		if (roleData.loading || !roleData.payload) return
		setRoleOptions(roleData.payload.map<Option>((r) => ({ label: r.name, id: r.id })))
	}, [roleData.payload, roleData.loading])

	// emergency contact
	const [emergencyContacts, setEmergencyContacts] = React.useState<EmergencyContactInput[]>([])
	const RemoveCard = (index: number) => {
		const target = [...emergencyContacts]
		target.splice(index, 1)
		setEmergencyContacts(target)
	}

	// react use form
	const { errors, control, formState, setValue, trigger, getValues, register } = useForm()
	
	// register customize field
	React.useEffect(() => {
		register({ name: "offices" })
	}, [register])
	
	React.useEffect(() => {
		setValue("firstName", props.firstName)
		setValue("lastName", props.lastName)
		setValue("email", props.email)
		setValue("mobileNumber", props.mobileNumber)
		setValue("vehicleRegistrationNumber", props.vehicleRegistrationNumber)
		setValue("positionID", [{ ...props.position, label: props.position.name }])
		setValue("departmentID", [{ ...props.department, label: props.department.name }])
		setValue("status", [{ id: props.status, label: splitOnCaps(props.status) }])
		setValue("hoursPerFortnight", `${props.hoursPerFortnight ?? ""}`)
		setValue("fte", !props.fte ? [] : [{id: props.fte, label: props.fte === 1 ? "1.0" : String(props.fte)}])
		setValue("fortnightlyKpis", props?.fortnightlyKpis ?? "")

		const offices: { [key: string]: Value } = {}
		if (props.offices) {
			for (const office of props.offices) {
				offices[office.day] = [{ id: office.office.id, label: office.office.name }]
			}
		}
		setValue("offices", offices)

		if (props.emergencyContacts) {
			setEmergencyContacts(
				props.emergencyContacts.map<EmergencyContactInput>((e) => ({
					firstName: e.emergencyContact.firstName,
					lastName: e.emergencyContact.lastName,
					relationship: e.relationship,
					telephoneNumber: e.emergencyContact.telephoneNumber,
					mobileNumber: e.emergencyContact.mobileNumber,
				})),
			)
		}
	}, [props, setValue])

	const onSubmit = async () => {
		// manually process validation, because there is a nested react-hook-form
		const isValid = await trigger([
			"firstName",
			"lastName",
			"email",
			"address",
			"mobileNumber",
			"vehicleRegistrationNumber",
			"roleID",
			"departmentID",
			"positionID",
			"hoursPerFortnight",
			"fte",
			"fortnightlyKpis",
		])
		if (!isValid) {
			return
		}
		const value = getValues()

		const offices: UserOfficesInput[] = []
		for (const key in value.offices) {
			if (value.offices[key].length > 0) {
				offices.push({ day: key, officeID: value.offices[key][0].id })
			}
		}

		// upload
		let avatarID: string | undefined
		if (avatar) {
			const resp = await fileUpload.mutate({ file: avatar })
			if (!resp || resp.error) return
			avatarID = resp.payload?.fileID
		}

		const res = await mutate({
			...value,
			id: props.id,
			addressPlaceID: value.address[0].id,
			offices: offices,
			positionID: value.positionID?.length > 0 ? value.positionID[0].id : undefined,
			departmentID: value.departmentID?.length > 0 ? value.departmentID[0].id : undefined,
			roleID: value.roleID?.length > 0 ? value.roleID[0].id : undefined,
			emergencyContact: emergencyContacts,
			avatarID,
			status: value.status && value.status.length > 0 ? value.status[0].id?.toString() : undefined,
			hoursPerFortnight: Number(value?.hoursPerFortnight),
			fte: Number(value?.fte?.[0]?.label),
			fortnightlyKpis: value?.fortnightlyKpis,
		})

		if (!res.payload || res.error) {
			return
		}

		// push to worker view page
		showToast("Worker updated successfully", "positive")
		control.updateFormState({ isDirty: false })
		history.push(`/portal/workers/${res.payload.id}`)
	}

	const container = css({
		width: "700px",
		padding: "15px",
		height: "100%",
		backgroundColor: theme.colors.white,
		display: "flex",
		flexDirection: "column",
	})
	const form = css({
		height: "100%",
		maxHeight: "100%",
		width: "100%",
		overflowX: "hidden",
		overflowY: "auto",
		padding: "15px",
	})
	const avatarContainer = css({
		width: "225px",
		display: "flex",
		alignItems: "center",
	})
	const avatarContainerInner = css({
		width: "100%",
		height: "173px",
	})
	const nameContainer = css({
		width: "100%",
		marginLeft: "15px",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
		},
	})

	return (
		<ZenCard className={container}>
			<LabelMedium>Team Member Edit</LabelMedium>
			<div className={form}>
				<div className={group}>
					<div className={avatarContainer}>
						<div className={avatarContainerInner}>
							<ImageUpload
								label="Avatar"
								name="avatar"
								imageURL={props.avatarURL || ""}
								imageRemoved={false}
								file={avatar}
								setFile={(file?: File) => setAvatar(file)}
								previewHeight="200px"
								maxFileSize={1e7}
							/>
						</div>
					</div>
					<div className={nameContainer}>
						<ZenInput label="First name" nameRef="firstName" inputError={errors.firstName} formRef={control} required />
						<ZenInput label="Last name" nameRef="lastName" inputError={errors.lastName} formRef={control} required />
					</div>
				</div>

				{/* <ZenDatePicker
					label="Date of birth"
					formName="dateOfBirth"
					formRef={control}
					value={new Date(props.dateOfBirth)}
					minDate={new Date("1900-01-01")}
					maxDate={moment().subtract(minimumAgeMonths, "month").toDate()}
				/> */}
				<ZenInput label="Email" nameRef="email" inputError={errors.email} formRef={control} required />
				<ZenInput
					// Use generic input to allow for more flexible usage, such as a note about the number.
					label="Contact Number"
					nameRef="mobileNumber"
					inputError={errors.mobileNumber}
					formRef={control}
					required // A worker needs a contact number
				/>
				<ZenPlaceSelect
					label="Address"
					formName="address"
					formRef={control}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Address is required",
						},
					}}
					inputError={errors.address}
					value={props.address ? [{ label: props.address.fullAddress, id: props.address.placeID }] : []}
				/>
				<ZenInput label="Vehicle Registration Number" nameRef="vehicleRegistrationNumber" inputError={errors.vehicleRegistrationNumber} formRef={control} />
				<ZenSelect
					label="Role"
					formName="roleID"
					formRef={control}
					clearable={false}
					value={props.role ? [{ label: props.role.name, id: props.role.id }] : []}
					options={roleOptions}
					disabled={!currentUser || currentUser.id === props.id} // disable if editing own profile
				/>
				<ZenSelect
					label="Department"
					formName="departmentID"
					clearable={false}
					formRef={control}
					inputError={errors.departmentID}
					actionOnChange={(v) => {
						setDepartment(v)
						setValue("positionID", [])
					}}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Department is required",
						},
					}}
					options={departmentOptions}
				/>
				<ZenSelect
					label="Position"
					formName="positionID"
					clearable={false}
					formRef={control}
					inputError={errors.positionID}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Position is required",
						},
					}}
					options={positionOptions}
				/>
				<ZenSelect
					label="Status"
					formName="status"
					clearable={false}
					formRef={control}
					inputError={errors.status}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Status is required",
						},
					}}
					options={statusOptions}
				/>
				
				<ZenInput 
					label="Hours per Fortnight" 
					nameRef="hoursPerFortnight" 
					placeholder="Select Hours per Fortnight"
					inputError={errors.hoursPerFortnight} 
					formRef={control} 
					required 
					formRules={{
						validate: {
							required: (value: string) => {
								const hasLetter = (/[a-zA-Z]/).test(value)
								const isInvalidInput = (value?.split('')[value?.split('')?.length -1])?.includes(".");
								if(isInvalidInput || hasLetter) {
									return "Invalid Hours per Fortnight value. Only accepts number"
								}
								if(!value && value.length === 0) {
									return "Hours per Fortnight is required"
								}
								return
							},
						},
					}}
				/>
				
				<ZenSelect
					label="FTE"
					formName="fte"
					placeholder="Select FTE"
					clearable={false}
					formRef={control}
					inputError={errors.fte}
					required 
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "FTE is required",
						},
					}}
					options={fteOptions}
				/>
				<ZenInput 
					label="Fortnightly KPIs" 
					nameRef="fortnightlyKpis" 
					inputError={errors.fortnightlyKpis} 
					placeholder="Select Fortnightly KPI's"
					formRef={control} 
					formRules={{
						validate: {
							required: (value: string) => {
								const hasLetter = (/[a-zA-Z]/).test(value)
								const isInvalidInput = (value?.split('')[value?.split('')?.length -1])?.includes(".");
								if(isInvalidInput || hasLetter) {
									return "Invalid Fortnight KPI's. Only accepts number"
								}
								return
							},
						},
					}}
				/>

				<ZenDivider marginTop="15px" marginBottom="15px" />
				<LabelMedium>Office Locations</LabelMedium>
				{daysOfWeek.map((day, index) => {
					const usersOffice = props.offices?.find((value) => value.day === day)
					return (
						<ZenSelect
							key={index}
							label={capitalize(day)}
							formName={`offices[${day}]`}
							formRef={control}
							clearable={true}
							value={usersOffice ? [{ label: usersOffice.office.name, id: usersOffice.office.id }] : []}
							options={officeOptions}
						/>
					)
				})}
				<ZenDivider marginTop="15px" marginBottom="15px" />
				{/* <LabelMedium>Emergency Contact</LabelMedium> */}
				{/* <EmergencyContactForm setEmergencyContacts={setEmergencyContacts} /> */}
				<LabelMedium marginTop="10px" marginBottom="5px">
					List
				</LabelMedium>
				{emergencyContacts.length > 0 ? (
					emergencyContacts.map((ec, i) => <EmergencyCard key={i} index={i} {...ec} clear={RemoveCard} setEmergencyContacts={setEmergencyContacts} />)
				) : (
					<LabelSmall>N/A</LabelSmall>
				)}
			</div>

			{error && <ErrorNotification messageOrPayload={payload} />}
			{fileUpload.error && <ErrorNotification messageOrPayload={"Failed to upload avatar image; please try again or try a different file format"} />}
			{officeData.error && <ErrorNotification messageOrPayload={officeData.payload} />}
			{departmentData.error && <ErrorNotification messageOrPayload={departmentData.payload} />}
			{positionData.error && <ErrorNotification messageOrPayload={positionData.payload} />}
			{roleData.error && <ErrorNotification messageOrPayload={roleData.payload} />}
			<ErrorFieldTracker errorIDs={Object.keys(errors)} />
			<CancelAndSaveButtons saveFn={onSubmit} cancelFn={() => history.push(`/portal/workers/${props.id}`)} isLoading={updateLoading || fileUpload.loading} />

			<Prompt when={formState.isDirty} message={"You have unsaved changes, are you sure you want to leave?"} />
		</ZenCard>
	)
}
