import * as React from "react"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { FormControl } from "baseui/form-control"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal"
import { Option, Value } from "baseui/select"
import { HeadingXSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"

import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenTextArea } from "../../../components/zenComponents/zenInput"
import { ZenSelect } from "../../../components/zenComponents/zenSelectBox"
import { ZenDatePicker } from "../../../components/zenComponents/zenTime"
import { PortalContainer } from "../../../controllers/portal"
import { fetching } from "../../../fetching"
import { NDISPlanStatus, NDISPriceType } from "../../../types/enums"
import { BasicLabel, NDISPlan } from "../../../types/types"

interface AddPlanProps {
	clientID: string
	onSuccess: (plan: NDISPlan) => void
	onCancel: () => void
	plan?: NDISPlan
	currentPlans: NDISPlan[]
	refetchPlans: () => void
}

interface NDISPlanFormValues {
	ndisNumber: string
	contractArea: BasicLabel[]
	planStartDate: string
	planEndDate: string
	serviceStartDate: string
	serviceEndDate: string
	status: { id: NDISPlanStatus; label: string }[]
	priceType: { id: NDISPriceType; label: string }[]
	note: string
	shortTermGoals: string
	mediumOrLongTermGoals: string
}

// Modal to create new plan, or edit an existing one if supplying plan prop
export const AddPlan = (props: AddPlanProps) => {
	const [css] = useStyletron()
	const { refetchPlans } = props
	const card = css({
		minWidth: "680px",
	})
	const dateRange = css({
		display: "flex",
	})
	const dateFrom = css({
		width: "100%",
		marginRight: "20px",
	})
	const dateTo = css({
		width: "100%",
	})

	const statusOptions: Option[] = React.useMemo(() => {
		return [
			{ id: NDISPlanStatus.Inactive, label: "Inactive" },
			{ id: NDISPlanStatus.Active, label: "Active" },
		]
	}, [])

	const priceTypes: Option[] = React.useMemo(() => {
		return [
			{ id: NDISPriceType.National, label: "National" },
			{ id: NDISPriceType.Remote, label: "Remote" },
			{ id: NDISPriceType.VeryRemote, label: "Very Remote" },
		]
	}, [])

	const { control, handleSubmit, getValues, errors, setValue, trigger } = useForm()
	const { mutate, error, loading, payload } = useMutation(props.plan ? fetching.mutation.clientNDISUpdate : fetching.mutation.clientNDISCreate)
	const { client, fetchClient } = PortalContainer.useContainer()

	const [ndisContractAreas, setNdisContractAreas] = React.useState<BasicLabel[]>([])
	const getNDISContractArea = useQuery(fetching.query.getNDISContractAreas())
	React.useEffect(() => {
		if (getNDISContractArea.loading || getNDISContractArea.error || !getNDISContractArea.payload) return
		setNdisContractAreas(getNDISContractArea.payload)
	}, [getNDISContractArea.loading, getNDISContractArea.error, getNDISContractArea.payload])

	const [showSwapActivePlanModal, setShowSwapActivePlanModal] = React.useState(false)
	const inactivatePlans = useMutation(fetching.mutation.clientNDISInactivate)
	React.useEffect(() => {
		// Refetch plans on successful
		if (inactivatePlans.status === 200) {
			inactivatePlans.reset()
			refetchPlans()
		}
	}, [showSwapActivePlanModal, inactivatePlans, refetchPlans])
	React.useEffect(() => {
		if (!showSwapActivePlanModal) return
		setShowSwapActivePlanModal(false)
		trigger("status")
	}, [props.currentPlans, showSwapActivePlanModal, trigger])

	React.useEffect(() => {
		if (!props.plan) return
		setValue("clientID", props.plan.clientID)
		setValue("planStartDate", moment(props.plan.planStartDate).toDate())
		setValue("planEndDate", moment(props.plan.planEndDate).toDate())
		setValue(
			"status",
			statusOptions.filter((value) => value.id === props.plan?.status),
		)
		setValue("note", props.plan.note)
		setValue(
			"priceType",
			priceTypes.filter((value) => value.id === props.plan?.priceType),
		)
		setValue("shortTermGoals", props.plan.shortTermGoals)
		setValue("mediumOrLongTermGoals", props.plan.mediumOrLongTermGoals)
	}, [client, props.plan, priceTypes, setValue, statusOptions, ndisContractAreas])

	const onSubmit = async (data: NDISPlanFormValues) => {
		const resp = await mutate({
			planID: props.plan ? props.plan.id : undefined,
			clientID: props.clientID,
			planStartDate: data.planStartDate,
			planEndDate: data.planEndDate,
			status: data.status[0].id,
			priceType: data.priceType[0].id,
			note: data.note,
			shortTermGoals: data.shortTermGoals,
			mediumOrLongTermGoals: data.mediumOrLongTermGoals,
		})
		if (resp.status === 200 && resp.payload) {
			props.onSuccess(resp.payload)
			fetchClient(props.clientID)
		}
	}

	const validatePlanStatus = (value: Value) => {
		// Make sure selection was made
		if (!value || value.length !== 1) {
			return "You must select a plan status"
		}
		// If trying to set plan to active or gap management, check that no other plans currently have one of these status'
		// May only have one active/gap management plan at a time
		if (value[0].id === NDISPlanStatus.Active) {
			// get a list that exclude current plan
			const currentPlanList = [...props.currentPlans].filter((n) => props.plan?.id !== n.id)
			const index = currentPlanList.findIndex((value) => value.status === NDISPlanStatus.Active)
			if (index !== -1) {
				setShowSwapActivePlanModal(true)
				return "Only one plan may be active or in gap management at a time"
			}
		}
		return null
	}

	return (
		<ZenCard className={card}>
			<HeadingXSmall marginTop={0} marginBottom={"10px"}>
				{props.plan ? "Edit NDIS Plan" : "Add NDIS Plan"}
			</HeadingXSmall>
			<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<div className={dateRange}>
					<div className={dateFrom}>
						<ZenDatePicker
							formRef={control}
							label={"Plan Start Date"}
							formName={"planStartDate"}
							inputError={errors.planStartDate}
							nullDefaultValue={!props.plan}
							formRules={{
								validate: {
									required: (value: string) => {
										if (!value) {
											return "You must select a start date"
										}
										const endDate = getValues("planEndDate")
										if (moment(value).isSameOrAfter(endDate)) {
											return "Start date must be before end date"
										}
										return null
									},
								},
							}}
						/>
					</div>
					<div className={dateTo}>
						<ZenDatePicker
							formRef={control}
							label={"Plan End Date"}
							formName={"planEndDate"}
							inputError={errors.planEndDate}
							nullDefaultValue={!props.plan}
							formRules={{
								validate: {
									required: (value: string) => {
										if (!value) {
											return "You must select an end date"
										}
										const startDate = getValues("planStartDate")
										if (moment(value).isSameOrBefore(startDate)) {
											return "End date must be after start date"
										}
										return null
									},
								},
							}}
						/>
					</div>
				</div>
				<ZenSelect
					options={priceTypes}
					label={"Price Type (Remoteness)"}
					formRef={control}
					formName={"priceType"}
					placeholder={""}
					inputError={errors.priceType}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length === 1) || "You must select a price type",
						},
					}}
				/>
				<ZenSelect
					options={statusOptions}
					label={"Plan Status"}
					formRef={control}
					formName={"status"}
					placeholder={""}
					inputError={errors.status}
					formRules={{
						validate: {
							required: validatePlanStatus,
						},
					}}
				/>
				<ZenTextArea label="Short Term Goals" formRef={control} nameRef="shortTermGoals" />
				<ZenTextArea label="Medium or Long Term Goals" formRef={control} nameRef="mediumOrLongTermGoals" />
				{!props.plan && <ZenTextArea label="Notes" formRef={control} nameRef="note" />}
				{error && <ErrorNotification messageOrPayload={payload} />}
				<FormControl error={error}>
					<CancelAndSaveButtons cancelFn={() => props.onCancel()} isLoading={loading} />
				</FormControl>
			</form>

			<Modal
				isOpen={showSwapActivePlanModal}
				onClose={() => setShowSwapActivePlanModal(false)}
				size="auto"
				autoFocus={false}
				unstable_ModalBackdropScroll={true}
			>
				<ModalHeader>Inactivate current NDIS Plan?</ModalHeader>
				<ModalBody>You already have an active NDIS Plan, would you like to set your existing plan as inactive?</ModalBody>
				<ModalFooter>
					{inactivatePlans.error && <ErrorNotification messageOrPayload={inactivatePlans.payload} />}
					<Block display="flex" justifyContent="space-between" alignItems="center" width="100%">
						<ZenButton altKind="secondary" onClick={() => setShowSwapActivePlanModal(false)} disabled={inactivatePlans.loading}>
							No
						</ZenButton>
						<ZenButton isLoading={inactivatePlans.loading} onClick={() => inactivatePlans.mutate({ clientID: props.clientID })}>
							Yes
						</ZenButton>
					</Block>
				</ModalFooter>
			</Modal>
		</ZenCard>
	)
}
