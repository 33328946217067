import * as React from "react"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import { ZenCard } from "../../../components/common"
import { useStyletron } from "baseui"
import { ZenFileUploader } from "../../../components/fileUploader"
import { useMutation } from "react-fetching-library"
import { fetching } from "../../../fetching"
import { useForm } from "react-hook-form"
import { ErrorNotification } from "../../../components/errorBox"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { FormControl } from "baseui/form-control"
import { ZenTheme } from "../../../themeOverrides"
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip"
import { Block } from "baseui/block"
import { Notification, KIND } from "baseui/notification"
import { useZenToast } from "../../../components/zenComponents/useZenToast"
import { routes } from "../../../routes"
import { useHistory } from "react-router"

interface FormValues {
	file: File
	yearFrom: string
	yearTo: string
	versionNumber: string
}

const yearRegex = /^[1-9][0-9]{3}$/
const versionRegex = /^\d+(\.\d+)*$/

export const ImportPriceGuide = () => {
	const [css] = useStyletron()
	const card = css({
		height: "100%",
		overflowY: "scroll"
	})
	const version = css({
		display: "flex",
	})
	const buttons = css({
		marginTop: "10px",
		display: "flex",
		justifyContent: "flex-end",
	})
	const validationError = css({
		marginLeft: "10px",
		marginTop: "10px",
		display: "flex",
	})
	const bull = css({
		marginRight: "1em",
	})

	const { control, handleSubmit, watch, errors, setValue, setError, register, trigger } = useForm<FormValues>()
	const { showToast } = useZenToast()
	const history = useHistory()

	const [canSubmit, setCanSubmit] = React.useState(false)

	const { mutate, payload, loading, error } = useMutation(fetching.mutation.priceGuideImport)
	register("file", {
		required: "You must select a price guide file",
	})
	const file = watch("file")
	const yearTo = watch("yearTo")
	const yearFrom = watch("yearFrom")
	const versionNumber = watch("versionNumber")

	const handleFileSelectError = (error: string) => {
		setValue("file", undefined)
		setError("file", { type: "validate", message: error })
	}

	const handleFileSelected = (selectedFile: File) => {
		setValue("file", selectedFile)
		trigger("file")
	}

	const onSubmit = async (data: FormValues) => {
		const resp = await mutate({
			file: data.file,
			json: {
				yearFrom: parseInt(data.yearFrom, 10),
				yearTo: parseInt(data.yearTo, 10),
				versionNumber: data.versionNumber,
				commit: canSubmit,
			},
		})

		// if validation failed, then set can submit false, and return
		if (!resp.payload || resp.error || resp.payload.validationErrors.length > 0) {
			setCanSubmit(false)
			return
		}

		// if canSubmit is false, then they must be doing a "check" action only, so set this true and return
		if (!canSubmit) {
			setCanSubmit(true)
			return
		}

		// made it this far then it was a "commit" action, and the price guide was added successfully
		showToast(`Price guide ${data.versionNumber} added successfully.`, "positive")
		history.push(routes.admin.ndisManagement.root)
	}

	return (
		<ZenCard className={card}>
			<LabelLarge marginBottom="20px">Import Price Guide</LabelLarge>
			<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<LabelSmall>Price Guide CSV</LabelSmall>
				<FormControl error={errors.file ? errors.file : ""}>
					<>
						<ZenFileUploader
							acceptedFileTypes={[".csv"]}
							secondaryLabel="(Must be in  csv format)"
							disabled={canSubmit}
							onError={handleFileSelectError}
							onFileAccepted={handleFileSelected}
						/>
						{file && (
							<>
								<LabelSmall marginTop="8px" marginBottom="8px">
									Selected File:
								</LabelSmall>
								<LabelMedium marginBottom={"10px"} padding={"10px"} backgroundColor={ZenTheme.colors.lightGrey}>
									{file.name} ({file.size / (1 << 20) < 0.01 ? "< 0.01" : (file.size / (1 << 20)).toFixed(2)}MB)
								</LabelMedium>
							</>
						)}
					</>
				</FormControl>

				<div className={version}>
					<ZenInput
						disabled={canSubmit}
						label="Year From"
						nameRef="yearFrom"
						type="number"
						placeholder={"YYYY"}
						inputError={errors.yearFrom}
						formRef={control}
						formRules={{
							required: "Year from is required.",
							pattern: {
								value: yearRegex,
								message: "Please enter a valid year from",
							},
						}}
					/>
					<ZenInput
						disabled={canSubmit}
						label="Year To"
						nameRef="yearTo"
						type="number"
						placeholder={"YYYY"}
						inputError={errors.yearTo}
						formRef={control}
						formRules={{
							required: "Year to is required.",
							pattern: {
								value: yearRegex,
								message: "Please enter a valid year to",
							},
							validate: (value: string) => !yearFrom || parseInt(value, 10) >= parseInt(yearFrom) || "Year to must be after year from.",
						}}
						marginLeft={"20px"}
					/>
					<ZenInput
						disabled={canSubmit}
						label="Version Number"
						nameRef="versionNumber"
						inputError={errors.versionNumber}
						formRef={control}
						formRules={{
							required: "Version number is required.",
							pattern: {
								value: versionRegex,
								message: `Please enter a valid version number eg. "2.1"`,
							},
						}}
						marginLeft={"20px"}
					/>
				</div>
				<LabelMedium marginBottom={"10px"} padding={"10px"} backgroundColor={ZenTheme.colors.lightGrey}>
					Version: {yearFrom}
					{yearTo && `-${yearTo}`}
					{versionNumber && ` v${versionNumber}`}
				</LabelMedium>

				{error && <ErrorNotification messageOrPayload={payload} />}
				{!error && payload && payload.validationErrors.length > 0 && (
					<Notification
						kind={KIND.warning}
						overrides={{
							Body: {
								style: {
									width: "100%",
								},
							},
						}}
					>
						<div>The below errors were found while validating the price guide. You will not be able to submit until they are resolved.</div>
						{payload.validationErrors.map((value, index) => (
							<div className={validationError}>
								<div className={bull}>&bull;</div>
								<div>{value}</div>
							</div>
						))}
					</Notification>
				)}
				<div className={buttons}>
					<ZenButton
						type={"button"}
						onClick={() => {
							if (!canSubmit) {
								handleSubmit(onSubmit)()
								return
							}
							setCanSubmit(false)
						}}
						altKind={canSubmit ? "danger" : undefined}
						isLoading={!canSubmit && loading}
					>
						{!canSubmit ? "Check" : "Edit"}
					</ZenButton>
					<StatefulTooltip
						content={() => (!canSubmit ? <Block padding={"5px"}>You must click "Check" to validate the import before you can submit.</Block> : "")}
						ignoreBoundary={true}
						returnFocus
						autoFocus
						showArrow
						placement={PLACEMENT.topRight}
					>
						<div>
							<ZenButton disabled={!canSubmit} marginLeft={"10px"} type={"submit"} isLoading={canSubmit && loading}>
								Submit
							</ZenButton>
						</div>
					</StatefulTooltip>
				</div>
			</form>
		</ZenCard>
	)
}
