import * as React from "react"
import { LabelLarge } from "baseui/typography"
import { ZenCard } from "../../../components/common"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { NDISPriceGuide } from "../../../types/types"
import { useStyletron } from "baseui"
import { LineItemList } from "./priceGuideItems"
import { PriceGuidesList } from "./priceGuides"
import { useHistory } from "react-router"
import { routes } from "../../../routes"
import { FILL, Tab, TabOverrides, Tabs, TabsOverrides } from "baseui/tabs-motion"
import { ZenTheme } from "../../../themeOverrides"
import { ItemGroups } from "./itemGroups"

const tabOverrides: TabOverrides = {
	TabPanel: {
		style: {
			display: "none",
		},
	},
	Tab: {
		style: {
			fontWeight: "bold",
			fontSize: "16px",
			whiteSpace: "nowrap",
			background: "unset",
		},
	},
}

const tabsOverrides: TabsOverrides = {
	TabList: {
		style: {
			backgroundColor: "transparent",
		},
	},
	TabHighlight: {
		style: {
			backgroundColor: ZenTheme.colors.primaryGreen,
		},
	},
	Root: {
		style: {
			maxHeight: "100%",
			overflow: "hidden",
			flex: 1,
			marginBottom: "15px",
		},
	},
}

export const NDISManagement = () => {
	const [css] = useStyletron()
	const container = css({
		width: "100%",
		height: "100%",
	})
	const cardTop = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "40%",
		marginBottom: "15px",
	})
	const cardBottom = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "60%",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	})

	const history = useHistory()

	const [selectedRow, setSelectedRow] = React.useState<NDISPriceGuide>()
	const [activeKey, setActiveKey] = React.useState("1")

	return (
		<div className={container}>
			<ZenCard className={cardTop}>
				<div className={title}>
					<LabelLarge marginBottom="15px">Price Guide Versions</LabelLarge>
					<ZenButton onClick={() => history.push(routes.admin.ndisManagement.import)}>Import Price Guide</ZenButton>
				</div>
				<PriceGuidesList selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
			</ZenCard>
			<ZenCard className={cardBottom}>
				<LabelLarge marginBottom={"15px"}>Price Guide {selectedRow && `- ${selectedRow.version}`}</LabelLarge>
				<div>
					<Tabs
						uid="ndis-plan-details" // required for tab styles to work from styles.scss
						activeKey={activeKey}
						onChange={({ activeKey }) => {
							setActiveKey(activeKey.toString())
						}}
						fill={FILL.fixed}
						overrides={tabsOverrides}
					>
						<Tab title="Line Items" key="1" overrides={tabOverrides} />
						<Tab title="Item Groups" key="2" overrides={tabOverrides} />
					</Tabs>
				</div>
				{activeKey === "1" && <LineItemList selectedRow={selectedRow} />}
				{activeKey === "2" && <ItemGroups priceGuide={selectedRow} />}
			</ZenCard>
		</div>
	)
}
