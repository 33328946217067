import * as React from "react"
import { LabelLarge } from "baseui/typography"
import moment from "moment-timezone"
import { useForm } from "react-hook-form"
import { useStyletron } from "styletron-react"

import { Spacer, ZenCard } from "../../components/common"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenDatePicker } from "../../components/zenComponents/zenTime"
import { ErrorNotification } from "../../components/errorBox"
import { useParameterizedQuery } from "react-fetching-library"
import { fetching } from "../../fetching"
import { getErrorFromBlob } from "../../helpers/utils"
import { Option } from "baseui/select"
import { ZenClientSelect, ZenUserSelect } from "../../components/zenComponents/zenSelectBox"

interface FormData {
	startDate: string
	endDate: string
	user?: Option[]
	client?: Option[]
}

export const CallLogReport = () => {
	const [css] = useStyletron()
	const card = css({
		height: "fit-content",
		width: "fit-content",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		flexDirection: "column",
	})
	const buttonRow = css({
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "15px",
	})
	const group = css({
		display: "flex",
		justifyContent: "space-between",
	})

	const { control, handleSubmit, getValues, errors } = useForm<FormData>()
	const { query, loading, error } = useParameterizedQuery(fetching.query.getCallLogReport)

	const [errorMsg, setErrorMsg] = React.useState<string>()

	const onSubmit = async (data: FormData) => {
		setErrorMsg(undefined)

		const resp = await query({
			startDate: new Date(data.startDate),
			endDate: new Date(data.endDate),
			workerIDs: data.user ? data.user.map<string>((u) => u.id?.toString() || "") : undefined,
			clientIDs: data.client ? data.client.map<string>((c) => c.id?.toString() || "") : undefined,
		})

		if (resp.error || !resp.payload) {
			setErrorMsg(await getErrorFromBlob(resp.payload))
			return
		}

		// Create link and initiate download when response is received
		const downloadLink = document.createElement("a")
		downloadLink.href = URL.createObjectURL(resp.payload)
		downloadLink.download = `contract_hours_${moment(data.startDate).format("YYYYMMDD")}_${moment(data.endDate).format("YYYYMMDD")}.xlsx`
		downloadLink.click()
	}

	return (
		<ZenCard className={card}>
			<form autoComplete="off" className={title} onSubmit={handleSubmit(onSubmit)}>
				<LabelLarge marginBottom="20px">Call Logs Report</LabelLarge>
				<div className={group}>
					<div>
						<ZenDatePicker
							inputError={errors.startDate}
							nullDefaultValue={true}
							formName="startDate"
							label="Start Date"
							formRef={control}
							formRules={{
								required: "You must select a start date.",
							}}
						/>
					</div>
					<Spacer style={{ width: 15 }} />
					<div>
						<ZenDatePicker
							inputError={errors.endDate}
							nullDefaultValue={true}
							formName="endDate"
							label="End Date"
							formRef={control}
							formRules={{
								required: "You must select an end date.",
								validate: (value: string) => {
									if (moment(value).isBefore(moment(getValues("startDate")))) {
										return "End date must be after start date."
									}
									return null
								},
							}}
						/>
					</div>
				</div>
				<ZenUserSelect label={"Worker (Optional)"} formName={"user"} formRef={control} />
				<ZenClientSelect label={"Client (Optional)"} formName={"client"} formRef={control} />
				{error && <ErrorNotification message={errorMsg} />}
				<div className={buttonRow}>
					<ZenButton type="submit" isLoading={loading}>
						Export
					</ZenButton>
				</div>
			</form>
		</ZenCard>
	)
}
