import * as React from "react"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { FILL, Tab, TabOverrides, Tabs, TabsOverrides } from "baseui/tabs-motion"
import { useState } from "react"
import { Layout, Layouts } from "react-grid-layout"
import { Loading } from "../../components/loading"
import { AuthContainer } from "../../controllers/auth"
import { ZenTheme } from "../../themeOverrides"
import { RolePermission, UserDetail } from "../../types/types"
import { getBreakpoint } from "./layout"
import { MemberDashboard } from "./member"
import { SupervisorDashboard } from "./supervisor"

const tabOverrides: TabOverrides = {
	Tab: {
		style: {
			fontWeight: "bold",
			fontSize: "16px",
			whiteSpace: "nowrap",
			background: "unset",
		},
	},
	TabPanel: {
		style: {
			overflowY: "auto",
			maxHeight: "100%",
		},
	},
}

const tabsOverrides: TabsOverrides = {
	TabList: {
		style: {
			backgroundColor: "transparent",
		},
	},
	TabHighlight: {
		style: {
			backgroundColor: ZenTheme.colors.primaryGreen,
		},
	},
	Root: {
		style: {
			maxHeight: "100%",
			overflow: "hidden",
			flex: 1,
		},
	},
}

export default function Dashboard() {
	const { currentUser, editingLayout, reloadLoginState, loading, hasPermission } = AuthContainer.useContainer()
	const [breakpoint, setBreakpoint] = useState<string>("")
	const [activeTab, setActiveTab] = useState<string>(localStorage.getItem("active-dashboard-tab") || "my-dashboard")
	const [layouts, setLayouts] = useState<Layouts | undefined>(undefined)

	const [css] = useStyletron()
	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexGrow: 1,
		flexDirection: "column",
		overflowY: "auto",
		position: "relative",
	})

	React.useEffect(() => {
		reloadLoginState()
	}, [reloadLoginState])
	if (!currentUser) {
		return <Loading />
	}

	const itemCls = css({
		border: editingLayout ? "1px dotted #000" : "unset",
		cursor: editingLayout ? "move" : "unset",
		overflowY: "auto",
	})

	const onLayoutChange = (storageKey: string, breakpoint: string) => (layout: Layout[]) => {
		if (breakpoint !== "" && editingLayout) localStorage.setItem(storageKey, JSON.stringify(layout))
	}
	const onWidthChange = (width: number) => {
		setBreakpoint(getBreakpoint(width))
	}
	const onBreakpointChange = (bpoint: string) => {
		setBreakpoint(bpoint)
	}

	if (!currentUser || loading) return <Loading />

	const memboard = (
		<MemberDashboard
			editing={editingLayout}
			itemCls={itemCls}
			breakpoint={breakpoint}
			currentUser={currentUser}
			layouts={layouts}
			setLayouts={setLayouts}
			onLayoutChange={onLayoutChange}
			onWidthChange={onWidthChange}
			onBreakpointChange={onBreakpointChange}
		/>
	)

	if (hasPermission(RolePermission.TeamCreate)) {
		return (
			<div className={container}>
				<Tabs
					uid="supervisor-dashboard" // required for tab styles to work from styles.scss
					activeKey={activeTab}
					overrides={tabsOverrides}
					onChange={({ activeKey }) => {
						const at = activeKey.toString()
						setActiveTab(at)
						localStorage.setItem("active-dashboard-tab", at)
					}}
					fill={FILL.fixed}
				>
					<Tab title="My Dashboard" key="my-dashboard" overrides={tabOverrides}>
						<div className={container}>
							<Block paddingBottom="40px">{memboard}</Block>
						</div>
					</Tab>
					<Tab title="Team" key="team-dashboard" overrides={tabOverrides}>
						<div className={container}>
							<SupervisorDashboard
								editing={editingLayout}
								itemCls={itemCls}
								breakpoint={breakpoint}
								currentUser={currentUser}
								layouts={layouts}
								setLayouts={setLayouts}
								onLayoutChange={onLayoutChange}
								onWidthChange={onWidthChange}
								onBreakpointChange={onBreakpointChange}
							/>
						</div>
					</Tab>
				</Tabs>
			</div>
		)
	}

	return <div className={container}>{memboard}</div>
}

export const ItemMask = () => {
	const [css] = useStyletron()

	const style = css({
		width: "100%",
		height: "calc(100% - 20px)",
		position: "absolute",
		left: 0,
		top: 0,
		marginBottom: "-10px",
		background: "white",
		opacity: 0.6,
		zIndex: 99,
	})
	return <div className={style} />
}

export interface NodeFNProps {
	editing?: boolean
	itemCls: string
	breakpoint: string
	currentUser: UserDetail
	layouts?: Layouts
	onLayoutChange: (storageKey: string, breakpoint: string) => (layout: Layout[], allLayouts: Layouts) => void
	setLayouts: (layouts: Layouts) => void
	onWidthChange: (width: number) => void
	onBreakpointChange: (bpoint: string) => void
}
