import * as React from "react"
import moment from "moment-timezone"
import { useParameterizedQuery } from "react-fetching-library"
import { fetching } from "../fetching"
import { CheckAvailabilityInput } from "../fetching/inputType"
import { CheckAvailability, Timezone } from "../types/types"
import { setTimezoneToTime } from "./utils"

interface Props {
	/** Will change validations messages to say 'time' instead of 'date' */
	timeOnly?: boolean

	/** Will force time aligned with the provided timezone */
	timezone?: Timezone[]
}

/** Checks worker availability */
export const useCheckWorkerAvailability = ({ timeOnly, timezone }: Partial<Props> = {}) => {
	const { query, payload, loading } = useParameterizedQuery<CheckAvailability>(fetching.query.checkWorkerAvailability)

	const [state, setState] = React.useState<CheckAvailability>()
	React.useEffect(() => {
		// Time only messages?
		if (timeOnly && !!payload) {
			if (payload.message === "Start date must be before end date") {
				payload.message = "Start time must be before end time"
			} else if (payload.message === "End date must be after start date") {
				payload.message = "End time must be after start time"
			}
		}

		if (!!payload?.startTime && !!payload.endTime) {
			// parse end time format, show date info if the date in start time and end time is not the same
			let endTimeFormat = "hh:mm A"
			if (!moment(payload.startTime).isSame(payload.endTime, "date")) endTimeFormat = "DD/MM/YYYY hh:mm A"

			let startTime = moment(payload.startTime)
			let endTime = moment(payload.endTime)

			if (timezone && timezone.length > 0) {
				startTime = startTime.tz(timezone[0].id)
				endTime = endTime.tz(timezone[0].id)
				if (payload.message.includes("[start]")) payload.message = `${payload.message} (${timezone[0].label})`
			}

			payload.message = payload.message.replace("[date]", startTime.format("DD/MM/YYYY"))
			payload.message = payload.message.replace("[start]", startTime.format("hh:mm A"))
			payload.message = payload.message.replace("[end]", endTime.format(endTimeFormat))
		}

		setState(payload)
	}, [payload, timeOnly, timezone])

	const check = React.useMemo(
		() => (input: CheckAvailabilityInput) => {
			input.startTime = moment(setTimezoneToTime(moment(input.startTime).toDate(), input.timezone.id))
			input.endTime = moment(setTimezoneToTime(moment(input.endTime).toDate(), input.timezone.id))

			// if compare time exists, set error if two time frame is overlapped
			// this is used for admin note time, checking whether it is within its own session time
			if (input.compareStartTime && input.compareEndTime) {
				input.compareStartTime = moment(setTimezoneToTime(moment(input.compareStartTime).toDate(), input.timezone.id))
				input.compareEndTime = moment(setTimezoneToTime(moment(input.compareEndTime).toDate(), input.timezone.id))
				if (input.compareEndTime.toDate() >= input.startTime.toDate() && input.compareStartTime.toDate() <= input.endTime.toDate()) {
					setState({
						available: false,
						message: "Admin note time is within the current session time frame",
					})
					return
				}
			}

			// If billable, check hours
			if (input.billableSession) {
				const startTime = input.startTime.toDate()
				const endTime = input.endTime.toDate()

				// parse date
				const formattedDate = {
					year: startTime.getFullYear(),
					month: startTime.getMonth(),
					date: startTime.getDate(),
				}

				const earliestTime = setTimezoneToTime(moment(`6:00am`, "h:mma").set(formattedDate).toDate(), input.timezone.id)
				const latestTime = setTimezoneToTime(moment("10:00pm", "h:mma").set(formattedDate).toDate(), input.timezone.id)
				if (startTime < earliestTime || startTime > latestTime || endTime < earliestTime || endTime > latestTime) {
					setState({
						available: false,
						message: "Times must be in between 6:00AM to 10:00PM",
					})
					return
				}
			}

			// Check worker availability
			query(input)
		},
		[query],
	)

	return {
		check,
		resetState: () => setState(undefined),
		loading,
		state,
		available: state?.available,
		message: state?.message,
	}
}
