import * as React from "react"
import { Route, RouteComponentProps, Switch } from "react-router-dom"

import { CallLogList } from "../../components/callLogs/callLogList"
import { PortalContainer } from "../../controllers/portal"
import { CallLogCreate } from "./callLogCreate"
import { CallLogView } from "./callLogView"
import { routes } from "routes"

export default function CallLogPage() {
	return (
		<Switch>
			<Route exact path={routes.callLogs.root} component={CallLogList} />
			<Route exact path={routes.callLogs.create} component={CallLogCreate} />
			<Route exact path={routes.callLogs.view} component={CallLogSub} />
		</Switch>
	)
}

export const CallLogSub = (props: RouteComponentProps<{ callLogID: string }>) => {
	const { fetchCall } = PortalContainer.useContainer()

	React.useEffect(() => {
		fetchCall(props.match.params.callLogID)
	}, [props.match.params.callLogID]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Switch>
			<Route exact path={routes.callLogs.view} component={CallLogView} />
		</Switch>
	)
}
