import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge } from "baseui/typography"

import { ZenCard } from "../../common"

const HazardRiskInfoCard = (props: { visible: boolean }) => {
	const [css] = useStyletron()
	const cardStyle = css({
		padding: "20px",
		width: "100%",
		height: "fit-content",
		maxHeight: "40vh",
		overflowY: "auto",
		fontSize: "14px",
		visibility: props.visible ? "unset" : "hidden",
	})
	const innerStyle = css({
		paddingBottom: "10px",
	})

	return (
		<ZenCard className={cardStyle}>
			<div className={innerStyle}>
				<LabelLarge>Results</LabelLarge>

				<InfoBox colour="#73DD7F" header="Result 2 to 5 - LOW RISK" footer="Action needed more than 3 months">
					Adequately controlled but continue to review. Any action ought to be possible at a local/site level and attempts made, where reasonably practicable to
					move the risk into the Trivial Category.
				</InfoBox>

				<InfoBox colour="#F3F861" header="Result 6 to 11 - MEDIUM RISK" footer="Action needed within 3 months">
					Any action should be aimed at moving this hazard down a grade as soon as reasonably practicable, but a factor in such a decision may be the need to
					clear High risks or to affect a greater number of Low risks- giving a better overall environment for team members.
				</InfoBox>

				<InfoBox colour="#FF9553" header="Result 12 to 19 - HIGH RISK" footer="Action needed within 1 months">
					Every effort must be made to reduce this risk by at least one, ideally two grades – i.e. down to Medium/Low. Action should be taken urgently by
					management to reduce such a level of risk and to alert team members to it.
				</InfoBox>

				<InfoBox colour="#FF5353" header="Result 20 to 25 - IMMEDIATE RISK" footer="Action needed within 1 week">
					Activity MUST cease and not proceed until further action is taken to reduce the risk.
				</InfoBox>
			</div>
		</ZenCard>
	)
}

interface InfoBoxProps {
	colour: string
	header: string
	footer: string
	children: string
}

const InfoBox = ({ colour, header, footer, children }: InfoBoxProps) => {
	const [css] = useStyletron()
	const headerStyle = css({
		fontWeight: 600,
	})
	const textStyle = css({
		marginTop: "10px",
		marginBottom: "10px",
	})
	const containerStyle = css({
		width: "100%",
		borderRadius: "3px",
		backgroundColor: colour,
		fontSize: "14px",
		marginTop: "12px",
		padding: "10px",
	})

	return (
		<div className={containerStyle}>
			<div className={headerStyle}>{header}</div>
			<div className={textStyle}>{children}</div>
			<div className={headerStyle}>{footer}</div>
		</div>
	)
}

export default HazardRiskInfoCard
