import { Value } from "baseui/select"
import { CallFromClientOptions, CallTypeOptions } from "../components/callLogs/callLogCreateForm"
import { Client, UserDetail } from "../types/types"

export interface CallLogState {
	stale: boolean
	callPurpose?: Value
	callPurposeType?: Value
	client?: Client[]
	worker?: UserDetail[]
	callTypeOptionKey: string
	callFromOptionKey: string
	callerName: string
	phoneNumber: string
	date: Date
	startTime: Date
	endTime?: Date
	transferredTo?: Value
	transferredToWorker?: UserDetail[]
	note: string
}

/**
 * Default values for Call Log Create State Data
 */
export const callLogCreateInitialState = (): CallLogState => {
	return {
		stale: false,
		callPurpose: undefined,
		callPurposeType: undefined,
		client: undefined,
		worker: undefined,
		transferredTo: undefined,
		transferredToWorker: undefined,
		callTypeOptionKey: Object.values(CallTypeOptions)[0],
		callFromOptionKey: Object.values(CallFromClientOptions)[0],
		callerName: "",
		phoneNumber: "",
		date: new Date(),
		startTime: new Date(),
		endTime: undefined,
		note: "",
	}
}
