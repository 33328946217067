import * as React from "react"
import { useStyletron } from "baseui"
import { Value } from "baseui/select"
import { LabelLarge, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router"
import { routes } from "routes"
import { AuthContainer } from "../../../controllers/auth"
import { useCheckWorkerAvailability } from "../../../helpers/useCheckWorkerAvailability"
import { RolePermission } from "../../../types/types"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { AvailableCheck, Divider } from "../../common"
import { ZenClientSelect, ZenPlaceSelect, ZenSelect, ZenTimezoneSelect, ZenUserSelect } from "../../zenComponents/zenSelectBox"
import { ZenDatePicker, ZenTimePicker } from "../../zenComponents/zenTime"
import { GroupAppointmentFormData, GroupAppointmentFormProps } from "./groupSessionBaseForm"
import { ErrorFieldTracker } from "../../forms/errorFieldTracker"
import { ZenTheme } from "../../../themeOverrides"
import { SessionClientType } from "../../../types/enums"
import { snakeToTitle } from "../../../helpers/utils"

export const GroupSessionGeneralForm = (props: GroupAppointmentFormProps) => {
	const { data, setData, changePage, setIsDirty } = props
	const history = useHistory()
	const searchArgs = React.useMemo(() => {
		return new URLSearchParams(history.location.search)
	}, [history.location.search])
	// auth
	const { hasPermission } = AuthContainer.useContainer()
	// first contact
	const [firstContactDate, setFirstContactDate] = React.useState<string>()
	// excluded id
	const [excludedID, setExcludedID] = React.useState<string[]>([])

	const { control, handleSubmit, setValue, getValues, errors, clearErrors, watch, formState } = useForm()

	React.useEffect(() => {
		if (setIsDirty) setIsDirty(formState.isDirty)
	}, [setIsDirty, formState.isDirty])

	// watches date/ time / worker inputs changes
	const watchDate = watch("appointmentDate")
	const watchTimezone = watch("timezone")
	const watchStartTime = watch("startTime")
	const watchEndTime = watch("endTime")
	const watchWorker = watch("worker")

	const workerAvailability = useCheckWorkerAvailability({ timeOnly: true, timezone: watchTimezone })

	// checks availability
	React.useEffect(() => {
		handleCheckAvailability()
	}, [watchDate, watchStartTime, watchEndTime, watchWorker, watchTimezone]) // eslint-disable-line react-hooks/exhaustive-deps

	// check availability handler
	const handleCheckAvailability = async () => {
		// form inputs
		const input: GroupAppointmentFormData = getValues()

		// null checks
		if (!input.worker || !input.worker.length || !input.startTime || !input.endTime || !watchTimezone || !watchTimezone.length) {
			if (workerAvailability.state) workerAvailability.resetState()
			return
		}

		workerAvailability.check({
			workerIDs: [input.worker[0].id as string],
			timezone: watchTimezone[0],
			startTime: input.startTime,
			endTime: input.endTime,
			billableSession: true,
		})
	}

	// set initial state
	const loadDefaults = React.useCallback(() => {
		setValue("worker", data.worker || [])
		setValue("organisation", data.organisation || [])
		setValue("startTime", data.startTime || null)
		setValue("endTime", data.endTime || null)
		setValue("appointmentDate", data.appointmentDate || new Date())
		setValue("sessionAddress", data.sessionAddress)
		setValue("clientType", data.clientType || [])
		if (data.organisation && data.organisation.length > 0) {
			setFirstContactDate(data.organisation[0].dateOfFirstContact)
		}
	}, [setValue, data])
	React.useEffect(() => {
		loadDefaults()
	}, [loadDefaults])

	// handles organisation input change
	const onOrgChange = (o: Value) => {
		if (o.length > 0) {
			setFirstContactDate(o[0].dateOfFirstContact)
			if (o[0].currentContact && o[0].currentContact.residentialAddress) {
				setValue("sessionAddress", [{ id: o[0].currentContact.residentialAddress.placeID, label: o[0].currentContact.residentialAddress.fullAddress }])
			} else {
				setValue("sessionAddress", [])
			}
			clearErrors("sessionAddress")
			return
		}
		setFirstContactDate("")
	}

	// adjust start/end time on data change
	const handleDateOnChange = (date: Date) => {
		setValue(
			"startTime",
			moment(getValues().startTime ?? date.setMinutes(0))
				.set({
					year: date.getFullYear(),
					month: date.getMonth(),
					date: date.getDate(),
				})
				.toDate(),
		)

		setValue(
			"endTime",
			moment(getValues().endTime ?? date.setMinutes(0))
				.set({
					year: date.getFullYear(),
					month: date.getMonth(),
					date: date.getDate(),
				})
				.toDate(),
		)
	}

	// submit handler
	const submit = (formData: any) => {
		setData((d) => ({
			...d,
			...formData,
		}))

		if (!workerAvailability.available) return

		changePage(1)
	}
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		width: "50%",
		[`@media only screen and (max-width: 1500px)`]: {
			width: "90%",
		},
	})
	const rangeTimeStyle = css({
		marginTop: "8px",
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	})
	const timerStyle = css({
		width: "49%",
	})

	const body = css({
		height: "100%",
		minHeight: 0,
		display: "flex",
		flexDirection: "column",
	})

	const scrollingDiv = css({
		overflowY: "auto",
		height: "100%",
		maxHeight: "100%",
		minHeight: 0,
		paddingRight: "8px",
	})

	return (
		<form autoComplete="off" className={container} onSubmit={handleSubmit(submit)}>
			<LabelLarge>General</LabelLarge>
			<div className={body}>
				<div className={scrollingDiv}>
					<ZenUserSelect
						label="Worker"
						formName="worker"
						formRef={control}
						inputError={errors.worker}
						disabled={!hasPermission(RolePermission.SessionUpdate)}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "worker is required",
							},
						}}
						actionOnChange={(v) => setExcludedID(v.length > 0 && v[0].id ? [v[0].id.toString()] : [])}
						excludedID={excludedID}
					/>
					<Divider style={{ backgroundColor: "white" }} />

					<ZenDatePicker label="Appointment date" formName="appointmentDate" actionOnChange={handleDateOnChange} formRef={control} />

					<Divider style={{ backgroundColor: "white" }} />

					<ZenTimezoneSelect
						formRef={control}
						formName="timezone"
						label="Timezone"
						clearable={false}
						timezone={data.timezone}
						actionOnChange={(v) => {
							const offsetDifferent = watchTimezone[0].offsetMinutes - v[0].offsetMinutes
							if (watchStartTime) setValue("startTime", moment(watchStartTime).add(offsetDifferent, "minute"))
							if (watchEndTime) setValue("endTime", moment(watchEndTime).add(offsetDifferent, "minute"))
						}}
					/>

					<Divider style={{ backgroundColor: "white" }} />

					<div className={rangeTimeStyle}>
						<div className={timerStyle}>
							<ZenTimePicker
								inputError={errors.startTime}
								label="Start Time"
								formName="startTime"
								date={watch("appointmentDate")}
								creatable
								nullable
								formRef={control}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "Start time is required"
											}

											if (watchEndTime && moment(value).isSameOrAfter(watchEndTime)) {
												return "Start time must be before end time"
											}
											return null
										},
									},
								}}
							/>
						</div>
						<div className={timerStyle}>
							<ZenTimePicker
								inputError={errors.endTime}
								label="End Time"
								formName="endTime"
								date={watch("appointmentDate")}
								creatable
								nullable
								formRef={control}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "End time is required"
											}
											if (watchStartTime && moment(value).isSameOrBefore(watchStartTime)) {
												return "End time must be after start time"
											}
											return null
										},
									},
								}}
							/>
						</div>
					</div>

					<div>{!!workerAvailability.state && <AvailableCheck isAvailable={workerAvailability.state} />}</div>

					<Divider style={{ backgroundColor: "white" }} />

					<ZenClientSelect
						formRef={control}
						label="Organisation"
						placeholder="Select an organisation"
						formName="organisation"
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Organisation is required",
							},
						}}
						isOrganisation
						disabled={!!searchArgs.get("client_id")}
						inputError={errors.organisation}
						actionOnChange={onOrgChange}
						customError={"Group session cannot be created unless you have been assigned an organisation client by your supervisor"}
					/>
					{firstContactDate && (
						<>
							<LabelSmall marginTop="10px" marginBottom={firstContactDate ? 0 : "20px"}>
								Date of First Contact
							</LabelSmall>
							<LabelSmall marginBottom="5px" padding={"15px"} backgroundColor={ZenTheme.colors.lightGrey}>
								{moment(firstContactDate).format("DD/MM/YYYY")}
							</LabelSmall>
						</>
					)}

					<Divider style={{ backgroundColor: "white" }} />

					<ZenPlaceSelect
						label="Session Address"
						formName="sessionAddress"
						formRef={control}
						placeholder="Address here"
						inputError={errors.sessionAddress}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Address is required",
							},
						}}
					/>

					<Divider style={{ backgroundColor: "white" }} />
					<ZenSelect
						label="Client Type"
						formName="clientType"
						options={Object.entries(SessionClientType).map((sct) => ({ id: sct[1], label: snakeToTitle(sct[0]) }))}
						formRef={control}
					/>
					<Divider style={{ backgroundColor: "white" }} />
				</div>
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				<CancelAndSaveButtons cancelLabel="Back" cancelFn={() => history.push(routes.sessions.root)} saveLabel="Next" />
			</div>
		</form>
	)
}
