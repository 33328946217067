import * as React from "react"
import { Document, Image, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import { useStyletron } from "baseui"
import helping_minds_logo from "../../../assets/images/helping_minds_logo.png"
import moment from "moment-timezone"

import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { friendlyDate, friendlyTime, splitOnCaps } from "../../../helpers/utils"
import { MileageClaim, Travel, TZString, ZenDateFormat } from "../../../types/types"
import { Loading } from "../../../components/loading"
import { ReviewStatus } from "../../../types/enums"

interface MileageClaimProps {
	rows: Travel[]
	mileageClaim: MileageClaim
	totalDistance: number
	totalDistancePaid: number
	totalDistanceOther: number
}

interface MileageClaimPDFPreviewProps {
	fileName?: string
	claims: MileageClaimProps[]
}

export const MileageClaimPDFPreview = (props: MileageClaimPDFPreviewProps) => {
	const { claims } = props

	const [css] = useStyletron()
	const exportContainer = css({
		height: "90%",
		overflowY: "auto",
	})

	// eg fileName: will_smith_2020_10_10_to_2020_10_17.pdf (if single claim)
	if (claims.length === 0) return null

	const documentFileName =
		props.fileName ||
		`${claims[0].mileageClaim.user.firstName}_${claims[0].mileageClaim.user.lastName}_${friendlyDate(
			claims[0].mileageClaim.periodStart,
			TZString[0],
		)}_to_${friendlyDate(claims[0].mileageClaim.periodEnd, TZString[0])}.pdf`

	const document = <MileageClaimPDF fileName={documentFileName} claims={claims} />

	return (
		<>
			<div className={exportContainer}>
				<PDFViewer width={"100%"} height={"100%"}>
					{document}
				</PDFViewer>
			</div>
			<br />
			<ZenButton>
				<PDFDownloadLink style={{ color: "white" }} document={document} fileName={documentFileName}>
					{({ loading, blob }) => (loading || !blob ? <Loading hideLabel /> : "Export")}
				</PDFDownloadLink>
			</ZenButton>
		</>
	)
}

const BORDER_COLOR = "#bfbfbf"
const BORDER_STYLE = "solid"
const COL1_WIDTH = 30
const COLN_WIDTH = (80 - COL1_WIDTH) / 3

const styles = StyleSheet.create({
	body: {
		padding: 15,
	},
	bodyCentered: {
		padding: 15,
		textAlign: "center",
	},

	top: {
		padding: 15,
		textAlign: "center",
	},
	checklist: {
		display: "flex",
		flexDirection: "row",
		marginBottom: 5,
	},
	checklistBox: {
		borderStyle: BORDER_STYLE,
		borderColor: "#000",
		borderWidth: 1,
		width: 16,
		height: 16,
		marginRight: 7,
	},
	checklistQuestion: {
		flexGrow: 1,
		fontSize: 13,
	},
	table: {
		width: "auto",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderTopWidth: 0,
		borderRightWidth: 0,
		borderLeftWidth: 0,
		borderBottomWidth: 0,
	},
	tableRow: {
		flexDirection: "row",
	},
	tableCol1Header: {
		width: COL1_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderBottomColor: "#000",
		borderWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 1,
	},
	tableColHeader: {
		width: COLN_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderBottomColor: "#000",
		borderWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 1,
	},

	tableCol1: {
		width: COL1_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 0,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderBottomWidth: 1,
	},

	tableColAlt: {
		width: COL1_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderBottomWidth: 1,
	},

	tableCol: {
		width: COLN_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 0,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderBottomWidth: 1,
	},

	tableColBlank: {
		width: COLN_WIDTH + 15 + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 1,
		borderBottomWidth: 1,
		height: "40px",
	},

	tableColHeaderAlt: {
		width: COLN_WIDTH + 15 + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderBottomColor: "#000",
		borderWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 1,
	},
	tableColSummary: {
		width: COLN_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderRightWidth: 0,
	},

	tableColSm: {
		width: COLN_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 0,
	},

	tableCellHeader: {
		margin: 5,
		fontSize: 12,
	},
	tableCell: {
		margin: 5,
		fontSize: 10,
	},

	tableCellAlt: {
		margin: 5,
		fontSize: 10,
	},

	divider: {
		padding: "4px",
	},

	infoText: {
		paddingBottom: "4px",
		fontSize: 13,
	},

	titleText: {
		paddingBottom: "4px",
		fontSize: 25,
		color: "#88BB3C",
	},

	block: {
		width: "auto",
	},
	hmLogoContainer: {
		height: "60px",
		width: "100%",
		textAlign: "center",
	},
	hmLogo: {
		height: "70px",
		width: "120px",
		textAlign: "center",
		margin: "auto",
	},
})

const getDistancePayable = (travel: Travel): number => {
	if (!travel.travelType.distancePayable || travel.car) {
		return 0
	}

	if (!!travel.maximumDistancePayable) {
		if (travel.actualDistanceMetres > travel.maximumDistancePayable) {
			return travel.actualDistanceMetres - travel.maximumDistancePayable
		}
		return 0
	}
	return travel.actualDistanceMetres
}

const getDistanceOther = (travel: Travel): number => {
	if (!travel.travelType.distancePayable || travel.car) {
		return travel.actualDistanceMetres
	}

	if (!!travel.maximumDistancePayable) {
		if (travel.actualDistanceMetres > travel.maximumDistancePayable) {
			return travel.actualDistanceMetres - (travel.actualDistanceMetres - travel.maximumDistancePayable)
		}
		return travel.actualDistanceMetres
	}

	return 0
}

const Divider = () => (
	<>
		<View style={styles.divider}></View>
		<View style={styles.divider}></View>
	</>
)

interface MileageClaimPDFProps {
	fileName: string
	claims: MileageClaimProps[]
}

const MileageClaimPDF = (props: MileageClaimPDFProps) => {
	const { fileName, claims } = props

	return (
		<Document title={fileName}>
			{claims.map((c, i) => {
				let TravelRow: Travel[][] = [[]]
				let index = 0
				let pageHeight = 0
				c.rows.forEach((d) => {
					const rowHeight = 2 + Math.ceil(d.fromAddress.fullAddress.length / 40) + Math.ceil(d.toAddress.fullAddress.length / 40)
					if (pageHeight + rowHeight <= 40) {
						pageHeight += rowHeight
						TravelRow[index].push(d)
						return
					}
					pageHeight = 0
					index += 1
					TravelRow.push([])
					TravelRow[index].push(d)
				})

				return <MileageClaimPDFPage key={`page-${i}`} travelList={TravelRow} {...c} />
			})}
		</Document>
	)
}

const MileageClaimPDFHeaderRow = () => {
	return (
		<View style={styles.tableRow}>
			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>Date/Start Time</Text>
			</View>
			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>Travel Type</Text>
			</View>
			<View style={styles.tableCol1Header}>
				<Text style={styles.tableCellHeader}>Start Location/ Destination</Text>
			</View>
			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>Paid</Text>
			</View>
			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>Other</Text>
			</View>
		</View>
	)
}

const MileageClaimPDFPage = (props: MileageClaimProps & { travelList: Travel[][] }) => {
	return (
		<>
			<Page>
				<MileageReimbursementPDF {...props} />
			</Page>
			{props.travelList.map((ts, i) => (
				<React.Fragment key={`page-${i}`}>
					<Page style={styles.body} orientation={"landscape"}>
						{i === 0 && (
							<>
								<View style={styles.block}>
									<Text style={styles.titleText}>Travel Log</Text>
									<Text style={styles.infoText}>
										{props.mileageClaim.user.firstName} {props.mileageClaim.user.lastName} | {moment(props.mileageClaim.user.dateOfBirth).format(ZenDateFormat)}{" "}
										| {splitOnCaps(props.mileageClaim.user.status)}
									</Text>
									<Text style={styles.infoText}>
										Period: {friendlyDate(props.mileageClaim.periodStart, TZString[0])} - {friendlyDate(props.mileageClaim.periodEnd, TZString[0])}
									</Text>
								</View>

								<Divider />
							</>
						)}

						<View style={styles.table}>
							<MileageClaimPDFHeaderRow />
							{ts.length > 0 &&
								ts.map((d, idx) => {
									// every travel record has one \n and one divider (count as two row)
									const tz = TZString.find((tzs) => tzs.id === d.timezoneID) || TZString[0]
									return (
										<View key={`row-${d.id}-${idx}`}>
											<View style={styles.tableRow} key={idx}>
												<View style={styles.tableCol}>
													<Text style={idx === 0 ? styles.tableCell : styles.tableCellAlt}>
														{friendlyDate(d.startTime, tz)} / {friendlyTime(tz, d.startTime)}
													</Text>
													<Text style={styles.tableCellAlt}>{d.timezoneID}</Text>
												</View>
												<View style={styles.tableCol}>
													<Text style={styles.tableCell}>{d.travelType.label}</Text>
												</View>
												<View style={styles.tableCol1}>
													<Text style={styles.tableCell}>{d.fromAddress.fullAddress}</Text>
													<Text style={styles.tableCell}>{d.toAddress.fullAddress}</Text>
												</View>
												<View style={styles.tableCol}>
													<Text style={styles.tableCell}>{`${(getDistancePayable(d) / 1000).toFixed(2)}kms`}</Text>
												</View>

												<View style={styles.tableCol}>
													<Text style={styles.tableCell}>{`${(getDistanceOther(d) / 1000).toFixed(2)}kms`}</Text>
												</View>
											</View>
										</View>
									)
								})}
						</View>
						<View style={styles.divider}></View>
					</Page>
				</React.Fragment>
			))}
		</>
	)
}

const MileageReimbursementPDFHeader = (props: { headerTitle: string }) => {
	return (
		<View style={styles.tableRow}>
			<View style={styles.tableColHeaderAlt}>
				<Text style={styles.tableCellHeader}>{props.headerTitle} </Text>
			</View>
			<View style={styles.tableColHeaderAlt}>
				<Text style={styles.tableCellHeader}>Date</Text>
			</View>
		</View>
	)
}

const MileageReimbursementPDF = (props: MileageClaimProps) => {
	return (
		<View style={styles.body}>
			<View style={styles.top}>
				<View style={styles.hmLogoContainer}>
					<Image style={styles.hmLogo} src={helping_minds_logo} />
				</View>
				<Divider />
				<Text style={styles.titleText}>Mileage Reimbursement Form</Text>
			</View>

			<Divider />
			<Divider />

			<Text style={styles.infoText}>
				Name: {props.mileageClaim.user.firstName} {props.mileageClaim.user.lastName}
			</Text>
			<Text style={styles.infoText}>Date: {friendlyDate(moment())}</Text>

			<Divider />
			<Text style={styles.infoText}>I am claiming the total of {`${(props.totalDistancePaid / 1000).toFixed(2)}`} business kilometers for the period:</Text>
			<Text style={styles.infoText}>
				From: {friendlyDate(props.mileageClaim.periodStart, TZString[0])} To {friendlyDate(props.mileageClaim.periodEnd, TZString[0])}
			</Text>

			<Divider />
			{/* table 1 */}
			<MileageReimbursementPDFHeader headerTitle="Checked by" />
			<View style={styles.tableRow}>
				<View style={styles.tableColBlank}>
					<Text style={styles.tableCell}>
						{props.mileageClaim.checkedBy && (
							<>
								{props.mileageClaim.checkedBy.firstName} {props.mileageClaim.checkedBy.lastName}
							</>
						)}
					</Text>
				</View>
				<View style={styles.tableColBlank}>
					<Text style={styles.tableCell}>{props.mileageClaim.checkedAt && <>{friendlyDate(props.mileageClaim.checkedAt)}</>}</Text>
				</View>
			</View>

			<Divider />

			{/* table 2 */}
			<MileageReimbursementPDFHeader headerTitle="Approved by" />
			<View style={styles.tableRow}>
				<View style={styles.tableColBlank}>
					<Text style={styles.tableCell}>
						{props.mileageClaim.reviewStatus === ReviewStatus.Approved && props.mileageClaim.reviewedBy && (
							<>
								{props.mileageClaim.reviewedBy.firstName} {props.mileageClaim.reviewedBy.lastName}
							</>
						)}
					</Text>
				</View>
				<View style={styles.tableColBlank}>
					<Text style={styles.tableCell}>
						{props.mileageClaim.reviewStatus === ReviewStatus.Approved && props.mileageClaim.reviewedAt && <>{friendlyDate(props.mileageClaim.reviewedAt)}</>}
					</Text>
				</View>
			</View>

			<Divider />

			<View>
				<Text style={styles.infoText}>Total Distance = {`${(props.totalDistance / 1000).toFixed(2)}kms`}</Text>
			</View>

			<View style={styles.divider}></View>
			<View>
				<Text style={styles.infoText}>Total Distance (Paid) = {`${(props.totalDistancePaid / 1000).toFixed(2)}kms`}</Text>
			</View>

			<View style={styles.divider}></View>
			<View>
				<Text style={styles.infoText}>Total Distance (Other) = {`${(props.totalDistanceOther / 1000).toFixed(2)}kms`}</Text>
			</View>
		</View>
	)
}
