import * as React from "react"
import { Control } from "react-hook-form"
import { FieldError, FieldValues } from "react-hook-form/dist/types/form"
import { DeepMap } from "react-hook-form/dist/types/utils"
import { useParameterizedQuery, useQuery } from "react-fetching-library"
import { SessionFundingSource, Client, ContractArea, SupportType, SubSupportType, FundingSource } from "../../types/types"
import { fetching } from "../../fetching"
import { ErrorNotification } from "../errorBox"
import { Value } from "baseui/select"
import { ZenSelect } from "../zenComponents/zenSelectBox"
import { Divider } from "../common"
import { useStyletron } from "styletron-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ZenTheme } from "../../themeOverrides"
import { LabelSmall } from "baseui/typography"

interface SessionFundingSourceFormProps {
	control: Control<FieldValues>
	errors: DeepMap<FieldValues, FieldError>
	placeholder: number[]
	watch: any
	setValue: any
	setAvailableIndexes: React.Dispatch<React.SetStateAction<number[]>>
	setPlaceholder: React.Dispatch<React.SetStateAction<number[]>>
	sessionFundingSources?: SessionFundingSource[]
	isGroupSession?: boolean
	disabledFundingSource?: boolean
	disabledDelete?: boolean
	onPostSession?: boolean
	involvedClient?: Client[]
	showContractArea?: boolean // force to show contract area field
}

export const SessionFundingSourceForm = (props: SessionFundingSourceFormProps) => {
	const {
		control,
		errors,
		placeholder,
		watch,
		setValue,
		setAvailableIndexes,
		setPlaceholder,
		sessionFundingSources,
		isGroupSession,
		onPostSession,
		involvedClient,
		showContractArea,
	} = props

	const contractAreaID = React.useMemo(() => {
		if (!involvedClient) return
		const targetClient = involvedClient.find((ic) => !!ic.contractAreaID)
		if (!targetClient) return
		return targetClient.contractAreaID
	}, [involvedClient])

	const [selectedFundingSources, setSelectedFundingSources] = React.useState<string[]>([])

	// "deleted indexes" is an indicator to prevent data to be pre-fill again if any funding source was deleted
	const [deletedIndexes, setDeletedIndexes] = React.useState<number[]>([])
	const recordDeletedIndex = (index: number) => {
		if (!deletedIndexes.includes(index)) {
			setDeletedIndexes(deletedIndexes.concat(index))
		}
	}

	React.useEffect(() => {
		if (!sessionFundingSources || sessionFundingSources.length === 0 || !sessionFundingSources[0].fundingSource) return
		setSelectedFundingSources(sessionFundingSources.map((sfs) => sfs.fundingSource.label))
	}, [sessionFundingSources])

	// get entire funding source list
	const getFundingSourceAll = useQuery(fetching.query.getFundingSourceAll())
	const fundingSourceList = React.useMemo(() => {
		if (getFundingSourceAll.loading || getFundingSourceAll.error || !getFundingSourceAll.payload) return []
		let list = [...getFundingSourceAll.payload]

		// filter out NDIA if it is a group session
		if (isGroupSession) list = list.filter((fs) => fs.label !== "NDIA")

		// filter out the options which are already selected
		if (selectedFundingSources.length > 0) list = list.filter((fs) => !selectedFundingSources.includes(fs.label))

		return list
	}, [getFundingSourceAll.payload, getFundingSourceAll.loading, getFundingSourceAll.error, selectedFundingSources, isGroupSession])
	return (
		<>
			{getFundingSourceAll.error && <ErrorNotification messageOrPayload={getFundingSourceAll.payload} />}
			{placeholder.map((p, i) => {
				// prepare pre-fill data
				let sessionFundingSource: SessionFundingSource | undefined = undefined

				// if current index has not been deleted before AND
				// session funding sources is provided AND
				// current index is within the range of provided session funding source list
				if (!deletedIndexes.includes(p) && sessionFundingSources && sessionFundingSources.length > p) {
					// set pre-fill data
					sessionFundingSource = sessionFundingSources[p]
				}
				return (
					<SessionFundingSourceInner
						key={i}
						contractAreaID={contractAreaID}
						showContractArea={showContractArea}
						setAvailableIndexes={setAvailableIndexes}
						index={p}
						control={control}
						errors={errors}
						watch={watch}
						setValue={setValue}
						setSelectedFundingSources={setSelectedFundingSources}
						setPlaceholder={setPlaceholder}
						recordDeletedIndex={recordDeletedIndex}
						sessionFundingSource={sessionFundingSource}
						onPostSession={onPostSession}
						// filter "NDIA" option in the addition funding source select box
						fundingSourceList={i === 0 ? fundingSourceList : fundingSourceList.filter((fs) => fs.label !== "NDIA")}
					/>
				)
			})}
		</>
	)
}

interface SessionFundingSourceInnerProps {
	index: number
	control: Control<FieldValues>
	errors: DeepMap<FieldValues, FieldError>
	fundingSourceList: FundingSource[]
	sessionFundingSource?: SessionFundingSource
	recordDeletedIndex: (index: number) => void
	setSelectedFundingSources: React.Dispatch<React.SetStateAction<string[]>>
	setAvailableIndexes: React.Dispatch<React.SetStateAction<number[]>>
	setPlaceholder: React.Dispatch<React.SetStateAction<number[]>>
	onPostSession?: boolean
	watch: any
	setValue: any
	contractAreaID?: string
	showContractArea?: boolean
}
const SessionFundingSourceInner = (props: SessionFundingSourceInnerProps) => {
	const {
		control,
		errors,
		index,
		fundingSourceList,
		watch,
		setValue,
		setSelectedFundingSources,
		setAvailableIndexes,
		setPlaceholder,
		recordDeletedIndex,
		sessionFundingSource,
		onPostSession,
		contractAreaID,
		showContractArea,
	} = props

	const [css] = useStyletron()

	// watch value change
	const watchedFundingSource: FundingSource[] = watch(`fundingSource-${index}`) || []
	const watchedSupportType: SupportType[] = watch(`supportType-${index}`) || []

	const hadBorder = !onPostSession && index !== 0
	// show contract area field only if
	// 1. showContractArea is true OR
	// 2. related client does not have contract area id OR
	// 3. current funding source is not NDIA
	const showContractAreaField =
		showContractArea ||
		(watchedFundingSource.length > 0 && watchedFundingSource[0].label === "NDIA" && !contractAreaID) ||
		!(watchedFundingSource.length > 0 && watchedFundingSource[0].label === "NDIA")

	// options
	const [contractAreaOptions, setContractAreaOptions] = React.useState<ContractArea[]>([])
	const [supportTypeOptions, setSupportTypeOptions] = React.useState<SupportType[]>([])
	const [subSupportTypeOptions, setSubSupportTypeOptions] = React.useState<SubSupportType[]>([])

	// fetching function for contract area options
	const getContractAreas = useParameterizedQuery(fetching.query.getContractAreasByFundingSourceID)
	const refetchContractAreas = (fundingSourceID: string) => {
		getContractAreas.query(fundingSourceID).then((resp) => {
			if (resp.error || !resp.payload) return
			setContractAreaOptions(resp.payload)
		})
	}

	// fetching function for support type options
	const getSupportTypes = useParameterizedQuery(fetching.query.getSupportTypesByFundingSourceID)
	const refetchSupportTypes = (fundingSourceID: string) => {
		getSupportTypes.query(fundingSourceID).then((resp) => {
			if (resp.error || !resp.payload) return
			setSupportTypeOptions(resp.payload)
		})
	}

	// fetching function for sub support type options
	const getSubSupportTypes = useParameterizedQuery(fetching.query.getSubSupportTypesBySupportTypeID)
	const refetchSubSupportTypes = (fundingSourceID: string, supportTypeID: string) => {
		getSubSupportTypes.query({ fundingSourceID, supportTypeID }).then((resp) => {
			if (resp.error || !resp.payload) return
			setSubSupportTypeOptions(resp.payload)
		})
	}

	React.useEffect(() => {
		if (!sessionFundingSource) return
		// refetch contract area options
		if (!onPostSession) refetchContractAreas(sessionFundingSource.fundingSource.id)
		// refetch support type options
		refetchSupportTypes(sessionFundingSource.fundingSource.id)
		// refetch sub support type options if support type is provided
		if (sessionFundingSource.supportType) refetchSubSupportTypes(sessionFundingSource.fundingSource.id, sessionFundingSource.supportType.id)
	}, [sessionFundingSource]) // eslint-disable-line react-hooks/exhaustive-deps

	const onDelete = () => {
		if (onPostSession) return
		// restore index back to available list
		setAvailableIndexes((ais) => ais.concat(index))

		// clean up all the previous data
		setValue(`fundingSource-${index}`, [])
		setValue(`contractArea-${index}`, [])
		setValue(`supportType-${index}`, [])
		setValue(`subSupportType-${index}`, [])

		// filter out the placeholder
		setPlaceholder((ps) => ps.filter((p) => p !== index))

		// record deleted index
		recordDeletedIndex(index)
	}

	React.useEffect(() => {
		const currentFundingSource = watch(`fundingSource-${index}`) || []
		if (
			!contractAreaID ||
			contractAreaOptions.length === 0 ||
			currentFundingSource.length === 0 ||
			currentFundingSource[0].label !== "NDIA" ||
			!!sessionFundingSource
		)
			return
		const targetContractArea = contractAreaOptions.find((ca) => ca.id === contractAreaID)
		if (!targetContractArea) return
		setValue(`contractArea-${index}`, [{ ...targetContractArea }])
	}, [contractAreaID, watch(`fundingSource-${index}`), contractAreaOptions, index, setValue, sessionFundingSource]) // eslint-disable-line react-hooks/exhaustive-deps

	const displayFundingSourceOption = () => {
		if (onPostSession) {
			if (!sessionFundingSource) return null
			return <LabelSmall>{`Funding source - ${sessionFundingSource.fundingSource.label}`}</LabelSmall>
		}
		return (
			<ZenSelect // funding contract
				label="Funding source"
				formName={`fundingSource-${index}`}
				formRef={control}
				inputError={errors[`fundingSource-${index}`]}
				clearable={false}
				formRules={{
					validate: {
						required: (value: Value) => (!!value && value.length > 0) || "Funding source is required",
					},
				}}
				options={fundingSourceList}
				actionOnChange={(v: Value) => {
					setSelectedFundingSources((sfs) => {
						let newList = [...sfs]

						// if funding source already has value, filter it out from the selected list
						if (watchedFundingSource && watchedFundingSource.length > 0) {
							newList = newList.filter((nl) => nl !== watchedFundingSource[0].label)
						}

						// if inputted value is not empty, push it to the selected list
						if (v.length > 0 && v[0].label) {
							newList.push(v[0].label.toString())
						}

						return newList
					})
					// clear up contract area, support type and sub support type
					setContractAreaOptions([])
					setSupportTypeOptions([])
					setSubSupportTypeOptions([])
					setValue(`contractArea-${index}`, [])
					setValue(`supportType-${index}`, [])
					setValue(`subSupportType-${index}`, [])

					// if value is empty skip rest of the process
					if (v.length === 0 || !v[0].id) return

					// Otherwise, refetch new contract area and support type options
					refetchContractAreas(v[0].id.toString())
					refetchSupportTypes(v[0].id.toString())
				}}
			/>
		)
	}

	const container = css({
		width: "100%",
		height: "fit-content",
		display: "flex",
		flexDirection: "column",
		position: "relative",
		border: hadBorder ? "2px solid " + ZenTheme.colors.primaryGrey : "unset",
		padding: hadBorder ? "6px 10px" : "unset",
		borderRadius: "5px",
		marginTop: onPostSession || index !== 0 ? "8px" : "unset",
	})

	const deleteIcon = css({
		position: "absolute",
		top: "8px",
		right: "12px",
		cursor: "pointer",
	})

	const innerDiv = css({
		padding: onPostSession ? "6px 10px" : "unset",
	})

	return (
		<div className={container}>
			{index !== 0 && !onPostSession && (
				<div className={deleteIcon} onClick={onDelete}>
					<FontAwesomeIcon icon={["fas", "times-circle"]} />
				</div>
			)}

			{displayFundingSourceOption()}

			<div className={innerDiv}>
				{showContractAreaField &&
					!onPostSession &&
					watchedFundingSource.length > 0 &&
					contractAreaOptions.length > 0 &&
					contractAreaOptions[0].fundingSourceID === watchedFundingSource[0].id && (
						<>
							<Divider style={{ backgroundColor: "white" }} />
							<ZenSelect // contract area
								label="Contract Area"
								formName={`contractArea-${index}`}
								formRef={control}
								options={contractAreaOptions}
							/>
							{getContractAreas.error && <ErrorNotification messageOrPayload={getContractAreas.payload} />}
						</>
					)}

				{watchedFundingSource.length > 0 &&
					watchedFundingSource[0].label !== "NDIA" &&
					supportTypeOptions.length > 0 &&
					supportTypeOptions[0].fundingSourceID === watchedFundingSource[0].id && (
						<>
							{!onPostSession && <Divider style={{ backgroundColor: "white" }} />}
							<ZenSelect
								label="Support Type"
								formName={`supportType-${index}`}
								formRef={control}
								inputError={errors[`supportType-${index}`]}
								actionOnChange={(v: Value) => {
									// clear up sub support type
									setSubSupportTypeOptions([])
									setValue(`subSupportType-${index}`, [])

									// if value is empty skip rest of the process
									if (v.length === 0 || !v[0].id) return

									// Otherwise, refetch new sub support types
									refetchSubSupportTypes(watchedFundingSource[0].id, v[0].id.toString())
								}}
								options={supportTypeOptions}
								formRules={{
									validate: {
										required: (value: Value) => !onPostSession || (!!value && value.length > 0) || "Support type is required",
									},
								}}
							/>
							{getSupportTypes.error && <ErrorNotification messageOrPayload={getSupportTypes.payload} />}

							{watchedSupportType.length > 0 && subSupportTypeOptions.length > 0 && subSupportTypeOptions[0].supportTypeID === watchedSupportType[0].id && (
								<>
									<Divider style={{ backgroundColor: "white" }} />
									<ZenSelect
										label="Sub Support Type"
										formName={`subSupportType-${index}`}
										formRef={control}
										clearable={false}
										options={subSupportTypeOptions}
										inputError={errors[`subSupportType-${index}`]}
										formRules={{
											validate: {
												required: (value: Value) => !onPostSession || (!!value && value.length > 0) || "Sub support type is required",
											},
										}}
									/>
									{getSubSupportTypes.error && <ErrorNotification messageOrPayload={getSubSupportTypes.payload} />}
								</>
							)}
						</>
					)}
			</div>
		</div>
	)
}
