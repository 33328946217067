import { GlobalState } from "little-state-machine"
import { CallLogState } from "../states/callLogCreate"

/**
 * Updates Call Log Create State (from little-state-machine)
 */
export const updateCallLogCreate = (prev: GlobalState, payload: Partial<CallLogState>) => {
	return {
		...prev,
		callLogCreate: {
			...prev.callLogCreate,
			...payload,
		},
	}
}
