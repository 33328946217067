import * as React from "react"
import { useStyletron } from "baseui"
import { StyleObject } from "styletron-react"

interface IOverrides {
	container?: StyleObject
	space?: StyleObject
}

interface IProps {
	children: (JSX.Element | boolean | undefined)[]
	overrides?: IOverrides
}

// Applies a right margin to child components. eg. Use to place a space between multiple button components.
export const Spaced = (props: IProps) => {
	const [css] = useStyletron()
	const containerOverrides: StyleObject | undefined = props.overrides ? props.overrides.container : undefined
	const container = css({
		display: "flex",
		alignItems: "center",
		...containerOverrides,
	})
	const spaceOverrides: StyleObject | undefined = props.overrides ? props.overrides.space : undefined
	const space = css({
		display: "flex",
		alignItems: "center",
		...spaceOverrides,
	})
	const marginRight = css({
		marginRight: "0.5rem",
	})

	const children = props.children.filter((c) => c !== undefined && c !== false) as JSX.Element[]

	return (
		<div className={container}>
			{children.map((element, index) => (
				<div key={"spaced-" + index} className={space + (index !== children.length - 1 ? ` ${marginRight}` : "")}>
					{element}
				</div>
			))}
		</div>
	)
}
