import { library as IconLibrary } from "@fortawesome/fontawesome-svg-core"
import {
	faBriefcase,
	faArrowRight,
	faBorderAll,
	faChevronDown,
	faChevronUp,
	faClock,
	faCog,
	faEdit,
	faEnvelope,
	faEnvelopeOpen,
	faFile,
	faFileAlt,
	faFileChartLine,
	faFolder,
	faHistory,
	faHome,
	faIdCard,
	faMapMarkedAlt,
	faMapMarkerAlt,
	faPhoneAlt,
	faPhoneSquare,
	faPlusCircle,
	faSearch,
	faSignOut,
	faTimes,
	faTimesCircle,
	faTrashAlt,
	faUpload,
	faUser,
	faUserCircle,
	faUsers,
	faQuestionCircle,
	faTrashRestoreAlt,
	faPencil,
	faExclamationCircle as faExclamationCircleLight,
	faPlus,
	faMinusOctagon,
	faCar,
	faBus,
	faDownload,
	faExclamationTriangle,
	faGlobeAsia,
	faSignIn,
	faSearchPlus,
	faCalendarAlt,
	faMobile,
	faRedo,
	faFileInvoiceDollar,
} from "@fortawesome/pro-light-svg-icons"
import {
	faArrowRight as fasArrowRight,
	faBars,
	faCircle as fasCircle,
	faCheckCircle as fasCheckCircle,
	faExclamationCircle,
	faLock as fasLock,
	faLockOpen as fasLockOpen,
	faTh,
	faTimesCircle as fasTimesCircle,
	faDollarSign,
	faCheck as fasCheck,
	faEye as fasEye,
	faExclamationTriangle as fasExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons"

export const loadIcons = () => {
	IconLibrary.add(
		faBriefcase,
		faCalendarAlt,
		faUser,
		faUserCircle,
		faEnvelope,
		faEnvelopeOpen,
		faChevronDown,
		faChevronUp,
		faMapMarkedAlt,
		faBorderAll,
		faPhoneSquare,
		faHistory,
		faSignOut,
		faTrashAlt,
		faEdit,
		faTimes,
		faUpload,
		faTimesCircle,
		faSearch,
		faPlusCircle,
		faPlus,
		faClock,
		faMapMarkerAlt,
		faUsers,
		faCog,
		faHome,
		faPhoneAlt,
		faFile,
		faFileAlt,
		faFolder,
		faFileChartLine,
		faQuestionCircle,
		faPencil,
		fasCheck,
		faArrowRight,
		faTrashRestoreAlt,
		faIdCard,
		faExclamationCircleLight,
		faMinusOctagon,
		faCar,
		faBus,
		fasEye,
		faDownload,
		faExclamationTriangle,
		faGlobeAsia,
		faSignIn,
		faSearchPlus,
		faMobile,
		faRedo,
		faFileInvoiceDollar,

		// solid icon
		fasCircle,
		fasCheckCircle,
		fasTimesCircle,
		fasArrowRight,
		faExclamationCircle,
		faBars,
		faTh,
		fasLock,
		fasLockOpen,
		faDollarSign,
		fasExclamationTriangle,
	)
}
