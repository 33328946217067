import { PreferredLocation } from "./commonForms/actionPlan"

// react-hook-form trigger doesn't work when the fields is in a different component,
// so need manual check all the form to show incomplete step on the progression bar

// Following validation methods are used in both Adult intake and Youth intake

// check referrer detail form
export const validateReferrerDetail = (currentState: any) => {
	if (!currentState.workerID || currentState.workerID.length === 0) return false
	return true
}

// check present of risk concerns form
export const validatePresenceOfRiskConcerns = (currentState: any) => {
	// generate checkList
	if (currentState.hasSuicidalIdeationSelfHarm && !currentState.suicidalIdeationSelfHarm) return false
	if (currentState.hasFinancialHardship && !currentState.financialHardship) return false
	if (currentState.hasUnstableHousing && !currentState.unstableHousing) return false
	if (currentState.hasViolenceAbuse && !currentState.violenceAbuse) return false
	if (currentState.hasAODIssue && !currentState.aodIssue) return false

	return true
}

// check challenge for client form
export const validateChallengeForClient = (currentState: any) => {
	// generate checkList
	if (currentState.hasCognitiveDifficulties && !currentState.learnCognitiveDifficulties) return false
	if (currentState.hasMentalHealthConcerns && !currentState.mentalHealthConcerns) return false
	if (currentState.hasPhysicalDifficulties && !currentState.physicalDifficulties) return false
	if (currentState.hasAccessibilityRequirements && !currentState.accessibilityRequirements) return false
	if (currentState.hasRiskOfFalls && !currentState.riskOfFalls) return false
	if (currentState.hasAllergies && !currentState.allergies) return false
	if (currentState.hasMedicalHistory && !currentState.medicalHistory) return false
	if (currentState.requirePalliativeSupport && !currentState.palliativeSupportCare) return false

	return true
}

// check client context form
export const validateClientContext = (currentState: any) => {
	if (currentState.receivingFormalSupports && !currentState.formalSupportDetail) return false
	return true
}

// check action plan form
export const validateActionPlan = (currentState: any) => {
	if (currentState.preferredLocationOption && currentState.preferredLocationOption.length > 0) {
		switch (currentState.preferredLocationOption[0].id) {
			case PreferredLocation.Office:
				if (!currentState.office) return false
				break
			case PreferredLocation.School:
			case PreferredLocation.OtherLocation:
				if (!currentState.preferredLocation) return false
				break
		}
	}

	return true
}
