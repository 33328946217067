import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import { useQuery } from "react-fetching-library"
import { ZenCard } from "../../components/common"
import { fetching } from "../../fetching"

export const ChangeLogs = () => {
	const changeLogs = useQuery(fetching.query.changeLogGet())

	const [css] = useStyletron()
	const card = css({
		height: "100%",
	})
	const ScrollDiv = css({
		height: "100%",
		overflow: "auto",
		maxHeight: "100%",
	})
	const version = css({
		display: "flex",
		width: "100%",
		flexDirection: "column",
		paddingBottom: "12px",
	})

	return (
		<ZenCard className={card}>
			<LabelLarge marginBottom="20px">Change Logs</LabelLarge>
			<div className={ScrollDiv}>
				{changeLogs.payload &&
					changeLogs.payload.map((changeLog, i) => (
						<div key={i} className={version}>
							<LabelMedium marginBottom="2px">{changeLog.version}</LabelMedium>
							{changeLog.logs.length > 0 &&
								changeLog.logs.map((log, i) => (
									<LabelSmall key={i} marginLeft="16px">
										{log}
									</LabelSmall>
								))}
						</div>
					))}
			</div>
		</ZenCard>
	)
}
