import * as React from "react"
import moment from "moment-timezone"
import { ClientDetail, TimeFormat, RangeOption } from "../../../../types/types"
import { useStyletron } from "baseui"
import { LabelMedium } from "baseui/typography"
import { TravelTable } from "../../../travels/travelTable"
import { Value, Select, OnChangeParams } from "baseui/select"
import { ZenButton } from "../../../zenComponents/zenButtons"
import { ZenCard } from "../../../common"
import { useHistory } from "react-router-dom"
import { routes } from "../../../../routes"
import { AuthContainer } from "../../../../controllers/auth"

export const ClientTravel = (props: ClientDetail) => {
	const { id, firstName, lastName } = props
	const { currentUser } = AuthContainer.useContainer()
	const [dateOption, setDateOption] = React.useState<Value>([{ label: RangeOption.thisWeek, id: RangeOption.thisWeek }])
	const [startTime, setStartTime] = React.useState<Date>(moment().startOf("week").toDate())
	const [endTime, setEndTime] = React.useState<Date>(moment().endOf("week").toDate())
	const [hasResult, setHasResult] = React.useState<boolean>(false)
	const history = useHistory()

	const selectDateOption = (params: OnChangeParams) => {
		const value = params.value
		switch (value[0].id) {
			case RangeOption.thisWeek:
				setStartTime(moment().startOf("week").toDate())
				setEndTime(moment().endOf("week").toDate())
				break
			case RangeOption.thisMonth:
				setStartTime(moment().startOf("month").toDate())
				setEndTime(moment().endOf("month").toDate())
				break
		}
		setDateOption(params.value)
	}

	const exportTravel = () => {
		fetch(
			`/api/files/client/travel_log?clientID=${id}&start=${moment(startTime).format(TimeFormat)}&end=${moment(endTime).format(
				TimeFormat,
			)}&timezoneOffsetMinutes=${startTime.getTimezoneOffset()}`,
		)
			.then((response) => {
				response.blob().then((blob) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement("a")
					a.href = url
					a.download = `${firstName}_${lastName}_${moment(startTime).format("DD-MM-YYYY")}_to_${moment(endTime).format("DD-MM-YYYY")}_travel_log.csv`
					a.click()
				})
			})
			.catch((e) => {
				console.error(e)
			})
	}

	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "15px",
		alignItems: "center",
	})
	const functionBar = css({
		display: "flex",
		alignItems: "center",
	})

	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelMedium>Travels</LabelMedium>
				<div className={functionBar}>
					<ZenButton
						onClick={() => {
							if (currentUser?.department.name === "Individualised Services") {
								history.push(routes.travels.createConfirm)
								return
							}
							history.push(`${routes.travels.create}?client_id=${id}&client_name=${firstName || ""}_${lastName || ""}`)
						}}
						marginRight="12px"
					>
						New Travel
					</ZenButton>
					<ZenButton onClick={exportTravel} marginRight="12px" disabled={!hasResult}>
						Export
					</ZenButton>
					<Select
						options={Object.values(RangeOption).map((v) => ({
							label: v,
							id: v,
						}))}
						value={dateOption}
						clearable={false}
						onChange={selectDateOption}
						overrides={{
							Root: {
								style: {
									width: "150px",
									height: "fit-content",
									boxShadow: "1px 0px 8px 1px rgba(0,0,0,0.2)",
									marginRight: "15px",
								},
							},
							InputContainer: {
								style: {
									width: "fit-content",
									height: "fit-content",
								},
							},
							ValueContainer: {
								style: {
									paddingTop: 0,
									paddingBottom: 0,
								},
							},
						}}
					/>
				</div>
			</div>
			<TravelTable hideTopbar clientID={id} canToggleView={false} startTime={startTime} endTime={endTime} setHasResult={setHasResult} />
		</ZenCard>
	)
}
