import * as React from "react"
import moment from "moment-timezone"
import { Calendar, Event, momentLocalizer } from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { ZenCard } from "./common"
import { useStyletron } from "baseui"
import { useParameterizedQuery } from "react-fetching-library"
import { Session } from "../types/types"
import { Link, useHistory } from "react-router-dom"
import { fetching } from "../fetching"
import { ErrorNotification } from "./errorBox"
import { Button } from "baseui/button"
import { SessionSearchFilterInput } from "../fetching/inputType"
import { PortalContainer } from "../controllers/portal"
import { LabelSmall } from "baseui/typography"

interface EventCalendarProps {
	clientID?: string
	workerID?: string
	defaultView?: "month" | "week" | "work_week" | "day" | "agenda" | undefined
	hideBorder?: boolean
}

const EventCalendar = (props: EventCalendarProps) => {
	const { clientID, workerID, defaultView, hideBorder } = props
	const [events, setEvents] = React.useState<Event[]>([])
	const { timezone } = PortalContainer.useContainer()

	const history = useHistory()

	const { payload, loading, error, query } = useParameterizedQuery<{ sessions: Session[]; total: number }>(fetching.query.getSessionMany)
	const [startTime, setStartTime] = React.useState<Date>()
	const [endTime, setEndTime] = React.useState<Date>()
	const [view, setView] = React.useState<"month" | "week" | "work_week" | "day" | "agenda" | undefined>(defaultView)
	React.useEffect(() => {
		let input: SessionSearchFilterInput = {
			search: {
				workerID,
				clientID,
				sortBy: "EndTime",
			},
			limit: 100,
			offset: 0,
			isCancelled: false,
		}
		if (startTime && endTime) {
			input.startTime = startTime
			input.endTime = endTime
		} else {
			switch (view) {
				case "month":
					input.startTime = moment().startOf("month").toDate()
					input.endTime = moment().endOf("month").toDate()
					break
				case "agenda":
					input.startTime = moment().startOf("day").toDate()
					input.endTime = moment().add(1, "month").endOf("day").toDate()
					break
				case "week":
					input.startTime = moment().startOf("week").toDate()
					input.endTime = moment().endOf("week").toDate()
					break
				case "day":
					input.startTime = moment().startOf("day").toDate()
					input.endTime = moment().endOf("day").toDate()
					break
			}
		}
		query(input)
	}, [clientID, workerID, defaultView, startTime, endTime, view, query])

	const localizer = momentLocalizer(moment.tz.setDefault(timezone.id))
	React.useEffect(() => {
		if (loading || !payload) return
		setEvents(
			payload.sessions
				.sort((a, b) => {
					if (a.startTime >= b.startTime) return 1
					return -1
				})
				.map((session) => {
					let personName = ""
					if (workerID) {
						// Worker's calendar - show client name
						if (session.clients?.length === 1) {
							if (session.clients[0].firstName || session.clients[0].lastName) {
								// Client exists
								const firstName = session.clients[0].firstName || ""
								const lastName = session.clients[0].lastName?.substr(0, 1) || ""
								personName = `w/ ${firstName} ${lastName}`
							} else {
								// No client; Non-billable session
								personName = `(Non-billable)`
							}
						} else {
							personName =
								`w/` +
								(session.clients
									? session.clients
											.slice(3)
											.map((c, index) => {
												if (index === 3) return `...`
												return `${c.firstName} ${c.lastName},`
											})
											.join(" ")
									: "Multi-client")
						}
					} else {
						// Client calendar - show worker name
						personName = session.worker ? `w/ ${session.worker.firstName} ${session.worker.lastName.substr(0, 1)}` : session.meetingType
					}
					return {
						title: `Appt ${personName}`,
						start: view === "agenda" ? moment(new Date(session.startTime).setHours(0, 0, 0, 0)).toDate() : moment(session.startTime).toDate(),
						end: view === "agenda" ? moment(new Date(session.startTime).setHours(23, 59, 59)).toDate() : moment(session.endTime).toDate(),
						resource: {
							url: `/portal/sessions/${session.identifier}`,
							attendanceStatus: session.attendanceStatus,
							clients: session.clients,
							startTime: moment(session.startTime).tz(timezone.id).format(),
							endTime: moment(session.endTime).tz(timezone.id).format(),
						},
					} as Event
				}),
		)
	}, [payload, view, loading, workerID, timezone.id])

	// Styling
	const [css] = useStyletron()
	const cardStyle = css({
		padding: "unset !important",
		maxWidth: "unset !important",
		boxShadow: hideBorder ? "none" : "",
		height: "100%",
	})
	const calendarStyle = css({
		zIndex: 0,
	})
	const titleStyle = css({
		marginBottom: "10px",
	})
	const agendaTime = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	})

	return (
		<ZenCard className={cardStyle}>
			<Calendar
				localizer={localizer}
				culture={"en-AU"}
				events={events}
				startAccessor="start"
				endAccessor="end"
				resourceAccessor="resource"
				className={calendarStyle}
				onSelectEvent={(event) => history.push(event.resource.url)}
				defaultView={defaultView || "month"}
				showAllEvents
				formats={{
					dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
						if (!localizer || !culture) return ""
						if (start.getFullYear() !== end.getFullYear())
							return localizer.format(start, "D MMMM, YYYY", culture) + " - " + localizer.format(end, "D MMMM, YYYY", culture)
						else if (start.getMonth() !== end.getMonth())
							return localizer.format(start, "D MMMM", culture) + " - " + localizer.format(end, "D MMMM, YYYY", culture)
						return localizer.format(start, "D", culture) + " - " + localizer.format(end, "D MMMM, YYYY", culture)
					},
					dayHeaderFormat: (date, culture, localizer) => {
						if (!localizer || !culture) return ""
						return localizer.format(date, "MMMM D, YYYY", culture)
					},
					agendaHeaderFormat: ({ start, end }, culture, localizer) => {
						if (!localizer || !culture) return ""
						return localizer.format(start, "DD/MM/YYYY", culture) + " - " + localizer.format(end, "DD/MM/YYYY", culture)
					},
				}}
				onRangeChange={(d) => {
					if (Array.isArray(d)) {
						setStartTime(moment(d[0]).startOf("day").toDate())
						setEndTime(
							moment(d[d.length - 1])
								.endOf("day")
								.toDate(),
						)
						return
					}
					setStartTime(moment(d.start).startOf("day").toDate())
					setEndTime(moment(d.end).endOf("day").toDate())
				}}
				onView={setView}
				components={{
					event: ({ event }) => {
						const eventStyle = css({})
						const statusStyle = css({
							marginBottom: "10px",
						})
						const spacer = css({
							display: "block",
							height: "5px",
						})

						return (
							<div className={eventStyle}>
								{event.resource.attendanceStatus !== "Unknown status" && (
									<>
										<em className={statusStyle}>{event.resource.attendanceStatus}</em>
										<div className={spacer} />
									</>
								)}
								<div>{event.title}</div>
								<div>{`${moment(event.resource.startTime).tz(timezone.id).format("hh:mm A")} - ${moment(event.resource.endTime)
									.tz(timezone.id)
									.format("hh:mm A")}`}</div>
							</div>
						)
					},
					agenda: {
						time: (a: any) => {
							return (
								<div className={agendaTime}>
									<LabelSmall>{`${moment(a.event.resource.startTime).tz(timezone.id).format("hh:mm a")} - ${moment(a.event.resource.endTime)
										.tz(timezone.id)
										.format("hh:mm a")}`}</LabelSmall>
								</div>
							)
						},
						event: ({ event }) => {
							return (
								<span>
									{event.resource.attendanceStatus !== "Unknown status" && <em>{event.resource.attendanceStatus}</em>}
									<div className={titleStyle}>{event.title}</div>
									<Link to={event.resource.url}>
										<Button size="compact">View Session</Button>
									</Link>
								</span>
							)
						},
					},
				}}
			/>
			{error && <ErrorNotification messageOrPayload={payload} />}
		</ZenCard>
	)
}

export default EventCalendar
