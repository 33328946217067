import * as React from "react"
import { useStyletron } from "baseui"
import { Option, Value } from "baseui/select"
import { LabelLarge, LabelSmall, ParagraphSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useParameterizedQuery, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import { AuthContainer } from "../../../controllers/auth"
import { fetching } from "../../../fetching"
import { useCheckWorkerAvailability } from "../../../helpers/useCheckWorkerAvailability"
import { snakeToTitle } from "../../../helpers/utils"
import { routes } from "../../../routes"
import { ZenTheme } from "../../../themeOverrides"
import { Client, ClientWithContact, RolePermission } from "../../../types/types"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { AvailableCheck, Divider } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { ZenButton, ZenButtonGroup } from "../../zenComponents/zenButtons"
import { ZenCheckbox } from "../../zenComponents/zenCheckboxList"
import { ZenInput } from "../../zenComponents/zenInput"
import { ZenTimezoneSelect, ZenClientSelect, ZenPlaceSelect, ZenSelect, ZenUserSelect } from "../../zenComponents/zenSelectBox"
import { ZenDatePicker, ZenTimePicker } from "../../zenComponents/zenTime"
import { SessionFormData, SessionFundingSourceInput, SingleClientSessionInput } from "./singleSessionBaseForm"
import { SessionFundingSourceForm } from "../SessionFundingSource"
import { ErrorFieldTracker } from "../../forms/errorFieldTracker"
import { SessionClientType } from "../../../types/enums"

export enum SessionMeetingType {
	InPerson = "IN_PERSON",
	Online = "ONLINE",
	Telephone = "TELEPHONE",
	ServiceProviderOther = "SERVICE_PROVIDER_OTHER",
}

enum errorMsgSkipForceCreate {
	timeDisorder = "Start time must be before end time",
	timeFrameError = "Times must be in between 6:00AM to 10:00PM",
}

export const NewClientSessionGeneral = (props: SessionFormData) => {
	const { data, setData, pageChange, setIsDirty } = props

	// use history
	const history = useHistory()
	const searchArgs = React.useMemo(() => {
		return new URLSearchParams(history.location.search)
	}, [history.location.search])

	// auth
	const { hasPermission } = AuthContainer.useContainer()

	// excluded IDs
	const [excludedUserID, setExcludedUserID] = React.useState<string[]>([])
	const [selectedClient, setSelectedClient] = React.useState<ClientWithContact>()

	// hook form
	const { control, handleSubmit, watch, getValues, setValue, errors, clearErrors, formState } = useForm()

	// force create state
	const [forceCreate, setForceCreate] = React.useState(false)

	// Prepare form data for multiple funding sources
	const [placeholder, setPlaceholder] = React.useState<number[]>([0]) // use for tracking the index of session funding source
	const [availableIndexes, setAvailableIndexes] = React.useState<number[]>([2, 1]) // additional index available for new funding sources
	const addMoreFundingSource = () => {
		let list = [...availableIndexes]
		const index = list.pop()

		// if the list is empty, then skip
		if (!index) return

		// store remain indexes
		setAvailableIndexes(list)

		// append index to placeholder list
		setPlaceholder((p) => p.concat(index))
	}

	// set default form values
	const loadDefaults = React.useCallback(() => {
		setValue("worker", data.worker || [])
		setValue("client", data.client || [])

		setValue("appointmentDate", moment(data.appointmentDate).toDate() || new Date())
		setValue("sessionLocation", data.sessionLocation || [])
		if (data.meetingTypeKey === SessionMeetingType.Telephone && data.client && data.client.length > 0) {
			setValue("otherMeetingMethod", data.client[0].currentContact?.mobileNumber || data.client[0].currentContact?.telephoneNumber || "")
		} else setValue("otherMeetingMethod", data.otherMeetingMethod || "")

		if (data.clientType) {
			setValue("clientType", data.clientType)
		}
		if (data.startTime) {
			setValue("startTime", data.startTime)
		}
		if (data.endTime) {
			setValue("endTime", data.endTime)
		}
		if (data.meetingTypeKey) {
			setCurrentMeetingTypeKey(data.meetingTypeKey)
		}
		setValue("office", data.office ? data.office : [{ id: "other", label: "Other" }])

		if (data.sessionFundingSources && data.sessionFundingSources.length > 0) {
			setPlaceholder(data.sessionFundingSources.map((_, i) => i))
			data.sessionFundingSources.forEach((sfs, i) => {
				setAvailableIndexes((ais) => ais.filter((ai) => ai !== i))
				setValue(`fundingSource-${i}`, sfs.fundingSource)
				setValue(`contractArea-${i}`, sfs.contractArea || [])
				setValue(`supportType-${i}`, sfs.supportType || [])
				setValue(`subSupportType-${i}`, sfs.subSupportType || [])
			})
		}
		setForceCreate(!!data.forceCreate)
	}, [data, setValue])
	React.useEffect(() => {
		loadDefaults()
	}, [loadDefaults])

	// watches start/end time and date inputs
	const watchDate = watch("appointmentDate")
	const watchStartTime = watch("startTime")
	const watchEndTime = watch("endTime")
	const watchTimezone = watch("timezone")
	const watchWorker = watch("worker")
	const watchMentor = watch("buddySessionMentor")
	const clients: Client[] = watch("client")
	const buddySessionWithMentor = watch("buddySessionWithMentor")

	// check worker availability query
	const workerAvailability = useCheckWorkerAvailability({ timezone: watchTimezone })

	const office: Value = watch("office")

	React.useEffect(() => {
		if (setIsDirty) setIsDirty(true)
	}, [setIsDirty, formState.isDirty, watchDate, watchStartTime, watchEndTime, watchWorker, clients])

	// calls handleCheckAvailability on date/time change
	React.useEffect(() => {
		handleCheckAvailability()
	}, [watchDate, watchStartTime, watchEndTime, watchWorker, watchMentor, watchTimezone]) // eslint-disable-line react-hooks/exhaustive-deps

	// handles worker availability check
	const handleCheckAvailability = async () => {
		// form inputs
		const input = getValues()

		// null checks
		if (!input.worker || !input.worker.length || !input.startTime || !input.endTime || !watchTimezone || !watchTimezone.length) {
			if (workerAvailability.state) workerAvailability.resetState()
			return
		}

		const wIDs = [input.worker[0].id as string]
		if (input.buddySessionMentor && input.buddySessionMentor[0]) {
			wIDs.push(input.buddySessionMentor[0].id as string)
		}

		// check available
		workerAvailability.check({
			workerIDs: wIDs,
			timezone: watchTimezone[0],
			startTime: input.startTime,
			endTime: input.endTime,
			billableSession: true,
		})
	}

	const displayTimeCheckErrorMsg = () => {
		if (!workerAvailability.state) return null

		// display available message
		if (
			workerAvailability.state.available ||
			workerAvailability.state.message === errorMsgSkipForceCreate.timeDisorder ||
			workerAvailability.state.message === errorMsgSkipForceCreate.timeFrameError
		) {
			return <AvailableCheck isAvailable={workerAvailability.state} />
		}

		return (
			<AvailableCheck
				isAvailable={workerAvailability.state}
				isForceCreate={forceCreate}
				SetIsForceCreate={setForceCreate}
				question="I am trying to book a DNA or cancelled session"
				confirmMsg="You have confirmed to book this overlapped session"
			/>
		)
	}

	// handles client input change
	const onClientChange = (c: Value) => {
		if (c.length > 0 && c[0].id) {
			setSelectedClient(c[0] as Client)
			if (c[0].currentContact?.residentialAddress)
				setValue("sessionLocation", [{ id: c[0].currentContact?.residentialAddress.placeID, label: c[0].currentContact?.residentialAddress.fullAddress }])
			if (currentMeetingTypeKey === SessionMeetingType.Telephone)
				setValue("otherMeetingMethod", c[0].currentContact?.mobileNumber || c[0].currentContact?.telephoneNumber || "")
			clearErrors("sessionLocation")
			return
		}
		setSelectedClient(undefined)
	}

	// handle button group select on change
	const [currentMeetingTypeKey, setCurrentMeetingTypeKey] = React.useState<string>(Object.values(SessionMeetingType)[0])
	const handleSessionTypeChange = (id: string) => {
		setCurrentMeetingTypeKey(id)
		if (selectedClient) {
			if (id === SessionMeetingType.Telephone)
				setValue("otherMeetingMethod", selectedClient.currentContact?.mobileNumber || selectedClient.currentContact?.telephoneNumber || "")
		}
	}

	// go back
	const goBack = () => {
		if (pageChange) {
			pageChange(-1)
			return
		}

		// return back to client session tab, if client id exists
		const returnClientID = searchArgs.get("client_id")
		if (returnClientID) {
			history.push({
				pathname: routes.withID(returnClientID, routes.clients.client.root),
				hash: "sessions",
			})
			return
		}

		// otherwise redirect to session list
		history.push({
			pathname: routes.sessions.root,
			search: searchArgs.toString(),
		})
	}

	// submit form
	const onSubmit = (formData: any) => {
		if (!forceCreate && (!workerAvailability.available || NDISErrorMessage.length > 0 || NDISGroupSessionError.length > 0)) return
		saveData(formData)
		if (pageChange) {
			pageChange(1)
			return
		}

		// push to appointments page
		history.push({
			pathname: routes.sessions.create.single.note,
			search: searchArgs.toString(),
		})
	}

	const saveData = (formData: any) => {
		const includeClientType = baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label !== "NDIA"

		// prepare input
		const input: SingleClientSessionInput = {
			...data,
			...formData,
			forceCreate,
			meetingTypeKey: currentMeetingTypeKey,
			office: undefined,
			sessionLocation: data.otherMeetingMethod,
			buddySessionWithMentor: formData.buddySessionWithMentor,
			buddySessionMentorID: formData.buddySessionMentor && formData.buddySessionMentor.length > 0 ? formData.buddySessionMentor[0].id : undefined,
			clientType: includeClientType ? formData.clientType : [],
		}

		// parse session funding source
		let sessionFundingSources: SessionFundingSourceInput[] = []
		placeholder
			.sort((a: number, b: number) => a - b)
			.forEach((p) => {
				sessionFundingSources.push({
					fundingSource: getValues(`fundingSource-${p}`) || [],
					supportType: getValues(`supportType-${p}`) || [],
					contractArea: getValues(`contractArea-${p}`) || [],
					subSupportType: getValues(`subSupportType-${p}`) || [],
				})
			})
		input.sessionFundingSources = sessionFundingSources

		// set office value only if funding source is not NDIA
		if (!baseFundingSource || baseFundingSource.length === 0 || baseFundingSource[0].label !== "NDIA") input.office = formData.office

		// set session location only if
		// funding source is "NDIA" OR
		// office is "other"
		if (
			(baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label === "NDIA") ||
			(office && office.length > 0 && office[0].id === "other")
		) {
			input.sessionLocation = formData.sessionLocation
		}

		// sets data for multipart form
		setData(input)
	}

	// adjust start/end time on data change
	const handleDateOnChange = (date: Date) => {
		const { startTime, endTime } = getValues(["startTime", "endTime"])
		setForceCreate(false)
		setValue(
			"startTime",
			moment(startTime ?? date.setMinutes(0))
				.set({
					year: date.getFullYear(),
					month: date.getMonth(),
					date: date.getDate(),
				})
				.toDate(),
		)

		setValue(
			"endTime",
			moment(endTime ?? date.setMinutes(15))
				.set({
					year: date.getFullYear(),
					month: date.getMonth(),
					date: date.getDate(),
				})
				.toDate(),
		)
	}

	// the first funding source
	const baseFundingSource = watch("fundingSource-0")

	React.useEffect(() => {
		// if the base funding source is "NDIA", clean up all the additional funding sources
		if (baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label === "NDIA") {
			// clean up selected data
			placeholder.forEach((p) => {
				// skip base funding source
				if (p === 0) return

				// clean up the rest
				setValue(`fundingSource-${p}`, [])
				setValue(`contractArea-${p}`, [])
				setValue(`supportType-${p}`, [])
				setValue(`subSupportType-${p}`, [])
			})

			setPlaceholder([0])
			setAvailableIndexes([2, 1])
		}
	}, [baseFundingSource, setValue, setPlaceholder, setAvailableIndexes]) // eslint-disable-line react-hooks/exhaustive-deps

	const [NDISErrorMessage, setNDISErrorMessage] = React.useState<string[]>([])
	const [NDISGroupSessionError, setNDISGroupSessionError] = React.useState<string[]>([])

	// get client's NDIS plan
	const checkClientGroupLineItem = useParameterizedQuery(fetching.query.checkClientGroupLineItem)
	React.useEffect(() => {
		setNDISErrorMessage([])
		setNDISGroupSessionError([])
		if (!clients || clients.length === 0 || !baseFundingSource || baseFundingSource.length === 0 || baseFundingSource[0].label !== "NDIA") return

		if (!watchDate || !watchStartTime || !watchEndTime) {
			setNDISErrorMessage((msg) => msg.concat("Session date and time is not set"))
			return
		}

		checkClientGroupLineItem.query({ clientIDList: clients.map((c) => c.id), bookingDate: watchDate }).then((resp) => {
			if (resp.error || !resp.payload) return
			setNDISGroupSessionError(resp.payload)
		})
	}, [clients, baseFundingSource, watchStartTime, watchEndTime, watchDate]) // eslint-disable-line react-hooks/exhaustive-deps

	// get HelpingMinds offices
	const [officeOptions, setOfficeOptions] = React.useState<Option[]>([])
	const officeAll = useQuery(fetching.query.getOfficeAll())
	React.useEffect(() => {
		if (officeAll.loading || officeAll.error || !officeAll.payload) return
		setOfficeOptions(officeAll.payload.map((o) => ({ id: o.id, label: o.name })).concat({ id: "other", label: "Other" }))
	}, [officeAll.payload, officeAll.loading, officeAll.error])

	// display location
	const displayLocationOption = () => {
		switch (currentMeetingTypeKey) {
			case SessionMeetingType.InPerson:
				return (
					<>
						{!baseFundingSource ||
							baseFundingSource.length === 0 ||
							(baseFundingSource[0].label !== "NDIA" && (
								<ZenSelect
									label="Offices"
									formName="office"
									formRef={control}
									clearable={false}
									options={officeOptions}
									inputError={errors.office}
									formRules={{
										validate: {
											required: (v: Value) => (!!v && v.length > 0) || "Office is required",
										},
									}}
								/>
							))}
						{((baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label === "NDIA") ||
							(office && office.length > 0 && office[0].id === "other")) && (
							<ZenPlaceSelect
								label="Location"
								formName="sessionLocation"
								formRef={control}
								placeholder="Location"
								inputError={errors.sessionLocation}
								formRules={{
									validate: {
										required: (value: Value) => (!!value && value.length > 0 && value[0].id !== "") || "Location is required",
									},
								}}
							/>
						)}
					</>
				)
			case SessionMeetingType.Online:
				return (
					<ZenInput
						label={"Additional Info"}
						formRef={control}
						nameRef="otherMeetingMethod"
						inputError={errors.otherMeetingMethod}
						placeholder="Enter session URL or location details for online session"
					/>
				)
			case SessionMeetingType.Telephone:
				return (
					<ZenInput
						// Use generic input to allow for more flexible usage, such as a note about the number.
						required // A phone meeting requires a number, or note
						label={"Phone Number"}
						formRef={control}
						nameRef="otherMeetingMethod"
						inputError={errors.otherMeetingMethod}
					/>
				)
			case SessionMeetingType.ServiceProviderOther:
				return (
					<ZenInput
						// Use generic input to allow for more flexible usage, such as a note about the number.
						required // A phone meeting requires a number, or note
						label={"Other"}
						formRef={control}
						nameRef="otherMeetingMethod"
						inputError={errors.otherMeetingMethod}
					/>
				)
		}
		return null
	}

	// styling component
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		maxWidth: "600px",
		height: "100%",
	})
	const rangeTimeStyle = css({
		marginTop: "10px",
		marginBottom: "10px",
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	})
	const timerStyle = css({
		width: "49%",
	})
	const body = css({
		height: "100%",
		minHeight: 0,
		display: "flex",
		flexDirection: "column",
	})
	const scrollingDiv = css({
		overflowY: "auto",
		height: "100%",
		maxHeight: "100%",
		minHeight: 0,
		paddingRight: "8px",
	})
	const dobContainerStyle = css({
		marginBottom: "15px",
	})
	const dobCardContainerStyle = css({
		display: "flex",
		borderRadius: "5px",
		padding: "15px",
		color: "black",
		backgroundColor: ZenTheme.colors.lightGrey,
		marginBottom: "5px",
	})

	return (
		<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
			<LabelLarge>General</LabelLarge>
			<div className={body}>
				<div className={scrollingDiv}>
					<ZenUserSelect
						label="Worker"
						formName="worker"
						formRef={control}
						inputError={errors.worker}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
							},
						}}
						disabled={!hasPermission(RolePermission.SessionUpdate)}
						actionOnChange={(v) => {
							// check null value
							if (!v || v.length === 0 || !v[0].id) {
								setExcludedUserID([])
								return
							}
							setExcludedUserID([v[0].id.toString()])
							setValue("worker", v)
						}}
						excludedID={
							watch("buddySessionMentor") && watch("buddySessionMentor").length > 0 && watch("buddySessionMentor")[0]?.id
								? [watch("buddySessionMentor")[0]?.id, ...excludedUserID]
								: []
						}
					/>

					<ZenDatePicker
						label="Appointment date"
						formName="appointmentDate"
						formRef={control}
						inputError={errors.appointmentDate}
						actionOnChange={handleDateOnChange}
					/>

					<ZenTimezoneSelect
						formRef={control}
						formName="timezone"
						label="Timezone"
						clearable={false}
						timezone={data.timezone}
						actionOnChange={(v) => {
							const offsetDifferent = watchTimezone[0].offsetMinutes - v[0].offsetMinutes
							if (watchStartTime) setValue("startTime", moment(watchStartTime).add(offsetDifferent, "minute"))
							if (watchEndTime) setValue("endTime", moment(watchEndTime).add(offsetDifferent, "minute"))
						}}
					/>

					<div className={rangeTimeStyle}>
						<div className={timerStyle}>
							<ZenTimePicker
								inputError={errors.startTime}
								label="Start time"
								formName="startTime"
								creatable
								nullable
								date={watch("appointmentDate")}
								minuteStep={1}
								formRef={control}
								actionOnChange={() => setForceCreate(false)}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "Start time is required"
											}
											if (watchEndTime && moment(value).isSameOrAfter(watchEndTime)) {
												return "Start time must be before end time"
											}
											return null
										},
									},
								}}
							/>
						</div>
						<div className={timerStyle}>
							<ZenTimePicker
								inputError={errors.endTime}
								label="End time"
								formName="endTime"
								creatable
								nullable
								date={watch("appointmentDate")}
								minuteStep={1}
								formRef={control}
								actionOnChange={() => setForceCreate(false)}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "End time is required"
											}
											if (watchStartTime && moment(value).isSameOrBefore(watchStartTime)) {
												return "End time must be after start time"
											}
											return null
										},
									},
								}}
							/>
						</div>
					</div>
					{displayTimeCheckErrorMsg()}
					<ZenClientSelect
						label="Client(s)"
						formName="client"
						formRef={control}
						inputError={errors.client}
						disabled={!!pageChange || !!searchArgs.get("client_id")}
						multi
						ndisOnly={baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label === "NDIA"}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Client is required",
							},
						}}
						excludedID={selectedClient ? [selectedClient.id] : []}
						actionOnChange={onClientChange}
					/>

					{clients && clients.length > 0 && (
						<div className={dobContainerStyle}>
							<LabelSmall marginBottom={"5px"}>Date of birth</LabelSmall>
							{clients.map((c: Client) => (
								<div key={`dob-${c.id}`} className={dobCardContainerStyle}>
									<LabelSmall>{`${c.firstName} ${c.lastName}`}</LabelSmall>
									<ParagraphSmall flex={1} margin={"0 15px"}>
										{moment(c.dateOfBirth).format("DD/MM/YYYY")}
									</ParagraphSmall>
								</div>
							))}
						</div>
					)}

					{officeAll.error && <ErrorNotification messageOrPayload={officeAll.payload} />}
					{NDISErrorMessage.length > 0 && NDISErrorMessage.map((errMsg, i) => <ErrorNotification key={i} messageOrPayload={errMsg} />)}
					{NDISGroupSessionError.length > 0 && NDISGroupSessionError.map((errMsg, i) => <ErrorNotification key={i} messageOrPayload={errMsg} />)}

					<SessionFundingSourceForm
						control={control}
						involvedClient={clients}
						errors={errors}
						placeholder={placeholder}
						watch={watch}
						setValue={setValue}
						setAvailableIndexes={setAvailableIndexes}
						setPlaceholder={setPlaceholder}
						sessionFundingSources={data.sessionFundingSources?.map((sfs) => ({
							fundingSource: sfs.fundingSource[0],
							contractArea: sfs.contractArea && sfs.contractArea.length > 0 ? sfs.contractArea[0] : undefined,
							supportType: sfs.supportType && sfs.supportType.length > 0 ? sfs.supportType[0] : undefined,
							subSupportType: sfs.subSupportType && sfs.subSupportType.length > 0 ? sfs.subSupportType[0] : undefined,
						}))}
					/>
					{baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label !== "NDIA" && availableIndexes.length > 0 && (
						<>
							<Divider style={{ backgroundColor: "white" }} />
							<ZenButton type="button" onClick={addMoreFundingSource}>
								Add New Funding Source
							</ZenButton>
							<Divider style={{ backgroundColor: "white" }} />
							<ZenSelect
								label="Client Type"
								formName="clientType"
								options={Object.entries(SessionClientType).map((sct) => ({ id: sct[1], label: snakeToTitle(sct[0]) }))}
								formRef={control}
							/>
						</>
					)}

					{/* Buddy sessions */}
					{baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label === "NDIA" && (
						<>
							<ZenCheckbox label="Buddy Session?" marginTop="5px" labelPlacement="left" formName="buddySessionWithMentor" formRef={control} />
							{buddySessionWithMentor && (
								<ZenUserSelect
									label="Mentor"
									formName="buddySessionMentor"
									inputError={errors.buddySessionMentor}
									excludedID={watch("worker") && watch("worker").length > 0 && watch("worker")[0]?.id ? [watch("worker")[0]?.id, ...excludedUserID] : []}
									formRef={control}
									formRules={{
										validate: {
											required: (value: Value) => (!!value && value.length > 0) || "Buddy Session Mentor is required",
										},
									}}
								/>
							)}
						</>
					)}
					<Divider style={{ backgroundColor: "white" }} />
					<ZenButtonGroup
						label="Meeting Type"
						data={Object.values(SessionMeetingType).map((s) => {
							let label = snakeToTitle(s)
							if (s === SessionMeetingType.ServiceProviderOther) label = "Service Provider / Other"
							return { id: s, label }
						})}
						currentKey={currentMeetingTypeKey}
						actionOnSelect={handleSessionTypeChange}
					/>

					{displayLocationOption()}
				</div>

				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				<CancelAndSaveButtons cancelLabel="Exit" cancelFn={goBack} saveLabel="Next" />
			</div>
		</form>
	)
}
