import * as React from "react"
import { BlockProps } from "baseui/block"
import { IconName } from "@fortawesome/pro-light-svg-icons"
import { useStyletron } from "styletron-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ZenTheme } from "../../themeOverrides"

export interface SubInfoProps extends BlockProps {
	icon: IconName
}
export const SubInfo = (props: SubInfoProps) => {
	const { icon, children } = props
	const [css] = useStyletron()
	const container = css({
		marginTop: "15px",
		display: "flex",
		alignItems: "center",
	})
	const iconContainer = css({
		width: "20px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	})
	return (
		<div className={container}>
			<div className={iconContainer}>
				<FontAwesomeIcon color={ZenTheme.colors.primaryGreen} icon={["fal", icon]} />
			</div>
			{children}
		</div>
	)
}
