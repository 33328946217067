import { Divider, SearchAndFilter, ZenCard } from "components/common"
import { ErrorNotification } from "components/errorBox"
import { ListTable, useListTable } from "components/listTable"
import { AuthContainer } from "controllers/auth"
import { fetching } from "fetching"
import React from "react"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { useStyletron } from "baseui"
import { BlacklistUser, RolePermission } from "types/types"
import { ZenPagination } from "components/zenComponents/zenPagination"
import { LabelLarge } from "baseui/typography"
import { PortalContainer } from "controllers/portal"
import { friendlyDate, friendlyTime, useDebounce } from "helpers/utils"
import { Button, KIND, SIZE } from "baseui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ZenTheme } from "themeOverrides"
import { ZenButton } from "components/zenComponents/zenButtons"
import { ZenModal } from "components/zenComponents/zenModal"
import { ModalHeader } from "baseui/modal"
import { NestedValue, useForm } from "react-hook-form"
import { ZenClientSelect, ZenSelect, ZenUserSelect } from "components/zenComponents/zenSelectBox"
import { Option, Value } from "baseui/select"
import { ZenInput } from "components/zenComponents/zenInput"
import { ErrorFieldTracker } from "components/forms/errorFieldTracker"
import { CancelAndSaveButtons } from "components/cancelSaveButtons"
import { BlacklistInput } from "fetching/inputType"
import { ZenArchiveModal } from "components/zenComponents/zenArchiveDialog"
import { NotFound } from "components/notFound"

enum SortBy {
    Worker = "Worker",
    Client = "Client",
    Name = "Name",
    Description = "Description",
    Scope = "Scope",
    CreatedAt = "CreatedAt",
}

const typeOptions: Option[] = [
    { id: "ALL", label: "All" },
    { id: "CUSTOM", label: "Custom" },
]

const scopeOptions: Option[] = [
    { id: "CLIENT", label: "Client", "name": "client_profile" },
    { id: "FORMS", label: "Forms", "name": "client_form" },
]

interface FormData {
    worker: NestedValue<Option[]>
    client: NestedValue<Option[]>
    type: NestedValue<Option[]>
    scope: NestedValue<Option[]>
    description: string
}

interface BlacklistFormProps {
    blacklistUser?: BlacklistUser
    onClose: () => void
    queryBlacklistUser: () => Promise<
        QueryResponse<{
            blacklist: BlacklistUser[]
            total: number
        }>
    >
}

enum FilterOption {
    Active = "Active",
    Archive = "Archived"
}

export const RestrictedClients = () => {
    const { hasPermission } = AuthContainer.useContainer()
    const { timezone } = PortalContainer.useContainer()
    const [rows, setRows] = React.useState<BlacklistUser[]>([])
    const [openCreateModal, setOpenCreateModal] = React.useState<boolean>(false)
    const [openArchiveModal, setOpenArchiveModal] = React.useState<boolean>(false)
    const [openUnArchiveModal, setOpenUnArchiveModal] = React.useState<boolean>(false)
    const [selectedBlacklistUser, setSelectedBlacklistUser] = React.useState<BlacklistUser>()
    const [selectedRowForArchive, setSelectedRowForArchive] = React.useState<BlacklistUser>()


    const blacklistArchive = useMutation<BlacklistUser>(fetching.mutation.blacklistArchive)
    const blacklistUnArchive = useMutation<BlacklistUser>(fetching.mutation.blacklistUnArchive)

    const { sortColumn, sortAsc, limit, offset, setTotal, total, setOffset, filter, setFilter, search, setSearch } =
        useListTable({
            sortColumn: SortBy.Worker,
        })

    const [displayKey, setDisplayKey] = React.useState("")
    const debouncedSearchTerm = useDebounce(displayKey, 500)
    React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm, setSearch])

    const blacklistMany = useQuery(
        fetching.query.getBlacklistUsers({
            search,
            limit,
            offset,
            isArchived: filter[0].id === FilterOption.Archive
        })
    )

    React.useEffect(() => {
        if (blacklistMany.loading || !blacklistMany.payload || !blacklistMany.payload.blacklist) return
        setRows(blacklistMany.payload.blacklist)
        setTotal(blacklistMany.payload.total)
    }, [blacklistMany.loading, blacklistMany.payload, blacklistMany.error, setTotal])


    if (blacklistMany.error) return <ErrorNotification messageOrPayload={blacklistMany.payload} />

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [css] = useStyletron()

    const containerStyle = css({
        height: "100%",
    })
    const top = css({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "14px",
    })
    const actionButtonHeader = css({
        textAlign: "right",
        marginRight: "8%",
    })

    const actionButton = css({
        textAlign: "right",
        marginRight: "5%",
    })

    if (blacklistMany.error) {
        if (blacklistMany.status === 403) {
            return <NotFound heading={"Permission Denied"} message={"You do not have permission to view this restricted clients."} />
        }
        return <NotFound />
    }

    if (!hasPermission(RolePermission.Executive) || !hasPermission(RolePermission.ExecutiveCorporate)) {
        return <NotFound heading={"Permission Denied"} message={"You do not have permission to view this restricted clients."} />
    }

    return (
        <ZenCard className={containerStyle}>
            <div className={top}>
                <SearchAndFilter
                    search={displayKey}
                    setSearch={setDisplayKey}
                    filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
                    filter={filter}
                    setFilter={(v) => {
                        setFilter(v)
                        blacklistMany.query()
                    }} />
                {filter[0].id === FilterOption.Active && <ZenButton onClick={() => {
                    setSelectedBlacklistUser(undefined)
                    setOpenCreateModal(true)
                }}>New Restricted Client</ZenButton>}
            </div>
            <Divider style={{ backgroundColor: "transparent" }} />
            {blacklistMany.error && <ErrorNotification messageOrPayload={blacklistMany.payload} />}
            {blacklistArchive.error && <ErrorNotification messageOrPayload={blacklistArchive.payload} />}
            {blacklistUnArchive.error && <ErrorNotification messageOrPayload={blacklistUnArchive.payload} />}
            <ListTable
                isLoading={blacklistMany.loading}
                sortColumn={sortColumn}
                sortAsc={sortAsc}
                rows={rows}
                onRowClick={(row: BlacklistUser) => {
                    if (row.deletedAt) {
                        // trigger unarchive
                        //etTargetedContractAreaID(row.id)
                        //setOpenUnarchiveModal(true)
                        return
                    }
                    setSelectedBlacklistUser(row)
                    setOpenCreateModal(true)
                }}
                columns={[
                    {
                        id: SortBy.Worker,
                        header: "Worker",
                        resolver: (row: BlacklistUser) => (
                            <div>
                                <div>
                                    {`${row.worker?.firstName} ${row.worker?.lastName} `}
                                </div>
                            </div>
                        ),
                        // sortable: true,
                    },
                    {
                        id: SortBy.Client,
                        header: "Client",
                        resolver: (row: BlacklistUser) => (
                            <div>
                                <div>
                                    {`${row.client?.firstName} ${row.client?.lastName} `}
                                </div>
                            </div>
                        ),
                        sortable: true,
                    },
                    {
                        id: SortBy.Description,
                        header: "Description",
                        resolver: (row: BlacklistUser) => (
                            <div>{row.description}</div>
                        ),
                    },
                    {
                        id: SortBy.CreatedAt,
                        header: "Created At",
                        resolver: (row: BlacklistUser) => (
                            <div>{`${friendlyDate(row.createdAt, timezone)} ${friendlyTime(timezone, row.createdAt)}`}</div>
                        ),
                    },
                    {
                        id: "",
                        header: "",
                        //omitted: props.viewOnly, // for passing props only
                        resolver: (row: BlacklistUser) => {
                            return (
                                <div className={row.deletedAt ? actionButtonHeader : actionButton}>
                                    <Button
                                        kind={KIND.minimal}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setSelectedRowForArchive(row)
                                            if (row.deletedAt) {
                                                setOpenUnArchiveModal(true)
                                                return
                                            }
                                            setOpenArchiveModal(true)
                                        }}>
                                        <FontAwesomeIcon
                                            color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
                                            icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
                                        />
                                    </Button>
                                    <div onClick={(e) => e.stopPropagation()}>
                                        {openArchiveModal && (
                                            <ZenArchiveModal
                                                open={openArchiveModal}
                                                loading={blacklistArchive.loading || blacklistMany.loading}
                                                message={`${selectedRowForArchive?.worker?.firstName} ${selectedRowForArchive?.worker?.lastName}`}
                                                onClose={() => setOpenArchiveModal(false)}
                                                confirmArchive={() => {
                                                    blacklistArchive.mutate(selectedRowForArchive?.id).then((resp) => {
                                                        if (resp.error || !resp.payload) return
                                                        blacklistMany.query()
                                                        setOpenArchiveModal(false)
                                                    })
                                                }}
                                            />
                                        )}
                                        {openUnArchiveModal && (
                                            <ZenArchiveModal
                                                open={openUnArchiveModal}
                                                loading={blacklistUnArchive.loading || blacklistMany.loading}
                                                message={`${selectedRowForArchive?.worker?.firstName} ${selectedRowForArchive?.worker?.lastName}`}
                                                onClose={() => setOpenArchiveModal(false)}
                                                restoreMode
                                                confirmArchive={() => {
                                                    blacklistUnArchive.mutate(selectedRowForArchive?.id).then((resp) => {
                                                        if (resp.error || !resp.payload) return
                                                        blacklistMany.query()
                                                        setOpenUnArchiveModal(false)
                                                    })
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        },
                    },
                ]} />

            <ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />

            <ZenModal
                overrides={{
                    Dialog: {
                        style: {
                            maxWidth: "600px",
                            height: "500px",
                            overflowY: "auto",
                            width: "100%",
                        },
                    },
                }}
                isOpen={openCreateModal}
                onClose={() => setOpenCreateModal(false)}
                size={SIZE.default}
                autoFocus={false}
            >
                <ModalHeader>
                    <LabelLarge>{`${selectedBlacklistUser ? "Edit" : "New"} Restricted Client`}</LabelLarge>
                </ModalHeader>
                <BlacklistForm
                    blacklistUser={selectedBlacklistUser}
                    onClose={() => setOpenCreateModal(false)}
                    queryBlacklistUser={blacklistMany.query}
                />
            </ZenModal>
        </ZenCard>
    )
}

const BlacklistForm = (props: BlacklistFormProps) => {
    const { blacklistUser, onClose, queryBlacklistUser } = props
    const [isCustomType, setCustomType] = React.useState<boolean>(false)
    // react hook form
    const { handleSubmit, control, setValue, errors } = useForm<FormData>()

    React.useEffect(() => {
        if (!blacklistUser) return

        setValue("worker", [{
            id: blacklistUser.worker?.id,
            label: `${blacklistUser.worker?.firstName} ${blacklistUser.worker?.lastName}`
        }])
        setValue("client", [{
            id: blacklistUser?.client?.id,
            label: `${blacklistUser.client?.firstName} ${blacklistUser.client?.lastName}`
        }])

        setValue("description", blacklistUser?.description);

        setValue("type", [{ id: blacklistUser?.type }]);
        const custom = blacklistUser?.type !== "ALL"
        setCustomType(custom)

        if (custom) {
            const name = blacklistUser?.scope?.find(s => s.type === "CUSTOM")
            if (name) {
                const s = Object.values(scopeOptions).find(v => v.name === name?.name) as Option
                setValue("scope", s ? [{ id: s.id }] : []);
            }
        } else {
            const name = blacklistUser?.scope?.find(s => s.type !== "CUSTOM")
            const s = Object.values(scopeOptions).find(v => v.name === name?.name) as Option
            setValue("scope", s ? [{ id: s.id }] : []);
        }

    }, [blacklistUser, setValue])

    const createBlacklist = useMutation<BlacklistUser>(fetching.mutation.blacklistCreate)
    const updateBlacklist = useMutation<BlacklistUser>(fetching.mutation.blacklistUpdate)
    const onSubmit = async (formData: any) => {
        let values: BlacklistInput = {
            worker: formData.worker[0].id,
            client: formData.client[0].id,
            description: formData.description,
            type: formData.type[0].id,
            scope: []
        }
        if (values.type !== "ALL") {
            values.scope = [
                {
                    name: formData.scope[0]["name"],
                    type: formData.type[0].id,
                    scope: []
                }
            ]
        }

        if (blacklistUser) {
            values.id = blacklistUser?.id
            await updateBlacklist
                .mutate(values)
                .then((resp) => {
                    if (resp.error && !resp.payload) return
                    queryBlacklistUser()
                    onClose()
                })
            return
        }
        await createBlacklist
            .mutate(values)
            .then((resp) => {
                if (resp.error && !resp.payload) return
                queryBlacklistUser()
                onClose()
            })
    }

    const [css] = useStyletron()
    const eventBody = css({
        height: "85%",
        fontFamily: "'Open Sans', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif",
        padding: "20px",
    })
    const container: string = css({
        height: "100%",
        display: "flex",
        flexDirection: "column",
    })
    const scrollingDiv = css({
        height: "100%",
        maxHeight: "100%",
        paddingRight: "8px",
        overflowY: "auto",
        overflowX: "hidden",
    })

    return (
        <div className={eventBody}>
            <form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
                <div className={scrollingDiv}>
                    <ZenUserSelect
                        label="Worker"
                        formName="worker"
                        formRef={control}
                        inputError={errors.worker}
                        formRules={{
                            validate: {
                                required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
                            },
                        }}
                        actionOnChange={(v) => {
                            //setExcludedUserID(v.length > 0 && v[0].id?.toString() ? [v[0].id.toString()] : [])
                            //onWorkerChange(v)
                        }}
                    />
                    <ZenClientSelect
                        label="Client"
                        formName="client"
                        formRef={control}
                        inputError={errors.client}
                        formRules={{
                            validate: {
                                required: (value: Value) => (!!value && value.length > 0) || "Client is required",
                            },
                        }}
                        actionOnChange={(v) => {
                            //setExcludedUserID(v.length > 0 && v[0].id?.toString() ? [v[0].id.toString()] : [])
                            //onWorkerChange(v)
                        }}
                    />
                    <ZenInput
                        formRef={control}
                        nameRef="description"
                        placeholder="Description here..."
                        label="Description"
                        inputError={errors.description}
                    />
                    <ZenSelect
                        label={"Type"}
                        formName={"type"}
                        formRef={control}
                        inputError={errors.type}
                        formRules={{
                            validate: {
                                required: (value: Value) => (!!value && value.length > 0) || "Type is required",
                            },
                        }}
                        placeholder={"Select travel type"}
                        options={typeOptions}
                        actionOnChange={(v) => {
                            if (!v) return
                            const opt = v as Option[]
                            setCustomType(opt[0].id !== "ALL")
                        }}
                    />
                    {
                        isCustomType &&
                        <ZenSelect
                            label={"Scope"}
                            formName={"scope"}
                            formRef={control}
                            inputError={errors.scope}
                            formRules={{
                                validate: {
                                    required: (value: Value) => (!!value && value.length > 0) || "Scope is required",
                                },
                            }}
                            placeholder={"Select scope"}
                            options={scopeOptions}
                        />
                    }
                    <ErrorFieldTracker errorIDs={Object.keys(errors)} />
                    <CancelAndSaveButtons
                        buttonWidths={"28%"}
                        cancelFn={onClose}
                        saveLabel="Submit"
                    //isLoading={createBlacklist.loading || updateBlacklist.loading}
                    />
                </div>
            </form>
        </div>
    )
}