import * as React from "react"
import { useStyletron } from "baseui"
import { LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { timeDuration } from "../../helpers/utils"
import { Travel, RolePermission, TZString } from "../../types/types"
import { LabelField, UserLabel } from "../callLogs/subComponents"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenArchiveModal } from "../zenComponents/zenArchiveDialog"
import { ZenButton } from "../zenComponents/zenButtons"
import { TravelExpenseList } from "./expenseList"
import { PortalContainer } from "../../controllers/portal"
import { StatefulTooltip } from "baseui/tooltip"
import { TravelTypesForShowingClient } from "./travelForm"

interface TravelDetailProps {
	setEditMode: React.Dispatch<React.SetStateAction<boolean>>
	travelData: Travel
	refetch: () => void
	setSelectedTravel?: React.Dispatch<React.SetStateAction<Travel | null>> | undefined
	refetchMileageClaim?: (() => void) | undefined
	refetchTimesheet?: (() => void) | undefined
}
export const TravelDetail = (props: TravelDetailProps) => {
	const { setEditMode, travelData, refetch, setSelectedTravel, refetchMileageClaim, refetchTimesheet } = props
	const { mutate, payload, loading: archiveLoading, error } = useMutation<Travel>(fetching.mutation.travelToggleArchive)
	const [archiveModalOpen, setArchiveModalOpen] = React.useState(false)
	const history = useHistory()
	const { showToast } = useZenToast()
	const [css] = useStyletron()
	const container = css({
		position: archiveLoading ? "relative" : "unset",
		opacity: archiveLoading ? 0.5 : 1,
		display: "flex",
		flexDirection: "column",
		width: "100%",
		padding: "15px",
		backgroundColor: "white",
		height: "100%",
		overflowY: "auto",
	})
	const archiveMask = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		opacity: travelData?.deletedAt ? 0.5 : 1,
	})
	const groupDisplay = css({
		display: "flex",
		justifyContent: "space-between",
	})
	const groupCenter = css({
		display: "flex",
		alignItems: "center",
	})

	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const { timezone: globalTimezone } = PortalContainer.useContainer()
	const timezone = React.useMemo(() => {
		const target = TZString.find((tz) => tz.id === travelData.timezoneID)
		if (!target) return globalTimezone
		return target
	}, [travelData.timezoneID]) // eslint-disable-line react-hooks/exhaustive-deps

	const toggleEditMode = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (travelData?.deletedAt) return
		event.preventDefault()
		setEditMode(true)
	}

	const handleToggleArchive = () => {
		setArchiveModalOpen(true)
	}

	const displayArchiveInfo = (): string => {
		if (!travelData?.deletedAt) return ""
		return `archived at ${moment(travelData.deletedAt).tz(timezone.id).format("DD/MM/YYYY, hh:mm a")}`
	}

	let carDetails = "Failed to get car details"
	if (travelData.car) {
		const car = travelData.car
		carDetails = `[${car.regoNumber}] ${car.make} ${car.model}`
	}

	const cantEditReason = React.useMemo<string[]>(() => {
		// skip all the check if user have completeReportManage permission
		if (hasPermission(RolePermission.CompletedReportManage)) return []

		let errorMessages: string[] = []
		// return false if current user is not loaded
		if (!currentUser) {
			return ["Current user is not loaded"]
		}

		if (travelData.isLocked) {
			if (travelData.timesheet) {
				if (travelData.timesheet.completedAt) {
					errorMessages.push("It is linked to a completed timesheet")
				} else {
					errorMessages.push("It is linked to an approved timesheet")
				}
			}

			if (travelData.mileageClaim) {
				if (travelData.mileageClaim.completedAt) {
					errorMessages.push("It is linked to a completed mileage claim")
				} else {
					errorMessages.push("It is linked to an approved mileage claim")
				}
			}
		}

		if (hasPermission(RolePermission.TravelUpdate)) return errorMessages

		// if the worker is not the dedicated worker of the session
		if (travelData.workerID !== currentUser.id) {
			errorMessages.push("Only workers involved in this travel can edit the travel")
		}

		if ((!!travelData.timesheet?.readyAt || !!travelData.mileageClaim?.readyAt) && !travelData.isLocked) {
			errorMessages.push("It is claimed and waiting for supervisor to approve")
		}

		return errorMessages
	}, [currentUser, hasPermission, travelData])

	return (
		<div className={container}>
			<div className={archiveMask}>
				<div className={groupDisplay}>
					<LabelMedium>{`Travel #${travelData.identifier} ${displayArchiveInfo()}`}</LabelMedium>
					<StatefulTooltip
						content={() =>
							cantEditReason.length > 0 || travelData.deletedAt ? (
								<>
									<LabelSmall color="white">This travel cannot be edited because</LabelSmall>
									{cantEditReason.map((r, i) => (
										<LabelSmall key={i} color="white" marginLeft="5px">
											{`-  ${r}`}
										</LabelSmall>
									))}
									{travelData.deletedAt && (
										<LabelSmall color="white" marginLeft="5px">
											{"-  It is archived"}
										</LabelSmall>
									)}
								</>
							) : null
						}
						triggerType="hover"
						placement="leftTop"
						popoverMargin={5}
					>
						<div>
							<ZenButton onClick={toggleEditMode} disabled={!!travelData.deletedAt || cantEditReason.length > 0}>
								Edit
							</ZenButton>
						</div>
					</StatefulTooltip>
				</div>
				{travelData.session && (
					<LabelField label="Linked Session">
						<div className={groupCenter}>
							<LabelSmall marginRight="16px">{`# ${travelData.session.identifier}`}</LabelSmall>
							<ZenButton size="mini" onClick={() => history.push(`/portal/sessions/${travelData.session?.identifier}`)}>
								View Session
							</ZenButton>
						</div>
					</LabelField>
				)}
				<LabelField label="Date">
					<LabelSmall>{moment(travelData.startTime).tz(timezone.id).format("DD/MM/YYYY")}</LabelSmall>
				</LabelField>
				<LabelField label="Timezone">
					<LabelSmall>{timezone.label}</LabelSmall>
				</LabelField>
				<div className={groupDisplay}>
					<LabelField label="Start time">
						<LabelSmall>{moment(travelData.startTime).tz(timezone.id).format("hh:mm a")}</LabelSmall>
					</LabelField>
					<LabelField label="End time">
						<LabelSmall>{moment(travelData.endTime).tz(timezone.id).format("hh:mm a")}</LabelSmall>
					</LabelField>
					<LabelField label="Duration">
						<LabelSmall>
							{
								timeDuration
								(
									travelData.startTime, travelData.endTime,
									/* for 30 mins only the limit */
									travelData.travelType.label === TravelTypesForShowingClient.cBProviderTravel
								)
							}{
								travelData.travelType.label === TravelTypesForShowingClient.cBProviderTravel
								&& console.log(travelData.travelType.label)
							}
						</LabelSmall>
					</LabelField>
				</div>
				<LabelField label="Travel Type">
					<LabelSmall>{travelData.travelType.label}</LabelSmall>
				</LabelField>
				<LabelField label="Worker">
					<UserLabel data={travelData.worker} />
				</LabelField>
				<div className={groupDisplay}>
					{travelData.client && (
						<LabelField label="Client">
							<UserLabel data={travelData.client} />
						</LabelField>
					)}
					{travelData.anotherClient && (
						<LabelField label="Another Client">
							<UserLabel data={travelData.anotherClient} />
						</LabelField>
					)}
				</div>
				<LabelField label="From" warningMsg={travelData.fromAddress.placeID === "" ? "Invalid address" : ""}>
					<LabelSmall>{travelData.fromAddress.fullAddress}</LabelSmall>
				</LabelField>
				<LabelField label="To" warningMsg={travelData.toAddress.placeID === "" ? "Invalid address" : ""}>
					<LabelSmall>{travelData.toAddress.fullAddress}</LabelSmall>
				</LabelField>
				<div className={groupDisplay}>
					<LabelField label="Calculated Distance">
						<LabelSmall>{`${travelData.calculatedDistanceMetres / 1000.0} KM`}</LabelSmall>
					</LabelField>
					<LabelField label="Actual Distance">
						<LabelSmall>{`${travelData.actualDistanceMetres / 1000.0} KM`}</LabelSmall>
					</LabelField>
				</div>
				<div className={groupDisplay}>
					<LabelField label="Odometer Before Trip">
						{travelData.odometerBeforeKM ? <LabelSmall>{`${travelData.odometerBeforeKM} KM`}</LabelSmall> : <LabelSmall>Not specified</LabelSmall>}
					</LabelField>
					<LabelField label="Odometer After Trip">
						{travelData.odometerAfterKM ? <LabelSmall>{`${travelData.odometerAfterKM} KM`}</LabelSmall> : <LabelSmall>Not specified</LabelSmall>}
					</LabelField>
				</div>
				<div className={groupDisplay}>
					<LabelField label="Calculated Duration">
						<LabelSmall>{`${~~(travelData.calculatedDurationSeconds / 3600)}h ${~~(
							(travelData.calculatedDurationSeconds / 60) %
							60
						)}m`}</LabelSmall>
					</LabelField>
					<LabelField label="Actual Duration">
						<LabelSmall>{`${~~(travelData.actualDurationSeconds / 3600)}h ${~~((travelData.actualDurationSeconds / 60) % 60)}m`}</LabelSmall>
					</LabelField>
				</div>
				{travelData.car && (
					<LabelField label="Used Company Car">
						<LabelSmall>{carDetails}</LabelSmall>
					</LabelField>
				)}
				{travelData.fundingSource && (
					<LabelField label="Funding Source">
						<LabelSmall>{travelData.fundingSource.label}</LabelSmall>
					</LabelField>
				)}
				<div>
					{travelData.travelExpenses && travelData.travelExpenses.length > 0 && <TravelExpenseList travelExpenses={travelData.travelExpenses} />}
				</div>
			</div>
			{cantEditReason.length === 0 && (
				<>
					<ZenButton
						width="100px"
						marginTop={"20px"}
						onClick={handleToggleArchive}
						disabled={archiveLoading}
						altKind={travelData.deletedAt ? "primary" : "danger"}
					>
						{travelData.deletedAt ? "Unarchive" : "Archive"}
					</ZenButton>
					<ZenArchiveModal
						open={archiveModalOpen}
						onClose={() => setArchiveModalOpen(false)}
						loading={archiveLoading}
						message={"Travel"}
						restoreMode={travelData.deletedAt ? true : false}
						confirmArchive={async () => {
							const resp = await mutate({
								id: travelData.id,
							})

							if (resp.error || !resp.payload) return

							if (travelData.deletedAt) {
								showToast("Travel document unarchived successfully.", "positive")
								if (setSelectedTravel) setSelectedTravel(null)
							} else {
								showToast("Travel document archived successfully.", "positive")
							}

							if (refetchMileageClaim) refetchMileageClaim()
							if (refetchTimesheet) refetchTimesheet()
							refetch()
						}}
						error={error}
						payload={payload}
					/>
				</>
			)}
		</div>
	)
}
