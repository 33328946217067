import React, { useContext } from "react";
import YouthCareerContext from "./context";
import { Spacer, ZenCard } from "components/common";
import { LabelLarge, ParagraphSmall } from "baseui/typography";
import { useStyletron } from "baseui"
import { ZenDatePicker } from "components/zenComponents/zenTime";
import moment from "moment";
import { ZenButton } from "components/zenComponents/zenButtons";
import { ZenClientSelect } from "components/zenComponents/zenSelectBox";
import { ZenSelect } from "./components/youthCareerSelect"
import { FormControl } from "baseui/form-control";
import { Notification, KIND } from "baseui/notification"
import { ErrorNotification } from "components/errorBox";
import { Value } from "baseui/select"

const YouthCareerView = () => {
	const [css] = useStyletron()
	const card = css({
		width: "fit-content",
	});
	const dates = css({
		display: "flex",
	});
	const buttons = css({
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "15px",
	});
    const { errorMsg, values, actions, useFormHooks } = useContext(YouthCareerContext);
    const { 
        data, 
        fundingTypeOpt,
		fundingTypeLoading,
		formTypeOpt,
        durationWarning, 
        loading, 
        YouthCarerPayloadError 
    } = values;
    const { handleOnSubmit, setLineItemDisplayOptions } = actions;
    const { control, handleSubmit, errors, getValues } = useFormHooks;

    return (
        <ZenCard className={card}>
			<LabelLarge>Pre and Post Outcomes Report</LabelLarge>
			<ParagraphSmall maxWidth={"400px"}>Create Pre and Post Outcomes report here</ParagraphSmall>
            <form autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)}>
            {/* <form autoComplete="off" > */}
                <div className={dates}>
					<div>
						<ZenDatePicker
							nullDefaultValue={true}
							formRef={control}
							label={"Start Date"}
							formName={"startDate"}
							inputError={errors.startDate}
							formRules={{
								required: "You must select a start date.",
							}}
						/>
					</div>
					<Spacer style={{ width: "15px" }} />
					<div>
						<ZenDatePicker
							nullDefaultValue={true}
							formRef={control}
							label={"End Date"}
							formName={"endDate"}
							inputError={errors.endDate}
							formRules={{
								required: "You must select an end date.",
								validate: (value: string) => {
									if (moment(value).isBefore(moment(getValues("startDate")))) {
										return "End date must be after start date."
									}
									return null
								},
							}}
						/>
					</div>
				</div>
				<ZenClientSelect 
					label={"Client"} 
					formName={"client"} 
					formRef={control} 
					inputError={errors?.client}
					actionOnChange={(e) => {
						const {firstName, lastName, id} = e?.[0] ?? {};
						// return setLineItemDisplayOptions(`${firstName || ''} ${lastName || ''}`);
						return setLineItemDisplayOptions(`${id|| ''}`);
					}}
				/>
				<ZenSelect
					multi={true}
					options={fundingTypeOpt}
					isLoading={fundingTypeLoading}
					label="Funding Type"
					formName={"funding_type"}
					formRef={control}
                    inputError={errors?.funding_type}
                    // formRules={{
                    //     validate: {
                    //         required: (value: Value) => (!!value && value.length > 0) || `Funding Type is required`,
                    //     },
                    // }}
				/>
				<ZenSelect
					// multi={true}
					options={formTypeOpt}
					isLoading={loading}
					label="Form Type"
					formName={"form_type"}
					formRef={control}
				/>
				<FormControl error={errors.client}>
					<div />
				</FormControl>
				{durationWarning && <Notification kind={KIND.warning}>{durationWarning}</Notification>}
                {YouthCarerPayloadError && <ErrorNotification message={errorMsg['eblobErr']} />}
                <div className={buttons}>
					<ZenButton type={"submit"} isLoading={loading}>
						Create Report
					</ZenButton>
				</div>
            </form>

        </ZenCard>
    )
};

export default YouthCareerView;