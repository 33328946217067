import * as React from "react"
import { useStyletron } from "baseui"
import { ModalBody } from "baseui/modal"
import { Option, Select } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import { LabelMedium } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import moment from "moment-timezone"
import { ClientSessionList } from "pages/sessions/sessionList"
import { QueryResponse, useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { Responsive, WidthProvider } from "react-grid-layout"
import { useForm } from "react-hook-form"
import { RouteComponentProps, useLocation } from "react-router-dom"
import { CallLogList } from "../../components/callLogs/callLogList"
import { CancelAndSaveButtons } from "../../components/cancelSaveButtons"
import { ClientParentGuardianCard } from "../../components/clients/clientOverview/clientDetails/clientDetail"
import { ClientOverviewPage } from "../../components/clients/clientOverview/clientOverview"
import { ClientTravel } from "../../components/clients/clientOverview/clientTravel/clientTravelRoot"
import { HealthTreatmentTeams } from "../../components/clients/clientOverview/healthTreatmentTeams"
import { ViewClientFunctionBar } from "../../components/clients/clientViewButtonBar"
import { EmergencyContactForm } from "../../components/clients/Intake/adult/intakeAdultEmergencyContact"
import { ParentGuardianDetailForm } from "../../components/clients/Intake/youth/intakeYouthParentGuardianDetail"
import { Divider, NoDataText, Spacer, ZenCard } from "../../components/common"
import { FormList } from "../../components/forms/formList"
import { FormTypeList } from "../../components/forms/formTypeList"
import { Loading } from "../../components/loading"
import { NotFound } from "../../components/notFound"
import { TooltipButton } from "../../components/tooltipButton"
import { EmergencyContactItem } from "../../components/zenComponents/emergencyContact"
import { ZenArchiveModal } from "../../components/zenComponents/zenArchiveDialog"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { ClientEmergencyContactInput, ClientParentGuardianContactInput } from "../../fetching/inputType"
import { FilterBy } from "../../types/enums"
import { ClientDetail, ClientEmergencyContact, ClientParentGuardian } from "../../types/types"
import { NDIS } from "./NDIS/NDIS"
import { ClientNotebook } from "./clientNotebook"

export const ClientView = (props: RouteComponentProps<{ id: string }>) => {
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%",
	})
	const content = css({
		height: "100%",
		minHeight: 0,
		marginTop: "20px",
	})

	const id = props.match.params.id
	const clientGet = useQuery(fetching.query.getClient(id))
	const location = useLocation()
	const { query } = useParameterizedQuery(fetching.query.recordViewAction)
	// record view action every time enter client view page
	React.useEffect(() => {
		query(id)
	}, [query, id])
	const [activeKey, setActiveKey] = React.useState(location.hash.replace("#", "") || "overview")

	if (clientGet.error) {
		if (clientGet.status === 403) {
			return <NotFound heading={"Permission Denied"} message={"You do not have permission to view this client."} />
		}
		return <NotFound />
	}

	const pageSwitcher = () => {
		if (!clientGet.payload) return null

		switch (activeKey.toString()) {
			case "overview": // client overview page
				return <ClientOverviewPage client={clientGet.payload} loading={clientGet.loading} refetch={clientGet.query} setActiveKey={setActiveKey} />
			case "forms":
				return <FormsAndDocsPage />
			case "healthTeam":
				return <HealthTreatmentTeams />
			case "travels":
				return <ClientTravelPage client={clientGet.payload} />
			case "sessions":
				return <ClientSessionPage client={clientGet.payload} />
			case "ndis":
				return <NDIS client={clientGet.payload} loading={clientGet.loading} refetchClient={clientGet.query} />
			case "callLogs":
				return <CallLogList client={clientGet.payload} />
			case "contacts":
				return <ClientContactPage clientID={id} />
			case "notebook":
				return <ClientNotebook clientID={id} />
			default:
				return null
		}
	}
	if (!clientGet.payload) return <Loading />

	return (
		<div className={container}>
			<ViewClientFunctionBar activeKey={activeKey} setActiveKey={setActiveKey} />
			<div className={content}>{pageSwitcher()}</div>
		</div>
	)
}

const ResponsiveGridLayout = WidthProvider(Responsive)

const FormsAndDocsPage = () => {
	const [css] = useStyletron()
	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexGrow: 1,
		flexDirection: "column",
		overflowY: "auto",
	})
	const itemCls = css({
		display: "flex",
		overflowY: "auto",
		overflowX: "hidden",
	})

	const { client } = PortalContainer.useContainer()

	if (!client) return <Loading />

	return (
		<div className={container}>
			<ResponsiveGridLayout measureBeforeMount={true} layouts={layouts} rowHeight={(window.innerHeight - 66) / 25}>
				<div key={"action-required"} className={itemCls}>
					<FormList requiresAction={1} title="Action Required" clientID={client.id} />
				</div>
				<div key={"forms"} className={itemCls}>
					<FormList requiresAction={2} clientID={client.id} canAttachFile />
				</div>
				<div key={"forms-type"} className={itemCls}>
					<FormTypeList client={client} />
				</div>
			</ResponsiveGridLayout>
		</div>
	)
}

const layouts = {
	lg: [
		{
			w: 8,
			h: 7,
			x: 0,
			y: 0,
			i: "action-required",
			static: true,
		},
		{
			w: 8,
			h: 13,
			x: 0,
			y: 7,
			i: "forms",
			static: true,
		},
		{
			w: 4,
			h: 20,
			x: 8,
			y: 0,
			i: "forms-type",
			static: true,
		},
	],
	md: [
		{
			w: 7,
			h: 6,
			x: 0,
			y: 0,
			i: "action-required",
			static: true,
		},
		{
			w: 7,
			h: 13,
			x: 0,
			y: 6,
			i: "forms",
			static: true,
		},
		{
			w: 3,
			h: 19,
			x: 7,
			y: 0,
			i: "forms-type",
			static: true,
		},
	],

	sm: [
		{
			w: 6,
			h: 5,
			x: 0,
			y: 6,
			i: "action-required",
			static: true,
		},
		{
			w: 6,
			h: 9,
			x: 0,
			y: 11,
			i: "forms",
			static: true,
		},
		{
			w: 6,
			h: 6,
			x: 0,
			y: 0,
			i: "forms-type",
			static: true,
		},
	],
	xs: [
		{
			w: 4,
			h: 5,
			x: 0,
			y: 5,
			i: "action-required",
			static: true,
		},
		{
			w: 4,
			h: 9,
			x: 0,
			y: 10,
			i: "forms",
			static: true,
		},
		{
			w: 4,
			h: 5,
			x: 0,
			y: 0,
			i: "forms-type",
			static: true,
		},
	],
	xxs: [
		{
			w: 2,
			h: 6,
			x: 0,
			y: 6,
			i: "action-required",
			static: true,
		},
		{
			w: 2,
			h: 13,
			x: 0,
			y: 12,
			i: "forms",
			static: true,
		},
		{
			w: 2,
			h: 6,
			x: 0,
			y: 0,
			i: "forms-type",
			static: true,
		},
	],
}

const ClientTravelPage = (props: { client: ClientDetail }) => {
	const { client } = props
	return <ClientTravel {...client} />
}

const ClientSessionPage = (props: { client: ClientDetail }) => {
	const { client } = props
	return <ClientSessionList client={client} />
}

const ClientContactPage = (props: { clientID: string }) => {
	const { clientID } = props
	const [css] = useStyletron()
	const outer = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%",
	})

	return (
		<div className={outer}>
			<EmergencyContactList clientID={clientID} />

			<Divider style={{ backgroundColor: "transparent" }} />

			<ClientParentGuardianList clientID={clientID} />
		</div>
	)
}

interface EmergencyContactListProps {
	clientID: string
}
const EmergencyContactList = (props: EmergencyContactListProps) => {
	const { clientID } = props
	const [css] = useStyletron()
	const filterOptions = [
		{ label: FilterBy.Active, id: FilterBy.Active },
		{ label: FilterBy.Archived, id: FilterBy.Archived },
	]
	const [archiveModalOpen, setArchiveModalOpen] = React.useState(false)
	const [selectedContact, setSelectedContact] = React.useState<ClientEmergencyContact>()

	const clientEmergencyContactArchive = useMutation(fetching.mutation.clientEmergencyContactArchive)
	const clientEmergencyContactUnarchive = useMutation(fetching.mutation.clientEmergencyContactUnarchive)
	const [clientEmergencyContacts, setClientEmergencyContacts] = React.useState<ClientEmergencyContact[]>([])
	const [emergencyContactFilter, setEmergencyContactFilter] = React.useState([filterOptions[0]])
	const clientEmergencyContactMany = useQuery(
		fetching.query.clientEmergencyContactMany({ clientID, isArchived: emergencyContactFilter[0].label === FilterBy.Archived }),
	)

	const isLoading = clientEmergencyContactMany.loading || clientEmergencyContactArchive.loading || clientEmergencyContactUnarchive.loading

	React.useEffect(() => {
		if (clientEmergencyContactMany.error || !clientEmergencyContactMany.payload) return
		setClientEmergencyContacts(clientEmergencyContactMany.payload)
	}, [clientEmergencyContactMany.payload, clientEmergencyContactMany.error])

	const [openEmergencyContactModal, setOpenEmergencyContactModal] = React.useState<boolean>(false)
	const [selectedEmergencyContact, setSelectedEmergencyContact] = React.useState<ClientEmergencyContact>()
	const handleEditEmergencyContact = (cec: ClientEmergencyContact) => {
		setSelectedEmergencyContact(cec)
		setOpenEmergencyContactModal(true)
	}
	const container = css({
		height: "100%",
		flex: "unset !important",
		minHeight: 0,
		position: isLoading ? "relative" : "unset",
		opacity: isLoading ? 0.5 : 1,
	})
	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "4px",
	})
	const scrollContainer = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
		maxHeight: "100%",
		overflowY: "auto",
		overflowX: "hidden",
	})
	const group = css({
		display: "flex",
		alignContent: "center",
		width: "100%",
	})
	const actionButton = css({
		display: "flex",
		alignContent: "center",
		width: "fit-content",
	})
	return (
		<>
			<ZenCard className={container}>
				{isLoading && (
					<div className={loadingIcon}>
						<StyledSpinnerNext />
					</div>
				)}
				<div className={title}>
					<LabelMedium>Emergency contacts</LabelMedium>
					<div className={actionButton}>
						<Select
							onChange={({ value }) => {
								setEmergencyContactFilter(value as { label: FilterBy; id: FilterBy }[])
							}}
							value={emergencyContactFilter}
							clearable={false}
							options={filterOptions}
							overrides={{
								ControlContainer: {
									style: ({ $disabled }) => ({
										backgroundColor: "transparent",
										borderLeftWidth: 0,
										borderTopWidth: 0,
										borderBottomWidth: $disabled ? 0 : "2px",
										borderBottomColor: "black",
										borderRightWidth: 0,
									}),
								},
								Dropdown: {
									style: {
										maxHeight: "400px",
									},
								},
							}}
						/>
						<Spacer style={{ width: "30px" }} />
						{emergencyContactFilter[0].label === FilterBy.Active && (
							<ZenButton width="100px" onClick={() => setOpenEmergencyContactModal(true)}>
								Add
							</ZenButton>
						)}
					</div>
				</div>
				<div className={scrollContainer}>
					{clientEmergencyContacts.length > 0 ? (
						clientEmergencyContacts
							.sort((a, b) => {
								if (!a.emergencyContact.createdAt) return -1
								if (!b.emergencyContact.createdAt) return 1
								return a.emergencyContact.createdAt > b.emergencyContact.createdAt ? 1 : -1
							})
							.map((e, i) => (
								<div key={i}>
									<Divider />
									<div className={group}>
										<EmergencyContactItem width="100%" index={i} relationship={e.relationshipToClient} {...e.emergencyContact} />
										<div>
											<div className={actionButton}>
												{emergencyContactFilter[0].label === FilterBy.Active && (
													<TooltipButton onClick={() => handleEditEmergencyContact(e)} tooltip={"Edit emergency contact"} iconName={"pencil"} />
												)}
												<TooltipButton
													onClick={() => {
														setArchiveModalOpen(true)
														setSelectedContact(e)
													}}
													tooltip={e.deletedAt ? "Unarchive Contact" : "Archive Contact"}
													iconName={e.deletedAt ? "trash-restore-alt" : "trash-alt"}
												/>
											</div>
										</div>
									</div>
								</div>
							))
					) : (
						<NoDataText />
					)}
				</div>
			</ZenCard>

			<EmergencyContactModal
				open={openEmergencyContactModal}
				setOpen={setOpenEmergencyContactModal}
				selectedEmergencyContact={selectedEmergencyContact}
				setSelectedEmergencyContact={setSelectedEmergencyContact}
				refetch={clientEmergencyContactMany.query}
				clientID={clientID}
			/>

			{/* Archive Modal */}
			{archiveModalOpen && selectedContact && (
				<ZenArchiveModal
					error={clientEmergencyContactArchive.error || clientEmergencyContactUnarchive.error}
					payload={clientEmergencyContactArchive.payload || clientEmergencyContactUnarchive.payload}
					open={archiveModalOpen}
					onClose={() => setArchiveModalOpen(false)}
					loading={clientEmergencyContactArchive.loading || clientEmergencyContactUnarchive.loading}
					message={`Emergency contact - ${selectedContact.emergencyContact.firstName} ${selectedContact.emergencyContact.lastName}`}
					restoreMode={!!selectedContact?.deletedAt}
					confirmArchive={() => {
						if (selectedContact.deletedAt) {
							clientEmergencyContactUnarchive.mutate({ clientID, emergencyContactID: selectedContact.emergencyContact.id }).then((resp) => {
								if (resp.error || !resp.payload) return
								clientEmergencyContactMany.query()
								setArchiveModalOpen(false)
							})
						} else {
							clientEmergencyContactArchive.mutate({ clientID, emergencyContactID: selectedContact.emergencyContact.id }).then((resp) => {
								if (resp.error || !resp.payload) return
								clientEmergencyContactMany.query()
								setArchiveModalOpen(false)
							})
						}
					}}
				/>
			)}
		</>
	)
}

interface EmergencyContactModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	refetch: () => Promise<QueryResponse<ClientEmergencyContact[]>>
	clientID: string
	selectedEmergencyContact?: ClientEmergencyContact
	setSelectedEmergencyContact: React.Dispatch<React.SetStateAction<ClientEmergencyContact | undefined>>
}
const EmergencyContactModal = (props: EmergencyContactModalProps) => {
	const { open, setOpen, refetch, clientID, selectedEmergencyContact, setSelectedEmergencyContact } = props
	const [css] = useStyletron()
	const { control, errors, trigger, getValues, setValue } = useForm()
	const [contactError, setContactError] = React.useState<boolean>(false)

	const emergencyContactCreate = useMutation(fetching.mutation.clientEmergencyContactCreate)
	const emergencyContactUpdate = useMutation(fetching.mutation.clientEmergencyContactUpdate)
	const isLoading = emergencyContactCreate.loading || emergencyContactUpdate.loading

	const clearData = () => {
		setValue("emergencyContactFirstName0", "")
		setValue("emergencyContactLastName0", "")
		setValue("emergencyContactTelephoneNumber0", "")
		setValue("emergencyContactMobileNumber0", "")
		setValue("emergencyContactRelationshipToClient0", [])
		setValue("emergencyContactRelationshipToConsumer0", [])
		setSelectedEmergencyContact(undefined)
	}
	const onClose = () => {
		clearData()
		setOpen(false)
	}

	React.useEffect(() => {
		if (!selectedEmergencyContact) return
		setValue("emergencyContactFirstName0", selectedEmergencyContact.emergencyContact.firstName)
		setValue("emergencyContactLastName0", selectedEmergencyContact.emergencyContact.lastName)
		setValue("emergencyContactTelephoneNumber0", selectedEmergencyContact.emergencyContact.telephoneNumber)
		setValue("emergencyContactMobileNumber0", selectedEmergencyContact.emergencyContact.mobileNumber)
		setValue(
			"emergencyContactRelationshipToClient0",
			selectedEmergencyContact.relationshipToClient !== ""
				? [{ id: selectedEmergencyContact.relationshipToClient, label: selectedEmergencyContact.relationshipToClient }]
				: [],
		)
		setValue(
			"emergencyContactRelationshipToConsumer0",
			selectedEmergencyContact.relationshipToConsumer !== ""
				? [{ id: selectedEmergencyContact.relationshipToConsumer, label: selectedEmergencyContact.relationshipToConsumer }]
				: [],
		)
	}, [selectedEmergencyContact, setValue])

	const onSubmit = async () => {
		const isValid = await trigger([
			"emergencyContactFirstName0",
			"emergencyContactLastName0",
			"emergencyContactTelephoneNumber0",
			"emergencyContactMobileNumber0",
			"emergencyContactRelationshipToClient0",
			"emergencyContactRelationshipToConsumer0",
		])

		const hasContactError = getValues()["emergencyContactTelephoneNumber0"] === "" && getValues()["emergencyContactMobileNumber0"] === ""
		setContactError(hasContactError)

		if (!isValid || hasContactError) return
		const currentState = getValues()

		const input: ClientEmergencyContactInput = {
			clientID,
			firstName: currentState.emergencyContactFirstName0,
			lastName: currentState.emergencyContactLastName0,
			telephoneNumber: currentState.emergencyContactTelephoneNumber0,
			mobileNumber: currentState.emergencyContactMobileNumber0,
			relationshipToClient:
				currentState.emergencyContactRelationshipToClient0 && currentState.emergencyContactRelationshipToClient0.length > 0
					? currentState.emergencyContactRelationshipToClient0[0].id
					: "",
			relationshipToConsumer:
				currentState.emergencyContactRelationshipToConsumer0 && currentState.emergencyContactRelationshipToConsumer0.length > 0
					? currentState.emergencyContactRelationshipToConsumer0[0].id
					: "",
		}

		if (selectedEmergencyContact) {
			emergencyContactUpdate
				.mutate({
					emergencyContactID: selectedEmergencyContact.emergencyContact.id,
					...input,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return
					refetch()
					onClose()
				})
			return
		}

		// add new emergency contact
		emergencyContactCreate.mutate(input).then((resp) => {
			if (resp.error || !resp.payload) return
			refetch()
			onClose()
		})
	}
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		opacity: isLoading ? 0.5 : 1,
		position: isLoading ? "relative" : "unset",
	})
	const buttonContainer = css({
		paddingLeft: "8px",
		paddingRight: "8px",
	})
	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})
	return (
		<ZenModal
			isOpen={open}
			onClose={onClose}
			overrides={{
				Close: {
					style: {
						display: "none",
					},
				},
			}}
		>
			<ModalBody>
				<form autoComplete="off" className={formStyle}>
					{isLoading && (
						<div className={loadingIcon}>
							<StyledSpinnerNext />
						</div>
					)}
					<EmergencyContactForm index={0} formRef={control} inputError={errors} contactError={contactError} omitBorder />
					<div className={buttonContainer}>
						<CancelAndSaveButtons cancelFn={onClose} saveFn={onSubmit} />
					</div>
				</form>
			</ModalBody>
		</ZenModal>
	)
}

interface ClientParentGuardianListProps {
	clientID: string
}
const ClientParentGuardianList = (props: ClientParentGuardianListProps) => {
	const { clientID } = props
	const [css] = useStyletron()
	const filterOptions = [
		{ label: FilterBy.Active, id: FilterBy.Active },
		{ label: FilterBy.Archived, id: FilterBy.Archived },
	]

	const [archiveModalOpen, setArchiveModalOpen] = React.useState(false)
	const [selectedPG, setSelectedPG] = React.useState<ClientParentGuardian>()

	const clientParentGuardianContactArchive = useMutation(fetching.mutation.clientParentGuardianContactArchive)
	const clientParentGuardianContactUnarchive = useMutation(fetching.mutation.clientParentGuardianContactUnarchive)
	const [clientParentGuardianContacts, setClientParentGuardianContacts] = React.useState<ClientParentGuardian[]>([])
	const [parentGuardianContactFilter, setParentGuardianContactFilter] = React.useState([filterOptions[0]])
	const clientParentGuardianContactMany = useQuery(
		fetching.query.clientParentGuardianContactMany({ clientID, isArchived: parentGuardianContactFilter[0].label === FilterBy.Archived }),
	)

	const isLoading = clientParentGuardianContactArchive.loading || clientParentGuardianContactUnarchive.loading || clientParentGuardianContactMany.loading

	React.useEffect(() => {
		if (clientParentGuardianContactMany.error || !clientParentGuardianContactMany.payload) return
		setClientParentGuardianContacts(clientParentGuardianContactMany.payload)
	}, [clientParentGuardianContactMany.payload, clientParentGuardianContactMany.error])

	const [openParentGuardianContactModal, setOpenParentGuardianContactModal] = React.useState<boolean>(false)
	const [selectedParentGuardianContact, setSelectedParentGuardianContact] = React.useState<ClientParentGuardian>()
	const handleEditParenGuardianContact = (egc: ClientParentGuardian) => {
		setSelectedParentGuardianContact(egc)
		setOpenParentGuardianContactModal(true)
	}
	const [languageOptions, setLanguageOptions] = React.useState<Option[]>([])
	const [countryOptions, setCountryOptions] = React.useState<Option[]>([])
	const { query: languagesQuery } = useQuery(fetching.query.getLanguageAll(), false)
	const { query: countriesQuery } = useQuery(fetching.query.getCountryAll(), false)
	React.useEffect(() => {
		languagesQuery().then((resp) => {
			if (resp.error || !resp.payload) return
			setLanguageOptions(resp.payload.map((l) => ({ id: l.id, label: l.label })))
		})
		countriesQuery().then((resp) => {
			if (resp.error || !resp.payload) return
			setCountryOptions(resp.payload.map((co) => ({ id: co.id, label: co.countryName })))
		})
	}, [languagesQuery, countriesQuery])
	const container = css({
		height: "100%",
		flex: "unset !important",
		minHeight: 0,
		position: isLoading ? "relative" : "unset",
		opacity: isLoading ? 0.5 : 1,
	})
	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "4px",
	})
	const scrollContainer = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
		maxHeight: "100%",
		overflowY: "auto",
		overflowX: "hidden",
	})
	const group = css({
		display: "flex",
		alignContent: "center",
		width: "100%",
	})
	const actionButton = css({
		display: "flex",
		alignContent: "center",
		width: "fit-content",
	})
	return (
		<>
			<ZenCard className={container}>
				{isLoading && (
					<div className={loadingIcon}>
						<StyledSpinnerNext />
					</div>
				)}
				<div className={title}>
					<LabelMedium>Parent guardians</LabelMedium>
					<div className={actionButton}>
						<Select
							onChange={({ value }) => {
								setParentGuardianContactFilter(value as { label: FilterBy; id: FilterBy }[])
							}}
							value={parentGuardianContactFilter}
							clearable={false}
							options={filterOptions}
							overrides={{
								ControlContainer: {
									style: ({ $disabled }) => ({
										backgroundColor: "transparent",
										borderLeftWidth: 0,
										borderTopWidth: 0,
										borderBottomWidth: $disabled ? 0 : "2px",
										borderBottomColor: "black",
										borderRightWidth: 0,
									}),
								},
								Dropdown: {
									style: {
										maxHeight: "400px",
									},
								},
							}}
						/>
						<Spacer style={{ width: "30px" }} />
						{parentGuardianContactFilter[0].label === FilterBy.Active && (
							<ZenButton width="100px" onClick={() => setOpenParentGuardianContactModal(true)}>
								Add
							</ZenButton>
						)}
					</div>
				</div>
				<div className={scrollContainer}>
					{clientParentGuardianContacts.length > 0 ? (
						clientParentGuardianContacts
							.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
							.map((p, i) => (
								<div key={i}>
									<Divider />
									<div className={group}>
										<ClientParentGuardianCard key={p.id} index={i + 1} {...p} />
										<div>
											<div className={actionButton}>
												{parentGuardianContactFilter[0].label === FilterBy.Active && (
													<TooltipButton onClick={() => handleEditParenGuardianContact(p)} tooltip={"Edit parent guardian contact"} iconName={"pencil"} />
												)}
												<TooltipButton
													onClick={() => {
														setArchiveModalOpen(true)
														setSelectedPG(p)
													}}
													tooltip={p.deletedAt ? "Unarchive Parent Guardian" : "Archive Parent Guardian"}
													iconName={p.deletedAt ? "trash-restore-alt" : "trash-alt"}
												/>
											</div>
										</div>
									</div>
								</div>
							))
					) : (
						<NoDataText />
					)}
				</div>
			</ZenCard>
			<ParentGuardianModal
				open={openParentGuardianContactModal}
				setOpen={setOpenParentGuardianContactModal}
				selectedParentGuardianContact={selectedParentGuardianContact}
				setSelectedParentGuardianContact={setSelectedParentGuardianContact}
				languageOptions={languageOptions}
				countryOptions={countryOptions}
				refetch={clientParentGuardianContactMany.query}
				clientID={clientID}
			/>

			{archiveModalOpen && selectedPG && (
				<ZenArchiveModal
					error={clientParentGuardianContactArchive.error || clientParentGuardianContactUnarchive.error}
					payload={clientParentGuardianContactArchive.payload || clientParentGuardianContactUnarchive.payload}
					open={archiveModalOpen}
					onClose={() => setArchiveModalOpen(false)}
					loading={clientParentGuardianContactArchive.loading || clientParentGuardianContactUnarchive.loading}
					message={`Parent Guardian contact - ${selectedPG.firstName} ${selectedPG.lastName}`}
					restoreMode={!!selectedPG?.deletedAt}
					confirmArchive={() => {
						if (selectedPG.deletedAt) {
							clientParentGuardianContactUnarchive.mutate(selectedPG.id).then((resp) => {
								if (resp.error || !resp.payload) return
								clientParentGuardianContactMany.query()
								setArchiveModalOpen(false)
							})
						} else {
							clientParentGuardianContactArchive.mutate(selectedPG.id).then((resp) => {
								if (resp.error || !resp.payload) return
								clientParentGuardianContactMany.query()
								setArchiveModalOpen(false)
							})
						}
					}}
				/>
			)}
		</>
	)
}

interface ParentGuardianModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	refetch: () => Promise<QueryResponse<ClientParentGuardian[]>>
	languageOptions: Option[]
	countryOptions: Option[]
	setSelectedParentGuardianContact: React.Dispatch<React.SetStateAction<ClientParentGuardian | undefined>>
	selectedParentGuardianContact?: ClientParentGuardian
	clientID: string
}
const ParentGuardianModal = (props: ParentGuardianModalProps) => {
	const { clientID, open, setOpen, languageOptions, countryOptions, selectedParentGuardianContact, setSelectedParentGuardianContact, refetch } = props
	const [css] = useStyletron()
	const { control, errors, setValue, getValues, trigger } = useForm()
	const [contactError, setContactError] = React.useState<boolean>(false)

	const clientParentGuardianCreate = useMutation(fetching.mutation.clientParentGuardianContactCreate)
	const clientParentGuardianUpdate = useMutation(fetching.mutation.clientParentGuardianContactUpdate)
	const isLoading = clientParentGuardianCreate.loading || clientParentGuardianUpdate.loading

	React.useEffect(() => {
		if (!selectedParentGuardianContact) return
		setValue("parentGuardianFirstName", selectedParentGuardianContact.firstName)
		setValue("parentGuardianLastName", selectedParentGuardianContact.lastName)
		setValue(
			"parentGuardianRelationship",
			selectedParentGuardianContact.relationship ? [{ id: selectedParentGuardianContact.relationship, label: selectedParentGuardianContact.relationship }] : [],
		)
		setValue("parentGuardianTelephoneNumber", selectedParentGuardianContact.telephoneNumber)
		setValue("parentGuardianMobileNumber", selectedParentGuardianContact.mobileNumber)
		setValue("parentGuardianDateOfBirth", selectedParentGuardianContact.dateOfBirth ? moment(selectedParentGuardianContact.dateOfBirth).toDate() : null)
		setValue("parentGuardianRequiredTranslator", selectedParentGuardianContact.requiresTranslator)
		setValue("parentGuardianCulturallyAndLinguisticallyDiverse", selectedParentGuardianContact.culturallyAndLinguisticallyDiverse)
		setValue(
			"parentGuardianAboriginalOrTorresStraitIslander",
			selectedParentGuardianContact.aboriginalOrTorresStraitIslander
				? [
						{
							id: selectedParentGuardianContact.aboriginalOrTorresStraitIslander,
							label: selectedParentGuardianContact.aboriginalOrTorresStraitIslander,
						},
				  ]
				: [],
		)
		setValue(
			"parentGuardianCountryOfBirthID",
			selectedParentGuardianContact.countryOfBirth
				? [{ id: selectedParentGuardianContact.countryOfBirth.id, label: selectedParentGuardianContact.countryOfBirth.countryName }]
				: [],
		)
		setValue(
			"parentGuardianLanguageSpokenAtHomeID",
			selectedParentGuardianContact.languageSpokenAtHome
				? [{ id: selectedParentGuardianContact.languageSpokenAtHome.id, label: selectedParentGuardianContact.languageSpokenAtHome.label }]
				: [],
		)
	}, [selectedParentGuardianContact, languageOptions, countryOptions, setValue])

	const clearData = () => {
		setValue("parentGuardianFirstName", "")
		setValue("parentGuardianLastName", "")
		setValue("parentGuardianRelationship", [])
		setValue("parentGuardianTelephoneNumber", "")
		setValue("parentGuardianMobileNumber", "")
		setValue("parentGuardianDateOfBirth", null)
		setValue("parentGuardianCountryOfBirthID", [])
		setValue("parentGuardianLanguageSpokenAtHomeID", [])
		setValue("parentGuardianRequiredTranslator", false)
		setValue("parentGuardianCulturallyAndLinguisticallyDiverse", false)
		setValue("parentGuardianAboriginalOrTorresStraitIslander", [])
		setSelectedParentGuardianContact(undefined)
	}
	const onClose = () => {
		clearData()
		setOpen(false)
	}
	const onSubmit = async () => {
		const isValid = await trigger()
		const hasContactError = getValues()["parentGuardianTelephoneNumber"] === "" && getValues()["parentGuardianMobileNumber"] === ""
		setContactError(hasContactError)
		if (!isValid || hasContactError) return

		const currentState = getValues()
		const input: ClientParentGuardianContactInput = {
			clientID,
			firstName: currentState.parentGuardianFirstName,
			lastName: currentState.parentGuardianLastName,
			telephoneNumber: currentState.parentGuardianTelephoneNumber,
			mobileNumber: currentState.parentGuardianMobileNumber,
			relationship: currentState.parentGuardianRelationship[0].id,
			dateOfBirth: currentState.parentGuardianDateOfBirth,
			countryOfBirthID: currentState.parentGuardianCountryOfBirthID[0].id,
			languageSpokenAtHomeID: currentState.parentGuardianLanguageSpokenAtHomeID[0].id,
			requiresTranslator: currentState.parentGuardianRequiredTranslator,
			culturallyAndLinguisticallyDiverse: currentState.parentGuardianCulturallyAndLinguisticallyDiverse,
			aboriginalOrTorresStraitIslander: currentState.parentGuardianAboriginalOrTorresStraitIslander[0].id,
		}
		if (selectedParentGuardianContact) {
			clientParentGuardianUpdate
				.mutate({
					id: selectedParentGuardianContact.id,
					...input,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return
					refetch()
					onClose()
				})
			return
		}
		clientParentGuardianCreate.mutate(input).then((resp) => {
			if (resp.error || !resp.payload) return
			refetch()
			onClose()
		})
	}
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		opacity: isLoading ? 0.5 : 1,
		position: isLoading ? "relative" : "unset",
	})
	const buttonContainer = css({
		paddingLeft: "8px",
		paddingRight: "8px",
	})
	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})
	return (
		<ZenModal
			isOpen={open}
			onClose={onClose}
			overrides={{
				Close: {
					style: {
						display: "none",
					},
				},
			}}
		>
			<ModalBody>
				<form autoComplete="off" className={formStyle}>
					{isLoading && (
						<div className={loadingIcon}>
							<StyledSpinnerNext />
						</div>
					)}
					<LabelMedium>Parent Guardian Contact</LabelMedium>
					<ParentGuardianDetailForm
						control={control}
						inputError={errors}
						contactError={contactError}
						languageOptions={languageOptions}
						countryOptions={countryOptions}
						omitBorder
					/>
					<div className={buttonContainer}>
						<CancelAndSaveButtons cancelFn={onClose} saveFn={onSubmit} />
					</div>
				</form>
			</ModalBody>
		</ZenModal>
	)
}
