import * as React from "react"
import { useStyletron } from "baseui"
import { Divider, Spacer, ZenCard } from "../common"
import { BlockOverrides } from "baseui/block"
import { LabelLarge, LabelSmall } from "baseui/typography"
import { ZenCheckBoxCenteredOverrides, ZenTheme } from "../../themeOverrides"
import { Checkbox } from "baseui/checkbox"
import { ZenClientSelect, ZenUserSelect } from "../zenComponents/zenSelectBox"
import { useForm } from "react-hook-form"
import { Value } from "baseui/select"
import { CancelAndSaveButtons } from "../cancelSaveButtons"
import { Prompt, useHistory } from "react-router-dom"
import { AuthContainer } from "../../controllers/auth"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { fetching } from "../../fetching"
import { FormType, FormTypeName } from "../../types/enums"
import { ClientDetail, Form, UserDetail } from "../../types/types"
import { StyledSpinnerNext } from "baseui/spinner"
import { ErrorNotification } from "../errorBox"
import { ZenButton } from "../zenComponents/zenButtons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useZenToast } from "../zenComponents/useZenToast"

import { routes } from "routes"

const questions = [
	"I found it hard to wind down",
	"I was aware of dryness of my mouth",
	"I couldn't seem to experience any positive feeling at all",
	"I experienced breathing difficulty (eg, excessively rapid breathing, breathlessness in the absence ofphysical exertion)",
	"I found it difficult to work up the initiative to do things",
	"I tended to over-react to situations",
	"I experienced trembling (eg, in the hands)",
	"I felt that I was using a lot of nervous energy",
	"I was worried about situations in which I might panic and make a fool of myself",
	"I felt that I had nothing to look forward to",
	"I found myself getting agitated",
	"I found it difficult to relax",
	"I felt down-hearted and blue",
	"I was intolerant of anything that kept me from getting on with what I was doing",
	"I felt I was close to panic",
	"I was unable to become enthusiastic about anything.",
	"I felt I wasn't worth much as a person",
	"I felt that I was rather touchy",
	"I was aware of the action of my heart in the absence of physical exertion (eg, sense of heart rate increase, heart missing a beat)",
	"I felt scared without any good reason.",
	"I felt that life was meaningless ",
]

const ratingLevel = ["Normal", "Mild", "Moderate", "Severe", "Extremely Severe"]
const depressionLevelBoundary = [9, 13, 20, 27]
const anxietyLevelBoundary = [7, 9, 14, 19]
const stressLevelBoundary = [14, 18, 25, 33]

interface DASS21FormData {
	client: ClientDetail
	worker: UserDetail
	values: number[]
}

export const DASS21Form = () => {
	const [css] = useStyletron()
	const { showToast } = useZenToast()
	const { control, errors, formState, setValue, trigger, getValues } = useForm()
	const { currentUser } = AuthContainer.useContainer()
	const history = useHistory()
	const searchArgs = new URLSearchParams(history.location.search)
	const formID = searchArgs.get("formID")
	const clientID = searchArgs.get("id")
	const [depressionPoints, setDepressionPoints] = React.useState<number>(0)
	const [anxietyPoints, setAnxietyPoints] = React.useState<number>(0)
	const [stressPoints, setStressPoints] = React.useState<number>(0)

	// pre-fill worker
	const prefillWorker = React.useCallback(() => {
		if (formID || !currentUser) return
		setValue("worker", [
			{
				...currentUser,
				label: `${currentUser.firstName} ${currentUser.lastName}`,
			},
		])
	}, [currentUser, formID, setValue])
	React.useEffect(() => {
		prefillWorker()
	}, [prefillWorker])

	// pre-fill client
	const getClient = useParameterizedQuery<ClientDetail>(fetching.query.getClient)
	React.useEffect(() => {
		if (!clientID) return
		getClient
			.query(clientID)
			.then((resp) => {
				if (resp.error || !resp.payload) return
				setValue("client", [
					{
						...resp.payload,
						label: `${resp.payload.firstName} ${resp.payload.lastName}`,
					},
				])
			})
			.catch()
	}, [clientID, setValue]) // eslint-disable-line react-hooks/exhaustive-deps

	const [values, setValues] = React.useState<number[]>(questions.map(() => -1))
	const [check, setCheck] = React.useState(false)
	const ResponsiveLabelOverrides: BlockOverrides = {
		Block: {
			style: {
				fontSize: "15px",
				[`@media only screen and (max-width: 1500px)`]: {
					fontSize: "12px",
				},
			},
		},
	}
	// pre-fill form data
	const formGet = useParameterizedQuery<Form<DASS21FormData>>(fetching.query.getForm)
	React.useEffect(() => {
		if (!formID) return
		formGet.query(formID).then((resp) => {
			if (resp.error || !resp.payload) return
			setFormData(resp.payload.content)
		})
	}, [formID]) // eslint-disable-line react-hooks/exhaustive-deps

	const setFormData = (currentState: DASS21FormData) => {
		setDepressionPoints(0)
		setAnxietyPoints(0)
		setStressPoints(0)
		setValue("client", [
			{
				...currentState.client,
				label: `${currentState.client.firstName} ${currentState.client.lastName}`,
			},
		])
		setValue("worker", [
			{
				...currentState.worker,
				label: `${currentState.worker.firstName} ${currentState.worker.lastName}`,
			},
		])
		setValues(currentState.values)

		// update depression, anxiety and stress points
		currentState.values.forEach((v, i) => {
			if (v < 1) return
			addPoints(i + 1, v)
		})
	}

	// track form state
	const [formDirty, setFormDirty] = React.useState(false)

	React.useEffect(() => {
		if (formState.isDirty || formState.isSubmitting) {
			setFormDirty(true)
		}
	}, [formState.isDirty, formState.isSubmitting])

	React.useEffect(() => {
		if (formState.isSubmitted) {
			setFormDirty(false)
		}
	}, [formState.isSubmitted])

	// add point to depression, anxiety or stress
	const addPoints = (index: number, value: number) => {
		if (value === 0) return
		// add point to related table
		switch (index) {
			// depression question
			case 3:
			case 5:
			case 10:
			case 13:
			case 16:
			case 17:
			case 21:
				setDepressionPoints((dp) => dp + value * 2)
				break
			// anxiety questions
			case 2:
			case 4:
			case 7:
			case 9:
			case 15:
			case 19:
			case 20:
				setAnxietyPoints((ap) => ap + value * 2)
				break
			// stress questions
			case 1:
			case 6:
			case 8:
			case 11:
			case 12:
			case 14:
			case 18:
				setStressPoints((sp) => sp + value * 2)
				break
		}
	}

	const checkValue = (index: number, value: number) => {
		// update value list
		const newValue = [...values]
		if (newValue[index] === value) {
			newValue[index] = -1
			addPoints(index + 1, -value)
			setValues(newValue)
			return
		}
		newValue[index] = value
		let diff = value
		if (values[index] > 0) diff -= values[index]
		addPoints(index + 1, diff)
		setValues(newValue)
		setFormDirty(true)
	}
	const formCreate = useMutation(fetching.mutation.formCreate)
	const formUpdate = useMutation(fetching.mutation.formUpdate)
	const isLoading = formCreate.loading || formUpdate.loading || formGet.loading

	const [isEditMode, setIsEditMode] = React.useState(!formID)
	const cachedDASS21FormData = React.useRef<DASS21FormData>()
	const parseFormContent = (): DASS21FormData => {
		const formData = getValues()
		return {
			client: formData.client[0],
			worker: formData.worker[0],
			values,
		}
	}
	const onSubmit = async () => {
		setCheck(true)
		// check empty fields
		let isComplete = true
		values.forEach((v) => {
			if (v < 0) isComplete = false
		})

		const isValid = await trigger(["worker", "client"])
		if (!isComplete || !isValid) return
		const formData = getValues()
		const content = parseFormContent()

		if (formID) {
			const resp = await formUpdate.mutate({
				id: formID,
				content,
			})

			if (resp.error || !resp.payload) return
			setFormDirty(false)
			setIsEditMode(false)
			showToast("DASS21 document updated successfully.", "positive")
			return
		}

		const resp = await formCreate.mutate({
			input: {
				clientID: formData.client[0].id,
				content,
				date: new Date(),
				type: FormType.DASS21,
				name: `${FormTypeName(FormType.DASS21)} Form | ${formData.client[0].firstName} ${formData.client[0].lastName} | ${new Date().toLocaleDateString(
					undefined,
					{
						day: "numeric",
						month: "numeric",
						year: "numeric",
					},
				)}`,
			},
		})
		if (resp.error || !resp.payload) return
		setFormDirty(false)
		showToast("DASS21 document created successfully.", "positive")
		history.push(`/portal/clients/${formData.client[0].id}#forms`)
	}

	const displayRatingRange = (list: number[], currentIndex: number, currentScore: number): React.ReactNode => {
		let startText = "0"
		let isGreaterThanAndEqualToStartNumber = true
		if (currentIndex > 0) {
			const startNumber = list[currentIndex - 1] + 1
			startText = `${startNumber}`
			isGreaterThanAndEqualToStartNumber = currentScore >= startNumber
		}

		let endText = "+"
		let isLessThanAndEqualToEndNumber = true
		if (currentIndex < list.length) {
			const endNumber = list[currentIndex]
			endText = ` - ${endNumber}`
			isLessThanAndEqualToEndNumber = currentScore <= endNumber
		}

		const shouldHightLight = isGreaterThanAndEqualToStartNumber && isLessThanAndEqualToEndNumber

		return (
			<LabelSmall color={shouldHightLight ? ZenTheme.colors.primaryGreen : "black"} overrides={ResponsiveLabelOverrides}>{`${startText}${endText}`}</LabelSmall>
		)
	}

	const cancelLabel = (): string => {
		if (!formID || isEditMode) return "Cancel"
		return "Exit"
	}

	const cancelFn = () => {
		if (!!formID && isEditMode) {
			// reset data
			if (cachedDASS21FormData.current) setFormData(cachedDASS21FormData.current)
			setFormDirty(false)
			setIsEditMode(false)
			return
		}
		if (!formID && clientID) {
			history.push(`/portal/clients/${clientID}#forms`)
			return
		}
		const client = getValues().client
		if (client && client.length > 0) {
			history.push(`/portal/clients/${client[0].id}#forms`)
			return
		}

		history.push(routes.forms.root)
	}

	const saveLabel = (): string => {
		if (!formID) return "Submit"
		return "Save"
	}

	const readOnly = !!formID && !isEditMode

	const outer = css({
		display: "flex",
		maxHeight: "100%",
		width: "100%",
		overflowY: "auto",
		"@media only screen and (max-width: 1400px)": {
			flexDirection: "column-reverse",
		},
	})
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		position: isLoading ? "relative" : "unset",
		opacity: isLoading ? 0.5 : 1,
	})
	const scoreCardStyle = css({
		maxWidth: "440px",
		"@media only screen and (max-width: 1400px)": {
			maxWidth: "100%",
		},
	})
	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})

	const tableWrapStyle = css({
		maxWidth: "100%",
		overflowX: "auto",
		overflowY: "auto",
	})

	const headerRowStyle = css({
		display: "grid",
		gridTemplateColumns: "40% 15% 15% 15% 15%",
		padding: "8px",
		margin: "0 20px",
		alignItems: "center",
		minWidth: "900px",
	})

	const cellStyle = css({
		maxWidth: "100px",
		textAlign: "center",
		justifySelf: "center",
		paddingLeft: "8px",
		paddingRight: "8px",
	})

	const tableContentStyle = css({
		borderRadius: "3px",
		border: "#D6DBF0 solid 2px",
		marginRight: "10px",
		padding: "20px",
		minWidth: "900px",
	})

	const contentRowStyle = css({
		display: "grid",
		gridTemplateColumns: "40% 15% 15% 15% 15%",
		padding: "8px",
		alignItems: "center",
	})

	const summaryTableContainer = css({
		border: "#D6DBF0 solid 2px",
		padding: "3px",
		borderRadius: "3px",
	})
	const summaryTableRowStyle = css({
		display: "grid",
		gridTemplateColumns: "35% 23% 21% 21%",
	})

	const group = css({
		display: "flex",
		width: "100%",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
		},
	})

	const linkStyle = css({
		color: "blue",
		borderBottom: "1px solid blue",
		cursor: "pointer",
		marginLeft: "4px",
		marginRight: "4px",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
	})

	return (
		<div className={outer}>
			<ZenCard>
				<div className={formStyle}>
					{isLoading && (
						<div className={loadingIcon}>
							<StyledSpinnerNext />
						</div>
					)}
					{formCreate.error && <ErrorNotification messageOrPayload={formCreate.payload} />}
					{formUpdate.error && <ErrorNotification messageOrPayload={formUpdate.payload} />}
					<div className={title}>
						<LabelLarge>Depression, Anxiety and Stress Scale (DASS21)</LabelLarge>
						{!!formID && !isEditMode && (
							<ZenButton
								onClick={() => {
									// save current status
									cachedDASS21FormData.current = parseFormContent()
									setIsEditMode(true)
									setFormDirty(true)
								}}
								height="38px"
								width="100px"
							>
								Edit
							</ZenButton>
						)}
					</div>
					<div className={group}>
						<ZenUserSelect
							label="Worker"
							formRef={control}
							disabled={!!formID}
							formName="worker"
							clearable={false}
							width="100%"
							inputError={errors.worker}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
								},
							}}
						/>
						<Spacer style={{ width: "40px" }} />
						<ZenClientSelect
							label="Client"
							formRef={control}
							disabled={!!formID}
							formName="client"
							clearable={false}
							width="100%"
							inputError={errors.client}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || "Client is required",
								},
							}}
						/>
					</div>
					<LabelSmall
						overrides={{
							Block: {
								style: {
									color: ZenTheme.colors.primaryGrey,
								},
							},
						}}
					>
						For each statement below, please check the box that best represents how you have been feeling in the last week.
					</LabelSmall>
					<div className={tableWrapStyle}>
						<div className={headerRowStyle}>
							<div className={cellStyle}>
								<LabelSmall overrides={ResponsiveLabelOverrides}>Statement</LabelSmall>
							</div>
							<div className={cellStyle}>
								<LabelSmall overrides={ResponsiveLabelOverrides}>Did not apply to me at all</LabelSmall>
							</div>
							<div className={cellStyle}>
								<LabelSmall overrides={ResponsiveLabelOverrides}>Applied to me to some degree or some of the time</LabelSmall>
							</div>
							<div className={cellStyle}>
								<LabelSmall overrides={ResponsiveLabelOverrides}>Applied to me a considerable degree or a good part of the time</LabelSmall>
							</div>
							<div className={cellStyle}>
								<LabelSmall overrides={ResponsiveLabelOverrides}>Applied to me very much or most of the time</LabelSmall>
							</div>
						</div>
						<div className={tableContentStyle}>
							{questions.map((q, i) => (
								<div key={i} className={contentRowStyle}>
									<div className={group}>
										<LabelSmall color={check && values[i] === -1 ? "red" : "black"} marginRight="4px" overrides={ResponsiveLabelOverrides}>{`${
											i + 1
										}. `}</LabelSmall>
										<LabelSmall color={check && values[i] === -1 ? "red" : "black"} overrides={ResponsiveLabelOverrides}>
											{q}
										</LabelSmall>
									</div>
									<div className={cellStyle}>
										<DASS21Checkbox disabled={readOnly} checked={values[i] === 0} onChange={() => checkValue(i, 0)} />
									</div>
									<div className={cellStyle}>
										<DASS21Checkbox disabled={readOnly} checked={values[i] === 1} onChange={() => checkValue(i, 1)} />
									</div>
									<div className={cellStyle}>
										<DASS21Checkbox disabled={readOnly} checked={values[i] === 2} onChange={() => checkValue(i, 2)} />
									</div>
									<div className={cellStyle}>
										<DASS21Checkbox disabled={readOnly} checked={values[i] === 3} onChange={() => checkValue(i, 3)} />
									</div>
								</div>
							))}
						</div>
					</div>
					<CancelAndSaveButtons cancelFn={cancelFn} cancelLabel={cancelLabel()} saveLabel={saveLabel()} saveFn={onSubmit} omitSave={!!formID && !isEditMode} />
					<Prompt when={formDirty} message={"You have unsaved changes, are you sure you want to leave?"} />
				</div>
			</ZenCard>
			<Spacer style={{ width: "15px" }} />
			<ZenCard className={scoreCardStyle}>
				<div className={formStyle}>
					<LabelLarge marginBottom="15px">SCORING</LabelLarge>
					<div className={summaryTableContainer}>
						<div className={summaryTableRowStyle}>
							<LabelSmall overrides={ResponsiveLabelOverrides}>Points</LabelSmall>
							<div className={cellStyle}>
								<LabelSmall overrides={ResponsiveLabelOverrides}>Depression</LabelSmall>
								<LabelSmall overrides={ResponsiveLabelOverrides}>{depressionPoints}</LabelSmall>
							</div>
							<div className={cellStyle}>
								<LabelSmall overrides={ResponsiveLabelOverrides}>Anxiety</LabelSmall>
								<LabelSmall overrides={ResponsiveLabelOverrides}>{anxietyPoints}</LabelSmall>
							</div>
							<div className={cellStyle}>
								<LabelSmall overrides={ResponsiveLabelOverrides}>Stress</LabelSmall>
								<LabelSmall overrides={ResponsiveLabelOverrides}>{stressPoints}</LabelSmall>
							</div>
						</div>
						<Divider style={{ marginTop: "1px" }} />
						<div className={summaryTableRowStyle}>
							<LabelSmall overrides={ResponsiveLabelOverrides}>Rating</LabelSmall>
						</div>
						{ratingLevel.map((rl, i) => (
							<React.Fragment key={i}>
								<Divider style={{ marginTop: "1px" }} />
								<div className={summaryTableRowStyle}>
									<LabelSmall overrides={ResponsiveLabelOverrides}>{rl}</LabelSmall>
									<div className={cellStyle}>{displayRatingRange(depressionLevelBoundary, i, depressionPoints)}</div>
									<div className={cellStyle}>{displayRatingRange(anxietyLevelBoundary, i, anxietyPoints)}</div>
									<div className={cellStyle}>{displayRatingRange(stressLevelBoundary, i, stressPoints)}</div>
								</div>
							</React.Fragment>
						))}
					</div>
					<LabelSmall marginTop="15px" overrides={ResponsiveLabelOverrides}>
						Provided to you by
						<span className={linkStyle} onClick={() => window.open("https://www.depression-test.net/")}>
							Depression-Test.net
						</span>
						for educational purposes only. If there is an indication that you might be depressed, please check out the site for additional information, tools
						and support.
					</LabelSmall>

					<LabelSmall marginTop="8px" overrides={ResponsiveLabelOverrides}>
						If there is an indication that It might be serious then please seek the help of a mental health professional.
					</LabelSmall>
				</div>
			</ZenCard>
		</div>
	)
}

interface DASS21CheckboxProps {
	disabled?: boolean
	checked: boolean
	onChange: () => void
}
const DASS21Checkbox = (props: DASS21CheckboxProps) => {
	const { disabled, checked, onChange } = props
	const [css] = useStyletron()
	const checkStyle = css({
		textAlign: "center",
		color: ZenTheme.colors.primaryGreen,
	})
	if (disabled) {
		if (checked) {
			return (
				<div className={checkStyle}>
					<FontAwesomeIcon icon={["fas", "check"]} />
				</div>
			)
		}
		return <span />
	}
	return <Checkbox overrides={ZenCheckBoxCenteredOverrides} disabled={disabled} checked={checked} onChange={onChange} />
}
