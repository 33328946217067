import * as React from "react"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { FormControl } from "baseui/form-control"
import { Option, Select, Value } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import { Textarea } from "baseui/textarea"
import { LabelMedium, LabelSmall } from "baseui/typography"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { RouteComponentProps, useHistory } from "react-router-dom"

import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { borderRadiusStyle, ZenTheme } from "../../themeOverrides"
import { FilterBy, SortBy, SortDir } from "../../types/enums"
import { Note, RolePermission, SearchTextMinLength, ZenDateFormat } from "../../types/types"
import { UserLabel } from "../callLogs/subComponents"
import { useZenToast } from "../zenComponents/useZenToast"
import { SearchAndFilter, ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { ZenButton } from "../zenComponents/zenButtons"
import { routes } from "routes"
import { TooltipButton } from "../tooltipButton"
import { ZenTextArea } from "../zenComponents/zenInput"
import { useForm } from "react-hook-form"
import { CancelAndSaveButtons } from "../cancelSaveButtons"
import { RevisionListModal } from "../sessions/sessionNoteComponent"
import moment from "moment/moment"

/* eslint-disable @typescript-eslint/no-var-requires */
const MicRecorder = require("mic-recorder-to-mp3")

interface NoteListProps {
	clientID: string
	search?: string
	limit?: number
	offset?: number
	filter?: Value
	setOffset?: (value: number) => void
	headerOptions?: React.ReactNode
	onArchive?: (note: Note) => void
	triggerQuery?: boolean
}

export const NoteList = (props: NoteListProps) => {
	const history = useHistory()
	const { clientID, headerOptions, triggerQuery } = props
	const limit = props.limit || 20
	const offset = props.offset || 0

	const { payload, loading, error, query } = useQuery<{ notes: Note[]; total: number }>(
		fetching.query.getClientNotesMany({
			search: {
				search: props.search && props.search.length >= SearchTextMinLength ? props.search : undefined,
				filterBy: props.filter && props.filter[0]?.id ? props.filter[0].id.toString() : FilterBy.Active,
				sortBy: SortBy.DateCreated,
				sortDir: SortDir.Descending,
			},
			limit,
			offset,
			clientID,
		}),
	)

	React.useEffect(() => {
		if (triggerQuery !== undefined) query()
	}, [triggerQuery, query])

	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
	})
	const title = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "15px",
	})
	const scrollable = css({
		height: "100%",
		overflowX: "hidden",
		overflowY: "auto",
	})

	const onExitPage = () => {
		if (clientID) {
			history.push(`/portal/clients/${clientID}`)
			return
		}
		history.push(routes.clients.root)
	}

	const showMenu = !history.location.pathname.endsWith("/notes")

	return (
		<div className={container}>
			<div className={title}>
				<LabelMedium marginRight="12px">Notes</LabelMedium>
				{error && <ErrorNotification messageOrPayload="Fail to load the notes" closeable />}
				{loading && <StyledSpinnerNext size="24px" />}
				{headerOptions}
			</div>
			<div className={scrollable}>
				{!loading && !payload && <div>Failed to load notes</div>}
				{payload &&
					payload.notes &&
					payload.notes.map((note) => <ClientNote key={`note-${note.id}`} note={note} onArchive={props.onArchive} refetchNotes={query} />)}
			</div>
			{showMenu ? (
				<Block display="flex" marginTop="15px" justifyContent="space-between">
					<ZenButton altKind="tertiary" onClick={() => history.push(history.location.pathname + "/notes")}>
						View all
					</ZenButton>
					<ZenButton onClick={() => history.push(history.location.pathname + "/notes")}>Create Note</ZenButton>
				</Block>
			) : (
				<Block marginTop="15px">
					<ZenButton altKind={"secondary"} type="button" onClick={onExitPage}>
						Back
					</ZenButton>
				</Block>
			)}
		</div>
	)
}

export const NotesPage = (props: RouteComponentProps<{ id: string }>) => {
	const clientID = props.match.params.id

	const { showToast } = useZenToast()

	const [search, setSearch] = React.useState("")
	const [filter, setFilter] = React.useState<Value>([])
	const [offset, setOffset] = React.useState(0)

	// Add notes
	const [recordAudio, setRecordAudio] = React.useState(false)
	const [recording, setRecording] = React.useState(false)
	const [audioFile, setAudioFile] = React.useState<File>()
	const [showAudioError, setShowAudioError] = React.useState<boolean>(false)

	const [content, setContent] = React.useState("")
	const [triggerQuery, setTriggerQuery] = React.useState<boolean>(false)
	const { mutate: addNote, loading: savingNote, error: saveNoteError } = useMutation<Note>(fetching.mutation.clientNoteAdd)
	const uploadImage = useMutation(fetching.mutation.fileUpload)
	const [check, setCheck] = React.useState<boolean>(false)

	const clearData = () => {
		setSelectedNoteType(undefined)
		setCheck(false)
		setContent("")
		setAudioFile(undefined)
	}
	const onSaveNote = async () => {
		setCheck(true)
		if (!selectedNoteType?.length || (!recordAudio && !content) || (recordAudio && (recording || !audioFile)) || savingNote || uploadImage.loading) return
		const noteTypeID = selectedNoteType[0].id?.toString() || ""
		if (recordAudio) {
			// Add audio note
			if (!audioFile) return

			const result = await uploadImage.mutate({ file: audioFile })
			if (!result.payload) return

			const resp = await addNote({ clientID, noteTypeID, audioBlobID: result.payload.fileID })

			if (resp.payload) setTriggerQuery((t) => !t)
			showToast("Audio Note created successfully.", "positive")

			clearData()
			return
		}

		// Add text note
		const resp = await addNote({ clientID, noteTypeID, content })
		showToast("Note created successfully.", "positive")

		if (resp.payload) setTriggerQuery((t) => !t)

		clearData()
	}

	const recorder = React.useMemo(() => new MicRecorder({ bitRate: 128 }), [])

	const toggleRecording = () => {
		if (recording) {
			// stop recording
			setRecording(false)

			recorder
				.stop()
				.getMp3()
				.then(([buffer, blob]: any) => {
					const file = new File(buffer, "recording.mp3", {
						type: blob.type,
						lastModified: Date.now(),
					})
					setAudioFile(file)
				})
				.catch(() => {
					alert("We could not retrieve your message")
				})
			return
		}

		// start recording
		setRecording(true)
		setAudioFile(undefined)

		recorder.start().catch(() => {
			setShowAudioError(true)
			setRecording(false)
		})
	}

	// Manage notes
	const { mutate: archiveNote, loading: archivingNote, error: archiveNoteError } = useMutation<Note>(fetching.mutation.noteArchive)
	const { mutate: unarchiveNote, loading: unarchivingNote, error: unarchiveNoteError } = useMutation<Note>(fetching.mutation.noteUnarchive)
	const { hasPermission } = AuthContainer.useContainer()

	const onArchive = async (note: Note) => {
		if (archivingNote || unarchivingNote) return
		if (note.deletedAt) {
			const resp = await unarchiveNote({ id: note.id })
			if (resp.payload) setTriggerQuery((t) => !t)
			showToast("Note unarchived successfully.", "positive")
			return
		}
		const resp = await archiveNote({ id: note.id })
		if (resp.payload) setTriggerQuery((t) => !t)
		showToast("Note archived successfully.", "positive")
	}

	// note type list
	const [noteTypeOptions, setNoteTypeOptions] = React.useState<Option[]>([])
	const [selectedNoteType, setSelectedNoteType] = React.useState<Value>()
	const noteTypeMany = useQuery(fetching.query.noteTypeMany(true))
	React.useEffect(() => {
		if (noteTypeMany.loading || noteTypeMany.error || !noteTypeMany.payload) return
		setNoteTypeOptions(noteTypeMany.payload.map((nt) => ({ id: nt.id, label: nt.name })))
	}, [noteTypeMany.payload, noteTypeMany.error, noteTypeMany.loading])

	const showRecordButtonText = (): string => {
		if (recording) {
			return "Stop Audio Recording"
		}
		if (audioFile) {
			return "Re-record Audio"
		}
		return "Start Audio Recording"
	}

	// Styling
	const [css, theme] = useStyletron()
	const containerStyle = css({
		display: "flex",
		height: "100%",
	})
	const listStyle = css({
		display: "flex",
		justifyContent: "space-between",
		width: "50%",
		minWidth: "400px",
	})
	const addNoteStyle = css({
		height: "fit-content",
		width: "unset",
		minWidth: "30%",
		marginLeft: "14px",
	})
	const descStyle = css({
		fontSize: "14px",
		color: theme.colors.contentTertiary,
		marginTop: "14px",
		marginBottom: "6px",
	})
	const footerStyle = css({
		marginTop: "14px",
		display: "flex",
		justifyContent: "space-between",
	})
	const audioPlayerStyle = css({
		width: "100%",
		marginTop: "10px",
		textAlign: "center",
		outline: "none",
	})

	return (
		<div className={containerStyle}>
			<ZenCard className={listStyle}>
				<NoteList
					clientID={clientID}
					search={search}
					offset={offset}
					filter={filter}
					setOffset={setOffset}
					headerOptions={<SearchAndFilter search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} alignRight />}
					onArchive={hasPermission(RolePermission.NoteArchive) ? onArchive : undefined}
					triggerQuery={triggerQuery}
				/>
			</ZenCard>
			<ZenCard className={addNoteStyle}>
				<LabelMedium>Add Note</LabelMedium>
				<FormControl
					label={<LabelSmall>Type</LabelSmall>}
					error={check && !selectedNoteType?.length ? "note type is required" : ""}
					overrides={{
						ControlContainer: {
							style: {
								marginBottom: 0,
							},
						},
					}}
				>
					<Select
						options={noteTypeOptions}
						onChange={(v) => setSelectedNoteType(v.value)}
						value={selectedNoteType}
						overrides={{
							ControlContainer: {
								style: ({ $disabled }) => ({
									backgroundColor: "transparent",
									borderLeftWidth: 0,
									borderTopWidth: 0,
									borderBottomWidth: $disabled ? 0 : "2px",
									borderBottomColor: theme.colors.black,
									borderRightWidth: 0,
								}),
							},
							Dropdown: {
								style: {
									maxHeight: "400px",
								},
							},
						}}
					/>
				</FormControl>
				{recordAudio ? (
					<div>
						<div className={descStyle}>Record notes.</div>
						{check && !audioFile && <LabelSmall color={ZenTheme.colors.negative300}>record file is required</LabelSmall>}
						<ZenButton width={"100%"} onClick={toggleRecording} disabled={savingNote || uploadImage.loading}>
							{showRecordButtonText()}
						</ZenButton>
						{audioFile && (
							<audio controls src={URL.createObjectURL(audioFile)} className={audioPlayerStyle} controlsList="nodownload">
								Your browser does not support the <code>audio</code> element.
							</audio>
						)}
						{!audioFile && <div className={audioPlayerStyle}>{recording ? "Recording..." : "..."}</div>}
						{showAudioError && <ErrorNotification messageOrPayload="Failed to record note, please check if your recording device is connected." />}
					</div>
				) : (
					<div>
						<div className={descStyle}>Enter notes, be very descriptive.</div>
						<Textarea
							value={content}
							onChange={(e) => setContent(e.currentTarget.value)}
							placeholder="Enter Notes"
							disabled={savingNote}
							overrides={{
								Input: {
									style: {
										resize: "vertical",
										height: "300px",
										maxHeight: "800px",
										minHeight: "100px",
									},
								},
								InputContainer: {
									style: {
										borderTopColor: check && !content.length ? ZenTheme.colors.negative400 : "#C1C1C1",
										borderBottomColor: check && !content.length ? ZenTheme.colors.negative400 : "#C1C1C1",
										borderLeftColor: check && !content.length ? ZenTheme.colors.negative400 : "#C1C1C1",
										borderRightColor: check && !content.length ? ZenTheme.colors.negative400 : "#C1C1C1",
										borderTopStyle: "solid",
										borderLeftStyle: "solid",
										borderBottomStyle: "solid",
										borderRightStyle: "solid",
										borderTopWidth: "1px",
										borderLeftWidth: "1px",
										borderBottomWidth: "1px",
										borderRightWidth: "1px",
										...borderRadiusStyle,
									},
								},
							}}
						/>
						{check && !content.length && <LabelSmall color={ZenTheme.colors.negative300}>Content is required</LabelSmall>}
					</div>
				)}
				<div className={footerStyle}>
					<ZenButton
						disabled={savingNote || uploadImage.loading}
						onClick={() => {
							if (recordAudio) setRecording(false)
							setShowAudioError(false)
							setRecordAudio(!recordAudio)
						}}
					>
						{recordAudio ? "Write Note..." : "Record Audio..."}
					</ZenButton>
					<ZenButton onClick={onSaveNote} isLoading={savingNote || uploadImage.loading}>
						Save Note
					</ZenButton>
				</div>
				{saveNoteError && <ErrorNotification messageOrPayload={"Failed to add note"} closeable />}
				{archiveNoteError && <ErrorNotification messageOrPayload={"Failed to archive the note"} closeable />}
				{unarchiveNoteError && <ErrorNotification messageOrPayload={"Failed to unarchive the note"} closeable />}
			</ZenCard>
		</div>
	)
}

interface ClientNoteProps {
	refetchNotes: () => Promise<
		QueryResponse<{
			notes: Note[]
			total: number
		}>
	>
	note: Note
	onArchive?: (note: Note) => void
}

const ClientNote = (props: ClientNoteProps) => {
	const { note, onArchive, refetchNotes } = props
	const [css, theme] = useStyletron()
	const [editMode, setEditMode] = React.useState(false)
	const { control, errors, trigger, getValues, setValue } = useForm()
	const { currentUser } = AuthContainer.useContainer()
	const containerStyle = css({
		marginBottom: "12px",
		padding: "12px",
		fontSize: "14px",
		display: "flex",
		flexDirection: "column",
		borderRadius: "3px",
		backgroundColor: theme.colors.backgroundSecondary,
	})
	const headerStyle = css({
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "10px",
	})
	const dateStyle = css({
		...theme.typography.LabelSmall,
		color: theme.colors.contentTertiary,
		marginLeft: "4px",
	})

	const contentStyle = css({
		wordWrap: "break-word",
		whiteSpace: "pre-line",
	})

	const audioPlayerStyle = css({
		width: "100%",
		outline: "none",
	})

	const group = css({
		display: "flex",
		alignItems: "center",
	})

	const [showHistoryNotes, setShowHistoryNotes] = React.useState(false)

	// always display the latest note
	const displayNote = React.useMemo(() => {
		if (!note.revisions) return
		if (note.revisions.length === 0) return
		setValue("content", note.revisions[0].content)
		return note.revisions[0]
	}, [note.revisions, setValue])

	const clientNoteEdit = useMutation(fetching.mutation.clientNoteEdit)
	const onSubmit = async () => {
		const isValid = await trigger()
		if (!isValid) return
		clientNoteEdit
			.mutate({
				id: note.id,
				content: getValues().content,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				refetchNotes()
				setEditMode(false)
			})
	}

	const actionButton = () => {
		if (editMode) return null
		if (note.deletedAt) {
			if (onArchive)
				return (
					<TooltipButton
						onClick={(e) => {
							e.stopPropagation()
							onArchive(note)
						}}
						tooltip={"Restore"}
						iconName={"trash-restore-alt"}
					/>
				)
			return null
		}
		return (
			<>
				{note.poster.id === currentUser?.id && displayNote && !displayNote.audioURL && displayNote.content && (
					<>
						<TooltipButton
							onClick={(e) => {
								e.stopPropagation()
								setShowHistoryNotes(true)
							}}
							tooltip="history"
							iconName="history"
						/>
						<TooltipButton
							onClick={(e) => {
								e.stopPropagation()
								setEditMode(true)
							}}
							tooltip="Edit"
							iconName="edit"
						/>
					</>
				)}

				{onArchive && (
					<TooltipButton
						onClick={(e) => {
							e.stopPropagation()
							onArchive(note)
						}}
						tooltip={"Archive"}
						iconName={"trash-alt"}
					/>
				)}
			</>
		)
	}

	const displayTextContent = () => {
		if (editMode) {
			return (
				<>
					<ZenTextArea nameRef="content" formRef={control} formRules={{ required: "content is required" }} inputError={errors.content} />
					<CancelAndSaveButtons cancelFn={() => setEditMode(false)} saveFn={onSubmit} />
				</>
			)
		}
		return <div className={contentStyle}>{displayNote?.content}</div>
	}

	return (
		<div className={containerStyle}>
			<div className={headerStyle}>
				<div className={group}>
					<UserLabel data={note.poster} />
					<LabelSmall marginLeft="3px">{`- ${note.type.name} Note`}</LabelSmall>
				</div>
				<div className={group}>
					{actionButton()}
					<div className={dateStyle}>{moment(note.createdAt).format(ZenDateFormat)}</div>
				</div>
			</div>
			{displayTextContent()}
			{displayNote && displayNote.audioURL && (
				<audio controls src={displayNote.audioURL} className={audioPlayerStyle} controlsList="nodownload">
					Your browser does not support the <code>audio</code> element.
				</audio>
			)}
			<RevisionListModal isOpen={showHistoryNotes} setIsOpen={setShowHistoryNotes} revisions={note.revisions} />
		</div>
	)
}
