import * as React from "react"
import moment from "moment-timezone"
import { useStyletron } from "baseui"
import { useQuery } from "react-fetching-library"
import { LabelMedium, LabelXSmall } from "baseui/typography"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ZenTheme } from "../../themeOverrides"
import { SortDir, SupportType } from "../../types/enums"
import { SelectedItemCard } from "../zenComponents/zenSelectBox"
import { fetching } from "../../fetching"
import { ErrorNotification } from "../errorBox"
import { SessionListItem } from "fetching/query"
import { PortalContainer } from "../../controllers/portal"

interface SessionPanelProps {
	clientID?: string
	workerID?: string
	showPastSessions: boolean
	isCancelled?: boolean
	triggerQuery?: boolean
	nullOnEmpty?: boolean
	renderName?: string
}
export const SessionPanel = (props: SessionPanelProps) => {
	const { clientID, workerID, showPastSessions, isCancelled, triggerQuery, nullOnEmpty, renderName } = props
	const [css] = useStyletron()
	const [rows, setRows] = React.useState<SessionListItem[]>([])
	const { payload, loading, error, query } = useQuery(
		workerID
			? fetching.query.getSessionMany({
					search: {
						workerID,
						sortBy: "StartTime",
						sortDir: showPastSessions ? SortDir.Descending : SortDir.Ascending,
					},
					limit: 10,
					offset: 0,
					showPastSessions: showPastSessions,
					isCancelled,
			  })
			: fetching.query.getClientSessionMany({
					search: {
						clientID,
						workerID,
						sortBy: "StartTime",
						sortDir: showPastSessions ? SortDir.Descending : SortDir.Ascending,
					},
					limit: 10,
					offset: 0,
					showPastSessions: showPastSessions,
					isCancelled,
			  }),
		false, // disable initial query
	)

	React.useEffect(() => {
		if (!clientID && !workerID) return
		query()
	}, [clientID, workerID, triggerQuery, query])

	React.useEffect(() => {
		if (loading || error || !payload) return
		setRows(payload.sessions)
	}, [payload, loading, error])

	const container = css({
		display: "flex",
		flexDirection: "column",
	})
	if (rows.length === 0) {
		if (nullOnEmpty) return null
		return <LabelMedium>N/A</LabelMedium>
	}
	return (
		<div className={container}>
			{renderName && <h4>{renderName}</h4>}
			{error && <ErrorNotification messageOrPayload={payload} />}
			{rows.map((r) => (
				<SessionCard key={r.id} showPastSessions={showPastSessions} {...r} showClient={!!workerID} item={r} />
			))}
		</div>
	)
}

interface SessionCardProps {
	showPastSessions: boolean
	showClient: boolean
	item: SessionListItem
}
const SessionCard = (props: SessionCardProps) => {
	const { showPastSessions, item, showClient } = props
	const [css] = useStyletron()
	const history = useHistory()
	const { timezone } = PortalContainer.useContainer()

	const showState = () => {
		if (!item.supportType) return <LabelXSmall color={ZenTheme.colors.iconColour}>Pending</LabelXSmall>

		switch (item.supportType) {
			case SupportType.DidNotAttend:
			case SupportType.CancelWithIn24HRS:
				return <FontAwesomeIcon color={ZenTheme.colors.dark} icon={["fas", "times-circle"]} />
			default:
				return <FontAwesomeIcon color={ZenTheme.colors.primaryGreen} icon={["fas", "check-circle"]} />
		}
	}

	const container = css({
		display: "flex",
		flexDirection: "column",
		padding: "15px",
		backgroundColor: ZenTheme.colors.lightGrey,
		cursor: "pointer",
		marginBottom: "10px",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column",
	})
	const time = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: "8px",
	})
	const row = css({
		marginBottom: "15px",
	})
	let firstClient
	if (item.clients.length > 0) {
		firstClient = {
			firstName: item.clients[0].firstName,
			lastName: item.clients[0].lastName,
			avatarURL: item.clients[0].avatarURL,
		}
	}

	const mentor = item.mentors && item.mentors.length > 0 ? item.mentors[0] : undefined

	const clientExists =
		(firstClient?.firstName !== "" || firstClient?.lastName !== "") && (!!firstClient?.firstName || !!firstClient?.lastName || !!firstClient?.avatarURL)

	return (
		<div className={container} onClick={() => history.push(`/portal/sessions/${item.identifier}`)}>
			<div className={title}>
				{mentor && (
					<div className={row}>
						<SelectedItemCard firstName={mentor.firstName} lastName={`${mentor.lastName} (Mentor)`} avatarUrl={mentor.avatarURL} />
					</div>
				)}
				{showClient && clientExists && firstClient ? (
					<div className={row}>
						<SelectedItemCard firstName={firstClient.firstName} lastName={firstClient.lastName} avatarUrl={firstClient.avatarURL} />
					</div>
				) : (
					"Non-billable Session"
				)}
			</div>
			<LabelMedium marginTop="8px">{item.location}</LabelMedium>
			<div className={time}>
				<LabelMedium>{`${moment(item.startTime).tz(timezone.id).format("DD MMMM hh:mm a")} - ${moment(item.endTime)
					.tz(timezone.id)
					.format("hh:mm a")}`}</LabelMedium>
				{showPastSessions && showState()}
			</div>
		</div>
	)
}
