import * as React from "react"
import { useStyletron } from "baseui"
import { Checkbox } from "baseui/checkbox"
import { DatePicker } from "baseui/datepicker"
import { FormControl } from "baseui/form-control"
import { LabelLarge } from "baseui/typography"
import { Controller, useFormContext } from "react-hook-form"

import { ZenCheckBoxOverrides, ZenDatepickerOverrides, ZenFormControlOverrides } from "../../../themeOverrides"
import { WellbeingFormData } from "./wellbeingPlan"

interface WellbeingConfirmationProps {
	disabled?: boolean
}

export const WellbeingConfirmation = (props: WellbeingConfirmationProps) => {
	const { disabled } = props
	const { control } = useFormContext<WellbeingFormData>()
	const [css] = useStyletron()
	const dateStyle = css({
		maxWidth: "250px",
	})
	const checkBoxSpreadStyle = css({
		height: "120px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-around",
	})

	return (
		<div>
			<LabelLarge $style={{ fontWeight: "bold" }}>Confirmation</LabelLarge>

			<div className={dateStyle}>
				<FormControl label="Date wellbeing plan was created" overrides={ZenFormControlOverrides}>
					<Controller
						name={`confirmation.dateCreated`}
						control={control}
						render={({ value, onChange }) => (
							<DatePicker
								formatString={"dd/MM/yyyy"}
								onChange={({ date }) => onChange(date)}
								value={value ? new Date(value) : new Date()}
								disabled={disabled}
								overrides={ZenDatepickerOverrides}
							/>
						)}
					/>
				</FormControl>

				<FormControl label="Date wellbeing plan will be reviewed" overrides={ZenFormControlOverrides}>
					<Controller
						name={`confirmation.dateToBeReviewed`}
						control={control}
						render={({ value, onChange }) => (
							<DatePicker
								formatString={"dd/MM/yyyy"}
								onChange={({ date }) => onChange(date)}
								value={value ? new Date(value) : new Date()}
								disabled={disabled}
								overrides={ZenDatepickerOverrides}
							/>
						)}
					/>
				</FormControl>
			</div>

			<FormControl label="Client Involvement" overrides={ZenFormControlOverrides}>
				<div className={checkBoxSpreadStyle}>
					<Controller
						name={`confirmation.clientParticipated`}
						control={control}
						render={({ onChange, onBlur, value, name }) => (
							<Checkbox
								onBlur={onBlur}
								onChange={(e) => onChange(e.currentTarget.checked)}
								checked={value}
								name={name}
								overrides={ZenCheckBoxOverrides}
								disabled={disabled}
							>
								They have actively participated in the development of this Wellbeing Plan.
							</Checkbox>
						)}
					/>
					<Controller
						name={`confirmation.clientReviewed`}
						control={control}
						render={({ onChange, onBlur, value, name }) => (
							<Checkbox
								onBlur={onBlur}
								onChange={(e) => onChange(e.currentTarget.checked)}
								checked={value}
								name={name}
								overrides={ZenCheckBoxOverrides}
								disabled={disabled}
							>
								They have had an opportunity to review it and ask questions.
							</Checkbox>
						)}
					/>
					<Controller
						name={`confirmation.clientProvidedWithCopy`}
						control={control}
						render={({ onChange, onBlur, value, name }) => (
							<Checkbox
								onBlur={onBlur}
								onChange={(e) => onChange(e.currentTarget.checked)}
								checked={value}
								name={name}
								overrides={ZenCheckBoxOverrides}
								disabled={disabled}
							>
								They have been provided with a paper copy to keep for Their reference.
							</Checkbox>
						)}
					/>
				</div>
			</FormControl>

			<FormControl label="If applicable, together we have completed a:" overrides={ZenFormControlOverrides}>
				<div className={checkBoxSpreadStyle}>
					<Controller
						name={`confirmation.completedRiskAssessment`}
						control={control}
						render={({ onChange, onBlur, value, name }) => (
							<Checkbox
								onBlur={onBlur}
								onChange={(e) => onChange(e.currentTarget.checked)}
								checked={value}
								name={name}
								overrides={ZenCheckBoxOverrides}
								disabled={disabled}
							>
								Risk Assessment
							</Checkbox>
						)}
					/>
					<Controller
						name={`confirmation.completedSafetyPlan`}
						control={control}
						render={({ onChange, onBlur, value, name }) => (
							<Checkbox
								onBlur={onBlur}
								onChange={(e) => onChange(e.currentTarget.checked)}
								checked={value}
								name={name}
								overrides={ZenCheckBoxOverrides}
								disabled={disabled}
							>
								Safety Plan
							</Checkbox>
						)}
					/>
					<Controller
						name={`confirmation.completedCommunicationAgreement`}
						control={control}
						render={({ onChange, onBlur, value, name }) => (
							<Checkbox
								onBlur={onBlur}
								onChange={(e) => onChange(e.currentTarget.checked)}
								checked={value}
								name={name}
								overrides={ZenCheckBoxOverrides}
								disabled={disabled}
							>
								Communication Agreement
							</Checkbox>
						)}
					/>
				</div>
			</FormControl>
		</div>
	)
}
