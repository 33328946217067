import * as React from "react"
import { useStyletron } from "baseui"
import { ButtonKind, ZenButton } from "./zenComponents/zenButtons"

interface CancelAndSaveButtonsProps {
	cancelLabel?: string
	cancelFn?: () => void
	saveLabel?: string
	saveButtonKind?: ButtonKind
	saveFn?: () => void
	disabled?: boolean
	cancelFnDisabled?: boolean
	saveFnDisabled?: boolean
	width?: string
	isLoading?: boolean
	buttonWidths?: string | "wrap"
	omitSave?: boolean
	omitCancel?: boolean
}
export const CancelAndSaveButtons = (props: CancelAndSaveButtonsProps) => {
	const { cancelLabel, cancelFn, saveLabel, saveFn, disabled, width, isLoading, omitSave, buttonWidths, cancelFnDisabled, saveFnDisabled, omitCancel } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		justifyContent: omitCancel ? "flex-end" : "space-between",
		alignItems: "center",
		marginTop: "15px",
		width: width || "unset",
	})

	const handleCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault()
		if (cancelFn) cancelFn()
	}

	let newButtonWidths: string | undefined = "20%"
	if (buttonWidths === "wrap") {
		newButtonWidths = undefined
	} else if (buttonWidths) {
		newButtonWidths = buttonWidths
	}

	return (
		<div className={container}>
			{!omitCancel && (
				<ZenButton width={newButtonWidths} altKind={"secondary"} disabled={disabled || cancelFnDisabled} type="button" onClick={handleCancel}>
					{cancelLabel || "Cancel"}
				</ZenButton>
			)}

			{!omitSave && (
				<ZenButton
					altKind={props.saveButtonKind}
					width={newButtonWidths}
					isLoading={isLoading}
					disabled={disabled || saveFnDisabled}
					type={saveFn ? "button" : "submit"}
					onClick={
						saveFn
							? (e) => {
									e.preventDefault()
									saveFn()
							  }
							: undefined
					}
				>
					{saveLabel || "Save"}
				</ZenButton>
			)}
		</div>
	)
}
