import * as React from "react"
import { useStyletron } from "baseui"
import { useQuery } from "react-fetching-library"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { ErrorNotification } from "../../components/errorBox"
import { Loading } from "../../components/loading"
import { WorkerDetail } from "../../components/workers/workerDetail"
import { WorkerInfoEditForm } from "../../components/workers/workerEditForm"
import { AuthContainer } from "../../controllers/auth"
import { query } from "../../fetching/query"
import { RolePermission, UserDetail } from "../../types/types"
import { Responsive, WidthProvider } from "react-grid-layout"

const ResponsiveGridLayout = WidthProvider(Responsive)

export const WorkerEdit = (props: RouteComponentProps<{ workerID: string }>) => {
	const id = props.match.params.workerID
	const [css] = useStyletron()
	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const { error, loading, payload } = useQuery<UserDetail>(query.getUser(id))
	const container = css({
		display: "flex",
		maxHeight: "100%",
		width: "100%",
		overflowY: "auto",
	})

	// if current user is not admin and current user is not the user of the page, then redirect
	if (!currentUser || (currentUser.id !== id && !hasPermission(RolePermission.UserUpdate))) return <Redirect to={`/portal/workers/${id}`} />
	if (loading || !payload) return <Loading />
	if (error) return <ErrorNotification messageOrPayload={payload} />
	return (
		<div className={container}>
			<ResponsiveGridLayout style={{ width: "100%" }} layouts={layouts}>
				<div key="worker-detail">
					<WorkerDetail editMode {...payload} />
				</div>
				<div key="worker-edit-form">
					<WorkerInfoEditForm {...payload} />
				</div>
			</ResponsiveGridLayout>
		</div>
	)
}

const layouts = {
	lg: [
		{
			w: 5,
			h: 5,
			x: 0,
			y: 0,
			i: "worker-detail",
			moved: false,
			static: true,
		},
		{
			w: 5,
			h: 5,
			x: 5,
			y: 0,
			i: "worker-edit-form",
			moved: false,
			static: true,
		},
	],
	sm: [
		{
			w: 4,
			h: 5,
			x: 0,
			y: 0,
			i: "worker-detail",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 5,
			x: 0,
			y: 5,
			i: "worker-edit-form",
			moved: false,
			static: true,
		},
	],
}
