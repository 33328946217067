import * as React from "react"
import { useStyletron } from "baseui"
import { Value } from "baseui/select"
import { AttendeeBlock } from "components/sessions/newGroupAppointment/groupSessionAttendeeInfoForm"
import moment from "moment-timezone"
import { useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { AuthContainer } from "../../../../controllers/auth"
import { PortalContainer } from "../../../../controllers/portal"
import { fetching } from "../../../../fetching"
import { useCheckWorkerAvailability } from "../../../../helpers/useCheckWorkerAvailability"
import { alignDate, setTimezoneToTime, snakeToTitle } from "../../../../helpers/utils"
import { AddedWorker, Client, RolePermission, TZString, ZenDateTimeFormat } from "../../../../types/types"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { AvailableCheck, Divider, Spacer, ZenCard } from "../../../common"
import { ErrorNotification } from "../../../errorBox"
import { useZenToast } from "../../../zenComponents/useZenToast"
import { ZenButton } from "../../../zenComponents/zenButtons"
import { ZenInput } from "../../../zenComponents/zenInput"
import { ZenClientSelect, ZenPlaceSelect, ZenSelect, ZenTimezoneSelect, ZenUserSelect } from "../../../zenComponents/zenSelectBox"
import { ZenDatePicker, ZenTimePicker } from "../../../zenComponents/zenTime"
import { EDUCATION_YEAR_GROUP } from "../../newGroupAppointment/groupSessionNotesForm"
import { AddUserBlock } from "../../newGroupAppointment/groupSessionWorkerDetailForm"
import { SessionFundingSourceForm } from "../../SessionFundingSource"
import { SessionViewProps } from "../sessionInfo"
import { SessionGroupInput } from "../../../../fetching/inputType"
import { ZenModal } from "../../../zenComponents/zenModal"
import { ZenTheme } from "../../../../themeOverrides"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LabelMedium } from "baseui/typography"
import { SessionClientType } from "../../../../types/enums"

export const EditGroupSession = (props: SessionViewProps) => {
	const {
		id,
		worker,
		additionalWorkers,
		startTime,
		endTime,
		sessionLocation,
		groupAttendees,
		attendeeNumber,
		groupAtslNumber,
		groupCaldNumber,
		groupProgramDelivered,
		groupYearGroup,
		sessionFundingSources,
		clients,
		setDisplayMode,
		refetch,
		triggerQuerySessionList,
		clientType,
	} = props

	const { control, handleSubmit, setValue, getValues, watch, errors } = useForm()
	// auth
	const { hasPermission } = AuthContainer.useContainer()
	const [selectedWorkerID, setSelectedWorkerID] = React.useState<[string] | []>([worker.id])
	const [addedWorkers, setAddedWorkers] = React.useState<AddedWorker[]>(additionalWorkers || [])
	const [groupSessionAttendees, setGroupSessionAttendees] = React.useState<Client[]>(groupAttendees || [])
	const { timezone: globalTimezone, fetchIncompleteSessionAlert } = PortalContainer.useContainer()
	const timezone = React.useMemo(() => {
		const target = TZString.find((tz) => tz.id === props.timezoneID)
		if (!target) return globalTimezone
		return target
	}, [props.timezoneID]) // eslint-disable-line react-hooks/exhaustive-deps

	// handle post session reset message modal
	const [sessionInput, setSessionInput] = React.useState<SessionGroupInput>()
	const [resetPostDetailReason, setResetPostDetailReason] = React.useState("")

	const [workerIDList, setWorkerIDList] = React.useState<string[]>([])

	const [excludedID, setExcludedID] = React.useState<string[]>([])
	const { mutate, error, payload, loading } = useMutation(fetching.mutation.sessionGroupUpdate)
	const { showToast } = useZenToast()

	// watches start/end time and date inputs
	const watchDate = watch("appointmentDate")
	const watchStartTime = watch("startTime")
	const watchEndTime = watch("endTime")
	const watchWorker = watch("worker")
	const watchTimezone = watch("timezone")

	const workerAvailability = useCheckWorkerAvailability({ timezone: watchTimezone })

	const deliveredPrograms = useQuery(fetching.query.getDeliveredProgramAll())

	// Prepare form data for multiple funding sources
	const [placeholder, setPlaceholder] = React.useState<number[]>([0]) // use for tracking the index of session funding source
	const [availableIndexes, setAvailableIndexes] = React.useState<number[]>([2, 1]) // additional index available for new funding sources
	const addMoreFundingSource = () => {
		let list = [...availableIndexes]
		const index = list.pop()

		// if the list is empty, then skip
		if (!index) return

		// store remain indexes
		setAvailableIndexes(list)

		// append index to placeholder list
		setPlaceholder((p) => p.concat(index))
	}

	// set initial values
	React.useEffect(() => {
		setValue("approxNumberOfATSI", groupAtslNumber || "0")
		setValue("approxNumberOfCALD", groupCaldNumber || "0")
		setValue("numberOfAttendees", attendeeNumber || "0")
		// get offset minutes different
		const localTimezoneOffset = new Date().getTimezoneOffset()
		const setTimezoneOffset = timezone.offsetMinutes
		const offsetDiff = localTimezoneOffset - setTimezoneOffset

		setValue("startTime", moment(startTime).add(offsetDiff, "minute").toDate())
		setValue("endTime", moment(endTime).add(offsetDiff, "minute").toDate())
		setValue("sessionLocation", sessionLocation ? [{ id: sessionLocation, label: sessionLocation }] : [])

		if (sessionFundingSources.length > 0) {
			setPlaceholder(sessionFundingSources.map((_, i) => i))
			sessionFundingSources.forEach((sfs, i) => {
				setAvailableIndexes((ais) => ais.filter((ai) => ai !== i))
				setValue(`fundingSource-${i}`, [sfs.fundingSource])
				setValue(`contractArea-${i}`, sfs.contractArea ? [sfs.contractArea] : [])
				setValue(`supportType-${i}`, sfs.supportType ? [sfs.supportType] : [])
				setValue(`subSupportType-${i}`, sfs.subSupportType ? [sfs.subSupportType] : [])
			})
		}
		if (clientType) {
			const target = Object.entries(SessionClientType)
				.map((sct) => ({ id: sct[1], label: snakeToTitle(sct[0]) }))
				.find((sct) => sct.id === clientType)
			if (!target) return
			setValue("clientType", [{ ...target }])
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const handleCheckAvailability = () => {
		// form inputs
		const input = getValues()

		// null checks
		if (!input.worker || !input.worker.length || !input.startTime || !input.endTime || !watchTimezone || !watchTimezone.length) {
			if (workerAvailability.state) workerAvailability.resetState()
			return
		}

		// check available
		workerAvailability.check({
			workerIDs: [input.worker[0].id as string],
			timezone: watchTimezone[0],
			startTime: input.startTime,
			endTime: input.endTime,
			sessionID: id,
			billableSession: true,
		})
	}

	// adjust start/end time on data change
	const handleDateOnChange = (date: Date) => {
		setValue(
			"startTime",
			moment(getValues().startTime)
				.set({
					year: date.getFullYear(),
					month: date.getMonth(),
					date: date.getDate(),
				})
				.toDate(),
		)

		setValue(
			"endTime",
			moment(getValues().endTime)
				.set({
					year: date.getFullYear(),
					month: date.getMonth(),
					date: date.getDate(),
				})
				.toDate(),
		)
	}

	React.useEffect(() => {
		handleCheckAvailability()
	}, [watchDate, watchStartTime, watchEndTime, watchWorker, watchTimezone]) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		const idList: string[] = []
		if (selectedWorkerID[0]) idList.push(selectedWorkerID[0])
		if (addedWorkers.length > 0) idList.push(...addedWorkers.map<string>((a) => a.worker.id))
		setWorkerIDList(idList)
	}, [selectedWorkerID, addedWorkers])

	const org: Client[] = watch("organisation")

	const onSubmit = async () => {
		const data = getValues()
		// checks worker appointment availability
		if (!workerAvailability.available || selectedWorkerID.length === 0) return
		const input: SessionGroupInput = {
			id,
			workerID: selectedWorkerID[0],
			organisationID: org[0].id,
			timezoneID: data.timezone[0].id,
			startTime: setTimezoneToTime(alignDate(data.appointmentDate, data.startTime), data.timezone[0].id),
			endTime: setTimezoneToTime(alignDate(data.appointmentDate, data.endTime), data.timezone[0].id),
			additionalWorkers: addedWorkers.map((aw) => ({ workerID: aw.worker.id, claimHour: aw.claimHour })),
			attendeeNumber: parseInt(data.numberOfAttendees),
			ATslNumber: parseInt(data.approxNumberOfATSI),
			CaLDNumber: parseInt(data.approxNumberOfCALD),
			yearGroup: data.yearGroup.length > 0 ? data.yearGroup[0].id : undefined,
			programDeliveredID: data.programDelivered.length > 0 ? data.programDelivered[0].id : undefined,
			attendeeIDs: groupSessionAttendees.map((a) => a.id),
			sessionLocation: data.sessionLocation[0].label,
			sessionFundingSourcesInput: placeholder
				.sort((a: number, b: number) => a - b)
				.map((p) => {
					// read data from react hook form
					const fundingSource = getValues(`fundingSource-${p}`) || []
					const supportType = getValues(`supportType-${p}`) || []
					const contractArea = getValues(`contractArea-${p}`) || []
					const subSupportType = getValues(`subSupportType-${p}`) || []

					// parse and return valid id
					return {
						fundingSourceID: fundingSource[0].id,
						supportTypeID: supportType.length > 0 ? supportType[0].id : undefined,
						contractAreaID: contractArea.length > 0 ? contractArea[0].id : undefined,
						subSupportTypeID: subSupportType.length > 0 ? subSupportType[0].id : undefined,
					}
				}),
			clientType: data.clientType && data.clientType.length > 0 ? data.clientType[0].id?.toString() : undefined,
		}

		// show post session wipe out reminder if
		if (props.attendanceStatus) {
			// 1. the date has changed
			if (
				moment(props.startTime).tz(props.timezoneID).format(ZenDateTimeFormat) !== moment(input.startTime).tz(props.timezoneID).format(ZenDateTimeFormat) ||
				moment(props.endTime).tz(props.timezoneID).format(ZenDateTimeFormat) !== moment(input.endTime).tz(props.timezoneID).format(ZenDateTimeFormat)
			) {
				// show modal
				setSessionInput(input)
				setResetPostDetailReason("Due to the booking date of the session is changed,")
				return
			}

			// 2. the funding source has changed
			if (input.sessionFundingSourcesInput.length !== props.sessionFundingSources.length) {
				// show modal
				setSessionInput(input)
				setResetPostDetailReason("Due to the funding source of the session is changed,")
				return
			}

			let fundingSourceChanged = false
			input.sessionFundingSourcesInput.forEach((sfs1) => {
				// skip process if detect funding source is changed
				if (fundingSourceChanged) return

				// check input funding source exists in original session funding source list
				const exists = props.sessionFundingSources.some((sfs2) => sfs2.fundingSource.id === sfs1.fundingSourceID)

				// funding source has changed
				if (!exists) fundingSourceChanged = true
			})

			if (fundingSourceChanged) {
				// show modal
				setSessionInput(input)
				setResetPostDetailReason("Due to the funding source of the session is changed,")
				return
			}

			// 3. the client has changed
			if (props.clients && props.clients.length > 0 && input.organisationID !== props.clients[0].id) {
				// show modal
				setSessionInput(input)
				setResetPostDetailReason("Due to the client of the session is changed,")
				return
			}
		}

		const resp = await mutate(input)
		if (!resp.payload || resp.error) return
		await refetch()
		showToast("Group Session updated successfully.", "positive")
		setDisplayMode(true)
		triggerQuerySessionList((t) => !t)
		fetchIncompleteSessionAlert()
	}

	const [css] = useStyletron()
	const container = css({
		height: "100%",
		marginTop: "10px",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})

	const scrollDiv = css({
		maxHeight: "100%",
		height: "100%",
		paddingRight: "8px",
		overflowY: "auto",
		overflowX: "hidden",
	})
	const rangeTimeStyle = css({
		marginTop: "8px",
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	})
	const unidentifiedMask = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
	})
	const footer = css({
		minHeight: "80px",
	})

	return (
		<>
			<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
				{error && <ErrorNotification messageOrPayload={payload} closeable />}

				<div className={scrollDiv}>
					<ZenUserSelect
						label="Primary Worker"
						formName="worker"
						formRef={control}
						inputError={errors.worker}
						defaultValue={[worker]}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
							},
						}}
						disabled={!hasPermission(RolePermission.SessionUpdate)}
						actionOnChange={(v) => {
							if (!v || v.length === 0 || !v[0].id) {
								setSelectedWorkerID([])
								return
							}
							setSelectedWorkerID([v[0].id.toString()])
							setExcludedID([v[0].id.toString()])
							setValue("worker", v)
						}}
						excludedID={excludedID}
					/>
					<ZenClientSelect
						formRef={control}
						label="Organisation"
						formName="organisation"
						defaultValue={clients}
						actionOnChange={(v) => {
							if (v.length === 0) return
							setValue(
								"sessionLocation",
								v[0].currentContact.residentialAddress?.fullAddress
									? [{ id: v[0].currentContact.residentialAddress.fullAddress, label: v[0].currentContact.residentialAddress.fullAddress }]
									: [],
							)
						}}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Organisation is required",
							},
						}}
						isOrganisation
						inputError={errors.organisation}
					/>
					<ZenSelect
						label="Client Type"
						formName="clientType"
						options={Object.entries(SessionClientType).map((sct) => ({ id: sct[1], label: snakeToTitle(sct[0]) }))}
						formRef={control}
					/>
					<ZenDatePicker
						label="Appointment date"
						clearable={false}
						formName="appointmentDate"
						formRef={control}
						value={moment(startTime).toDate()}
						actionOnChange={handleDateOnChange}
					/>
					<ZenTimezoneSelect
						formRef={control}
						formName="timezone"
						label="Timezone"
						timezone={[timezone]}
						clearable={false}
						actionOnChange={(v) => {
							const offsetDifferent = watchTimezone[0].offsetMinutes - v[0].offsetMinutes
							if (watchStartTime) setValue("startTime", moment(watchStartTime).add(offsetDifferent, "minute"))
							if (watchEndTime) setValue("endTime", moment(watchEndTime).add(offsetDifferent, "minute"))
						}}
					/>
					<div className={rangeTimeStyle}>
						<ZenTimePicker
							formRules={{
								validate: {
									required: (value: string) => {
										if (!value) {
											return "You must select a start time"
										}
										if (watchEndTime && moment(value).isSameOrAfter(watchEndTime)) {
											return "Start time must be before end time"
										}
										return null
									},
								},
							}}
							label="Time Start"
							formName="startTime"
							date={watch("appointmentDate")}
							formRef={control}
							creatable={false}
							value={moment(startTime).toDate()}
							inputError={errors.startTime}
						/>
						<Spacer />
						<ZenTimePicker
							formRules={{
								validate: {
									required: (value: string) => {
										if (!value) {
											return "You must select an end time"
										}
										if (watchStartTime && moment(value).isSameOrBefore(watchStartTime)) {
											return "End time must be after start time"
										}
										return null
									},
								},
							}}
							label="Time End"
							formName="endTime"
							date={watch("appointmentDate")}
							formRef={control}
							creatable={false}
							value={moment(endTime).toDate()}
							inputError={errors.endTime}
						/>
					</div>
					<div>{!!workerAvailability.state && <AvailableCheck isAvailable={workerAvailability.state} />}</div>
					<ZenPlaceSelect
						label="Session Address *"
						formName="sessionLocation"
						formRef={control}
						placeholder="Address here"
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Location is required",
							},
						}}
					/>
					<AddUserBlock addedWorkers={addedWorkers} setAddedWorkers={setAddedWorkers} excludedID={workerIDList} />
					<div className={unidentifiedMask}>
						<AttendeeBlock
							attendees={groupSessionAttendees}
							setAttendees={setGroupSessionAttendees}
							excludedID={org && org.length > 0 ? groupSessionAttendees.map((a) => a.id).concat(org[0].id) : groupSessionAttendees.map((a) => a.id)}
						/>
						<ZenInput formRef={control} nameRef="numberOfAttendees" type="number" label="Number of Attendees" required inputError={errors.numberOfAttendees} />
						<ZenInput
							formRef={control}
							nameRef="approxNumberOfATSI"
							type="number"
							label="Approx Number of ATSI"
							required
							inputError={errors.approxNumberOfATSI}
							formRules={{
								validate: {
									greaterThanZero: (value: string) => (!isNaN(parseInt(value)) && parseInt(value) >= 0) || "Approx number of ATSI should not be negative",
									lessThanTotal: (value: string) => {
										const total = parseInt(getValues().numberOfAttendees)
										const numberOfCaLD = getValues().approxNumberOfCALD ? parseInt(getValues().approxNumberOfCALD) : 0
										const numberOfATSI = parseInt(value)
										if (total < numberOfCaLD + numberOfATSI) {
											return "The sum of number of ATSI and number of CaLD should be less than the total of attendees"
										}
										return true
									},
								},
							}}
						/>
						<ZenInput
							formRef={control}
							nameRef="approxNumberOfCALD"
							type="number"
							label="Approx Number of CaLD"
							required
							inputError={errors.approxNumberOfCALD}
							formRules={{
								validate: {
									greaterThanZero: (value: string) => (!isNaN(parseInt(value)) && parseInt(value) >= 0) || "Approx number of CaLD should not be negative",
									lessThanTotal: (value: string) => {
										const total = parseInt(getValues().numberOfAttendees)
										const numberOfCaLD = parseInt(value)
										const numberOfATSI = getValues().approxNumberOfATSI ? parseInt(getValues().approxNumberOfATSI) : 0
										if (total < numberOfCaLD + numberOfATSI) {
											return "The sum of number of ATSI and number of CaLD should be less than the total of attendees"
										}
										return true
									},
								},
							}}
						/>
					</div>
					<SessionFundingSourceForm
						control={control}
						errors={errors}
						placeholder={placeholder}
						watch={watch}
						setValue={setValue}
						setAvailableIndexes={setAvailableIndexes}
						setPlaceholder={setPlaceholder}
						isGroupSession
						sessionFundingSources={sessionFundingSources}
					/>
					{availableIndexes.length > 0 && (
						<>
							<Divider style={{ backgroundColor: "white" }} />
							<div>
								<ZenButton type="button" onClick={addMoreFundingSource}>
									Add New Funding Source
								</ZenButton>
							</div>
						</>
					)}
					<ZenSelect
						label="Year Group"
						formName="yearGroup"
						formRef={control}
						value={groupYearGroup ? [{ label: groupYearGroup, id: groupYearGroup }] : []}
						options={EDUCATION_YEAR_GROUP.map((y) => ({ label: y, id: y }))}
					/>
					<ZenSelect
						label="Program Delivered"
						formName="programDelivered"
						formRef={control}
						value={groupProgramDelivered ? [{ ...groupProgramDelivered }] : []}
						options={deliveredPrograms.payload}
						isLoading={deliveredPrograms.loading}
					/>
				</div>
				<div className={footer}>
					<CancelAndSaveButtons buttonWidths="wrap" cancelFn={() => setDisplayMode(true)} isLoading={loading} />
				</div>
			</form>
			{sessionInput && resetPostDetailReason && (
				<ZenModal
					isOpen={!!sessionInput}
					onClose={() => {
						setSessionInput(undefined)
						setResetPostDetailReason("")
					}}
					role="dialog"
				>
					<ZenCard style={{ alignItems: "center" }}>
						<FontAwesomeIcon size="3x" color={ZenTheme.colors.warning400} icon={["fal", "exclamation-circle"]} />
						<LabelMedium marginTop="8px">{resetPostDetailReason}</LabelMedium>
						<LabelMedium marginTop="4px">the post session detail will be reset.</LabelMedium>
						<LabelMedium marginTop="4px">Are you sure you want to update the session?</LabelMedium>
						<div style={{ width: "100%" }}>
							<CancelAndSaveButtons
								saveLabel="Confirm"
								isLoading={loading}
								saveFn={async () => {
									const resp = await mutate(sessionInput)
									if (!resp.payload || resp.error) return
									showToast("Single Session updated successfully.", "positive")
									await refetch()
									setDisplayMode(true)
									triggerQuerySessionList((t) => !t)
									fetchIncompleteSessionAlert()

									if (props.refetchTimesheet) props.refetchTimesheet()
									setSessionInput(undefined)
									setResetPostDetailReason("")
								}}
								cancelFn={() => {
									setSessionInput(undefined)
									setResetPostDetailReason("")
								}}
							/>
						</div>
					</ZenCard>
				</ZenModal>
			)}
		</>
	)
}
