import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button, KIND } from "baseui/button"
import { Value } from "baseui/select"
import { useQuery } from "react-fetching-library"
import { useHistory } from "react-router-dom"

import { AuthContainer } from "../../controllers/auth"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { friendlyDate, friendlyTime, truncate } from "../../helpers/utils"
import { ZenTheme } from "../../themeOverrides"
import { FilterBy } from "../../types/enums"
import { RolePermission, SearchTextMinLength, SortOrder, Travel } from "../../types/types"
import { SearchAndFilter, Spacer } from "../common"
import { ErrorNotification } from "../errorBox"
import { ListTable } from "../listTable"
import { ZenPagination } from "../zenComponents/zenPagination"
import { StatefulTooltip } from "baseui/tooltip"
import { Block } from "baseui/block"

interface TravelTableProps {
	clientID?: string
	workerID?: string
	sessionID?: string
	hasPagination?: boolean
	hideTopbar?: boolean
	startTime?: Date
	endTime?: Date
	height?: string
	canToggleView?: boolean
	emptyPrompt?: React.ReactNode
	setHasResult?: React.Dispatch<React.SetStateAction<boolean>>
}

enum SortBy {
	Identifier = "Identifier",
	StartTime = "StartTime",
	EndTime = "EndTime",
	Duration = "Duration",
	FromClient = "FromClient",
	Client = "Client",
	Worker = "Worker",
	PhoneNumber = "PhoneNumber",
	Source = "Source",
	Type = "Type",
	TransferredToUser = "TransferredToUser",
	FromAddress = "FromAddress",
	ToAddress = "ToAddress",
	ActualDistanceMetres = "ActualDistanceMetres",
	WithClient = "WithClient",
}

export const TravelTable = (props: TravelTableProps) => {
	const { clientID, hasPagination, startTime, endTime, workerID, canToggleView, setHasResult, hideTopbar, sessionID } = props
	const history = useHistory()
	const limit = 20
	const { hasPermission } = AuthContainer.useContainer()
	const [offset, setOffset] = React.useState(0)
	const [rows, setRows] = React.useState<Travel[]>([])
	const [total, setTotal] = React.useState<number>(0)
	const [sortColumn, setSortColumn] = React.useState("Identifier")
	const [sortAsc, setSortAsc] = React.useState(false)
	const [search, setSearch] = React.useState("")
	const [filter, setFilter] = React.useState<Value>([{ label: FilterBy.Active, id: FilterBy.Active }])
	const [listView, setListView] = React.useState(true)
	const { timezone } = PortalContainer.useContainer()
	const {
		payload: travelData,
		error: travelError,
		loading,
	} = useQuery<{ travels: Travel[]; total: number }>(
		fetching.query.getTravelMany({
			search: {
				clientID,
				workerID,
				sessionID,
				search: search.length >= SearchTextMinLength ? search : undefined,
				startTime,
				endTime,
				sortBy: sortColumn,
				sortDir: sortAsc ? SortOrder.Ascending : SortOrder.Descending,
				filterBy: filter[0]?.id?.toString(),
			},
			limit,
			offset,
		}),
	)

	// function for rendering table
	React.useEffect(() => {
		setOffset(0)
	}, [search])

	const loadRows = React.useMemo(() => {
		return () => {
			if (!travelData) return
			setRows(travelData.travels)
			setTotal(travelData.total)
			if (setHasResult) setHasResult(travelData.total > 0)
		}
	}, [travelData, setHasResult])
	React.useEffect(() => {
		loadRows()
	}, [loadRows])

	const handleSort = (id: string) => {
		if (id === sortColumn) {
			setSortAsc(!sortAsc)
			return
		}
		setSortColumn(id)
		setSortAsc(true)
	}

	const viewTravel = (id: string) => history.push(`/portal/travels/${id}`)

	const [css] = useStyletron()
	const top = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "14px",
	})

	const group = css({
		display: "flex",
		alignItems: "center",
	})

	if (travelError) return <ErrorNotification messageOrPayload={travelData} />

	return (
		<>
			{!hideTopbar && (
				<div className={top}>
					{/* Search/ filter bar */}
					<SearchAndFilter
						search={search}
						setSearch={setSearch}
						filter={filter}
						setFilter={setFilter}
						filterOptions={
							!hasPermission(RolePermission.TravelRead)
								? [
									{ label: FilterBy.Active, id: FilterBy.Active },
									{ label: FilterBy.Archived, id: FilterBy.Archived },
								]
								: undefined
						}
					/>

					{/* Toggle buttons for list / cards view */}
					{canToggleView && (
						<>
							<Button kind={KIND.tertiary} onClick={() => setListView(false)} overrides={{ BaseButton: { style: { backgroundColor: "transparent" } } }}>
								<FontAwesomeIcon size={"lg"} color={!listView ? ZenTheme.colors.primaryGreen : "grey"} icon={["fas", "th"]} />
							</Button>

							<Spacer />

							<Button kind={KIND.tertiary} onClick={() => setListView(true)} overrides={{ BaseButton: { style: { backgroundColor: "transparent" } } }}>
								<FontAwesomeIcon size={"lg"} color={listView ? ZenTheme.colors.primaryGreen : "grey"} icon={["fas", "bars"]} />
							</Button>
						</>
					)}
				</div>
			)}
			<ListTable
				emptyPrompt={props.emptyPrompt}
				isLoading={loading}
				handleSort={handleSort}
				sortColumn={sortColumn}
				sortAsc={sortAsc}
				rows={rows}
				onRowClick={(row: Travel) => viewTravel(row.id)}
				columns={[
					{
						id: SortBy.StartTime,
						header: "Date",
						resolver: (row: Travel) => (
							<StatefulTooltip
								content={() => (row.fromAddress.placeID === "" || row.toAddress.placeID === "" ? "contain invalid addresses" : null)}
								triggerType="hover"
								returnFocus
								autoFocus
								placement="right"
							>
								<div className={group}>
									{friendlyDate(row.startTime, timezone)}
									{(row.fromAddress.placeID === "" || row.toAddress.placeID === "") && (
										<Block marginLeft="3px">
											<FontAwesomeIcon size={"lg"} color={ZenTheme.colors.warning300} icon={["fal", "exclamation-triangle"]} />
										</Block>
									)}
								</div>
							</StatefulTooltip>
						),
						sortable: true,
					},
					{
						id: SortBy.StartTime,
						header: "Start",
						resolver: (row: Travel) => friendlyTime(timezone, row.startTime),
						sortable: false,
					},
					{
						id: SortBy.EndTime,
						header: "End",
						resolver: (row: Travel) => friendlyTime(timezone, row.endTime),
						sortable: false,
					},

					{
						id: SortBy.Type,
						header: "Travel Type",
						resolver: (row: Travel) => row.travelType.label,
						sortable: false,
					},

					{
						id: SortBy.FromAddress,
						header: "From",
						resolver: (row: Travel) => {
							if (row.fromAddress.fullAddress.length <= 15) return row.fromAddress.fullAddress
							return (
								<StatefulTooltip content={() => <div>{row.fromAddress.fullAddress || ""}</div>} returnFocus autoFocus>
									{truncate(row.fromAddress.fullAddress, 15)}
								</StatefulTooltip>
							)
						},
						sortable: false,
					},
					{
						id: SortBy.ToAddress,
						header: "To",
						resolver: (row: Travel) => {
							if (row.toAddress.fullAddress.length <= 15) return row.toAddress.fullAddress
							return (
								<StatefulTooltip content={() => <div>{row.toAddress.fullAddress || ""}</div>} returnFocus autoFocus>
									{truncate(row.toAddress.fullAddress, 15)}
								</StatefulTooltip>
							)
						},
						sortable: false,
					},

					{
						id: SortBy.FromClient,
						header: "From Client",
						resolver: (row: Travel) => (row.anotherClient ? `${row.anotherClient.firstName} ${row.anotherClient.lastName}` : "N/A"),
						sortable: false,
					},

					{
						id: SortBy.Client,
						header: "Client",
						resolver: (row: Travel) => (row.client ? `${row.client.firstName} ${row.client.lastName}` : "N/A"),
						sortable: false,
					},

					{
						id: SortBy.Worker,
						header: "Worker",
						resolver: (row: Travel) => (row.worker ? `${row.worker.firstName} ${row.worker.lastName}` : ""),
						sortable: false,
					},
				]}
			/>
			{hasPagination && <ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />}
		</>
	)
}
