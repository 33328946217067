import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Checkbox } from "baseui/checkbox"
import { DatePicker } from "baseui/datepicker"
import { FormControl } from "baseui/form-control"
import { Value } from "baseui/select"
import { LabelLarge } from "baseui/typography"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"

import { fetching } from "../../fetching"
import { ZenCheckBoxCenteredOverrides, ZenDatepickerOverrides, ZenTheme } from "../../themeOverrides"
import { FormType } from "../../types/enums"
import { Client, Form } from "../../types/types"
import { ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenClientSelect } from "../zenComponents/zenSelectBox"

const questions = [
	"About how often did you feel tired out for no good reason?",
	"About how often did you feel nervous?",
	"About how often did you feel so nervous that nothing could calm you down?",
	"About how often did you feel hopeless?",
	"About how often did you feel restless or fidgety?",
	"About how often did you feel so restless you could not sit still?",
	"About how often did you feel depressed?",
	"About how often did you feel that everything was an effort?",
	"About how often did you feel so sad that nothing could cheer you up?",
	"About how often did you feel worthless?",
]

interface k10FormData {
	values: number[]
	dateCompleted: Date
}

const K10Form = () => {
	const history = useHistory()
	const { showToast } = useZenToast()
	const searchArgs = new URLSearchParams(history.location.search)

	const { control, setValue, errors, trigger, watch } = useForm()
	const client = watch("client")

	// Get existing form
	const formID = searchArgs.get("formID")
	const { loading: formLoading, query } = useParameterizedQuery<Form<k10FormData>>(fetching.query.getForm)

	React.useEffect(() => {
		if (!formID) return
		query(formID).then((resp) => {
			if (resp.error || !resp.payload) return
			setValues(resp.payload.content.values)
			setValue("client", [resp.payload.client])
			setDate(new Date(resp.payload.content.dateCompleted))
		})
	}, [formID]) // eslint-disable-line react-hooks/exhaustive-deps

	// Editing
	const [isEditMode, setIsEditMode] = React.useState(false)
	const [isFormDirty, setIsFormDirty] = React.useState(false)
	const [date, setDate] = React.useState(new Date())
	const [values, setValues] = React.useState<number[]>(Array(10).fill(0))

	const cachedK10Values = React.useRef<string[]>(Array(10).fill("0"))
	const canSubmit = () => !!client && client.length > 0 && !values.some((v) => v === 0)

	const formDisabled = () => savingForm || formLoading || (!!formID && !isEditMode)

	const { mutate: createForm, loading: creatingForm, error: createError, payload: createPayload } = useMutation<Form<k10FormData>>(fetching.mutation.formCreate)
	const { mutate: updateForm, loading: updatingForm, error: updateError, payload: updatePayload } = useMutation<Form<k10FormData>>(fetching.mutation.formUpdate)

	const [savingForm, setSavingForm] = React.useState(false)
	React.useEffect(() => {
		setSavingForm(creatingForm || updatingForm)
	}, [creatingForm, updatingForm])

	// pre-fill client
	const clientID = searchArgs.get("id")

	const getClient = useParameterizedQuery<Client>(fetching.query.getClient)
	React.useEffect(() => {
		if (!clientID) return
		getClient
			.query(clientID)
			.then((r) => {
				if (r.error || !r.payload) return
				setValue("client", [{ ...r.payload, label: `${r.payload.firstName} ${r.payload.lastName}` }])
			})
			.catch()
	}, [clientID]) // eslint-disable-line react-hooks/exhaustive-deps

	const [check, setCheck] = React.useState<boolean>(false)
	const onSaveForm = async () => {
		if (savingForm) return
		setCheck(true)
		const isValid = await trigger(["client"])
		if (!isValid || !canSubmit()) return
		// if the form is used in intake process, save the data and skip the rest
		setIsFormDirty(false)
		const input = {
			clientID: client[0].id,
			content: {
				values,
				dateCompleted: date,
			},
			date: date,
		}

		if (formID) {
			updateForm({
				id: formID,
				name: `K10 Form | ${client[0].firstName} ${client[0].lastName} | ${date.toLocaleDateString(undefined, {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				})}`,
				...input,
			}).then((resp) => {
				if (resp.error || !resp.payload) return
				setIsEditMode(false)
				showToast("K10 Document updated successfully.", "positive")
				history.push(`/portal/forms`)
			})
		} else {
			createForm({
				input: {
					name: `K10 Form | ${client[0].firstName} ${client[0].lastName} | ${date.toLocaleDateString(undefined, {
						day: "numeric",
						month: "numeric",
						year: "numeric",
					})}`,
					type: FormType.K10,
					...input,
				},
			}).then((resp) => {
				if (resp.error || !resp.payload) return
				showToast("K10 Document created successfully.", "positive")
				history.push(`/portal/clients/${client[0].id}#forms`)
			})
		}
	}

	const nextButton = () => {
		if (!formID)
			return (
				<ZenButton width="100px" onClick={onSaveForm} isLoading={savingForm}>
					Submit
				</ZenButton>
			)

		if (!isEditMode) return null

		return (
			<ZenButton width="100px" onClick={onSaveForm} isLoading={savingForm}>
				Save
			</ZenButton>
		)
	}

	// Styling
	const [css, theme] = useStyletron()
	const containerStyle = css({
		height: "100%",
		display: "flex",
		overflowY: "auto",
		"@media only screen and (max-width: 1400px)": {
			maxHeight: "100%",
			flexDirection: "column-reverse",
		},
	})
	const cardStyle = css({
		padding: "10px",
		height: "fit-content",
	})
	const sideCardStyle = css({
		padding: "20px",
		width: "30%",
		marginLeft: "20px",
		height: "fit-content",
		fontSize: "14px",
		"@media only screen and (max-width: 1400px)": {
			width: "100%",
			marginLeft: "0",
			marginBottom: "10px",
		},
	})
	const linkStyle = css({
		color: theme.colors.primary,
	})
	const topStyle = css({
		display: "flex",
		marginTop: "10px",
		marginBottom: "20px",
		marginRight: "30%",
	})
	const dateStyle = css({
		width: "50%",
		marginLeft: "40px",
	})
	const footerStyle = css({
		display: "flex",
		justifyContent: "space-between",
		marginTop: "10px",
	})
	const scoreStyle = css({
		fontSize: "12px",
		fontWeight: 600,
		textAlign: "right",
		color: theme.colors.contentTertiary,
	})

	const tableStyle = css({
		fontSize: "14px",
		fontWeight: 600,
		maxWidth: "900px",
		overflow: "auto",
	})
	const tableContentStyle = css({
		borderRadius: "3px",
		border: theme.colors.borderOpaque + " solid 2px",
		padding: "20px",
		minWidth: "900px",
	})
	const headerRowStyle = css({
		display: "grid",
		gridTemplateColumns: "30% 14% 14% 14% 14% 14%",
		padding: "12px 16px",
		margin: "0 20px",
		alignItems: "center",
		minWidth: "900px",
	})
	const cellStyle = css({
		maxWidth: "80px",
		textAlign: "center",
		justifySelf: "center",
	})
	const title = css({
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
	})

	return (
		<>
			<div className={containerStyle}>
				<ZenCard className={cardStyle}>
					<div className={title}>
						<LabelLarge $style={{ fontWeight: "bold" }}>For all questions, please select the appropriate response.</LabelLarge>
						{!!formID && !isEditMode && (
							<ZenButton
								onClick={() => {
									// save current status
									cachedK10Values.current = values.map((v) => v.toString())
									setIsEditMode(true)
								}}
								height="38px"
								width="100px"
							>
								Edit
							</ZenButton>
						)}
					</div>
					{/* Client & Date */}
					<div className={topStyle}>
						<ZenClientSelect
							label="Client"
							formName="client"
							width="100%"
							formRef={control}
							disabled={formDisabled()}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || "Client is required",
								},
							}}
							inputError={errors.client}
						/>

						<div className={dateStyle}>
							<FormControl
								label="Date Completed"
								overrides={{
									ControlContainer: {
										style: { marginBottom: "4px" },
									},
								}}
							>
								<DatePicker
									value={date}
									onChange={({ date }) => !Array.isArray(date) && setDate(date)}
									disabled={formDisabled()}
									overrides={ZenDatepickerOverrides}
									formatString={"dd/MM/yyyy"}
								/>
							</FormControl>
						</div>
					</div>

					{/* Form */}
					<div className={tableStyle}>
						<div className={headerRowStyle}>
							<div>In the past 4 weeks</div>
							<div className={cellStyle}>None of the time</div>
							<div className={cellStyle}>A little of the time</div>
							<div className={cellStyle}>Some of the time</div>
							<div className={cellStyle}>Most of the time</div>
							<div className={cellStyle}>All of the time</div>
						</div>
						<div className={tableContentStyle}>
							{questions.map((q, i) => (
								<Row
									key={`k10-question-${q}`}
									check={check}
									title={q}
									index={i}
									value={values[i]}
									setValue={(val) => {
										const v = values.splice(0)
										v[i] = val

										// If "None of the time" is picked for  question 2, "None of the time" is picked for question 3; or
										// If "None of the time" is picked for  question 5, "None of the time" is picked for question 6
										if (val === 1 && (i === 1 || i === 4)) v[i + 1] = 1

										setValues(v)
										setIsFormDirty(true)
									}}
									disabled={formDisabled()}
								/>
							))}
						</div>
					</div>
					<div className={scoreStyle}>{`Score: ${values.reduce((a, b) => a + b, 0)}`}</div>

					{/* Footer */}
					{createError && <ErrorNotification messageOrPayload={createPayload} closeable />}
					{updateError && <ErrorNotification messageOrPayload={updatePayload} closeable />}

					<div className={footerStyle}>
						<ZenButton
							onClick={() => {
								if (isEditMode && formID) {
									// reset the changes
									setValues(cachedK10Values.current.map((v) => parseInt(v)))

									setIsEditMode(false)
									setIsFormDirty(false)
									return
								}
								history.goBack()
							}}
							disabled={savingForm || formLoading}
							altKind="secondary"
							width="100px"
						>
							{isEditMode && formID ? "Cancel" : "Exit"}
						</ZenButton>
						{nextButton()}
					</div>
				</ZenCard>
				<ZenCard className={sideCardStyle}>
					<LabelLarge>What is the K10 and how is it scored?</LabelLarge>
					<div>
						<p>
							The K10 is widely recommended as a simple measure of psychological distress and as a measure of outcomes following treatment for common mental
							health disorders. The K10 is in the public domain and is promoted on the Clinical Research Unit for Anxiety and Depression website{" "}
							<a href="https://www.crufad.org" target="_blank" className={linkStyle} rel="noreferrer">
								www.crufad.org
							</a>{" "}
							as a self report measure to identify need for treatment.
						</p>
						<p>
							The K10 uses a five value response option for each question - all of the time, most of the time, some of the time, a little of the time and none
							of the time which can be scored from five through to one.
						</p>
						<p>The maximum score is 50 indicating severe distress, the minimum score is 10 indicating no distress.</p>
						<p>
							Questions 3 and 6 are not asked if the proceeding question was ‘none of the time’ in which case questions 3 and 6 would automatically receive a
							score of one.
						</p>
						<p>
							For further information on the K10 please refer to{" "}
							<a href="https://www.crufad.org" target="_blank" className={linkStyle} rel="noreferrer">
								www.crufad.org
							</a>{" "}
							or Andrews, G Slade, T. Interpreting score on the Kessler Psychological Distress Scale (K10).
						</p>
						<p>Australia and New Zealand Journal of Public Health: 2001; 25:6: 494-497.</p>
					</div>
				</ZenCard>
			</div>
			<Prompt when={isFormDirty} message={"You have unsaved changes, are you sure you want to leave?"} />
		</>
	)
}

interface RowProps {
	check: boolean
	title: string
	index: number
	value: number
	setValue: (val: number) => void
	disabled?: boolean
}

const Row = (props: RowProps) => {
	const { title, index, value, setValue, disabled, check } = props
	const [css, theme] = useStyletron()
	const errorBackground = (): string => {
		if (check && value === 0) return ZenTheme.colors.negative100
		if (index % 2 !== 0) return theme.colors.backgroundSecondary
		return "unset"
	}
	const rowStyle = css({
		display: "grid",
		gridTemplateColumns: "30% 14% 14% 14% 14% 14%",
		padding: "12px 16px",
		alignItems: "center",
		backgroundColor: errorBackground(),
	})
	const firstCellStyle = css({
		paddingRight: "30px",
		color: check && value === 0 ? ZenTheme.colors.negative500 : ZenTheme.colors.black,
	})

	const checkStyle = css({
		textAlign: "center",
		color: ZenTheme.colors.primaryGreen,
	})

	return (
		<div className={rowStyle}>
			<div className={firstCellStyle}>{`${index + 1}. ${title}`}</div>
			{Array.from(Array(5)).map((_, i) => {
				const checked = value === i + 1
				if (disabled) {
					if (checked) {
						return (
							<div className={checkStyle} key={`question-${index}-checkbox-${i}`}>
								<FontAwesomeIcon icon={["fas", "check"]} />
							</div>
						)
					}
					return <span key={`question-${index}-checkbox-${i}`} />
				}
				return (
					<Checkbox
						key={`question-${index}-checkbox-${i}`}
						overrides={ZenCheckBoxCenteredOverrides}
						checked={checked}
						onChange={(e) => {
							setValue(e.currentTarget.checked ? i + 1 : 0)
						}}
						disabled={disabled}
					/>
				)
			})}
		</div>
	)
}

export default K10Form
