import * as React from "react"
import { EmergencyContact } from "../../types/types"
import { useStyletron } from "baseui"
import { LabelSmall } from "baseui/typography"
import { FieldDisplay } from "../fieldDisplay"

interface EmergencyContactProps extends EmergencyContact {
	relationship: string
	index: number
	marginBottom?: string
	width?: string
}

export const EmergencyContactItem = (props: EmergencyContactProps) => {
	const { width, index, relationship, marginBottom, firstName, lastName, telephoneNumber, mobileNumber } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		marginBottom: marginBottom || 0,
		width: width || "unset",
	})
	const group = css({
		marginTop: "8px",
		display: "flex",
		alignItems: "center",
	})

	return (
		<div className={container}>
			<div className={group}>
				<FieldDisplay label={`Emergency contact ${index + 1}`}>
					<LabelSmall>{`${firstName} ${lastName}`}</LabelSmall>
				</FieldDisplay>
				<FieldDisplay label="Relation">
					<LabelSmall>{relationship}</LabelSmall>
				</FieldDisplay>
			</div>
			<div className={group}>
				<FieldDisplay label="Home phone">
					<LabelSmall>{telephoneNumber || "N/A"}</LabelSmall>
				</FieldDisplay>
				<FieldDisplay label="Mobile">
					<LabelSmall>{mobileNumber || "N/A"}</LabelSmall>
				</FieldDisplay>
			</div>
		</div>
	)
}
