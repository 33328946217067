import * as React from "react"
import { useStyletron } from "baseui"
import { Value } from "baseui/select"
import { LabelLarge } from "baseui/typography"
import { useParameterizedQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import { fetching } from "../../../fetching"
import { Client } from "../../../types/types"
import { Divider, ZenCard } from "../../common"
import { Loading } from "../../loading"
import { ZenButton } from "../../zenComponents/zenButtons"
import { ZenConfirmModal } from "../../zenComponents/zenConfirmModal"
import { ZenClientSelect } from "../../zenComponents/zenSelectBox"

export const IntakeSelectPage = () => {
	const history = useHistory()
	const [css] = useStyletron()
	const { control, errors, setValue, getValues, setError } = useForm()
	const [ndisSelection, setNdisSelection] = React.useState(false)
	const searchArgs = new URLSearchParams(history.location.search)
	const id = searchArgs.get("id")
	const { query: getClient, payload: client, loading: clientLoading, error: clientError } = useParameterizedQuery<Client>(fetching.query.getClient)
	React.useEffect(() => {
		if (!id) return
		getClient(id)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const loadClient = React.useCallback(() => {
		if (clientLoading || clientError || !client) return
		setValue("targetClient", [{ ...client, label: `${client.firstName} ${client.lastName}` }])
	}, [client, clientLoading, clientError, setValue])
	React.useEffect(() => {
		loadClient()
	}, [loadClient])

	const onSubmit = async (intakeType: "adult" | "youth", withNDIS?: boolean) => {
		// verified client input
		const target: Value = getValues("targetClient")

		if (!target || target.length === 0) {
			setError("targetClient", {
				type: "manual",
				message: "Client is required",
			})
			return
		}

		history.push(`/portal/clients/${target[0].id}/intake/${intakeType}${withNDIS ? `?ndis=true` : ""}`)
	}

	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
	})

	const formStyle = css({
		height: "fit-content",
		backgroundColor: "white",
		paddingTop: "35px",
		paddingBottom: "35px",
		paddingLeft: "50px",
		paddingRight: "50px",
		maxWidth: "400px !important",
	})
	const buttonPair = css({
		display: "flex",
		alignItems: "center",
		marginTop: "5px",
		marginBottom: "8px",
		justifyContent: "space-between",
	})
	const footerStyle = css({
		display: "flex",
		marginTop: "10px",
	})

	const [openDialog, setOpenDialog] = React.useState(false)

	if (clientLoading) return <Loading />

	return (
		<>
			<div className={container}>
				<ZenCard className={formStyle}>
					<LabelLarge>Target Client</LabelLarge>
					<ZenClientSelect label=" " formName="targetClient" formRef={control} disabled={!!id} inputError={errors.targetClient} />

					<LabelLarge marginTop="15px" marginBottom="8px">
						Intake Adult or Youth
					</LabelLarge>
					<div className={buttonPair}>
						<ZenButton width="100px" onClick={() => setNdisSelection(true)} disabled={ndisSelection}>
							Adult
						</ZenButton>
						<ZenButton
							width="100px"
							onClick={() => {
								setOpenDialog(true)
							}}
						>
							Family
						</ZenButton>
						<ZenButton width="100px" onClick={() => onSubmit("youth")}>
							Youth
						</ZenButton>
					</div>
					{ndisSelection && (
						<>
							<LabelLarge>with NDIS plan</LabelLarge>
							<div className={buttonPair}>
								<ZenButton width="100px" onClick={() => onSubmit("adult")} marginRight="50px">
									No
								</ZenButton>
								<ZenButton width="100px" onClick={() => onSubmit("adult", true)}>
									Yes
								</ZenButton>
							</div>
						</>
					)}

					<Divider />
					<div className={footerStyle}>
						<ZenButton width="100px" altKind="secondary" onClick={() => history.push(id ? `/portal/clients/${id}#forms` : "/portal/forms")}>
							Cancel
						</ZenButton>
					</div>
				</ZenCard>
			</div>
			<ZenConfirmModal
				title={"Family form is not available yet."}
				loading={false}
				action={"Close"}
				open={openDialog}
				setOpen={setOpenDialog}
				confirm={() => setOpenDialog(false)}
			/>
		</>
	)
}
