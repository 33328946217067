import React from 'react';
import { Option } from "baseui/select";

export type FormValues = {
	startDate: string
	endDate: string
	client?: Option[]
	funding_type?: Option[]
	form_type?: Option[]
};

export type Props = {
    children: React.ReactNode
};

export type Values = {
    data: Option[]
	fundingTypeOpt: Option[]
	fundingTypeLoading: boolean
	formTypeOpt: Option[]
	durationWarning: string
	loading: boolean
	prcSupportLoading: boolean
	YouthCarerPayloadError: boolean
	supportCoordinationItemsLoading: boolean
};

export type Actions = {
    handleOnSubmit: (data: FormValues) => void
	setData: (data: any) => void
	setLineItemDisplayOptions: (data: any) => void
	// handleInputChange: (data: any) => void
}; 

export enum FormType {
	youthStarReport = "YOUTH_STAR",
	carerStarReport = "CARER_STAR",
}
