import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Value } from "baseui/select"
import { Tab, TabOverrides, Tabs } from "baseui/tabs-motion"
import { LabelLarge, LabelMedium } from "baseui/typography"
import * as React from "react"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { Divider, SearchAndFilter, ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ListTable } from "../../../components/listTable"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { fetching } from "../../../fetching"
import { useDebounce } from "../../../helpers/utils"
import { ZenTheme } from "../../../themeOverrides"
import { ContractArea, FundingSource, SubSupportType, SupportType } from "../../../types/types"
import { PlaceholderPanel } from "./placeholderPanel"

enum FilterOption {
	Active = "Active",
	Archive = "Archive",
}

export const FundingSourceOptionManagement = () => {
	const [css] = useStyletron()
	const outer = css({
		display: "flex",
		flexDirection: "column",
		maxWidth: "calc(min(1280px, 100%))",
		width: "100%",
		height: "100%",
	})

	const [selectedParentFundingSource, setSelectedParentFundingSource] = React.useState<FundingSource>()
	return (
		<div className={outer}>
			<FundingSources selectedParentFundingSource={selectedParentFundingSource} setSelectedParentFundingSource={setSelectedParentFundingSource} />
			{selectedParentFundingSource && (
				<ZenModal isOpen={!!selectedParentFundingSource} onClose={() => setSelectedParentFundingSource(undefined)} size="auto" role="dialog">
					<SubOptionPanel selectedParentFundingSource={selectedParentFundingSource} />
				</ZenModal>
			)}
		</div>
	)
}

interface FundingSourceProps {
	selectedParentFundingSource: FundingSource | undefined
	setSelectedParentFundingSource: React.Dispatch<React.SetStateAction<FundingSource | undefined>>
}
const FundingSources = (props: FundingSourceProps) => {
	const { selectedParentFundingSource, setSelectedParentFundingSource } = props
	const [css] = useStyletron()
	const container = css({
		height: "100%",
		flex: "unset !important",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [fundingSources, setFundingSources] = React.useState<FundingSource[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, label: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(20)

	const fundingSourceMany = useQuery(
		fetching.query.getFundingSourceMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
		}),
	)
	React.useEffect(() => {
		if (fundingSourceMany.error || !fundingSourceMany.payload) return
		setTotal(fundingSourceMany.payload.total)
		setFundingSources(fundingSourceMany.payload.fundingSources)
	}, [fundingSourceMany.payload, fundingSourceMany.error])

	const fundingSourceArchive = useMutation(fetching.mutation.fundingSourceArchive)
	const fundingSourceUnarchive = useMutation(fetching.mutation.fundingSourceUnarchive)

	const [targetedFundingSourceID, setTargetedFundingSourceID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedFundingSource, setSelectedFundingSource] = React.useState<FundingSource>()

	return (
		<ZenCard className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={(v) => {
						setSelectedParentFundingSource(undefined)
						setFilter(v)
					}}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Funding Source</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{fundingSourceMany.error && <ErrorNotification messageOrPayload={fundingSourceMany.payload} />}
			{fundingSourceArchive.error && <ErrorNotification messageOrPayload={fundingSourceArchive.payload} />}
			{fundingSourceUnarchive.error && <ErrorNotification messageOrPayload={fundingSourceUnarchive.payload} />}
			<ListTable
				isLoading={fundingSourceMany.loading || fundingSourceArchive.loading || fundingSourceUnarchive.loading}
				rows={fundingSources}
				selectedID={selectedParentFundingSource?.id}
				onRowClick={(row: FundingSource) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedFundingSourceID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setSelectedParentFundingSource(row)
				}}
				columns={[
					{
						id: "label",
						header: "Funding Source",
						resolver: (row: FundingSource) => row.label,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: FundingSource) => {
							// hide action button for NDIA and Non-billable funding source
							if (row.label === "NDIA" || row.label === "Non-billable") return <></>
							const isHighlighted = selectedParentFundingSource?.id === row.id
							return (
								<div className={row.deletedAt ? actionButtonHeader : actionButton}>
									{!row.deletedAt && (
										<Button
											kind="minimal"
											onClick={(e) => {
												e.stopPropagation()
												setOpenUpdateModal(true)
												setSelectedFundingSource(row)
											}}
										>
											<FontAwesomeIcon color={isHighlighted ? "white" : ZenTheme.colors.primaryGreen} size={"1x"} icon={["fal", "edit"]} />
										</Button>
									)}
									<Button
										kind="minimal"
										onClick={(e) => {
											e.stopPropagation()
											setTargetedFundingSourceID(row.id)
											if (!row.deletedAt) {
												setOpenArchiveModal(true)
												return
											}
											setOpenUnarchiveModal(true)
										}}
									>
										<FontAwesomeIcon
											color={isHighlighted ? "white" : row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
											size={"1x"}
											icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
										/>
									</Button>
									{targetedFundingSourceID === row.id && (
										<div onClick={(e) => e.stopPropagation()}>
											{openArchiveModal && (
												<ZenArchiveModal
													open={openArchiveModal}
													loading={fundingSourceArchive.loading || fundingSourceMany.loading}
													message={row.label}
													onClose={() => setOpenArchiveModal(false)}
													confirmArchive={() => {
														fundingSourceArchive.mutate(row.id).then((resp) => {
															if (resp.error || !resp.payload) return
															if (selectedParentFundingSource?.id === row.id) {
																setSelectedParentFundingSource(undefined)
															}
															fundingSourceMany.query()
															setOpenArchiveModal(false)
														})
													}}
												/>
											)}
											{openUnarchiveModal && (
												<ZenArchiveModal
													open={openUnarchiveModal}
													loading={fundingSourceUnarchive.loading || fundingSourceMany.loading}
													message={row.label}
													onClose={() => setOpenUnarchiveModal(false)}
													restoreMode
													confirmArchive={() => {
														fundingSourceUnarchive.mutate(row.id).then((resp) => {
															if (resp.error || !resp.payload) return
															fundingSourceMany.query()
															setOpenUnarchiveModal(false)
														})
													}}
												/>
											)}
										</div>
									)}
								</div>
							)
						},
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedFundingSource && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<FundingSourceForm
						fundingSource={selectedFundingSource}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedFundingSource(undefined)
						}}
						queryFundingSources={fundingSourceMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<FundingSourceForm
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryFundingSources={fundingSourceMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface FundingSourceFormProps {
	fundingSource?: FundingSource
	onClose: () => void
	queryFundingSources: () => Promise<
		QueryResponse<{
			fundingSources: FundingSource[]
			total: number
		}>
	>
}
const FundingSourceForm = (props: FundingSourceFormProps) => {
	const { fundingSource, onClose, queryFundingSources } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedFundingSource, setDuplicatedFundingSource] = React.useState<FundingSource>()

	const fundingSourceCreate = useMutation(fetching.mutation.fundingSourceCreate)
	const fundingSourceUpdate = useMutation(fetching.mutation.fundingSourceUpdate)
	const fundingSourceUnarchive = useMutation(fetching.mutation.fundingSourceUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!fundingSource) return
		setValue("label", fundingSource.label)
	}, [fundingSource, setValue])

	const onSubmit = (formData: any) => {
		if (fundingSource?.label === formData.label) {
			onClose()
			return
		}

		// update existing fundingSource
		if (fundingSource) {
			fundingSourceUpdate
				.mutate({
					id: fundingSource.id,
					label: formData.label,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated fundingSource
					if (typeof resp.payload === "object") {
						setDuplicatedFundingSource(resp.payload)
						return
					}

					queryFundingSources()
					onClose()
				})
			return
		}

		// create new fundingSource
		fundingSourceCreate
			.mutate({
				label: formData.label,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated fundingSource
				if (typeof resp.payload === "object") {
					setDuplicatedFundingSource(resp.payload)
					return
				}

				queryFundingSources()
				onClose()
			})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated fundingSource after submit
		if (duplicatedFundingSource && !!duplicatedFundingSource.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The funding source "{duplicatedFundingSource.label}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedFundingSource(undefined)}
						saveFn={() => {
							// handle position
							fundingSourceUnarchive.mutate(duplicatedFundingSource.id).then((resp) => {
								if (resp.error || !resp.payload) return
								queryFundingSources()
								onClose()
							})
						}}
						isLoading={fundingSourceUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{fundingSourceUnarchive.error && <ErrorNotification messageOrPayload={fundingSourceUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!fundingSource ? "Update" : "Create"} Funding Source</LabelMedium>
				<ZenInput label="Label" formRef={control} nameRef="label" inputError={errors.label} required />
				<CancelAndSaveButtons
					cancelFn={onClose}
					saveLabel={!!fundingSource ? "Save" : "Submit"}
					isLoading={fundingSourceCreate.loading || fundingSourceUpdate.loading}
				/>
				{duplicatedFundingSource && <ErrorNotification message={`The funding source "${duplicatedFundingSource.label}" is already exists`} />}
				{fundingSourceCreate.error && <ErrorNotification messageOrPayload={fundingSourceCreate.payload} />}
				{fundingSourceUpdate.error && <ErrorNotification messageOrPayload={fundingSourceUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}

interface SubOptionPanelProps {
	selectedParentFundingSource: FundingSource
}

const SubOptionPanel = (props: SubOptionPanelProps) => {
	const { selectedParentFundingSource } = props
	const [css] = useStyletron()
	const container = css({
		height: "80vh",
		width: "80vw",
		minWidth: "450px",
		maxWidth: "1200px",
		flex: "unset !important",
		minHeight: 0,
	})
	const contentContainer = css({
		height: "100%",
		display: "flex",
		width: "100%",
		minHeight: 0,
	})

	const [activeKey, setActiveKey] = React.useState("contractAreas")

	const tabOverrides: TabOverrides = {
		TabPanel: {
			style: {
				display: "none",
			},
		},
		Tab: {
			style: {
				fontWeight: "bold",
				fontSize: "16px",
				whiteSpace: "nowrap",
				background: "unset",
			},
		},
	}

	const displayContent = () => {
		switch (activeKey) {
			case "contractAreas":
				return <ContractAreaPanel fundingSourceID={selectedParentFundingSource.id} />
			case "supportTypes":
				return <SupportTypePanel fundingSourceID={selectedParentFundingSource.id} />
			default:
				return null
		}
	}
	return (
		<ZenCard className={container}>
			<LabelLarge marginBottom="4px">{selectedParentFundingSource.label}</LabelLarge>
			<Tabs
				activeKey={activeKey}
				onChange={({ activeKey }) => {
					setActiveKey(activeKey.toString())
				}}
				overrides={{
					TabList: {
						style: {
							backgroundColor: "transparent",
						},
					},
					TabHighlight: {
						style: {
							backgroundColor: ZenTheme.colors.primaryGreen,
						},
					},
				}}
			>
				<Tab title="Contract Areas" key="contractAreas" overrides={tabOverrides} />
				<Tab title="Support Types" key="supportTypes" overrides={tabOverrides} />
			</Tabs>

			<div className={contentContainer}>{displayContent()}</div>
		</ZenCard>
	)
}

const ContractAreaPanel = (props: { fundingSourceID: string }) => {
	const { fundingSourceID } = props
	const [css] = useStyletron()
	const container = css({
		height: "100%",
		width: "100%",
		maxHeight: "100%",
		display: "flex",
		flexDirection: "column",
		padding: "8px",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})
	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [contractAreas, setContractAreas] = React.useState<ContractArea[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, label: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(60)

	const contractAreaMany = useQuery(
		fetching.query.getContractAreaMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
			fundingSourceID: fundingSourceID,
		}),
	)
	React.useEffect(() => {
		if (contractAreaMany.error || !contractAreaMany.payload) return
		setTotal(contractAreaMany.payload.total)
		setContractAreas(contractAreaMany.payload.contractAreas)
	}, [contractAreaMany.payload, contractAreaMany.error])

	const contractAreaArchive = useMutation(fetching.mutation.contractAreaArchive)
	const contractAreaUnarchive = useMutation(fetching.mutation.contractAreaUnarchive)

	const [targetedContractAreaID, setTargetedContractAreaID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedContractArea, setSelectedContractArea] = React.useState<ContractArea>()
	return (
		<div className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={(v) => {
						setFilter(v)
					}}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Contract Area</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{contractAreaMany.error && <ErrorNotification messageOrPayload={contractAreaMany.payload} />}
			{contractAreaArchive.error && <ErrorNotification messageOrPayload={contractAreaArchive.payload} />}
			{contractAreaUnarchive.error && <ErrorNotification messageOrPayload={contractAreaUnarchive.payload} />}
			<ListTable
				isLoading={contractAreaMany.loading || contractAreaArchive.loading || contractAreaUnarchive.loading}
				rows={contractAreas}
				onRowClick={(row: ContractArea) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedContractAreaID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedContractArea(row)
				}}
				columns={[
					{
						id: "label",
						header: "Contract Area",
						resolver: (row: ContractArea) => row.label,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: ContractArea) => {
							return (
								<div className={row.deletedAt ? actionButtonHeader : actionButton}>
									<Button
										kind="minimal"
										onClick={(e) => {
											e.stopPropagation()
											setTargetedContractAreaID(row.id)
											if (!row.deletedAt) {
												setOpenArchiveModal(true)
												return
											}
											setOpenUnarchiveModal(true)
										}}
									>
										<FontAwesomeIcon
											color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
											size={"1x"}
											icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
										/>
									</Button>
									{targetedContractAreaID === row.id && (
										<div onClick={(e) => e.stopPropagation()}>
											{openArchiveModal && (
												<ZenArchiveModal
													open={openArchiveModal}
													loading={contractAreaArchive.loading || contractAreaMany.loading}
													message={row.label}
													onClose={() => setOpenArchiveModal(false)}
													confirmArchive={() => {
														contractAreaArchive.mutate({ fundingSourceID, id: row.id }).then((resp) => {
															if (resp.error || !resp.payload) return
															contractAreaMany.query()
															setOpenArchiveModal(false)
														})
													}}
												/>
											)}
											{openUnarchiveModal && (
												<ZenArchiveModal
													open={openUnarchiveModal}
													loading={contractAreaUnarchive.loading || contractAreaMany.loading}
													message={row.label}
													onClose={() => setOpenUnarchiveModal(false)}
													restoreMode
													confirmArchive={() => {
														contractAreaUnarchive.mutate({ fundingSourceID, id: row.id }).then((resp) => {
															if (resp.error || !resp.payload) return
															contractAreaMany.query()
															setOpenUnarchiveModal(false)
														})
													}}
												/>
											)}
										</div>
									)}
								</div>
							)
						},
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedContractArea && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<ContractAreaForm
						contractArea={selectedContractArea}
						fundingSourceID={fundingSourceID}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedContractArea(undefined)
						}}
						queryContractAreas={contractAreaMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<ContractAreaForm
						fundingSourceID={fundingSourceID}
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryContractAreas={contractAreaMany.query}
					/>
				</ZenModal>
			)}
		</div>
	)
}

interface ContractAreaFormProps {
	contractArea?: ContractArea
	fundingSourceID: string
	onClose: () => void
	queryContractAreas: () => Promise<
		QueryResponse<{
			contractAreas: ContractArea[]
			total: number
		}>
	>
}
const ContractAreaForm = (props: ContractAreaFormProps) => {
	const { fundingSourceID, contractArea, onClose, queryContractAreas } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedContractArea, setDuplicatedContractArea] = React.useState<ContractArea>()

	const contractAreaCreate = useMutation(fetching.mutation.contractAreaCreate)
	const contractAreaUpdate = useMutation(fetching.mutation.contractAreaUpdate)
	const contractAreaUnarchive = useMutation(fetching.mutation.contractAreaUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!contractArea) return
		setValue("label", contractArea.label)
	}, [contractArea, setValue])

	const onSubmit = (formData: any) => {
		if (contractArea?.label === formData.label) {
			onClose()
			return
		}

		// update existing contractArea
		if (contractArea) {
			contractAreaUpdate
				.mutate({
					id: contractArea.id,
					label: formData.label,
					fundingSourceID,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated contractArea
					if (typeof resp.payload === "object") {
						setDuplicatedContractArea(resp.payload)
						return
					}

					queryContractAreas()
					onClose()
				})
			return
		}

		// create new contractArea
		contractAreaCreate
			.mutate({
				label: formData.label,
				fundingSourceID,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated contractArea
				if (typeof resp.payload === "object") {
					setDuplicatedContractArea(resp.payload)
					return
				}

				queryContractAreas()
				onClose()
			})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated contractArea after submit
		if (duplicatedContractArea && !!duplicatedContractArea.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The contract area "{duplicatedContractArea.label}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedContractArea(undefined)}
						saveFn={() => {
							// handle position
							contractAreaUnarchive.mutate({ fundingSourceID, id: duplicatedContractArea.id }).then((resp) => {
								if (resp.error || !resp.payload) return
								queryContractAreas()
								onClose()
							})
						}}
						isLoading={contractAreaUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{contractAreaUnarchive.error && <ErrorNotification messageOrPayload={contractAreaUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!contractArea ? "Update" : "Create"} Contract Area</LabelMedium>
				<ZenInput label="Label" formRef={control} nameRef="label" inputError={errors.label} required />
				<CancelAndSaveButtons
					cancelFn={onClose}
					saveLabel={!!contractArea ? "Save" : "Submit"}
					isLoading={contractAreaCreate.loading || contractAreaUpdate.loading}
				/>
				{duplicatedContractArea && <ErrorNotification message={`The contract area "${duplicatedContractArea.label}" is already exists`} />}
				{contractAreaCreate.error && <ErrorNotification messageOrPayload={contractAreaCreate.payload} />}
				{contractAreaUpdate.error && <ErrorNotification messageOrPayload={contractAreaUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}

const SupportTypePanel = (props: { fundingSourceID: string }) => {
	const { fundingSourceID } = props
	const [css] = useStyletron()
	const container = css({
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
		overflowY: "scroll"
	})

	const border = css({
		backgroundColor: ZenTheme.colors.lightGrey,
		height: "6px",
	})

	const [selectedParentSupportType, setSelectedParentSupportType] = React.useState<SupportType>()
	const [selectedBreakSubSupportType, setSelectedBreakSubSupportType] = React.useState<SubSupportType>()

	// clean up selected parent support type when funding source changed
	React.useEffect(() => {
		setSelectedParentSupportType(undefined)
		setSelectedBreakSubSupportType(undefined)
	}, [fundingSourceID, setSelectedParentSupportType,setSelectedBreakSubSupportType])

	const displaySubSupportTypePanel = () => {
		if (!selectedParentSupportType) {
			return <PlaceholderPanel height="50%" text="Please select a support type to view sub support types" />
		}
		return <SubSupportTypes fundingSourceID={fundingSourceID} selectedParentSupportType={selectedParentSupportType} setSelectedBreakSubSupportType={setSelectedBreakSubSupportType} />
	}

	const displayBreakSubSupportTypePanel = () => {
		// only for non-billable support types
		if(selectedParentSupportType?.label.toLowerCase().endsWith('non-billable')){
			return <>	
				<Divider className={border} />
				<SubSupportTypesForBreak 
					fundingSourceID={fundingSourceID} 
					selectedParentSupportType={selectedParentSupportType} 
					selectedParentSubSupportType={selectedBreakSubSupportType} />
			</>
		}
		return <React.Fragment />
	}

	return (
		<div id="support-type-panel" className={container}>
			<SupportTypes
				fundingSourceID={fundingSourceID}
				setSelectedParentSupportType={setSelectedParentSupportType}
				selectedParentSupportType={selectedParentSupportType}
			/>
			<Divider className={border} />
			{displaySubSupportTypePanel()}
			{displayBreakSubSupportTypePanel()}
		</div>
	)
}

interface SupportTypesProps {
	fundingSourceID: string
	setSelectedParentSupportType: React.Dispatch<React.SetStateAction<SupportType | undefined>>
	selectedParentSupportType?: SupportType
}
const SupportTypes = (props: SupportTypesProps) => {
	const { fundingSourceID, setSelectedParentSupportType, selectedParentSupportType } = props
	const [css] = useStyletron()
	const container = css({
		height: "50%",
		width: "100%",
		maxHeight: "100%",
		display: "flex",
		flexDirection: "column",
		padding: "8px",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})
	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [supportTypes, setSupportTypes] = React.useState<SupportType[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, label: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(60)

	const supportTypeMany = useQuery(
		fetching.query.getSupportTypeMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
			fundingSourceID: fundingSourceID,
		}),
	)
	React.useEffect(() => {
		if (supportTypeMany.error || !supportTypeMany.payload) return
		setTotal(supportTypeMany.payload.total)
		setSupportTypes(supportTypeMany.payload.supportTypes)
	}, [supportTypeMany.payload, supportTypeMany.error])

	const supportTypeArchive = useMutation(fetching.mutation.supportTypeArchive)
	const supportTypeUnarchive = useMutation(fetching.mutation.supportTypeUnarchive)

	const [targetedSupportTypeID, setTargetedSupportTypeID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedSupportType, setSelectedSupportType] = React.useState<SupportType>()
	return (
		<div className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={(v) => {
						if (v.length > 0 && v[0].id === FilterOption.Archive) {
							setSelectedParentSupportType(undefined)
						}
						setFilter(v)
					}}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Support Type</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{supportTypeMany.error && <ErrorNotification messageOrPayload={supportTypeMany.payload} />}
			{supportTypeArchive.error && <ErrorNotification messageOrPayload={supportTypeArchive.payload} />}
			{supportTypeUnarchive.error && <ErrorNotification messageOrPayload={supportTypeUnarchive.payload} />}
			<ListTable
				isLoading={supportTypeMany.loading || supportTypeArchive.loading || supportTypeUnarchive.loading}
				rows={supportTypes}
				selectedID={selectedParentSupportType?.id}
				onRowClick={(row: SupportType) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedSupportTypeID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setSelectedParentSupportType(row)
				}}
				error={supportTypes.length === 0 ? "No Support Type" : undefined}
				columns={[
					{
						id: "label",
						header: "Support Type",
						resolver: (row: SupportType) => row.label,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: SupportType) => {
							const isHighlighted = selectedParentSupportType?.id === row.id
							return (
								<div className={row.deletedAt ? actionButtonHeader : actionButton}>
									{!row.deletedAt && (
										<Button
											kind="minimal"
											onClick={(e) => {
												e.stopPropagation()
												setOpenUpdateModal(true)
												setSelectedSupportType(row)
											}}
										>
											<FontAwesomeIcon color={isHighlighted ? "white" : ZenTheme.colors.primaryGreen} size={"1x"} icon={["fal", "edit"]} />
										</Button>
									)}
									<Button
										kind="minimal"
										onClick={(e) => {
											e.stopPropagation()
											setTargetedSupportTypeID(row.id)
											if (!row.deletedAt) {
												setOpenArchiveModal(true)
												return
											}
											setOpenUnarchiveModal(true)
										}}
									>
										<FontAwesomeIcon
											color={isHighlighted ? "white" : row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
											size={"1x"}
											icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
										/>
									</Button>
									{targetedSupportTypeID === row.id && (
										<div onClick={(e) => e.stopPropagation()}>
											{openArchiveModal && (
												<ZenArchiveModal
													open={openArchiveModal}
													loading={supportTypeMany.loading || supportTypeArchive.loading}
													message={row.label}
													onClose={() => setOpenArchiveModal(false)}
													confirmArchive={() => {
														supportTypeArchive.mutate({ fundingSourceID, id: row.id }).then((resp) => {
															if (resp.error || !resp.payload) return
															if (selectedParentSupportType?.id === row.id) {
																setSelectedParentSupportType(undefined)
															}
															supportTypeMany.query()
															setOpenArchiveModal(false)
														})
													}}
												/>
											)}
											{openUnarchiveModal && (
												<ZenArchiveModal
													open={openUnarchiveModal}
													loading={supportTypeUnarchive.loading || supportTypeMany.loading}
													message={row.label}
													onClose={() => setOpenUnarchiveModal(false)}
													restoreMode
													confirmArchive={() => {
														supportTypeUnarchive.mutate({ fundingSourceID, id: row.id }).then((resp) => {
															if (resp.error || !resp.payload) return
															supportTypeMany.query()
															setOpenUnarchiveModal(false)
														})
													}}
												/>
											)}
										</div>
									)}
								</div>
							)
						},
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedSupportType && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<SupportTypeForm
						supportType={selectedSupportType}
						fundingSourceID={fundingSourceID}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedSupportType(undefined)
						}}
						querySupportTypes={supportTypeMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<SupportTypeForm
						fundingSourceID={fundingSourceID}
						onClose={() => {
							setOpenCreateModal(false)
						}}
						querySupportTypes={supportTypeMany.query}
					/>
				</ZenModal>
			)}
		</div>
	)
}

interface SupportTypeFormProps {
	supportType?: SupportType
	fundingSourceID: string
	onClose: () => void
	querySupportTypes: () => Promise<
		QueryResponse<{
			supportTypes: SupportType[]
			total: number
		}>
	>
}
const SupportTypeForm = (props: SupportTypeFormProps) => {
	const { fundingSourceID, supportType, onClose, querySupportTypes } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedSupportType, setDuplicatedSupportType] = React.useState<SupportType>()

	const supportTypeCreate = useMutation(fetching.mutation.supportTypeCreate)
	const supportTypeUpdate = useMutation(fetching.mutation.supportTypeUpdate)
	const supportTypeUnarchive = useMutation(fetching.mutation.supportTypeUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!supportType) return
		setValue("label", supportType.label)
	}, [supportType, setValue])

	const onSubmit = (formData: any) => {
		if (supportType?.label === formData.label) {
			onClose()
			return
		}

		// update existing supportType
		if (supportType) {
			supportTypeUpdate
				.mutate({
					id: supportType.id,
					label: formData.label,
					fundingSourceID,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated supportType
					if (typeof resp.payload === "object") {
						setDuplicatedSupportType(resp.payload)
						return
					}

					querySupportTypes()
					onClose()
				})
			return
		}

		// create new supportType
		supportTypeCreate
			.mutate({
				label: formData.label,
				fundingSourceID,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated supportType
				if (typeof resp.payload === "object") {
					setDuplicatedSupportType(resp.payload)
					return
				}

				querySupportTypes()
				onClose()
			})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated supportType after submit
		if (duplicatedSupportType && !!duplicatedSupportType.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The support type "{duplicatedSupportType.label}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedSupportType(undefined)}
						saveFn={() => {
							// handle position
							supportTypeUnarchive.mutate({ fundingSourceID, id: duplicatedSupportType.id }).then((resp) => {
								if (resp.error || !resp.payload) return
								querySupportTypes()
								onClose()
							})
						}}
						isLoading={supportTypeUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{supportTypeUnarchive.error && <ErrorNotification messageOrPayload={supportTypeUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!supportType ? "Update" : "Create"} Support Type</LabelMedium>
				<ZenInput label="Label" formRef={control} nameRef="label" inputError={errors.label} required />
				<CancelAndSaveButtons
					cancelFn={onClose}
					saveLabel={!!supportType ? "Save" : "Submit"}
					isLoading={supportTypeCreate.loading || supportTypeUpdate.loading}
				/>
				{duplicatedSupportType && <ErrorNotification message={`The support type "${duplicatedSupportType.label}" is already exists`} />}
				{supportTypeCreate.error && <ErrorNotification messageOrPayload={supportTypeCreate.payload} />}
				{supportTypeUpdate.error && <ErrorNotification messageOrPayload={supportTypeUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}

const SubSupportTypes = (props: { fundingSourceID: string; selectedParentSupportType?: SupportType; setSelectedBreakSubSupportType?: any }) => {
	const { fundingSourceID, selectedParentSupportType } = props
	let { setSelectedBreakSubSupportType } = props
	const [css] = useStyletron()
	const container = css({
		height: "50%",
		width: "100%",
		maxHeight: "50%",
		display: "flex",
		flexDirection: "column",
		padding: "8px",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})
	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [subSupportTypes, setSubSupportTypes] = React.useState<SubSupportType[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, label: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(60)

	const subSupportTypeMany = useQuery(
		fetching.query.getSubSupportTypeMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
			fundingSourceID: fundingSourceID,
			supportTypeID: selectedParentSupportType?.id || "",
		}),
		!!selectedParentSupportType,
	)
	React.useEffect(() => {
		setSelectedBreakSubSupportType(undefined)
		if (!selectedParentSupportType) {
			setSubSupportTypes([])
			setTotal(0)
			return
		}
		if (subSupportTypeMany.error || !subSupportTypeMany.payload) return
		setTotal(subSupportTypeMany.payload.total)
		setSubSupportTypes(subSupportTypeMany.payload.subSupportTypes)
	}, [subSupportTypeMany.payload, subSupportTypeMany.error, selectedParentSupportType,setSelectedBreakSubSupportType])

	const subSupportTypeArchive = useMutation(fetching.mutation.subSupportTypeArchive)
	const subSupportTypeUnarchive = useMutation(fetching.mutation.subSupportTypeUnarchive)

	const [targetedSubSupportTypeID, setTargetedSubSupportTypeID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedSubSupportType, setSelectedSubSupportType] = React.useState<SubSupportType>()

	return (
		<div className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={(v) => {
						setFilter(v)
					}}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Sub Support Type</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{subSupportTypeMany.error && <ErrorNotification messageOrPayload={subSupportTypeMany.payload} />}
			{subSupportTypeArchive.error && <ErrorNotification messageOrPayload={subSupportTypeArchive.payload} />}
			{subSupportTypeUnarchive.error && <ErrorNotification messageOrPayload={subSupportTypeUnarchive.payload} />}
			<ListTable
				isLoading={subSupportTypeMany.loading || subSupportTypeArchive.loading || subSupportTypeUnarchive.loading}
				rows={subSupportTypes}
				selectedID={selectedSubSupportType?.id}
				onRowClick ={(row: SubSupportType) =>{
					setSelectedSubSupportType(row)
					if(!selectedParentSupportType?.label.toLowerCase().endsWith('non-billable')){
						if (row.deletedAt) {
							// trigger unarchive
							setTargetedSubSupportTypeID(row.id)
							setOpenUnarchiveModal(true)
							return
						}
						setOpenUpdateModal(true)
						return;
					}
					
					let supportTypePanel = document.getElementById('support-type-panel')
					if(supportTypePanel){
						supportTypePanel.scrollTop = supportTypePanel.scrollHeight;
					}

					setSelectedBreakSubSupportType(row)
				}}
				error={subSupportTypes.length === 0 ? "No Sub Support Type" : undefined}
				columns={[
					{
						id: "label",
						header: "Sub Support Type",
						resolver: (row: SubSupportType) => row.label,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: SubSupportType) => {
							const isHighlighted = selectedSubSupportType?.id === row.id
							return (
								<div className={row.deletedAt ? actionButtonHeader : actionButton}>
									{
										selectedParentSupportType?.label.toLowerCase().endsWith('non-billable') &&
										<Button
											kind="minimal"
											onClick={(e) => {
												e.stopPropagation()
												if(!row.deletedAt){
													setOpenUpdateModal(true)
													setSelectedSubSupportType(row)
												}
											}}
										>
											<FontAwesomeIcon color={isHighlighted ? "white" : ZenTheme.colors.primaryGreen} size={"1x"} icon={["fal", "edit"]} />
										</Button>
									}
									<Button
										kind="minimal"
										onClick={(e) => {
											e.stopPropagation()
											setTargetedSubSupportTypeID(row.id)
											if (!row.deletedAt) {
												setOpenArchiveModal(true)
												return
											}
											setOpenUnarchiveModal(true)
										}}
									>
										<FontAwesomeIcon
											color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
											size={"1x"}
											icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
										/>
									</Button>
									{targetedSubSupportTypeID === row.id && selectedParentSupportType && (
										<div onClick={(e) => e.stopPropagation()}>
											{openArchiveModal && (
												<ZenArchiveModal
													open={openArchiveModal}
													loading={subSupportTypeArchive.loading || subSupportTypeMany.loading}
													message={row.label}
													onClose={() => setOpenArchiveModal(false)}
													confirmArchive={() => {
														subSupportTypeArchive.mutate({ fundingSourceID, supportTypeID: selectedParentSupportType?.id, id: row.id }).then((resp) => {
															if (resp.error || !resp.payload) return
															subSupportTypeMany.query()
															setOpenArchiveModal(false)
														})
													}}
												/>
											)}
											{openUnarchiveModal && (
												<ZenArchiveModal
													open={openUnarchiveModal}
													loading={subSupportTypeUnarchive.loading || subSupportTypeMany.loading}
													message={row.label}
													onClose={() => setOpenUnarchiveModal(false)}
													restoreMode
													confirmArchive={() => {
														subSupportTypeUnarchive.mutate({ fundingSourceID, supportTypeID: selectedParentSupportType?.id, id: row.id }).then((resp) => {
															if (resp.error || !resp.payload) return
															subSupportTypeMany.query()
															setOpenUnarchiveModal(false)
														})
													}}
												/>
											)}
										</div>
									)}
								</div>
							)
						},
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedParentSupportType && selectedSubSupportType && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<SubSupportTypeForm
						supportTypeID={selectedParentSupportType.id}
						subSupportType={selectedSubSupportType}
						fundingSourceID={fundingSourceID}
						subSupportTypeID=""
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedSubSupportType(undefined)
						}}
						querySubSupportTypes={subSupportTypeMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && selectedParentSupportType && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<SubSupportTypeForm
						supportTypeID={selectedParentSupportType.id}
						fundingSourceID={fundingSourceID}
						subSupportTypeID=""
						onClose={() => {
							setOpenCreateModal(false)
						}}
						querySubSupportTypes={subSupportTypeMany.query}
					/>
				</ZenModal>
			)}
		</div>
	)
}

const SubSupportTypesForBreak = (props: { fundingSourceID: string; selectedParentSupportType?: SupportType; selectedParentSubSupportType?: SubSupportType }) => {
	const { fundingSourceID, selectedParentSupportType,selectedParentSubSupportType } = props
	const [css] = useStyletron()
	const container = css({
		height: "50%",
		width: "100%",
		maxHeight: "50%",
		display: "flex",
		flexDirection: "column",
		padding: "8px",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})
	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [subSupportTypes, setSubSupportTypes] = React.useState<SubSupportType[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, label: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(60)

	const subSubSupportTypeMany = useQuery(
		fetching.query.getSubSubSupportTypeMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
			fundingSourceID: fundingSourceID,
			subSupportTypeID: selectedParentSubSupportType?.id || "",
			supportTypeID: selectedParentSupportType?.id || "",
		}),
		!!selectedParentSubSupportType,
	)
	React.useEffect(() => {
		if (!selectedParentSupportType) {
			setSubSupportTypes([])
			setTotal(0)
			return
		}
		if (subSubSupportTypeMany.error || !subSubSupportTypeMany.payload) return
		setTotal(subSubSupportTypeMany.payload.total)
		setSubSupportTypes(subSubSupportTypeMany.payload.subSupportTypes)
	}, [subSubSupportTypeMany.payload, subSubSupportTypeMany.error, selectedParentSupportType])

	const subSubSupportTypeArchive = useMutation(fetching.mutation.subSubSupportTypeArchive)
	const subSubSupportTypeUnarchive = useMutation(fetching.mutation.subSubSupportTypeUnarchive)

	const [targetedSubSupportTypeID, setTargetedSubSupportTypeID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedSubSupportType, setSelectedSubSupportType] = React.useState<SubSupportType>()

	return (
		<div className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={(v) => {
						setFilter(v)
					}}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Support Type</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{subSubSupportTypeMany.error && <ErrorNotification messageOrPayload={subSubSupportTypeMany.payload} />}
			{subSubSupportTypeArchive.error && <ErrorNotification messageOrPayload={subSubSupportTypeArchive.payload} />}
			{subSubSupportTypeUnarchive.error && <ErrorNotification messageOrPayload={subSubSupportTypeUnarchive.payload} />}
			<ListTable
				isLoading={subSubSupportTypeMany.loading || subSubSupportTypeUnarchive.loading || subSubSupportTypeUnarchive.loading}
				rows={subSupportTypes}
				onRowClick ={(row: SubSupportType) =>{
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedSubSupportTypeID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedSubSupportType(row)
				}}
				error={subSupportTypes?.length === 0 || !subSupportTypes ? "No Support Type" : undefined}
				columns={[
					{
						id: "label",
						header: "Support Type",
						resolver: (row: SubSupportType) => row.label,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: SubSupportType) => {
							return (
								<div className={row.deletedAt ? actionButtonHeader : actionButton}>
									<Button
										kind="minimal"
										onClick={(e) => {
											e.stopPropagation()
											setTargetedSubSupportTypeID(row.id)
											if (!row.deletedAt) {
												setOpenArchiveModal(true)
												return
											}
											setOpenUnarchiveModal(true)
										}}
									>
										<FontAwesomeIcon
											color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
											size={"1x"}
											icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
										/>
									</Button>
									{targetedSubSupportTypeID === row.id && selectedParentSupportType && (
										<div onClick={(e) => e.stopPropagation()}>
											{openArchiveModal && (
												<ZenArchiveModal
													open={openArchiveModal}
													loading={subSubSupportTypeArchive.loading || subSubSupportTypeMany.loading}
													message={row.label}
													onClose={() => setOpenArchiveModal(false)}
													confirmArchive={() => {
														subSubSupportTypeArchive
														.mutate({ 
															fundingSourceID, 
															supportTypeID: selectedParentSupportType?.id, 
															subSupportTypeID: row.supportTypeID,
															id: row.id })
														.then((resp) => {
															if (resp.error || !resp.payload) return
															subSubSupportTypeMany.query()
															setOpenArchiveModal(false)
														})
													}}
												/>
											)}
											{openUnarchiveModal && (
												<ZenArchiveModal
													open={openUnarchiveModal}
													loading={subSubSupportTypeUnarchive.loading || subSubSupportTypeMany.loading}
													message={row.label}
													onClose={() => setOpenUnarchiveModal(false)}
													restoreMode
													confirmArchive={() => {
														subSubSupportTypeUnarchive
														.mutate({ 
															fundingSourceID, 
															supportTypeID: selectedParentSupportType?.id, 
															subSupportTypeID: row.supportTypeID,
															id: row.id })
														.then((resp) => {
															if (resp.error || !resp.payload) return
															subSubSupportTypeMany.query()
															setOpenUnarchiveModal(false)
														})
													}}
												/>
											)}
										</div>
									)}
								</div>
							)
						},
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedParentSupportType && selectedSubSupportType && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<SubSupportTypeForm
						supportTypeID={selectedParentSupportType.id}
						subSupportType={selectedSubSupportType}
						fundingSourceID={fundingSourceID}
						subSupportTypeID={ selectedParentSubSupportType !== undefined ? selectedParentSubSupportType.id : "" }
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedSubSupportType(undefined)
						}}
						querySubSupportTypes={subSubSupportTypeMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && selectedParentSupportType && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<SubSupportTypeForm
						supportTypeID={selectedParentSupportType.id}
						fundingSourceID={fundingSourceID}
						subSupportTypeID={ selectedParentSubSupportType !== undefined ? selectedParentSubSupportType.id : "" }
						onClose={() => {
							setOpenCreateModal(false)
						}}
						querySubSupportTypes={subSubSupportTypeMany.query}
					/>
				</ZenModal>
			)}
		</div>
	)
}

interface SubSupportTypeFormProps {
	subSupportType?: SubSupportType
	supportTypeID: string
	subSupportTypeID: string
	fundingSourceID: string
	onClose: () => void
	querySubSupportTypes: () => Promise<
		QueryResponse<{
			subSupportTypes: SubSupportType[]
			total: number
		}>
	>
}
const SubSupportTypeForm = (props: SubSupportTypeFormProps) => {
	const { fundingSourceID, subSupportType, onClose, querySubSupportTypes, supportTypeID, subSupportTypeID } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedSubSupportType, setDuplicatedSubSupportType] = React.useState<SubSupportType>()

	const subSupportTypeCreate = useMutation(fetching.mutation.subSupportTypeCreate)
	const subSupportTypeUpdate = useMutation(fetching.mutation.subSupportTypeUpdate)
	const subSupportTypeUnarchive = useMutation(fetching.mutation.subSupportTypeUnarchive)
	
	// sub sub
	const subSubSupportTypeCreate = useMutation(fetching.mutation.subSubSupportTypeCreate)
	const subSubSupportTypeUpdate = useMutation(fetching.mutation.subSubSupportTypeUpdate)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!subSupportType) return
		setValue("label", subSupportType.label)
	}, [subSupportType, setValue])

	const onSubmit = (formData: any) => {
		if (subSupportType?.label === formData.label) {
			onClose()
			return
		}

		// update existing subSupportType
		if (subSupportType) {
			
			// sub sub
			if(subSupportTypeID){
				
				subSubSupportTypeUpdate
					.mutate({
						id: subSupportType.id,
						label: formData.label,
						fundingSourceID,
						supportTypeID,
						subSupportTypeID
					})
					.then((resp) => {
						if (resp.error || !resp.payload) return

						// if contain duplicated subSupportType
						if (typeof resp.payload === "object") {
							setDuplicatedSubSupportType(resp.payload)
							return
						}

						querySubSupportTypes()
						onClose()
					})

				return
			}
			subSupportTypeUpdate
				.mutate({
					id: subSupportType.id,
					label: formData.label,
					fundingSourceID,
					supportTypeID,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated subSupportType
					if (typeof resp.payload === "object") {
						setDuplicatedSubSupportType(resp.payload)
						return
					}

					querySubSupportTypes()
					onClose()
				})
			return
		}

		
		// sub sub
		if(subSupportTypeID){
			// create new subSupportType
			subSubSupportTypeCreate
			.mutate({
				label: formData.label,
				fundingSourceID,
				supportTypeID,
				subSupportTypeID
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated subSupportType
				if (typeof resp.payload === "object") {
					setDuplicatedSubSupportType(resp.payload)
					return
				}

				querySubSupportTypes()
				onClose()
			})
			return
		}

		// create new subSupportType
		subSupportTypeCreate
			.mutate({
				label: formData.label,
				fundingSourceID,
				supportTypeID,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated subSupportType
				if (typeof resp.payload === "object") {
					setDuplicatedSubSupportType(resp.payload)
					return
				}

				querySubSupportTypes()
				onClose()
			})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated subSupportType after submit
		if (duplicatedSubSupportType && !!duplicatedSubSupportType.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The sub support type "{duplicatedSubSupportType.label}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedSubSupportType(undefined)}
						saveFn={() => {
							// handle position
							subSupportTypeUnarchive.mutate({ fundingSourceID, id: duplicatedSubSupportType.id, supportTypeID }).then((resp) => {
								if (resp.error || !resp.payload) return
								querySubSupportTypes()
								onClose()
							})
						}}
						isLoading={subSupportTypeUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{subSupportTypeUnarchive.error && <ErrorNotification messageOrPayload={subSupportTypeUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!subSupportType ? "Update" : "Create"} Sub Support Type</LabelMedium>
				<ZenInput label="Label" formRef={control} nameRef="label" inputError={errors.label} required />
				<CancelAndSaveButtons
					cancelFn={onClose}
					saveLabel={!!subSupportType ? "Save" : "Submit"}
					isLoading={subSupportTypeCreate.loading || subSupportTypeUpdate.loading}
				/>
				{duplicatedSubSupportType && <ErrorNotification message={`The sub support type "${duplicatedSubSupportType.label}" is already exists`} />}
				{subSupportTypeCreate.error && <ErrorNotification messageOrPayload={subSupportTypeCreate.payload} />}
				{subSupportTypeUpdate.error && <ErrorNotification messageOrPayload={subSupportTypeUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}
