import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Select, Value } from "baseui/select"
import { StatefulTooltip } from "baseui/tooltip"
import { LabelLarge, LabelMedium, LabelXSmall } from "baseui/typography"
import { SessionListItem } from "fetching/query"
import { useQuery } from "react-fetching-library"
import { Responsive, WidthProvider } from "react-grid-layout"
import { useHistory, useLocation } from "react-router-dom"
import { routes } from "routes"
import { SearchAndFilter, ZenCard } from "../../components/common"
import { ErrorNotification } from "../../components/errorBox"
import { ListTable } from "../../components/listTable"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenPagination } from "../../components/zenComponents/zenPagination"
import { AuthContainer } from "../../controllers/auth"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { friendlyDate, friendlyTime, snakeToTitle, truncate } from "../../helpers/utils"
import { AttendanceStatus, FilterBy, SessionClientType } from "../../types/enums"
import { BasicLabel, ClientDetail, RolePermission, SortOrder } from "../../types/types"

const ResponsiveGridLayout = WidthProvider(Responsive)

const layouts = {
	lg: [
		{
			w: 10,
			h: 9,
			x: 0,
			y: 0,
			i: "panel",
			static: true,
		},
		{
			w: 10,
			h: 9,
			x: 0,
			y: 0,
			i: "panel2",
			static: true,
		},
	],
}

export const SessionList = () => {
	const { currentUser } = AuthContainer.useContainer()
	const history = useHistory()
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		maxHeight: "100%",
		overflowY: "auto",
		overflowX: "hidden",
	})
	const itemCls = css({
		display: "flex",
		overflowY: "auto",
		overflowX: "hidden",
		height: "100%",
	})

	return (
		<div className={container}>
			<Block
				display="flex"
				overrides={{
					Block: {
						style: {
							display: "none",
							marginTop: "-5px",
							marginRight: "-10px",
							marginLeft: "10px",
							"@media only screen and (max-width: 1100px)": {
								display: "flex",
							},
							"@media only screen and (max-width: 700px)": {
								flexDirection: "column",
							},
						},
					},
				}}
			>
				<ZenButton marginTop="5px" marginLeft="5px" marginRight="5px" onClick={() => history.push(routes.sessions.logNonBillable)}>
					Log Non-billable Session
				</ZenButton>
				<ZenButton marginTop="5px" marginLeft="5px" marginRight="5px" onClick={() => history.push(routes.sessions.create.single.root)}>
					New Appointment
				</ZenButton>
				<ZenButton marginTop="5px" marginLeft="5px" marginRight="5px" onClick={() => history.push(routes.sessions.create.group)}>
					New Group Appointment
				</ZenButton>
			</Block>
			<ResponsiveGridLayout measureBeforeMount={true} layouts={layouts} rowHeight={(window.innerHeight - 66) / 25}>
				<ZenCard className={itemCls} key="panel">
					{/* Upcoming sessions */}
					<SessionDisplay workerID={currentUser?.id} whoseSessions="worker" fullHeight showPastSessions={false} useUrlParams />
				</ZenCard>

				<ZenCard className={itemCls} key="panel2">
					{/* Past sessions */}
					<SessionDisplay workerID={currentUser?.id} whoseSessions="worker" fullHeight showPastSessions useUrlParams />
				</ZenCard>
			</ResponsiveGridLayout>
		</div>
	)
}

export const ClientSessionList = (props: { client: ClientDetail }) => {
	const { client } = props
	const [css] = useStyletron()
	const history = useHistory()
	const itemCls = css({
		display: "flex",
		overflowY: "auto",
		overflowX: "hidden",
		height: "100%",
		width: "100% !important",
		boxShadow: "unset !important",
		padding: "0 15px 0 0 !important",
	})
	const group = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const createButton = () => {
		if (client.isOrganisation) {
			return <ZenButton onClick={() => history.push(`${routes.sessions.create.group}?client_id=${client.id}`)}>New Group Appointment</ZenButton>
		}
		return <ZenButton onClick={() => history.push(`${routes.sessions.create.single.root}?client_id=${client.id}`)}>New Appointment</ZenButton>
	}

	return (
		<ZenCard>
			<div className={group}>
				<LabelMedium>Session</LabelMedium>
				{createButton()}
			</div>
			<ResponsiveGridLayout measureBeforeMount={true} layouts={layouts} rowHeight={(window.innerHeight - 66) / 25}>
				<ZenCard className={itemCls} key="panel">
					{/* Upcoming sessions */}
					<SessionDisplay clientID={client.id} whoseSessions="client" fullHeight title="Upcoming" fullView />
				</ZenCard>

				<ZenCard className={itemCls} key="panel2">
					{/* Past sessions */}
					<SessionDisplay clientID={client.id} whoseSessions="client" showPastSessions fullHeight title="Past" fullView />
				</ZenCard>
			</ResponsiveGridLayout>
		</ZenCard>
	)
}

enum SortBy {
	Date = "Date",
	StartTime = "StartTime",
}

interface SessionDisplayProps {
	useUrlParams?: boolean
	showPastSessions?: boolean
	fullHeight?: boolean
	title?: string
	clientID?: string
	workerID?: string
	hideFilter?: boolean
	setSelectedSessionID?: (id: string) => void
	viewNotes?: boolean
	getIncomplete?: boolean // search for incomplete  sessions
	modalOnClose?: () => void
	filterOptions?: {
		label: FilterBy
		id: FilterBy
	}[]
	whoseSessions: "client" | "worker"
	fullView?: boolean // disable padding on the container if true
}

export const SessionDisplay = (props: SessionDisplayProps) => {
	const { hasPermission } = AuthContainer.useContainer()
	const { timezone } = PortalContainer.useContainer()

	const filterOptions = props.filterOptions || [
		{ label: FilterBy.Own, id: FilterBy.Own },
		{ label: FilterBy.All, id: FilterBy.All },
	]

	const {
		useUrlParams,
		showPastSessions,
		fullHeight,
		title,
		clientID,
		workerID,
		hideFilter,
		viewNotes,
		getIncomplete,
		modalOnClose,
		setSelectedSessionID,
		whoseSessions,
		fullView,
	} = props
	const history = useHistory()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const limit = 10
	const [rows, setRows] = React.useState<SessionListItem[]>([])
	const [fundingFilterOptions, setFundingFilterOptions] = React.useState<BasicLabel[]>([])
	const { payload: fundingSourcePayload, loading: fundingSourceLoading } = useQuery<BasicLabel[]>(fetching.query.getFundingSourceAll(false, true))

	let defaultOffset = parseInt((useUrlParams && searchParams.get("offset")) || "0")
	if (isNaN(defaultOffset)) defaultOffset = 0

	const [offset, setOffset] = React.useState(defaultOffset)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterBy.Own, label: FilterBy.Own }])
	const [fundingFilter, setFundingFilter] = React.useState<Value>(fundingFilterOptions)
	const [clientTypeFilter, setClientTypeFilter] = React.useState<Value>(fundingFilterOptions)
	const [searchKey, setSearchKey] = React.useState<string>((useUrlParams && searchParams.get("search")) || "")
	const [sortColumn, setSortColumn] = React.useState<string>((useUrlParams && searchParams.get("sortColumn")) || SortBy.StartTime)
	const [sortAsc, setSortAsc] = React.useState((!showPastSessions ? true : useUrlParams && searchParams.get("sortAsc") === "true") || false)

	const { payload, loading, error } = useQuery(
		whoseSessions === "client"
			? fetching.query.getClientSessionMany({
					search: {
						search: searchKey,
						filterBy: filter.length > 0 ? filter[0]?.id?.toString() : FilterBy.Own,
						sortBy: sortColumn,
						sortDir: sortAsc ? SortOrder.Ascending : SortOrder.Descending,
						clientID,
						fundingSourceID: fundingFilter.length > 0 ? (fundingFilter[0].id === FilterBy.All ? undefined : fundingFilter[0].id?.toString()) : undefined,
						clientType: clientTypeFilter && clientTypeFilter.length > 0 ? clientTypeFilter[0].id?.toString() : undefined,
					},
					limit,
					offset,
					showPastSessions: getIncomplete || showPastSessions, // if get incomplete, set has past to true
					getIncomplete,
			  })
			: fetching.query.getSessionMany({
					search: {
						search: searchKey,
						filterBy: filter.length > 0 ? filter[0]?.id?.toString() : FilterBy.Own,
						sortBy: sortColumn,
						sortDir: sortAsc ? SortOrder.Ascending : SortOrder.Descending,
						workerID: filter.length > 0 ? (filter[0].id === FilterBy.Own ? workerID : undefined) : workerID,
						fundingSourceID: fundingFilter.length > 0 ? (fundingFilter[0].id === FilterBy.All ? undefined : fundingFilter[0].id?.toString()) : undefined,
						clientType: clientTypeFilter && clientTypeFilter.length > 0 ? clientTypeFilter[0].id?.toString() : undefined,
					},
					limit,
					offset,
					showPastSessions: getIncomplete || showPastSessions, // if get incomplete, set has past to true
					getIncomplete,
			  }),
	)

	React.useEffect(() => {
		if (!useUrlParams) return

		let changed = false
		if (`${sortAsc}` !== searchParams.get("sortAsc")) {
			searchParams.set("sortAsc", `${sortAsc}`)
			changed = true
		}
		if (sortColumn !== searchParams.get("sortColumn")) {
			searchParams.set("sortColumn", `${sortColumn}`)
			changed = true
		}

		if (`${offset}` !== searchParams.get("offset")) {
			searchParams.set("offset", `${offset}`)
			changed = true
		}
		if (`${searchKey}` !== searchParams.get("search")) {
			if (searchKey === "") {
				searchParams.delete("search")
			} else {
				searchParams.set("search", `${searchKey}`)
				searchParams.delete("offset")
				setOffset(0)
			}
			changed = true
		}
		if (filter.length > 0 && searchParams.get("filter") !== filter[0].id) {
			searchParams.set("filter", `${filter[0].id}`)
			searchParams.delete("offset")
			setOffset(0)
		}
		if (fundingFilter.length > 0 && searchParams.get("fundingFilter") !== fundingFilter[0].id) {
			searchParams.set("fundingFilter", `${fundingFilter[0].id}`)
			searchParams.delete("offset")
			setOffset(0)
		}
		if (changed) {
			history.push({ pathname: location.pathname, search: searchParams.toString() })
		}
	}, [filter, fundingFilter, sortAsc, offset, sortColumn, searchKey]) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (fundingSourceLoading || !fundingSourcePayload) return
		setFundingFilterOptions([{ label: FilterBy.All, id: FilterBy.All }, ...fundingSourcePayload])

		if (!useUrlParams) return
		const newFundingFilter = fundingSourcePayload.find((f) => {
			return f.id === searchParams.get("fundingFilter")
		})
		if (newFundingFilter) {
			setFundingFilter([
				{
					id: newFundingFilter.id,
					label: newFundingFilter.label,
				},
			])
		}
	}, [fundingSourceLoading, fundingSourcePayload]) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (!useUrlParams) return
		const newFilter = filterOptions.find((f) => {
			return f.id === searchParams.get("filter")
		})
		if (newFilter) {
			setFilter([
				{
					id: newFilter.id,
					label: newFilter.label,
				},
			])
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		// Wait for data
		if (loading || !payload) return
		// Then set the data to display
		setRows(payload.sessions)
	}, [loading, payload])

	const handleSort = (id: string) => {
		if (id === sortColumn) {
			setSortAsc(!sortAsc)
			return
		}
		setSortColumn(id)
		setSortAsc(true)
	}

	const viewSession = (id: string | number) => {
		history.push(`/portal/sessions/${id}`)
	}

	const [css, theme] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		backgroundColor: "white",
		padding: fullView ? "0" : "15px",
		width: "100%",
		height: fullHeight ? "100%" : "90%",
	})
	const headerLine = css({
		display: "flex",
		justifyContent: "space-between",
	})
	const functionBar = css({
		display: "flex",
		alignItems: "center",
	})
	const paginationBox = css({
		flex: 1,
		display: "flex",
		alignItems: "flex-end",
	})

	const columnCellStyle = css({
		color: "#343434",
	})
	const cancelledStyle = css({
		color: theme.colors.negative,
	})

	if (error) return <ErrorNotification messageOrPayload={payload} />
	return (
		<div className={container}>
			<div className={headerLine}>
				{title ? <LabelLarge>{title}</LabelLarge> : <LabelLarge>{showPastSessions ? "Past Sessions" : "Upcoming Sessions"}</LabelLarge>}

				<div className={functionBar}>
					<SearchAndFilter
						search={searchKey}
						setSearch={setSearchKey}
						filter={filter}
						setFilter={setFilter}
						hideFilter={hideFilter || !hasPermission(RolePermission.SessionRead)}
						filterOptions={filterOptions}
					/>
					<Select
						searchable={false}
						options={Object.entries(SessionClientType).map((sct) => ({ id: sct[1], label: snakeToTitle(sct[0]) }))}
						value={clientTypeFilter}
						placeholder="Client Type"
						onChange={(params) => setClientTypeFilter(params.value)}
						clearable={false}
						backspaceRemoves={false}
						overrides={{
							Root: {
								style: {
									marginLeft: "14px",
									borderTopColor: "#D7DAE2",
									borderBottomColor: "#D7DAE2",
									borderLeftColor: "#D7DAE2",
									borderRightColor: "#D7DAE2",
									borderWidth: "1px",
									borderRadius: "3px",
									boxShadow: "#00000029 0px 2px 3px",
								},
							},
							ControlContainer: {
								style: {
									minWidth: "100px",
								},
							},
							ValueContainer: {
								style: {
									fontSize: "14px",
									fontWeight: 600,
									lineHeight: "16px",
									paddingTop: "0",
									paddingBottom: "0",
									paddingLeft: "6px",
								},
							},
							SelectArrow: {
								component: () => <FontAwesomeIcon fontWeight="bold" color="#A4AFB7" icon={["fal", "chevron-down"]} size="xs" />,
							},
							OptionContent: {
								style: {
									textTransform: "capitalize",
								},
							},
							Dropdown: {
								style: {
									maxHeight: "calc(min(200px, 60vh))",
								},
							},
						}}
					/>
					{!hideFilter && hasPermission(RolePermission.SessionRead) && (
						<Select
							searchable={false}
							options={fundingFilterOptions}
							value={fundingFilter}
							placeholder="Funding Source"
							onChange={(params) => setFundingFilter(params.value)}
							clearable={false}
							backspaceRemoves={false}
							overrides={{
								Root: {
									style: {
										marginLeft: "14px",
										borderTopColor: "#D7DAE2",
										borderBottomColor: "#D7DAE2",
										borderLeftColor: "#D7DAE2",
										borderRightColor: "#D7DAE2",
										borderWidth: "1px",
										borderRadius: "3px",
										boxShadow: "#00000029 0px 2px 3px",
									},
								},
								ControlContainer: {
									style: {
										minWidth: "200px",
									},
								},
								ValueContainer: {
									style: {
										fontSize: "14px",
										fontWeight: 600,
										lineHeight: "16px",
										paddingTop: "0",
										paddingBottom: "0",
										paddingLeft: "6px",
									},
								},
								SelectArrow: {
									component: () => <FontAwesomeIcon fontWeight="bold" color="#A4AFB7" icon={["fal", "chevron-down"]} size="xs" />,
								},
								OptionContent: {
									style: {
										textTransform: "capitalize",
									},
								},
								Dropdown: {
									style: {
										maxHeight: "calc(min(200px, 60vh))",
									},
								},
							}}
						/>
					)}
				</div>
			</div>

			{/* list */}
			<ListTable
				handleSort={handleSort}
				isLoading={loading}
				sortColumn={sortColumn}
				sortAsc={sortAsc}
				rows={rows}
				onRowClick={(row: SessionListItem) => {
					if (modalOnClose) modalOnClose()
					viewSession(row.identifier)
				}}
				columns={[
					{
						id: "StartTime",
						header: "Date",
						resolver: (row: SessionListItem) => (
							<div
								className={
									columnCellStyle +
									(row.attendanceStatus === AttendanceStatus.Cancelled || row.attendanceStatus === AttendanceStatus.ShortNoticeCancellation || !!row.deletedAt
										? ` ${cancelledStyle}`
										: "")
								}
							>
								{friendlyDate(row.startTime, timezone)}
							</div>
						),
						sortable: true,
					},
					{
						id: "StartTime",
						header: "Start Time",
						resolver: (row: SessionListItem) => <div className={columnCellStyle}>{friendlyTime(timezone, row.startTime)}</div>,
					},
					{
						id: "EndTime",
						header: "End Time",
						resolver: (row: SessionListItem) => <div className={columnCellStyle}>{friendlyTime(timezone, row.endTime)}</div>,
					},
					{
						id: "Worker",
						header: "Worker",
						resolver: (row: SessionListItem) => {
							return (
								<div className={columnCellStyle}>
									<div>{row.workers.length > 0 && `${row.workers[0].firstName} ${row.workers[0].lastName}`}</div>
								</div>
							)
						},
					},
					{
						id: "Client",
						header: "Client",
						resolver: (row: SessionListItem) => {
							if (!row.clients) {
								return <div></div>
							}
							const clientList: string[] = []
							row.clients.forEach((c) => {
								if (!c.firstName && !c.lastName) {
									clientList.push("N/A")
								} else {
									clientList.push(`${c.firstName} ${c.lastName}`)
								}
							})
							return (
								<div className={columnCellStyle}>
									<StatefulTooltip content={() => (row.clients.length > 1 ? <div>{clientList.join(", ")}</div> : null)} returnFocus autoFocus>
										<div>{row.clients.length > 1 ? "Multi-Client" : clientList}</div>
									</StatefulTooltip>
								</div>
							)
						},
					},

					{
						id: "SessionAddress",
						header: "Location",
						resolver: (row: SessionListItem) => {
							let location = "N/A"
							if (row.location !== "") {
								location = row.location
							} else if (row.officeLocation !== "") {
								location = row.officeLocation + " office"
							}
							return (
								<StatefulTooltip content={() => <div>{location}</div>} returnFocus autoFocus>
									<div className={columnCellStyle}>{truncate(location, 20)}</div>
								</StatefulTooltip>
							)
						},
					},

					{
						id: "SessionFundingSources",
						header: "Funding source - Support type",
						resolver: (row: SessionListItem) => {
							const fundingSourceSupportTypeList = row.sessionFundingSources.map<string>((sfs) => {
								let fundingSource = "N/A"
								let supportType = "N/A"
								if (sfs.fundingSource) fundingSource = sfs.fundingSource.label
								if (sfs.supportType) supportType = sfs.supportType.label

								return `${fundingSource} - ${supportType}`
							})
							return (
								<div
									onClick={() => {
										viewSession(row.identifier)
									}}
								>
									<StatefulTooltip
										content={() => (
											<div>
												{fundingSourceSupportTypeList.map((fss, i) => (
													<LabelXSmall key={i} color="white">
														{fss}
													</LabelXSmall>
												))}
											</div>
										)}
										returnFocus
										autoFocus
									>
										<div className={columnCellStyle}>{truncate(fundingSourceSupportTypeList[0] || "", 20)}</div>
									</StatefulTooltip>
								</div>
							)
						},
					},

					{
						id: "viewNotes",
						header: "",
						resolver: (row: SessionListItem) => {
							return (
								<ZenButton
									altKind="tertiary"
									onClick={(e) => {
										e.stopPropagation()
										if (setSelectedSessionID) setSelectedSessionID(row.id)
									}}
								>
									View notes
								</ZenButton>
							)
						},
						omitted: !viewNotes,
					},
				]}
			/>

			<div className={paginationBox}>
				<ZenPagination total={payload?.total} limit={limit} offset={offset} setOffset={setOffset} />
			</div>
		</div>
	)
}
