import * as React from "react"
import { useStyletron } from "baseui"
import { Alert } from "baseui/icon"
import { ProgressBar } from "baseui/progress-bar"
import { Value } from "baseui/select"
import { StyledTableBody, TableBuilder, TableBuilderColumn } from "baseui/table-semantic"
import { ZenTheme } from "../themeOverrides"
import { AttendanceStatus, FilterBy } from "../types/enums"
import { Divider, NoDataText } from "./common"
import { EmptyPrompt } from "./emptyPrompt"
import { Loading } from "./loading"

export interface ListTableColumn {
	id: string
	header: React.ReactNode
	resolver: (row: any) => React.ReactNode
	sortable?: boolean
	omitted?: boolean
	minWidth?: string
}

interface ListTableProps {
	rows: any[]
	columns: ListTableColumn[]
	onRowClick?: (row: any) => void
	cardViewResolver?: (value: any, onRowClick: ((data: any) => void) | null) => React.ReactNode
	sortColumn?: string
	sortAsc?: boolean
	cardView?: boolean
	handleSort?: (id: string) => void
	isLoading?: boolean
	error?: string
	disabledFunc?: (row: any) => boolean
	/** Will highlight the row with the matching id */
	selectedID?: string
	/** Set's overflow to visible and adjusts borders */
	allowOverflow?: boolean
	/** Use custom empty prompt */
	emptyPrompt?: React.ReactNode
}

// list table with view toggle (cards/ list table)
export const ListTable = (props: ListTableProps) => {
	const { rows, columns, onRowClick, sortColumn, sortAsc, handleSort, cardViewResolver, cardView, disabledFunc, selectedID, allowOverflow } = props

	const ListTableColumn = (column: ListTableColumn) => {
		if (column.omitted) return null
		if (!rows.length) return null
		return (
			<TableBuilderColumn key={`column-${column.id}`} id={column.id} header={<div className={headerCellStyle}>{column.header}</div>} sortable={column.sortable}>
				{(row) => <div className={cellStyle}>{column.resolver(row)}</div>}
			</TableBuilderColumn>
		)
	}

	// Styling
	const [css, theme] = useStyletron()
	const cellStyle = css({
		paddingLeft: "17px",
		marginTop: "7px",
		marginBottom: "7px",
	})
	const headerCellStyle = css({
		paddingTop: "6px",
		paddingLeft: "12px",
		marginTop: "5px",
		marginBottom: "5px",
	})
	const flex = css({
		display: "flex",
		alignItems: "flex-start",
		alignContent: "flex-start",
		flexWrap: "wrap",
		paddingTop: "20px",
		paddingBottom: "20px",
		paddingRight: "20px",
		paddingLeft: 0,
		height: "100%",
		overflowY: "auto",
	})

	if (!rows) {
		return <NoDataText />
	}
	if (cardView && rows.length < 1) {
		return <NoDataText />
	}
	if (props.isLoading) {
		return <Loading />
	}

	if (cardView && cardViewResolver) {
		return (
			<>
				<div className={flex}>
					{rows.map((r, idx) => (
						<div style={{ margin: "10px" }} key={idx}>
							{cardViewResolver(r, () => onRowClick && onRowClick(r))}
						</div>
					))}
				</div>
				<Divider style={{ backgroundColor: "transparent" }} />
			</>
		)
	}
	return (
		<>
			<TableBuilder
				data={rows}
				onSort={handleSort}
				sortColumn={sortColumn}
				sortOrder={sortAsc ? "ASC" : "DESC"}
				overrides={{
					Root: {
						style: {
							height: "100%",
							minHeight: "0",
							borderTopStyle: "unset",
							Style: "unset",
							borderLeftStyle: "unset",
							borderRightStyle: "unset",
							overflow: allowOverflow ? "visible" : "auto",
						},
					},
					TableHeadCellSortable: {
						style: {
							paddingTop: "6px",
							paddingBottom: "6px",
							paddingLeft: "6px",
							paddingRight: "6px",
							color: theme.colors.contentTertiary,
							":before": {
								borderLeftStyle: "unset",
							},
							":after": {
								backgroundImage: "unset",
							},
							borderBottomColor: theme.colors.backgroundSecondary,
							borderBottomWidth: "2px",
							borderBottomStyle: "solid",
							backgroundColor: "unset",
							backgroundImage: "linear-gradient(180deg, white 80%, transparent)",
						},
					},
					SortAscIcon: {
						style: { right: "0px" },
					},
					SortDescIcon: {
						style: { right: "0px" },
					},
					SortNoneIcon: {
						style: { right: "0px" },
					},
					TableHeadCell: {
						style: ({ $colIndex }) => {
							return {
								paddingTop: "6px",
								paddingBottom: "6px",
								paddingLeft: "6px",
								paddingRight: "6px",
								color: theme.colors.contentTertiary,
								":before": {
									borderLeftStyle: "unset",
								},
								":after": {
									backgroundImage: "unset",
								},
								borderBottomColor: theme.colors.backgroundSecondary,
								borderBottomWidth: "2px",
								borderBottomStyle: "solid",
								minWidth: columns[$colIndex] && columns[$colIndex].minWidth ? columns[$colIndex].minWidth : "unset",
								backgroundColor: "unset",
								backgroundImage: "linear-gradient(180deg, white 80%, transparent)",
							}
						},
					},
					TableBodyCell: {
						style: {
							fontSize: "14px",
							fontWeight: 600,
							color: "unset",
							verticalAlign: "center",
							paddingTop: "20px",
							overflowX: "hidden",
						},
					},

					TableBodyRow: {
						style: {
							cursor: "pointer",
						},
						component: ListTableRow,
						props: {
							onRowClick: onRowClick,
							disabledFunc: disabledFunc,
							selectedID: selectedID,
							borderWidth: allowOverflow ? "2px" : "5px",
						},
					},

					TableBody: {
						component:
							rows.length < 1 || props.error
								? () => (props.isLoading ? <ProgressBar infinite /> : <NoData error={props.error} customPrompt={props.emptyPrompt} />)
								: StyledTableBody,
					},
				}}
			>
				{columns.map((c) => ListTableColumn(c))}
			</TableBuilder>
		</>
	)
}

const NoData = (props: { error?: string; customPrompt?: React.ReactNode }) => {
	return (
		<tbody>
			<tr>
				<td
					height="100%"
					style={{
						display: "contents",
					}}
				>
					{props.customPrompt ? (
						props.customPrompt
					) : (
						<EmptyPrompt
							style={{
								color: "rgba(0,0,0,0.3)",
								fontFamily: "'Open Sans', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif",
							}}
							icon={<Alert size={32} />}
							title={props.error || "No results"}
							titleSize="s"
						/>
					)}
				</td>
			</tr>
		</tbody>
	)
}

const ListTableRow = React.forwardRef<HTMLTableRowElement>(({ $row, children, onRowClick, disabledFunc, selectedID, borderWidth }: any, ref) => {
	const [css, theme] = useStyletron()

	const disabled = disabledFunc && disabledFunc($row)

	// Get border colour (normal, disabed or negative)
	let borderColor = disabled ? theme.colors.white : ZenTheme.colors.primaryGreen
	// For sessions
	if (
		!!$row &&
		!!$row.attendanceStatus &&
		($row.attendanceStatus === AttendanceStatus.Cancelled || $row.attendanceStatus === AttendanceStatus.ShortNoticeCancellation || !!$row.deletedAt)
	) {
		borderColor = theme.colors.negative
	}
	// For mileage claims
	if (!!$row && !!$row.status && $row.status === "Declined") {
		borderColor = theme.colors.negative
	}

	// Get background colour (normal, disabled or selected)
	let backgroundColor = disabled ? ZenTheme.colors.lightGrey : theme.colors.white
	let backgroundColorHover = disabled ? ZenTheme.colors.lightGrey : "#eef0f9"
	let color = theme.colors.black
	if (!!$row && $row.id === selectedID) {
		backgroundColor = theme.colors.primary
		backgroundColorHover = theme.colors.buttonPrimaryHover
		color = theme.colors.white
	}

	const rowContainer = css({
		cursor: disabled || !onRowClick ? "" : "pointer",
		borderBottomWidth: "6px",
		borderBottomStyle: "solid",
		borderBottomColor: "#f4f4f4",
		borderLeftWidth: borderWidth || "5px",
		borderLeftStyle: "solid",
		borderLeftColor: borderColor,
		borderRightWidth: "3px",
		borderRightColor: "#f4f4f4",
		backgroundColor: backgroundColor,
		color: color,
		":hover": {
			backgroundColor: backgroundColorHover,
		},
	})

	return (
		<tr className={rowContainer} onClick={() => onRowClick && onRowClick($row)} ref={ref}>
			{children}
		</tr>
	)
})

export const useListTable = (options: { limit?: number; sortColumn?: string; sortAsc?: boolean }) => {
	const limit: number = options.limit || 20
	const [offset, setOffset] = React.useState(0)
	const [total, setTotal] = React.useState<number>(0)
	const [sortColumn, setSortColumn] = React.useState<string>(options.sortColumn || "")
	const [sortAsc, setSortAsc] = React.useState<boolean>(options.sortAsc === undefined ? true : options.sortAsc)
	const [filter, setFilter] = React.useState<Value>([{ label: FilterBy.Active, id: FilterBy.Active }])
	const [search, setSearch] = React.useState<string>("")
	const [listView, setListView] = React.useState(true)

	const handleSort = (id: string) => {
		if (id === sortColumn) {
			setSortAsc(!sortAsc)
			return
		}
		setSortColumn(id)
		setSortAsc(true)
	}

	return {
		limit,
		offset,
		setOffset,
		handleSort,
		total,
		setTotal,
		sortAsc,
		sortColumn,
		filter,
		setFilter,
		search,
		setSearch,
		listView,
		setListView,
	}
}
