import * as React from "react"
import { useParameterizedQuery, useQuery } from "react-fetching-library"
import { useHistory } from "react-router"
import { createContainer } from "unstated-next"

import { fetching } from "../fetching"
import { FilterBy } from "../types/enums"
import { CallLog, ClientDetail, Timezone, Travel, TZString, UserDetail } from "../types/types"
import { AuthContainer } from "./auth"

export const PortalContainer = createContainer(() => {
	const history = useHistory()
	const auth = AuthContainer.useContainer()
	const [client, setClient] = React.useState<ClientDetail>()
	const [worker, setWorker] = React.useState<UserDetail>()
	const [call, setCall] = React.useState<CallLog>()
	const [travel, setTravel] = React.useState<Travel>()
	const [lockDate, setLockDate] = React.useState(new Date()) // default to now until it has loaded from get request.
	const [lockDateLoaded, setLockDateLoaded] = React.useState(false)

	const [timezone, setTimezone] = React.useState<Timezone>(TZString[0])

	const getClient = useParameterizedQuery<ClientDetail>(fetching.query.getClient)
	const getWorker = useParameterizedQuery<UserDetail>(fetching.query.getUser)
	const getCall = useParameterizedQuery<CallLog>(fetching.query.getCallLog)
	const getTravel = useParameterizedQuery<Travel>(fetching.query.getTravel)
	const getLockDate = useQuery(fetching.query.lockDateGet())

	React.useEffect(() => {
		if (getLockDate.payload && !getLockDate.error && !lockDateLoaded) {
			const date = new Date(getLockDate.payload.date)
			setLockDate(date)
			setLockDateLoaded(true)
		}
	}, [setLockDate, getLockDate, lockDateLoaded])

	const fetchClient = async (id: string) => {
		const response = await getClient.query(id)
		if (response.payload) {
			setClient(response.payload)
		}
	}

	const fetchWorker = async (id: string) => {
		const response = await getWorker.query(id)
		if (response.payload) {
			setWorker(response.payload)
		}
	}

	const fetchCall = async (id: string) => {
		if (!id) return
		const response = await getCall.query(id)
		if (response.error || !response.payload) return
		setCall(response.payload)

		return {
			call,
			loading: getCall.loading,
			error: getCall.error,
		}
	}

	const fetchTravel = async (id: string) => {
		if (!id) return
		const response = await getTravel.query(id)
		if (response.error || !response.payload) return
		setTravel(response.payload)

		return {
			travel,
			loading: getTravel.loading,
			error: getTravel.error,
		}
	}

	// incomplete sessions
	const incompleteSessions = useQuery<{ has24HrsIncompleteSessions: boolean; has48HrsIncompleteSessions: boolean }>(
		fetching.query.getIncompleteSession(auth.currentUser?.id || ""),
	)
	const [has48HrsIncompleteSessions, setHas48HrsIncompleteSessions] = React.useState(false)
	const [has24HrsIncompleteSessions, setHas24HrsIncompleteSessions] = React.useState(false)

	React.useEffect(() => {
		if (incompleteSessions.loading || incompleteSessions.error || !incompleteSessions.payload) return
		setHas24HrsIncompleteSessions(incompleteSessions.payload.has24HrsIncompleteSessions)
		setHas48HrsIncompleteSessions(incompleteSessions.payload.has48HrsIncompleteSessions)
	}, [incompleteSessions.payload, incompleteSessions.error, incompleteSessions.loading])

	// incomplete form alert
	const incompleteForms = useQuery(
		fetching.query.getFormsMany({
			search: {
				filterBy: FilterBy.Active,
			},
			limit: 1,
			offset: 0,
			requiresAction: 1,
			isOwnActionableForms: true,
		}),
	)

	const [hasActionableForms, setHasActionableForms] = React.useState(false)

	React.useEffect(() => {
		if (incompleteForms.loading || incompleteForms.error || !incompleteForms.payload) return
		setHasActionableForms(incompleteForms.payload.total > 0)
	}, [incompleteForms.payload, incompleteForms.loading, incompleteForms.error])

	const declinedReports = useQuery<{ hasDeclinedTimesheets: boolean; hasDeclinedMileageClaims: boolean }>(
		fetching.query.getHasDeclinedReports(auth.currentUser?.id || ""),
	)

	const [hasDeclinedTimesheets, setHasDeclinedTimesheets] = React.useState(false)
	const [hasDeclinedMileageClaims, setHasDeclinedMileageClaims] = React.useState(false)
	React.useEffect(() => {
		if (declinedReports.loading || declinedReports.error || !declinedReports.payload) return

		setHasDeclinedTimesheets(declinedReports.payload.hasDeclinedTimesheets)
		setHasDeclinedMileageClaims(declinedReports.payload.hasDeclinedMileageClaims)
	}, [declinedReports.payload, declinedReports.loading, declinedReports.error])

	React.useEffect(() => {
		// Set client, worker, call and travel data to undefined when not
		// in a details page
		const crumbs = history.location.pathname.split("/")
		if (crumbs[0] === "" && crumbs[1] === "portal" && crumbs.length === 3) {
			setClient(undefined)
			setWorker(undefined)
			setTravel(undefined)
			setCall(undefined)
		}
	}, [history.location.pathname])

	React.useEffect(() => {
		if (!auth.currentUser) return
		const timezoneID = auth.currentUser.timezoneID
		const targetTimezone = TZString.find((tz) => tz.id === timezoneID)
		if (!targetTimezone) return
		setTimezone(targetTimezone)
	}, [auth.currentUser])

	return {
		// Clients
		client,
		clientLoading: getClient.loading,
		clientError: getClient.error,
		clientErrorPayload: getClient.payload,
		fetchClient,

		// Workers
		worker,
		fetchWorker,

		// Call Logs
		call,
		fetchCall,

		// Travels
		travel,
		fetchTravel,

		// Session
		has48HrsIncompleteSessions,
		has24HrsIncompleteSessions,
		fetchIncompleteSessionAlert: incompleteSessions.query,

		// Form
		hasActionableForms,
		fetchActionableForms: incompleteForms.query,

		// Timesheets
		hasDeclinedTimesheets,

		// Mileage claims
		hasDeclinedMileageClaims,

		lockDate,

		// Timezone
		timezone,
		setTimezone,
	}
})
