import * as React from "react"
import { styled } from "baseui"

// STYLES
const Container = styled("div", {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	flexDirection: "column",
	height: "100%",
	padding: "2rem",
})

const Section = styled("div", {
	":not(:last-child)": {
		marginBottom: "1rem",
	},
})

const TitleSection = styled<TitleSectionProps, keyof JSX.IntrinsicElements | React.ComponentType<any>, unknown>(Section, (props) => {
	const { $titleSize } = props
	let fontSize = "3rem"
	switch ($titleSize) {
		case "xs":
			fontSize = "1rem"
			break
		case "s":
			fontSize = "2rem"
			break
		case "l":
			fontSize = "4rem"
			break
	}

	return {
		fontSize,
		":not(:last-child)": {
			marginBottom: "1rem",
		},
	}
})

// TYPES/INTERFACES
type TitleSize = "xs" | "s" | "m" | "l"

interface TitleSectionProps {
	$titleSize: TitleSize
}

interface EmptyPromptProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
	icon?: React.ReactNode
	title: React.ReactNode
	titleSize?: TitleSize
	body?: React.ReactNode
	actions?: React.ReactNode
}

export const EmptyPrompt: React.FC<EmptyPromptProps> = ({ icon, title, titleSize = "m", body, actions, ...props }: EmptyPromptProps) => {
	return (
		<Container {...props}>
			{icon && <Section>{icon}</Section>}
			<TitleSection $titleSize={titleSize}>{title}</TitleSection>
			{body && <Section>{body}</Section>}
			{actions && <Section>{actions}</Section>}
		</Container>
	)
}
