import * as React from "react"
import { StyledLink } from "baseui/link"
import { H1, Paragraph1 } from "baseui/typography"
import { Redirect, useHistory } from "react-router-dom"
import { useStyletron } from "styletron-react"

const NotFound = (props: { heading?: string; message?: string }) => {
	const { heading, message } = props
	const [css] = useStyletron()

	const container = css({
		padding: "100px",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	})
	const link = css({
		paddingTop: "5px",
		cursor: "pointer",
	})
	const history = useHistory()
	const [redirect] = React.useState<string | null>(null)
	return (
		<div className={container}>
			{redirect && <Redirect to={redirect} />}
			<H1>{heading || "Page Not Found"}</H1>
			<Paragraph1>{message || "The link you followed may be broken, or the page may have been removed."}</Paragraph1>
			<StyledLink className={link} href={"/"}>
				Return to Home
			</StyledLink>
			<StyledLink
				className={link}
				onClick={() => {
					history.goBack()
				}}
			>
				Go Back
			</StyledLink>
		</div>
	)
}

export { NotFound }
