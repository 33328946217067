import * as React from "react"
import { useStyletron } from "baseui"
import { Option, Value } from "baseui/select"
import { LabelLarge } from "baseui/typography"
import { useQuery } from "react-fetching-library"
import { useFormContext } from "react-hook-form"
import { useHistory } from "react-router-dom"

import { fetching } from "../../../../fetching"
import { emailRegex, phoneNumberRegex } from "../../../../helpers/utils"
import { IntakeType } from "../../../../types/enums"
import { Referrer } from "../../../../types/types"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { ErrorNotification } from "../../../errorBox"
import { ZenInput } from "../../../zenComponents/zenInput"
import { ZenSelect, ZenUserSelect } from "../../../zenComponents/zenSelectBox"
import { intakeURLParser } from "../adult/intakeAdultBaseForm"
import { ErrorFieldTracker } from "../../../forms/errorFieldTracker"

export interface CommonIntakeFormData {
	intakeType: IntakeType
	clientID: string
	withNDIS?: boolean
	formID: string | null
	isReadOnly: boolean
	validationTriggered: boolean
	countryOptions?: Option[]
	languageOptions?: Option[]
	onSubmit?: () => void
}

export const Detail = (props: CommonIntakeFormData) => {
	const { clientID, intakeType, formID, withNDIS, isReadOnly, validationTriggered } = props
	const [css] = useStyletron()
	const history = useHistory()
	const { watch, control, errors, trigger } = useFormContext()

	// load select options
	const referrerID = watch("referrerID")
	const referrerData = useQuery<Referrer[]>(fetching.query.getReferrerAll())
	const parentReferrer = referrerID && referrerID.length > 0 && referrerData.payload ? referrerData.payload.find((r) => r.id === referrerID[0].id) : undefined

	const validation = React.useCallback(async () => {
		let fieldsToValidate = ["workerID"]

		if (referrerID && referrerID.length > 0 && referrerID[0].id === "other") {
			fieldsToValidate = [...fieldsToValidate, "referrerName", "referrerAgencyRelationship", "referrerContactNumber", "referrerEmail"]
		}

		return await trigger(fieldsToValidate)
	}, [referrerID, trigger])

	React.useEffect(() => {
		if (validationTriggered) validation()
	}, [validationTriggered, validation])

	const onSubmit = async () => {
		const isValid = await validation()
		if (!isValid) return
		nextPage()
	}

	const nextPage = () => {
		switch (intakeType) {
			case IntakeType.Adult:
				if (withNDIS) {
					history.push(intakeURLParser(`/portal/clients/${clientID}/intake/adult/emergencyContactDetails`, formID, withNDIS))
					break
				}
				history.push(intakeURLParser(`/portal/clients/${clientID}/intake/adult/presenceOfRiskConcerns`, formID))
				break
			case IntakeType.Youth:
				history.push(intakeURLParser(`/portal/clients/${clientID}/intake/youth/parentGuardianDetails`, formID))
				break
		}
	}
	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		flexDirection: "row",
		backgroundColor: "white",
	})

	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
	})
	const body = css({
		width: "100%",
		height: "95%",
	})

	return (
		<div className={container}>
			{/* <form autoComplete="off"className={formStyle} onSubmit={handleSubmit(onSubmit)}> */}
			<div className={formStyle}>
				<div className={body}>
					<LabelLarge marginBottom="15px">Referrer Details</LabelLarge>
					{referrerData.error && <ErrorNotification messageOrPayload={referrerData.payload} />}

					<ZenUserSelect
						label="Worker"
						formName="workerID"
						disabled={isReadOnly}
						formRef={control}
						inputError={errors.workerID}
						clearable={false}
						formRules={{
							validate: {
								required: (value: Value) => {
									return (!!value && value.length > 0) || "Worker is required"
								},
							},
						}}
					/>
					<LabelLarge marginTop="20px" marginBottom="15px">
						Referrer Details
					</LabelLarge>
					{referrerData.payload && (
						<ZenSelect
							label={"Select Referrer"}
							formName={"referrerID"}
							formRef={control}
							disabled={isReadOnly}
							inputError={errors.genderID}
							options={referrerData.payload
								.map((r) => ({
									id: r.id,
									label: r.referrerName,
								}))
								.concat({
									id: "other",
									label: "Specify Details",
								})}
						/>
					)}
					{parentReferrer?.subReferrers && (
						<ZenSelect
							label={""}
							formName={"subReferrerID"}
							formRef={control}
							disabled={isReadOnly}
							inputError={errors.genderID}
							options={parentReferrer.subReferrers.map((r) => ({
								id: r.id,
								label: r.referrerName,
							}))}
						/>
					)}
					{referrerID && referrerID.length > 0 && referrerID[0].id === "other" && (
						<>
							<ZenInput
								disabled={isReadOnly}
								label="Name"
								nameRef="referrerName"
								formRules={{
									validate: {
										required: (value: Value) => {
											return !!value || "Name is required"
										},
									},
								}}
								formRef={control}
								inputError={errors.referrerName}
							/>
							<ZenInput
								disabled={isReadOnly}
								label="Agency/Relationship"
								nameRef="referrerAgencyRelationship"
								formRules={{
									validate: {
										required: (value: Value) => {
											return !!value || "Agency/Relationship is required"
										},
									},
								}}
								formRef={control}
								inputError={errors.referrerAgencyRelationship}
							/>
							<ZenInput
								disabled={isReadOnly}
								label="Contact Number"
								nameRef="referrerContactNumber"
								formRules={{
									validate: {
										required: (value: Value) => {
											return !!value || "Contact number is required"
										},
									},
									pattern: {
										value: phoneNumberRegex,
										message: "Invalid contact number",
									},
								}}
								formRef={control}
								inputError={errors.referrerContactNumber}
							/>
							<ZenInput
								disabled={isReadOnly}
								label="Email"
								nameRef="referrerEmail"
								formRules={{
									validate: {
										required: (value: Value) => {
											return !!value || "Email is required"
										},
									},
									pattern: {
										value: emailRegex,
										message: "Invalid email",
									},
								}}
								formRef={control}
								inputError={errors.referrerEmail}
							/>
						</>
					)}
				</div>
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				{!isReadOnly && (
					<CancelAndSaveButtons cancelLabel="Back" cancelFn={() => history.push(`/portal/clients/${clientID}/intake`)} saveLabel="Next" saveFn={onSubmit} />
				)}
			</div>
		</div>
	)
}
