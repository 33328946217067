import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Value } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import { Textarea } from "baseui/textarea"
import { DisplayXSmall, LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { Control, Controller, FormProvider, useForm, useFormContext } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"

import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { ZenTheme } from "../../themeOverrides"
import { FormType, FormTypeName, StarChartLabel } from "../../types/enums"
import { CarersStarActionPlanInput, CarersStarFormInput } from "../../types/inputTypes"
import { ClientDetail, Form } from "../../types/types"
import { CancelAndSaveButtons } from "../cancelSaveButtons"
import { Spacer, ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenInput, ZenTextArea } from "../zenComponents/zenInput"
import { ZenClientSelect, ZenSelect, ZenUserSelect } from "../zenComponents/zenSelectBox"
import { ZenDatePicker } from "../zenComponents/zenTime"
import { routes } from "routes"
import { ErrorFieldTracker } from "./errorFieldTracker"

const PrimaryPurple = "#717A9D"
const SecondaryPurple = "#B1BADD"

const bookMark = [
	{ label: "5 As good as it can be", hexCode: "#4CA7A0" },
	{ label: "4 Finding what works", hexCode: "#9ECB19" },
	{ label: "3 Making changes", hexCode: "#E8AA09" },
	{ label: "2 Getting help", hexCode: "#DE7019" },
	{ label: "1 Cause for concern", hexCode: "#C73536" },
]
const inputTypeOptions = ["First", "Review", "Retrospective"]
const completedByOptions = ["Worker and Carer", "Worker alone", "Carer"]

export const CarersStarForm = () => {
	const formMethods = useForm()
	const [css] = useStyletron()

	const { currentUser } = AuthContainer.useContainer()

	const [starSpike, setStarSpike] = React.useState(Object.values(StarChartLabel).map((sct) => ({ label: sct, value: 0 })))
	const [actionPlanPlaceholder, setActionPlanPlaceholder] = React.useState<number[]>([0])
	const [actionPlanNextIndex, setActionPlanNextIndex] = React.useState<number>(1)

	const history = useHistory()
	const { showToast } = useZenToast()
	const searchArgs = new URLSearchParams(history.location.search)
	const clientID = searchArgs.get("id")
	const formID = searchArgs.get("formID")

	// pre-fill carerStarWorker
	React.useEffect(() => {
		if (formID || !currentUser) return
		formMethods.setValue("carerStarWorker", [{ ...currentUser, label: `${currentUser.firstName} ${currentUser.lastName}` }])
	}, [currentUser, formID, formMethods])

	// pre-fill client
	const getClient = useParameterizedQuery<ClientDetail>(fetching.query.getClient)
	React.useEffect(() => {
		if (!clientID) return
		getClient.query(clientID).then((resp) => {
			if (resp.error || !resp.payload) return
			formMethods.setValue("carerStarClient", [{ ...resp.payload, label: `${resp.payload.firstName} ${resp.payload.lastName}` }])
		})
	}, [clientID]) // eslint-disable-line react-hooks/exhaustive-deps

	// pre-fill form data
	const setFormData = (currentState: CarersStarFormInput) => {
		formMethods.setValue("carerStarClient", currentState.carerStarClient)
		formMethods.setValue("carerStarWorker", currentState.carerStarWorker)
		formMethods.setValue("carersStarType", currentState.carersStarType)
		formMethods.setValue("dateOfCompletion", currentState.dateOfCompletion ? moment(currentState.dateOfCompletion).toDate() : null)
		formMethods.setValue("completedBy", currentState.completedBy)
		formMethods.setValue("healthNote", currentState.healthNote)
		formMethods.setValue("caringRoleNote", currentState.caringRoleNote)
		formMethods.setValue("managingAtHomeNote", currentState.managingAtHomeNote)
		formMethods.setValue("timeForYourselfNote", currentState.timeForYourselfNote)
		formMethods.setValue("howYouFeelNote", currentState.howYouFeelNote)
		formMethods.setValue("financesNote", currentState.financesNote)
		formMethods.setValue("workNote", currentState.workNote)
		formMethods.setValue("otherNote", currentState.otherNote)

		// set star spike
		setStarSpike(
			starSpike.map((sp) => {
				// find form star spike
				const target = currentState.starSpike.find((s) => s.label === sp.label)
				if (!target) return sp
				return target
			}),
		)

		// set action plan
		if (currentState.actionPlans && currentState.actionPlans.length > 0) {
			const placeHolder: number[] = []
			let nextIndex = 0
			currentState.actionPlans.forEach((ap, i) => {
				placeHolder.push(i)
				nextIndex += 1
				formMethods.setValue(`priorityAreaAndStageStep${i}`, ap.priorityAreaAndStageStep)
				formMethods.setValue(`goal${i}`, ap.goal)
				formMethods.setValue(`action${i}`, ap.action)
				formMethods.setValue(`byWho${i}`, ap.byWho)
				formMethods.setValue(`byWhen${i}`, ap.byWhen ? moment(ap.byWhen).toDate() : null)
			})
			setActionPlanPlaceholder(placeHolder)
			setActionPlanNextIndex(nextIndex)
		}
	}

	const formGet = useParameterizedQuery<Form<CarersStarFormInput>>(fetching.query.getForm)
	React.useEffect(() => {
		if (!formID) return
		formGet.query(formID).then((resp) => {
			if (resp.error || !resp.payload) return
			setFormData(resp.payload.content)
		})
	}, [formID]) // eslint-disable-line react-hooks/exhaustive-deps

	const formCreate = useMutation(fetching.mutation.formCreate)
	const formUpdate = useMutation(fetching.mutation.formUpdate)
	const selectStarDegree = (label: StarChartLabel, value: number) => {
		setFormDirty(true)
		setStarSpike(
			starSpike.map((st) => {
				// if not match, return original data
				if (st.label !== label) return st

				// otherwise, return new value
				return { label, value }
			}),
		)
	}

	// cache data before edit
	const stateBeforeEdit = React.useRef<CarersStarFormInput>()

	const parseContent = (): CarersStarFormInput => {
		const formData = formMethods.getValues()
		// parse action plan card
		const actionPlans: CarersStarActionPlanInput[] = []
		actionPlanPlaceholder.forEach((index) => {
			return actionPlans.push({
				priorityAreaAndStageStep: formData[`priorityAreaAndStageStep${index}`],
				goal: formData[`goal${index}`],
				action: formData[`action${index}`],
				byWho: formData[`byWho${index}`],
				byWhen: formData[`byWhen${index}`],
			})
		})

		// prepare form input
		return {
			carerStarClient: formData.carerStarClient,
			carerStarWorker: formData.carerStarWorker,
			carersStarType: formData.carersStarType,
			dateOfCompletion: formData.dateOfCompletion,
			completedBy: formData.completedBy,
			healthNote: formData.healthNote,
			caringRoleNote: formData.caringRoleNote,
			managingAtHomeNote: formData.managingAtHomeNote,
			timeForYourselfNote: formData.timeForYourselfNote,
			howYouFeelNote: formData.howYouFeelNote,
			financesNote: formData.financesNote,
			workNote: formData.workNote,
			otherNote: formData.otherNote,
			starSpike,
			actionPlans,
		}
	}

	const [isEditMode, setIsEditMode] = React.useState(!formID)
	const onSubmit = async () => {
		const isValid = await formMethods.trigger(["carerStarWorker", "carerStarClient", "dateOfCompletion"])
		if (!isValid) return
		const formData = formMethods.getValues()
		const content: CarersStarFormInput = parseContent()

		if (formID) {
			const resp = await formUpdate.mutate({
				id: formID,
				content,
			})

			if (resp.error || !resp.payload) return
			setFormDirty(false)
			setIsEditMode(false)
			showToast("Carer Star document updated successfully.", "positive")
			return
		}

		const resp = await formCreate.mutate({
			input: {
				clientID: formData.carerStarClient[0].id,
				content,
				date: formData.dateOfCompletion,
				type: FormType.CarersStar,
				name: `${FormTypeName(FormType.CarersStar)} Form | ${formData.carerStarClient[0].firstName} ${
					formData.carerStarClient[0].lastName
				} | ${formData.dateOfCompletion.toLocaleDateString(undefined, {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				})}`,
			},
		})

		if (resp.error || !resp.payload) return
		setFormDirty(false)
		showToast("Carer Star document created successfully.", "positive")
		history.push(`/portal/clients/${formData.carerStarClient[0].id}#forms`)
	}

	const isLoading = formCreate.loading || formUpdate.loading || formGet.loading

	const [formDirty, setFormDirty] = React.useState(false)
	const cancelLabel = (): string => {
		if (!formID || isEditMode) return "Cancel"
		return "Exit"
	}

	React.useEffect(() => {
		if (formMethods.formState.isDirty || formMethods.formState.isSubmitting) {
			setFormDirty(true)
		}
	}, [formMethods.formState.isDirty, formMethods.formState.isSubmitting])

	React.useEffect(() => {
		if (formMethods.formState.isSubmitted) {
			setFormDirty(false)
		}
	}, [formMethods.formState.isSubmitted])

	const cancelFn = () => {
		if (!formID && clientID) {
			history.push(`/portal/clients/${clientID}#forms`)
			return
		}
		const client = formMethods.getValues().client
		if (client && client.length > 0) {
			history.push(`/portal/clients/${client[0].id}#forms`)
			return
		}

		history.push(routes.forms.root)
	}

	const saveLabel = (): string => {
		if (!formID) return "Submit"
		return "Save"
	}

	const formStyle = css({
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		opacity: isLoading ? 0.5 : 1,
		position: isLoading ? "relative" : "unset",
	})
	const scrollingDiv = css({
		maxHeight: "100%",
		height: "100%",
		width: "100%",
		paddingRight: "8px",
		overflowX: "hidden",
		overflowY: "auto",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "8px",
	})
	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
	})

	return (
		<ZenCard style={{ width: "100%", height: "100%" }}>
			<FormProvider {...formMethods}>
				<div className={formStyle}>
					{isLoading && (
						<div className={loadingIcon}>
							<StyledSpinnerNext />
						</div>
					)}
					{formCreate.error && <ErrorNotification messageOrPayload={formCreate.payload} />}
					{formUpdate.error && <ErrorNotification messageOrPayload={formUpdate.payload} />}

					<div className={title}>
						<LabelLarge>Carers Star</LabelLarge>
						{!!formID && !isEditMode && (
							<ZenButton
								onClick={() => {
									// save current status
									stateBeforeEdit.current = parseContent()
									setFormDirty(true)
									setIsEditMode(true)
								}}
								height="38px"
								width="100px"
							>
								Edit
							</ZenButton>
						)}
					</div>
					<div className={scrollingDiv}>
						<CarersStar
							starSpike={starSpike}
							selectStarDegree={selectStarDegree}
							actionPlanPlaceholder={actionPlanPlaceholder}
							setActionPlanPlaceholder={setActionPlanPlaceholder}
							actionPlanNextIndex={actionPlanNextIndex}
							setActionPlanNextIndex={setActionPlanNextIndex}
							isReadOnly={!!formID && !isEditMode}
						/>
					</div>
					<ErrorFieldTracker errorIDs={Object.keys(formMethods.errors)} />
					<CancelAndSaveButtons cancelFn={cancelFn} cancelLabel={cancelLabel()} saveLabel={saveLabel()} omitSave={!!formID && !isEditMode} saveFn={onSubmit} />
					<Prompt
						when={formDirty}
						message={() => {
							return "You have unsaved changes, are you sure you want to leave?"
						}}
					/>
				</div>
			</FormProvider>
		</ZenCard>
	)
}

interface CarersStarProps {
	starSpike: {
		label: StarChartLabel
		value: number
	}[]
	selectStarDegree: (label: StarChartLabel, value: number) => void
	actionPlanPlaceholder: number[]
	setActionPlanPlaceholder: React.Dispatch<React.SetStateAction<number[]>>
	actionPlanNextIndex: number
	setActionPlanNextIndex: React.Dispatch<React.SetStateAction<number>>
	starScale?: number
	isIntake?: boolean
	isReadOnly?: boolean
}
export const CarersStar = (props: CarersStarProps) => {
	const {
		isIntake,
		starSpike,
		selectStarDegree,
		actionPlanPlaceholder,
		setActionPlanPlaceholder,
		actionPlanNextIndex,
		setActionPlanNextIndex,
		starScale,
		isReadOnly,
	} = props
	const [css] = useStyletron()
	const { control, errors, getValues } = useFormContext()
	const addCard = () => {
		if (isReadOnly) return
		setActionPlanPlaceholder(actionPlanPlaceholder.concat(actionPlanNextIndex))
		setActionPlanNextIndex(actionPlanNextIndex + 1)
	}
	const removeCard = (index: number) => {
		if (isReadOnly) return
		setActionPlanPlaceholder(actionPlanPlaceholder.filter((p) => p !== index))
	}
	const group = css({
		display: "flex",
		width: "100%",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
		},
	})
	const starContainer = css({
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginTop: "20px",
		width: "100%",
		minHeight: "700px",
		"@media only screen and (max-width: 700px)": {
			transform: "scale(.5)",
		},
	})
	const central = css({
		marginTop: "60px",
		position: "relative",
		width: "1px",
		height: "1px",
		transform: `scale(${starScale || 1})`,
	})
	const bookMarkContainer = css({
		position: "absolute",
		height: "fit-content",
		width: "200px",
		top: "-24rem",
		left: "-22rem",
		display: "flex",
		flexDirection: "column",
	})
	const centralDot = css({
		position: "absolute",
		height: "3rem",
		width: "3rem",
		top: "-1.5rem",
		left: "-1.5rem",
		backgroundColor: "white",
		borderRadius: "100px",
		zIndex: 4,
	})
	return (
		<>
			<div className={group}>
				<ZenUserSelect
					label="Worker"
					formRef={control}
					formName="carerStarWorker"
					clearable={false}
					disabled={isReadOnly || isIntake}
					width="100%"
					inputError={errors.carerStarWorker}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
						},
					}}
				/>
				<Spacer style={{ width: "40px" }} />
				<ZenClientSelect
					label="Client"
					formRef={control}
					formName="carerStarClient"
					clearable={false}
					disabled={isReadOnly || isIntake}
					width="100%"
					inputError={errors.carerStarClient}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Client is required",
						},
					}}
				/>
			</div>
			<ZenSelect
				label="Type"
				formName="carersStarType"
				disabled={isReadOnly}
				getValues={getValues}
				searchable={false}
				formRef={control}
				options={inputTypeOptions.map((it) => ({ id: it, label: it }))}
			/>
			<div id="dateOfCompletion">
				<ZenDatePicker
					label="Date of completion"
					formName="dateOfCompletion"
					getValues={getValues}
					formRef={control}
					disabled={isReadOnly}
					nullDefaultValue
					inputError={errors.dateOfCompletion}
					formRules={{
						validate: {
							required: (value: Date) => !!value || "Date of completion is required",
						},
					}}
				/>
			</div>
			<ZenSelect
				label="Completed By"
				formName="completedBy"
				disabled={isReadOnly}
				getValues={getValues}
				formRef={control}
				options={completedByOptions.map((it) => ({ id: it, label: it }))}
			/>
			<div className={starContainer}>
				<div className={central}>
					<div className={bookMarkContainer}>
						{bookMark.map((b, i) => (
							<BookMark {...b} key={i} index={i} />
						))}
					</div>
					{starSpike.map((st, i) => (
						<React.Fragment key={i}>
							<TriangleBorder deg={(360 * i + 180) / starSpike.length} />
							<Triangle starSpike={st} deg={(360 * i + 180) / starSpike.length} />
							<ButtonBar
								selectDegree={(v) => {
									if (isReadOnly) return
									selectStarDegree(st.label, v)
								}}
								value={st.value}
								deg={(360 * i + 180) / starSpike.length}
							/>
						</React.Fragment>
					))}
					<div className={centralDot} />
				</div>
			</div>
			<DisplayXSmall
				marginTop="8px"
				marginBottom="8px"
				color={PrimaryPurple}
				overrides={{
					Block: {
						style: {
							fontWeight: "bold",
						},
					},
				}}
			>
				Star notes
			</DisplayXSmall>
			<CarersStarNoteTextarea disabled={isReadOnly} getValues={getValues} label="1. Health" name="healthNote" control={control} />
			<CarersStarNoteTextarea disabled={isReadOnly} getValues={getValues} label="2. The caring role" name="caringRoleNote" control={control} />
			<CarersStarNoteTextarea disabled={isReadOnly} getValues={getValues} label="3. Managing at home" name="managingAtHomeNote" control={control} />
			<CarersStarNoteTextarea disabled={isReadOnly} getValues={getValues} label="4. Time for yourself" name="timeForYourselfNote" control={control} />
			<CarersStarNoteTextarea disabled={isReadOnly} getValues={getValues} label="5. How you feel" name="howYouFeelNote" control={control} />
			<CarersStarNoteTextarea disabled={isReadOnly} getValues={getValues} label="6. Finances" name="financesNote" control={control} />
			<CarersStarNoteTextarea disabled={isReadOnly} getValues={getValues} label="7. Work" name="workNote" control={control} />
			<CarersStarNoteTextarea disabled={isReadOnly} getValues={getValues} label="Other notes" name="otherNote" control={control} />
			<DisplayXSmall
				marginTop="8px"
				marginBottom="8px"
				color={PrimaryPurple}
				overrides={{
					Block: {
						style: {
							fontWeight: "bold",
						},
					},
				}}
			>
				Action plan
			</DisplayXSmall>
			{actionPlanPlaceholder.map((app, i) => (
				<ActionPlanCard isIntake={isIntake} disabled={isReadOnly} getValues={getValues} key={i} control={control} index={app} clear={removeCard} />
			))}
			{!isReadOnly && (
				<ZenButton width="100%" onClick={addCard} type="button">
					<LabelMedium color="white">Add new action plan card</LabelMedium>
				</ZenButton>
			)}
		</>
	)
}

interface ActionPlanCardProps {
	control: Control<Record<string, any>>
	index: number
	disabled?: boolean
	isIntake?: boolean
	clear: (index: number) => void
	getValues: () => any
}
const ActionPlanCard = (props: ActionPlanCardProps) => {
	const { control, index, clear, disabled, getValues, isIntake = false } = props
	const [css] = useStyletron()
	const container = css({
		padding: "10px",
		border: "3px solid " + PrimaryPurple,
		borderRadius: "8px",
		marginBottom: "10px",
		position: "relative",
	})
	const clearIcon = css({
		position: "absolute",
		cursor: "pointer",
		top: "8px",
		right: "15px",
	})
	const group = css({
		display: "flex",
		width: "100%",
	})
	return (
		<div className={container}>
			<ZenInput formRef={control} getValues={getValues} disabled={disabled} label="Priority area and stage/step" nameRef={`priorityAreaAndStageStep${index}`} />
			{!disabled && index > 0 && (
				<div className={clearIcon} onClick={() => clear(index)}>
					<FontAwesomeIcon icon={["fas", "times-circle"]} />
				</div>
			)}
			<ZenTextArea disabled={disabled} getValues={getValues} formRef={control} label="Goal" nameRef={`goal${index}`} initialHeight={200} />
			<ZenTextArea disabled={disabled} getValues={getValues} formRef={control} label="Action" nameRef={`action${index}`} />
			<div className={group}>
				{!isIntake ? (
					<ZenUserSelect label="By who?" formRef={control} formName={`byWho${index}`} clearable={false} width="100%" disabled={disabled} placeholder="" />
				) : (
					<ZenInput formRef={control} getValues={getValues} disabled={disabled} label="By who?" nameRef={`byWho${index}`} width="100%" />
				)}
				<Spacer style={{ width: "20px" }} />
				<div style={{ width: "100%" }}>
					<ZenDatePicker getValues={getValues} disabled={disabled} formRef={control} label="By when? (date)" formName={`byWhen${index}`} nullDefaultValue />
				</div>
			</div>
		</div>
	)
}

interface StarNoteTextareaProps {
	label: string
	name: string
	control: Control<Record<string, any>>
	getValues: () => any
	disabled?: boolean
}
const CarersStarNoteTextarea = (props: StarNoteTextareaProps) => {
	const { control, name, label, disabled, getValues } = props
	const [css] = useStyletron()
	const container = css({
		border: "3px solid " + PrimaryPurple,
		borderRadius: "8px",
		padding: "5px",
		marginBottom: "8px",
	})
	const displayMessage = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "fit-content",
		marginBottom: "8px",
	})
	if (disabled)
		return (
			<div className={displayMessage}>
				<LabelMedium marginBottom="4px">{label}</LabelMedium>
				<LabelSmall
					marginLeft="16px"
					overrides={{
						Block: {
							style: {
								whiteSpace: "pre-line",
								color: ZenTheme.colors.primaryGrey,
							},
						},
					}}
				>
					{getValues()[`${name}`]}
				</LabelSmall>
			</div>
		)
	return (
		<div className={container}>
			<FormControl label={<LabelMedium>{label}</LabelMedium>}>
				<Controller
					name={name}
					disabled={disabled}
					control={control}
					as={Textarea}
					defaultValue=""
					placeholder=" "
					overrides={{
						InputContainer: {
							style: {
								backgroundColor: "white",
							},
						},
					}}
				/>
			</FormControl>
		</div>
	)
}

interface BookMarkProps {
	index: number
	label: string
	hexCode: string
}
const BookMark = (props: BookMarkProps) => {
	const { label, hexCode } = props
	const [css] = useStyletron()
	const bookMark = css({
		backgroundColor: hexCode,
		width: "100%",
		height: "fit-content",
		display: "flex",
		alignItems: "center",
		paddingLeft: "8px",
		paddingTop: "3px",
		paddingBottom: "3px",
		borderRadius: "8px",
		marginBottom: "4px",
	})
	return (
		<div className={bookMark}>
			<LabelSmall color="white">{label}</LabelSmall>
		</div>
	)
}

interface AngleProps {
	deg: number
}

interface ButtonBarProps extends AngleProps {
	value: number
	selectDegree: (value: number) => void
}

const ButtonBar = (props: ButtonBarProps) => {
	const { deg, value, selectDegree } = props
	const [css] = useStyletron()
	const buttonContent = [1, 2, 3, 4, 5]
	const buttonBar = css({
		position: "absolute",
		width: "3rem",
		height: "12rem",
		zIndex: 4,
		transform: `rotate(${deg}deg)`,
		transformOrigin: "top center",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		top: 0,
		left: "-1.5rem",
		paddingTop: "30px",
	})
	const button = css({
		height: "20px",
		width: "20px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "10px",
		marginTop: "16px",
		transform: deg > 100 && deg < 250 ? "rotate(180deg)" : "unset",
		cursor: "pointer",
	})
	return (
		<div className={buttonBar}>
			{buttonContent.map((c, i) => (
				<div key={i} className={button} onClick={() => selectDegree(c)} style={{ backgroundColor: value >= c ? PrimaryPurple : "white" }}>
					<LabelSmall color={value >= c ? "white" : "black"}>{c}</LabelSmall>
				</div>
			))}
		</div>
	)
}

const TriangleBorder = (props: AngleProps) => {
	const { deg } = props
	const [css] = useStyletron()
	const container = css({
		position: "absolute",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		transform: `rotate(${deg}deg)`,
		transformOrigin: "top center",
		paddingTop: "8px",
		left: "-4rem",
	})
	const triangle = css({
		width: 0,
		height: 0,
		paddingTop: "3px",
		borderRight: "4rem solid transparent",
		borderTop: "16rem solid " + PrimaryPurple,
		borderLeft: "4rem solid transparent",
	})

	return (
		<div className={container}>
			<div className={triangle} />
		</div>
	)
}

interface TriangleProps extends AngleProps {
	starSpike: {
		label: StarChartLabel
		value: number
	}
}
const Triangle = (props: TriangleProps) => {
	const { deg, starSpike } = props
	const [css] = useStyletron()
	const triangleBackground = css({
		position: "absolute",
		zIndex: 2,
		width: 0,
		height: 0,
		left: "-4rem",
		borderRight: "4rem solid transparent",
		borderTop: "16rem solid " + SecondaryPurple,
		borderLeft: "4rem solid transparent",
		transform: `rotate(${deg}deg)`,
		transformOrigin: "top center",
	})
	const labelBoxContainer = css({
		position: "absolute",
		paddingTop: "20rem",
		width: "3rem",
		height: "3rem",
		top: 0,
		left: "-1.5rem",
		transform: `rotate(${deg}deg)`,
		transformOrigin: "top center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
	})
	const labelParent = css({
		position: "relative",
		width: "3rem",
		height: "3rem",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		transform: `rotate(-${deg}deg)`,
	})
	const labelBox = css({
		border: `3px solid ${starSpike.value > 0 ? bookMark[5 - starSpike.value].hexCode : PrimaryPurple}`,
		borderRadius: "25px",
		position: "absolute",
		backgroundColor: `${starSpike.value > 0 ? bookMark[5 - starSpike.value].hexCode + "40" : "white"}`,
		height: "50px",
		width: "110px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		padding: "15px",
	})

	return (
		<>
			<div className={triangleBackground} />
			<div className={labelBoxContainer}>
				<div className={labelParent}>
					<div className={labelBox}>
						<LabelSmall>{starSpike.label}</LabelSmall>
					</div>
				</div>
			</div>
		</>
	)
}
