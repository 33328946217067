import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "baseui/button"
import { StatefulMenu } from "baseui/menu"
import { StatefulPopover } from "baseui/popover"
import { Tab, TabOverrides, Tabs } from "baseui/tabs-motion"
import { useHistory } from "react-router-dom"
import { AuthContainer } from "../../controllers/auth"
import { ZenTheme } from "../../themeOverrides"
import { RolePermission } from "../../types/types"

interface ViewClientFunctionBarProps {
	activeKey: string
	setActiveKey: React.Dispatch<React.SetStateAction<string>>
}
export const ViewClientFunctionBar = (props: ViewClientFunctionBarProps) => {
	const { hasPermission } = AuthContainer.useContainer()
	const { activeKey, setActiveKey } = props
	const history = useHistory()
	const [isOpen, setIsOpen] = React.useState(false)

	const menuItems = (close: () => void) => {
		const items = [
			{
				label: "Client Overview",
				onClick: () => {
					setActiveKey("overview")
					history.push({ hash: "overview" })
					close()
				},
			},
			{
				label: "Health Team",
				onClick: () => {
					setActiveKey("healthTeam")
					history.push({ hash: "healthTeam" })
					close()
				},
			},
			{
				label: "Client Contacts",
				onClick: () => {
					setActiveKey("contacts")
					history.push({ hash: "contacts" })
					close()
				},
			},
			{
				label: "Forms & Docs",
				onClick: () => {
					setActiveKey("forms")
					history.push({ hash: "forms" })
					close()
				},
			},
			{
				label: "Travels",
				onClick: () => {
					setActiveKey("travels")
					history.push({ hash: "travels" })
					close()
				},
			},
			{
				label: "Sessions",
				onClick: () => {
					setActiveKey("sessions")
					history.push({ hash: "sessions" })
					close()
				},
			},
			{
				label: "Call Logs",
				onClick: () => {
					setActiveKey("callLogs")
					history.push({ hash: "callLogs" })
					close()
				},
			},
			{
				label: "Notebook",
				onClick: () => {
					setActiveKey("notebook")
					history.push({ hash: "notebook" })
					close()
				},
			},
		]

		if (hasPermission(RolePermission.NDISPlanRead)) {
			items.push({
				label: "NDIS",
				onClick: () => {
					setActiveKey("ndis")
					history.push({ hash: "ndis" })
					close()
				},
			})
		}

		return items
	}

	const getActiveKeyTitle = () => {
		switch (activeKey.toString()) {
			case "overview": // client overview page
				return "Client Overview"
			case "forms":
				return "Forms & Docs"
			case "healthTeam":
				return "Health Team"
			case "travels":
				return "Travels"
			case "sessions":
				return "Sessions"
			case "ndis":
				return "NDIS"
			case "callLogs":
				return "Call Logs"
			case "contacts":
				return "Client Contacts"
			case "notebook":
				return "Notebook"
			default:
				return "Select a tab"
		}
	}

	const tabOverrides: TabOverrides = {
		TabPanel: {
			style: {
				display: "none",
			},
		},
		Tab: {
			style: {
				fontWeight: "bold",
				fontSize: "16px",
				whiteSpace: "nowrap",
				background: "unset",
			},
		},
	}

	return (
		<>
			<Tabs
				activeKey={activeKey}
				onChange={({ activeKey }) => {
					setActiveKey(activeKey.toString())
					history.push({ hash: activeKey.toString() })
				}}
				overrides={{
					Root: {
						style: {
							"@media only screen and (max-width: 1000px)": {
								display: "none",
							},
						},
					},
					TabList: {
						style: {
							backgroundColor: "transparent",
						},
					},
					TabHighlight: {
						style: {
							backgroundColor: ZenTheme.colors.primaryGreen,
						},
					},
				}}
			>
				<Tab title="Client Overview" key="overview" overrides={tabOverrides} />
				<Tab title="Health Team" key="healthTeam" overrides={tabOverrides} />
				<Tab title="Client Contacts" key="contacts" overrides={tabOverrides} />
				<Tab title="Forms & Docs" key="forms" overrides={tabOverrides} />
				{hasPermission(RolePermission.NDISPlanRead) && <Tab title="NDIS" key="ndis" overrides={tabOverrides} />}
				<Tab title="Travels" key="travels" overrides={tabOverrides} />
				<Tab title="Sessions" key="sessions" overrides={tabOverrides} />
				<Tab title="Call Logs" key="callLogs" overrides={tabOverrides} />
				<Tab title="Notebook" key="notebook" overrides={tabOverrides} />
			</Tabs>
			<StatefulPopover
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
				overrides={{
					Body: {
						style: {
							width: "70vw",
							zIndex: 12,
							":focus": {
								borderColor: "black",
							},
						},
					},
				}}
				content={({ close }) => (
					<StatefulMenu
						onItemSelect={(selection) => {
							selection.item.onClick && selection.item.onClick()
						}}
						items={menuItems(close)}
					/>
				)}
				returnFocus
				autoFocus
			>
				<Button
					overrides={{
						Root: {
							style: {
								display: "none",
								"@media only screen and (max-width: 1000px)": {
									display: "inline-flex",
								},
							},
						},
					}}
					endEnhancer={<FontAwesomeIcon icon={["fal", isOpen ? "chevron-up" : "chevron-down"]} size={"xs"} />}
					onClick={() => setIsOpen(true)}
				>
					{getActiveKeyTitle()}
				</Button>
			</StatefulPopover>
		</>
	)
}
