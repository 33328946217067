import * as React from "react"
import { BasicName } from "../../../types/types"
import { useStyletron } from "baseui"
import { Divider, SearchAndFilter, ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ListTable } from "../../../components/listTable"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "baseui/button"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { useDebounce } from "../../../helpers/utils"
import { Value } from "baseui/select"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { fetching } from "../../../fetching"
import { ZenTheme } from "../../../themeOverrides"
import { LabelLarge, LabelMedium } from "baseui/typography"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { useForm } from "react-hook-form"
import { PlaceholderPanel } from "./placeholderPanel"

enum FilterOption {
	Active = "Active",
	Archive = "Archive",
}

export const DepartmentPositionOptionManagement = () => {
	const [css] = useStyletron()
	const outer = css({
		display: "flex",
		flexDirection: "column",
		maxWidth: "calc(min(1280px, 100%))",
		width: "100%",
		height: "100%",
	})
	const [selectedDepartment, setSelectedDepartment] = React.useState<BasicName>()
	const displayPositions = () => {
		if (!selectedDepartment) return <PlaceholderPanel height="50%" text="Please select a department to view positions" />
		return <Positions selectedDepartment={selectedDepartment} />
	}
	return (
		<div className={outer}>
			<Departments selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} />
			<Divider style={{ backgroundColor: "transparent" }} />
			{displayPositions()}
		</div>
	)
}
interface DepartmentProps {
	selectedDepartment: BasicName | undefined
	setSelectedDepartment: React.Dispatch<React.SetStateAction<BasicName | undefined>>
}

const Departments = (props: DepartmentProps) => {
	const { selectedDepartment, setSelectedDepartment } = props
	const [css] = useStyletron()
	const container = css({
		height: "50%",
		flex: "unset !important",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [departments, setDepartments] = React.useState<BasicName[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, name: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(10)

	const departmentMany = useQuery(
		fetching.query.getDepartmentMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
		}),
	)
	React.useEffect(() => {
		if (departmentMany.error || !departmentMany.payload) return
		setTotal(departmentMany.payload.total)
		setDepartments(departmentMany.payload.departments)
	}, [departmentMany.payload, departmentMany.error])

	const departmentArchive = useMutation(fetching.mutation.departmentArchive)
	const departmentUnarchive = useMutation(fetching.mutation.departmentUnarchive)

	const [targetedDepartment, setTargetedDepartment] = React.useState<BasicName>()
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)

	return (
		<ZenCard className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={(v) => {
						setSelectedDepartment(undefined)
						setFilter(v)
					}}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Department</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{departmentMany.error && <ErrorNotification messageOrPayload={departmentMany.payload} />}
			{departmentArchive.error && <ErrorNotification messageOrPayload={departmentArchive.payload} />}
			{departmentUnarchive.error && <ErrorNotification messageOrPayload={departmentUnarchive.payload} />}
			<ListTable
				isLoading={departmentMany.loading || departmentArchive.loading || departmentUnarchive.loading}
				rows={departments}
				selectedID={selectedDepartment?.id}
				onRowClick={(row: BasicName) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedDepartment(row)
						setOpenUnarchiveModal(true)
						return
					}
					setSelectedDepartment(row)
				}}
				columns={[
					{
						id: "name",
						header: "Department",
						resolver: (row: BasicName) => row.name,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: BasicName) => {
							const isHighlighted = selectedDepartment?.id === row.id
							return (
								<div className={row.deletedAt ? actionButtonHeader : actionButton}>
									{!row.deletedAt && (
										<Button
											kind="minimal"
											onClick={(e) => {
												e.stopPropagation()
												setOpenUpdateModal(true)
												setTargetedDepartment(row)
											}}
										>
											<FontAwesomeIcon color={isHighlighted ? "white" : ZenTheme.colors.primaryGreen} size={"1x"} icon={["fal", "edit"]} />
										</Button>
									)}
									<Button
										kind="minimal"
										onClick={(e) => {
											e.stopPropagation()
											setTargetedDepartment(row)
											if (!row.deletedAt) {
												setOpenArchiveModal(true)
												return
											}
											setOpenUnarchiveModal(true)
										}}
									>
										<FontAwesomeIcon
											color={isHighlighted ? "white" : row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
											size={"1x"}
											icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
										/>
									</Button>
									{targetedDepartment?.id === row.id && (
										<div onClick={(e) => e.stopPropagation()}>
											{openArchiveModal && (
												<ZenArchiveModal
													open={openArchiveModal}
													loading={departmentMany.loading || departmentArchive.loading}
													message={row.name}
													onClose={() => setOpenArchiveModal(false)}
													confirmArchive={() => {
														departmentArchive.mutate(row.id).then((resp) => {
															if (resp.error || !resp.payload) return
															if (selectedDepartment?.id === row.id) {
																setSelectedDepartment(undefined)
															}
															departmentMany.query()
															setOpenArchiveModal(false)
														})
													}}
												/>
											)}
											{openUnarchiveModal && (
												<ZenArchiveModal
													open={openUnarchiveModal}
													loading={departmentMany.loading || departmentUnarchive.loading}
													message={row.name}
													onClose={() => setOpenUnarchiveModal(false)}
													restoreMode
													confirmArchive={() => {
														departmentUnarchive.mutate(row.id).then((resp) => {
															if (resp.error || !resp.payload) return
															departmentMany.query()
															setOpenUnarchiveModal(false)
														})
													}}
												/>
											)}
										</div>
									)}
								</div>
							)
						},
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && targetedDepartment && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<DepartmentForm
						department={targetedDepartment}
						onClose={() => {
							setOpenUpdateModal(false)
							setTargetedDepartment(undefined)
						}}
						queryDepartments={departmentMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<DepartmentForm
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryDepartments={departmentMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface DepartmentFormProps {
	department?: BasicName
	onClose: () => void
	queryDepartments: () => Promise<
		QueryResponse<{
			departments: BasicName[]
			total: number
		}>
	>
}
const DepartmentForm = (props: DepartmentFormProps) => {
	const { department, onClose, queryDepartments } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedDepartment, setDuplicatedDepartment] = React.useState<BasicName>()

	const departmentCreate = useMutation(fetching.mutation.departmentCreate)
	const departmentUpdate = useMutation(fetching.mutation.departmentUpdate)
	const departmentUnarchive = useMutation(fetching.mutation.departmentUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!department) return
		setValue("name", department.name)
	}, [department, setValue])

	const onSubmit = (formData: any) => {
		if (department?.name === formData.name) {
			onClose()
			return
		}

		// update existing department
		if (department) {
			departmentUpdate
				.mutate({
					id: department.id,
					name: formData.name,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated department
					if (typeof resp.payload === "object") {
						setDuplicatedDepartment(resp.payload)
						return
					}

					queryDepartments()
					onClose()
				})
			return
		}

		// create new department
		departmentCreate
			.mutate({
				name: formData.name,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated department
				if (typeof resp.payload === "object") {
					setDuplicatedDepartment(resp.payload)
					return
				}

				queryDepartments()
				onClose()
			})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated department after submit
		if (duplicatedDepartment && !!duplicatedDepartment.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The department "{duplicatedDepartment.name}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedDepartment(undefined)}
						saveFn={() => {
							// handle position
							departmentUnarchive.mutate(duplicatedDepartment.id).then((resp) => {
								if (resp.error || !resp.payload) return
								queryDepartments()
								onClose()
							})
						}}
						saveLabel={"Confirm"}
					/>
					{departmentUnarchive.error && <ErrorNotification messageOrPayload={departmentUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!department ? "Update" : "Create"} Department</LabelMedium>
				<ZenInput label="Name" formRef={control} nameRef="name" inputError={errors.name} required />
				<CancelAndSaveButtons
					cancelFn={onClose}
					saveLabel={!!department ? "Save" : "Submit"}
					isLoading={departmentCreate.loading || departmentUpdate.loading || departmentUnarchive.loading}
				/>
				{duplicatedDepartment && <ErrorNotification message={`The department "${duplicatedDepartment.name}" is already exists`} />}
				{departmentCreate.error && <ErrorNotification messageOrPayload={departmentCreate.payload} />}
				{departmentUpdate.error && <ErrorNotification messageOrPayload={departmentUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}

interface PositionProps {
	selectedDepartment: BasicName
}
const Positions = (props: PositionProps) => {
	const { selectedDepartment } = props
	const [css] = useStyletron()
	const container = css({
		height: "50%",
		flex: "unset !important",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [positions, setPositions] = React.useState<BasicName[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, name: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(10)

	const positionMany = useQuery(
		fetching.query.getPositionMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
			departmentID: selectedDepartment.id,
		}),
	)
	React.useEffect(() => {
		if (positionMany.error || !positionMany.payload) return
		setTotal(positionMany.payload.total)
		setPositions(positionMany.payload.positions)
	}, [positionMany.payload, positionMany.error])

	const positionArchive = useMutation(fetching.mutation.positionArchive)
	const positionUnarchive = useMutation(fetching.mutation.positionUnarchive)

	const [targetedPositionID, setTargetedPositionID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedPosition, setSelectedPosition] = React.useState<BasicName>()

	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelLarge>{selectedDepartment.name} - Positions</LabelLarge>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Position</ZenButton>}
			</div>
			<SearchAndFilter
				search={displayKey}
				setSearch={setDisplayKey}
				filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
				filter={filter}
				setFilter={setFilter}
			/>
			<Divider style={{ backgroundColor: "transparent" }} />
			{positionMany.error && <ErrorNotification messageOrPayload={positionMany.payload} />}
			{positionArchive.error && <ErrorNotification messageOrPayload={positionArchive.payload} />}
			{positionUnarchive.error && <ErrorNotification messageOrPayload={positionUnarchive.payload} />}
			<ListTable
				isLoading={positionMany.loading || positionArchive.loading || positionUnarchive.loading}
				rows={positions}
				onRowClick={(row: BasicName) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedPositionID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedPosition(row)
				}}
				columns={[
					{
						id: "name",
						header: "Position",
						resolver: (row: BasicName) => row.name,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: BasicName) => (
							<div className={row.deletedAt ? actionButtonHeader : actionButton}>
								<Button
									kind="minimal"
									onClick={(e) => {
										e.stopPropagation()
										setTargetedPositionID(row.id)
										if (!row.deletedAt) {
											setOpenArchiveModal(true)
											return
										}
										setOpenUnarchiveModal(true)
									}}
								>
									<FontAwesomeIcon
										color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
										size={"1x"}
										icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
									/>
								</Button>
								{targetedPositionID === row.id && (
									<div onClick={(e) => e.stopPropagation()}>
										{openArchiveModal && (
											<ZenArchiveModal
												open={openArchiveModal}
												loading={positionMany.loading || positionArchive.loading}
												message={row.name}
												onClose={() => setOpenArchiveModal(false)}
												confirmArchive={() => {
													positionArchive.mutate({ id: row.id, departmentID: selectedDepartment.id }).then((resp) => {
														if (resp.error || !resp.payload) return
														positionMany.query()
														setOpenArchiveModal(false)
													})
												}}
											/>
										)}
										{openUnarchiveModal && (
											<ZenArchiveModal
												open={openUnarchiveModal}
												loading={positionMany.loading || positionUnarchive.loading}
												message={row.name}
												onClose={() => setOpenUnarchiveModal(false)}
												restoreMode
												confirmArchive={() => {
													positionUnarchive.mutate({ id: row.id, departmentID: selectedDepartment.id }).then((resp) => {
														if (resp.error || !resp.payload) return
														positionMany.query()
														setOpenUnarchiveModal(false)
													})
												}}
											/>
										)}
									</div>
								)}
							</div>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedPosition && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<PositionForm
						position={selectedPosition}
						departmentID={selectedDepartment.id}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedPosition(undefined)
						}}
						queryPositions={positionMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<PositionForm
						departmentID={selectedDepartment.id}
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryPositions={positionMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface PositionFormProps {
	position?: BasicName
	departmentID: string
	onClose: () => void
	queryPositions: () => Promise<
		QueryResponse<{
			positions: BasicName[]
			total: number
		}>
	>
}
const PositionForm = (props: PositionFormProps) => {
	const { position, departmentID, onClose, queryPositions } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedPosition, setDuplicatedPosition] = React.useState<BasicName>()

	const positionCreate = useMutation(fetching.mutation.positionCreate)
	const positionUpdate = useMutation(fetching.mutation.positionUpdate)
	const positionUnarchive = useMutation(fetching.mutation.positionUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!position) return
		setValue("name", position.name)
	}, [position, setValue])

	const onSubmit = (formData: any) => {
		if (position?.name === formData.name) {
			onClose()
			return
		}
		// update existing position
		if (position) {
			positionUpdate
				.mutate({
					id: position.id,
					name: formData.name,
					departmentID,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated position
					if (typeof resp.payload === "object") {
						setDuplicatedPosition(resp.payload)
						return
					}

					queryPositions()
					onClose()
				})
			return
		}

		// create new position
		positionCreate
			.mutate({
				name: formData.name,
				departmentID,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated position
				if (typeof resp.payload === "object") {
					setDuplicatedPosition(resp.payload)
					return
				}

				queryPositions()
				onClose()
			})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated position after submit
		if (duplicatedPosition && !!duplicatedPosition.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The position "{duplicatedPosition.name}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedPosition(undefined)}
						isLoading={positionUnarchive.loading}
						saveFn={() => {
							// handle position
							positionUnarchive.mutate({ id: duplicatedPosition.id, departmentID }).then((resp) => {
								if (resp.error || !resp.payload) return
								queryPositions()
								onClose()
							})
						}}
						saveLabel={"Confirm"}
					/>
					{positionUnarchive.error && <ErrorNotification messageOrPayload={positionUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!position ? "Update" : "Create"} Position</LabelMedium>
				<ZenInput label="Name" formRef={control} nameRef="name" inputError={errors.name} required />
				<CancelAndSaveButtons cancelFn={onClose} saveLabel={!!position ? "Save" : "Submit"} isLoading={positionCreate.loading || positionUpdate.loading} />
				{duplicatedPosition && <ErrorNotification message={`The position "${duplicatedPosition.name}" is already exists`} />}
				{positionCreate.error && <ErrorNotification messageOrPayload={positionCreate.payload} />}
				{positionUpdate.error && <ErrorNotification messageOrPayload={positionUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}
