import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Value } from "baseui/select"
import { LabelMedium } from "baseui/typography"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { Divider, SearchAndFilter, ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ListTable } from "../../../components/listTable"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { fetching } from "../../../fetching"
import { useDebounce } from "../../../helpers/utils"
import { ZenTheme } from "../../../themeOverrides"
import { ServiceProvider } from "../../../types/types"

enum FilterOption {
	Active = "Active",
	Archive = "Archive",
}

export const ServiceProviderOptionManagement = () => {
	const [css] = useStyletron()
	const container = css({
		height: "100%",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [serviceProviders, setServiceProviders] = React.useState<ServiceProvider[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, name: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(20)

	const serviceProviderMany = useQuery(
		fetching.query.getServiceProviderMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
		}),
	)
	React.useEffect(() => {
		if (serviceProviderMany.error || !serviceProviderMany.payload) return
		setTotal(serviceProviderMany.payload.total)
		setServiceProviders(serviceProviderMany.payload.serviceProviders)
	}, [serviceProviderMany.payload, serviceProviderMany.error])

	const serviceProviderArchive = useMutation(fetching.mutation.serviceProviderArchive)
	const serviceProviderUnarchive = useMutation(fetching.mutation.serviceProviderUnarchive)

	const [targetedServiceProviderID, setTargetedServiceProviderID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedServiceProvider, setSelectedServiceProvider] = React.useState<ServiceProvider>()
	return (
		<ZenCard className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={setFilter}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New ServiceProvider</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{serviceProviderMany.error && <ErrorNotification messageOrPayload={serviceProviderMany.payload} />}
			{serviceProviderArchive.error && <ErrorNotification messageOrPayload={serviceProviderArchive.payload} />}
			{serviceProviderUnarchive.error && <ErrorNotification messageOrPayload={serviceProviderUnarchive.payload} />}
			<ListTable
				isLoading={serviceProviderMany.loading || serviceProviderArchive.loading || serviceProviderUnarchive.loading}
				rows={serviceProviders}
				onRowClick={(row: ServiceProvider) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedServiceProviderID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedServiceProvider(row)
				}}
				columns={[
					{
						id: "name",
						header: "Service Provider",
						resolver: (row: ServiceProvider) => row.name,
					},
					{
						id: "Action",
						header: <div className={actionButton}>Action</div>,
						resolver: (row: ServiceProvider) => (
							<div className={actionButton}>
								<Button
									kind="minimal"
									onClick={(e) => {
										e.stopPropagation()
										setTargetedServiceProviderID(row.id)
										if (!row.deletedAt) {
											setOpenArchiveModal(true)
											return
										}
										setOpenUnarchiveModal(true)
									}}
								>
									<FontAwesomeIcon
										color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
										size={"1x"}
										icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
									/>
								</Button>
								{targetedServiceProviderID === row.id && (
									<div onClick={(e) => e.stopPropagation()}>
										{openArchiveModal && (
											<ZenArchiveModal
												open={openArchiveModal}
												loading={serviceProviderArchive.loading || serviceProviderMany.loading}
												message={row.name}
												onClose={() => setOpenArchiveModal(false)}
												confirmArchive={() => {
													serviceProviderArchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														serviceProviderMany.query()
														setOpenArchiveModal(false)
													})
												}}
											/>
										)}
										{openUnarchiveModal && (
											<ZenArchiveModal
												open={openUnarchiveModal}
												loading={serviceProviderUnarchive.loading || serviceProviderMany.loading}
												message={row.name}
												onClose={() => setOpenUnarchiveModal(false)}
												restoreMode
												confirmArchive={() => {
													serviceProviderUnarchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														serviceProviderMany.query()
														setOpenUnarchiveModal(false)
													})
												}}
											/>
										)}
									</div>
								)}
							</div>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedServiceProvider && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<ServiceProviderForm
						serviceProvider={selectedServiceProvider}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedServiceProvider(undefined)
						}}
						queryServiceProviders={serviceProviderMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<ServiceProviderForm
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryServiceProviders={serviceProviderMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface ServiceProviderFormProps {
	serviceProvider?: ServiceProvider
	onClose: () => void
	queryServiceProviders: () => Promise<
		QueryResponse<{
			serviceProviders: ServiceProvider[]
			total: number
		}>
	>
}
const ServiceProviderForm = (props: ServiceProviderFormProps) => {
	const { serviceProvider, onClose, queryServiceProviders } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedServiceProvider, setDuplicatedServiceProvider] = React.useState<ServiceProvider>()

	const serviceProviderCreate = useMutation(fetching.mutation.serviceProviderCreate)
	const serviceProviderUpdate = useMutation(fetching.mutation.serviceProviderUpdate)
	const serviceProviderUnarchive = useMutation(fetching.mutation.serviceProviderUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!serviceProvider) return
		setValue("name", serviceProvider.name)
	}, [serviceProvider, setValue])

	const onSubmit = (formData: any) => {
		// update existing serviceProvider
		if (serviceProvider) {
			// close modal if the serviceProvider name is unchanged
			if (serviceProvider.name === formData.name) {
				onClose()
				return
			}

			serviceProviderUpdate
				.mutate({
					id: serviceProvider.id,
					...formData,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated serviceProvider
					if (typeof resp.payload === "object") {
						setDuplicatedServiceProvider(resp.payload)
						return
					}

					queryServiceProviders()
					onClose()
				})
			return
		}

		// create new serviceProvider
		serviceProviderCreate.mutate(formData).then((resp) => {
			if (resp.error || !resp.payload) return
			// if contain duplicated serviceProvider
			if (typeof resp.payload === "object") {
				setDuplicatedServiceProvider(resp.payload)
				return
			}

			queryServiceProviders()
			onClose()
		})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated serviceProvider after submit
		if (duplicatedServiceProvider && !!duplicatedServiceProvider.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The service provider "{duplicatedServiceProvider.name}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedServiceProvider(undefined)}
						saveFn={() =>
							serviceProviderUnarchive.mutate(duplicatedServiceProvider.id).then((resp) => {
								if (resp.error || !resp.payload) return
								queryServiceProviders()
								onClose()
							})
						}
						isLoading={serviceProviderUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{serviceProviderUnarchive.error && <ErrorNotification messageOrPayload={serviceProviderUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!serviceProvider ? "Rename" : "Create"} Service Provider</LabelMedium>
				<ZenInput name="Name" formRef={control} nameRef="name" inputError={errors.name} required />
				<CancelAndSaveButtons
					cancelFn={onClose}
					saveLabel={!!serviceProvider ? "Save" : "Submit"}
					isLoading={serviceProviderCreate.loading || serviceProviderUpdate.loading}
				/>
				{duplicatedServiceProvider && <ErrorNotification message={`The serviceProvider "${duplicatedServiceProvider.name}" is already exists`} />}
				{serviceProviderCreate.error && <ErrorNotification messageOrPayload={serviceProviderCreate.payload} />}
				{serviceProviderUpdate.error && <ErrorNotification messageOrPayload={serviceProviderUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}
