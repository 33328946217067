import * as React from "react"
import { LabelLarge, LabelSmall, ParagraphSmall } from "baseui/typography"
import { Spacer, ZenCard } from "../../components/common"
import { useStyletron } from "baseui"
import { ZenDatePicker } from "../../components/zenComponents/zenTime"
import { useForm } from "react-hook-form"
import { ZenClientSelect, ZenSelect, ZenUserSelect } from "../../components/zenComponents/zenSelectBox"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ErrorNotification } from "../../components/errorBox"
import { Option } from "baseui/select"
import { useParameterizedQuery, useQuery } from "react-fetching-library"
import { fetching } from "../../fetching"
import { getErrorFromBlob, snakeToTitle, useDebounce } from "../../helpers/utils"
import moment from "moment-timezone"
import { FormControl } from "baseui/form-control"
import { FieldError } from "react-hook-form/dist/types"
import { Notification, KIND } from "baseui/notification"
import { ZenCheckbox } from "../../components/zenComponents/zenCheckboxList"
import { FilterByOptions } from "types/enums"

interface FormValues {
	startDate: string
	endDate: string
	client?: Option[]
	worker?: Option[]
	ndisLineItem?: Option[]
	includeIncomplete: boolean
	attendanceStatus?: Option[]
	filterBy?: Option[]
}

export const ClientHoursReport = () => {
	const [css] = useStyletron()
	const card = css({
		width: "fit-content",
	})
	const dates = css({
		display: "flex",
	})
	const buttons = css({
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "15px",
	})

	const { control, handleSubmit, errors, getValues } = useForm<FormValues>()
	const { query, loading, error } = useParameterizedQuery(fetching.query.getClientHours)
	const [errorMsg, setErrorMsg] = React.useState<string>()
	const [durationWarning, setDurationWarning] = React.useState<string>()
	const [searchKey, setSearchKey] = React.useState<any>("")
	const [lineItemDisplayKey, setLineItemDisplayKey] = React.useState<string>("")
	const debouncedSearchTerm = useDebounce(lineItemDisplayKey, 500)
	React.useEffect(() => setSearchKey(debouncedSearchTerm), [debouncedSearchTerm])
	const { query: searchLineItems, payload: lineItems, loading: lineItemsLoading, error: lineItemsError } = useQuery(
		fetching.query.getNDISLineItems(searchKey),
	)

	React.useEffect(() => {
		if (searchKey === "") return
		searchLineItems()
	}, [searchKey, searchLineItems])

	const onSubmit = async (data: FormValues) => {
        console.log("%c 🛏️: onSubmit -> data ", "font-size:16px;background-color:#db042a;color:white;", data)
		// Check that duration for report period is not more than one year if no other filters are selected. Response size
		// can excessive at this point and takes too long to process. Should not be necessary.
		if (
			moment.duration(moment(data.endDate).diff(moment(data.startDate))) > moment.duration(1, "year") &&
			(!data.client || data.client.length < 1) &&
			(!data.worker || data.worker.length < 1) &&
			(!data.ndisLineItem || data.ndisLineItem.length < 1)
		) {
			setDurationWarning("Report period is longer than 1 year. You must also select a worker, client or support code.")
			return
		}
		setDurationWarning(undefined)

		setErrorMsg(undefined)

		const resp = await query({
			startDate: new Date(data.startDate),
			endDate: new Date(data.endDate),
			clientID: data.client && data.client.length > 0 ? `${data.client[0].id}` : undefined,
			workerID: data.worker && data.worker.length > 0 ? `${data.worker[0].id}` : undefined,
			ndisLineItemID: data.ndisLineItem && data.ndisLineItem.length > 0 ? `${data.ndisLineItem[0].id}` : undefined,
			includeIncomplete: data.includeIncomplete,
			attendanceStatus: data.attendanceStatus ? data.attendanceStatus.map<string>((f) => f.id?.toString() || "") : undefined,
			filterBy: data?.filterBy?.[0].id
		})

		if (resp.error || !resp.payload) {
			setErrorMsg(await getErrorFromBlob(resp.payload))
			return
		}

		// Create link and initiate download when response is received
		const downloadLink = document.createElement("a")
		downloadLink.href = URL.createObjectURL(resp.payload)
		downloadLink.download = "client_hours_report.xlsx"
		downloadLink.click()
	}

	const getLabel = ({ option }: any) => (
		<>
			<LabelSmall>{option?.itemNumber}</LabelSmall>
			{`${option?.itemName}`}
		</>
	)

	return (
		<ZenCard className={card}>
			<LabelLarge>Client Hours Report</LabelLarge>
			<ParagraphSmall maxWidth={"400px"}>Create client hours report here</ParagraphSmall>
			<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<div className={dates}>
					<div>
						<ZenDatePicker
							nullDefaultValue={true}
							formRef={control}
							label={"Start Date"}
							formName={"startDate"}
							inputError={errors.startDate}
							formRules={{
								required: "You must select a start date.",
							}}
						/>
					</div>
					<Spacer style={{ width: "15px" }} />
					<div>
						<ZenDatePicker
							nullDefaultValue={true}
							formRef={control}
							label={"End Date"}
							formName={"endDate"}
							inputError={errors.endDate}
							formRules={{
								required: "You must select an end date.",
								validate: (value: string) => {
									if (moment(value).isBefore(moment(getValues("startDate")))) {
										return "End date must be after start date."
									}
									return null
								},
							}}
						/>
					</div>
				</div>
				<ZenSelect
					multi={true}
					required
					options={[
						{ id: FilterByOptions.Sessions, label: "All clients" },
						{ id: FilterByOptions.ActiveClientsOnly, label: "Active clients only" },
						{ id: FilterByOptions.ArchivedClientsOnly, label: "Archived clients only" },
					]}
					label="Client Hours for"
					formName={"filterBy"}
					formRef={control}
				/>
				<ZenSelect
					multi={true}
					options={[
						{ id: "ATTENDED", label: snakeToTitle("ATTENDED") },
						{ id: "DNA", label: snakeToTitle("DNA") },
						{ id: "SHORT_NOTICE_CANCELLATION", label: snakeToTitle("SHORT_NOTICE_CANCELLATION") },
						{ id: "ENDED_EARLY", label: snakeToTitle("ENDED_EARLY") },
						{ id: "CANCELLED", label: snakeToTitle("CANCELLED") },
					]}
					label="Attendance Status (Optional)"
					formName={"attendanceStatus"}
					formRef={control}
				/>
				<ZenCheckbox
					label="Include incomplete sessions"
					formName="includeIncomplete"
					labelPlacement="left"
					formRef={control}
					checked={false}
					marginTop={"10px"}
					marginBottom={"10px"}
				/>
				<ZenClientSelect label={"Client (Optional)"} formName={"client"} formRef={control} />
				<ZenUserSelect label={"Worker (Optional)"} formName={"worker"} formRef={control} />
				<ZenSelect
					options={lineItems}
					filterOptions={(options) => options}
					isLoading={lineItemsLoading}
					label={"NDIS Support Item (Optional)"}
					formRef={control}
					formName={"ndisLineItem"}
					placeholder={"Search..."}
					labelKey={"itemName"}
					valueKey={"id"}
					getOptionLabel={getLabel}
					getValueLabel={getLabel}
					onInputChange={(e) => setLineItemDisplayKey(e.currentTarget.value)}
					inputError={errors.ndisLineItem ? ((errors.ndisLineItem as unknown) as FieldError) : undefined}
				/>
				<FormControl error={errors.client}>
					<div />
				</FormControl>
				{durationWarning && <Notification kind={KIND.warning}>{durationWarning}</Notification>}
				{lineItemsError && <ErrorNotification messageOrPayload={lineItems} />}
				{error && <ErrorNotification message={errorMsg} />}
				<div className={buttons}>
					<ZenButton type={"submit"} isLoading={loading}>
						Create Report
					</ZenButton>
				</div>
			</form>
		</ZenCard>
	)
}
