import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import { useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ZenFileUploader } from "../../../components/fileUploader"
import { ListTable } from "../../../components/listTable"
import { MenuItem, RowActions } from "../../../components/rowActions"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { PortalContainer } from "../../../controllers/portal"
import { fetching } from "../../../fetching"
import { getErrorMessage } from "../../../helpers/errors"
import { RolePermission, ValueCharge, ValueChargeDetails, ZenDateFormat } from "../../../types/types"
import moment from "moment"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { ZenInput, ZenTextArea } from "../../../components/zenComponents/zenInput"
import { currencyRegex } from "../../../helpers/utils"
import { ZenDatePicker } from "../../../components/zenComponents/zenTime"
import { useZenToast } from "../../../components/zenComponents/useZenToast"
import { AuthContainer } from "../../../controllers/auth"
import { LineItemRow } from "./planDetails"
import { NDISLineItemType } from "../../../types/enums"

const chargeType = (planLine: LineItemRow): NDISLineItemType => {
	if (planLine.isGeneralTransport) return NDISLineItemType.GeneralTransport
	else if (planLine.isTransport) return NDISLineItemType.Transport
	else if (planLine.isTravel) return NDISLineItemType.Travel
	return NDISLineItemType.Support
}

export const ValueCharges = (props: { planLine: LineItemRow; onSuccess: (valueCharge: ValueCharge) => void }) => {
	const [css] = useStyletron()
	const card = css({
		minWidth: "900px",
	})
	const content = css({
		display: "flex",
	})
	const tableContainer = css({
		flex: 3,
	})
	const createContainer = css({
		flex: 1,
		marginLeft: "15px",
	})

	const { showToast } = useZenToast()
	const [deleteCharge, setDeleteCharge] = React.useState<ValueCharge>()
	const { error, loading, payload, query } = useQuery(
		fetching.query.getNDISPlanValueCharges({ planLineID: props.planLine.id, chargeType: chargeType(props.planLine) }),
	)
	const deleteGeneralCharge = useMutation(fetching.mutation.ndisGeneralChargeDelete)
	const { timezone } = PortalContainer.useContainer()
	const { hasPermission } = AuthContainer.useContainer()

	const handleDelete = async () => {
		if (!deleteCharge) return

		const resp = await deleteGeneralCharge.mutate(deleteCharge.id)

		if (!resp.payload || resp.error) {
			return
		}

		props.onSuccess(deleteCharge)

		showToast(`Charge deleted successfully.`, "positive")
		setDeleteCharge(undefined)
		query()
	}

	return (
		<ZenCard className={card}>
			<LabelLarge>{props.planLine.isGeneralTransport ? "General Charges" : "Balance Adjustment"}</LabelLarge>
			<div className={content}>
				<div className={tableContainer}>
					<ListTable
						error={error ? getErrorMessage(payload) : undefined}
						isLoading={loading}
						rows={payload || []}
						columns={[
							{
								id: "Description",
								header: "Description",
								resolver: (row: ValueChargeDetails) => row.description,
							},
							{
								id: "Amount",
								header: "Amount",
								resolver: (row: ValueChargeDetails) => `$${parseFloat(row.amount).toFixed(2)}`,
							},
							{
								id: "ChargeDate",
								header: "Charge Date",
								resolver: (row: ValueChargeDetails) => moment(row.chargeDate).tz(timezone.id).format(ZenDateFormat),
							},
							{
								id: "Created",
								header: "Created",
								resolver: (row: ValueChargeDetails) => moment(row.createdAt).tz(timezone.id).format(ZenDateFormat),
							},
							{
								id: "Actions",
								header: "",
								resolver: (row: ValueChargeDetails) => {
									const menuItems: MenuItem[] = []
									if (row.blobID) {
										menuItems.push({
											icon: "download",
											label: "Download File",
											onClick: () => {
												window.open(`/api/files/${row.blobID}`, "_blank")
											},
										})
									}
									if (hasPermission(RolePermission.NDISPlanUpdate)) {
										menuItems.push({
											icon: "trash-alt",
											label: "Delete Charge",
											onClick: () => {
												setDeleteCharge(row)
											},
										})
									}

									return <RowActions menuItems={menuItems} />
								},
							},
						]}
					/>
				</div>
				{hasPermission(RolePermission.NDISPlanUpdate) && (
					<div className={createContainer}>
						<CreateChargeForm
							planLine={props.planLine}
							onSuccess={(valueCharge) => {
								query()
								props.onSuccess(valueCharge)
							}}
						/>
					</div>
				)}
			</div>

			{deleteCharge && (
				<ZenModal isOpen={!!deleteCharge} onClose={() => setDeleteCharge(undefined)} autoFocus={false}>
					<ZenArchiveModal
						open={true}
						onClose={() => setDeleteCharge(undefined)}
						loading={deleteGeneralCharge.loading}
						message={`Are you sure you want to delete charge for $${deleteCharge.amount}? This action can't be undone.`}
						hideHeader
						confirmArchive={handleDelete}
						confirmButtonText="Delete"
						error={deleteGeneralCharge.error}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface FormValues {
	description: string
	amount: string
	chargeDate: string
	file: File
}

const CreateChargeForm = (props: { planLine: LineItemRow; onSuccess: (valueCharge: ValueCharge) => void }) => {
	const { planLine, onSuccess } = props

	const [css] = useStyletron()
	const uploader = css({
		marginTop: "15px",
	})

	const { showToast } = useZenToast()
	const { control, handleSubmit, watch, errors, setValue } = useForm<FormValues>()
	const [fileSelectError, setFileSelectError] = React.useState<string>("")
	const file = watch("file")

	const createGeneralCharge = useMutation(fetching.mutation.ndisAddGeneralCharge)

	const handleFileSelected = (selectedFile: File) => {
		setValue("file", selectedFile)
		setFileSelectError("")
	}

	const handleFileSelectError = (error: string) => {
		setValue("file", undefined)
		setFileSelectError(error)
	}

	const onSubmit = async (data: FormValues) => {
		const resp = await createGeneralCharge.mutate({
			file: data.file,
			json: {
				amount: data.amount,
				chargeDate: data.chargeDate,
				description: data.description,
				planLineType: chargeType(props.planLine),
			},
			planLineID: planLine.id,
		})

		if (!resp.payload || resp.error) {
			return
		}

		setValue("file", undefined)
		showToast(`Charge created successfully.`, "positive")
		onSuccess(resp.payload)
	}

	return (
		<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<ZenDatePicker
				formRef={control}
				label={"Date to Charge"}
				formName={"chargeDate"}
				inputError={errors.chargeDate}
				nullDefaultValue
				formRules={{
					required: "You must select a date to charge.",
				}}
			/>
			<ZenInput
				label="Amount"
				nameRef="amount"
				type="number"
				inputError={errors.amount}
				formRef={control}
				formRules={{
					required: "Amount is required",
					pattern: {
						value: currencyRegex,
						message: "Please enter a valid amount",
					},
				}}
			/>
			<ZenTextArea
				label={"Short Description"}
				nameRef={"description"}
				formRef={control}
				inputError={errors.description}
				formRules={{
					required: "Please enter a description for the charge.",
				}}
			/>
			{props.planLine.isGeneralTransport && (
				<>
					<div className={uploader}>
						<ZenFileUploader onError={handleFileSelectError} onFileAccepted={handleFileSelected} />
					</div>
					{fileSelectError && <ErrorNotification messageOrPayload={fileSelectError} />}

					<LabelSmall marginTop="8px" marginBottom="8px">
						Files:
					</LabelSmall>
					{file && (
						<LabelMedium>
							{file.name} ({(file.size / (1 << 20)).toFixed(2)}MB)
						</LabelMedium>
					)}
				</>
			)}
			{createGeneralCharge.error && <ErrorNotification messageOrPayload={createGeneralCharge.payload} />}
			<ZenButton marginTop="10px" width="100%" type={"submit"} isLoading={createGeneralCharge.loading} disabled={props.planLine.isGeneralTransport && !file}>
				Add Charge
			</ZenButton>
		</form>
	)
}
