import * as React from "react"
import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Option, Value } from "baseui/select"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import moment from "moment-timezone"
import { useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ZenFileUploader } from "../../../components/fileUploader"
import { ListTable } from "../../../components/listTable"
import { RowActions } from "../../../components/rowActions"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenSelect } from "../../../components/zenComponents/zenSelectBox"
import { PortalContainer } from "../../../controllers/portal"
import { fetching } from "../../../fetching"
import { genericError } from "../../../helpers/errors"
import { NDISFileType } from "../../../types/enums"
import { NDISPlan, NDISPlanFile, ZenDateFormat } from "../../../types/types"

interface AddFileFormValues {
	fileType: { id: NDISFileType; label: string }[]
	file: File
}

export const FileAttachments = (props: { plan: NDISPlan; onAdd: (file: NDISPlanFile) => void; onRemove: (file: NDISPlanFile) => void }) => {
	const { onRemove } = props

	const [css] = useStyletron()
	const card = css({
		minWidth: "900px",
	})
	const uploader = css({
		marginTop: "15px",
	})
	const content = css({
		display: "flex",
	})
	const tableContainer = css({
		flex: 3,
	})
	const uploadContainer = css({
		flex: 1,
		marginLeft: "15px",
	})

	const { control, handleSubmit, watch, errors, setValue } = useForm()
	const [fileSelectError, setFileSelectError] = React.useState<string>("")
	const [deleteFile, setDeleteFile] = React.useState<NDISPlanFile>()
	const { error, loading, payload, query } = useQuery(fetching.query.getNDISPlanFiles(props.plan.id))
	const deleteAttachment = useMutation(fetching.mutation.ndisPlanFileDelete)
	const [uploading, setUploading] = React.useState(false)
	const uploadFile = useMutation(fetching.mutation.ndisPlanAddFile)
	const file = watch("file")
	const { timezone } = PortalContainer.useContainer()

	const handleFileSelected = (selectedFile: File) => {
		setValue("file", selectedFile)
		setFileSelectError("")
	}

	const handleFileSelectError = (error: string) => {
		setValue("file", undefined)
		setFileSelectError(error)
	}

	const onSubmit = async (data: AddFileFormValues) => {
		setUploading(true)
		const resp = await uploadFile.mutate({
			file: data.file,
			json: { fileType: data.fileType[0].id },
			planID: props.plan.id,
		})
		if (resp.payload) {
			setValue("file", undefined)
			query()
			props.onAdd(resp.payload)
		}
		setUploading(false)
	}

	const handleDelete = async (id: string) => {
		const resp = await deleteAttachment.mutate(id)

		if (!resp.payload || resp.error) {
			return
		}

		query()
		setDeleteFile(undefined)
		onRemove(resp.payload)
	}

	const fileTypeOptons: Option[] = [
		{ id: NDISFileType.NDISPlan, label: "NDIS Plan" },
		{ id: NDISFileType.ServiceAgreement, label: "Service Agreement" },
	]

	return (
		<ZenCard className={card}>
			<LabelLarge>Plan Files</LabelLarge>
			<div className={content}>
				<div className={tableContainer}>
					<ListTable
						error={error ? genericError : undefined}
						isLoading={loading}
						rows={payload || []}
						columns={[
							{
								id: "File Name",
								header: "File Name",
								resolver: (row: NDISPlanFile) => row.blob.fileName,
							},
							{
								id: "Size",
								header: "Size (MB)",
								resolver: (row: NDISPlanFile) => (row.blob.fileSizeBytes / (1 << 20)).toFixed(2),
							},
							{
								id: "Type",
								header: "Type",
								resolver: (row: NDISPlanFile) => row.fileType,
							},
							{
								id: "Type",
								header: "Type",
								resolver: (row: NDISPlanFile) => moment(row.createdAt).tz(timezone.id).format(ZenDateFormat),
							},
							{
								id: "Actions",
								header: "",
								resolver: (row: NDISPlanFile) => (
									<RowActions
										menuItems={[
											{
												icon: "trash-alt",
												label: "Delete File",
												onClick: () => {
													setDeleteFile(row)
												},
											},
										]}
									/>
								),
							},
						]}
					/>
				</div>
				<div className={uploadContainer}>
					<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
						<ZenSelect
							options={fileTypeOptons}
							label={"File/Document Type"}
							formRef={control}
							formName={"fileType"}
							placeholder={""}
							inputError={errors.fileType}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length === 1) || "You must select which type of file you are uploading",
								},
							}}
						/>
						<div className={uploader}>
							<ZenFileUploader onError={handleFileSelectError} onFileAccepted={handleFileSelected} />
						</div>
						{fileSelectError && <ErrorNotification messageOrPayload={fileSelectError} />}
						{error && <ErrorNotification messageOrPayload={payload} />}
						{uploadFile.error && <ErrorNotification messageOrPayload={uploadFile.payload} />}

						<LabelSmall marginTop="8px" marginBottom="8px">
							Files:
						</LabelSmall>
						{file && (
							<LabelMedium>
								{file.name} ({(file.size / (1 << 20)).toFixed(2)}MB)
							</LabelMedium>
						)}
						<FormControl error={uploadFile.error ? genericError : ""}>
							<ZenButton marginTop="10px" width="100%" type={"submit"} isLoading={uploading} disabled={!file}>
								Upload
							</ZenButton>
						</FormControl>
					</form>
				</div>
			</div>

			{deleteFile && (
				<ZenModal isOpen={!!deleteFile} onClose={() => setDeleteFile(undefined)} autoFocus={false}>
					<ZenArchiveModal
						open={true}
						onClose={() => setDeleteFile(undefined)}
						loading={deleteAttachment.loading}
						message={`Are you sure you want to delete ${deleteFile.blob.fileName}? This action can't be undone.`}
						hideHeader
						confirmArchive={() => handleDelete(deleteFile.id)}
						confirmButtonText="Delete"
						error={deleteAttachment.error}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}
