import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Radio, RadioGroup } from "baseui/radio"
import { Value } from "baseui/select"
import { HeadingXSmall, LabelSmall, LabelXSmall, ParagraphSmall } from "baseui/typography"
import { useMutation, useQuery } from "react-fetching-library"
import { Controller, useForm } from "react-hook-form"
import { ZenTheme } from "themeOverrides"

import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { LoadingOverlay } from "../../../components/loadingOverlay"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { ZenSelect } from "../../../components/zenComponents/zenSelectBox"
import { fetching } from "../../../fetching"
import { formatPrice, friendlyDate } from "../../../helpers/utils"
import { NDISLineItemType } from "../../../types/enums"
import { NDISPlan, NDISPlanLine } from "../../../types/types"

interface Props {
	plan: NDISPlan
	onClose: (updatedPlan?: NDISPlan) => void
}

export const TransferBudget = (props: Props) => {
	const { plan } = props

	const { control, errors, watch, setValue, handleSubmit } = useForm()
	const lineItems = useQuery(fetching.query.getClientNDISPlanLines(plan.id))
	const { mutate, error: submitErrors, loading: submitting, payload } = useMutation(fetching.mutation.ndisTransferSupportBudget)
	const [lineItemsFrom, setLineItemsFrom] = React.useState<NDISPlanLine[]>([])
	const [lineItemsTo, setLineItemsTo] = React.useState<NDISPlanLine[]>([])

	const lineItemFrom: NDISPlanLine[] = watch("lineItemFrom")
	const [lineRemainingBudget, setLineRemainingBudget] = React.useState<number>()
	React.useEffect(() => {
		if (!!lineItemFrom && lineItemFrom.length > 0 && !!lineItemFrom[0].support) {
			setLineRemainingBudget(parseFloat(lineItemFrom[0].support.remainingBudget))
		}
	}, [lineItemFrom])

	React.useEffect(() => {
		const amount = lineRemainingBudget ? Math.min(plan.supportBudgetTransferBalance, lineRemainingBudget) : plan.supportBudgetTransferBalance
		const parts = amount.toString().split(".")
		parts[1] = (parts[1] || "").padStart(2, "0")
		setValue("amountDollars", parts[0])
		setValue("amountCents", parts[1])
	}, [plan.supportBudgetTransferBalance, lineRemainingBudget, setValue])

	React.useEffect(() => {
		if (lineItems.loading || !lineItems.payload) return
		setLineItemsFrom(lineItems.payload.filter((l) => !!l.support && parseFloat(l.support.remainingBudget) > 0))
		setLineItemsTo(lineItems.payload.filter((l) => !!l.transport || !!l.travel))
	}, [lineItems.payload, lineItems.loading])

	const [css, theme] = useStyletron()

	const card = css({
		minHeight: "388px",
		minWidth: "800px",
	})

	const amountInputContainer = css({
		display: "flex",
		alignItems: "flex-end",
		width: "200px",
	})

	const amountInputDecimal = css({
		margin: "0 8px",
		paddingBottom: "8px",
		width: "4px !important",
	})

	const amountInputSign = css({
		marginRight: "15px",
		paddingBottom: "8px",
	})

	const submitHandler = handleSubmit(async (input) => {
		const response = await mutate({
			planID: plan.id,
			lineItemFromID: input.lineItemFrom[0].id,
			lineItemToID: input.lineItemTo[0].id,
			transferToType: input.transferToType,
			amountDollars: parseInt(input.amountDollars, 10),
			amountCents: parseInt(input.amountCents, 10),
		})
		if (response.payload && !response.error) {
			props.onClose(response.payload.plan)
		}
	})

	const selectedTransferTo = watch("lineItemTo")
	const amountCents = watch("amountCents")

	return (
		<ZenCard className={card}>
			{lineItems.loading && <LoadingOverlay />}
			{(lineItemsFrom.length === 0 || lineItemsTo.length === 0) && (
				<ParagraphSmall color={ZenTheme.colors.negative}>There are currently no plan lines to transfer support budget to.</ParagraphSmall>
			)}
			{lineItems.payload && (
				<form autoComplete="off" onSubmit={submitHandler}>
					<HeadingXSmall marginTop={0} marginBottom={0}>
						Transfer Support Budget
					</HeadingXSmall>
					<ParagraphSmall color={theme.colors.contentSecondary} marginTop={0}>
						Transfer budget from a support line item to a transport or travel line item.
					</ParagraphSmall>
					{submitErrors && <ErrorNotification messageOrPayload={payload} />}
					{lineItems.error && <ErrorNotification messageOrPayload={lineItems.payload} />}

					<ZenSelect
						options={lineItemsFrom}
						label={"Transfer From"}
						formName={"lineItemFrom"}
						formRef={control}
						inputError={errors.lineItemFrom}
						placeholder={"Select..."}
						getOptionLabel={formatSupportPlanLineLabel}
						getValueLabel={formatSupportPlanLineLabel}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length === 1) || "You must select a line item",
							},
						}}
					/>
					<ZenSelect
						options={lineItemsTo}
						label={"Transfer To"}
						formName={"lineItemTo"}
						formRef={control}
						inputError={errors.lineItemTo}
						placeholder={"Select..."}
						getOptionLabel={formatSupportPlanLineLabel}
						getValueLabel={formatSupportPlanLineLabel}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length === 1) || "You must select a line item",
							},
						}}
					/>

					{selectedTransferTo && selectedTransferTo.length > 0 && (
						<Controller
							name={"transferToType"}
							rules={{
								required: "Required",
							}}
							control={control}
							defaultValue={
								selectedTransferTo && selectedTransferTo.length > 0 && selectedTransferTo[0].transport ? NDISLineItemType.Transport : NDISLineItemType.Travel
							}
							render={({ value, onChange }) => (
								<FormControl
									label={"Select Plan Line"}
									error={errors.transferToType?.message}
									overrides={{
										ControlContainer: {
											style: {
												marginBottom: 0,
											},
										},
										Label: {
											style: {
												...theme.typography.LabelSmall,
											},
										},
									}}
								>
									<RadioGroup value={value} error={!!errors.transferToType} onChange={(e) => onChange(e.currentTarget.value)}>
										{selectedTransferTo && selectedTransferTo.length > 0 && selectedTransferTo[0].transport && (
											<Radio value={NDISLineItemType.Transport}>{formatPlanLineLabel(selectedTransferTo[0], "transport")}</Radio>
										)}
										{selectedTransferTo && selectedTransferTo.length > 0 && selectedTransferTo[0].travel && (
											<Radio value={NDISLineItemType.Travel}>{formatPlanLineLabel(selectedTransferTo[0], "travel")}</Radio>
										)}
									</RadioGroup>
								</FormControl>
							)}
						/>
					)}

					<FormControl
						label={"Transfer Amount"}
						error={errors.amountDollars?.message || errors.amountCents?.message}
						overrides={{
							Label: {
								style: {
									...theme.typography.LabelSmall,
								},
							},
						}}
					>
						<div className={amountInputContainer}>
							<div className={amountInputSign}>
								<FontAwesomeIcon icon={["fas", "dollar-sign"]} />
							</div>
							<ZenInput
								nameRef={"amountDollars"}
								required
								formRef={control}
								inputTextAlign={"right"}
								error={!!(errors.amountDollars?.message || errors.amountCents?.message)}
								formRules={{
									validate: {
										isValidCents: (value: string) => {
											if (/[^0-9]/.test(value)) {
												return "Please enter a valid transfer amount"
											}
											return true
										},
										isValidAmount: (value: string) => {
											const amount = parseFloat(value + "." + amountCents)
											if (isNaN(amount)) {
												return "Please enter a valid transfer amount"
											}
											if (amount > plan.supportBudgetTransferBalance) {
												return "Transfer Amount exceeds Max Transfer Amount"
											}
											if (!!lineRemainingBudget && amount > lineRemainingBudget) {
												return "Transfer Amount exceeds Plan Line's remaining budget"
											}
											return true
										},
									},
								}}
							/>
							<FontAwesomeIcon className={amountInputDecimal} icon={["fas", "circle"]} />
							<ZenInput
								nameRef={"amountCents"}
								required
								error={!!(errors.amountDollars?.message || errors.amountCents?.message)}
								formRef={control}
								formRules={{
									validate: {
										isValidCents: (value: string) => {
											if (!/^[0-9]{1,2}$/.test(value)) {
												return "Please enter a valid transfer amount"
											}
											return true
										},
									},
								}}
							/>
						</div>
					</FormControl>

					<LabelXSmall color={theme.colors.contentSecondary}>{`Max Transfer Amount ${formatPrice(
						lineRemainingBudget ? Math.min(plan.supportBudgetTransferBalance, lineRemainingBudget) : plan.supportBudgetTransferBalance,
					)}`}</LabelXSmall>

					<CancelAndSaveButtons
						cancelLabel="Cancel"
						cancelFn={() => {
							props.onClose()
						}}
						saveLabel="Transfer Budget"
						saveFn={() => {
							submitHandler()
						}}
						isLoading={submitting}
					/>
				</form>
			)}
		</ZenCard>
	)
}

const formatPlanLineLabel = (l: NDISPlanLine, type: "support" | "transport" | "travel") => {
	const item = l[type]
	if (!item) return null
	return (
		<>
			<LabelSmall>
				{item.lineItem.itemNumber} ({friendlyDate(l.serviceStartDate)} - {friendlyDate(l.serviceEndDate)}){" "}
			</LabelSmall>
			{item.lineItem.itemName}
		</>
	)
}

const formatSupportPlanLineLabel = (l: any) => {
	if (!l.option) return ""
	return formatPlanLineLabel(l.option, "support")
}
