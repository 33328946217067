import * as React from "react"
import moment from "moment-timezone"
import { useStyletron } from "baseui"
import { StyledBodyCell, StyledHeadCell, StyledTable } from "baseui/table-grid"
import { StatefulTooltip } from "baseui/tooltip"
import { LabelSmall } from "baseui/typography"
import { friendlyDate, friendlyTime, truncate, minsStr } from "../../helpers/utils"
import { Travel, Session, Timesheet, RolePermission, TZString } from "../../types/types"
import { NoDataText } from "../common"
import { TimesheetDayData, TimesheetRow } from "../../fetching/query"
import { Button } from "baseui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AuthContainer } from "controllers/auth"
import { PortalContainer } from "../../controllers/portal"
import { TravelTypesForShowingClient } from "components/travels/travelForm"

interface Props {
	rows: TimesheetDayData[]
	setSelectedSession: (s: Session | null) => void
	setSelectedTravel: (s: Travel | null) => void
	fullView?: boolean
	scrollTo?: () => void
	timesheet?: Timesheet
}

export const SessionTravelSpanTable = (props: Props) => {
	const [, theme] = useStyletron()
	const { timesheet, setSelectedSession, setSelectedTravel, scrollTo } = props
	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const { timezone } = PortalContainer.useContainer()

	let columns = [
		{ title: "Date", width: "20%" },
		{ title: "Start/End", width: "20%" },
		{ title: "Duration", width: "10%" },
		{ title: "Payable Time", width: "10%" },
		{ title: "Description", width: "20%" },
		{ title: "View/Edit", width: "20%" },
	]

	if (props.fullView) {
		columns = [
			{ title: "Date", width: "20%" },
			{ title: "Start/End", width: "15%" },
			{ title: "Duration", width: "15%" },
			{ title: "Payable Time", width: "20%" },
			{ title: "Description", width: "30%" },
		]
	}

	const borderTopStyle = `3px solid rgb(178, 178, 178)`

	const onRowClick = (obj: TimesheetRow) => {
		if (obj.travel) {
			setSelectedTravel(obj.travel)
			setSelectedSession(null)
		}

		if (obj.session) {
			setSelectedSession(obj.session)
			setSelectedTravel(null)
		}

		if (!!scrollTo) {
			scrollTo()
		}
	}

	const canEditSession = (session: Session): boolean => {
		const ready = !!(timesheet && !!timesheet.readyAt)

		// timesheet locked (approved + completed)
		if (session.isLocked) {
			return false
		}

		// ready but no update perms
		if (ready && !hasPermission(RolePermission.SessionUpdate)) {
			return false
		}

		// no perms or not own  editing own
		if (!(hasPermission(RolePermission.SessionUpdate) || (currentUser && session.workerID === currentUser.id))) {
			return false
		}
		return true
	}

	const canEditTravel = (travel: Travel): boolean => {
		const ready = !!(timesheet && !!timesheet.readyAt)
		// timesheet locked (approved + completed)
		if (travel.isLocked) {
			return false
		}

		// ready but no update perms
		if (ready && !hasPermission(RolePermission.TravelUpdate)) {
			return false
		}

		// no perms or not own  editing own timesheet
		if (!(hasPermission(RolePermission.TravelUpdate) || (currentUser && travel.workerID === currentUser.id))) {
			return false
		}
		return true
	}

	const canEdit = (obj: TimesheetRow) => {
		if (obj.travel) {
			return canEditTravel(obj.travel)
		}

		if (obj.session) {
			return canEditSession(obj.session)
		}

		return false
	}

	if (!props.rows || props.rows.length === 0) {
		return <NoDataText />
	}

	return (
		<StyledTable
			$style={{
				borderTopWidth: 0,
				borderLeftWidth: 0,
				borderBottomWidth: 0,
				borderRightWidth: 0,
				hieght: "auto",
			}}
			$gridTemplateColumns={columns.map((c) => c.width).join(" ")}
		>
			{columns.map((c) => (
				<StyledHeadCell
					key={c.title}
					$style={{
						boxShadow: "unset",
						borderTopWidth: 0,
						borderLeftWidth: 0,
						borderRightWidth: 0,
						borderBottomWidth: "2px",
						borderBottomStyle: "solid",
						borderBottomColor: theme.colors.backgroundSecondary,
						opacity: 0.6,
					}}
				>
					{c.title}
				</StyledHeadCell>
			))}

			{props.rows.map((row, i) => {
				return (
					<React.Fragment key={i}>
						<StyledBodyCell $style={{ borderTop: borderTopStyle, minWidth: 0 }} $gridRow={`span ${row.rows.length + 1}`}>
							<LabelSmall>{friendlyDate(row.date, timezone)}</LabelSmall>
							<div>{`Total: ${minsStr(row.timePayedDayMins)}`}</div>
							<div>{`Multiplier: ${row.multiplier}`}</div>
						</StyledBodyCell>

						{row.rows.map((d, index) => {
							const onFirstRow = index === 0
							const striped = index % 2 === 0
							const borderTop = onFirstRow ? borderTopStyle : ""
							const description = d.description

							const styleObj = {
								borderTop,
								cursor: "pointer",
							}

							// get timezone
							let tz = TZString.find((tzs) => tzs.id === d.timezoneID) || TZString[0]

							var duration = moment.duration(moment(d.endTime).diff(d.startTime))
							var minutes = duration.asMinutes()
							return (
								<React.Fragment key={index}>
									<StyledBodyCell onClick={() => onRowClick(d)} $striped={striped} $style={styleObj}>
										{friendlyTime(tz, d.startTime)} - {friendlyTime(tz, d.endTime)}
										<br />
										<span style={{ color: "grey" }}>{d.timezoneID}</span>
									</StyledBodyCell>
									<StyledBodyCell onClick={() => onRowClick(d)} $striped={striped} $style={styleObj}>
										{minsStr(minutes)}
									</StyledBodyCell>
									<StyledBodyCell onClick={() => onRowClick(d)} $style={styleObj} $striped={striped}>
										{
											minsStr(d.timePayedMins, (description === TravelTypesForShowingClient.cBProviderTravel))
										}
									</StyledBodyCell>
									<StyledBodyCell onClick={() => onRowClick(d)} $style={styleObj} $striped={striped}>
										<StatefulTooltip content={() => <div>{description || ""}</div>} returnFocus autoFocus>
											{
												d.isNonBillable ? description : truncate(description, 40)
											}
										</StatefulTooltip>
									</StyledBodyCell>
									{!props.fullView && (
										<StyledBodyCell onClick={() => onRowClick(d)} $style={styleObj} $striped={striped}>
											<Button kind={"minimal"} size={"compact"}>
												<FontAwesomeIcon icon={["fas", "eye"]} color="grey" />
												{canEdit(d) && <FontAwesomeIcon icon={["fal", "pencil"]} color="grey" />}
											</Button>
										</StyledBodyCell>
									)}

									{/* extra row for whitespace */}
									{index === row.rows.length - 1 && (
										<>
											{columns.map((_, i) => {
												return (
													<React.Fragment key={i}>
														{i === columns.length - 1 ? <></> : <StyledBodyCell key={i} $striped={false}></StyledBodyCell>}
													</React.Fragment>
												)
											})}
										</>
									)}
								</React.Fragment>
							)
						})}
					</React.Fragment>
				)
			})}
		</StyledTable>
	)
}
