import * as React from "react"
import moment from "moment-timezone"

import { MileageClaimStatus, ReviewStatus, TimesheetStatus } from "../types/enums"
import { Timezone, ZenDateFormat, Timesheet, MileageClaim } from "../types/types"
import { genericError, getErrorMessage } from "./errors"

export const getAge = (dateString: string) => {
	const today = new Date()
	const birthDate = new Date(dateString)
	let age = today.getFullYear() - birthDate.getFullYear()
	const m = today.getMonth() - birthDate.getMonth()
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--
	}
	return age
}

// allows numbers followed by upto 2 decimal places
export const currencyRegex = /^\d+(\.\d{1,2})?$/

export const phoneNumberRegex = /^[().+\-\d]+$/

// allows upto 3 decimal places for kilometers which will result in a whole number when converted to meters
export const kilometersRegex = /^\d+(\.\d{1,3})?$/

export const emailRegex = /^\w+([.!#$%&'*+-/=?^_`{|]?\w+)*@\w+([-]?\w+)*(\.\w{2,})+$/
//															   				  ^ match 2 or more to handle subdomains think @student.curtin.edu.au

export const yesNo = (b: boolean) => (b ? "Yes" : "No")

export const alignDate = (date?: Date, time?: Date): Date => {
	const formattedDate = {
		year: date?.getFullYear(),
		month: date?.getMonth(),
		date: date?.getDate(),
	}
	return moment(time).set(formattedDate).toDate()
}

export const truncate = (str: string, length = 30, withEllipsis = true): string => {
	if (!str) return ""

	if (str.length < length) {
		return str
	}
	return `${str.substr(0, length)}${withEllipsis && "..."}`
}

export const friendlyDate = (date: string | Date | moment.Moment | undefined, timezone?: Timezone) => {
	if (!date) {
		console.error("Date is invalid [" + date + "]")
		return ""
	}
	if (timezone) {
		return moment(date).tz(timezone.id).format(ZenDateFormat)
	}
	return moment(date).format(ZenDateFormat)
}

export const friendlyTime = (timezone: Timezone, time: string | moment.Moment | undefined) => {
	if (!time) {
		console.error("Time is invalid")
		return ""
	}

	return moment(time).tz(timezone.id).format("hh:mm a")
}

/*
	is30MinsOnly
		is only for TravelTypesForShowingClient.cBProviderTravel which limits the time
*/
export const minsStr = (mins: number, is30MinsOnly: boolean = false) => {
	const hours = Math.floor(mins / 60)
	const minutes = mins % 60

	if (isNaN(hours) || isNaN(minutes) || isNaN(mins)) {
		return "0h 0m"
	}

	if(is30MinsOnly && ( (hours > 0) || (hours < 1 && minutes > 30) )){
		return '0h 30m'
	}

	return `${hours}h ${minutes}m`
}

export const timeDuration = (time1: moment.MomentInput, time2: moment.MomentInput, is30MinsOnly: boolean = false) => {
	let start = moment(time1)
	let end = moment(time2)
	if (start.isAfter(end)) {
		const t = end
		end = start
		start = t
	}
	start.set({ seconds: 0 })
	end.set({ seconds: 1 })

	const diff = moment.duration(moment(end).diff(start))

	if(is30MinsOnly){
		const mins = diff.asMinutes();
		const hours = Math.floor(mins / 60)
		const minutes = mins % 60
		if(hours > 0 || (hours < 1 && minutes > 30)){
			return '0h 30m'
		}
	}
	
	if (diff.asDays() >= 1) {
		return `${Math.floor(diff.asDays())}d ${moment.utc(diff.asMilliseconds()).format("H[h] m[m]")}`
	}

	return moment.utc(diff.asMilliseconds()).format("H[h] m[m]")
}

export const getMileageClaimLatestStatusUpdateDate = (mileageClaim: MileageClaim): string | undefined => {
    const dates: (string | undefined)[] = [
        mileageClaim.completedAt,
        mileageClaim.reviewedAt,
        mileageClaim.checkedAt,
        mileageClaim.createdAt  // Assuming the creation date could also be relevant
    ];

    // Filter out any undefined dates to avoid errors in the sorting process
    const validDates = dates.filter(date => date !== undefined);

    // Check if there are any valid dates, return undefined if none
    if (validDates.length === 0) {
        return undefined;
    }

    // Sort the dates in descending order to find the latest
    validDates.sort((a, b) => {
        // Convert date strings to date objects to compare
        return new Date(b!).getTime() - new Date(a!).getTime();
    });

    return validDates[0]; // Return the latest date
};


// getMileageClaimStatus will return a mileage claim status enum
export const getMileageClaimStatus = (complete: boolean, ready: boolean, checked: boolean, reviewStatus: ReviewStatus): MileageClaimStatus => {
	if (complete) {
		return MileageClaimStatus.Complete
	}
	if (reviewStatus === ReviewStatus.Declined) {
		return MileageClaimStatus.Declined
	}
	if (!ready) {
		return MileageClaimStatus.Draft
	}
	if (reviewStatus === ReviewStatus.Approved) {
		return MileageClaimStatus.Approved
	}
	if (checked) {
		return MileageClaimStatus.Checked
	}
	return MileageClaimStatus.Pending
}

export const getMileageClaimLatestReviewer = (mileageClaim: MileageClaim): string => {
    const entries = [
        { date: mileageClaim.completedAt, user: mileageClaim.completedBy },
        { date: mileageClaim.reviewedAt, user: mileageClaim.reviewedBy },
        { date: mileageClaim.checkedAt, user: mileageClaim.checkedBy }
    ];

    // Filter out entries without dates or where user is undefined
    const validEntries = entries.filter(entry => entry.date !== undefined && entry.user !== undefined);

    // Sort by date in descending order
    validEntries.sort((a, b) => new Date(b.date!).getTime() - new Date(a.date!).getTime());

    // Check if there is at least one valid entry and extract the name
    if (validEntries.length > 0) {
        const latestUser = validEntries[0].user;
        // Assuming User has firstName and lastName properties
        return latestUser ? `${latestUser.firstName} ${latestUser.lastName}` : "";
    }

    return ""; // Return empty string if no valid entries
};


export const getTimesheetLatestStatusUpdateDate = (timesheet: Timesheet): string | undefined => {
    const dates: (string | undefined)[] = [
        timesheet.completedAt,
        timesheet.reviewedAt,
        timesheet.checkedAt,
        timesheet.createdAt // Assuming you might want to consider the creation date as a fallback
    ];

    // Filter out any undefined dates
    const validDates = dates.filter(date => date !== undefined);

    // Return the latest date using the sort function
    if (validDates.length === 0) {
        return undefined;
    }

    // Sort the dates in descending order and return the first one
    validDates.sort((a, b) => {
        // Convert date strings to date objects to compare
        return new Date(b!).getTime() - new Date(a!).getTime();
    });

    return validDates[0]; // return the latest date
};

// getTimesheetStatusStatus will return a timesheet status enum
export const getTimesheetStatus = (complete: boolean, ready: boolean, checked: boolean, reviewStatus: ReviewStatus): TimesheetStatus => {
	if (complete) {
		return TimesheetStatus.Complete
	}
	if (reviewStatus === ReviewStatus.Declined) {
		return TimesheetStatus.Declined
	}
	if (!ready) {
		return TimesheetStatus.Draft
	}
	if (reviewStatus === ReviewStatus.Approved) {
		return TimesheetStatus.Approved
	}
	if (checked) {
		return TimesheetStatus.Checked
	}
	return TimesheetStatus.Pending
}

export const getTimesheetLatestReviewer = (timesheet: Timesheet): string => {
    const entries = [
		{ date: timesheet.completedAt, user: timesheet.completedBy },
        { date: timesheet.reviewedAt, user: timesheet.reviewedBy },
        { date: timesheet.checkedAt, user: timesheet.checkedBy }
    ];

    // Filter out entries without dates or where user is undefined
    const validEntries = entries.filter(entry => entry.date !== undefined && entry.user !== undefined);

    // Sort by date in descending order
    validEntries.sort((a, b) => new Date(b.date!).getTime() - new Date(a.date!).getTime());

    // Check if there is at least one valid entry and extract the name
    if (validEntries.length > 0) {
        const latestUser = validEntries[0].user;
        if (latestUser) {
            return `${latestUser.firstName} ${latestUser.lastName}`;
        }
    }

    return ""; // Return empty string if no valid entries
};


export const splitOnCaps = (s: string) => {
	const rgx = s.match(/[A-Z][a-z]+|[0-9]+/g)
	if (!rgx) return ""
	return rgx.join(" ")
}

// combineDateTime takes a Date object and a time string, when using a separate date and time picker
export const combineDateTime = (date: Date, time: string): moment.Moment => {
	return moment(time).set({
		year: date.getFullYear(),
		month: date.getMonth(),
		date: date.getDate(),
	})
}

// TermChecklist contains a list of terms which should always capitalise all the characters
const TermChecklist = ["ndis", "ndia", "dna"]

// capitalize takes a string upper or lower case and converts to first letter capital, rest lower case.
export const capitalize = (str: string) => {
	if (TermChecklist.includes(str.toLowerCase())) {
		return str.toUpperCase()
	}
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

// snakeToTitle takes snake case upper or lower case and converts to space separated on each underscore with capital for
// start of each word.
export const snakeToTitle = (s: string) =>
	s
		.split("_")
		.map((n) => capitalize(n))
		.join(" ")

// formatPrice will convert a floating point number into price eg. 1000.5 becomes "$1,000.50"
export const formatPrice = (price: number) => {
	const parts = price.toString().split(".")
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	parts[1] = (parts[1] || "").padStart(2, "0")
	return "$" + parts.join(".")
}

// get react hook form err message string
export const getErrMsg = (errors: any) => {
	const errKeys = Object.keys(errors)
	if (errKeys[0]) {
		return errors[errKeys[0]].message
	}
}

export const dateOption = [
	{ label: "This Week", id: "ThisWeek" },
	{ label: "This Month", id: "ThisMonth" },
]

export const useDebounce = (value: string, delay: number) => {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = React.useState<string>(value)

	React.useEffect(
		() => {
			// Set debouncedValue to value (passed in) after the specified delay
			const handler = setTimeout(() => {
				setDebouncedValue(value)
			}, delay)

			// cleanup timeout
			// this will clean up the previous value as well
			return () => {
				clearTimeout(handler)
			}
		},
		// re-call effect if value changes
		[delay, value],
	)

	return debouncedValue
}

// When setting return type on react-fetching-library action as "blob" but the endpoint returns an error
// the object will still need to be a blob. Can attempt to parse the JSON and read the error or otherwise return a
// generic error.
export const getErrorFromBlob = async (blobResponse?: Blob): Promise<string> => {
	if (!blobResponse) {
		return genericError
	}
	try {
		const text = await new Response(blobResponse).text()
		const json = JSON.parse(text)
		return getErrorMessage(json)
	} catch {
		return genericError
	}
}

// convert a time with given timezone
export const setTimezoneToTime = (d: Date, tzID: string): Date => {
	// format time with out timezone
	const timeStr = moment(d).format("YYYY-MM-DD HH:mm")

	// return time with new timezone
	return moment.tz(timeStr, tzID).toDate()
}

// return timezone offset different
export const timezoneOffsetDifferent = (oldTZ: Timezone, newTZ: Timezone): number => {
	return newTZ.offsetMinutes - oldTZ.offsetMinutes
}

export const emailNotifTemplate = (data: {timesheetStatus: string, receiverDetails: Record<string, any>, startDate: string, endDate: string, url?: string, bodyMsg: string}) => {
	const { timesheetStatus, receiverDetails, startDate, endDate , url, bodyMsg } = data ?? {}
	const { firstName, lastName } = receiverDetails ?? {}

	return `
		<html>
			<head>
				<style>
					a { text-decoration: none }
					div.upper-body-msg { line-height: 50%; }
					div.time-duration { margin-top: 40px; line-height: 50%; }
						div.time-duration p.timesheet_status span { font-weight: bold; }
						div.time-duration p.start-date, div.time-duration p.end-date { margin-left: 20px; }
					div.email-footer { margin-top: 35px; font-style: oblique; }
				</style>
			</head>
			<body>
				<div class="upper-body-msg">
					<p>Dear Mr/Mrs ${`${firstName} ${lastName || ""}`},</p>
					<p>${bodyMsg}</p>
					${url ? `
					<p> Kindly visit this link</p>
					<p><a href='${url}'>${url}</a></p>
					`: ''}
				</div>
				<div class="time-duration">
					<p class="timesheet_status">
						Timesheet status: <span>${timesheetStatus}</span>
					</p>
					<p>Timesheet duration: </p>
					<p class="start-date">From:  ${startDate}</p>
					<p class="end-date">To:  ${endDate}</p>
				</div>
				<div class="email-footer">
					<p>This is a system generated email. We might not be able to read your message if you respond here.</p>
				</div>
			</body>
		</html>
	`
}
