import * as React from "react"
import { useStyletron } from "baseui"
import { Redirect, Route, Switch } from "react-router"

import { ToasterContainer } from "baseui/toast"
import { ReportsContainer } from "../controllers/reports"
import { createStore, StateMachineProvider } from "little-state-machine"
import { callLogCreateInitialState } from "../states/callLogCreate"
import { CallLogFloatingActionButton } from "../components/callLogs/callLogFloatingAction"
import { ErrorBoundary } from "../components/errorBoundary"
import { Loading } from "../components/loading"
import { NotFound } from "../components/notFound"
import { SideBar } from "../components/sidebar"
import { TopBar } from "../components/topbar"
import { AuthContainer } from "../controllers/auth"
import { PortalContainer } from "../controllers/portal"
import { routes } from "../routes"
import { ZenTheme } from "themeOverrides"
import { LabelLarge } from "baseui/typography"

import Schedule from "./schedule"
import TravelRoot from "./travels/travelRoot"
import Dashboard from "./dashboard"
import CallLogPage from "./callLogs/callLogs"
import SessionRoot from "./sessions/sessionRoot"
import TeamRoot from "./team"
import AuditLogPage from "./auditLogs/auditLogs"
import ClientRoot from "./clients/clientRoot"
import FormsPage from "./forms/forms"
import ReportRoot from "./reports/reports"
import WorkerRoot from "./workers/workerRoot"
import { RestrictedClients } from "./restrictedClients"

createStore({
	callLogCreate: callLogCreateInitialState(),
})

export default function Portal() {
	const { currentUser, initialLoading, isImpersonatingUser } = AuthContainer.useContainer()
	const isTrainingMode = process.env.REACT_APP_TRAINING_HOST_URL === window.location.host
	const hasBorder = isTrainingMode || isImpersonatingUser

	const [css] = useStyletron()
	const containerStyle = css({
		position: "relative",
		display: "flex",
		height: "100%",
		width: "100%",
	})
	const mainStyle = css({
		display: "flex",
		flex: "1",
		minWidth: "0",
		height: hasBorder ? "95%" : "100%",
		flexDirection: "column",
		marginTop: hasBorder ? "50px" : 0,
	})
	const contentStyle = css({
		margin: "0 25px 25px 25px",
		minHeight: 0,
		height: "100%",
		[`@media print`]: {
			marginLeft: 0,
			marginRight: 0,
			marginBottom: 0,
		},
	})

	if (initialLoading) {
		return <Loading />
	}

	if (!currentUser) {
		return <Redirect to={"/"} />
	}

	return (
		<ToasterContainer>
			<StateMachineProvider>
				<ReportsContainer.Provider>
					<PortalContainer.Provider>
						<ErrorBoundary>
							{/* Just training mode */}
							{!isImpersonatingUser && isTrainingMode && <InfoBorder label="TRAINING MODE" />}

							{/* Just impersonating a user */}
							{isImpersonatingUser && !isTrainingMode && <InfoBorder label="IMPERSONATING USER" colour="#FF4136" />}

							{/* impersonating a user + training mode */}
							{isImpersonatingUser && isTrainingMode && (
								<InfoBorder width="400px" fontSize="13px" label="TRAINING MODE (IMPERSONATING USER)" colour="#FF4136" />
							)}

							<div className={containerStyle}>
								<SideBar />
								<div className={mainStyle}>
									<TopBar />
									<div className={contentStyle}>
										<Switch>
											<Route exact path={routes.portal} component={Dashboard} />
											<Route path={routes.schedule.root} component={Schedule} />
											<Route path={routes.travels.root} component={TravelRoot} />
											<Route path={routes.callLogs.root} component={CallLogPage} />
											<Route path={routes.sessions.root} component={SessionRoot} />
											<Route path={routes.clients.root} component={ClientRoot} />
											<Route path={routes.team.root} component={TeamRoot} />
											<Route path={routes.workers.root} component={WorkerRoot} />
											<Route path={routes.forms.root} component={FormsPage} />
											<Route path={routes.admin.root} component={ReportRoot} />
											<Route path={routes.restrictedClients.root} component={RestrictedClients} />
											<Route path={routes.auditLogs.root} component={AuditLogPage} />
											<Route component={NotFound} />
										</Switch>
									</div>
									<CallLogFloatingActionButton />
								</div>
							</div>
						</ErrorBoundary>
					</PortalContainer.Provider>
				</ReportsContainer.Provider>
			</StateMachineProvider>
		</ToasterContainer>
	)
}

interface InfoBorderProps {
	label: string
	colour?: string
	width?: string
	fontSize?: string
}
export const InfoBorder = (props: InfoBorderProps) => {
	const { colour, label, width, fontSize } = props
	const borderColour = colour || ZenTheme.colors.primary
	const [css] = useStyletron()
	const trainingModeBorder = css({
		border: "8px solid " + borderColour,
		borderTop: "15px solid " + borderColour,
		position: "fixed",
		display: "flex",
		justifyContent: "center",
		top: "0px",
		left: "0px",
		width: "100vw",
		height: "100vh",
		pointerEvents: "none",
		zIndex: 20000,
	})

	const inner = css({
		width: width || "350px",
		height: "35px",
		paddingBottom: "10px",
		textAlign: "center",
		background: borderColour,
		marginTop: "-5px",
		color: "white",
		clipPath: "polygon(10% 100%, 90% 100%, 100% 0, 0 0)",
	})

	return (
		<div className={trainingModeBorder}>
			<div className={inner}>
				<LabelLarge color="white" $style={{ fontWeight: 1000, fontSize: fontSize || "15px" }}>
					{label}
				</LabelLarge>
			</div>
		</div>
	)
}
