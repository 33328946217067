import * as React from "react"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal"
import { LabelMedium, LabelSmall, LabelXSmall, ParagraphMedium } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import moment from "moment-timezone"
import { useMutation } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { AuthContainer } from "../../controllers/auth"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { capitalize, snakeToTitle } from "../../helpers/utils"
import { ZenTheme } from "../../themeOverrides"
import { daysOfWeek } from "../../types/enums"
import { RoleName, RolePermission, UserDetail } from "../../types/types"
import { ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { FieldDisplay } from "../fieldDisplay"
import { TooltipButton } from "../tooltipButton"
import { EmergencyContactItem } from "../zenComponents/emergencyContact"
import { SubInfo } from "../zenComponents/labels"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenDivider } from "../zenComponents/zenInput"

interface WorkerDetailProps extends UserDetail {
	editMode?: boolean
}

export const WorkerDetail = (props: WorkerDetailProps) => {
	const [css] = useStyletron()
	const container = css({
		height: "100%",
		width: "100%",
		padding: "15px",
	})
	const title = css({
		display: "flex",
		height: "fit-content",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const scrollDiv = css({
		maxHeight: "96%",
		overflowY: "auto",
		marginTop: "8px",
		paddingRight: "8px",
	})
	const userBasic = css({
		display: "flex",
		alignItems: "center",
		height: "fit-content",
	})
	const info = css({
		flex: 1,
		height: "100%",
		marginLeft: "8px",
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column",
	})
	const infoInner = css({
		flex: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	})

	const {
		id,
		firstName,
		lastName,
		avatarURL,
		createdAt,
		mobileNumber,
		email,
		emergencyContacts,
		editMode,
		dateOfBirth,
		position,
		department,
		address,
		offices,
		status,
		vehicleRegistrationNumber,
		role,
	} = props
	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const history = useHistory()
	const [openModal, setOpenModal] = React.useState<boolean>(false)
	const { timezone } = PortalContainer.useContainer()

	const canEdit = (): boolean => {
		if (!currentUser) return false

		// check if user has user update permission
		if (!hasPermission(RolePermission.UserUpdate)) {
			return false
		}

		// if user being edited is a superadmin and is the logged in user
		if (props.role.name === RoleName.SuperAdmin && currentUser.id !== props.id) {
			return false
		}
		return true
	}

	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelMedium>Details</LabelMedium>
				{!editMode && currentUser && hasPermission(RolePermission.UserUpdate) && (
					<div>
						{canEdit() && (
							<TooltipButton
								onClick={() => {
									history.push(`/portal/workers/${id}/edit/`)
								}}
								tooltip={"Edit Profile"}
								iconName={"pencil"}
							/>
						)}
						{hasPermission(RolePermission.UserInvalidateTokens) && (
							<TooltipButton
								onClick={() => {
									setOpenModal(true)
								}}
								tooltip={"Invalidate Login Tokens"}
								iconName={"minus-octagon"}
							/>
						)}
					</div>
				)}
			</div>
			<div className={scrollDiv}>
				<div className={userBasic}>
					<Avatar
						name={`${firstName} ${lastName}`}
						overrides={{
							Root: {
								style: {
									minWidth: ZenTheme.sizing.scale1000,
								},
							},
						}}
						src={avatarURL}
					/>
					<div className={info}>
						<div className={infoInner}>
							<LabelSmall marginBottom="8px">{`${firstName} ${lastName}`}</LabelSmall>
							<LabelSmall marginBottom="8px" color={ZenTheme.colors.primaryGrey}>{`Started ${moment(createdAt)
								.tz(timezone.id)
								.format("DD/MM/YY")}`}</LabelSmall>
						</div>
						<div className={infoInner}>
							<LabelSmall>{snakeToTitle(role.name)}</LabelSmall>
						</div>
					</div>
				</div>
				<SubInfo icon="home">
					<LabelXSmall marginLeft="10px">{address?.fullAddress}</LabelXSmall>
				</SubInfo>
				<SubInfo icon="phone-alt">
					<LabelXSmall marginLeft="10px">{mobileNumber}</LabelXSmall>
				</SubInfo>
				<SubInfo icon="envelope">
					<LabelXSmall marginLeft="10px" color="blue">
						{email}
					</LabelXSmall>
				</SubInfo>

				<ZenDivider marginTop="15px" />
				<FieldDisplay label="Department" marginTop="15px">
					<LabelSmall>{department.name}</LabelSmall>
				</FieldDisplay>
				<FieldDisplay label="Position" marginTop="15px">
					<LabelSmall>{position.name}</LabelSmall>
				</FieldDisplay>

				<FieldDisplay label="Status" marginTop="15px">
					<LabelSmall>{snakeToTitle(status)}</LabelSmall>
				</FieldDisplay>

				<FieldDisplay label="Office" marginTop="15px">
					{offices &&
						daysOfWeek.map((day, index) => {
							const usersOffice = offices.find((value) => value.day === day)
							if (!usersOffice) {
								return null
							}
							return (
								<LabelSmall key={index}>
									{capitalize(day)}: {usersOffice.office.name}
								</LabelSmall>
							)
						})}
					{!offices && <LabelSmall>N/A</LabelSmall>}
				</FieldDisplay>
				<FieldDisplay label="Date of birth" marginTop="15px">
					<LabelSmall>{`${moment(dateOfBirth).format("DD/MM/YYYY")} (${moment().diff(dateOfBirth, "y", false)})`}</LabelSmall>
				</FieldDisplay>
				<FieldDisplay label="Vehicle Registration Number" marginTop="15px">
					<LabelSmall>{vehicleRegistrationNumber || "N/A"}</LabelSmall>
				</FieldDisplay>
				{emergencyContacts && emergencyContacts.length > 0 && (
					<>
						<ZenDivider marginTop="15px" />
						{emergencyContacts.map((e, i) => (
							<EmergencyContactItem key={i} index={i} relationship={e.relationship} marginBottom="15px" {...e.emergencyContact} />
						))}
					</>
				)}
			</div>
			<InvalidateTokensModal id={id} isOpen={openModal} setIsOpen={setOpenModal} />
		</ZenCard>
	)
}

interface InvalidateTokensModalProps {
	id: string
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const InvalidateTokensModal = (props: InvalidateTokensModalProps) => {
	const { id, isOpen, setIsOpen } = props

	const [css] = useStyletron()
	const container = css({
		width: "500px",
		height: "fit-content",
	})

	const { mutate, payload, error, loading, reset } = useMutation(fetching.mutation.invalidateTokens)
	const onSubmit = () => {
		mutate({
			userID: id,
		})
	}

	// reset state when modal closed
	React.useEffect(() => {
		if (isOpen) {
			return
		}
		reset()
	}, [isOpen, reset])

	return (
		<ZenModal onClose={() => setIsOpen(false)} isOpen={isOpen} role={ROLE.dialog} size={SIZE.auto}>
			<ModalHeader>Invalidate Login Tokens</ModalHeader>
			<ModalBody>
				{error && <ErrorNotification messageOrPayload={payload} />}
				{payload && !error ? (
					<>
						<LabelSmall>Tokens have been invalidated.</LabelSmall>
						<ZenButton marginTop="20px" onClick={() => setIsOpen(false)} width="100%">
							Close
						</ZenButton>
					</>
				) : (
					<div className={container}>
						<ParagraphMedium>This will invalidate all of the users current login tokens.</ParagraphMedium>
						<ZenButton marginTop="20px" onClick={onSubmit} width="100%" isLoading={loading}>
							Invalidate
						</ZenButton>
					</div>
				)}
			</ModalBody>
		</ZenModal>
	)
}
