import * as React from "react"
import { NDISLineItem, NDISPriceGuide } from "../../../types/types"
import { useStyletron } from "baseui"
import { useForm } from "react-hook-form"
import { Divider, ZenCard } from "../../../components/common"
import { LabelLarge, LabelSmall } from "baseui/typography"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { FieldDisplay } from "../../../components/fieldDisplay"
import { currencyRegex, snakeToTitle } from "../../../helpers/utils"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { ZenTheme } from "../../../themeOverrides"
import { ErrorNotification } from "../../../components/errorBox"
import { useMutation } from "react-fetching-library"
import { fetching } from "../../../fetching"
import { useZenToast } from "../../../components/zenComponents/useZenToast"
import { ZenCheckbox } from "../../../components/zenComponents/zenCheckboxList"

interface FormValues {
	itemName: string
	priceNational: string
	priceRemote: string
	priceVeryRemote: string
	isSupportCoordinatorCode: boolean
}

interface Props {
	lineItem: NDISLineItem
	priceGuide: NDISPriceGuide
	setEditMode: (editMode: boolean) => void
	onSave: (item: NDISLineItem) => void
}

export const LineItemEdit = (props: Props) => {
	const { setEditMode, lineItem, priceGuide, onSave } = props

	const [css] = useStyletron()
	const container = css({
		minWidth: "400px",
		marginBottom: "15px",
	})
	const info = css({
		display: "flex",
		justifyContent: "space-between",
		backgroundColor: ZenTheme.colors.lightGrey,
		padding: "5px",
	})

	const { showToast } = useZenToast()
	const { control, handleSubmit, errors } = useForm<FormValues>({
		defaultValues: {
			itemName: lineItem.itemName,
			priceNational: lineItem.priceNational ? lineItem.priceNational.toString() : undefined,
			priceRemote: lineItem.priceRemote ? lineItem.priceRemote.toString() : undefined,
			priceVeryRemote: lineItem.priceVeryRemote ? lineItem.priceVeryRemote.toString() : undefined,
			isSupportCoordinatorCode: lineItem.isSupportCoordinatorCode,
		},
	})
	const { mutate, payload, error, loading } = useMutation(fetching.mutation.ndisLineItemUpdate)

	const onSubmit = async (data: FormValues) => {
		const resp = await mutate({
			...data,
			id: lineItem.id,
		})
		if (!resp.payload || resp.error) {
			return
		}
		showToast(`Line item ${resp.payload.itemNumber} updated successfully.`, "positive")
		setEditMode(false)
		onSave(resp.payload)
	}

	return (
		<ZenCard>
			<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<LabelLarge marginTop={0} marginBottom={"10px"}>
					Edit NDIS Line Item - {priceGuide.version}
				</LabelLarge>
				<div className={container}>
					<div className={info}>
						<FieldDisplay label="Item Number">
							<LabelSmall>{lineItem.itemNumber}</LabelSmall>
						</FieldDisplay>
						<FieldDisplay label="Item Type">
							<LabelSmall>{snakeToTitle(lineItem.itemType)}</LabelSmall>
						</FieldDisplay>
						<FieldDisplay label="Unit">
							<LabelSmall>{snakeToTitle(lineItem.unit)}</LabelSmall>
						</FieldDisplay>
					</div>
					<ZenInput
						label="Item Name"
						nameRef="itemName"
						inputError={errors.itemName}
						formRef={control}
						formRules={{
							required: "Item name is required.",
						}}
					/>
					<ZenInput
						label="National Price"
						nameRef="priceNational"
						type="number"
						startEnhancer={"$"}
						placeholder={"Enter price."}
						inputError={errors.priceNational}
						formRef={control}
						formRules={{
							required: "National price is required",
							pattern: {
								value: currencyRegex,
								message: "Please enter a valid dollar amount",
							},
						}}
					/>
					<ZenInput
						label="Remote Price"
						nameRef="priceRemote"
						type="number"
						startEnhancer={"$"}
						placeholder={"Enter price."}
						inputError={errors.priceRemote}
						formRef={control}
						formRules={{
							required: "Remote price is required",
							pattern: {
								value: currencyRegex,
								message: "Please enter a valid dollar amount",
							},
						}}
					/>
					<ZenInput
						label="Very Remote Price"
						nameRef="priceVeryRemote"
						type="number"
						startEnhancer={"$"}
						placeholder={"Enter price."}
						inputError={errors.priceVeryRemote}
						formRef={control}
						formRules={{
							required: "Very remote price is required",
							pattern: {
								value: currencyRegex,
								message: "Please enter a valid dollar amount",
							},
						}}
					/>
					<Divider style={{ backgroundColor: "transparent" }} />
					<ZenCheckbox label="Is Support Coordinator code" formName="isSupportCoordinatorCode" formRef={control} labelPlacement="left" />
					<Divider style={{ backgroundColor: "transparent" }} />
				</div>
				{error && <ErrorNotification messageOrPayload={payload} />}
				<CancelAndSaveButtons cancelFn={() => setEditMode(false)} isLoading={loading} />
			</form>
		</ZenCard>
	)
}
