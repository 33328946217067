import * as React from "react"
import { useQuery } from "react-fetching-library"
import { createContainer } from "unstated-next"

import { fetching } from "../fetching"
import { RolePermission, UserDetail } from "../types/types"

export interface ErrorData {
	message: string
	field: string[]
}

export interface NotificationMessage {
	type: NotificationType
	message: string
	success: boolean
}

export enum NotificationType {
	userAdded,
	userUpdated,
	userArchive,
	warning,
	passwordChanged,
	updated,
}

export const AuthContainer = createContainer(() => {
	const [notification, setNotification] = React.useState<NotificationMessage | null>()
	const [modal, setModal] = React.useState<NotificationMessage | null>()
	const [initialLoading, setInitialLoading] = React.useState(true)

	// turn dashboard edit off or on
	const [editingLayout, setEditingLayout] = React.useState<boolean | undefined>(false)

	// fetch current user detail
	const { payload: data, loading, query: refetch, reset } = useQuery(fetching.query.getMe(), false)
	const { payload: checkLoginPayload, loading: checkLoginLoading } = useQuery(fetching.query.check())

	React.useEffect(() => {
		if (!checkLoginPayload || checkLoginLoading) {
			return
		}
		if (!checkLoginPayload.loggedIn) {
			setInitialLoading(false)
			return
		}
		;(async () => {
			await refetch()
			setInitialLoading(false)
		})()
	}, [checkLoginPayload, checkLoginLoading, setInitialLoading, refetch])

	const [impersonatedUser, setImpersonatedUser] = React.useState<UserDetail>()

	const useLogout = () => {
		const [loading, setLoading] = React.useState<boolean>(false)

		const logout = async () => {
			setLoading(true)

			const response = await fetch("/api/auth/microsoft/logout", {
				method: "GET",
			})

			setLoading(false)

			if (response.status !== 200) {
				return
			}

			reset()
		}

		return {
			logout,
			loading,
		}
	}

	const hasPermission = (p: RolePermission) => {
		if (impersonatedUser) return impersonatedUser.role.permissions.includes(p)

		if (!data) return false

		return data.role.permissions.includes(p)
	}

	const hasAnyPermissions = (...permissions: RolePermission[]) => {
		if (permissions.length === 0) {
			console.error("hasAnyPermissions() expects at least 1 value")
			return false
		}

		if (impersonatedUser) return impersonatedUser.role.permissions.some((p) => permissions.includes(p))

		if (!data) return false
		return data.role.permissions.some((p) => permissions.includes(p))
	}

	const impersonateUser = (user?: UserDetail) => {
		if (user === undefined || impersonatedUser !== undefined) setImpersonatedUser(undefined)
		if (!hasPermission(RolePermission.ImpersonateUser)) return

		setImpersonatedUser(user)
	}

	return {
		currentUser: impersonatedUser || data,
		reloadLoginState: refetch,
		loading,
		initialLoading,
		useLogout,
		hasPermission,
		hasAnyPermissions,
		notification,
		setNotification,
		modal,
		setModal,
		impersonateUser,
		editingLayout,
		setEditingLayout,
		isImpersonatingUser: impersonatedUser !== undefined,
	}
})
