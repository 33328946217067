import * as React from "react"
import { Route, RouteComponentProps, Switch } from "react-router-dom"

import EventCalendar from "../../components/eventCalendar"
import { WorkerList } from "../../components/workers/workerList"
import { PortalContainer } from "../../controllers/portal"
import { WorkerCreate } from "./workerCreate"
import { WorkerEdit } from "./workerEdit"
import { WorkerView } from "./workerView"
import { WorkerTimesheets } from "./workerTimesheets"
import { WorkerMileageClaims } from "./workerMileageClaims"
import { routes } from "routes"

export default function WorkerRoot() {
	return (
		<Switch>
			<Route exact path={routes.workers.edit} component={WorkerEdit} />
			<Route path={routes.workers.create.root} component={WorkerCreate} />
			<Route path={routes.workers.view} component={WorkerSubRoot} />
			<Route exact path={routes.workers.root} component={WorkerList} />
		</Switch>
	)
}

export const WorkerSubRoot = (props: RouteComponentProps<{ workerID: string }>) => {
	const firstLoad = React.useRef(true)
	const { fetchWorker } = PortalContainer.useContainer()

	React.useEffect(() => {
		if (firstLoad.current) {
			// On the first render fetch the workers details
			fetchWorker(props.match.params.workerID)
			firstLoad.current = false
		}
	}, [fetchWorker, firstLoad, props.match.params.workerID])

	return (
		<Switch>
			<Route exact path={routes.workers.view} component={WorkerView} />
			<Route exact path={routes.workers.timesheets} component={WorkerTimesheets} />
			<Route exact path={routes.workers.mileageClaims} component={WorkerMileageClaims} />
			<Route exact path={routes.workers.calendar} render={() => <EventCalendar workerID={props.match.params.workerID} defaultView="month" />} />
		</Switch>
	)
}
