import * as React from "react"
import { useStyletron } from "baseui"
import { ModalFooter, ModalHeader } from "baseui/modal"
import { ZenModal } from "components/zenComponents/zenModal"
import { ClientDetail, ClientRelatedService } from "../../../../types/types"
import { ZenButton } from "../../../zenComponents/zenButtons"
import { ServiceCheckTable } from "../../clientServiceCheckTable"

export const SupplementaryInfo = (props: ClientDetail) => {
	const { relatedService } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
	})
	const [isOpen, setIsOpen] = React.useState(false)

	return (
		<>
			<div className={container}>
				<ZenButton
					$style={{
						width: "50%",
						[`@media only screen and (max-width: 1400px)`]: {
							width: "100%",
						},
					}}
					onClick={() => setIsOpen(true)}
				>
					View interested/explained services
				</ZenButton>
			</div>

			{relatedService && <ServicesModal isOpen={isOpen} onClose={() => setIsOpen(false)} relatedService={relatedService} />}
		</>
	)
}

const ServicesModal = (props: { isOpen: boolean; onClose: () => void; relatedService: ClientRelatedService }) => {
	const { isOpen, onClose, relatedService } = props

	const [css] = useStyletron()
	const container = css({
		padding: "20px",
	})

	return (
		<ZenModal onClose={() => onClose()} isOpen={isOpen}>
			<ModalHeader>Interested/Explained Services</ModalHeader>
			<div className={container}>
				<ServiceCheckTable explainedServiceIDs={relatedService.explainedServiceList} interestedServiceIDs={relatedService.interestedServiceList} />
			</div>

			<ModalFooter>
				<ZenButton onClick={() => onClose()}>Close</ZenButton>
			</ModalFooter>
		</ZenModal>
	)
}
