import * as React from "react"
import { useStyletron } from "baseui"
import { Checkbox, CheckboxProps } from "baseui/checkbox"
import { LabelMedium, LabelSmall } from "baseui/typography"
import { Controller } from "react-hook-form"
import { ZenTheme } from "../../themeOverrides"
import { BasicName } from "../../types/types"

interface ZenCheckboxProps extends CheckboxProps {
	label: string
	formName: string
	formRef: any
	marginTop?: string
	marginBottom?: string
	marginLeft?: string
	marginRight?: string
	width?: string
	fontSize?: "small" | "medium"
	actionOnChange?: (v: boolean) => void
}
export const ZenCheckbox = (props: ZenCheckboxProps) => {
	const { label, formName, formRef, marginBottom, marginTop, checked, width, disabled, fontSize, actionOnChange, ...rest } = props
	const [css] = useStyletron()
	const showLabel = () => {
		if (fontSize === "medium") {
			return <LabelMedium>{label}</LabelMedium>
		}
		return <LabelSmall>{label}</LabelSmall>
	}

	const container = css({
		marginTop: marginTop || 0,
		marginBottom: marginBottom || 0,
		marginLeft: props.marginLeft || 0,
		marginRight: props.marginRight || 0,
		width: width || "unset",
		opacity: disabled ? 0.5 : 1,
	})
	return (
		<div className={container}>
			<Controller
				name={formName}
				control={formRef}
				defaultValue={!!checked}
				render={({ onChange, value, name }) => (
					<Checkbox
						{...rest}
						disabled={disabled}
						onChange={(e) => {
							onChange(e.currentTarget.checked)
							if (actionOnChange) actionOnChange(e.currentTarget.checked)
						}}
						checked={value}
						name={name}
						overrides={{
							...rest.overrides,
							Root: {
								style: {
									alignItems: "center",
									position: "relative",
								},
							},
						}}
					>
						{showLabel()}
					</Checkbox>
				)}
			/>
		</div>
	)
}

interface ZenCheckListProps {
	label: string
	list: BasicName[]
	width?: string
	maxHeight?: string
	overflow?: string
	disabled?: boolean
	formRef: any
}
export const ZenCheckList = (props: ZenCheckListProps) => {
	const { label, list, width, maxHeight, overflow, disabled, formRef } = props
	const [css] = useStyletron()
	const container = css({
		width: width || "100%",
		maxHeight: maxHeight || "100%",
		overflow: overflow || "hidden",
		border: "1px solid " + ZenTheme.colors.primaryGrey,
		borderRadius: "4px",
		backgroundColor: ZenTheme.colors.divider,
		padding: "8px",
		marginTop: "5px",
		marginBottom: "10px",
	})
	return (
		<>
			<LabelMedium marginTop="10px">{label}</LabelMedium>
			<div className={container}>
				{list.map<React.ReactNode>((l, i) => (
					<ZenCheckbox disabled={disabled} key={i} formName={l.id} label={l.name} formRef={formRef} />
				))}
			</div>
		</>
	)
}
