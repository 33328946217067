import * as React from "react"
import { NDISLineItem, NDISPriceGuide, ZenDateTimeFormat } from "../../../types/types"
import { useStyletron } from "baseui"
import { ZenTheme } from "../../../themeOverrides"
import { useParameterizedQuery } from "react-fetching-library"
import { fetching } from "../../../fetching"
import { NDISLineItemType } from "../../../types/enums"
import { ZenCard } from "../../../components/common"
import { LabelLarge, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { FieldDisplay } from "../../../components/fieldDisplay"
import { snakeToTitle } from "../../../helpers/utils"
import { ProgressBar } from "baseui/progress-bar"
import { ErrorNotification } from "../../../components/errorBox"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { PortalContainer } from "../../../controllers/portal"

export const LineItemDetail = (props: { lineItem: NDISLineItem; priceGuide: NDISPriceGuide; setEditMode: (editMode: boolean) => void }) => {
	const [css, theme] = useStyletron()
	const container = css({
		display: "flex",
		flexWrap: "wrap",
	})
	const leftColumn = css({
		flex: 1,
		paddingRight: "40px",
		minWidth: "200px",
	})
	const rightColumn = css({
		minWidth: "200px",
		flex: 1,
	})
	const bottomContainer = css({
		marginTop: "20px",
		marginBottom: "20px",
		display: "flex",
		flexWrap: "wrap",
		backgroundColor: ZenTheme.colors.lightGrey,
		padding: "5px",
	})
	const buttons = css({
		display: "flex",
		justifyContent: "flex-end",
	})

	const { timezone } = PortalContainer.useContainer()
	const [providerTravel, setProviderTravel] = React.useState<NDISLineItem>()
	const [activityTransport, setActivityTransport] = React.useState<NDISLineItem>()
	const { query, payload, loading, error } = useParameterizedQuery(fetching.query.getNDISLineItem)

	const fetchTravel = React.useCallback(async () => {
		if (props.lineItem.providerTravelID) {
			const resp = await query(props.lineItem.providerTravelID)
			if (resp.error) return

			if (resp.payload) {
				setProviderTravel(resp.payload)
			}
		}

		if (props.lineItem.activityTransportID) {
			const resp = await query(props.lineItem.activityTransportID)
			if (resp.error) return

			if (resp.payload) {
				setActivityTransport(resp.payload)
			}
		}
	}, [props.lineItem, query, setProviderTravel, setActivityTransport])

	React.useEffect(() => {
		if (props.lineItem.itemType !== NDISLineItemType.Support) return
		fetchTravel()
	}, [fetchTravel, props.lineItem])

	return (
		<ZenCard>
			<LabelLarge marginTop={0} marginBottom={"10px"}>
				NDIS Line Item - {props.priceGuide.version}
			</LabelLarge>
			<div className={container}>
				<div className={leftColumn}>
					{props.lineItem.deletedAt && (
						<LabelSmall color={theme.colors.negative}>{`Archived (${moment(props.lineItem.deletedAt).tz(timezone.id).format(ZenDateTimeFormat)})`}</LabelSmall>
					)}
					<FieldDisplay label="Item Number" marginTop="10px">
						<LabelSmall>{props.lineItem.itemNumber}</LabelSmall>
					</FieldDisplay>
					<FieldDisplay label="Item Name" marginTop="10px">
						<LabelSmall>{props.lineItem.itemName}</LabelSmall>
					</FieldDisplay>
					<FieldDisplay label="Item Type" marginTop="10px">
						<LabelSmall>{snakeToTitle(props.lineItem.itemType)}</LabelSmall>
					</FieldDisplay>
					<FieldDisplay label="Unit" marginTop="10px">
						<LabelSmall>{snakeToTitle(props.lineItem.unit)}</LabelSmall>
					</FieldDisplay>
					<FieldDisplay label="Created At" marginTop="10px">
						<LabelSmall>{moment(props.lineItem.createdAt).tz(timezone.id).format(ZenDateTimeFormat)}</LabelSmall>
					</FieldDisplay>
					<FieldDisplay label="Updated At" marginTop="10px">
						<LabelSmall>{moment(props.lineItem.updatedAt).tz(timezone.id).format(ZenDateTimeFormat)}</LabelSmall>
					</FieldDisplay>
				</div>
				<div className={rightColumn}>
					<FieldDisplay label="Price National" marginTop="10px">
						<LabelSmall>{props.lineItem.priceNational ? `$${parseFloat(props.lineItem.priceNational).toFixed(2)}` : "N/A"}</LabelSmall>
					</FieldDisplay>
					<FieldDisplay label="Price Remote" marginTop="10px">
						<LabelSmall>{props.lineItem.priceRemote ? `$${parseFloat(props.lineItem.priceRemote).toFixed(2)}` : "N/A"}</LabelSmall>
					</FieldDisplay>
					<FieldDisplay label="Price Very Remote" marginTop="10px">
						<LabelSmall>{props.lineItem.priceVeryRemote ? `$${parseFloat(props.lineItem.priceVeryRemote).toFixed(2)}` : "N/A"}</LabelSmall>
					</FieldDisplay>
					<FieldDisplay label="Is Support Coordinator Code" marginTop="10px">
						<LabelSmall>{props.lineItem.isSupportCoordinatorCode ? "Yes" : "No"}</LabelSmall>
					</FieldDisplay>
					<FieldDisplay label="Rate Variant" marginTop="10px">
						<LabelSmall>{snakeToTitle(props.lineItem.rateVariant)}</LabelSmall>
					</FieldDisplay>
				</div>
			</div>

			{props.lineItem.itemType === NDISLineItemType.Support && (
				<div className={bottomContainer}>
					{loading && <ProgressBar infinite />}
					<div className={leftColumn}>
						<TravelDisplay label={"Provider Travel"} item={providerTravel} />
					</div>
					<div className={rightColumn}>
						<TravelDisplay label={"Activity Transport"} item={activityTransport} />
					</div>
				</div>
			)}
			{error && <ErrorNotification messageOrPayload={payload} />}
			<div className={buttons}>
				<ZenButton disabled={error} onClick={() => props.setEditMode(true)}>
					Edit
				</ZenButton>
			</div>
		</ZenCard>
	)
}

const TravelDisplay = (props: { label: string; item?: NDISLineItem }) => {
	return (
		<div>
			<FieldDisplay label={props.label} marginBottom={"5px"} marginTop={"5px"}>
				{props.item ? (
					<>
						<LabelSmall>{props.item.itemNumber}</LabelSmall>
						<LabelSmall>{props.item.itemName}</LabelSmall>
					</>
				) : (
					<LabelSmall>N/A</LabelSmall>
				)}
			</FieldDisplay>
		</div>
	)
}
