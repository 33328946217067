import * as React from "react"
import { Value } from "baseui/select"
import { LabelLarge } from "baseui/typography"
import { useFormContext } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { useStyletron } from "styletron-react"
import { CancelAndSaveButtons } from "../../../../cancelSaveButtons"
import { ZenInput } from "../../../../zenComponents/zenInput"
import { ZenSelect } from "../../../../zenComponents/zenSelectBox"
import { ZenDatePicker } from "../../../../zenComponents/zenTime"
import { IntakeAdultProps, intakeURLParser } from "../intakeAdultBaseForm"
import { ErrorFieldTracker } from "../../../../forms/errorFieldTracker"

const ServiceType = ["Psychosocial Support Services", "NDIS"]

export const IntakeNDISServiceDetail = (props: IntakeAdultProps) => {
	const { clientID, formID, validationTriggered, isReadOnly } = props
	const [css] = useStyletron()
	const { trigger, control, errors, clearErrors, getValues } = useFormContext()
	const history = useHistory()

	const validation = React.useCallback(async () => {
		return await trigger(["serviceType", "ndisPlanIsProvided", "ndisPlanNumber"])
	}, [trigger])

	React.useEffect(() => {
		if (validationTriggered) validation()
	}, [validationTriggered, validation])

	const onSubmit = async () => {
		const isValid = await validation()
		if (!isValid) return
		nextPage()
	}

	const nextPage = () => history.push(intakeURLParser(`/portal/clients/${clientID}/intake/ndis/mentalHealthTreatingTeam`, formID, true))

	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
	})
	const formStyle = css({
		width: "100%",
		height: "95%",
		display: "flex",
		flexDirection: "column",
	})
	const body = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})
	return (
		<div className={container}>
			<LabelLarge>Service Detail</LabelLarge>
			<form autoComplete="off" className={formStyle}>
				<div className={body}>
					<ZenSelect
						disabled={isReadOnly}
						label="Service type"
						formName="serviceType"
						inputError={errors.serviceType}
						formRef={control}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Service type is required",
							},
						}}
						options={ServiceType.map((st) => ({ id: st, label: st }))}
					/>
					<ZenSelect
						disabled={isReadOnly}
						label="NDIS Plan is Provided"
						formName="ndisPlanIsProvided"
						inputError={errors.ndisPlanIsProvided}
						formRef={control}
						actionOnChange={() => {
							clearErrors("ndisPlanNumber")
						}}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "NDIS plan option is required",
							},
						}}
						options={[
							{ id: "Yes", label: "Yes" },
							{ id: "No", label: "No" },
							{ id: "Will email through ASAP", label: "Will email through ASAP" },
						]}
					/>

					<ZenInput
						disabled={isReadOnly}
						label="NDIS plan number"
						nameRef="ndisPlanNumber"
						formRef={control}
						inputError={errors.ndisPlanNumber}
						formRules={{
							validate: {
								optionallyRequired: (value: string) => {
									// check whether NDIS plan is provided
									const ndisPlanProvided = getValues().ndisPlanIsProvided
									// if not, skip
									if (ndisPlanProvided.length === 0 || ndisPlanProvided[0].id === "No") return true
									// otherwise check the value is not empty
									return value !== "" || "NDIS plan number is required"
								},
							},
						}}
					/>

					<ZenDatePicker
						disabled={isReadOnly}
						label="NDIS plan review date"
						formName="ndisPlanReviewDate"
						formRef={control}
						inputError={errors.ndisPlanReviewDate}
						nullDefaultValue
						clearable
						formRules={{
							validate: {
								optionallyRequired: (value: Date) => {
									// check whether NDIS plan is provided
									const ndisPlanProvided = getValues().ndisPlanIsProvided
									// if not, skip
									if (ndisPlanProvided.length === 0 || ndisPlanProvided[0].id === "No") return true
									// otherwise check the value is not empty
									return !!value || "NDIS plan review date is required"
								},
							},
						}}
					/>
				</div>
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				{!isReadOnly && <CancelAndSaveButtons cancelLabel="Back" cancelFn={history.goBack} saveLabel="Next" saveFn={onSubmit} />}
			</form>
		</div>
	)
}
