import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal"
import { Value } from "baseui/select"
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip"
import { LabelMedium, LabelSmall, LabelXSmall } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { Control, useForm } from "react-hook-form"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { ZenTheme } from "../../themeOverrides"
import { NDISPlan, Note, RolePermission, Session, SessionNoteContent, SessionsNote } from "../../types/types"
import { UserLabel } from "../callLogs/subComponents"
import { CancelAndSaveButtons } from "../cancelSaveButtons"
import { ErrorNotification } from "../errorBox"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenTextArea } from "../zenComponents/zenInput"
import { ZenDisplaySelect, ZenSelect } from "../zenComponents/zenSelectBox"
import { AuthContainer } from "../../controllers/auth"
import { Block } from "baseui/block"
import { FieldValues } from "react-hook-form/dist/types/form"

export const SessionNoteCard = (props: SessionsNote) => {
	const { note } = props
	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const [css] = useStyletron()
	const { showToast } = useZenToast()
	const { control, errors, handleSubmit, setValue } = useForm({
		defaultValues: {
			newNote: note.revisions && note.revisions.length > 0 ? note.revisions[0].content : undefined,
		},
	})
	const { timezone } = PortalContainer.useContainer()

	const [poster, setPoster] = React.useState((note && note.revisions.length > 0 && note.revisions[0].poster) || undefined)
	const [content, setContent] = React.useState<string>((note.revisions && note.revisions.length > 0 && note.revisions[0].content) || "")
	const [contentJSON, setContentJSON] = React.useState<SessionNoteContent | undefined>(
		(note.revisions && note.revisions.length > 0 && note.revisions[0].contentJSON) || undefined,
	)
	const [editMode, setEditMode] = React.useState<boolean>(false)
	const [showRevisionList, setShowRevisionList] = React.useState<boolean>(false)
	const [revisionNotes, setRevisionNotes] = React.useState<Note[]>(note.revisions)
	const { mutate, error, payload, loading } = useMutation<Note>(fetching.mutation.sessionNoteUpdate)

	const canEdit = hasPermission(RolePermission.SessionNoteUpdate) || currentUser?.id === poster?.id

	React.useEffect(() => {
		if (!note.revisions || note.revisions.length === 0) return
		if (note.revisions[0].contentJSON) {
			Object.entries(note.revisions[0].contentJSON).forEach((c) => {
				setValue(c[0], c[1])
			})
			return
		}
		setValue("newNote", note.revisions[0].content)
	}, [note, setValue])

	const onSubmit = async (formData: any) => {
		let input: any = {
			id: note.id,
		}
		// if content is in json format
		if (contentJSON) {
			// check content is different
			let isDifferent = false
			Object.entries(contentJSON).forEach((content) => {
				// skip checking if detected content is different
				if (isDifferent) return
				SessionNoteQuestionLabels.forEach((q) => {
					// skip checking if detected content is different
					if (isDifferent) return
					if (content[0] === q && content[1] !== formData[q]) {
						isDifferent = true
					}
				})
			})

			// do not trigger fetching, if the content is the same
			if (!isDifferent) {
				setEditMode(false)
				return
			}

			// otherwise append value to request
			let content: any = {}
			SessionNoteQuestionLabels.forEach((q) => {
				content[q] = formData[q] || ""
			})
			input["contentJSON"] = content
		} else {
			// do not trigger fetching, if the content is the same
			if (content === formData.newNote) {
				setEditMode(false)
				return
			}

			// otherwise append value to request
			input["content"] = formData.newNote
		}

		const resp = await mutate(input)

		if (resp.error || !resp.payload) return
		showToast("Session Note updated successfully.", "positive")

		const newList = [...revisionNotes]
		newList.unshift(resp.payload)
		setPoster(resp.payload.poster)
		setRevisionNotes(newList)
		setContent(resp.payload.content)
		setEditMode(false)

		if (resp.payload.contentJSON) {
			setContentJSON(resp.payload.contentJSON)
			Object.entries(resp.payload.contentJSON).forEach((c) => {
				setValue(c[0], c[1])
			})
			return
		}
		setValue("newNote", resp.payload.content)
	}

	const exitEdit = () => {
		setEditMode(false)
		if (note.revisions[0].contentJSON) {
			Object.entries(note.revisions[0].contentJSON).forEach((c) => {
				setValue(c[0], c[1])
			})
			return
		}
		setValue("newNote", note.revisions[0].content)
	}
	const container = css({
		display: "flex",
		flexDirection: "column",
		padding: "10px",
		backgroundColor: ZenTheme.colors.lightGrey,
		marginBottom: "8px",
	})
	const title = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	})
	const timestamp = css({
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
	})
	const iconStyle = css({
		marginLeft: "8px",
		cursor: "pointer",
	})

	return (
		<div className={container}>
			{error && <ErrorNotification messageOrPayload={payload} closeable />}
			<div className={title}>
				<div className={group}>
					<UserLabel data={poster || note.poster} />
					<LabelSmall marginLeft="3px">{`- ${note.type.name} Note`}</LabelSmall>
				</div>
				{!editMode && (
					<div className={group}>
						<StatefulTooltip content={() => <div>History</div>} placement={PLACEMENT.top} returnFocus autoFocus>
							<div className={iconStyle} onClick={() => setShowRevisionList(true)}>
								<FontAwesomeIcon icon={["fal", "history"]} />
							</div>
						</StatefulTooltip>
						{canEdit && (
							<div className={iconStyle} onClick={() => setEditMode(true)}>
								<FontAwesomeIcon icon={["fal", "edit"]} />
							</div>
						)}
					</div>
				)}
			</div>
			{editMode ? (
				<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					{contentJSON ? (
						<SessionNoteQuestionForm control={control} />
					) : (
						<ZenTextArea formRef={control} nameRef="newNote" inputError={errors.newNote} height="210px" formRules={{ required: "can not be empty" }} />
					)}
					<CancelAndSaveButtons cancelFn={exitEdit} isLoading={loading} />
				</form>
			) : (
				<>
					<DisplaySessionNoteJSONcontent contentJSON={contentJSON} contentText={content} padding="8px" />
					<div className={timestamp}>
						<LabelXSmall marginLeft={"8px"}>
							Client: {props.client.firstName} {props.client.lastName}
						</LabelXSmall>
						<LabelXSmall>{moment(note.createdAt).tz(timezone.id).format("hh:mm a, DD/MM/YYYY")}</LabelXSmall>
					</div>
				</>
			)}
			<RevisionListModal isOpen={showRevisionList} setIsOpen={setShowRevisionList} revisions={revisionNotes} />
		</div>
	)
}

interface RevisionListModalProps {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	revisions: Note[]
}
export const RevisionListModal = (props: RevisionListModalProps) => {
	const { isOpen, setIsOpen, revisions } = props
	const [css] = useStyletron()
	const { timezone } = PortalContainer.useContainer()
	const container = css({
		maxHeight: "700px",
		width: "500px",
		paddingRight: "8px",
		overflowY: "auto",
		overflowX: "hidden",
	})
	const cardStyle = css({
		padding: "10px",
		backgroundColor: ZenTheme.colors.divider,
		marginBottom: "8px",
		width: "100%",
		height: "fit-content",
	})
	const timestamp = css({
		display: "flex",
		width: "100%",
		justifyContent: "flex-end",
	})

	const displayInfo = () => {
		if (!revisions || revisions.length === 0) return null
		return revisions.map((rn, i) => (
			<div key={i} className={cardStyle}>
				<UserLabel data={rn.poster} />
				<DisplaySessionNoteJSONcontent contentText={rn.content} contentJSON={rn.contentJSON} padding="8px" />
				<div className={timestamp}>
					<LabelXSmall>{moment(rn.createdAt).tz(timezone.id).format("hh:mm a, DD/MM/YYYY")}</LabelXSmall>
				</div>
			</div>
		))
	}

	return (
		<ZenModal onClose={() => setIsOpen(false)} isOpen={isOpen} size={SIZE.auto} role={ROLE.dialog}>
			<ModalHeader>Revision List</ModalHeader>
			<ModalBody>
				<div className={container}>{displayInfo()}</div>
			</ModalBody>
		</ZenModal>
	)
}

interface SessionNoteContentProps {
	contentJSON?: SessionNoteContent
	contentText?: string
	padding?: string
}
export const DisplaySessionNoteJSONcontent = (props: SessionNoteContentProps) => {
	const { contentJSON, contentText, padding } = props

	if (contentJSON && typeof contentJSON === "object" && Object.keys(contentJSON).length > 0)
		return (
			<Block padding={padding}>
				{SessionNoteQuestionLabels.map((noteLabel, i) => (
					<React.Fragment key={i}>
						<LabelSmall
							overrides={{
								Block: {
									style: {
										whiteSpace: "pre-line",
									},
								},
							}}
						>
							{noteLabel}:
						</LabelSmall>
						<LabelSmall
							marginLeft="4px"
							overrides={{
								Block: {
									style: {
										color: ZenTheme.colors.primaryGrey,
										whiteSpace: "pre-line",
									},
								},
							}}
						>
							{Object.entries(contentJSON).filter((o) => o[0] === noteLabel)[0][1]}
						</LabelSmall>
					</React.Fragment>
				))}
			</Block>
		)
	return (
		<LabelSmall
			padding={padding}
			overrides={{
				Block: {
					style: {
						whiteSpace: "pre-line",
					},
				},
			}}
		>
			{contentText ? contentText : typeof contentJSON === "string" ? contentJSON : ""}
		</LabelSmall>
	)
}

export const SessionNoteQuestionLabels: string[] = [
	"Observations of clients overall Health/Wellbeing",
	"Actions undertaken during session",
	"Plan for next session",
	"Other comments/notes",
]
interface SessionNoteQuestionProps {
	control: Control<FieldValues>
	nameRefPrefix?: string
	label?: string
}
export const SessionNoteQuestionForm = (props: SessionNoteQuestionProps) => {
	const { label, control, nameRefPrefix } = props
	const [css] = useStyletron()
	const contentContainer = css({
		border: "2px solid black",
		display: "flex",
		flexDirection: "column",
		padding: "8px",
		maxHeight: "400px",
		overflow: "auto",
		marginTop: "8px",
		borderRadius: "4px",
		marginBottom: "4px",
		backgroundColor: "white",
	})
	return (
		<>
			{label && <LabelSmall>{label}</LabelSmall>}
			<div className={contentContainer}>
				{SessionNoteQuestionLabels.map((q, i) => (
					<div key={i}>
						<LabelSmall>{q}</LabelSmall>
						<ZenTextArea formRef={control} nameRef={`${nameRefPrefix || ""}${q}`} resizable hideBorder marginTop="0" marginBottom="0" />
					</div>
				))}
			</div>
		</>
	)
}

interface SessionNoteMode {
	session: Session
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	setNotes: React.Dispatch<React.SetStateAction<SessionsNote[]>>
}

export const SessionAddNoteModal = (props: SessionNoteMode) => {
	const { session, isOpen, setIsOpen, setNotes } = props
	const [css] = useStyletron()
	const { showToast } = useZenToast()
	const { mutate, error, payload, loading } = useMutation<SessionsNote>(fetching.mutation.sessionNoteAdd)
	const { control, handleSubmit, setValue, errors } = useForm()

	const noteTypeMany = useQuery(fetching.query.noteTypeMany(false, session.id))

	const { query: getNDISPlan, payload: ndisPlan, error: ndisPlanError } = useParameterizedQuery<NDISPlan>(fetching.query.getClientActiveNDISPlan)

	React.useEffect(() => {
		if (session.sessionFundingSources.length === 0 || !session.sessionFundingSources[0].fundingSource) return
		if (session.sessionFundingSources[0].fundingSource.label === "NDIA" && session.clients) getNDISPlan(session.clients[0].id)
	}, [session.clients, getNDISPlan, session.sessionFundingSources])

	// set up the options for the select box, when loaded
	const [options, setOptions] = React.useState<Value>([])
	React.useEffect(() => {
		if (noteTypeMany.loading || noteTypeMany.error || !noteTypeMany.payload) return
		setOptions(noteTypeMany.payload.map((nt) => ({ id: nt.id, label: nt.name })))
	}, [noteTypeMany.payload, noteTypeMany.loading, noteTypeMany.error])

	const clearData = () => {
		setValue("noteType", [])
		setValue("client", [])
		setValue("groupSession", [])
		setValue("unexpectedAttendance", [])
		setValue("notifiedDate", null)
		setValue("notifiedTime", null)
		setValue("clientContactDate", null)
		setValue("clientContactTime", null)
		setValue("sessionEndedDate", null)
		setValue("sessionEndedTime", null)
		setValue("notificationWasSentBy", "")
		setValue("notifiedTeamLeader", [])
		setValue("actionTaken", "")
		setValue("actionsTakenToLocateClient", "")
		setValue("reasonSessionEndedEarly", "")
		setValue("notificationMadeTo", [])

		SessionNoteQuestionLabels.forEach((q) => {
			setValue(q, "")
		})
	}

	const handleOnClose = () => {
		clearData()
		setIsOpen(false)
	}
	const onSubmit = async (data: any) => {
		// parse content
		let content: any = {}
		SessionNoteQuestionLabels.forEach((q) => {
			content[q] = data[q] || ""
		})

		// parse input
		const input: any = {
			clientID: data.client[0].id,
			sessionID: session.id,
			noteTypeID: data.noteType[0].id,
			content,
		}

		const resp = await mutate(input)
		if (resp.error) return
		showToast("Session Note created successfully.", "positive")
		setNotes((n) => {
			if (!resp.payload) return n
			return n.concat(resp.payload)
		})
		handleOnClose()
	}
	const container = css({
		padding: "15px",
		backgroundColor: "white",
	})
	const buttonContainer = css({
		display: "flex",
		width: "100%",
		justifyContent: "flex-end",
	})
	const noteInfo = css({
		width: "100%",
		maxWidth: "500px",
		display: "flex",
		flexDirection: "column",
	})

	return (
		<ZenModal
			onClose={handleOnClose}
			size={SIZE.auto}
			role={ROLE.dialog}
			isOpen={isOpen}
			overrides={{
				Dialog: {
					style: {
						width: "80%",
						maxWidth: "1200px",
					},
				},
			}}
		>
			<ModalBody>
				<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
					<LabelMedium>Session Notes</LabelMedium>
					{ndisPlanError && <ErrorNotification messageOrPayload={ndisPlan} />}
					{error && <ErrorNotification messageOrPayload={payload} />}
					<div className={noteInfo}>
						{ndisPlan && <ClientNDISGoal {...ndisPlan} />}
						<ZenDisplaySelect
							placeholder={"Select a client"}
							label={"Client"}
							formRef={control}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || "Client is required",
								},
							}}
							formName={"client"}
							inputError={errors.client}
							options={props.session.clients?.map((c) => ({ ...c, label: `${c.firstName} ${c.lastName}` })) || []}
							loading={false}
							defaultValue={props.session.clients && props.session.clients.length === 1 ? [{ id: props.session.clients[0].id }] : []}
						/>
						<ZenSelect
							label="Type"
							formRef={control}
							formName="noteType"
							options={options}
							clearable={false}
							inputError={errors.noteType}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || "Note type is required",
								},
							}}
						/>
					</div>
					<LabelSmall marginTop="15px">Enter notes on what you talked about, be very descriptive.</LabelSmall>
					<SessionNoteQuestionForm control={control} />
					<div className={buttonContainer}>
						<ZenButton type="submit" isLoading={loading}>
							Submit
						</ZenButton>
					</div>
				</form>
			</ModalBody>
		</ZenModal>
	)
}

export const ClientNDISGoal = (props: NDISPlan) => {
	const [css] = useStyletron()
	const container = css({
		height: "fit-content",
		width: "100%",
		marginTop: "5px",
		marginBottom: "5px",
	})
	const labelBox = css({
		whiteSpace: "pre-line",
		wordBreak: "break-word",
	})
	return (
		<div className={container}>
			<LabelSmall>Short Term Goals</LabelSmall>
			<LabelXSmall className={labelBox}>{props.shortTermGoals}</LabelXSmall>
			<LabelSmall>Medium or Long Term Goals</LabelSmall>
			<LabelXSmall className={labelBox}>{props.mediumOrLongTermGoals}</LabelXSmall>
		</div>
	)
}
