import * as React from "react"
import { LabelLarge, ParagraphSmall } from "baseui/typography"
import { useForm } from "react-hook-form"
import { useStyletron } from "styletron-react"

import { Spacer, ZenCard } from "../../components/common"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenDatePicker } from "../../components/zenComponents/zenTime"
import { ErrorNotification } from "../../components/errorBox"
import { useMutation } from "react-fetching-library"
import { fetching } from "../../fetching"
import { useZenToast } from "../../components/zenComponents/useZenToast"
import { ZenConfirmModal } from "../../components/zenComponents/zenConfirmModal"
import moment from "moment/moment"

interface FormData {
	startDate: string
	endDate: string
}

export const RecalculateLimits = () => {
	const [css] = useStyletron()
	const card = css({
		height: "fit-content",
		width: "fit-content",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		flexDirection: "column",
	})
	const buttonRow = css({
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "15px",
	})
	const group = css({
		display: "flex",
		justifyContent: "space-between",
	})

	const { control, handleSubmit, getValues, errors } = useForm<FormData>()
	const { mutate, loading, error, payload } = useMutation(fetching.mutation.recalculateTravelLimits)
	const [showConfirmModal, setShowConfirmModal] = React.useState(false)
	const { showToast } = useZenToast()

	const onSubmit = () => {
		setShowConfirmModal(true)
	}

	const handleMutate = async () => {
		const data = getValues()

		const resp = await mutate({
			startDate: new Date(data.startDate),
			endDate: new Date(data.endDate),
		})

		if (resp.error || !resp.payload) {
			setShowConfirmModal(false)
			return
		}

		setShowConfirmModal(false)
		showToast(`Updated ${resp.payload.travelCount} travels.`, "positive")
	}

	return (
		<ZenCard className={card}>
			<form autoComplete="off" className={title} onSubmit={handleSubmit(onSubmit)}>
				<LabelLarge marginBottom="20px">Recalculate Travel Limits</LabelLarge>
				<ParagraphSmall maxWidth={"400px"}>
					This will re-calculate travel limits for all travels in the selected period ignoring legacy imported sessions.
				</ParagraphSmall>
				<div className={group}>
					<div>
						<ZenDatePicker
							inputError={errors.startDate}
							nullDefaultValue={true}
							formName="startDate"
							label="Start Date"
							formRef={control}
							formRules={{
								required: "You must select a start date.",
							}}
						/>
					</div>
					<Spacer style={{ width: 15 }} />
					<div>
						<ZenDatePicker
							inputError={errors.endDate}
							nullDefaultValue={true}
							formName="endDate"
							label="End Date"
							formRef={control}
							formRules={{
								required: "You must select an end date.",
								validate: (value: string) => {
									if (moment(value).isBefore(moment(getValues("startDate")))) {
										return "End date must be after start date."
									}

									// Limit to one week duration to limit number of travels updated as action can take
									// a long time since it makes api calls etc.
									const startDate = getValues("startDate")
									if (moment.duration(moment(value).diff(moment(startDate))) > moment.duration(1, "week")) {
										return "Duration must be one week or less."
									}

									return null
								},
							}}
						/>
					</div>
				</div>
				{error && <ErrorNotification messageOrPayload={payload} />}
				<div className={buttonRow}>
					<ZenButton type="submit" isLoading={loading}>
						Recalculate
					</ZenButton>
				</div>
			</form>
			<ZenConfirmModal
				open={showConfirmModal}
				setOpen={setShowConfirmModal}
				loading={loading}
				title={"Are you sure?"}
				message={"Are you sure you want recalculate travel limits? This action can't be undone."}
				action={"Recalculate"}
				confirm={() => {
					handleMutate()
				}}
				kind="primary"
			/>
		</ZenCard>
	)
}
