import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Option, Value } from "baseui/select"
import { LabelLarge, LabelMedium } from "baseui/typography"
import moment from "moment-timezone"
import { FieldError, useFormContext } from "react-hook-form"
import { DeepMap } from "react-hook-form/dist/types/utils"
import { useHistory } from "react-router-dom"

import { ZenTheme } from "../../../../themeOverrides"
import { ClientRelationshipType } from "../../../../types/enums"
import { atsiOption } from "../../../../types/types"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { ZenButton } from "../../../zenComponents/zenButtons"
import { ZenCheckbox } from "../../../zenComponents/zenCheckboxList"
import { ZenInput } from "../../../zenComponents/zenInput"
import { ZenSelect } from "../../../zenComponents/zenSelectBox"
import { ZenDatePicker } from "../../../zenComponents/zenTime"
import { intakeURLParser } from "../adult/intakeAdultBaseForm"
import { IntakeScrollDiv } from "../intakeComponent"
import { IntakeYouthProps } from "./intakeYouthBaseForm"
import { ErrorFieldTracker } from "../../../forms/errorFieldTracker"

interface IntakeYouthParentGuardianDetailProps extends IntakeYouthProps {
	placeholder: number[]
	setPlaceholder: React.Dispatch<React.SetStateAction<number[]>>
	count: number
	setCount: React.Dispatch<React.SetStateAction<number>>
	contactErrors: boolean[]
	setContactErrors: React.Dispatch<React.SetStateAction<boolean[]>>
	countryOptions: Option[]
	languageOptions: Option[]
}

export const IntakeYouthParentGuardianDetail = (props: IntakeYouthParentGuardianDetailProps) => {
	const {
		clientID,
		formID,
		validationTriggered,
		isReadOnly,
		placeholder,
		setPlaceholder,
		count,
		setCount,
		contactErrors,
		setContactErrors,
		countryOptions,
		languageOptions,
	} = props
	const [css] = useStyletron()
	const history = useHistory()
	const { control, errors, getValues, trigger } = useFormContext()

	// functions
	const addNewForm = () => {
		const nextIndex = count + 1
		setPlaceholder(placeholder.concat(nextIndex))
		setContactErrors(contactErrors.concat(false))
		setCount(count + 1)
	}
	const clearForm = (index: number) => {
		setPlaceholder(placeholder.filter((p) => p !== index))
	}

	const validation = React.useCallback(async () => {
		// generate check list
		const checkList: string[] = []
		const contactErrors: boolean[] = []
		let hasError = false // track whether there is any contact error

		placeholder.forEach((p) => {
			let isError = false // track whether current contact has error

			// check current parent and guardian has contact error
			if (getValues()[`parentGuardianMobileNumber${p}`] === "" && getValues()[`parentGuardianTelephoneNumber${p}`] === "") {
				isError = true
				hasError = true
			}

			checkList.push(
				`parentGuardianFirstName${p}`,
				`parentGuardianLastName${p}`,
				`parentGuardianRelationship${p}`,
				`parentGuardianTelephoneNumber${p}`,
				`parentGuardianMobileNumber${p}`,
				`parentGuardianCountryOfBirthID${p}`,
				`parentGuardianLanguageSpokenAtHomeID${p}`,
				`parentGuardianAboriginalOrTorresStraitIslander${p}`,
				`parentGuardianDateOfBirth${p}`,
			)
			contactErrors.push(isError)
		})
		setContactErrors(contactErrors)
		// trigger validation
		const isValid = await trigger(checkList)

		return isValid && !hasError
	}, [getValues, placeholder, setContactErrors, trigger])

	const onSubmit = async () => {
		const isValid = await validation()
		if (!isValid) return
		history.push(intakeURLParser(`/portal/clients/${clientID}/intake/youth/challengesForTheClient`, formID))
	}

	React.useEffect(() => {
		if (validationTriggered) validation()
	}, [validationTriggered, validation])

	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "white",
		flexDirection: "row",
	})
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
		paddingTop: "25px",
	})
	const body = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})

	return (
		<div className={container}>
			<form autoComplete="off" className={formStyle}>
				<LabelLarge marginBottom="10px">Parent Guardian Details</LabelLarge>
				<div className={body}>
					<IntakeScrollDiv>
						{placeholder.length > 0 &&
							placeholder.map((c) => (
								<ParentGuardianDetailForm
									disabled={isReadOnly}
									key={c}
									index={c}
									control={control}
									inputError={errors}
									contactError={contactErrors[c]}
									languageOptions={languageOptions}
									countryOptions={countryOptions}
									clear={c > 0 ? () => clearForm(c) : undefined}
								/>
							))}
						{!isReadOnly && (
							<ZenButton type="button" onClick={addNewForm}>
								Add another parent/guardian
							</ZenButton>
						)}
					</IntakeScrollDiv>
				</div>
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				{!isReadOnly && <CancelAndSaveButtons cancelLabel="Back" cancelFn={history.goBack} saveLabel="Next" saveFn={onSubmit} />}
			</form>
		</div>
	)
}

interface ParentGuardianDetailFormProps {
	index?: number
	control: any
	countryOptions: Option[]
	languageOptions: Option[]
	inputError: DeepMap<Record<string, any>, FieldError>
	contactError: boolean
	clear?: () => void
	disabled?: boolean
	omitBorder?: boolean
}

export const ParentGuardianDetailForm = (props: ParentGuardianDetailFormProps) => {
	const { index, inputError, control, clear, contactError, countryOptions, languageOptions, disabled, omitBorder } = props
	const [css] = useStyletron()
	const container = css({
		border: omitBorder ? 0 : "1px solid " + ZenTheme.colors.primaryGrey,
		borderRadius: "8px",
		padding: "10px",
		marginBottom: "15px",
		position: clear ? "relative" : "unset",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
	})
	const removeIcon = css({
		position: "absolute",
		top: "8px",
		right: "10px",
	})
	return (
		<div className={container}>
			{clear && !disabled && (
				<div className={removeIcon} onClick={() => clear()}>
					<FontAwesomeIcon icon={["fal", "times-circle"]} />
				</div>
			)}
			<div className={group}>
				<ZenInput
					disabled={disabled}
					label="First Name"
					nameRef={`parentGuardianFirstName${index !== undefined ? index : ""}`}
					formRef={control}
					required
					inputError={inputError[`parentGuardianFirstName${index !== undefined ? index : ""}`]}
				/>
				<ZenInput
					disabled={disabled}
					marginLeft="10px"
					label="Last Name"
					nameRef={`parentGuardianLastName${index !== undefined ? index : ""}`}
					formRef={control}
					required
					inputError={inputError[`parentGuardianLastName${index !== undefined ? index : ""}`]}
				/>
			</div>
			<ZenSelect
				disabled={disabled}
				label="Relationship"
				formName={`parentGuardianRelationship${index !== undefined ? index : ""}`}
				formRef={control}
				formRules={{
					validate: {
						required: (value: Value) => (!!value && value.length > 0) || "Guardian Relationship is required",
					},
				}}
				inputError={inputError[`parentGuardianRelationship${index !== undefined ? index : ""}`]}
				clearable={false}
				options={Object.values(ClientRelationshipType).map((r) => ({ id: r, label: r }))}
			/>
			{contactError && (
				<LabelMedium color={ZenTheme.colors.warning600} marginTop="10px">
					One phone number is required
				</LabelMedium>
			)}
			<ZenInput
				// Use generic input to allow for more flexible usage, such as a note about the number.
				// Not required to speed up onboarding
				disabled={disabled}
				label="Telephone Number"
				nameRef={`parentGuardianTelephoneNumber${index !== undefined ? index : ""}`}
				formRef={control}
				inputError={inputError[`parentGuardianTelephoneNumber${index !== undefined ? index : ""}`]}
			/>
			<ZenInput
				// Use generic input to allow for more flexible usage, such as a note about the number.
				// Not required to speed up onboarding
				disabled={disabled}
				label="Mobile Number"
				nameRef={`parentGuardianMobileNumber${index !== undefined ? index : ""}`}
				formRef={control}
				inputError={inputError[`parentGuardianMobileNumber${index !== undefined ? index : ""}`]}
			/>
			<ZenDatePicker
				disabled={disabled}
				label="Date of birth"
				nullDefaultValue
				formName={`parentGuardianDateOfBirth${index !== undefined ? index : ""}`}
				inputError={inputError[`parentGuardianDateOfBirth${index !== undefined ? index : ""}`]}
				formRef={control}
				formRules={{
					required: "Guardian Date of birth is required",
					validate: {
						isInThePast: (value: Date) => {
							return value < new Date() || "Guardian date of birth should be in the past"
						},
					},
				}}
				minDate={new Date("1900-01-01")}
				maxDate={moment().toDate()}
			/>
			<ZenSelect
				disabled={disabled}
				label="Country of Birth"
				formName={`parentGuardianCountryOfBirthID${index !== undefined ? index : ""}`}
				formRef={control}
				formRules={{
					validate: {
						required: (value: Value) => (!!value && value.length > 0) || "Guardian Country of birth is required",
					},
				}}
				inputError={inputError[`parentGuardianCountryOfBirthID${index !== undefined ? index : ""}`]}
				clearable={false}
				options={countryOptions}
			/>
			<ZenSelect
				disabled={disabled}
				label="Language Spoken at Home"
				formName={`parentGuardianLanguageSpokenAtHomeID${index !== undefined ? index : ""}`}
				formRef={control}
				formRules={{
					validate: {
						required: (value: Value) => (!!value && value.length > 0) || "Guardian Language spoken at home is required",
					},
				}}
				inputError={inputError[`parentGuardianLanguageSpokenAtHomeID${index !== undefined ? index : ""}`]}
				clearable={false}
				options={languageOptions}
			/>
			<div className={group}>
				<ZenCheckbox
					disabled={disabled}
					label="Translator Required"
					formName={`parentGuardianRequiredTranslator${index !== undefined ? index : ""}`}
					formRef={control}
					width="100%"
					marginTop="5px"
				/>
				<ZenCheckbox
					disabled={disabled}
					label="Identify as CaLD"
					formName={`parentGuardianCulturallyAndLinguisticallyDiverse${index !== undefined ? index : ""}`}
					formRef={control}
					checked={false}
					width="100%"
					marginTop="5px"
				/>
			</div>
			<ZenSelect
				disabled={disabled}
				label="Identify as ATSI"
				formName={`parentGuardianAboriginalOrTorresStraitIslander${index !== undefined ? index : ""}`}
				formRef={control}
				inputError={inputError[`parentGuardianAboriginalOrTorresStraitIslander${index !== undefined ? index : ""}`]}
				formRules={{
					validate: {
						required: (value: Value) => (!!value && value.length > 0) || "Guardian ATSI is required",
					},
				}}
				clearable={false}
				options={Object.values(atsiOption).map((a) => ({ label: a, id: a }))}
			/>
		</div>
	)
}
