import * as React from "react"
import { useStyletron } from "baseui"
import { Checkbox } from "baseui/checkbox"
import { LabelMedium } from "baseui/typography"
import { LabelSmall } from "baseui/typography"
import { useQuery } from "react-fetching-library"

import { fetching } from "../../fetching"
import { ZenTheme } from "../../themeOverrides"
import { BasicName } from "../../types/types"
import { ErrorNotification } from "../errorBox"

enum CheckType {
	Explained = "Explained",
	Interested = "Interested",
}
interface ServiceCheckTableProps {
	explainedServiceIDs: string[]
	setExplainedServiceIDs?: React.Dispatch<React.SetStateAction<string[]>>
	interestedServiceIDs: string[]
	setInterestedServiceIDs?: React.Dispatch<React.SetStateAction<string[]>>
	disabled?: boolean
}
export const ServiceCheckTable = (props: ServiceCheckTableProps) => {
	const { explainedServiceIDs, setExplainedServiceIDs, interestedServiceIDs, setInterestedServiceIDs, disabled } = props
	const [relatedServiceOptions, setRelatedServiceOptions] = React.useState<BasicName[]>([])
	const relatedServiceData = useQuery<BasicName[]>(fetching.query.getRelatedServiceAll())
	React.useEffect(() => {
		if (relatedServiceData.loading || !relatedServiceData.payload) return
		setRelatedServiceOptions(relatedServiceData.payload)
	}, [relatedServiceData.payload, relatedServiceData.loading])
	const handleOnChecked = (type: CheckType, id: string, checked: boolean) => {
		switch (type) {
			case CheckType.Interested:
				toggleCheck(id, checked, setInterestedServiceIDs)
				break
			case CheckType.Explained:
				toggleCheck(id, checked, setExplainedServiceIDs)
				break
		}
	}
	const toggleCheck = (id: string, checked: boolean, setData?: React.Dispatch<React.SetStateAction<string[]>>) => {
		if (!setData) return
		if (checked) {
			setData((data) => data.concat(id))
			return
		}
		setData((data) => data.filter((d) => d !== id))
	}
	const [css] = useStyletron()
	const container = css({
		width: "100%",
		padding: "8px",
		backgroundColor: ZenTheme.colors.divider,
		border: "1px solid " + ZenTheme.colors.primaryGrey,
		borderRadius: "5px",
	})
	const header = css({
		display: "flex",
		marginBottom: "8px",
	})
	return (
		<div className={container}>
			{relatedServiceData.error && <ErrorNotification messageOrPayload={relatedServiceData.payload} />}

			<div className={header}>
				<LabelMedium width="100%">Service</LabelMedium>
				<LabelMedium minWidth="100px">Explained</LabelMedium>
				<LabelMedium minWidth="100px">Interested</LabelMedium>
			</div>
			{relatedServiceOptions.length > 0 &&
				relatedServiceOptions.map((o, i) => (
					<ServiceCheckCard
						disabled={disabled}
						key={i}
						{...o}
						explainedServiceIDs={explainedServiceIDs}
						interestedServiceIDs={interestedServiceIDs}
						onCheck={handleOnChecked}
						backgroundColor={i % 2 === 0 ? "#00000011" : "unset"}
					/>
				))}
		</div>
	)
}

interface ServiceCheckCardProps extends BasicName {
	explainedServiceIDs: string[]
	interestedServiceIDs: string[]
	onCheck: (type: CheckType, id: string, checked: boolean) => void
	disabled?: boolean
	backgroundColor?: string
}
export const ServiceCheckCard = (props: ServiceCheckCardProps) => {
	const { id, name, onCheck, explainedServiceIDs, interestedServiceIDs, disabled } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		backgroundColor: props.backgroundColor,
		paddingTop: "2px",
		paddingBottom: "2px",
		paddingLeft: "5px",
	})
	const labelColumn = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		justifyContent: "center",
	})
	const checkboxColumn = css({
		minWidth: "100px",
		display: "flex",
		justifyContent: "center",
	})

	return (
		<div className={container}>
			<div className={labelColumn}>
				<LabelSmall>{name}</LabelSmall>
			</div>
			<div className={checkboxColumn}>
				<Checkbox
					disabled={disabled}
					checked={explainedServiceIDs.includes(id)}
					onChange={(e) => onCheck(CheckType.Explained, id, e.currentTarget.checked)}
					overrides={{
						Root: {
							style: {
								alignItems: "center",
								position: "relative",
								cursor: "default",
							},
						},
					}}
				/>
			</div>
			<div className={checkboxColumn}>
				<Checkbox
					disabled={disabled}
					checked={interestedServiceIDs.includes(id)}
					onChange={(e) => onCheck(CheckType.Interested, id, e.currentTarget.checked)}
					overrides={{
						Root: {
							style: {
								alignItems: "center",
								position: "relative",
								cursor: "default",
							},
						},
					}}
				/>
			</div>
		</div>
	)
}
