import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Select, SelectOverrides, Value } from "baseui/select"
import { LabelMedium } from "baseui/typography"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { Divider, ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ListTable } from "../../../components/listTable"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenInput, ZenSearchInput } from "../../../components/zenComponents/zenInput"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { fetching } from "../../../fetching"
import { snakeToTitle, useDebounce } from "../../../helpers/utils"
import { ZenTheme } from "../../../themeOverrides"
import { NoteTypeItem } from "../../../types/types"
import { NoteTypeCategory } from "../../../types/enums"

enum FilterOption {
	Active = "Active",
	Archive = "Archive",
}
export const NoteTypeOptionManagement = () => {
	const [css] = useStyletron()
	const container = css({
		height: "100%",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})

	const group = css({
		display: "flex",
		width: "100%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [noteTypeItems, setNoteTypeItems] = React.useState<NoteTypeItem[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, name: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(20)
	const [selectedNoteTypeCategory, setSelectedNoteTypeCategory] = React.useState([{ id: NoteTypeCategory.Client, label: "client" }])

	const noteTypeManyFromCategory = useQuery(
		fetching.query.noteTypeManyFromCategory({
			category: selectedNoteTypeCategory[0].id,
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
		}),
	)
	React.useEffect(() => {
		if (noteTypeManyFromCategory.error || !noteTypeManyFromCategory.payload) return
		setTotal(noteTypeManyFromCategory.payload.total)
		setNoteTypeItems(noteTypeManyFromCategory.payload.noteTypeItems)
	}, [noteTypeManyFromCategory.payload, noteTypeManyFromCategory.error])

	const noteTypeArchive = useMutation(fetching.mutation.noteTypeArchive)
	const noteTypeUnarchive = useMutation(fetching.mutation.noteTypeUnarchive)

	const [targetedNoteTypeItemID, setTargetedNoteTypeItemID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedNoteType, setSelectedNoteType] = React.useState<NoteTypeItem>()
	const selectOverride: SelectOverrides = {
		Root: {
			style: {
				maxWidth: "165px",
				marginLeft: "14px",
				borderTopColor: "#D7DAE2",
				borderBottomColor: "#D7DAE2",
				borderLeftColor: "#D7DAE2",
				borderRightColor: "#D7DAE2",
				borderWidth: "1px",
				borderRadius: "3px",
				boxShadow: "#00000029 0px 2px 3px",
			},
		},
		InputContainer: {
			style: {
				width: "fit-content",
			},
		},
		ValueContainer: {
			style: {
				height: "24px",
				fontSize: "14px",
				fontWeight: 600,
				lineHeight: "16px",
				paddingTop: "0",
				paddingBottom: "0",
				paddingLeft: "6px",
			},
		},
		SelectArrow: {
			component: () => <FontAwesomeIcon fontWeight="bold" color="#A4AFB7" icon={["fal", "chevron-down"]} size="xs" />,
		},
		OptionContent: {
			style: {
				textTransform: "capitalize",
				whiteSpace: "nowrap",
			},
		},
		DropdownContainer: {
			style: {
				width: "unset",
				minWidth: "122px",
			},
		},
	}

	return (
		<ZenCard className={container}>
			<div className={title}>
				<div className={group}>
					<ZenSearchInput placeholder="Search" value={displayKey} onChange={(event) => setDisplayKey(event.currentTarget.value)} />
					<Select
						searchable={false}
						options={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
						value={filter}
						onChange={(params) => {
							setFilter(params.value)
							setOffset(0)
						}}
						clearable={false}
						backspaceRemoves={false}
						overrides={selectOverride}
					/>
					<Select
						searchable={false}
						options={Object.values(NoteTypeCategory).map((ntc) => ({ id: ntc, label: snakeToTitle(ntc) }))}
						value={selectedNoteTypeCategory}
						onChange={(params) => {
							setSelectedNoteTypeCategory(
								params.value as {
									id: NoteTypeCategory
									label: string
								}[],
							)
							setOffset(0)
						}}
						clearable={false}
						backspaceRemoves={false}
						overrides={selectOverride}
					/>
				</div>

				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New NoteTypeItem</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{noteTypeManyFromCategory.error && <ErrorNotification messageOrPayload={noteTypeManyFromCategory.payload} />}
			{noteTypeArchive.error && <ErrorNotification messageOrPayload={noteTypeArchive.payload} />}
			{noteTypeUnarchive.error && <ErrorNotification messageOrPayload={noteTypeUnarchive.payload} />}
			<ListTable
				isLoading={noteTypeManyFromCategory.loading || noteTypeArchive.loading || noteTypeUnarchive.loading}
				rows={noteTypeItems}
				onRowClick={(row: NoteTypeItem) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedNoteTypeItemID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedNoteType(row)
				}}
				columns={[
					{
						id: "name",
						header: "Note Type",
						resolver: (row: NoteTypeItem) => row.noteType.name,
					},
					{
						id: "Action",
						header: <div className={actionButton}>Action</div>,
						resolver: (row: NoteTypeItem) => (
							<div className={actionButton}>
								<Button
									kind="minimal"
									onClick={(e) => {
										e.stopPropagation()
										setTargetedNoteTypeItemID(row.id)
										if (!row.deletedAt) {
											setOpenArchiveModal(true)
											return
										}
										setOpenUnarchiveModal(true)
									}}
								>
									<FontAwesomeIcon
										color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
										size={"1x"}
										icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
									/>
								</Button>
								{targetedNoteTypeItemID === row.id && (
									<div onClick={(e) => e.stopPropagation()}>
										{openArchiveModal && (
											<ZenArchiveModal
												open={openArchiveModal}
												loading={noteTypeArchive.loading || noteTypeManyFromCategory.loading}
												message={row.noteType.name}
												onClose={() => setOpenArchiveModal(false)}
												confirmArchive={() => {
													noteTypeArchive.mutate({ id: row.id, category: selectedNoteTypeCategory[0].id }).then((resp) => {
														if (resp.error || !resp.payload) return
														noteTypeManyFromCategory.query()
														setOpenArchiveModal(false)
													})
												}}
											/>
										)}
										{openUnarchiveModal && (
											<ZenArchiveModal
												open={openUnarchiveModal}
												loading={noteTypeUnarchive.loading || noteTypeManyFromCategory.loading}
												message={row.noteType.name}
												onClose={() => setOpenUnarchiveModal(false)}
												restoreMode
												confirmArchive={() => {
													noteTypeUnarchive.mutate({ id: row.id, category: selectedNoteTypeCategory[0].id }).then((resp) => {
														if (resp.error || !resp.payload) return
														noteTypeManyFromCategory.query()
														setOpenUnarchiveModal(false)
													})
												}}
											/>
										)}
									</div>
								)}
							</div>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedNoteType && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<NoteTypeForm
						noteTypeCategory={selectedNoteTypeCategory[0].id}
						noteTypeItem={selectedNoteType}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedNoteType(undefined)
						}}
						queryNoteTypes={noteTypeManyFromCategory.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<NoteTypeForm
						noteTypeCategory={selectedNoteTypeCategory[0].id}
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryNoteTypes={noteTypeManyFromCategory.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface NoteTypeFormProps {
	noteTypeItem?: NoteTypeItem
	onClose: () => void
	noteTypeCategory: NoteTypeCategory
	queryNoteTypes: () => Promise<
		QueryResponse<{
			noteTypeItems: NoteTypeItem[]
			total: number
		}>
	>
}
const NoteTypeForm = (props: NoteTypeFormProps) => {
	const { noteTypeItem, onClose, queryNoteTypes, noteTypeCategory } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedNoteType, setDuplicatedNoteType] = React.useState<{ id: string; deletedAt?: string }>()

	const noteTypeCreate = useMutation(fetching.mutation.noteTypeCreate)
	const noteTypeUpdate = useMutation(fetching.mutation.noteTypeUpdate)
	const noteTypeUnarchive = useMutation(fetching.mutation.noteTypeUnarchive)

	const { control, errors, setValue, handleSubmit, watch } = useForm()

	const noteTypeName: string = watch("name")

	React.useEffect(() => {
		if (!noteTypeItem) return
		setValue("name", noteTypeItem.noteType.name)
	}, [noteTypeItem, setValue])

	const onSubmit = (formData: any) => {
		// update existing noteTypeItem
		if (noteTypeItem) {
			// close modal if the noteTypeItem name is unchanged
			if (noteTypeItem.noteType.name === formData.name) {
				onClose()
				return
			}

			noteTypeUpdate
				.mutate({
					id: noteTypeItem.id,
					category: noteTypeCategory,
					...formData,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated noteTypeItem
					if (typeof resp.payload === "object") {
						setDuplicatedNoteType(resp.payload)
						return
					}

					queryNoteTypes()
					onClose()
				})
			return
		}

		// create new noteTypeItem
		noteTypeCreate
			.mutate({
				category: noteTypeCategory,
				...formData,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated noteTypeItem
				if (typeof resp.payload === "object") {
					setDuplicatedNoteType(resp.payload)
					return
				}

				queryNoteTypes()
				onClose()
			})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated noteTypeItem after submit
		if (duplicatedNoteType && !!duplicatedNoteType.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The {noteTypeCategory.toLowerCase().replace("_", " ")} note type "{noteTypeName}" is already exists, but it had been archived. Do you want to
						restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedNoteType(undefined)}
						saveFn={() =>
							noteTypeUnarchive.mutate({ id: duplicatedNoteType.id, category: noteTypeCategory }).then((resp) => {
								if (resp.error || !resp.payload) return
								queryNoteTypes()
								onClose()
							})
						}
						isLoading={noteTypeUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{noteTypeUnarchive.error && <ErrorNotification messageOrPayload={noteTypeUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!noteTypeItem ? "Rename" : "Create"} Note Type</LabelMedium>
				<ZenInput name="Name" formRef={control} nameRef="name" inputError={errors.name} required />
				<CancelAndSaveButtons cancelFn={onClose} saveLabel={!!noteTypeItem ? "Save" : "Submit"} isLoading={noteTypeCreate.loading || noteTypeUpdate.loading} />
				{duplicatedNoteType && (
					<ErrorNotification message={`The ${noteTypeCategory.toLowerCase().replace("_", " ")} note type "${noteTypeName}" is already exists`} />
				)}
				{noteTypeCreate.error && <ErrorNotification messageOrPayload={noteTypeCreate.payload} />}
				{noteTypeUpdate.error && <ErrorNotification messageOrPayload={noteTypeUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}
