import * as React from "react"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { fetching } from "../../../fetching"
import { WorkerFormData } from "../../../pages/workers/workerCreate"
import { ZenTheme } from "../../../themeOverrides"
import { daysOfWeek } from "../../../types/enums"
import { UserDetail, UserOfficesInput } from "../../../types/types"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { ZenCard } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { DisplayLabel } from "../../sessions/newClientAppointment/singleSessionConfirmation"
import { EmergencyContactItem } from "../../zenComponents/emergencyContact"
import { useZenToast } from "../../zenComponents/useZenToast"
import { ZenDivider } from "../../zenComponents/zenInput"

export const WorkerConfirmation = (props: WorkerFormData & { onSubmitted: () => void }) => {
	const { data, onSubmitted } = props
	const [css] = useStyletron()
	const history = useHistory()
	const { showToast } = useZenToast()

	const { mutate, loading, error, payload } = useMutation<UserDetail>(fetching.mutation.userCreate)

	const fileUpload = useMutation<{ fileID: string }>(fetching.mutation.fileUpload)

	const onSubmit = async () => {
		const offices: UserOfficesInput[] = []
		data.offices?.forEach((o) => {
			if (!o.office) return
			offices.push({ day: o.day, officeID: o.office.id })
		})

		// avatar upload
		let avatarID = ""
		if (data.avatar) {
			const resp = await fileUpload.mutate({ file: data.avatar })
			if (!resp || resp.error) return
			avatarID = resp.payload?.fileID || ""
		}
		const res = await mutate({
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			mobileNumber: data.mobileNumber,
			dateOfBirth: data.dateOfBirth,
			roleID: data.role && data.role.length > 0 ? data.role[0].id?.toString() : undefined,
			avatarID: avatarID || null,
			addressPlaceID: data.address && data.address.length > 0 ? data.address[0].id : undefined,
			positionID: data.position && data.position.length > 0 ? data.position[0].id?.toString() : undefined,
			departmentID: data.department && data.department.length > 0 ? data.department[0].id?.toString() : undefined,
			offices,
			vehicleRegistrationNumber: data.vehicleRegistrationNumber,
			emergencyContact: data.emergencyContact,
			status: data.status && data.status.length > 0 ? data.status[0].id?.toString() : undefined,
		})

		if (!res.payload || res.error) return
		onSubmitted()
		showToast("Worker created successfully", "positive")

		history.push(`/portal/workers/${res.payload.id}`)
	}
	const container = css({
		width: "60%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		backgroundColor: ZenTheme.colors.divider,
		padding: "15px",
	})
	const title = css({
		display: "flex",
		alignItems: "center",
	})
	const scrollingDiv = css({
		padding: "20px",
		height: "100%",
		width: "100%",
		maxHeight: "100%",
		overflowY: "auto",
	})
	const group = css({
		display: "flex",
	})
	const formStyle = css({
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})
	const subForm = css({
		paddingLeft: "6px",
	})
	return (
		<ZenCard className={container}>
			<div className={formStyle}>
				<LabelLarge marginBottom="10px">Confirmation</LabelLarge>
				<div className={scrollingDiv}>
					<LabelMedium marginBottom="6px">General Details</LabelMedium>
					<div className={subForm}>
						<div className={title}>
							<Avatar
								name={`${data.firstName} ${data.lastName}`}
								overrides={{
									Root: {
										style: {
											minWidth: ZenTheme.sizing.scale1000,
										},
									},
								}}
								src={data.avatar ? URL.createObjectURL(data.avatar) : undefined}
							/>
							<LabelSmall marginLeft="10px">{`${data.firstName} ${data.lastName}`}</LabelSmall>
						</div>
						<div className={group}>
							<div style={{ width: "50%" }}>
								<DisplayLabel label="Email">{data.email}</DisplayLabel>
							</div>
						</div>
						<div className={group}>
							<DisplayLabel label="Mobile">{data.mobileNumber}</DisplayLabel>
							<DisplayLabel label="Date of birth">{data.dateOfBirth ? moment(data.dateOfBirth).format("DD/MM/YYYY") : ""}</DisplayLabel>
						</div>
						<DisplayLabel label="Address">{data.address && data.address.length > 0 ? data.address[0].label : "N/A"}</DisplayLabel>
						<DisplayLabel label="Role">{data.role && data.role.length > 0 ? data.role[0].label : "N/A"}</DisplayLabel>
						<div className={group}>
							<DisplayLabel label="Department">{data.department && data.department.length > 0 ? data.department[0].label : "N/A"}</DisplayLabel>
							<DisplayLabel label="Position">{data.position && data.position.length > 0 ? data.position[0].label : "N/A"}</DisplayLabel>
							<DisplayLabel label="Status">{data.status && data.status.length > 0 ? data.status[0].label : "N/A"}</DisplayLabel>
						</div>
						<DisplayLabel label="Vehicle Registration Number">{data.vehicleRegistrationNumber || "N/A"}</DisplayLabel>
					</div>
					<LabelMedium marginTop="8px" marginBottom="6px">
						Offices Detail
					</LabelMedium>
					<div className={subForm}>
						{daysOfWeek.map((d, i) => {
							const office = data.offices?.find((o) => o.day === d)
							return (
								<DisplayLabel key={i} label={`Office - ${d}`}>
									{office && office.office ? office.office.name : "N/A"}
								</DisplayLabel>
							)
						})}
					</div>

					<LabelMedium marginTop="8px">Emergency contact detail</LabelMedium>
					<div className={subForm}>
						{data.emergencyContact && data.emergencyContact.length > 0 && (
							<>
								<ZenDivider marginTop="15px" />
								{data.emergencyContact.map((e, i) => (
									<EmergencyContactItem
										key={i}
										index={i}
										id={i.toString()}
										marginBottom="15px"
										firstName={e.firstName}
										lastName={e.lastName}
										telephoneNumber={e.telephoneNumber}
										mobileNumber={e.mobileNumber}
										relationship={e.relationship}
									/>
								))}
							</>
						)}
					</div>
				</div>
			</div>
			{error && <ErrorNotification messageOrPayload={payload} />}
			{fileUpload.error && <ErrorNotification messageOrPayload={"Failed to upload avatar image; please try again or try a different file format"} />}
			<CancelAndSaveButtons cancelLabel="Back" cancelFn={() => history.goBack()} isLoading={loading} saveLabel="Create" saveFn={onSubmit} />
		</ZenCard>
	)
}
