import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge } from "baseui/typography"
import { useFormContext } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { ZenCheckbox } from "../../../zenComponents/zenCheckboxList"
import { ZenInput, ZenTextArea } from "../../../zenComponents/zenInput"
import { IntakeScrollDiv } from "../intakeComponent"
import { IntakeAdultProps, intakeURLParser } from "./intakeAdultBaseForm"
import { ErrorFieldTracker } from "../../../forms/errorFieldTracker"

export const IntakeAdultCareContext = (props: IntakeAdultProps) => {
	const { clientID, formID, isReadOnly, validationTriggered } = props
	const [css] = useStyletron()
	const history = useHistory()

	const { watch, trigger, control, errors, getValues } = useFormContext()
	const receivingFormalSupports = watch("receivingFormalSupports")

	const validation = React.useCallback(async () => {
		const currentState = getValues()

		const checkList: string[] = []
		if (currentState.receivingFormalSupports) checkList.push("formalSupportDetail")

		const isValid = await trigger(checkList)
		return isValid
	}, [trigger, getValues])
	React.useEffect(() => {
		if (validationTriggered) validation()
	}, [validation, validationTriggered])

	const onSubmit = async () => {
		const checkList: string[] = []
		if (receivingFormalSupports) checkList.push("formalSupportDetail")

		const isValid = await validation()
		if (!isValid) return

		// route to next page
		history.push(intakeURLParser(`/portal/clients/${clientID}/intake/adult/carerStar`, formID))
	}

	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "white",
		flexDirection: "row",
	})
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
		paddingTop: "25px",
	})
	const body = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})
	const subForm = css({
		padding: "20px",
	})
	return (
		<div className={container}>
			<form autoComplete="off" className={formStyle}>
				<div className={body}>
					<LabelLarge>Client Context</LabelLarge>
					<IntakeScrollDiv>
						<div className={subForm}>
							<ZenInput disabled={isReadOnly} label="If a carer, name of person who has mental health concern?" nameRef="name" formRef={control} />
							<ZenInput disabled={isReadOnly} label="Relationship" nameRef="relationship" formRef={control} placeholder="Enter relationship" />
							<ZenTextArea
								disabled={isReadOnly}
								label="Mental Health Diagnosis/Concern"
								nameRef="mentalHealthDiagnosisConcern"
								formRef={control}
								placeholder="Enter details"
								initialHeight={180}
								getValues={getValues}
							/>
							<ZenCheckbox
								disabled={isReadOnly}
								label="Is the consumer receiving formal support?"
								formName="receivingFormalSupports"
								formRef={control}
								overrides={{ Root: { style: { marginTop: "20px" } } }}
							/>
							{receivingFormalSupports && (
								<ZenTextArea
									disabled={isReadOnly}
									label="Please list relevant information"
									nameRef="formalSupportDetail"
									formRef={control}
									formRules={{ required: "Detail is required" }}
									placeholder="Enter details about receiving formal support"
									inputError={errors.formalSupportDetail}
									initialHeight={180}
									getValues={getValues}
								/>
							)}
						</div>

						<LabelLarge marginTop="10px">Current Carer Concerns</LabelLarge>
						<div className={subForm}>
							<ZenTextArea
								disabled={isReadOnly}
								label="Reasons for referral, impact of the issues, duration of issues etc."
								nameRef="currentCarerConcerns"
								formRef={control}
								placeholder="Enter details"
								initialHeight={180}
								getValues={getValues}
							/>
						</div>
					</IntakeScrollDiv>
				</div>
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				{!isReadOnly && <CancelAndSaveButtons cancelLabel="Back" cancelFn={() => history.goBack()} saveLabel="Next" saveFn={onSubmit} />}
			</form>
		</div>
	)
}
