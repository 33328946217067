import * as React from "react"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Option, Value } from "baseui/select"
import { LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"

import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { alignDate, snakeToTitle, timeDuration } from "../../helpers/utils"
import { BasicLabel, BasicName, CallLog, CallPurpose, Client, RolePermission } from "../../types/types"
import { CancelAndSaveButtons } from "../cancelSaveButtons"
import { Divider, Spacer } from "../common"
import { ErrorNotification } from "../errorBox"
import { Loading } from "../loading"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenButton, ZenButtonGroup } from "../zenComponents/zenButtons"
import { ZenInput } from "../zenComponents/zenInput"
import { ZenClientSelect, ZenSelect, ZenUserSelect } from "../zenComponents/zenSelectBox"
import { ZenDatePicker, ZenTimePicker } from "../zenComponents/zenTime"
import { AlertsModel, CallFromClientOptions, CallTypeOptions, CreateNewClientModal } from "./callLogCreateForm"
import { ErrorFieldTracker } from "../forms/errorFieldTracker"

export const CallLogUpdateForm = (props: { callLog: CallLog }) => {
	const { callLog } = props

	const history = useHistory()
	const { showToast } = useZenToast()
	const { errors, handleSubmit, control, watch, setValue, formState } = useForm()
	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const { mutate, error, loading, payload } = useMutation<CallLog>(fetching.mutation.callLogUpdate)

	const watchStartTime = watch("startTime")
	const watchEndTime = watch("endTime")

	// options for call purpose
	const [selectedCallPurpose, setSelectedCallPurpose] = React.useState<CallPurpose>()
	const [callPurposeOptions, setCallPurposeOptions] = React.useState<Option[]>([])
	const callPurposeData = useQuery<CallPurpose[]>(fetching.query.getCallPurposeAll())
	React.useEffect(() => {
		if (callPurposeData.loading || !callPurposeData.payload) return
		setCallPurposeOptions(callPurposeData.payload)
	}, [callPurposeData.payload, callPurposeData.error, callPurposeData.loading])

	// options for call purpose type
	const [callPurposeTypeOptions, setCallPurposeTypeOptions] = React.useState<Option[]>([])

	const {
		query: callPurposeTypeQuery,
		error: callPurposeTypeError,
		loading: callPurposeTypeLoading,
		payload: callPurposeTypePayload,
	} = useParameterizedQuery<BasicLabel[]>(fetching.query.getCallPurposeTypesByCallPurposeID)
	const callPurposeTypeDataCallback = React.useCallback(() => {
		if (!selectedCallPurpose?.id) return
		callPurposeTypeQuery(selectedCallPurpose.id)
	}, [selectedCallPurpose, callPurposeTypeQuery])
	React.useEffect(() => {
		callPurposeTypeDataCallback()
	}, [callPurposeTypeDataCallback])
	React.useEffect(() => {
		if (callPurposeTypeLoading || callPurposeTypeError || !callPurposeTypePayload) return
		setCallPurposeTypeOptions(callPurposeTypePayload)
	}, [callPurposeTypePayload, callPurposeTypeError, callPurposeTypeLoading])

	// options for departments
	const [departmentOptions, setDepartmentOptions] = React.useState<Option[]>([])
	const departmentData = useQuery<BasicName[]>(fetching.query.getDepartmentAll())
	React.useEffect(() => {
		if (departmentData.loading || !departmentData.payload) return
		setDepartmentOptions(departmentData.payload.map<Option>((d) => ({ ...d, label: d.name })))
	}, [departmentData.payload, departmentData.loading, departmentData.error])

	// handle client alerts model
	const selectedClient = watch("client")
	const [showAlertsModel, setShowAlertsModel] = React.useState(false)

	// handle transfer to staff worker
	const selectedTransferredTo = watch("transferredTo")

	// handle create new client
	const [newClient, setNewClient] = React.useState<Client>()
	const createNewClient = (c: Client) => {
		setNewClient(c)
		setValue("client", [{ ...c, label: `${c.firstName} ${c.lastName}` }])
		setExcludedClientID([c.id])
	}

	// handle excluded workers and clients
	const [workerID, setWorkerID] = React.useState<string>("")
	const [excludedUserID, setExcludedUserID] = React.useState<string[]>([])
	const [excludedClientID, setExcludedClientID] = React.useState<string[]>([])

	React.useEffect(() => {
		const idList: string[] = []
		if (workerID !== "") idList.push(workerID)
		setExcludedUserID(idList)
	}, [workerID])

	const onSave = async (formData: any) => {
		// submit call log
		const resp = await mutate({
			id: callLog.id,
			callType: CallTypeOptionKey,
			workerID: formData.worker[0].id,
			startTime: alignDate(formData.date, formData.startTime),
			endTime: alignDate(formData.date, formData.endTime),
			callFrom: CallFromOptionKey,
			callerName: CallFromOptionKey === CallFromClientOptions.CallAboutAClient ? formData.callerName : undefined,
			clientID: CallFromOptionKey !== CallFromClientOptions.AnonymousCaller ? formData.client[0].id : undefined,
			phoneNumber: formData.phoneNumber,
			callPurposeID: formData.callPurpose[0].id,
			callPurposeTypeIDList:
				callPurposeTypeOptions.length > 0 && formData.callPurposeType && formData.callPurposeType.length > 0
					? formData.callPurposeType.map((cpt: { id: string }) => cpt.id)
					: undefined,
			transferredToDepartmentID: formData.transferredTo && formData.transferredTo.length > 0 ? formData.transferredTo[0].id : undefined,
			transferredToWorkerID: formData.transferredToWorker && formData.transferredToWorker.length > 0 ? formData.transferredToWorker[0].id : undefined,
		})

		if (resp.error || !resp.payload) return
		showToast("Call Log updated successfully.", "positive")
		history.push(`/portal/call-logs/${resp.payload}`)
	}

	// Action for the button group
	const [openModal, setOpenModal] = React.useState<boolean>(false)
	const [CallTypeOptionKey, setCallTypeOptionKey] = React.useState<string>(Object.values(CallTypeOptions)[0])
	const [CallFromOptionKey, setCallFromOptionKey] = React.useState<string>(Object.values(CallFromClientOptions)[0])
	const callTypeActionOnSelect = (id: string) => {
		setCallTypeOptionKey(id)
	}
	const actionOnSelect = (id: string) => {
		setCallFromOptionKey(id)
		if (id === CallFromClientOptions.CallFromNewClient) {
			setValue("client", newClient ? [{ ...newClient, label: `${newClient.firstName} ${newClient.lastName}` }] : [])
			setExcludedClientID([])
			setOpenModal(true)
		}
	}

	// Load data from existing call log when editing
	const loadDefaults = React.useCallback(() => {
		if (!callLog) return

		if (callLog.client) {
			setValue("client", [{ ...callLog.client, label: `${callLog.client.firstName} ${callLog.client.lastName}` }])
			setExcludedClientID([callLog.client.id])
		}
		setValue("callerName", callLog.callerName)
		setValue("phoneNumber", callLog.phoneNumber)
		setValue("date", new Date(callLog.startTime))
		setValue("startTime", new Date(callLog.startTime))
		setValue("endTime", new Date(callLog.endTime))
		if (!!callLog.notes && callLog.notes.length > 0) setValue("note", callLog.notes[0].content)
		if (callLog.transferredToDepartment) setValue("transferredTo", [{ ...callLog.transferredToDepartment, label: callLog.transferredToDepartment.name }])
		if (callLog.transferredToWorker)
			setValue("transferredToWorker", [
				{ ...callLog.transferredToWorker, label: `${callLog.transferredToWorker.firstName} ${callLog.transferredToWorker.lastName}` },
			])
		if (callLog.worker) {
			setValue("worker", [{ ...callLog.worker, label: `${callLog.worker.firstName} ${callLog.worker.lastName}` }])
			setWorkerID(callLog.worker.id)
		}
		if (callLog.callPurpose) {
			setValue("callPurpose", [callLog.callPurpose])
			setSelectedCallPurpose(callLog.callPurpose as CallPurpose)
		}
		if (callLog.callPurposeTypes) setValue("callPurposeType", callLog.callPurposeTypes)
		setCallTypeOptionKey(callLog.type)
		setCallFromOptionKey(callLog.callFrom)
	}, [callLog, setValue])
	React.useEffect(() => {
		loadDefaults()
	}, [loadDefaults])

	// Auto-fill phone number if call from existing client
	const autoFillPhone = React.useCallback(() => {
		if (
			CallFromOptionKey === CallFromClientOptions.CallFromExistingClient &&
			!!selectedClient &&
			selectedClient.length > 0 &&
			!!selectedClient[0]?.currentContact
		) {
			setValue("phoneNumber", selectedClient[0]?.currentContact?.mobileNumber || selectedClient[0]?.currentContact?.telephoneNumber)
		}
	}, [selectedClient, CallFromOptionKey, setValue])
	React.useEffect(() => {
		autoFillPhone()
	}, [autoFillPhone])

	const [css] = useStyletron()
	const form = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		minHeight: 0,
	})
	const body = css({
		flex: 1,
		height: "100%",
		minHeight: 0,
		display: "flex",
		flexDirection: "column",
	})
	const scrollingDiv = css({
		height: "100%",
		overflowY: "auto",
		overflowX: "hidden",
		paddingRight: "8px",
	})
	const horizontalScrollingDiv = css({
		overflowX: "auto",
	})
	const rangeTimeStyle = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
	})
	const durationContainer = css({
		width: "100%",
		display: "flex",
		flexDirection: "column",
	})
	const title = css({
		display: "flex",
		alignItems: "center",
		height: "34px",
	})

	if (loading) return <Loading />

	return (
		<>
			<form autoComplete="off" className={form} onSubmit={handleSubmit(onSave)}>
				<div className={body}>
					<Block marginBottom="15px">
						<LabelMedium className={title}>{`Call Log #${callLog.identifier}`}</LabelMedium>
						<Divider />
					</Block>
					<div className={scrollingDiv}>
						<ZenButtonGroup
							label="Call Type"
							data={Object.values(CallTypeOptions).map((c) => ({
								id: c,
								label: snakeToTitle(c),
							}))}
							currentKey={CallTypeOptionKey}
							actionOnSelect={callTypeActionOnSelect}
						/>

						<ZenUserSelect
							label="Worker"
							formName="worker"
							formRef={control}
							excludedID={excludedUserID}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
								},
							}}
							// if users cannot view all the workers, disable the field
							disabled={!hasPermission(RolePermission.UserRead)}
							inputError={errors.worker}
							actionOnChange={(w: Value) => setWorkerID(w.length > 0 && w[0].id ? w[0].id.toString() : "")}
						/>

						<ZenDatePicker label="Date" formName="date" formRef={control} clearable={false} />

						<Divider style={{ backgroundColor: "transparent" }} />

						<div className={horizontalScrollingDiv}>
							<ZenButtonGroup
								label={CallTypeOptionKey === CallTypeOptions.IncomingCall ? "Call From" : "Call To"}
								data={Object.values(CallFromClientOptions).map((c) => {
									let label = snakeToTitle(c)
									switch (c) {
										case CallFromClientOptions.CallFromExistingClient:
											if (CallTypeOptionKey === CallTypeOptions.OutgoingCall) {
												label = "Call To Existing Client"
											}
											break
										case CallFromClientOptions.CallFromNewClient:
											if (CallTypeOptionKey === CallTypeOptions.OutgoingCall) {
												label = "Call To New Client"
											}
											break
									}

									return { id: c, label }
								})}
								currentKey={CallFromOptionKey}
								actionOnSelect={actionOnSelect}
							/>
						</div>
						{CallFromOptionKey === CallFromClientOptions.CallAboutAClient && (
							<ZenInput
								label="Name of the caller"
								nameRef="callerName"
								placeholder="Enter caller's name"
								inputError={errors.callerName}
								formRef={control}
								required
							/>
						)}
						{CallFromOptionKey !== CallFromClientOptions.AnonymousCaller && (
							<>
								<ZenClientSelect
									label="Client"
									clearable
									formName="client"
									formRef={control}
									inputError={errors.client}
									formRules={{
										validate: {
											required: (value: Value) => (!!value && value.length > 0) || "Client is required",
										},
									}}
									disabled={CallFromOptionKey === CallFromClientOptions.CallFromNewClient}
									actionOnChange={(c: Value) => setExcludedClientID(c.length > 0 && c[0].id ? [c[0].id.toString()] : [])}
									excludedID={excludedClientID}
									showAllClients
								/>
								{selectedClient && selectedClient.length > 0 && (
									<ZenButton type={"button"} onClick={() => setShowAlertsModel(true)}>
										View Alerts
									</ZenButton>
								)}
							</>
						)}

						<ZenInput
							// Use generic field so to allow for private numbers
							placeholder={"Phone number"}
							inputError={errors.phoneNumber}
							label={"Phone Number"}
							nameRef={"phoneNumber"}
							marginBottom="0"
							marginTop="0"
							formRef={control}
							required // A call log needs a related number, 'Private Number', or note
						/>

						<ZenSelect
							label="Purpose of Call"
							formName="callPurpose"
							options={callPurposeOptions}
							inputError={errors.callPurpose}
							formRef={control}
							actionOnChange={(v) => {
								setValue("callPurposeType", [])
								setSelectedCallPurpose(undefined)
								if (!v.length || !v[0].id) {
									setCallPurposeTypeOptions([])
									return
								}
								setSelectedCallPurpose(v[0] as CallPurpose)
							}}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || `Purpose of call required`,
								},
							}}
						/>

						{callPurposeTypeOptions.length > 0 && (
							<ZenSelect
								label="Type"
								formName="callPurposeType"
								options={callPurposeTypeOptions}
								formRef={control}
								multi={selectedCallPurpose?.acceptMultiTypes}
							/>
						)}

						<Divider style={{ backgroundColor: "transparent" }} />

						<ZenSelect label="Transferred to" formName="transferredTo" options={departmentOptions} formRef={control} />

						{selectedTransferredTo && selectedTransferredTo.length > 0 && selectedTransferredTo[0]?.label === "Staff Member" && (
							<ZenUserSelect
								label={"Transferred to Worker"}
								formName={"transferredToWorker"}
								formRef={control}
								excludedID={currentUser ? [currentUser.id] : []}
								inputError={errors.transferredToWorker}
								formRules={{
									validate: {
										required: (value: Value) => (!!value && value.length > 0) || `transferred worker is required`,
									},
								}}
							/>
						)}

						<Divider style={{ backgroundColor: "transparent" }} />

						<div className={rangeTimeStyle}>
							<ZenTimePicker
								label="Start time"
								formName="startTime"
								creatable
								date={watch("date")}
								formRef={control}
								inputError={errors.startTime}
								minuteStep={1}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "You must select a start time"
											}
											if (watchEndTime && moment(value).isSameOrAfter(watchEndTime)) {
												return "Start time must be before end time"
											}
											return null
										},
									},
								}}
								width="100%"
							/>
							<Spacer style={{ minWidth: "12px" }} />
							<ZenTimePicker
								label="End time"
								formName="endTime"
								creatable
								date={watch("date")}
								formRef={control}
								inputError={errors.endTime}
								minuteStep={1}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "You must select an end time"
											}
											if (watchStartTime && moment(value).isSameOrBefore(watchStartTime)) {
												return "End time must be after start time"
											}
											return null
										},
									},
								}}
								width="100%"
							/>
							<Spacer style={{ minWidth: "12px" }} />
							<div className={durationContainer}>
								<FormControl label={<LabelSmall>Duration</LabelSmall>}>
									<Input disabled value={watchStartTime && watchEndTime ? timeDuration(watchStartTime, watchEndTime) : "0h 0m"} />
								</FormControl>
							</div>
						</div>
					</div>
				</div>
				<Block backgroundColor="white" marginTop="15px">
					{departmentData.error && <ErrorNotification messageOrPayload={departmentData.payload} />}
					{callPurposeData.error && <ErrorNotification messageOrPayload={callPurposeData.payload} />}
					{callPurposeTypeError && <ErrorNotification messageOrPayload={callPurposeTypePayload} />}
					{error && <ErrorNotification messageOrPayload={payload} />}
					<ErrorFieldTracker errorIDs={Object.keys(errors)} />
					<CancelAndSaveButtons cancelFn={() => history.push({ search: "" })} />
				</Block>
			</form>
			<CreateNewClientModal isOpen={openModal} setIsOpen={setOpenModal} setCreatedClient={createNewClient} />
			<AlertsModel
				isOpen={showAlertsModel}
				clientID={selectedClient && selectedClient.length > 0 ? selectedClient[0].id : undefined}
				setIsOpen={setShowAlertsModel}
			/>
			<Prompt when={formState.isDirty} message={"You have unsaved changes, are you sure you want to leave?"} />
		</>
	)
}
