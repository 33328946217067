import React from "react";
import { useStyletron } from "baseui";
import { Textarea } from "baseui/textarea";
import { borderRadiusStyle, ZenTheme } from "../../themeOverrides";
import { ZenCard } from "components/common";
import { LabelMedium } from "baseui/typography";
import { MileageClaim, MileageClaimResult, TimesheetResult, UserDetail, ZenDateFormat } from "types/types";
import { ZenButton } from "../../components/zenComponents/zenButtons";
import { useMutation } from "react-fetching-library";
import { fetching } from "fetching";
import { AuthContainer } from "controllers/auth";
import { useForm } from "react-hook-form";
import { TooltipButton } from "components/tooltipButton";
import { ZenTextArea } from "../../components/zenComponents/zenInput";
import { CancelAndSaveButtons } from "components/cancelSaveButtons";
import { UserLabel } from "components/callLogs/subComponents";
import { RevisionListModal } from "components/sessions/sessionNoteComponent";
import moment from "moment";
import { ErrorNotification } from "components/errorBox";
import { useZenToast } from "../../components/zenComponents/useZenToast";

interface MileageNoteAreaProps {
    mileage: MileageClaim 
	loading: boolean
	refetchTimesheet: () => void
}

export const MileageNoteArea = (props: MileageNoteAreaProps) => {
    const {
        mileage,
        loading: timesheetLoading,
        refetchTimesheet
    } = props ?? {};

    const { showToast } = useZenToast();
	const { currentUser } = AuthContainer.useContainer();
    const [content, setContent] = React.useState("");
    const [check, setCheck] = React.useState<boolean>(false);
    const mileageAddNote = useMutation<MileageClaimResult>(fetching.mutation.mileageAddNote)

    const [css, theme] = useStyletron();
    const descStyle = css({
		fontSize: "14px",
		color: theme.colors.contentTertiary,
		marginTop: "14px",
		marginBottom: "6px",
	});
	const addNoteStyle = css({
		display: "flex",
		height: "fit-content",
		// width: "100%",
		minWidth: "30%",
        maxWidth: "95%",
		marginTop: "18px",
		marginLeft: "14px",
	});
	const footerStyle = css({
		marginTop: "14px",
		display: "flex",
		justifyContent: "space-between",
	});

    const clearData = () => {
		setCheck(false)
		setContent("")
	};

    const onSaveNote = async () => {
		setCheck(true)
        const variables = {
			userIDs: [currentUser?.id],
			mileageID: mileage?.id,
            mileageNotes: content
		}
        if (variables.mileageID) {
			const response = await mileageAddNote.mutate(variables)
			if (response.error) return
			if (response.payload && response.payload.validationErrors && response.payload.validationErrors.length !== 0) {
                showToast("Error adding timesheet note.", "negative");
                return
            }
			showToast("Mileage note added successfully.", "positive");
            clearData()
            refetchTimesheet()
			return
		}
    };
    
    return (
        <React.Fragment>
            { !mileage.mileageNotes ?
                (<ZenCard className={addNoteStyle}>
                    <LabelMedium>Add Note</LabelMedium>
                    <div>
                        <div className={descStyle}>Enter notes, be very descriptive.</div>
                        <Textarea
                            value={content}
                            onChange={(e) => setContent(e.currentTarget.value)}
                            placeholder="Enter Notes"
                            disabled={mileageAddNote.loading}
                            overrides={{
                                Input: {
                                    style: {
                                        resize: "vertical",
                                        height: "300px",
                                        maxHeight: "800px",
                                        minHeight: "50px",
                                    },
                                },
                                InputContainer: {
                                    style: {
                                        borderTopColor: check && !content.length ? ZenTheme.colors.negative400 : "#C1C1C1",
                                        borderBottomColor: check && !content.length ? ZenTheme.colors.negative400 : "#C1C1C1",
                                        borderLeftColor: check && !content.length ? ZenTheme.colors.negative400 : "#C1C1C1",
                                        borderRightColor: check && !content.length ? ZenTheme.colors.negative400 : "#C1C1C1",
                                        borderTopStyle: "solid",
                                        borderLeftStyle: "solid",
                                        borderBottomStyle: "solid",
                                        borderRightStyle: "solid",
                                        borderTopWidth: "1px",
                                        borderLeftWidth: "1px",
                                        borderBottomWidth: "1px",
                                        borderRightWidth: "1px",
                                        ...borderRadiusStyle,
                                    },
                                },
                            }}
                        />
                    </div>
                    <div className={footerStyle}>
                        <ZenButton onClick={onSaveNote} isLoading={mileageAddNote.loading}>
                            Save Note
                        </ZenButton>
                    </div>
                    {mileageAddNote.error && <ErrorNotification messageOrPayload={"Failed to add note"} closeable />}
                </ZenCard>) 
                : (
                    <ZenCard className={addNoteStyle}>
                        <NoteDisplay note={mileage} refetchNotes={refetchTimesheet}/>
                    </ZenCard>
                )
            }
        </React.Fragment>
    );
};

interface NoteDisplayProps {
    note: MileageClaim
	refetchNotes: () => void
}

const NoteDisplay = (props: NoteDisplayProps) => {
	const { note, refetchNotes } = props;
	const [css, theme] = useStyletron();
    const { showToast } = useZenToast();
	const [editMode, setEditMode] = React.useState(false);
	const { control, errors, trigger, getValues, setValue } = useForm();
	const { currentUser } = AuthContainer.useContainer();
	const containerStyle = css({
		marginBottom: "12px",
		padding: "12px",
		fontSize: "14px",
		display: "flex",
		flexDirection: "column",
		borderRadius: "3px",
		backgroundColor: theme.colors.backgroundSecondary,
	});
	const headerStyle = css({
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "10px",
	});
	const dateStyle = css({
		...theme.typography.LabelSmall,
		color: theme.colors.contentTertiary,
		marginLeft: "4px",
	});

	const contentStyle = css({
		wordWrap: "break-word",
		whiteSpace: "pre-line",
	});

	const group = css({
		display: "flex",
		alignItems: "center",
	});

	const [showHistoryNotes, setShowHistoryNotes] = React.useState(false);

	// always display the latest note
	const displayNote = React.useMemo(() => {
		if (!note.mileageNotes) return
		setValue("content", note.mileageNotes)
		return note
	}, [note.mileageNotes, setValue]);

	const noteEdit = useMutation<MileageClaimResult>(fetching.mutation.mileageEditNote);
	const onSubmit = async () => {
		const isValid = await trigger()
		if (!isValid) return

        const response = await noteEdit.mutate({mileageID: note.id, mileageNotes: getValues().content});
        if (
            (response.error || !response.payload) ||
            (response.payload && response.payload.validationErrors && response.payload.validationErrors.length !== 0)
        ) {
            showToast("You do not have permissions for this, please contact System Administrator.", "negative");
            return
        }
        if (response.status === 200) {
            showToast("Mileage note update successful.", "positive");
            refetchNotes()
            setEditMode(false)
            return
        }
	};

	const actionButton = () => {
		if (editMode) return null
		return (
			<>
				{/* {note.userID === currentUser?.id && displayNote && displayNote.timesheetNotes && ( */}
					<>
						<TooltipButton
							onClick={(e) => {
								e.stopPropagation()
								setShowHistoryNotes(true)
							}}
							tooltip="history"
							iconName="history"
						/>
						<TooltipButton
							onClick={(e) => {
								e.stopPropagation()
								setEditMode(true)
							}}
							tooltip="Edit"
							iconName="edit"
						/>
					</>
				{/* )} */}
			</>
		)
	};

	const displayTextContent = () => {
		if (editMode) {
			return (
				<>
					<ZenTextArea nameRef="content" formRef={control} formRules={{ required: "content is required" }} inputError={errors.content} />
					<CancelAndSaveButtons cancelFn={() => setEditMode(false)} saveFn={onSubmit} />
				</>
			)
		}
		return <div className={contentStyle}>{displayNote?.mileageNotes}</div>
	};

	return (
		<div className={containerStyle}>
			<div className={headerStyle}>
				<div className={group}>
					<UserLabel data={note.user as UserDetail} />
					{/* <LabelSmall marginLeft="3px">{`- ${note.type.name} Note`}</LabelSmall> */}
				</div>
				<div className={group}>
					{actionButton()}
					<div className={dateStyle}>{moment(note.createdAt).format(ZenDateFormat)}</div>
				</div>
			</div>
			{displayTextContent()}
			{/* <RevisionListModal isOpen={showHistoryNotes} setIsOpen={setShowHistoryNotes} revisions={[note.timesheetNotes]} /> */}
		</div>
	)
};

